import '../../../platform.css';
import '../scouting.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { trackEvent } from '../../../services/server/analytics/trackEvent';

import SearchIcon from '@mui/icons-material/Search';

import { translate } from '../../../../common/language/translations';
import { SearchPlayerOverviewsResponse, searchPlayerOverviews } from '../../../services/server/application/playerOverviews';
import { PlayerOverviewsQueryOptions } from '../../../types';
import { PlayerTable } from '../../../components/tables/playerTable/PlayerTable';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';
import { debounce, stringArraysContainSameElements } from '../../../utils/utils';
import { PlayerScoutingFilters } from './PlayerScoutingFilters';
import { contractKeysToMonths } from '../../../static/propertyValues';
import { playerTableChapterSize, playerTablePageSize } from '../Scouting';
import { getFiltersUsed } from '../../../utils/scoutingUtils';
import { anyFilterExceptNameAndDefaultOrderBySelected, PlayerScoutingState } from './playerScoutingState';
import { PlayerScoutingAction } from './playerScoutingReducer';
import { TextButton } from '../../../components/controls/buttons/TextButton';
import { nameSearchHistorySelector } from '../../../recoil/selectors/scoutingConfigSelectors';


interface PlayerScoutingProps {
  state: PlayerScoutingState;
  dispatch: React.Dispatch<PlayerScoutingAction>;
  nameSearchString: string;
  setNameSearchString: (value: string) => void;
  currentNameSearchString: string;
  setCurrentNameSearchString: (value: string) => void;
  debouncedNameSearchString: string;
  setDebouncedNameSearchString: (value: string) => void;
}

export const PlayerScouting: React.FC<PlayerScoutingProps> = ({
  state,
  dispatch,
  nameSearchString,
  setNameSearchString,
  currentNameSearchString,
  setCurrentNameSearchString,
  debouncedNameSearchString,
  setDebouncedNameSearchString,
}) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const nameSearchHistory = useRecoilValue(nameSearchHistorySelector);

  const clearNameDebounceRef = useRef<() => void>(() => null);


  const onKeyDownNameSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && nameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(true, true);
    }
  };


  const handleTrackEvent = (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    searchQueryOptions: PlayerOverviewsQueryOptions,
    totalHits: number,
  ) => {

    const typeOfSearch = searchQueryOptions.name
      ? anyFilterExceptNameAndDefaultOrderBySelected(state)
        ? 'nameAndFilterSearch'
        : 'nameSearch'
      : 'filterSearch';

    const initiator = nextChapter
      ? 'pagination'
      : !isNewSearch
        ? 'scroll'
        : isUserInitiated
          ? 'user'
          : 'changes';

    trackEvent(
      'PlayerSearch',
      {
        typeOfSearch: typeOfSearch,
        initiator: initiator,
        isNewSearch: isNewSearch,
        filtersUsed: getFiltersUsed(searchQueryOptions),
        totalHits: totalHits,

        name: searchQueryOptions.name,
        minConfidence: searchQueryOptions.minConfidence ? (searchQueryOptions.minConfidence * 100) : undefined,
        maxAge: searchQueryOptions.maxAge,
        primaryPositions: searchQueryOptions.primaryPositions,
        positionGroup: searchQueryOptions.positionGroup,
        leagues: searchQueryOptions.competitions,

        clubs: searchQueryOptions.clubs,
        form: searchQueryOptions.form,
        playingTime: searchQueryOptions.playingTime,
        formAndPlayingTime: (searchQueryOptions.form ?? []).concat(searchQueryOptions.playingTime ?? []),
        countryCodes: searchQueryOptions.countryCodes,
        maxContractLength: searchQueryOptions.maxContractLength,
        includeUnknownContracts: searchQueryOptions.includeUnknownContracts ?? false,

        sortBy: searchQueryOptions.sortBy && clubSettings?.roleConfigs[searchQueryOptions.sortBy]
          ? 'role_index'
          : searchQueryOptions.sortBy,
      },
      currentUser,
      'user',
    );
  };


  const handleSearchButtonPressed = async (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter?: number,
    newOverriddenOrderBy?: string | undefined
  ) => {

    dispatch({ type: 'SET_IS_LOADING' });

    if (isUserInitiated) {
      clearNameDebounceRef.current();
    }

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * playerTableChapterSize) + 1)
        : ((state.currentChapter * playerTableChapterSize) + (state.currentModuloPage + 1)));

    const sortBy = newOverriddenOrderBy ??
      (nameSearchString.length > 0
        ? undefined
        : state.selectedOrderBy.length > 0
          ? state.selectedOrderBy[0]
          : undefined);

    const positionGroupOfSelectedRoles = state.selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[state.selectedRoles[0]]
      ? clubSettings.roleConfigs[state.selectedRoles[0]].positionGroup
      : null;

    const queryOptions: PlayerOverviewsQueryOptions = {
      name: nameSearchString,
      minConfidence: state.selectedMinConfidence.length > 0 ? (parseInt(state.selectedMinConfidence[0]) / 100) : undefined,
      maxAge: state.selectedMaxAge.length > 0 ? parseInt(state.selectedMaxAge[0]) : undefined,
      primaryPositions: state.selectedPositions.length > 0 ? state.selectedPositions : undefined,
      positionGroup: positionGroupOfSelectedRoles,
      competitions: state.selectedLeagues.length > 0 ? state.selectedLeagues.map(league => parseInt(league)) : undefined,
      sortBy: sortBy,

      clubs: state.selectedClubs.length > 0 ? state.selectedClubs.map(club => club.id) : undefined,
      form: state.selectedForm.length > 0 ? state.selectedForm : undefined,
      playingTime: state.selectedPlayingTime.length > 0 ? state.selectedPlayingTime : undefined,
      countryCodes: state.selectedNationalities.length > 0 ? state.selectedNationalities : undefined,
      maxContractLength: state.selectedContractStatus.length > 0 ? contractKeysToMonths[state.selectedContractStatus[0]] : undefined,
      includeUnknownContracts: state.isContractStatusToggled,

      page: nextPageToQuery,
      pageSize: playerTablePageSize,
    };

    const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(
      queryOptions,
      currentUser,
      nameSearchHistory,
      userConfig?.email,
      userConfig?.club,
    );

    if (!result) {
      // todo: handle error?
      dispatch({ type: 'RESET_STATE' });
      return;
    }

    handleTrackEvent(isUserInitiated, isNewSearch, nextChapter, queryOptions, result.total_hits);

    setCurrentNameSearchString(nameSearchString);
    dispatch({
      type: 'SET_SEARCH_RESULT',
      payload: {
        result: result,
        isNewSearch: isNewSearch,
        nextChapter: nextChapter,
        newOverriddenOrderBy: newOverriddenOrderBy,
      }
    });
  };


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    dispatch({ type: 'SET_EMPTY_TABLE_DATA' });
    const nextChapter = isIncrement ? state.currentChapter + 1 : state.currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  // when a filter is de-expanded, we check if the selected filters have changed, and if so, trigger a new search
  useEffect(() => {
    // only trigger a new search if the filter is de-expanded and search is active
    const searchIsActive = state.tableData.length > 0 || state.isEmptySearchResult;
    if (!state.currentFilterExpanded && state.previousFilterExpanded && searchIsActive) {

      let shouldTriggerSearch = false;
      let rolesHaveChanged = false;

      switch (state.previousFilterExpanded) {
        case 'positionsAndRoles':
          rolesHaveChanged = !stringArraysContainSameElements(state.currentRoles, state.selectedRoles);
          shouldTriggerSearch = rolesHaveChanged || !stringArraysContainSameElements(state.currentPositions, state.selectedPositions);
          break;

        case 'minConfidence':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMinConfidence, state.selectedMinConfidence);
          break;

        case 'maxAge':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMaxAge, state.selectedMaxAge);
          break;

        case 'leagues':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentLeagues, state.selectedLeagues);
          break;

        case 'orderBy':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentOrderBy, state.selectedOrderBy);
          break;

        case 'clubs':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentClubs.map(c => String(c.id)), state.selectedClubs.map(c => String(c.id)));
          break;

        case 'form':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentForm, state.selectedForm);
          break;

        case 'playingTime':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentPlayingTime, state.selectedPlayingTime);
          break;

        case 'nationalities':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentNationalities, state.selectedNationalities);
          break;

        case 'contractStatus':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentContractStatus, state.selectedContractStatus)
            || state.currentIsContractStatusToggled !== state.isContractStatusToggled;
          break;
      }

      let newOverriddenOrderBy: string | undefined = undefined;

      // some changes to the selected roles will automatically set the orderBy
      if (rolesHaveChanged) {

        // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
        // we only want this if there are currently no selected roles - if there are, and the orderBy is not a role, the orderBy was manually set
        if (
          state.currentRoles.length === 0
          && state.selectedRoles.length > 0
          && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skillIndex')
        ) {
          const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
          newOverriddenOrderBy = lastSelectedRole;
        }

        // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
        else if (
          state.selectedOrderBy.length > 0
          && clubSettings?.roleConfigs[state.selectedOrderBy[0]]
          && !state.selectedRoles.includes(state.selectedOrderBy[0])
        ) {
          if (state.selectedRoles.length > 0) {
            newOverriddenOrderBy = state.selectedRoles[0];
          }
          else {
            newOverriddenOrderBy = 'skillIndex';
          }
        }
      }

      if (newOverriddenOrderBy) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [newOverriddenOrderBy] });
      }

      if (shouldTriggerSearch) {
        handleSearchButtonPressed(false, true, undefined, newOverriddenOrderBy);
      }
    }
  }, [state.currentFilterExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  // if search is not active, some changes to the selected roles will automatically set the orderBy
  useEffect(() => {
    if (state.tableData.length > 0 || state.isEmptySearchResult) return;

    // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
    if (
      state.selectedRoles.length > 0
      && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skillIndex')
    ) {
      const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
      dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [lastSelectedRole] });
    }

    // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
    if (
      state.selectedOrderBy.length > 0
      && clubSettings?.roleConfigs[state.selectedOrderBy[0]]
      && !state.selectedRoles.includes(state.selectedOrderBy[0])
    ) {
      if (state.selectedRoles.length > 0) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [state.selectedRoles[0]] });
      }
      else {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: ['skillIndex'] });
      }
    }
  }, [state.selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (state.searchWasLoaded) {
      handleSearchButtonPressed(true, true);
    }
  }, [state.searchWasLoaded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (nameSearchString.length === 0) {
      setCurrentNameSearchString('');
      setDebouncedNameSearchString('');
      dispatch({ type: 'RESET_SEARCH_STATE' });
      // if (anyFilterExceptNameAndDefaultOrderBySelected(state)) {
      //   handleSearchButtonPressed(false, true);
      // }
      // else {
      //   setCurrentNameSearchString('');
      //   setDebouncedNameSearchString('');
      //   dispatch({ type: 'RESET_STATE' });
      // }
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (nameSearchString !== debouncedNameSearchString) {
        setDebouncedNameSearchString(nameSearchString);
      }
    }, 600);

    debounceFilter();

    clearNameDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [nameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedNameSearchString !== '' && debouncedNameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(false, true);
    }
  }, [debouncedNameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='full-size-container'>

      {state.currentFilterExpanded &&
        <div className='filter-section-empty-background' onClick={() => dispatch({ type: 'RESET_FILTER_EXPANSION' })} />
      }

      <PlayerScoutingFilters
        state={state}
        dispatch={dispatch}
        nameSearchString={nameSearchString}
        setNameSearchString={setNameSearchString}
        onKeyDownNameSearchField={onKeyDownNameSearchField}
        onNameSearchHistoryClick={(name: string) => { setNameSearchString(name); setDebouncedNameSearchString(name); }}
      />

      <div
        className='scouting-view-result-container'
        style={{ top: state.scoutingViewResultContainerTop }}
      >
        {state.tableData.length > 0 && (
          <PlayerTable
            data={state.tableData}
            isLoading={state.isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={state.currentModuloPage}
            currentChapter={state.currentChapter}
            totalHits={state.totalHits}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            selectedOrderBy={state.selectedOrderBy.length > 0 ? state.selectedOrderBy[0] : undefined}
            selectedRoles={state.selectedRoles}
          />
        )}

        {state.tableData.length === 0 && state.isEmptySearchResult && !state.isLoading && (
          <div className='scouting-info-title fade-in'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {state.tableData.length === 0 && !state.isEmptySearchResult && !state.isLoading && (
          <TextButton
            onClick={() => handleSearchButtonPressed(true, true)}
            text={translate('searchForPlayers', userConfig?.language)}
            icon={<SearchIcon style={{ fontSize: 22 }} />}
            buttonType={'dark'}
            isRound={true}
            style={{ height: 40, fontSize: 15, gap: 30 }}
          />
        )}

      </div>
    </div>
  );
};
