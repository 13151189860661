import '../../platform.css';
import './accordions.css';

import { useState } from 'react';
import { useAuthContext } from '../../../common/contexts/useAuthContext';
import { trackEvent } from '../../services/server/analytics/trackEvent';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { FormOrPlayingTimeTable } from '../display/visualization/FormOrPlayingTimeTable';
import { PositionGroupsTable } from '../display/visualization/PositionGroupsTable';


interface SupportAccordionProps {
  title: string;
  englishTitle: string;
  text: string[];
}


export const SupportAccordion: React.FC<SupportAccordionProps> = ({
  title,
  englishTitle,
  text,
}) => {

  const { currentUser } = useAuthContext();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleAccordionClick = () => {

    if (!isExpanded) {
      trackEvent('SupportAccordionExpanded', { englishTitle: englishTitle }, currentUser, 'user');
    }

    const element = document.getElementById('accordion-' + title);
    if (element) {
      if (isExpanded) {
        element.style.height = '42px';
      }
      else {
        // element.scrollIntoView({ behavior: 'smooth' });
        element.style.height = element.scrollHeight + 'px';
      }
    }
    setIsExpanded(!isExpanded);
  };


  return (
    <div
      id={'accordion-' + title}
      className={'support-accordion-container' + (isExpanded ? ' support-accordion-container-expanded' : '')}
      onClick={() => handleAccordionClick()}
    >
      <div className='support-accordion-title'>
        {title}
      </div>

      <div className={'support-accordion-text' + (isExpanded ? ' support-accordion-text-expanded' : '')}>
        {text.map((line, lineIndex) => {

          const lineList = line.split(' _ ');

          if (lineList.length > 1) {
            return (
              <div key={lineIndex} style={{ marginTop: 30 }}>
                <div style={{ marginBottom: 8, fontWeight: 500 }}>
                  {lineList[0]}
                </div>

                {lineList[1].split('\n').map((subline, subIndex) => {

                  const isFormTable = subline === '<FormTable/>';
                  const isPlayingTimeTable = subline === '<PlayingTimeTable/>';

                  if (isFormTable || isPlayingTimeTable) {
                    return (
                      <div
                        key={lineIndex}
                        style={{
                          position: 'relative',
                          marginTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <FormOrPlayingTimeTable isFormTable={isFormTable} />
                      </div>
                    );
                  }

                  const subLineList = subline.split('<link>');

                  if (subLineList.length === 1) {
                    return (
                      <div key={lineIndex + '-' + subIndex} style={{ marginBottom: 10 }}>
                        {subline}
                      </div>
                    );
                  }

                  return (
                    <div key={lineIndex + '-' + subIndex} style={{ marginBottom: 10 }}>
                      {subLineList[0]}
                      <a
                        href={subLineList[1]}
                        className='text-no-wrap'
                        style={{
                          color: '#8bd3ff',
                          fontWeight: 500,
                          textDecoration: 'none',
                        }}
                        target='_blank'
                        rel='noreferrer'
                        onClick={(event) => event.stopPropagation()}
                      >
                        {subLineList[1]}
                      </a>
                    </div>
                  );
                })}
              </div>
            );
          }

          if (lineList[0] === '<PositionGroupsTable/>') {
            return (
              <div
                key={lineIndex}
                style={{
                  position: 'relative',
                  marginTop: 25,
                  width: 550,
                  height: 295,
                }}
              >
                <PositionGroupsTable />
              </div>
            );
          }

          return (
            <div key={lineIndex} style={{ marginTop: 16 }}>
              {lineList[0].split('\n').map((subline, subIndex) => {
                return (
                  <div key={subIndex} style={{ marginBottom: 6 }}>
                    {subline}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div className={'support-accordion-icon-container' + (isExpanded ? ' accordion-icon-container-rotated' : '')}>
        {<KeyboardArrowDownIcon style={{ fontSize: 20 }} />}
      </div>
    </div>
  );
};
