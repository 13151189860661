import '../mobilePlatform.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import { Dialog } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { ViewContainerBackgroundSvg } from '../../../assets/svg/ViewContainerBackgroundSvg';
import { trackEvent } from '../../services/server/analytics/trackEvent';
import { updateUserConfig } from '../../services/firestore/users';
import { AccountModal } from '../../components/modals/accounModals/AccountModal';
import { IconButton } from '../../components/controls/buttons/IconButton';
import { modalPaperProps } from '../../components/modals/globalModals/GlobalModals';
import { supportedLanguages, translate } from '../../../common/language/translations';
import { CountryFlagButton } from '../../components/display/images/CountryFlagButton';
import { TextButton } from '../../components/controls/buttons/TextButton';
import { MobileHeader } from '../mobileComponents/MobileHeader';


interface MobileAccountProps {
  noAccessView?: boolean;
  noMobileAccessView?: boolean;
  maintenanceMessage?: string; // if provided, the platform is under maintenance
}

export const MobileAccount: React.FC<MobileAccountProps> = ({
  noAccessView,
  noMobileAccessView,
  maintenanceMessage,
}) => {

  const { currentUser, logout, resetPassword } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);

  const isFokusAccount = userConfig?.email.split('@')[0] === 'fokus';
  const [isEditFokusAccessModalOpen, setIsEditFokusAccessModalOpen] = useState(false);

  const [message, setMessage] = useState<string | undefined>(undefined);


  const handleSignOut = async () => {
    trackEvent('UserLoggedOut', {}, currentUser, 'user');
    await logout();
  };


  const handleResetPassword = async () => {
    if (userConfig) {
      setMessage(undefined);

      trackEvent('PasswordResetRequested', {}, currentUser, 'user');
      await resetPassword(userConfig.email);

      setMessage('emailSent');
    }
  };


  const handleFlagClick = (language: string) => {
    if (userConfig && language !== userConfig.language) {
      updateUserConfig(userConfig.email, 'language', language, 'NewLanguageSelected', currentUser);
    }
  };


  const isLimitedView = noAccessView || noMobileAccessView || maintenanceMessage;

  return (
    <div className='full-size-container'>

      <div className='mobile-home-view-background'>
        <ViewContainerBackgroundSvg />
      </div>

      <Dialog
        open={isEditFokusAccessModalOpen}
        onClose={() => setIsEditFokusAccessModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <AccountModal
          user={userConfig ?? undefined}
          closeModal={() => setIsEditFokusAccessModalOpen(false)}
          isFokusAccount={true}
          isMobile={true}
        />
      </Dialog>

      <MobileHeader tab={'account'} />

      <div className='mobile-platform-view-section'>
        <div className='mobile-platform-view-content-container'>

          <div className='mobile-account-view-section'>
            <div className='mobile-account-view-name-row'>
              {isFokusAccount && (
                <IconButton
                  onClick={() => setIsEditFokusAccessModalOpen(true)}
                  icon={<MoreVertIcon style={{ fontSize: 26 }} />}
                  size={34}
                  style={{ position: 'absolute', left: -60 }}
                  isMobile={true}
                />
              )}

              {userConfig?.name ?? ''}
            </div>

            <div className='mobile-account-view-email-row'>
              {userConfig?.email ?? ''}
            </div>
          </div>

          {isLimitedView && (
            <div className='mobile-account-view-section'>
              {noAccessView && (
                <div className='mobile-account-no-access-row'>
                  {translate('noAccessInfo', userConfig?.language)}
                </div>
              )}

              {noMobileAccessView && (
                <div className='mobile-account-no-access-row'>
                  {/* todo: once the mobile platform is ready, this should be changed to "you dont have mobile access" */}
                  {translate('mobilePlatformIsUnderDevelopment', userConfig?.language)}
                </div>
              )}

              {maintenanceMessage && (
                <div className='mobile-account-no-access-row'>
                  {maintenanceMessage}
                </div>
              )}
            </div>
          )}

          <div className='mobile-account-view-section'>
            <div className='mobile-account-view-language-row'>
              {supportedLanguages.map((language) => (
                <CountryFlagButton
                  key={language}
                  language={language}
                  isSelected={userConfig?.language === language}
                  onClick={() => handleFlagClick(language)}
                  width={32}
                />
              ))}
            </div>
          </div>

          <div className='mobile-account-view-section'>
            <div className='mobile-account-view-message-row'>
              {message && <CheckCircleIcon style={{ fontSize: 20 }} />}
              {message && translate(message, userConfig?.language)}
            </div>

            {!isLimitedView && (
              <TextButton
                onClick={() => handleResetPassword()}
                text={translate('resetPassword', userConfig?.language)}
                icon={<ReplayIcon style={{ fontSize: 22 }} />}
                isMobile={true}
              />
            )}

            <TextButton
              onClick={() => handleSignOut()}
              text={translate('logout', userConfig?.language)}
              icon={<LogoutIcon style={{ fontSize: 22, marginRight: -1 }} />}
              isMobile={true}
            />
          </div>

        </div>
      </div>
    </div>
  );
};
