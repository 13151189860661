import { ClauseState, SubClauseState } from '../components/documents/clause/clauseState';
import { getDisplayEconomicValue } from './currencyUtils';
import { deepCloneObject } from './utils';
import {
  ClausePayment,
  Club,
  ClubMap,
  DatedValue,
  MatchDetails,
  PlayerCareer,
  PlayerClause,
  PlayerClubIteration,
  PlayerSubClause,
  SeasonCondition,
  SeasonOrCondition,
  SeasonSubCondition,
  StringToAnyMap,
  TotalCondition
} from '../types';
import { translate } from '../../common/language/translations';


// Get the representation to store in firestore
export const getSeasonConditionWithoutCurrentValues = (seasonCondition: SeasonCondition) => {
  const seasonConditionCopy = deepCloneObject(seasonCondition);
  delete seasonConditionCopy.seasonToCurrentValues;
  return seasonConditionCopy;
};


// Get the representation to store in firestore
export const getTotalConditionWithoutCurrentValue = (totalCondition: TotalCondition) => {
  const totalConditionCopy = deepCloneObject(totalCondition);
  delete totalConditionCopy.currentValue;
  return totalConditionCopy;
};


// Get the representation to store in firestore
export const getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees = (subClause: SubClauseState): PlayerSubClause => {
  const subClauseCopy = {
    ...subClause,
    totalConditions: subClause.totalConditions.map(getTotalConditionWithoutCurrentValue),
    seasonConditions: subClause.seasonConditions.map(getSeasonConditionWithoutCurrentValues),
  };
  delete subClauseCopy.nextClubId;
  delete subClauseCopy.conditionalFees;
  return subClauseCopy;
};


// Get the currency of the given payment
export const getPaymentCurrency = (payment: ClausePayment, state: ClauseState) => {
  const currency = payment.subClauseIndex !== undefined && state.subClauses && state.subClauses.length >= payment.subClauseIndex
    ? state.subClauses[payment.subClauseIndex].currency
    : state.currency;
  return currency;
};


// Get the title of the payment
export const getPaymentTitle = (payment: ClausePayment, language: string) => {
  if (payment.conditionType === 'sellOn') {
    return translate('sellOn', language);
  }

  if (payment.conditionType === 'season') {
    return translate('season', language) + ' ' + payment.seasonString;
  }

  if (payment.conditionType === 'total' && payment.totalCondition) {
    return payment.totalConditionThreshold + ' ' + translate(payment.totalCondition, language, true);
  }

  return translate('unconditioned', language);
};


// Get the display value of an economic value
export const getDisplayPaymentAmount = (payment: ClausePayment, state: ClauseState, withoutSuffix?: boolean) => {

  const currency = getPaymentCurrency(payment, state);

  let amount = payment.amount;

  if (payment.subClauseIndex !== undefined) {
    const discountFactor = state.subClauses && state.subClauses.length >= payment.subClauseIndex
      ? state.subClauses[payment.subClauseIndex].totalSellOnPercentage / 100
      : 1;
    const discountedAmount = Math.round(payment.amount * discountFactor);
    amount = discountedAmount;
  }

  if (currency || withoutSuffix) {
    return getDisplayEconomicValue(amount);
  }

  return getDisplayEconomicValue(amount, true);
};


// Get the current sell on percentage, based on the date today, from the dynamic sell on percentages
export const getCurrentSellOnPercentage = (dynamicSellOnPercentages: DatedValue[]) => {
  const currentDate = new Date();

  let currentSellOn = 0;
  dynamicSellOnPercentages.forEach(dynamicSellOnPercentage => {
    if (new Date(dynamicSellOnPercentage.date) <= currentDate) {
      currentSellOn = dynamicSellOnPercentage.value;
    }
  });

  return currentSellOn;
};


// Get the year of the first next season after the transfer date, for full-year and cross-year seasons
export const getYearOfFirstNextSeason = (transferDate: Date, isCrossYearSeason: boolean): number => {
  const month = transferDate.getMonth();
  const year = transferDate.getFullYear();

  if (isCrossYearSeason) {
    // if the transfer happens in May (index 4) or later, we assume the first next season starts after that summer
    return month < 4
      ? year - 1
      : year;
  }

  // if the transfer happens in November (index 10) or later, we assume the first next season starts next year
  return month < 10
    ? year
    : year + 1;
};


// Get the approximate start date of a season based on the season string
export const getSeasonStartDate = (seasonString: string): Date => {
  const seasonStringArray = seasonString.split('/');
  let year = seasonStringArray[0];
  let month = 1;
  if (seasonStringArray.length === 2) {
    month = 7;
    year = '20' + year;
  }
  return new Date(Number(year), month, 1);
};


// Check if a sub condition is reached according to the data
const isSubConditionReached = (
  subCondition: SeasonSubCondition,
  seasonStartedOverAYearAgo: boolean,
  seasonData: StringToAnyMap | undefined
) => {

  if (subCondition.condition === 'other') return seasonStartedOverAYearAgo;

  if (!seasonData) return false;

  if (subCondition.condition === 'tablePosition') {
    const threshold = Number(subCondition.threshold);
    const position = seasonData.tablePosition;

    if (position === undefined) return false;

    const leagueIsOver = seasonData.leagueMatchesPlayed >= seasonData.totalPossibleLeagueMatches;
    return position <= threshold && leagueIsOver;
  }

  const isPercentage =
    (
      subCondition.condition === 'matchesPlayed'
      || subCondition.condition === 'matchesStarted'
      || subCondition.condition === 'minutesPlayed'
    ) && subCondition.threshold.toString().includes('%');

  // if minMinutesPlayed is set, the seasonData accessor will be matchesPlayed<minMinutesPlayed>
  const accessor = subCondition.minMinutesPlayed
    ? (subCondition.condition + subCondition.minMinutesPlayed)
    : subCondition.condition;

  const absoluteValue = seasonData[accessor] ?? 0;
  const possibleValue = isPercentage
    ? accessor === 'minutesPlayed'
      ? seasonData['totalPossibleMinutes']
      : seasonData['totalPossibleMatches']
    : undefined;

  const currentValue = isPercentage
    ? Math.round((absoluteValue / possibleValue) * 100)
    : absoluteValue;

  const maxThreshold = Number(subCondition.threshold.toString().replace('%', ''));
  const isReached = currentValue >= maxThreshold && (!isPercentage || seasonStartedOverAYearAgo);

  return isReached;
};


// Decides if a notification should be displayed for a season condition
// - a notification is displayed if all common subconditions and all subconditions of one orCondition are either achieved or potentially reached
// - a subcondition is potentially reached if either the data suggest it is achieved, or if the season is over and the data cannot tell if it is achieved
export const allSubConditionsAreAchievedOrPotentiallyReachedForSeason = (
  seasonCondition: SeasonCondition,
  seasonString: string,
  seasonStartedOverAYearAgo: boolean
) => {

  // if the maxAmount is reached, we dont want to show any notification
  if (seasonCondition.maxAmount !== undefined && (seasonCondition.totalResolvedAmount ?? 0) >= seasonCondition.maxAmount) return false;

  const seasonData = (seasonCondition.seasonToCurrentValues ?? {})[seasonString];
  const seasonStatus = seasonCondition.seasonToCurrentStatuses[seasonString];
  const commonSubConditionStatues = (seasonStatus ?? {})['commonSubConditionStatuses'];
  const orConditionStatuses = (seasonStatus ?? {})['orConditionStatuses'];

  const allSubConditionsAreAchievedOrPotentiallyReached = seasonCondition.commonSubConditions && seasonCondition.commonSubConditions.every(
    (commonSubCondition, commonSubConditionIndex) => {

      const subConditionStatus = commonSubConditionStatues ? commonSubConditionStatues[commonSubConditionIndex] : null;

      if (subConditionStatus === 'achieved') return true;
      if (subConditionStatus === 'notAchieved') return false;

      return isSubConditionReached(commonSubCondition, seasonStartedOverAYearAgo, seasonData);
    }
  );

  if (seasonCondition.commonSubConditions && !allSubConditionsAreAchievedOrPotentiallyReached) return false;

  if (seasonCondition.orConditions) {

    const atLeastOneOrConditionIsAchievedOrPotentiallyReached = seasonCondition.orConditions.some((orCondition, orConditionIndex) => {

      const orConditionStatus = orConditionStatuses ? orConditionStatuses[orConditionIndex] : null;

      if (orConditionStatus === 'achieved') return true;
      if (orConditionStatus === 'notAchieved') return false;

      return orCondition.subConditions.every(orSubCondition => {
        return isSubConditionReached(orSubCondition, seasonStartedOverAYearAgo, seasonData);
      });
    });

    return atLeastOneOrConditionIsAchievedOrPotentiallyReached;
  }

  return true;
};


// Add relevant data to a sub clause, or to the main clause if subClauseIndex is undefined
export const addSubClauseData = (
  clause: PlayerClause,
  subClauseIndex: number | undefined,
  playerClubIterationArray: PlayerClubIteration[],
  clubs: ClubMap,
): [boolean, number | undefined] => {

  const subClauseAccessor: StringToAnyMap = subClauseIndex !== undefined && clause.subClauses
    ? clause.subClauses[subClauseIndex]
    : clause;

  const transferDate = new Date(subClauseAccessor.transferDate);
  const firstFullYearSeason = getYearOfFirstNextSeason(transferDate, false);
  const firstCrossYearSeason = getYearOfFirstNextSeason(transferDate, true);
  const sellingClubId = subClauseAccessor.sellingClubId;
  const buyingClubId = subClauseAccessor.buyingClubId;

  // iterate through all seasons and collect relevant player data
  let showNotification = false;
  let likelyNextClubId: number | undefined = undefined;

  const incorrectNextClubIds: number[] = [...subClauseAccessor.incorrectNextClubIds ?? []];
  const incorrectClubIdToIncorrectSeasons: StringToAnyMap = {};

  // we want to start collecting data from the first occurence of the buying club after the transfer date, and stop when a new club is detected
  let hasFoundBuyingClub = false;

  const competitionToSeasonToMatchDetails: StringToAnyMap = {};
  playerClubIterationArray.forEach(clubIteration => {

    const clubThisSeason: Club = clubIteration.club;

    const seasonString = clubIteration.season;
    const seasonStringArray = seasonString.split('/');
    const isCrossYearSeason = seasonStringArray.length === 2;

    let seasonYear = seasonStringArray[0];
    if (isCrossYearSeason) {
      seasonYear = '20' + seasonYear;
    }
    const seasonYearNumber = Number(seasonYear);

    // only consider seasons after the transfer date
    if ((isCrossYearSeason && seasonYearNumber < firstCrossYearSeason) || (!isCrossYearSeason && seasonYearNumber < firstFullYearSeason)) return;

    // only consider seasons for other clubs than the selling club
    if (!clubThisSeason || clubThisSeason.id === sellingClubId) return;

    // if likelyNextClub exists, the clause is likely completed and all relevant data has been collected, unless the player is on loan to likelyNextClub
    if (likelyNextClubId === undefined) {
      if ((clubThisSeason.id === buyingClubId || clubThisSeason.is_national_team)) {

        if (clubThisSeason.id === buyingClubId) {
          hasFoundBuyingClub = true;
        }

        // match_deatils should now always exists, but we keep this check just in case
        if (clubIteration.match_details) {

          // special handling for european cups where qualifiers are extracted into their own virtual competition European Qualifiers with id 100000
          let competitionIdList: number[] = [];
          let competitionMatchDetailsList: StringToAnyMap[][] = [];
          if (clubIteration.competition_id === 184 || clubIteration.competition_id === 185 || clubIteration.competition_id === 202) {
            competitionIdList = [
              clubIteration.competition_id,
              100000,
            ];
            competitionMatchDetailsList = [
              clubIteration.match_details.filter(matchDetails => matchDetails.round.toLowerCase()[0] !== 'q'),
              clubIteration.match_details.filter(matchDetails => matchDetails.round.toLowerCase()[0] === 'q'),
            ];
          }
          else {
            // special handling for national teams
            const competitionId = clubThisSeason.is_national_team
              ? clubThisSeason.name ? -2 : -1 // if name exist, this is a youth national team mapping (id -2), else it is the national team (id -1)
              : clubIteration.competition_id;

            competitionIdList = [competitionId];
            competitionMatchDetailsList = [clubIteration.match_details];
          }

          for (let i = 0; i < competitionIdList.length; i++) {
            const competitionId = competitionIdList[i];
            const competitionMatchDetails = competitionMatchDetailsList[i];

            if (competitionId in competitionToSeasonToMatchDetails) {
              // since national competition ids are merged into virtual ids, the seasonString may already exist
              let seasonData = competitionToSeasonToMatchDetails[competitionId]['seasonData'][seasonString] ?? [];
              seasonData = [...seasonData, ...competitionMatchDetails];

              competitionToSeasonToMatchDetails[competitionId]['seasonData'][seasonString] = seasonData;
            }
            else {
              competitionToSeasonToMatchDetails[competitionId] = {
                seasonData: {
                  [seasonString]: competitionMatchDetails
                },
                isCrossYearCompetition: isCrossYearSeason,
              };
            }
          }
        }
      }
      else {
        const shouldExcludeSeason = (incorrectClubIdToIncorrectSeasons[clubThisSeason.id] ?? []).includes(seasonString);
        if (!shouldExcludeSeason) {
          if (incorrectNextClubIds.includes(clubThisSeason.id)) {
            // if season is 22/23 (for example), we exclude 22/23 and 2023 seasons
            // if season is 2022 (for example), we exclude 2022 and 22/23 seasons
            const newSupersetSeasonStrings = isCrossYearSeason
              ? [seasonString, (Number(seasonYear) + 1) + '']
              : [seasonString, seasonString.substring(2, 4) + '/' + (Number(seasonString.substring(2, 4)) + 1)];

            const existingIncorrectSeasons = incorrectClubIdToIncorrectSeasons[clubThisSeason.id] ?? [];
            incorrectClubIdToIncorrectSeasons[clubThisSeason.id] = [...existingIncorrectSeasons, ...newSupersetSeasonStrings];

            // remove only the first occurence of a season for the club from incorrectNextClubIds, as the player might actually tranfser to this club later
            const index = incorrectNextClubIds.findIndex((clubId: number) => clubId === clubThisSeason.id);
            if (index !== -1) {
              incorrectNextClubIds.splice(index, 1);
            }
          }
          else if (hasFoundBuyingClub) {
            likelyNextClubId = clubThisSeason.id;
          }
        }
      }
    }
  });

  // compute condition-statuses for each condition based on the collected player data
  subClauseAccessor.totalConditions.forEach((totalCondition: TotalCondition) => {

    let currentValue = totalCondition.overriddenValue?.value ?? 0;

    Object.keys(competitionToSeasonToMatchDetails).forEach((competitionId: string) => {
      if (totalCondition.competitionIds.includes(Number(competitionId))) {
        const competitionData = competitionToSeasonToMatchDetails[competitionId];

        Object.keys(competitionData.seasonData).forEach((season: string) => {
          const seasonData = competitionData.seasonData[season];

          if (!seasonData) return;

          seasonData.forEach((matchDetails: MatchDetails) => {

            // always only consider matches after the transfer date
            if (matchDetails.match_date < subClauseAccessor.transferDate) return;

            // if overriddenValue is set, we only consider matches after the overridden date
            if (totalCondition.overriddenValue && matchDetails.match_date < totalCondition.overriddenValue.date) return;

            // if minMinutesPlayed is set, we only consider matches with at least minMinutesPlayed minutes played
            if (totalCondition.minMinutesPlayed && matchDetails.minutes_played < totalCondition.minMinutesPlayed) return;

            else if (totalCondition.condition === 'matchesStarted' && matchDetails.started_game) currentValue++;
            else if (totalCondition.condition === 'matchesPlayed' && matchDetails.minutes_played > 0) currentValue++;
            else if (totalCondition.condition === 'minutesPlayed') currentValue += (matchDetails.minutes_played ?? 0);
            else if (totalCondition.condition === 'goalsScored') currentValue += (matchDetails.n_goals ?? 0);
          });
        });
      }
    });

    totalCondition.currentValue = currentValue;
  });

  // create seasonToLeagueTableDataMap for buying club
  const seasonToLeagueTableDataMap: StringToAnyMap = {};
  const tableHistory: StringToAnyMap[] = clubs[buyingClubId]?.table_position_history ?? [];
  tableHistory.forEach((tableData: StringToAnyMap) => {
    seasonToLeagueTableDataMap[tableData.season] = {
      competitionId: tableData.competition_id,
      tablePosition: tableData.rank,
      leagueMatchesPlayed: tableData.matches,
      totalPossibleLeagueMatches: (tableData.n_clubs_in_league - 1) * 2,
      totalTeamsInLeague: tableData.n_clubs_in_league,
    };
  });

  subClauseAccessor.seasonConditions.forEach((seasonCondition: SeasonCondition) => {

    // there may be multiple minMinutesPlayed for the season condition, and and we must compute matches played for all of them
    const minMinutesPlayedList: number[] = [];
    if (seasonCondition.commonSubConditions) {
      seasonCondition.commonSubConditions.forEach((commonSubCondition: SeasonSubCondition) => {
        if (commonSubCondition.minMinutesPlayed) {
          minMinutesPlayedList.push(commonSubCondition.minMinutesPlayed);
        }
      });
    }
    if (seasonCondition.orConditions) {
      seasonCondition.orConditions.forEach((orCondition: SeasonOrCondition) => {
        orCondition.subConditions.forEach((subCondition: SeasonSubCondition) => {
          if (subCondition.minMinutesPlayed) {
            minMinutesPlayedList.push(subCondition.minMinutesPlayed);
          }
        });
      });
    }

    const seasonConditionIsCrossYear = seasonCondition.seasonStrings[0].includes('/');
    const seasonToCurrentValues: StringToAnyMap = {};
    seasonCondition.seasonStrings.forEach((season: string) => {

      let leagueTableDataMap = seasonToLeagueTableDataMap[season];
      if (!leagueTableDataMap || !seasonCondition.competitionIds.includes(leagueTableDataMap.competitionId)) {
        leagueTableDataMap = {};
      }

      const subConditionToCurrentValue: StringToAnyMap = {
        goalsScored: 0,
        matchesPlayed: 0,
        matchesStarted: 0,
        minutesPlayed: 0,
        totalPossibleMatches: 0,
        totalPossibleMinutes: 0,
        tablePosition: leagueTableDataMap.tablePosition ?? undefined,
        leagueMatchesPlayed: leagueTableDataMap.leagueMatchesPlayed ?? undefined,
        totalPossibleLeagueMatches: leagueTableDataMap.totalPossibleLeagueMatches ?? undefined,
        totalTeamsInLeague: leagueTableDataMap.totalTeamsInLeague ?? undefined,
      };

      minMinutesPlayedList.forEach((minMinutesPlayed: number) => {
        subConditionToCurrentValue['matchesPlayed' + minMinutesPlayed] = 0;
        subConditionToCurrentValue['matchesStarted' + minMinutesPlayed] = 0;
      });

      Object.keys(competitionToSeasonToMatchDetails).forEach((competitionId: string) => {
        if (seasonCondition.competitionIds.includes(Number(competitionId))) {
          const competitionData = competitionToSeasonToMatchDetails[competitionId];

          // some full-year season conditions may include cross-year seasons, i.e. Norwegian clubs playing European cups (22/23) in the 2022 season
          // if condition is full-year (at least one full-year competition selected) and this competition is cross-year, we adjust the season key to cross-year
          let seasonKey = season;
          const competitionIsCrossYear = competitionData.isCrossYearCompetition;
          if (!seasonConditionIsCrossYear && competitionIsCrossYear) {
            const seasonYearShort = season.substring(2, 4);
            seasonKey = seasonYearShort + '/' + (Number(seasonYearShort) + 1);
          }

          // extract season data for this competition
          if (seasonKey in competitionData.seasonData) {
            const seasonData = competitionData.seasonData[seasonKey];
            seasonData.forEach((matchDetails: MatchDetails) => {

              // always only consider matches after the transfer date
              if (matchDetails.match_date < subClauseAccessor.transferDate) return;

              subConditionToCurrentValue.totalPossibleMatches++;
              subConditionToCurrentValue.totalPossibleMinutes += matchDetails.total_minutes_in_match ?? 0;

              subConditionToCurrentValue.minutesPlayed += matchDetails.minutes_played ?? 0;
              subConditionToCurrentValue.goalsScored += matchDetails.n_goals ?? 0;

              if (matchDetails.minutes_played > 0) subConditionToCurrentValue.matchesPlayed++;
              if (matchDetails.started_game) subConditionToCurrentValue.matchesStarted++;

              // compute minMinutesPlayed for all minMinutesPlayedList
              if (minMinutesPlayedList.length > 0) {
                minMinutesPlayedList.forEach((minMinutesPlayed: number) => {
                  if (matchDetails.minutes_played >= minMinutesPlayed) {
                    subConditionToCurrentValue['matchesPlayed' + minMinutesPlayed]++;
                    if (matchDetails.started_game) subConditionToCurrentValue['matchesStarted' + minMinutesPlayed]++;
                  }
                });
              }
            });
          }
        }
      });

      seasonToCurrentValues[season] = subConditionToCurrentValue;
    });
    seasonCondition.seasonToCurrentValues = seasonToCurrentValues;
  });

  // if sell-on condition exists and is not resolved and has dynamic sell-on percentages, we compute the current sell-on percentage and add it to the condition
  if (subClauseAccessor.sellOnCondition && !subClauseAccessor.sellOnCondition.isResolved && subClauseAccessor.sellOnCondition.dynamicSellOnPercentages) {
    const currentSellOn = getCurrentSellOnPercentage(subClauseAccessor.sellOnCondition.dynamicSellOnPercentages);
    subClauseAccessor.sellOnCondition.sellOnPercentage = currentSellOn;
  }

  subClauseAccessor.nextClubId = likelyNextClubId;

  // if at least one condition will get a notification displayed, we set showNotification to true
  if (!clause.isDeleted && !clause.isResolved) {
    // sellOn notification or end-of-clause notification
    showNotification = likelyNextClubId !== undefined;

    // conditions will only have notifications if the maxConditionalFee is not reached
    // the toal conditional fees does not exist in store and must be calculated
    let conditionalFees = 0;
    clause.payments.forEach(payment => {
      if (payment.conditionType && payment.conditionType !== 'sellOn' && payment.subClauseIndex === subClauseIndex) {
        conditionalFees += payment.amount;
      }
    });

    const isMaxConditionalFeeReached = subClauseAccessor.maxConditionalFees !== null && conditionalFees >= subClauseAccessor.maxConditionalFees;

    if (!isMaxConditionalFeeReached) {
      // total condition notifications
      if (!showNotification && subClauseAccessor.totalConditions.length > 0) {
        // a notification is displayed for a total condition if the current value is greater than or equal to any unresolved threshold
        showNotification = subClauseAccessor.totalConditions.some((totalCondition: TotalCondition) => {
          return totalCondition.subConditions.some((subCondition) => {
            return !subCondition.isResolved && totalCondition.currentValue && totalCondition.currentValue >= subCondition.threshold;
          });
        });
      }

      // season condition notifications
      if (!showNotification && subClauseAccessor.seasonConditions.length > 0) {
        // a notification is displayed for a season if it is neither resolved nor discarded and if every subcondition is either achieved or potentially achieved
        // potentially achieved means that the data suggest that condition is achieved, but the user has not yet confirmed it,
        // or that the season is over and the data cannot tell whether the condition was achieved
        showNotification = subClauseAccessor.seasonConditions.some((seasonCondition: SeasonCondition) => {
          return seasonCondition.seasonStrings.some((seasonString: string) => {
            const seasonStartDate = getSeasonStartDate(seasonString);
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
            const seasonStartedOverAYearAgo = seasonStartDate < oneYearAgo;

            const overallStatus = (seasonCondition.seasonToCurrentStatuses ?? {})[seasonString]?.overallStatus;
            return !overallStatus && allSubConditionsAreAchievedOrPotentiallyReachedForSeason(seasonCondition, seasonString, seasonStartedOverAYearAgo);
          });
        });
      }
    }
  }
  return [showNotification, likelyNextClubId];
};


// Add all relevant data to the clause (could be optimized by not iterating through every season for every sub clause)
// (1) sort all season chronologically into an array
// (2) add relevant data for the main clause
// (3) iterate through all sub clauses and add relevant data to each sub clause
interface PlayerClubIterationWithSortDate extends PlayerClubIteration { sortDate?: string; }
export const addClauseData = (
  clause: PlayerClause,
  playerCareer: PlayerCareer,
  clubs: ClubMap,
): number | undefined => {

  // (1)
  const playerClubIterationArray: PlayerClubIterationWithSortDate[] = [];
  Object.keys(playerCareer).forEach((clubIterationId: string) => {
    const clubIteration: PlayerClubIterationWithSortDate = { ...playerCareer[clubIterationId] };
    if (clubIteration.basic_stats?.plot_date) {
      clubIteration['sortDate'] = clubIteration.basic_stats.plot_date;
    }
    else {
      const seasonString = clubIteration.season;
      const sortDate = getSeasonStartDate(seasonString);
      clubIteration['sortDate'] = sortDate.toISOString();
    }
    playerClubIterationArray.push(clubIteration);
  });
  playerClubIterationArray.sort((a, b) => b.sortDate && a.sortDate ? a.sortDate.localeCompare(b.sortDate) : 0);

  // (2)
  let [showNotification, likelyNextClubId] = addSubClauseData(clause, undefined, playerClubIterationArray, clubs);

  // (3)
  if (clause.subClauses) {
    clause.subClauses.forEach((_, subClauseIndex) => {
      [showNotification, likelyNextClubId] = addSubClauseData(clause, subClauseIndex, playerClubIterationArray, clubs);
    });
  }

  clause.showNotification = showNotification;

  return likelyNextClubId;
};
