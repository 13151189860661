import './login.css';

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../common/contexts/useAuthContext';

import ErrorIcon from '@mui/icons-material/Error';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';

import { FokusLogo } from '../../assets/svg/FokusLogo';
import { translate } from '../../common/language/translations';
import { ViewContainerBackgroundSvg } from '../../assets/svg/ViewContainerBackgroundSvg';


export const Login = () => {

  const { currentUser, login, resetPassword } = useAuthContext();

  const navigate = useNavigate();

  const [resetPasswordView, setResetPasswordView] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const toggleViewChange = () => {
    setError('');
    setMessage('');
    setResetPasswordView(!resetPasswordView);
  };


  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    if (emailRef.current && passwordRef.current) {
      setError('');
      event.preventDefault();

      try {
        setError('');
        setLoading(true);
        await login(emailRef.current.value, passwordRef.current.value);
      }
      catch {
        setError('invalidLoginInput');
      }

      setLoading(false);
    }
  };


  const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    if (emailRef.current) {
      setError('');
      setMessage('');
      event.preventDefault();

      try {
        setMessage('');
        setError('');
        setLoading(true);
        await resetPassword(emailRef.current.value);

        setMessage('emailSent');
      }
      catch {
        setError('invalidEmail');
      }

      setLoading(false);
    }
  };


  useEffect(() => {
    if (currentUser) {
      navigate('/platform');
    }
  }, [currentUser, navigate]);


  if (currentUser) {
    return null;
  }


  return (
    <div className='login-outer-container'>

      <div className='full-size'>
        <ViewContainerBackgroundSvg />
      </div>

      <div className='login-container'>
        <div className='login-section'>
          <div className='login-logo-section' >
            <div className='login-logo'>
              <FokusLogo />
            </div>
            <div className='login-logo-text' style={{ fontFamily: 'Montserrat' }}>
              FOKUS
            </div>
          </div >

          {resetPasswordView && (
            <div className='login-box'>

              <div className='login-space' />
              <div className='login-space' />

              <form className='login-form' onSubmit={handleResetPassword}>

                {translate('email', 'en')}
                <input id='email' className='login-input' type='email' ref={emailRef} required />

                {error && (
                  <div className='login-message'>
                    <div className='login-error-icon'>
                      <ErrorIcon style={{ fontSize: 26 }} />
                    </div>

                    {translate(error, 'en')}
                  </div>
                )}

                {message && (
                  <div className='login-message'>
                    <div className='login-error-icon'>
                      <CheckCircleIcon style={{ fontSize: 26 }} />
                    </div>
                    {translate(message, 'en')}
                  </div>
                )}

                {!error && !message && <div className='login-empty-box' />}

                <div className='login-button'>
                  <input disabled={loading} className='login-submit' type='submit' value={translate('resetPassword', 'en')} />

                  <div className='login-reset-icon'>
                    <ReplayIcon style={{ fontSize: 27 }} />
                  </div>
                </div>

              </form>

              <div className='login-space' />
              <div className='login-space' />
              <div className='login-space-small' />

              <div className='login-link' onClick={() => toggleViewChange()}>
                {translate('login', 'en')}
              </div>

            </div>
          )}

          {!resetPasswordView && (
            <div className='login-box'>

              <form className='login-form' onSubmit={handleSignIn}>

                {translate('email', 'en')}
                <input id='email' className='login-input' type='email' ref={emailRef} required />

                {translate('password', 'en')}
                <input id='password' className='login-input' type='password' ref={passwordRef} required />

                {error && (
                  <div className='login-message'>
                    <div className='login-error-icon'>
                      <ErrorIcon style={{ fontSize: 26 }} />
                    </div>
                    {translate(error, 'en')}
                  </div>
                )}

                {!error && <div className='login-empty-box' />}

                <div className='login-button'>
                  <input disabled={loading} className='login-submit' type='submit' value={translate('login', 'en')} />
                  <div className='login-icon'>
                    <ArrowRightAltIcon style={{ fontSize: 36 }} />
                  </div>
                </div>

              </form>

              <div className='login-link' onClick={() => toggleViewChange()}>
                {translate('forgotPassword?', 'en')}
              </div>

            </div>
          )}
        </div>

      </div>
    </div>
  );
};
