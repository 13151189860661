import fokus_logo from '../../assets/clubLogos/fokus_logo.png';
import ntf_logo from '../../assets/clubLogos/ntf_logo.png';
import dbudemo_logo from '../../assets/clubLogos/dbudemo_logo.png';

import glimt_logo from '../../assets/clubLogos/glimt_logo.png';
import molde_logo from '../../assets/clubLogos/molde_logo.png';
import rbk_logo from '../../assets/clubLogos/rbk_logo.png';
import fredrikstad_logo from '../../assets/clubLogos/fredrikstad_logo.png';
import odd_logo from '../../assets/clubLogos/odd_logo.png';
import ranheim_logo from '../../assets/clubLogos/ranheim_logo.png';
import brann_logo from '../../assets/clubLogos/brann_logo.png';
import viking_logo from '../../assets/clubLogos/viking_logo.png';
import lsk_logo from '../../assets/clubLogos/lsk_logo.png';
import vif_logo from '../../assets/clubLogos/vif_logo.png';
import lyn_logo from '../../assets/clubLogos/lyn_logo.png';
import sarpsborg_logo from '../../assets/clubLogos/sarpsborg_logo.png';
import stb_logo from '../../assets/clubLogos/stb_logo.png';
import haugesund_logo from '../../assets/clubLogos/haugesund_logo.png';
import godset_logo from '../../assets/clubLogos/godset_logo.png';
import bryne_logo from '../../assets/clubLogos/bryne_logo.png';
import kfum_logo from '../../assets/clubLogos/kfum_logo.png';
import kbk_logo from '../../assets/clubLogos/kbk_logo.png';
import hamkam_logo from '../../assets/clubLogos/hamkam_logo.png';
import til_logo from '../../assets/clubLogos/til_logo.png';
import sandefjord_logo from '../../assets/clubLogos/sandefjord_logo.png';
import kongsvinger_logo from '../../assets/clubLogos/kongsvinger_logo.png';
import moss_logo from '../../assets/clubLogos/moss_logo.png';
import sogndal_logo from '../../assets/clubLogos/sogndal_logo.png';
import egersund_logo from '../../assets/clubLogos/egersund_logo.png';
import start_logo from '../../assets/clubLogos/start_logo.png';
import aalesund_logo from '../../assets/clubLogos/aalesund_logo.png';
import raufoss_logo from '../../assets/clubLogos/raufoss_logo.png';
import asane_logo from '../../assets/clubLogos/asane_logo.png';
import mif_logo from '../../assets/clubLogos/mif_logo.png';
import hodd_logo from '../../assets/clubLogos/hodd_logo.png';
import skeid_logo from '../../assets/clubLogos/skeid_logo.png';

import hammarby_logo from '../../assets/clubLogos/hammarby_logo.png';
import sirius_logo from '../../assets/clubLogos/sirius_logo.png';
import goteborg_logo from '../../assets/clubLogos/goteborg_logo.png';
import aik_logo from '../../assets/clubLogos/aik_logo.png';
import hacken_logo from '../../assets/clubLogos/hacken_logo.png';
import djurgarden_logo from '../../assets/clubLogos/djurgarden_logo.png';
import elfsborg_logo from '../../assets/clubLogos/elfsborg_logo.png';
import bromma_logo from '../../assets/clubLogos/bromma_logo.png';

import agf_logo from '../../assets/clubLogos/agf_logo.png';
import brondby_logo from '../../assets/clubLogos/brondby_logo.png';
import aab_logo from '../../assets/clubLogos/aab_logo.png';
import lyngby_logo from '../../assets/clubLogos/lyngby_logo.png';
import randers_logo from '../../assets/clubLogos/randers_logo.png';
import viborg_logo from '../../assets/clubLogos/viborg_logo.png';
import b93_logo from '../../assets/clubLogos/b93_logo.png';

import ilves_logo from '../../assets/clubLogos/ilves_logo.png';
import raith_logo from '../../assets/clubLogos/raith_logo.png';
import poznan_logo from '../../assets/clubLogos/poznan_logo.png';
import gornik_logo from '../../assets/clubLogos/gornik_logo.png';
import psv_logo from '../../assets/clubLogos/psv_logo.png';
import twente_logo from '../../assets/clubLogos/twente_logo.png';
// import urawa_logo from '../../assets/clubLogos/urawa_logo.png';


type LogoShadow =

  | 'roundedSquare'
  | 'roundedSquareSmall'
  | 'roundedSquareNarrow'

  | 'circle'
  | 'circleTop'
  | 'circleTopWide'
  | 'circleTopSmall'
  | 'circleTopNarrow'
  | 'circleBottomSmall'

  | 'verticalEclipse'
  | 'verticalEclipseTop'
  | 'verticalEclipseNarrow'

  | 'shield'
  | 'shieldSmall'
  | 'shieldTiny'
  | 'shieldTall'
  | 'shieldTop'

  | 'wideDiamond'
  | 'flag'
  | 'flagSmall'

  | 'horizontalEclipse'
  | 'horizontalEclipseTall'
  | 'americanFootball'
  | 'americanFootballBottom'

  | 'viking'
  | 'haugesund'
  | 'ilves'
  | 'gornik'
  | 'elfsborg'


interface ClubConfigMap {
  color: string,
  secondaryColor: string,
  lightColor: string,
  contrastColor: string,
  useAlternativeTierColors?: boolean,

  logo: string,
  logoShadow?: LogoShadow,
  logoSize?: string, // 'x%'
  logoBackgroundColor?: string, // '#color'
  logoBorderRadius?: string, // 'apx bpx cpx dpx'
}


const fokusColor = '#ffdb65';
const fokusSecondaryColor = '#ffdb65';
const fokusLightColor = '#ffdf76';
const fokusContrastColor = '#2bffbf';
const fokusLogo = fokus_logo;

export const getClubColor = (club: string) => clubConfigs[club]?.color ?? fokusColor;
export const getClubSecondaryColor = (club: string) => clubConfigs[club]?.secondaryColor ?? fokusSecondaryColor;
export const getClubLightColor = (club: string) => clubConfigs[club]?.lightColor ?? fokusLightColor;
export const getClubContrastColor = (club: string) => clubConfigs[club]?.contrastColor ?? fokusContrastColor;
export const getUseAlternativeTierColors = (club: string) => clubConfigs[club]?.useAlternativeTierColors ?? false;

export const getClubLogo = (club: string) => clubConfigs[club]?.logo ?? fokusLogo;
export const getClubLogoShadow = (club: string) => clubConfigs[club]?.logoShadow;
export const getClubLogoSize = (club: string) => clubConfigs[club]?.logoSize;
export const getClubLogoBackgroundColor = (club: string) => clubConfigs[club]?.logoBackgroundColor;
export const getClubLogoBorderRadius = (club: string) => clubConfigs[club]?.logoBorderRadius;


export const clubConfigs: { [key: string]: ClubConfigMap } = {


  // organizations

  // 'fokus': {
  //   color: fokusColor,
  //   secondaryColor: fokusSecondaryColor,
  //   lightColor: fokusLightColor,
  //   contrastColor: fokusContrastColor,

  //   logo: fokusLogo,
  //   logoShadow: 'roundedSquare',
  // },

  'fokus-limited': {
    color: fokusColor,
    secondaryColor: fokusSecondaryColor,
    lightColor: fokusLightColor,
    contrastColor: fokusContrastColor,

    logo: fokusLogo,
    logoShadow: 'roundedSquare',
  },

  'glimtdemo': {
    color: fokusColor,
    secondaryColor: fokusSecondaryColor,
    lightColor: fokusLightColor,
    contrastColor: fokusContrastColor,

    logo: fokusLogo,
    logoShadow: 'roundedSquare',
  },

  'demo': {
    color: '#ffde00',
    secondaryColor: '#ffde00',
    lightColor: '#ffe432',
    contrastColor: fokusContrastColor,

    logo: glimt_logo,
    logoShadow: 'circle',
  },

  'norskfotball': {
    color: '#fcca70',
    secondaryColor: '#fcca70',
    lightColor: '#ffd27e',
    contrastColor: fokusContrastColor,

    logo: ntf_logo,
    logoShadow: 'circle',
  },

  'dbudemo': {
    color: '#ff686d',
    secondaryColor: '#ff686d',
    lightColor: '#ff8c90',
    contrastColor: fokusContrastColor,

    logo: dbudemo_logo,
    logoShadow: 'circle',
  },


  // norwegian clubs

  'glimt': {
    color: '#ffde00',
    secondaryColor: '#ffde00',
    lightColor: '#ffe432',
    contrastColor: fokusContrastColor,

    logo: glimt_logo,
    logoShadow: 'circle',
  },

  'molde': {
    color: '#21a3ff',
    secondaryColor: '#21a3ff',
    lightColor: '#94d2ff',
    contrastColor: fokusContrastColor,

    logo: molde_logo,
    logoShadow: 'americanFootball',
    logoSize: '95%',
  },

  'rbk': {
    color: '#43f291',
    secondaryColor: '#43f291',
    lightColor: '#43f291',
    contrastColor: '#fcca70',

    logo: rbk_logo,
    logoShadow: 'wideDiamond',
  },

  'brann': {
    color: '#ff2833',
    secondaryColor: '#ff515a',
    lightColor: '#ff787f',
    contrastColor: fokusContrastColor,

    logo: brann_logo,
    logoShadow: 'wideDiamond',
  },

  'viking': {
    color: '#e8cb6c',
    secondaryColor: '#e8cb6c',
    lightColor: '#ffdb66',
    contrastColor: fokusContrastColor,

    logo: viking_logo,
    logoShadow: 'viking',
    logoSize: '90%',
  },

  'lsk': {
    color: '#fddc02',
    secondaryColor: '#fddc02',
    lightColor: '#ffe853',
    contrastColor: fokusContrastColor,

    logo: lsk_logo,
    logoShadow: 'circle',
  },

  'godset': {
    color: '#008deb',
    secondaryColor: '#ffd12a',
    lightColor: '#ffd12a',
    contrastColor: fokusContrastColor,

    logo: godset_logo,
    logoShadow: 'circle',
  },

  'sarpsborg': {
    color: '#3e72ff',
    secondaryColor: '#7096ff',
    lightColor: '#a8bfff',
    contrastColor: fokusContrastColor,

    logo: sarpsborg_logo,
    logoShadow: 'shieldTiny',
  },

  'haugesund': {
    color: '#44a4ff',
    secondaryColor: '#44a4ff',
    lightColor: '#87c5ff',
    contrastColor: fokusContrastColor,

    logo: haugesund_logo,
    logoShadow: 'haugesund',
    logoSize: '90%',
  },

  'odd': {
    color: '#ffffff',
    secondaryColor: '#ffde00',
    lightColor: '#ffe432',
    contrastColor: fokusContrastColor,

    logo: odd_logo,
    logoShadow: 'shield',
  },

  'fredrikstad': {
    color: '#ff5f67',
    secondaryColor: '#ff5f67',
    lightColor: '#ff6971',
    contrastColor: fokusContrastColor,

    logo: fredrikstad_logo,
    logoShadow: 'flag',
    logoSize: '90%',
  },

  'kfum': {
    color: '#ff3f46',
    secondaryColor: '#ff3f46',
    lightColor: '#ff6469',
    contrastColor: fokusContrastColor,

    logo: kfum_logo,
    logoShadow: 'circle',
  },

  'kbk': {
    color: '#0073ff',
    secondaryColor: '#fcca70',
    lightColor: '#ffd78d',
    contrastColor: fokusContrastColor,

    logo: kbk_logo,
    logoShadow: 'roundedSquareSmall',
  },

  'hamkam': {
    color: '#21c859',
    secondaryColor: '#21c859',
    lightColor: '#1fd15a',
    contrastColor: '#fcca70',

    logo: hamkam_logo,
    logoShadow: 'circle',
  },

  'til': {
    color: '#ff0915',
    secondaryColor: '#d9bd6f',
    lightColor: '#ff6971',
    contrastColor: fokusContrastColor,

    logo: til_logo,
    logoShadow: 'circleTopSmall',
  },

  'sandefjord': {
    color: '#0080e9',
    secondaryColor: '#ff304c',
    lightColor: '#52b1ff',
    contrastColor: fokusContrastColor,

    logo: sandefjord_logo,
    logoShadow: 'circleTopNarrow',
  },

  'stb': {
    color: '#1184ff',
    secondaryColor: '#ffc342',
    lightColor: '#ffcd62',
    contrastColor: fokusContrastColor,

    logo: stb_logo,
    logoShadow: 'verticalEclipse',
  },

  'ranheim': {
    color: '#4c79ff',
    secondaryColor: '#7a9bff',
    lightColor: '#b1c5ff',
    contrastColor: fokusContrastColor,

    logo: ranheim_logo,
    logoShadow: 'horizontalEclipse',
  },

  'vif': {
    color: '#0e7eff',
    secondaryColor: '#4fa1ff',
    lightColor: '#ff7d82',
    contrastColor: fokusContrastColor,

    logo: vif_logo,
    logoShadow: 'horizontalEclipseTall',
  },

  'lyn': {
    color: '#ff2f39',
    secondaryColor: '#ffdb3b',
    lightColor: '#ffe572',
    contrastColor: fokusContrastColor,

    logo: lyn_logo,
    logoShadow: 'shield',
  },

  'kongsvinger': {
    color: '#ff282f',
    secondaryColor: '#3fafff',
    lightColor: '#ff686d',
    contrastColor: fokusContrastColor,

    logo: kongsvinger_logo,
    logoShadow: 'circleTop',
  },

  'bryne': {
    color: '#ff332c',
    secondaryColor: '#3bcf4f',
    lightColor: '#ff7c78',
    contrastColor: fokusContrastColor,

    logo: bryne_logo,
    logoShadow: 'circleTopWide',
  },

  'moss': {
    color: '#ffe500',
    secondaryColor: '#ffe500',
    lightColor: '#ffea31',
    contrastColor: fokusContrastColor,

    logo: moss_logo,
    logoShadow: 'verticalEclipseTop',
  },

  'sogndal': {
    color: '#ffd332',
    secondaryColor: '#ffd332',
    lightColor: '#ffdf69',
    contrastColor: fokusContrastColor,

    logo: sogndal_logo,
    logoShadow: 'circleTopWide',
  },

  'egersund': {
    color: '#ffe015',
    secondaryColor: '#ffe015',
    lightColor: '#ffe53f',
    contrastColor: fokusContrastColor,

    logo: egersund_logo,
    logoShadow: 'verticalEclipseNarrow',
  },

  'start': {
    color: '#ffdc16',
    secondaryColor: '#ffdc16',
    lightColor: '#ffe554',
    contrastColor: fokusContrastColor,

    logo: start_logo,
    logoShadow: 'flagSmall',
  },

  'aalesund': {
    color: '#ff9436',
    secondaryColor: '#f2cb76',
    lightColor: '#ffb371',
    contrastColor: fokusContrastColor,

    logo: aalesund_logo,
    logoShadow: 'circleTopSmall',
  },

  'raufoss': {
    color: '#fff200',
    secondaryColor: '#fff200',
    lightColor: '#fff200',
    contrastColor: fokusContrastColor,

    logo: raufoss_logo,
    logoShadow: 'shieldSmall',
  },

  'asane': {
    color: '#ff6135',
    secondaryColor: '#ff734c',
    lightColor: '#ff9a73',
    contrastColor: fokusContrastColor,

    logo: asane_logo,
    logoShadow: 'roundedSquareSmall',
    logoSize: '90%',
    'logoBackgroundColor': '#ff6135',
  },

  'mif': {
    color: '#be4717',
    secondaryColor: '#ddbc75',
    lightColor: '#ffde97',
    contrastColor: fokusContrastColor,

    logo: mif_logo,
    logoShadow: 'circle',
  },

  'hodd': {
    color: '#4882ff',
    secondaryColor: '#6b9aff',
    lightColor: '#8aafff',
    contrastColor: fokusContrastColor,

    logo: hodd_logo,
    logoShadow: 'shieldTall',
    'logoBorderRadius': '0px 0px 100px 100px',
  },

  'skeid': {
    color: '#ff1100',
    secondaryColor: '#ffda0a',
    lightColor: '#ffda0a',
    contrastColor: fokusContrastColor,

    logo: skeid_logo,
    logoShadow: 'horizontalEclipse',
  },


  // swedish clubs

  'hammarby': {
    color: '#00bc64',
    secondaryColor: '#ffd52c',
    lightColor: '#00e77b',
    contrastColor: '#ffde59',

    logo: hammarby_logo,
  },

  'sirius': {
    color: '#0077ff',
    secondaryColor: '#fde10a',
    lightColor: '#fde10a',
    contrastColor: fokusContrastColor,

    logo: sirius_logo,
    logoShadow: 'verticalEclipse',
  },

  'goteborg': {
    color: '#2e7bff',
    secondaryColor: '#ffcd29',
    lightColor: '#ffda61',
    contrastColor: fokusContrastColor,

    logo: goteborg_logo,
    logoShadow: 'roundedSquareNarrow',
  },

  'aik': {
    color: '#ffee00',
    secondaryColor: '#ffee00',
    lightColor: '#fff454',
    contrastColor: fokusContrastColor,

    logo: aik_logo,
    logoShadow: 'circleBottomSmall',
  },

  'hacken': {
    color: '#f7ef02',
    secondaryColor: '#f7ef02',
    lightColor: '#fff94e',
    contrastColor: fokusContrastColor,

    logo: hacken_logo,
    logoShadow: 'shieldSmall',
  },

  'djurgarden': {
    color: '#3da8ff',
    secondaryColor: '#fff081',
    lightColor: '#ffef5a',
    contrastColor: fokusContrastColor,

    logo: djurgarden_logo,
    logoShadow: 'shieldSmall',
  },

  'elfsborg': {
    color: '#ffe311',
    secondaryColor: '#ffe311',
    lightColor: '#fff07a',
    contrastColor: fokusContrastColor,
    logo: elfsborg_logo,
    logoShadow: 'elfsborg',
  },

  'bromma': {
    color: '#ff2c2c',
    secondaryColor: '#ffcb6a',
    lightColor: '#ff6161',
    contrastColor: fokusContrastColor,

    logo: bromma_logo,
    logoShadow: 'shield',
  },


  // danish clubs

  'agf': {
    color: '#ffffff',
    secondaryColor: '#ff6964',
    lightColor: '#ffffff',
    contrastColor: fokusContrastColor,

    logo: agf_logo,
    logoShadow: 'shieldTall',
  },

  'brondby': {
    color: '#ffed00',
    secondaryColor: '#ffed00',
    lightColor: '#ffed00',
    contrastColor: fokusContrastColor,

    logo: brondby_logo,
    logoShadow: 'shieldTop',
  },

  'aab': {
    color: '#ff1e26',
    secondaryColor: '#ff464c',
    lightColor: '#ff7479',
    contrastColor: fokusContrastColor,

    logo: aab_logo,
    logoShadow: 'circle',
  },

  'lyngby': {
    color: '#1198ff',
    secondaryColor: '#ffdf8e',
    lightColor: '#ffdf8e',
    contrastColor: fokusContrastColor,

    logo: lyngby_logo,
    logoShadow: 'circle',
  },

  'randers': {
    color: '#74b5ff',
    secondaryColor: '#74b5ff',
    lightColor: '#c1deff',
    contrastColor: fokusContrastColor,

    logo: randers_logo,
  },

  'viborg': {
    color: '#069676',
    secondaryColor: '#3dc195',
    lightColor: '#86d7c2',
    contrastColor: '#fcca70',

    logo: viborg_logo,
    logoShadow: 'circle',
  },

  'b93': {
    color: '#fcca70',
    secondaryColor: '#fcca70',
    lightColor: '#ffd27e',
    contrastColor: fokusContrastColor,
    useAlternativeTierColors: true,

    logo: b93_logo,
    logoShadow: 'shield',
  },

  // finnish clubs

  'ilves': {
    color: '#ffd016',
    secondaryColor: '#ffd016',
    lightColor: '#ffda48',
    contrastColor: fokusContrastColor,

    logo: ilves_logo,
    logoShadow: 'ilves',
  },


  // european clubs

  'raith': {
    color: '#4e5dd0', // '#ff3e41',
    secondaryColor: '#ff4b4e',
    lightColor: '#ff686a',
    contrastColor: fokusContrastColor,
    logo: raith_logo,
    logoShadow: 'shieldTiny',
  },

  'poznan': {
    color: '#0084ff',
    secondaryColor: '#ffcb62',
    lightColor: '#ffcb62',
    contrastColor: fokusContrastColor,

    logo: poznan_logo,
    logoShadow: 'verticalEclipseNarrow',
  },

  'gornik': {
    color: '#ff485d',
    secondaryColor: '#ff485d',
    lightColor: '#ff6275',
    contrastColor: fokusContrastColor,

    logo: gornik_logo,
    logoShadow: 'gornik',
  },

  'psv': {
    color: '#ff2b32',
    secondaryColor: '#f0c574',
    lightColor: '#ffd78e',
    contrastColor: fokusContrastColor,

    logo: psv_logo,
    logoShadow: 'americanFootballBottom',
  },

  'twente': {
    color: '#fa0217',
    secondaryColor: '#ff5768',
    lightColor: '#ff3048',
    contrastColor: fokusContrastColor,

    logo: twente_logo,
    logoShadow: 'shieldTall',
  },


  // rest of the world

  // 'urawa': {
  //   color: '#ff2d53',
  //   secondaryColor: '#ff4365',
  //   lightColor: '#ff5e7c',
  //   contrastColor: fokusContrastColor,

  //   logo: urawa_logo,
  //   logoShadow: 'shieldSmall',
  // },
};
