import '../scouting.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import { Dialog } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { translate } from '../../../../common/language/translations';
import { DropDown } from '../../../components/controls/dropDowns/DropDown';
import { minuteAbsoluteKeys, minutePercentageKeys, ageKeys, contractKeys } from '../../../static/propertyValues';
import { Club, Iteration, RatingRequirement, StringToAnyMap } from '../../../types';
import { SaveOrLoadSearchModal } from '../../../components/modals/SaveOrLoadSearchModal';
import { saveUserSearch } from '../../../services/firestore/scouting';
import { CountryDropDown } from '../../../components/controls/dropDowns/CountryDropDown';
import { RatingDropDown } from '../../../components/controls/dropDowns/RatingDropDown';
import { IterationDropDown } from '../../../components/controls/dropDowns/IterationDropDown';
import { MetricDropDown } from '../../../components/controls/dropDowns/MetricDropDown';
import { PositionAndRoleDropDown } from '../../../components/controls/dropDowns/PositionAndRoleDropDown';
import { SeasonScoutingFilter, SeasonScoutingState } from './seasonScoutingState';
import { SeasonScoutingAction } from './seasonScoutingReducer';
import { IconButton } from '../../../components/controls/buttons/IconButton';
import { ClubDropDown } from '../../../components/controls/dropDowns/ClubDropDown';
import { modalPaperProps } from '../../../components/modals/globalModals/GlobalModals';


interface SeasonScoutingFiltersProps {
  state: SeasonScoutingState;
  dispatch: React.Dispatch<SeasonScoutingAction>;
}

export const SeasonScoutingFilters: React.FC<SeasonScoutingFiltersProps> = ({
  state,
  dispatch,
}) => {

  const { currentUser } = useAuthContext();
  const userConfig = useRecoilValue(userConfigState);


  const saveSearch = async (searchName: string) => {
    if (!userConfig) return;

    const search: StringToAnyMap = {
      'searchName': searchName,
      'searchType': 'seasonScouting',
    };

    if (state.selectedMinutes.length > 0) search['minMinutes'] = state.selectedMinutes;
    if (state.selectedMaxAge.length > 0) search['maxAge'] = state.selectedMaxAge;
    if (state.selectedPositions.length > 0) search['positions'] = state.selectedPositions;
    if (state.selectedRoles.length > 0) search['roles'] = state.selectedRoles;
    if (state.selectedIterations.length > 0) search['iterations'] = state.selectedIterations;
    if (state.selectedOrderBy.length > 0) search['orderBy'] = state.selectedOrderBy;

    if (state.selectedRatings.length > 0) search['ratings'] = state.selectedRatings;
    if (state.selectedNationalities.length > 0) search['nationalities'] = state.selectedNationalities;
    if (state.selectedContractStatus.length > 0) search['contractStatus'] = state.selectedContractStatus;
    if (state.isContractStatusToggled) search['isContractStatusToggled'] = true;

    saveUserSearch(search, userConfig.email, userConfig.club, currentUser);
  };


  const loadSearch = (savedSearch: StringToAnyMap) => {
    dispatch({ type: 'LOAD_SEARCH', payload: savedSearch });
  };


  const handleSetIsDropDownExpanded = (filter: SeasonScoutingFilter, value: boolean) => {
    if (value) {
      dispatch({ type: 'SET_FILTER_EXPANSION', payload: filter });
    }
    else {
      dispatch({ type: 'RESET_FILTER_EXPANSION' });
    }
  };


  useEffect(() => {
    if (!state.isAdvancedFiltersExpanded) {
      dispatch({ type: 'SET_SHOW_ADVANCED_FILTERS', payload: false });
    }
    else {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_SHOW_ADVANCED_FILTERS', payload: true });
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [state.isAdvancedFiltersExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <Dialog
        open={state.modalTypeOpen !== undefined}
        onClose={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: undefined })}
        PaperProps={modalPaperProps}
      >
        <SaveOrLoadSearchModal
          modalType={state.modalTypeOpen}
          searchType={'seasonScouting'}
          closeModal={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: undefined })}
          saveSearch={saveSearch}
          loadSearch={loadSearch}
        />
      </Dialog>

      <div className='filter-section-drop-down-title season-filter-section-minutes'>
        {translate('minMinutesPlayed', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-minutes'>
        <DropDown
          id={'season-filter-section-minutes-drop-down'}
          dropDownOptions={minuteAbsoluteKeys}
          dropDownSecondCategoryOptions={minutePercentageKeys}
          selectedOptions={state.selectedMinutes}
          setSelectedOptions={(selectedMinutes: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMinutes } })}
          isDropDownExpanded={state.currentFilterExpanded === 'minutes'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('minutes', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-age'>
        <DropDown
          id={'season-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          selectedOptions={state.selectedMaxAge}
          setSelectedOptions={(selectedMaxAge: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMaxAge } })}
          isDropDownExpanded={state.currentFilterExpanded === 'maxAge'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('maxAge', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}

          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-positions-and-roles'>
        {translate('positionsAndRoles', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-positions-and-roles'>
        <PositionAndRoleDropDown
          id={'season-filter-section-positions-and-roles-drop-down'}
          selectedPositions={state.selectedPositions}
          setSelectedPositions={(selectedPositions: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedPositions } })}
          selectedRoles={state.selectedRoles}
          setSelectedRoles={(selectedRoles: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedRoles } })}
          isDropDownExpanded={state.currentFilterExpanded === 'positionsAndRoles'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('positionsAndRoles', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-iterations'>
        {translate('seasons', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-iterations'>
        <IterationDropDown
          id={'season-filter-section-iterations-drop-down'}
          selectedIterations={state.selectedIterations}
          setSelectedIterations={(selectedIterations: Iteration[]) => dispatch({ type: 'SET_FILTER', payload: { selectedIterations } })}
          isDropDownExpanded={state.currentFilterExpanded === 'iterations'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('iterations', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
          anySkillcornerFiltersSelected={state.anySkillcornerFiltersSelected}
          setAnyIterationWithoutSkillcornerSelected={(value: boolean) => dispatch({ type: 'SET_ANY_ITERATION_WITHOUT_SKILLCORNER_SELECTED', payload: value })}
        />
      </div>

      <div className='filter-section-drop-down-title season-filter-section-order-by'>
        {translate('orderBy', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down season-filter-section-order-by'>
        <MetricDropDown
          id={'season-filter-section-order-by-drop-down'}
          selectedOptions={state.selectedOrderBy}
          setSelectedOptions={(selectedOrderBy: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedOrderBy } })}
          selectedRoles={state.selectedRoles}
          isDropDownExpanded={state.currentFilterExpanded === 'orderBy'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('orderBy', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
          forceSelection={true}
          isGoalkeeperSelected={state.selectedPositions.includes('GK')}
          anyIterationWithoutSkillcornerSelected={state.anyIterationWithoutSkillcornerSelected}
          setAnySkillcornerFiltersSelected={(value: boolean) => dispatch({ type: 'SET_ANY_SKILLCORNER_FILTERS_SELECTED', payload: value })}
        />
      </div>

      <IconButton
        onClick={() => { dispatch({ type: 'RESET_STATE' }); }}
        icon={<ReplayIcon style={{ fontSize: 20 }} />}
        size={24}
        style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(94% - 9px)' }}
        startWhite={true}
        title={translate('resetFilters', userConfig?.language)}
      />

      <IconButton
        onClick={() => dispatch({ type: 'SET_ADVANCED_FILTERS_EXPANSION' })}
        icon={!state.isAdvancedFiltersExpanded ? <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} /> : <CloseIcon style={{ fontSize: 20 }} />}
        size={24}
        style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(97% - 7px)' }}
        startWhite={true}
        title={!state.isAdvancedFiltersExpanded ? translate('moreFilters', userConfig?.language) : undefined}
      />

      {state.isAdvancedFiltersExpanded && state.showAdvancedFilters && (
        <div className='filter-section-advanced-filter-section'>

          <div className='filter-section-drop-down-title season-filter-section-clubs'>
            {translate('clubs', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-clubs'>
            <ClubDropDown
              id={'season-filter-section-clubs-drop-down'}
              isDropDownExpanded={state.currentFilterExpanded === 'clubs'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('clubs', value)}
              selectedClubs={state.selectedClubs}
              setSelectedClubs={(selectedClubs: Club[]) => dispatch({ type: 'SET_FILTER', payload: { selectedClubs } })}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-ratings'>
            {translate('ratingRequirements', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-ratings'>
            <RatingDropDown
              id={'season-filter-section-ratings-drop-down'}
              selectedOptions={state.selectedRatings}
              setSelectedOptions={(selectedRatings: RatingRequirement[]) => dispatch({ type: 'SET_FILTER', payload: { selectedRatings } })}
              selectedRoles={state.selectedRoles}
              isDropDownExpanded={state.currentFilterExpanded === 'ratings'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('ratings', value)}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              isGoalkeeperSelected={state.selectedPositions.includes('GK')}
              anyIterationWithoutSkillcornerSelected={state.anyIterationWithoutSkillcornerSelected}
              setAnySkillcornerFiltersSelected={(value: boolean) => dispatch({ type: 'SET_ANY_SKILLCORNER_FILTERS_SELECTED', payload: value })}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-nationalities'>
            {translate('nationalities', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-nationalities'>
            <CountryDropDown
              id={'season-filter-section-nationalities-drop-down'}
              selectedCountryCodes={state.selectedNationalities}
              setSelectedCountryCodes={(selectedNationalities: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedNationalities } })}
              isDropDownExpanded={state.currentFilterExpanded === 'nationalities'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('nationalities', value)}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title season-filter-section-contract'>
            {translate('contractStatus', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down season-filter-section-contract'>
            <DropDown
              id={'season-filter-section-contract-drop-down'}
              dropDownOptions={contractKeys}
              selectedOptions={state.selectedContractStatus}
              setSelectedOptions={(selectedContractStatus: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedContractStatus } })}
              isDropDownExpanded={state.currentFilterExpanded === 'contractStatus'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('contractStatus', value)}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              toggleText={'includeUnknownContractStatus'}
              isToggled={state.isContractStatusToggled}
              setIsToggled={(value: boolean) => dispatch({ type: 'SET_FILTER', payload: { isContractStatusToggled: value } })}
            />
          </div>

          <IconButton
            onClick={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: 'saveSearch' })}
            icon={<SaveAsIcon style={{ fontSize: 20, marginTop: -1 }} />}
            size={24}
            style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(94% - 9px)' }}
            startWhite={true}
            title={translate('saveSearch', userConfig?.language)}
          />

          <IconButton
            onClick={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: 'loadSearch' })}
            icon={<ArrowCircleDownIcon style={{ fontSize: 20 }} />}
            size={24}
            style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(97% - 7px)' }}
            startWhite={true}
            title={translate('loadSearch', userConfig?.language)}
          />
        </div>
      )}

    </div>
  );
};
