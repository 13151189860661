import { useState, useMemo } from 'react';
import { forceSimulation, forceX, forceY, forceCollide } from 'd3-force';

import SwipeIcon from '@mui/icons-material/Swipe';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { PlannerAction } from '../../types';
import { getPlannerActionIcon } from '../../utils/iconUtils';
import { dateToLocaleTextFormat, getAdjustedDate } from '../../utils/dateUtils';


const generateLabels = (
  startDate: Date,
  endDate: Date,
  dateToPosition: (date: Date) => number,
  language: string
): { x: number; label: string; isYear: boolean }[] => {

  const labels: { x: number; label: string; isYear: boolean }[] = [];
  const locale = language === 'no' ? 'nb-NO' : 'en-US';

  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() < 3 ? 3 : startDate.getMonth() < 6 ? 6 : startDate.getMonth() < 9 ? 9 : 3,
    1
  );

  while (currentDate <= endDate) {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    if (month === 3 || month === 6 || month === 9) { // April, July, October
      const isYear = month === 6; // True for July
      const label = isYear
        ? year.toString() // Year for July
        : currentDate.toLocaleDateString(locale, { month: 'short' }); // Month label for April and October

      labels.push({
        x: dateToPosition(new Date(currentDate)),
        label,
        isYear
      });
    }

    // Determine next date
    const nextMonth =
      month === 3 ? 6 : // April -> July
        month === 6 ? 9 : // July -> October
          3; // October -> April (next year)

    const nextYear = month === 9 ? year + 1 : year; // Increment year after October
    currentDate = new Date(nextYear, nextMonth, 1);
  }

  return labels;
};


export interface PlannerActionPoint extends PlannerAction {
  x: number;
  y: number;
  key: string;
}


interface PlannerTimelineProps {
  actions: PlannerAction[];
  selectedAction: PlannerAction | undefined;
  onActionClick: (action: PlannerActionPoint) => void;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  isFullScreen: boolean;
  isLeftSectionExpanded: boolean;
  showActions: boolean;
  language: string;
}


export const PlannerTimeline: React.FC<PlannerTimelineProps> = ({
  actions,
  selectedAction,
  onActionClick,
  selectedDate,
  setSelectedDate,
  isFullScreen,
  isLeftSectionExpanded,
  showActions,
  language,
}) => {

  const svgWidth = 1900;
  const timeLineOffset = 60;
  const timeLineWidth = svgWidth - 2 * timeLineOffset;

  const startDate = new Date('2025-01-01');
  const endDate = new Date('2029-12-31');
  const yearDividers = [
    new Date('2025-01-01'),
    new Date('2026-01-01'),
    new Date('2027-01-01'),
    new Date('2028-01-01'),
    new Date('2029-01-01'),
    new Date('2029-12-31'),
  ];

  const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

  const [dragging, setDragging] = useState(false);
  const [draggingDate, setDraggingDate] = useState<Date | null>(null);
  const [offsetX, setOffsetX] = useState(0);


  const dateToPosition = (date: Date) => {
    const adjustedDate = getAdjustedDate(date);
    const currentDate = new Date(adjustedDate);
    const daysFromStart = (currentDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    const position = timeLineOffset + (daysFromStart / totalDays) * timeLineWidth;
    return position;
  };


  const positionToDate = (position: number) => {
    const daysFromPosition = ((position - timeLineOffset) / timeLineWidth) * totalDays;
    const currentDate = new Date(startDate.getTime() + daysFromPosition * 1000 * 3600 * 24);
    return getAdjustedDate(currentDate);
  };


  const handleArrowClick = (direction: 'previous' | 'next') => {
    const currentYear = selectedDate.getFullYear();
    const currentMonth = selectedDate.getMonth();

    let newDate;

    const quarterMonths = [0, 3, 6, 9];

    if (direction === 'previous') {

      // find the closest previous quarter date
      const previousQuarter = quarterMonths
        .slice()
        .reverse()
        .find((month) => currentMonth > month || (currentMonth === month && selectedDate.getDate() > 1));

      if (previousQuarter !== undefined) {
        newDate = new Date(currentYear, previousQuarter, 1);
      }
      else {
        // if no previous quarter is found, go to the last quarter of the previous year
        newDate = new Date(currentYear - 1, 9, 1);
      }
    }
    else if (direction === 'next') {
      // find the closest next quarter date
      const nextQuarter = quarterMonths.find((month) => currentMonth < month || (currentMonth === month && selectedDate.getDate() < 1));

      if (nextQuarter !== undefined) {
        newDate = new Date(currentYear, nextQuarter, 1);
      }
      else {
        // if no next quarter is found, go to the first quarter of the next year
        const nextYear = currentYear + 1;

        // edge case for January 1st, 2030 (December 31st, 2029 is the last day of the timeline)
        if (nextYear === 2030) {
          newDate = new Date(2029, 11, 31);
        }
        else {
          newDate = new Date(nextYear, 0, 1);
        }
      }
    }

    if (newDate) {
      setSelectedDate(getAdjustedDate(newDate));
    }
  };


  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (dragging) {
      const svgElement = event.currentTarget;
      const rect = svgElement.getBoundingClientRect();
      const scaleX = svgWidth / rect.width;

      const newPosition = (event.clientX - rect.left) * scaleX - offsetX;

      const clampedPosition = Math.min(
        Math.max(timeLineOffset, Math.min(svgWidth, newPosition)),
        timeLineWidth + timeLineOffset
      );
      const newDate = positionToDate(clampedPosition);

      // only update if the date is different
      if (!draggingDate || newDate.getTime() !== draggingDate.getTime()) {
        setDraggingDate(newDate);
      }
    }
  };


  const handleMouseDown = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    setDragging(true);

    const svgElement = event.currentTarget.ownerSVGElement as SVGSVGElement;
    const rect = svgElement.getBoundingClientRect();
    const scaleX = svgWidth / rect.width;

    setOffsetX((event.clientX - rect.left) * scaleX - event.currentTarget.x.baseVal.value - 24);
    setDraggingDate(selectedDate);
  };


  const handleMouseUp = () => {
    setDragging(false);
    if (draggingDate) {
      setSelectedDate(getAdjustedDate(draggingDate));
      setDraggingDate(null);
    }
  };


  const labels = generateLabels(startDate, endDate, dateToPosition, language);


  const points = useMemo(() => {
    const points: PlannerActionPoint[] = actions.map(action => ({
      ...action,
      x: dateToPosition(new Date(action.date)),
      y: 126,
      key: action.playerId + action.type + action.date,
    }));

    const simulation = forceSimulation(points)
      .force('x', forceX(d => d.x as number).strength(0.8))
      .force('y', forceY(d => d.y as number).strength(0.2))
      .force('collide', forceCollide(12))
      .stop();

    for (let i = 0; i < 100; i++) {
      simulation.tick();
    }

    return points;
  }, [actions]); // eslint-disable-line react-hooks/exhaustive-deps


  const renderAction = (
    point: PlannerActionPoint,
    renderOnlySelectedAction: boolean,
  ) => {

    const isSelected = selectedAction !== undefined && point.key === selectedAction.playerId + selectedAction.type + selectedAction.date;
    if (renderOnlySelectedAction && !isSelected) return null;

    // points outside the timeline will not be shown, unless they are selected
    const isLeftOfTimeline = point.x < timeLineOffset;
    const isRightOfTimeline = point.x > timeLineOffset + timeLineWidth;
    if (!isSelected && (isLeftOfTimeline || isRightOfTimeline)) return null;

    const x = isLeftOfTimeline ? 18 : isRightOfTimeline ? svgWidth - 38 : point.x - 12;
    const y = (isLeftOfTimeline || isRightOfTimeline) ? 60 : point.y - 12;

    return (
      <foreignObject
        key={point.key}
        x={x}
        y={y}
        width={24}
        height={24}
        className={'planner-timeline-action' + (isSelected ? ' planner-timeline-action-selected' : '')}
        onClick={() => onActionClick(point)}
      >
        <div className='full-size-centered-container'>
          {getPlannerActionIcon(point.type, 21, undefined, point.role, point.locality)}
        </div>
      </foreignObject>
    );
  };


  const internalDate = draggingDate || selectedDate;
  const isAtStart = internalDate <= new Date('2025-01-02');
  const isAtEnd = internalDate > new Date('2029-12-30');


  return (
    <svg
      className='player-view-svg-plot fade-in'
      viewBox={'0 0 ' + svgWidth + ' 250'}
      preserveAspectRatio={'xMidYMid meet'}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >

      {/* X-axis and labels */}
      <line
        x1={dateToPosition(startDate)}
        y1={0}
        x2={dateToPosition(endDate)}
        y2={0}
        stroke="#ffffff"
        style={{
          transform: `translateY(${isFullScreen ? 70 : 220}px)`,
          transition: 'transform 250ms',
        }}
      />

      {yearDividers.map(date => (
        <line
          key={date.toISOString()}
          x1={dateToPosition(date)}
          y1={0}
          x2={dateToPosition(date)}
          y2={12}
          stroke="#ffffff"
          style={{
            transform: `translateY(${isFullScreen ? 64 : 214}px)`,
            transition: 'transform 250ms',
          }}
        />
      ))}

      <line
        x1={dateToPosition(yearDividers[1])}
        y1={0}
        x2={dateToPosition(yearDividers[2])}
        y2={0}
        stroke="#87bbff"
        style={{
          transform: `translateY(${isFullScreen ? 70 : 220}px)`,
          transition: 'transform 250ms',
        }}
      />

      <line
        x1={dateToPosition(yearDividers[3])}
        y1={0}
        x2={dateToPosition(yearDividers[4])}
        y2={0}
        stroke="#87bbff"
        style={{
          transform: `translateY(${isFullScreen ? 70 : 220}px)`,
          transition: 'transform 250ms',
        }}
      />

      {labels.map(label => (
        <g key={label.x}>
          {/* {!label.isYear && ( */}
          <line
            x1={label.x}
            y1={0}
            x2={label.x}
            y2={6}
            stroke="#cdd1d7"
            style={{
              transform: `translateY(${isFullScreen ? 70 : 220}px)`,
              transition: 'transform 250ms',
            }}
          />
          {/* )} */}

          <text
            x={label.x}
            y={label.isYear ? 4 : 3}
            textAnchor='middle'
            fill={label.isYear ? '#ffffffcc' : '#ffffffaa'}
            fontSize={label.isYear ? 15 : 14}
            fontWeight={label.isYear ? 700 : 400}
            style={{
              transform: `translateY(${isFullScreen ? 90 : 240}px)`,
              transition: 'transform 250ms',
            }}
          >
            {label.label}
          </text>
        </g>
      ))}

      <line
        x1={dateToPosition(internalDate)}
        y1={0}
        x2={dateToPosition(internalDate)}
        y2={200}
        style={{
          transform: `translateY(${isFullScreen ? -130 : 20}px)`,
          transition: dragging ? '100ms' : '50ms' + ', transform 250ms',
        }}

        stroke={dragging ? '#cdd1d788' : '#cdd1d7'}
        strokeDasharray={dragging ? (isFullScreen ? '3 3' : '5 5') : 'none'}
      />


      {/* The selected action, if any, must be rendered in front of the others due to SVG not supporting z-index */}
      {showActions && points.map(point => renderAction(point, false))}
      {showActions && points.map(point => renderAction(point, true))}

      {/* Pagination arrows */}
      <foreignObject
        x={10}
        y={0}
        width={35}
        height={35}
        onClick={() => !isAtStart && handleArrowClick('previous')}
        className={'planner-timeline-arrow' + (isAtStart ? ' planner-timeline-arrow-disabled' : '')}
        style={{
          transform: `translateY(${isFullScreen ? 52.5 : 108}px)`,
          transition: 'transform 250ms',
        }}
      >
        <div className='full-size-centered-container'>
          <ArrowBackIosNewIcon style={{ fontSize: 28 }} />
        </div>
      </foreignObject>

      <foreignObject
        x={svgWidth - 45}
        y={0}
        width={35}
        height={35}
        onClick={() => !isAtEnd && handleArrowClick('next')}
        className={'planner-timeline-arrow' + (isAtEnd ? ' planner-timeline-arrow-disabled' : '')}
        style={{
          transform: `translateY(${isFullScreen ? 52.5 : 108}px)`,
          transition: 'transform 250ms',
        }}
      >
        <div className='full-size-centered-container'>
          <ArrowForwardIosIcon style={{ fontSize: 28 }} />
        </div>
      </foreignObject>


      {/* Draggable knob */}
      <foreignObject
        x={dateToPosition(internalDate) - 22}
        y={-1}
        width={44}
        height={50}
        onMouseDown={handleMouseDown}
        className='planner-timeline-knob-container'
        style={{
          height: 50,
          width: 44,
        }}
      >
        <div className={'planner-timeline-knob' + (dragging ? ' planner-timeline-knob-dragging' : '')}>
          <SwipeIcon style={{ fontSize: 24 }} />
        </div>
      </foreignObject>

      {!isLeftSectionExpanded && !dragging && (
        <text
          x={dateToPosition(internalDate) + (internalDate < yearDividers[3] ? 33 : -33)}
          y={34}
          textAnchor={internalDate < yearDividers[3] ? 'start' : 'end'}
          fill={'#ffffff'}
          fontSize={18}
          className='fade-in'
        >
          {dateToLocaleTextFormat(selectedDate, language)}
        </text>
      )}

    </svg>
  );
};
