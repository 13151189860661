import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import CloseIcon from '@mui/icons-material/Close';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { IconButton } from '../controls/buttons/IconButton';
import { PlannerAction, PlannerActionType, PlannerConfig, PlannerConfigAction, PlayerId, StringToAnyMap } from '../../types';
import { translate } from '../../../common/language/translations';
import { TextButton } from '../controls/buttons/TextButton';
import { DateInputField } from '../controls/input/DateInputField';
import { datesAreXYearsApart, dateStringToLocaleFormat, getAdjustedDate, getDateXYearsAhead, getNextDate } from '../../utils/dateUtils';
import { deepCloneObject } from '../../utils/utils';
import { DropDown } from '../controls/dropDowns/DropDown';
import { formationToPositionOptions, playerLocalityToSortOrder, teamRoles } from '../../static/propertyValues';
import { getNewValidPlayerActions, getPlayerStatusAtGivenDate } from '../../views/planner/utils';
import { DropDownWithIcons } from '../controls/dropDowns/DropDownWithIcons';
import { addPlayerWithoutData } from '../../services/firestore/players';


interface PlannerActionModalProps {
  closeModal: () => void;
  type: PlannerActionType;
  selectedAction: PlannerAction | undefined;

  selectedPlayerId: PlayerId;
  currentTeamPlayerMap: StringToAnyMap;
  originalTeamPlayerMap: StringToAnyMap;
  fullname: string;

  selectedDate: Date;
  plannerConfig: PlannerConfig;
  handleUpdatePlannerConfig: (config: PlannerConfig, newPlayerIdWithoutData?: PlayerId) => void;

  formation: string;
  newPosition?: string;
  contractExpirationDateToExtend?: string;
}

export const PlannerActionModal: React.FC<PlannerActionModalProps> = ({
  closeModal,
  type,
  selectedAction,

  selectedPlayerId,
  currentTeamPlayerMap,
  originalTeamPlayerMap,
  fullname,

  selectedDate,
  plannerConfig,
  handleUpdatePlannerConfig,

  formation,
  newPosition,
  contractExpirationDateToExtend,
}) => {

  const { currentUser } = useAuthContext();
  const userConfig = useRecoilValue(userConfigState);
  const { openConfirmModal } = useOpenGlobalModal();

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);


  const initialActionDate = selectedAction
    ? new Date(selectedAction.date)
    : contractExpirationDateToExtend
      ? selectedDate > new Date(contractExpirationDateToExtend)
        ? new Date(contractExpirationDateToExtend)
        : selectedDate
      : selectedDate;

  const [selectedActionDate, setSelectedActionDate] = useState<Date>(initialActionDate);

  const [newContractExpiration, setNewContractExpiration] = useState<Date | null>(
    selectedAction?.contractExpiration ? new Date(selectedAction.contractExpiration) : null
  );

  const initialNewPosition = newPosition ?? selectedAction?.position;
  const [selectedPosition, setSelectedPosition] = useState<string[]>(initialNewPosition ? [initialNewPosition] : []);
  const [selectedTeamRole, setSelectedTeamRole] = useState<string[]>(selectedAction?.role ? [selectedAction.role] : []);
  const [selectedLocality, setSelectedLocality] = useState<string[]>(selectedAction?.locality ? [selectedAction.locality] : []);

  const [currentFilterExpanded, setCurrentFilterExpanded] = useState<string | undefined>(undefined);


  // date has the highest validation precedence - if selecting a date causes the state to be invalid, but the date is valid in isolation, other states are reset
  const isValidActionDate = (actionDate: Date) => {

    const { currentPosition, currentRole, currentLocality, contractExpiredOnGivenDate } = getPlayerStatusAtGivenDate(
      plannerConfig,
      selectedPlayerId,
      originalTeamPlayerMap,
      actionDate,
      selectedAction
    );

    if (type === 'playerAdded' && currentPosition !== undefined) {
      setMessageKey('thePlayerIsAlreadyInTeamAtSelectedDate');
      return false;
    }

    // we allow contract extensions on the same date as the contract expiration, though the player is not considered to be in the team on that date
    if (type !== 'playerAdded' && currentPosition === undefined && !(type === 'contractExtended' && contractExpiredOnGivenDate)) {
      setMessageKey('thePlayerIsNotInTeamAtSelectedDate');
      return false;
    }

    // changing the date may cause other states to be invalid
    if (newContractExpiration && actionDate > newContractExpiration) setNewContractExpiration(null);
    if (selectedPosition.length > 0 && currentPosition === selectedPosition[0]) setSelectedPosition([]);
    if (selectedTeamRole.length > 0 && currentRole !== selectedTeamRole[0]) setSelectedTeamRole([]);
    if (selectedLocality.length > 0 && currentLocality !== selectedLocality[0]) setSelectedLocality([]);

    setMessageKey(undefined);
    return true;
  };


  const handleSetSelectedActionDate = (date: Date | null) => {
    if (!date) {
      return;
    }

    const adjustedDate = getAdjustedDate(date);
    if (isValidActionDate(adjustedDate)) {
      setSelectedActionDate(adjustedDate);
    }
  };


  // the only invalid is position is the player position at the given date
  const isValidProperty = (property: 'position' | 'role' | 'locality', value: string) => {

    const { currentPosition, currentRole, currentLocality } = getPlayerStatusAtGivenDate(
      plannerConfig,
      selectedPlayerId,
      originalTeamPlayerMap,
      selectedActionDate,
      selectedAction
    );

    if (property === 'position' && currentPosition === value) {
      setMessageKey('thePlayerAlreadyHasTheSelectedPositionAtSelectedDate');
      return false;
    }

    if (property === 'role' && currentRole === value) {
      setMessageKey('thePlayerAlreadyHasTheSelectedRoleAtSelectedDate');
      return false;
    }

    if (property === 'locality' && currentLocality === value) {
      setMessageKey('thePlayerAlreadyHasTheSelectedLocalityAtSelectedDate');
      return false;
    }

    setMessageKey(undefined);
    return true;
  };


  const handleSetSelectedPosition = (position: string[]) => {
    if (position.length === 0 || isValidProperty('position', position[0])) {
      setSelectedPosition(position);
    }
  };


  const handleSetSelectedRole = (role: string[]) => {
    if (role.length === 0 || isValidProperty('role', role[0])) {
      setSelectedTeamRole(role);
    }
  };


  const handleSetSelectedLocality = (locality: string[]) => {
    if (locality.length === 0 || isValidProperty('locality', locality[0])) {
      setSelectedLocality(locality);
    }
  };


  const confirmUpdatePlannerConfig = async (newValidPlayerActions: PlannerConfigAction[]) => {

    let newPlayerIdWithoutData: PlayerId | undefined = undefined;
    const newConfig: PlannerConfig = deepCloneObject(plannerConfig);

    if (newValidPlayerActions.length === 0) {
      delete newConfig[selectedPlayerId];
    }

    else {
      let playerId: PlayerId | undefined = selectedPlayerId;
      if (selectedPlayerId === 'newPlayerWithoutData' && userConfig && currentUser) {
        const newPlayerId = await addPlayerWithoutData(fullname, userConfig.club, currentUser);
        newPlayerIdWithoutData = newPlayerId;
        playerId = newPlayerId;
      }

      if (playerId !== undefined) {
        newConfig[playerId] = newValidPlayerActions;
      }
    }

    handleUpdatePlannerConfig(newConfig, newPlayerIdWithoutData);
    closeModal();
  };


  const onSubmitButtonClick = () => {
    if (isSubmitButtonDisabled()) return;

    // we do not allow multiple action s with the same type and date for the same player - we want to give this feedback instead of disabling the submit button
    if (!selectedAction && plannerConfig[selectedPlayerId]) {
      const playerActions = plannerConfig[selectedPlayerId];
      if (playerActions.some(action => action.type === type && action.date === selectedActionDate.toISOString().split('T')[0])) {
        setMessageKey('aPlayerCanNotHaveMultipleActionsOfTheSameTypeOnTheSameDate');
        return;
      }
    }

    // adding or editing some actions may cause subsequent actions to be invalid, in which case they should be deleted and the user should be warned
    let newPlannerConfigAction: PlannerConfigAction | undefined = undefined;

    if (type === 'playerRemoved') {
      newPlannerConfigAction = {
        type: 'playerRemoved',
        date: selectedActionDate.toISOString().split('T')[0],
      };
    }

    else if (type === 'playerAdded') {
      newPlannerConfigAction = {
        type: 'playerAdded',
        date: selectedActionDate.toISOString().split('T')[0],
        position: selectedPosition[0],
        fullname: fullname,
      };

      if (newContractExpiration) newPlannerConfigAction['contractExpiration'] = newContractExpiration.toISOString().split('T')[0];
      if (selectedTeamRole.length > 0) newPlannerConfigAction['role'] = selectedTeamRole[0];
      if (selectedLocality.length > 0) newPlannerConfigAction['locality'] = selectedLocality[0];
    }

    else if (type === 'contractExtended') {
      newPlannerConfigAction = {
        type: 'contractExtended',
        date: selectedActionDate.toISOString().split('T')[0],
        contractExpiration: newContractExpiration?.toISOString().split('T')[0],
      };
    }

    else if (type === 'positionChanged') {
      newPlannerConfigAction = {
        type: 'positionChanged',
        date: selectedActionDate.toISOString().split('T')[0],
        position: selectedPosition[0],
      };
    }

    else if (type === 'roleChanged') {
      newPlannerConfigAction = {
        type: 'roleChanged',
        date: selectedActionDate.toISOString().split('T')[0],
        role: selectedTeamRole[0],
      };
    }

    else if (type === 'localityChanged') {
      newPlannerConfigAction = {
        type: 'localityChanged',
        date: selectedActionDate.toISOString().split('T')[0],
        locality: selectedLocality[0],
      };
    }

    if (!newPlannerConfigAction) return;

    const { newValidPlayerActions, subsequentActionsWillBeDeleted } = getNewValidPlayerActions(
      plannerConfig,
      selectedPlayerId,
      originalTeamPlayerMap,
      newPlannerConfigAction,
      selectedAction
    );

    if (subsequentActionsWillBeDeleted) {
      openConfirmModal(
        () => confirmUpdatePlannerConfig(newValidPlayerActions),
        selectedAction ? 'editAction' : type + '<imperative>',
        selectedAction ? 'editActionPlannerInfo' : 'addActionPlannerInfo'
      );
    }
    else {
      confirmUpdatePlannerConfig(newValidPlayerActions);
    }
  };


  const onDeleteActionClick = () => {

    // some deletions may cause subsequent actions to be invalid, in which case they should also be deleted and the user should be warned
    const { newValidPlayerActions, subsequentActionsWillBeDeleted } = getNewValidPlayerActions(
      plannerConfig,
      selectedPlayerId,
      originalTeamPlayerMap,
      undefined,
      selectedAction
    );

    if (subsequentActionsWillBeDeleted) {
      openConfirmModal(
        () => confirmUpdatePlannerConfig(newValidPlayerActions),
        'deleteAction',
        'deleteActionPlannerInfo'
      );
    }
    else {
      confirmUpdatePlannerConfig(newValidPlayerActions);
    }
  };


  const submitButtonIcon = selectedAction
    ? <UpdateIcon style={{ fontSize: 22 }} />
    : type === 'playerRemoved'
      ? <PersonRemoveAlt1Icon style={{ fontSize: 22 }} />
      : type === 'playerAdded'
        ? <PersonAddAlt1Icon style={{ fontSize: 22 }} />
        : <UpdateIcon style={{ fontSize: 22 }} />;


  const modalHeight = type === 'playerAdded'
    ? 650
    : type === 'playerRemoved'
      ? 500
      : 550;


  const isSubmitButtonDisabled = () => {

    if (type === 'playerRemoved') {
      return selectedAction && selectedActionDate.toISOString().split('T')[0] === selectedAction.date;
    }

    if (type === 'playerAdded') {

      // we only require a position when adding a player, the other properties are optional
      if (selectedPosition.length === 0) return true;

      if (selectedAction) {
        const currentActionKey = selectedActionDate.toISOString().split('T')[0] + selectedPosition[0]
          + (newContractExpiration ? newContractExpiration.toISOString().split('T')[0] : 'none')
          + (selectedTeamRole.length > 0 ? selectedTeamRole[0] : 'none')
          + (selectedLocality.length > 0 ? selectedLocality[0] : 'none');

        const selectedActionKey = selectedAction.date + selectedAction.position
          + (selectedAction.contractExpiration ? selectedAction.contractExpiration : 'none')
          + (selectedAction.role ? selectedAction.role : 'none')
          + (selectedAction.locality ? selectedAction.locality : 'none');

        return currentActionKey === selectedActionKey;
      }
    }

    if (type === 'contractExtended') {
      if (!newContractExpiration) return true;

      if (selectedAction) {
        const currentActionKey = selectedActionDate.toISOString().split('T')[0] + newContractExpiration.toISOString().split('T')[0];
        const selectedActionKey = selectedAction.date + selectedAction.contractExpiration;
        return currentActionKey === selectedActionKey;
      }
    }

    if (type === 'positionChanged') {
      if (selectedPosition.length === 0) return true;

      if (selectedAction) {
        const currentActionKey = selectedActionDate.toISOString().split('T')[0] + selectedPosition[0];
        const selectedActionKey = selectedAction.date + selectedAction.position;
        return currentActionKey === selectedActionKey;
      }
    }

    if (type === 'roleChanged') {
      if (selectedTeamRole.length === 0) return true;

      if (selectedAction) {
        const currentActionKey = selectedActionDate.toISOString().split('T')[0] + selectedTeamRole[0];
        const selectedActionKey = selectedAction.date + selectedAction.role;
        return currentActionKey === selectedActionKey;
      }
    }

    if (type === 'localityChanged') {
      if (selectedLocality.length === 0) return true;

      if (selectedAction) {
        const currentActionKey = selectedActionDate.toISOString().split('T')[0] + selectedLocality[0];
        const selectedActionKey = selectedAction.date + selectedAction.locality;
        return currentActionKey === selectedActionKey;
      }
    }

    return false;
  };


  const getContractOption = (years: number, optionHeight?: number) => {

    const referenceDate = type === 'playerAdded'
      ? selectedActionDate
      : contractExpirationDateToExtend
        ? new Date(contractExpirationDateToExtend)
        : currentTeamPlayerMap[selectedPlayerId]?.contract_expiration
          ? new Date(currentTeamPlayerMap[selectedPlayerId]?.contract_expiration)
          : selectedActionDate;

    const isSelected = newContractExpiration ? datesAreXYearsApart(referenceDate, newContractExpiration, years) : false;

    return (
      <div className='planner-action-modal-input-row-option-container'>
        <div
          className={'planner-action-modal-input-row-option' + (isSelected ? ' planner-action-modal-input-row-option-selected' : '')}
          onClick={() => setNewContractExpiration(getDateXYearsAhead(referenceDate, years))}
          style={{ height: optionHeight }}
        >
          {years}
          <div style={{ fontSize: 10, color: '#ffffffdd' }}>
            {translate(years > 1 ? 'years' : 'year', userConfig?.language, true)}
          </div>
        </div>
      </div>
    );
  };


  // | 'playerAdded'
  // | 'playerRemoved'

  // | 'contractExtended'
  // | 'contractExpired' -> this will not have a modal, but will instead open the contract extension modal

  // | 'positionChanged'
  // | 'roleChanged'
  // | 'localityChanged'


  return (
    <div
      className='modal-root-container planner-action-modal-root'
      style={{ height: modalHeight }}
    >

      {currentFilterExpanded &&
        <div className='modal-empty-background' onClick={() => setCurrentFilterExpanded(undefined)} />
      }

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={() => closeModal()}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title' style={{ height: 65 }}>
        {translate(type + '<imperative>', userConfig?.language)}
        <div style={{ marginTop: 8 }}>
          {fullname}
        </div>
      </div>

      <div className='modal-divider modal-root-title-divider' style={{ top: 65 }} />

      {type !== 'playerAdded' && (
        <div className='modal-content-container' style={{ top: 65, bottom: 75 }}>

          <div className='planner-action-modal-input-section' style={{ top: 15, left: type === 'playerRemoved' ? undefined : 30, zIndex: 10 }}>
            {translate('selectActionDate', userConfig?.language)}

            <div className='planner-action-modal-input-entry'>
              <DateInputField
                value={selectedActionDate}
                onChange={handleSetSelectedActionDate}
                minDate='today-10y'
                maxDate='today+10y'
                popperPlacement='bottom'
                height={24}
                width={200}
              />
            </div>
          </div>

          {type === 'contractExtended' && (
            <div className='planner-action-modal-input-section' style={{ top: 15, right: 30 }}>
              {translate(
                contractExpirationDateToExtend || currentTeamPlayerMap[selectedPlayerId]?.contract_expiration
                  ? 'selectNewExpirationDate'
                  : 'selectNewExpirationDate',
                userConfig?.language
              )}

              <div className='planner-action-modal-input-entry'>
                <DateInputField
                  value={newContractExpiration}
                  onChange={(date) => setNewContractExpiration(date ? getAdjustedDate(date) : null)}
                  minDate={
                    getNextDate(
                      contractExpirationDateToExtend
                        ? new Date(contractExpirationDateToExtend)
                        : currentTeamPlayerMap[selectedPlayerId]?.contract_expiration
                          ? new Date(currentTeamPlayerMap[selectedPlayerId]?.contract_expiration)
                          : selectedActionDate
                    )
                  }
                  maxDate='today+10y'
                  popperPlacement='bottom'
                  placeholder={translate('selectDate', userConfig?.language)}
                  height={24}
                  width={200}
                />
              </div>
            </div>
          )}

          {type === 'contractExtended' && (
            <div className='planner-action-modal-input-section' style={{ top: 90, right: 30 }}>

              <div className='planner-action-modal-input-row'>
                {getContractOption(1, 20)}
                {getContractOption(2, 20)}
                {getContractOption(3, 20)}
              </div>

              <div className='planner-action-modal-input-row' style={{ marginTop: 20 }}>
                {getContractOption(4, 20)}
                {getContractOption(5, 20)}
                {getContractOption(6, 20)}
              </div>

              {(contractExpirationDateToExtend || currentTeamPlayerMap[selectedPlayerId]?.contract_expiration) && (
                <div style={{ textAlign: 'center', marginTop: 20, fontSize: 14, color: '#ffffff', lineHeight: '1.5' }}>
                  {translate(contractExpirationDateToExtend ? 'fromPreviousExpirationDate' : 'fromCurrentExpirationDate', userConfig?.language)}:
                  {' ' + dateStringToLocaleFormat(contractExpirationDateToExtend ?? currentTeamPlayerMap[selectedPlayerId]?.contract_expiration)}
                </div>
              )}

              {!(contractExpirationDateToExtend || currentTeamPlayerMap[selectedPlayerId]?.contract_expiration) && (
                <div style={{ textAlign: 'center', marginTop: 20, fontSize: 14, color: '#ffffff', lineHeight: '1.5' }}>
                  {translate('fromSelectedActionDate', userConfig?.language)}
                </div>
              )}
            </div>
          )}

          {type === 'positionChanged' && (
            <div className='planner-action-modal-input-section' style={{ top: 15, right: 30 }}>
              {translate('selectPosition', userConfig?.language)}

              <div className='planner-action-modal-input-entry'>
                <DropDown
                  id={'planner-action-modal-position-select'}
                  dropDownOptions={formationToPositionOptions[formation]}
                  selectedOptions={selectedPosition}
                  setSelectedOptions={handleSetSelectedPosition}
                  isDropDownExpanded={currentFilterExpanded === 'positions'}
                  setIsDropDownExpanded={(value: boolean) => setCurrentFilterExpanded(value ? 'positions' : undefined)}
                  defaultDropDownText={'selectPosition'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'360px'}
                  height={24}
                />
              </div>
            </div>
          )}

          {type === 'roleChanged' && (
            <div className='planner-action-modal-input-section' style={{ top: 15, right: 30 }}>
              {translate('selectTeamRole', userConfig?.language)}

              <div className='planner-action-modal-input-entry'>
                <DropDownWithIcons
                  id={'planner-action-modal-team-role-select'}
                  dropDownOptions={teamRoles}
                  selectedOptions={selectedTeamRole}
                  setSelectedOptions={handleSetSelectedRole}
                  isDropDownExpanded={currentFilterExpanded === 'teamRole'}
                  setIsDropDownExpanded={(value: boolean) => setCurrentFilterExpanded(value ? 'teamRole' : undefined)}
                  defaultDropDownText={'select'}
                  defaultDropDownTextColor={'#00000080'}
                  marginBetweenOptions={3}
                  maxHeight={'360px'}
                  useRoleIcons={true}
                  height={24}
                />
              </div>
            </div>
          )}

          {type === 'localityChanged' && (
            <div className='planner-action-modal-input-section' style={{ top: 15, right: 30 }}>
              {translate('selectLocality', userConfig?.language)}

              <div className='planner-action-modal-input-entry'>
                <DropDownWithIcons
                  id={'planner-action-modal-locality-select'}
                  dropDownOptions={Object.keys(playerLocalityToSortOrder)}
                  selectedOptions={selectedLocality}
                  setSelectedOptions={handleSetSelectedLocality}
                  isDropDownExpanded={currentFilterExpanded === 'locality'}
                  setIsDropDownExpanded={(value: boolean) => setCurrentFilterExpanded(value ? 'locality' : undefined)}
                  defaultDropDownText={'select'}
                  defaultDropDownTextColor={'#00000080'}
                  marginBetweenOptions={3}
                  maxHeight={'190px'}
                  useLocalityIcons={true}
                  height={24}
                />
              </div>
            </div>
          )}

        </div>
      )}

      {type === 'playerAdded' && (
        <div className='modal-content-container' style={{ top: 65, bottom: 75 }}>

          <div className='planner-action-modal-input-section' style={{ top: 15, left: 30, zIndex: 10 }}>
            {translate('selectActionDate', userConfig?.language)}

            <div className='planner-action-modal-input-entry'>
              <DateInputField
                value={selectedActionDate}
                onChange={handleSetSelectedActionDate}
                minDate='today-10y'
                maxDate='today+10y'
                popperPlacement='bottom'
                height={24}
                width={200}
              />
            </div>
          </div>

          <div className='planner-action-modal-input-section' style={{ top: 15, right: 30 }}>
            {translate('selectPosition', userConfig?.language)}

            <div className='planner-action-modal-input-entry'>
              <DropDown
                id={'planner-action-modal-position-select'}
                dropDownOptions={formationToPositionOptions[formation]}
                selectedOptions={selectedPosition}
                setSelectedOptions={setSelectedPosition}
                isDropDownExpanded={currentFilterExpanded === 'positions'}
                setIsDropDownExpanded={(value: boolean) => setCurrentFilterExpanded(value ? 'positions' : undefined)}
                defaultDropDownText={'selectPosition'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
                maxHeight={'360px'}
                height={24}
              />
            </div>
          </div>

          <div className='modal-divider' style={{ marginTop: 82 }} />

          <div className='planner-action-modal-input-section' style={{ top: 100, left: 30 }}>
            {translate('contract', userConfig?.language)}?

            <div className='planner-action-modal-input-entry'>
              <DateInputField
                value={newContractExpiration}
                onChange={(date) => setNewContractExpiration(date ? getAdjustedDate(date) : null)}
                minDate={selectedActionDate}
                maxDate='today+10y'
                popperPlacement='bottom'
                placeholder={translate('selectDate', userConfig?.language)}
                height={24}
                width={200}
              />
            </div>
          </div>

          <div className='planner-action-modal-input-section' style={{ top: 100, right: 30 }}>
            <div className='planner-action-modal-input-row' style={{ marginTop: -2 }}>
              {getContractOption(1)}
              {getContractOption(2)}
              {getContractOption(3)}
            </div>

            <div className='planner-action-modal-input-row' style={{ marginTop: 10 }}>
              {getContractOption(4)}
              {getContractOption(5)}
              {getContractOption(6)}
            </div>
          </div>

          <div className='modal-divider' style={{ marginTop: 82 }} />

          <div className='planner-action-modal-input-section' style={{ top: 185, left: 30 }}>
            {translate('teamRole', userConfig?.language)}?

            <div className='planner-action-modal-input-entry'>
              <DropDownWithIcons
                id={'planner-action-modal-team-role-select'}
                dropDownOptions={teamRoles}
                selectedOptions={selectedTeamRole}
                setSelectedOptions={setSelectedTeamRole}
                isDropDownExpanded={currentFilterExpanded === 'teamRole'}
                setIsDropDownExpanded={(value: boolean) => setCurrentFilterExpanded(value ? 'teamRole' : undefined)}
                defaultDropDownText={'select'}
                defaultDropDownTextColor={'#00000080'}
                marginBetweenOptions={3}
                maxHeight={'190px'}
                useRoleIcons={true}
                height={24}
              />
            </div>
          </div>

          <div className='planner-action-modal-input-section' style={{ top: 185, right: 30 }}>
            {translate('locality', userConfig?.language)}?

            <div className='planner-action-modal-input-entry'>
              <DropDownWithIcons
                id={'planner-action-modal-locality-select'}
                dropDownOptions={Object.keys(playerLocalityToSortOrder)}
                selectedOptions={selectedLocality}
                setSelectedOptions={setSelectedLocality}
                isDropDownExpanded={currentFilterExpanded === 'locality'}
                setIsDropDownExpanded={(value: boolean) => setCurrentFilterExpanded(value ? 'locality' : undefined)}
                defaultDropDownText={'select'}
                defaultDropDownTextColor={'#00000080'}
                marginBetweenOptions={3}
                maxHeight={'190px'}
                useLocalityIcons={true}
                height={24}
              />
            </div>
          </div>
        </div>
      )}

      {messageKey && (
        <div className='planner-action-modal-error-message'>
          {translate(messageKey, userConfig?.language)}
        </div>
      )}

      <div className='planner-action-modal-button-container'>
        <TextButton
          onClick={onSubmitButtonClick}
          text={translate(selectedAction ? 'editAction' : type + '<imperative>', userConfig?.language)}
          icon={submitButtonIcon}
          disabled={isSubmitButtonDisabled()}
          style={{ width: selectedAction ? 220 : 240, height: 26 }}
        />

        {selectedAction && (
          <TextButton
            onClick={onDeleteActionClick}
            text={translate('deleteAction', userConfig?.language)}
            icon={<DeleteOutlineIcon style={{ fontSize: 22 }} />}
            style={{ width: 220, height: 26 }}
          />
        )}
      </div>
    </div>
  );
};
