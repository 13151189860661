import '../documents.css';

import { useEffect, useReducer, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../../recoil/atoms/allUserConfigsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import reportReducer from './reportReducer';

import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Dialog } from '@mui/material';

import {
  StringToStringMap,
  StringToNumberMap,
  StringToBooleanMap,
  StringToAnyMap,
  PlayerOverview,
  PlayerReport,
  PlayerReportEdit,
  PlayerEntry,
  ReportType,
} from '../../../types';

import { translate } from '../../../../common/language/translations';
import { getStrictPlayerId } from '../../../utils/playerUtils';
import { FileUpload } from '../../controls/input/FileUpload';
import { ReportScores } from './ReportScores';
import { addReport, updateReport } from '../../../services/firestore/reports';
import { getLatestReportVersion } from './reportVersions';
import { uploadFiles } from '../../../services/storage/files';
import { generateRandomId } from '../../../utils/utils';
import { ReportEditHistoryModal } from '../../modals/historyModals/ReportEditHistoryModal';
import { getClubColor } from '../../../static/clubConfigs';
import { initializeState } from './reportInitialState';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { InputField } from '../../controls/input/InputField';
import { DateInputField } from '../../controls/input/DateInputField';
import { TextArea } from '../../controls/input/TextArea';
import { modalPaperProps } from '../../modals/globalModals/GlobalModals';
import { dateStringToLocaleFormat } from '../../../utils/dateUtils';
import { PlayerSimpleTable } from '../../tables/playerSimpleTable/PlayerSimpleTable';
import { getShortenedFileName, openPdfInNewTab } from '../../../utils/fileUtils';


interface ReportProps {
  newReportTypeSelected: ReportType | undefined;
  existingReportSelected: PlayerReport | undefined;

  reportHasUnsavedChanges: boolean;
  setReportHasUnsavedChanges: (value: boolean) => void;

  handleCloseIconClick: () => void;
  addOrUpdateReport: (report: PlayerReport, isNewReport: boolean) => void;

  playerOverview: PlayerOverview | undefined;
  playerEntry?: PlayerEntry; // provided for players without data

  averageMatchReportScores?: StringToNumberMap;

  isArchiveView?: boolean;
  isLoadingSelectedPlayerOverview?: boolean;
}


export const Report: React.FC<ReportProps> = ({
  newReportTypeSelected,
  existingReportSelected,

  reportHasUnsavedChanges,
  setReportHasUnsavedChanges,

  handleCloseIconClick,
  addOrUpdateReport,

  playerOverview,
  playerEntry,

  averageMatchReportScores,

  isArchiveView,
  isLoadingSelectedPlayerOverview,
}) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openTextModal } = useOpenGlobalModal();

  const [isLoading, setIsLoading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);


  const [state, dispatch] = useReducer(
    reportReducer,
    undefined,
    () => initializeState(newReportTypeSelected, existingReportSelected, userConfig?.club, playerOverview?.club?.name)
  );


  const playerData = playerOverview ?? playerEntry;
  const playerTableData = isLoadingSelectedPlayerOverview
    ? undefined
    : (playerData ?? {
      id: existingReportSelected?.playerId,
      fullname: existingReportSelected?.playerName,
    });

  const onPlayerClick = (player: StringToAnyMap) => {
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: playerOverview
    });
  };


  const isValidReport = () => {
    if (state.reportType === 'Comment' && state.comment.trim().length === 0) {
      dispatch({ type: 'SET_COMMENT', payload: '' });
      return false;
    }

    if (state.reportType === 'Attachment' && state.fileNames.length === 0) {
      return false;
    }

    const newRequiredFieldsToShow: StringToBooleanMap = {};

    if (state.reportType === 'ScoutingReport') {
      Object.entries(state.scores).forEach(([scoreKey, scoreValue]) => {
        if (!scoreValue) {
          newRequiredFieldsToShow[scoreKey + 'ShowRequired'] = true;
        }
      });
    }

    if (state.reportType === 'MatchReport') {
      if (!state.scores.overallScore) {
        newRequiredFieldsToShow['overallScoreShowRequired'] = true;
      }
      if (state.matchReportInfo?.team.trim().length === 0) {
        newRequiredFieldsToShow['teamShowRequired'] = true;
      }
      if (state.matchReportInfo?.opponent.trim().length === 0) {
        newRequiredFieldsToShow['opponentShowRequired'] = true;
      }
      if (!state.matchReportInfo?.matchDate) {
        newRequiredFieldsToShow['matchDateShowRequired'] = true;
      }
    }

    if (Object.keys(newRequiredFieldsToShow).length > 0) {
      dispatch({ type: 'SET_REQUIRED_FIELDS_TO_SHOW', payload: newRequiredFieldsToShow });
      return false;
    }

    return true;
  };


  const handleUpdateReport = async () => {
    if (!userConfig || !existingReportSelected) return;

    // first we check if there are any edits to the report
    const currentReportFields: StringToAnyMap = { ...existingReportSelected.reportFields };
    let newReportFields: StringToAnyMap = {
      'comment': state.comment,
    };

    if (state.reportType === 'ScoutingReport' || state.reportType === 'MatchReport') {
      newReportFields = {
        ...newReportFields,
        ...state.scores,
      };

      if (state.reportType === 'MatchReport') {
        newReportFields = {
          ...newReportFields,
          ...state.matchReportInfo,
        };
      }
    }

    let hasChanges = false;
    for (const key in newReportFields) {
      if (newReportFields[key] !== currentReportFields[key]) {
        hasChanges = true;
        break;
      }
    }

    // files are handled separately - we must be careful to not overwrite any files in storage
    const uploadedFiles = state.additionalReportState.uploadedFiles;
    const newFilesHaveBeenUploaded = uploadedFiles && uploadedFiles.length > 0;
    const existingFilesHaveBeenRemoved = state.fileNames.length !== (currentReportFields.fileNames ?? []).length;
    const hasFileChanges = newFilesHaveBeenUploaded || existingFilesHaveBeenRemoved;

    if (!hasChanges && !hasFileChanges) {
      dispatch({ type: 'SET_IS_EDIT_MODE', payload: false });
      setReportHasUnsavedChanges(false);
      return;
    }

    if (newFilesHaveBeenUploaded) {
      setIsLoading(true);

      // if any file upload would cause a file to be overwritten, we append a random identifier to the file name
      // we need to check the file names for all edits of the report
      const fileNamesInStorage: string[] = [];
      if (currentReportFields.fileNames) {
        fileNamesInStorage.push(...currentReportFields.fileNames);
      }
      if (existingReportSelected.previousEdits) {
        existingReportSelected.previousEdits.forEach(edit => {
          if (edit.reportFields.fileNames) {
            fileNamesInStorage.push(...edit.reportFields.fileNames);
          }
        });
      }

      const filesToUpload = uploadedFiles.map(file => {
        if (fileNamesInStorage.includes(file.name)) {
          const versionedFileName = file.name + '_' + generateRandomId(16);
          return new File([file], versionedFileName);
        }
        return file;
      });

      const newFileUrls = await uploadFiles(filesToUpload, 'reports', existingReportSelected.id as string, userConfig.club, currentUser);
      const fileNameToNewUrl: StringToStringMap = {};
      newFileUrls.forEach((url, index) => {
        fileNameToNewUrl[uploadedFiles[index].name] = url;
      });

      // the url to storage will be the one from fileNameToNewUrl if the file was added now, otherwise it will be the url in fileUrls at the same index
      const newFileUrlsToSet = state.fileNames.map(
        fileName => fileNameToNewUrl[fileName] ?? state.fileUrls[state.fileNames.indexOf(fileName)]
      );

      if (newFileUrlsToSet && newFileUrlsToSet.length > 0) {
        newReportFields.fileNames = state.fileNames;
        newReportFields.fileUrls = newFileUrlsToSet;
      }
    }
    else {
      newReportFields.fileNames = state.fileNames;
      newReportFields.fileUrls = state.fileUrls;
    }

    // we always shift the dates such that the report date reflects the date the report was added, and every edit date reflects the date of the next edit
    const newEditEntry: PlayerReportEdit = { dateOverwritten: new Date().toISOString(), reportFields: currentReportFields };
    const newPreviousEdits: PlayerReportEdit[] = existingReportSelected.previousEdits
      ? [...existingReportSelected.previousEdits, newEditEntry]
      : [newEditEntry];

    const newValues = { reportFields: newReportFields, previousEdits: newPreviousEdits };
    await updateReport(
      existingReportSelected.id as string,
      newValues,
      userConfig.club,
      currentUser,
      { action: 'edited', reportType: existingReportSelected.reportType },
    );

    const updatedReport: PlayerReport = {
      ...existingReportSelected,
      reportFields: newReportFields,
      previousEdits: newPreviousEdits,
    };
    addOrUpdateReport(updatedReport, false);

    setIsLoading(false);
  };


  const handleAddReport = async () => {
    if (!userConfig || !newReportTypeSelected || !playerData) return;

    const report: PlayerReport = {
      playerId: getStrictPlayerId(playerData.id),
      playerName: playerData.fullname,
      userEmail: userConfig.email,
      date: new Date().toISOString(),
      isDeleted: false,
      reportType: newReportTypeSelected,
      reportVersion: getLatestReportVersion(userConfig.club),
      reportFields: {
        'comment': state.comment,
      },
    };

    if (newReportTypeSelected === 'ScoutingReport' || newReportTypeSelected === 'MatchReport') {
      report.reportFields = {
        ...report.reportFields,
        ...state.scores,
      };

      if (newReportTypeSelected === 'MatchReport') {
        report.reportFields = {
          ...report.reportFields,
          ...state.matchReportInfo,
        };
      }
    }

    // (1) add report to firestore and get id
    const reportId = await addReport(report, userConfig.club, currentUser);

    if (reportId) {
      const newReport: PlayerReport = { ...report, id: reportId };

      // if attachments are uploaded, we do two additional steps:
      // (2) upload files to storage and get urls, (3) update report with urls both in firestore and in state
      if (state.additionalReportState.uploadedFiles) {
        setIsLoading(true);

        const newFileUrls = await uploadFiles(state.additionalReportState.uploadedFiles, 'reports', reportId, userConfig.club, currentUser);

        if (newFileUrls && newFileUrls.length > 0) {
          const newValues = { 'reportFields.fileNames': state.fileNames, 'reportFields.fileUrls': newFileUrls };
          const success = await updateReport(reportId, newValues, userConfig.club, currentUser);

          if (success) {
            newReport.reportFields.fileNames = state.fileNames;
            newReport.reportFields.fileUrls = newFileUrls;
          }
        }

        setIsLoading(false);
      }

      addOrUpdateReport(newReport, true);
    }
  };


  const handleSubmitReport = async () => {
    setSubmitButtonDisabled(true);

    if (isValidReport()) {
      if (state.additionalReportState.isEditMode) {
        await handleUpdateReport();
      }
      else {
        await handleAddReport();
      }
    }

    setSubmitButtonDisabled(false);
  };


  const handleDeleteOrRestoreReport = async () => {
    if (!existingReportSelected || !userConfig) return;

    setSubmitButtonDisabled(true);

    const newIsDeletedStatus = !existingReportSelected.isDeleted;
    const action = newIsDeletedStatus ? 'deleted' : 'restored';
    const newValues = { isDeleted: newIsDeletedStatus };

    const success = await updateReport(
      existingReportSelected.id as string,
      newValues,
      userConfig.club,
      currentUser,
      { action: action, reportType: existingReportSelected.reportType },
    );

    if (success) {
      addOrUpdateReport({ ...existingReportSelected, isDeleted: newIsDeletedStatus }, false);
    }

    setSubmitButtonDisabled(false);
  };


  const handleUploadFile = (file: File) => {
    const newFileName = file.name;
    if (state.fileNames.includes(newFileName)) {
      openTextModal(
        translate('errorOccurred', userConfig?.language),
        translate('fileAlreadyUploaded', userConfig?.language),
      );
      return;
    }

    dispatch({ type: 'UPLOAD_FILE', payload: file });
  };


  const handleRemoveFile = (fileUrl: string, fileName: string, event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    dispatch({ type: 'REMOVE_FILE', payload: { fileUrl, fileName } });
  };


  useEffect(() => {
    // revoke the object url to avoid memory leaks
    return () => {
      state.fileUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    // if report already has unsaved changes, we don't bother to recheck if the report ends up back in the same state
    if (!reportHasUnsavedChanges) {

      // if this is a new report, the report has unsaved changes if any data is entered
      if (!existingReportSelected) {
        if (
          Object.values(state.scores).some(score => score > 0) ||
          state.comment.trim().length > 0 ||
          (state.matchReportInfo && state.matchReportInfo.team !== (playerOverview?.club?.name ?? '')) ||
          (state.matchReportInfo && state.matchReportInfo.opponent.length > 0) ||
          (state.matchReportInfo && state.matchReportInfo.matchDate !== new Date().toISOString().split('T')[0]) ||
          state.fileNames.length > 0
        ) {
          setReportHasUnsavedChanges(true);
        }
      }

      // if report is being edited, we just assume unsaved changes and don't bother to check if the current state actually has unsaved changes
      else if (state.additionalReportState.isEditMode) {
        setReportHasUnsavedChanges(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    existingReportSelected,
    reportHasUnsavedChanges,
  ]);


  useEffect(() => {
    setIsLoading(false);
    setReportHasUnsavedChanges(false);

    dispatch({
      type: 'INITIALIZE_STATE',
      payload: initializeState(newReportTypeSelected, existingReportSelected, userConfig?.club, playerOverview?.club?.name)
    });
  }, [existingReportSelected]); // eslint-disable-line react-hooks/exhaustive-deps


  const getPreviousVersionDate = () => {
    if (!existingReportSelected || state.additionalReportState.activePreviousEditIndex === undefined) return '';

    const allDates: string[] = [
      existingReportSelected.date.split('T')[0],
      ...(existingReportSelected.previousEdits ?? []).map(edit => edit.dateOverwritten.split('T')[0]),
    ];

    return dateStringToLocaleFormat(allDates[state.additionalReportState.activePreviousEditIndex]);
  };


  const isReadOnlyMode = existingReportSelected !== undefined && !state.additionalReportState.isEditMode;


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='document-container'>

      {existingReportSelected && (
        <Dialog
          open={state.additionalReportState.isEditHistoryModalOpen}
          onClose={() => dispatch({ type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN', payload: false })}
          PaperProps={modalPaperProps}
        >
          <ReportEditHistoryModal
            dispatch={dispatch}
            existingReportSelected={existingReportSelected}
            activePreviousEditIndex={state.additionalReportState.activePreviousEditIndex}
          />
        </Dialog>
      )}

      <div className='fast-fade-in'>
        <IconButton
          onClick={handleCloseIconClick}
          icon={<CloseIcon style={{ fontSize: 24 }} />}
          size={26}
          style={{ position: 'absolute', top: 'calc(2vh - 6px)', right: 'calc(3% - 4px)', zIndex: 5 }}
          startWhite={true}
          whiteBackgroundOnHover={true}
        />

        {existingReportSelected
          && existingReportSelected.previousEdits
          && existingReportSelected.previousEdits.length > 0
          && !state.additionalReportState.isEditMode
          && (
            <IconButton
              onClick={() => dispatch({ type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN', payload: true })}
              icon={<LibraryBooksIcon style={{ fontSize: 18 }} />}
              size={26}
              style={{ position: 'absolute', top: 'calc(2vh - 6px)', left: 'calc(3% - 4px)', zIndex: 5 }}
              iconColor={state.additionalReportState.activePreviousEditIndex !== undefined ? clubColor : undefined}
              startWhite={true}
              whiteBackgroundOnHover={true}
              hoverText={translate('showEditHistory', userConfig?.language)}
            />
          )}

        {isArchiveView && playerTableData && (
          <div className='report-player-table-container' key={existingReportSelected?.id ?? 'newReport'}>
            <div className='fade-in'>
              <PlayerSimpleTable
                tableData={[playerTableData]}
                onPlayerClick={onPlayerClick}
                tableType={'playerButton'}
              />
            </div>
          </div>
        )}

        {existingReportSelected && !state.additionalReportState.isEditMode && (
          <div className={'document-title' + (isArchiveView ? ' report-title-archive' : '')}>

            {translate(existingReportSelected.reportType, userConfig?.language)}

            <div className='document-title-divider'>&nbsp;</div>

            <div className='document-title-info' style={{ fontSize: !allUsersWithSameClub[existingReportSelected.userEmail] ? 13 : undefined }}>
              {allUsersWithSameClub[existingReportSelected.userEmail]
                ? allUsersWithSameClub[existingReportSelected.userEmail].name
                : translate('unknownUserShort', userConfig?.language)
              }
              {','}
            </div>
            <div style={{ marginLeft: 4 }}>
              {existingReportSelected.date && dateStringToLocaleFormat(existingReportSelected.date.split('T')[0])}
            </div>
          </div>
        )}

        {(newReportTypeSelected || state.additionalReportState.isEditMode) && (
          <div className={'document-title' + (isArchiveView ? ' report-title-archive' : '')}>
            {translate(newReportTypeSelected ? ('new' + newReportTypeSelected) : ('edit' + state.reportType), userConfig?.language)}
          </div>
        )}

        <div className='document-horizontal-divider document-horizontal-top-divider' />

        <div className='document-horizontal-divider document-horizontal-bottom-divider' />

        <div className='document-submit-section'>
          {!isLoading && !isReadOnlyMode && userConfig && (
            <TextButton
              onClick={() => !submitButtonDisabled ? handleSubmitReport() : null}
              text={translate(state.additionalReportState.isEditMode ? 'confirmEdits' : ('submit' + state.reportType), userConfig.language)}
              icon={state.additionalReportState.isEditMode ? <CheckIcon style={{ fontSize: 21 }} /> : <ArrowRightAltIcon style={{ fontSize: 21 }} />}
              buttonType={'transparent'}
            />
          )}

          {!isLoading
            && isReadOnlyMode
            && userConfig
            && existingReportSelected.userEmail === userConfig.email
            && state.additionalReportState.activePreviousEditIndex === undefined
            && (
              <TextButton
                onClick={() => dispatch({ type: 'SET_IS_EDIT_MODE', payload: true })}
                text={translate('edit' + state.reportType, userConfig.language)}
                icon={<EditNoteIcon style={{ fontSize: 21 }} />}
                buttonType={'transparent'}
                style={{ width: 215 }}
              />
            )}

          {!isLoading
            && isReadOnlyMode
            && userConfig
            && existingReportSelected.userEmail === userConfig.email
            && state.additionalReportState.activePreviousEditIndex === undefined
            && (
              <TextButton
                onClick={() => !submitButtonDisabled ? handleDeleteOrRestoreReport : null}
                text={
                  translate(existingReportSelected.isDeleted ? 'restore' : 'delete', userConfig.language) + ' ' +
                  translate(
                    existingReportSelected.reportType === 'Comment'
                      ? 'comment'
                      : existingReportSelected.reportType === 'Attachment'
                        ? 'attachment'
                        : 'report',
                    userConfig.language,
                    true
                  )
                }
                icon={existingReportSelected.isDeleted ? <RestoreIcon style={{ fontSize: 21 }} /> : <DeleteOutlineIcon style={{ fontSize: 21 }} />}
                buttonType={'transparent'}
                style={{ width: 215 }}
              />
            )}

          {state.additionalReportState.activePreviousEditIndex !== undefined && (
            <div className='document-submit-text'>
              {translate('aPreviousVersionFrom', userConfig?.language)}
              {getPreviousVersionDate()}
            </div>
          )}

          {isLoading && (
            <div style={{ margin: 'auto', marginBottom: -2 }}>
              <div className='loading-spinner loading-spinner-small' />
            </div>
          )}
        </div>

      </div>

      <div className='document-content-section'>
        {state.reportType === 'MatchReport' && (
          <div className='report-content-left-column fast-fade-in'>

            <div className='report-content-rating-row report-content-input-row report-content-input-top-row'>
              <div className='report-content-input-title'>
                {translate('teamOfPlayer', userConfig?.language)}
              </div>
              <InputField
                value={state.matchReportInfo?.team}
                placeholder={translate('provideTeam', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_TEAM', payload: event.target.value })}
                disabled={isReadOnlyMode}
                isSquare={true}
                height={24}
                width={150}
              />
              {state.additionalReportState.requiredFieldsToShow.teamShowRequired && (
                <div className='document-required-star report-field-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-content-rating-row report-content-input-row'>
              <div className='report-content-input-title'>
                {translate('opponentTeam', userConfig?.language)}
              </div>
              <InputField
                value={state.matchReportInfo?.opponent}
                placeholder={translate('provideTeam', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_OPPONENT', payload: event.target.value })}
                disabled={isReadOnlyMode}
                isSquare={true}
                height={24}
                width={150}
              />
              {state.additionalReportState.requiredFieldsToShow.opponentShowRequired && (
                <div className='document-required-star report-field-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-content-rating-row report-content-input-row'>
              <div className='report-content-input-title'>
                {translate('matchDate', userConfig?.language)}
              </div>
              <DateInputField
                value={state.matchReportInfo?.matchDate}
                onChange={(date) => dispatch({ type: 'SET_MATCH_DATE', payload: date })}
                disabled={isReadOnlyMode}
                popperPlacement='bottom-start'
              />
              {state.additionalReportState.requiredFieldsToShow.matchDateShowRequired && (
                <div className='document-required-star report-field-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-text-area-container-match-report'>
              <TextArea
                value={state.comment}
                placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                disabled={isReadOnlyMode}
              />
            </div>
          </div>
        )}

        {state.reportType === 'ScoutingReport' && (
          <div className='report-content-left-column fast-fade-in'>
            <div className={
              'report-text-area-container-scouting-report report-text-area-container-scouting-report-' +
              (state.fileNames.length > 1 ? 'multiple' : state.fileNames.length)
            }>
              <TextArea
                value={state.comment}
                placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                disabled={isReadOnlyMode}
              />
            </div>

            <div
              className={
                'document-file-container report-file-container' +
                (state.fileNames.length < 2 ? (' report-file-container-' + state.fileNames.length) : '')
              }
            >

              <div
                className={'document-input-title report-file-input-title-' + (state.fileNames.length > 1 ? 'multiple' : state.fileNames.length)}
                style={{ width: 'fit-content', position: 'relative' }}
              >
                <div style={{ paddingLeft: state.fileNames.length > 0 ? 10 : undefined }}>
                  {translate(isReadOnlyMode && state.fileNames.length === 0 ? 'noAttachments' : 'attachments', userConfig?.language)}
                </div>

                {state.fileNames.length > 0 && (
                  <div style={{ position: 'absolute', left: -16, top: 0.5, fontSize: 12, color: '#ffffffaa' }}>
                    ({state.fileNames.length})
                  </div>
                )}

                {!isReadOnlyMode && (
                  <div style={{ position: 'absolute', right: -32, top: -3, zIndex: 1 }}>
                    <FileUpload handleFileUpload={handleUploadFile} language={userConfig?.language ?? 'en'} buttonType='iconButton' />
                  </div>
                )}
              </div>

              {state.fileUrls && state.fileUrls.length > 0 && (
                <div
                  className='document-file-container-existing-files-container'
                  style={{ height: state.fileUrls.length > 1 ? undefined : 55, paddingTop: state.fileUrls.length > 1 ? undefined : 10 }}
                >
                  <div className='document-file-container-existing-files report-file-container-existing-files'>
                    {state.fileUrls.map((fileUrl, index) => {
                      if (state.fileNames.length <= index) return null;

                      const fileName = state.fileNames[index];
                      return (
                        <div
                          key={index}
                          className='document-file-container-existing-file report-file-container-existing-file'
                          onClick={() => openPdfInNewTab(fileUrl, fileName, 'reports', currentUser)}
                        >
                          {!isReadOnlyMode && (
                            <IconButton
                              onClick={(event?: React.MouseEvent) => handleRemoveFile(fileUrl, fileName, event)}
                              icon={<CloseIcon style={{ fontSize: 18 }} />}
                              size={24}
                              style={{ position: 'absolute', marginLeft: -36 }}
                              hoverStyle={{ right: 32 }}
                            />
                          )}
                          <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 6 }} />
                          {getShortenedFileName(fileName, 22)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {(state.reportType === 'MatchReport' || state.reportType === 'ScoutingReport') && (
          <div className='report-content-right-column'>

            {newReportTypeSelected === 'ScoutingReport' && averageMatchReportScores && Object.keys(averageMatchReportScores).length > 0 && (
              <div className='report-content-average-title match-report-content-average-title fast-fade-in'>
                {translate('averageMatchReports', userConfig?.language)}
              </div>
            )}

            <ReportScores
              scores={state.scores}
              handleRatingChange={isReadOnlyMode
                ? undefined
                : (scoreKey, value) => dispatch({ type: 'SET_SCORE', payload: { scoreKey: scoreKey, scoreValue: value } })
              }
              averageMatchReportScores={state.reportType === 'ScoutingReport' ? averageMatchReportScores : undefined}
              requiredScoresToShow={state.additionalReportState.requiredFieldsToShow}
            />
          </div>
        )}

        {state.reportType === 'Comment' && (
          <div className='report-text-area-container-comment'>
            <TextArea
              value={state.comment}
              placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideCommentOrInformation', userConfig?.language)}
              onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
              disabled={isReadOnlyMode}
            />
          </div>
        )}

        {state.reportType === 'Attachment' && (
          <div className='full-size flex-column'>

            <div className='document-text-area-container' style={{ height: '62%', position: 'absolute', top: '3%' }}>
              {!isReadOnlyMode && (
                <div style={{ margin: 'auto', paddingTop: 20, paddingBottom: 10 }}>
                  <FileUpload handleFileUpload={handleUploadFile} language={userConfig?.language ?? 'en'} buttonType='textButton' />
                </div>
              )}

              <div className='document-file-container-existing-files report-file-container-existing-files-attachment'>
                {state.fileUrls && state.fileUrls.map((fileUrl, index) => {
                  if (!state.fileNames || state.fileNames.length <= index) return null;

                  const fileName = state.fileNames[index];
                  return (
                    <div
                      key={index}
                      className='document-file-container-existing-file'
                      style={{ width: 'fit-content', maxWidth: 'none', marginTop: 8, marginBottom: 8, fontSize: 13, padding: '5px 8px' }}
                      onClick={() => openPdfInNewTab(fileUrl, fileName, 'reports', currentUser)}
                    >
                      {!isReadOnlyMode && (
                        <IconButton
                          onClick={(event?: React.MouseEvent) => handleRemoveFile(fileUrl, fileName, event)}
                          icon={<CloseIcon style={{ fontSize: 18 }} />}
                          size={24}
                          style={{ position: 'absolute', marginLeft: -36 }}
                          hoverStyle={{ right: 32 }}
                        />
                      )}
                      <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 8 }} />
                      {getShortenedFileName(fileName, 60)}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='report-text-area-container-attachment'>
              <TextArea
                value={state.comment}
                placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                disabled={isReadOnlyMode}
              />
            </div>
          </div>
        )}

      </div>

    </div >
  );
};
