import '../tables.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { StringToAnyMap, AggregationRule } from '../../../types';
import { translate } from '../../../../common/language/translations';
import { Dialog } from '@mui/material';
import { RoleConfigAggregationModal } from '../../modals/RoleConfigAggregationModal';
import { deepCloneObject } from '../../../utils/utils';
import { getClubColor } from '../../../static/clubConfigs';
import { modalPaperProps } from '../../modals/globalModals/GlobalModals';


export const roleConfigTableWidth = 125;


interface RoleConfigTableProps {
  tableData: StringToAnyMap[];
  onRoleClick: (role: StringToAnyMap) => void;
  positionKey: string;
  draggedRole: StringToAnyMap | undefined;
  setDraggedRole?: (role: StringToAnyMap | undefined) => void;
  handleRoleDrop?: (newPosition: string, positionIndex?: number) => void;
  tableHoverDisabled?: boolean;
  droppedRole: StringToAnyMap | undefined;
  confirmNewAggregationRule?: (newAggregation: AggregationRule, positionKey: string) => void;
}

export const RoleConfigTable: React.FC<RoleConfigTableProps> = ({
  tableData,
  onRoleClick,
  positionKey,
  draggedRole,
  setDraggedRole,
  handleRoleDrop,
  tableHoverDisabled,
  droppedRole,
  confirmNewAggregationRule,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [isRoleConfigAggregationModalOpen, setIsRoleConfigAggregationModalOpen] = useState(false);

  const overlayContainerRef = useRef<HTMLDivElement>(null);
  const [tableIsDraggedOver, setTableIsDraggedOver] = useState(false);
  const [draggedRoleIdWithDelay, setDraggedRoleIdWithDelay] = useState<string | undefined>(undefined);
  const [originalDraggedRoleHasMoved, setOriginalDraggedRoleHasMoved] = useState<boolean>(false);
  const [originalRoleHasLeftOriginalTable, setOriginalRoleHasLeftOriginalTable] = useState<boolean>(false);


  const [data, setData] = useState<StringToAnyMap[]>(tableData);
  useEffect(() => {
    setData(deepCloneObject(tableData));
  }, [tableData]);


  useEffect(() => {
    if (draggedRole) {
      setTimeout(() => {
        setDraggedRoleIdWithDelay(draggedRole.id);
      }, 1);
    }
    else {
      setDraggedRoleIdWithDelay(undefined);
    }
  }, [draggedRole]);


  // get the index of the dragged role copy shown in the table, assuming the original dragged role does not exist
  const getDropIndex = () => {
    if (!draggedRole) return -1;

    let dropIndex = data.findIndex(role => role.id === draggedRole.id + '-copy');
    if (originalDraggedRoleHasMoved && draggedRole.positionKey === positionKey) {
      if (dropIndex > draggedRole.positionIndex) dropIndex--;
    }

    return dropIndex;
  };


  // get the next index of the dragged role copy shown in the table when entering drop zone with index adjusetdDropZoneIndex
  const getNewDropIndex = (adjusetdDropZoneIndex: number) => {
    if (!draggedRole) return;

    const dropIndex = getDropIndex();

    // if the copy is already in the table, the two drop zones around the copy (dropIndex and dropIndex + 1) both belong to the copy
    // when entering a new drop zone with a higher index than the copy, the adjusetdDropZoneIndex must be 2 higher to trigger 1 move downwards
    // when entering a new drop zone with a lower index than the copy, the copy just moves upwards to the new index
    const dropIndexAdjusetdForCopy = dropIndex !== -1 && adjusetdDropZoneIndex > dropIndex ? adjusetdDropZoneIndex - 1 : adjusetdDropZoneIndex;
    if (dropIndexAdjusetdForCopy === dropIndex) return;

    // the index logic so far assumes the original dragged role is not in the table, but when inserting here, we must adjust for the original row
    const newDropIndex = originalDraggedRoleHasMoved && draggedRole.positionKey === positionKey && adjusetdDropZoneIndex > draggedRole.positionIndex
      ? dropIndexAdjusetdForCopy + 1
      : dropIndexAdjusetdForCopy;

    return newDropIndex;
  };


  const handleDragStart = (role: StringToAnyMap, index: number) => {
    if (positionKey && setDraggedRole) {
      setDraggedRole({
        ...role,
        'positionKey': positionKey,
        'positionIndex': index,
      });
    }
  };


  const handleDragEnd = () => {
    if (setDraggedRole) {
      setDraggedRole(undefined);
    }
    setTableIsDraggedOver(false);
    setOriginalDraggedRoleHasMoved(false);
    setOriginalRoleHasLeftOriginalTable(false);
  };


  const handleDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (handleRoleDrop) {
      event.preventDefault();
      if (positionKey && draggedRole) {
        let dropIndex: number | undefined = getDropIndex();
        dropIndex = dropIndex === -1 ? undefined : dropIndex;
        const dropShouldTriggerUpdate = positionKey !== draggedRole.positionKey || dropIndex !== draggedRole.positionIndex;

        // the drop should not trigger an update if the player is dropped in the same position and index
        // if no update should be triggered, we must abort the drop and reset the data
        if (dropShouldTriggerUpdate) {
          handleRoleDrop(positionKey, dropIndex === -1 ? undefined : dropIndex);
          const newData = [...data].filter(role => role.id !== draggedRole.id);
          setData(newData);
        }

        else {
          setData(tableData);
          handleDragEnd();
        }
      }
    }
    setTableIsDraggedOver(false);
    setOriginalDraggedRoleHasMoved(false);
    setOriginalRoleHasLeftOriginalTable(false);
  };


  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };


  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    const relatedTarget = event.relatedTarget as Node | null;
    if (overlayContainerRef?.current && !overlayContainerRef.current.contains(relatedTarget)) {
      setTableIsDraggedOver(false);
      setOriginalDraggedRoleHasMoved(true);
      setOriginalRoleHasLeftOriginalTable(true);
      if (draggedRole) {
        const newData = [...data].filter(role => role.id !== draggedRole.id + '-copy');
        setData(newData);
      }
    }
  };


  const handleDragEnter = (index: number) => {
    setTableIsDraggedOver(true);
    if (draggedRole) {
      const newDropIndex = getNewDropIndex(index);
      if (newDropIndex === undefined) return;

      const newData = [...data].filter(role => (role.id !== draggedRole.id + '-copy'));
      newData.splice(newDropIndex, 0, { ...draggedRole, 'id': draggedRole.id + '-copy' });

      setData(newData);
      setOriginalDraggedRoleHasMoved(true);
    }
  };


  // goalkeeper (outfield) roles can only be dropped on the goalkeeper position (outfield positions)
  // we do not allow more than 3 roles per position
  // we do not allow duplicate roles -> if a role is dragged, all tables with that role are locked, unless the table is the original table
  const tableContainsDraggedRole = tableData.some(role => role['id'] === draggedRoleIdWithDelay);
  const tableContainsOriginalDraggedRole = draggedRole?.positionKey === positionKey;
  const tableIsLocked = (tableContainsDraggedRole || tableData.length >= 3) && !tableContainsOriginalDraggedRole;

  const tableMatchesPositionGroupOfDraggedRole = (
    (positionKey === 'GK' && draggedRole?.['positionGroup'] === 'GK') ||
    (positionKey !== 'GK' && draggedRole?.['positionGroup'] !== 'GK')
  );
  const isValidDropZone = handleRoleDrop !== undefined
    && draggedRoleIdWithDelay
    && tableMatchesPositionGroupOfDraggedRole
    && !tableIsLocked;


  const getBoxShadow = () => {
    if (isValidDropZone) {
      if (tableIsDraggedOver) {
        return '0px 0px 0px 3px ' + clubColor;
      }
      return '0px 0px 0px 3px #cdd0e773';
    }
    return 'none';
  };


  const getTableRowClassName = (role: StringToAnyMap, index: number) => {
    // if this is the original dragged role, we show it until it has moved, at which point we hide the original and show the copy
    if (role.id === draggedRoleIdWithDelay && originalDraggedRoleHasMoved && draggedRole?.positionKey === positionKey) {
      return 'player-simple-table-row-dragged-original';
    }

    if (droppedRole && role.id === droppedRole.id) {
      if (droppedRole.droppedPositionKey === positionKey) {
        return 'player-simple-table-row-dropped-new';
      }
      if (droppedRole.positionKey === positionKey) {
        return 'player-simple-table-row-dropped-old';
      }
    }

    else if (role.id === draggedRoleIdWithDelay || role.id === (draggedRoleIdWithDelay + '-copy')) {
      // if the original dragged role has moved, we must account for the offset of one invisible row
      let adjustedIndex = index;
      let adjusetdTableLength = tableData.length;
      if (draggedRole && draggedRole.positionKey === positionKey) {
        if (index > draggedRole.positionIndex) adjustedIndex--;
        adjusetdTableLength--;
      }

      if (adjustedIndex === 0) {
        return 'player-simple-table-row-dragged-copy-top';
      }
      if (adjustedIndex === adjusetdTableLength) {
        return 'player-simple-table-row-dragged-copy-bottom';
      }
      return 'player-simple-table-row-dragged-copy-middle';
    }

    return 'player-simple-table-row' + (!tableHoverDisabled ? ' player-simple-table-row-hover' : '');
  };


  const showRoleConfigAggregationRule = data.length > 1 && !draggedRole;
  const roleConfigAggregationRule: AggregationRule = data.length > 1
    ? data[0]['aggregationRule']
    : undefined;


  const showEmptyTableDropZone = tableData.length === 0 ||
    (draggedRole?.positionKey === positionKey && data.every(role => (role.id === draggedRoleIdWithDelay || role.id === (draggedRoleIdWithDelay + '-copy'))));


  const clubColor = getClubColor(userConfig?.club ?? '');

  const tableHeight = 28;


  return (
    <div>

      {isRoleConfigAggregationModalOpen && confirmNewAggregationRule && positionKey && (
        <Dialog
          open={isRoleConfigAggregationModalOpen}
          onClose={() => setIsRoleConfigAggregationModalOpen(false)}
          PaperProps={modalPaperProps}
        >
          <RoleConfigAggregationModal
            closeModal={() => setIsRoleConfigAggregationModalOpen(false)}
            currentAggregation={roleConfigAggregationRule}
            confirmNewAggregation={(newAggregation) => {
              confirmNewAggregationRule(newAggregation, positionKey);
              setIsRoleConfigAggregationModalOpen(false);
            }}
          />
        </Dialog>
      )}

      {data.length > 0 && (
        <div className={showEmptyTableDropZone ? 'player-simple-table-container-hidden' : 'player-simple-table-container'}>

          {showRoleConfigAggregationRule && (
            <div
              className='role-config-table-aggregation-rule-container fast-fade-in'
              title={translate('editRule', userConfig?.language)}
              onClick={() => setIsRoleConfigAggregationModalOpen(true)}
            >
              {translate(roleConfigAggregationRule + 'Aggregation', userConfig?.language)}
            </div>
          )}

          {isValidDropZone && (
            <div
              ref={overlayContainerRef}
              className='role-config-table-overlay-container'
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              {(Array.from({ length: data.length + 1 }, (_, i) => i)).map(index => {

                // if the original dragged role is in this table and has moved, we must account for the offset of one invisible row
                let adjustedIndex = index;
                if (draggedRole && draggedRole.positionKey === positionKey) {
                  if (index === draggedRole.positionIndex) return null;
                  if (index > draggedRole.positionIndex) adjustedIndex--;
                }

                return (
                  <div
                    key={index}
                    className='role-config-table-overlay'
                    style={{ top: adjustedIndex * tableHeight }}
                    onDragOver={handleDragOver}
                    onDragEnter={() => handleDragEnter(adjustedIndex)}
                    onDrop={(event) => handleDragDrop(event)}
                  />
                );
              })}
            </div>
          )}

          <div
            className={'player-simple-table' + (showRoleConfigAggregationRule ? ' role-config-table-with-aggregation-rule' : '')}
            style={{ boxShadow: getBoxShadow() }}
          >
            <div className='player-simple-table-body'>
              {data.map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  className={'table-cell ' + getTableRowClassName(row, rowIndex)}
                  style={{ width: roleConfigTableWidth, height: tableHeight, fontSize: 14 }}
                  onClick={() => onRoleClick(row)}
                  draggable={positionKey !== undefined && setDraggedRole !== undefined && row.id !== droppedRole?.id}
                  onDragStart={() => handleDragStart(row, rowIndex)}
                  onDragEnd={() => handleDragEnd()}
                >
                  {row.name}
                </div>
              ))}
            </div>
          </div>

        </div>
      )}

      {data.length === 0 && !isValidDropZone && (
        <div
          className='player-simple-table-empty-container player-simple-table-empty-container-drag-active role-config-table-empty-container'
          style={{ height: tableHeight, width: roleConfigTableWidth - 4 }}
        >
          {translate('skillIndex', userConfig?.language)}
        </div>
      )}

      {showEmptyTableDropZone && isValidDropZone && (
        <div
          className={'player-simple-table-empty-container player-simple-table-empty-container-drag-active'}
          style={{
            height: tableHeight,
            width: roleConfigTableWidth,
            boxShadow: tableIsDraggedOver && (draggedRole?.positionKey !== positionKey || originalRoleHasLeftOriginalTable)
              ? '0px 0px 0px 3px ' + clubColor
              : 'none',
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDragEnter={() => handleDragEnter(0)}
          onDrop={(event) => handleDragDrop(event)}
        />
      )}

    </div>
  );
};
