import './landingPage.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import { useAuthContext } from '../../common/contexts/useAuthContext';
import { useWindowSize } from '../../common/hooks/WindowSize';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import CopyrightIcon from '@mui/icons-material/Copyright';

import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import InsightsIcon from '@mui/icons-material/Insights';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import MergeIcon from '@mui/icons-material/Merge';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { supportedLanguages, translate } from '../../common/language/translations';
import { FokusLogo } from '../../assets/svg/FokusLogo';

import platform_search from '../../assets/images/platform_search.png';
import platform_own_team from '../../assets/images/platform_own_team.png';
import platform_compare from '../../assets/images/platform_compare.png';
import platform_report from '../../assets/images/platform_report.png';
import sakariassen_image from '../../assets/images/sakariassen_image.jpg';
import albert_goal_vs_ajax from '../../assets/images/albert_goal_vs_ajax.jpeg';
import kjetil_image_cropped from '../../assets/images/kjetil_image_cropped.jpg';

import glimt_logo from '../../assets/clubLogos/glimt_logo.png';
import rbk_logo from '../../assets/clubLogos/rbk_logo.png';
import viking_logo from '../../assets/clubLogos/viking_logo.png';
import godset_logo from '../../assets/clubLogos/godset_logo.png';
import til_logo from '../../assets/clubLogos/til_logo.png';
import { svgColors } from '../../assets/svg/colors';
import { CountryFlagButton } from '../../platform/components/display/images/CountryFlagButton';


declare global {
  interface Navigator {
    standalone?: boolean;
  }
}


export const LandingPage = () => {

  const { currentUser } = useAuthContext();

  const { width } = useWindowSize();
  const isMobile = width < 900;
  const isTablet = width < 1050;

  const navigate = useNavigate();

  const [isTransparentHeader, setIsTransparentHeader] = useState(true);
  const [isHeaderMenuActive, setIsHeaderMenuActive] = useState(false);

  const [isLanguageMenuToggleDisabled, setIsLanguageMenuToggleDisabled] = useState(false);
  const [isLanguageMenuActive, setIsLanguageMenuActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const [activeSolutionsTab, setActiveSolutionsTab] = useState('strategy');


  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);


  const toggleLanguageMenu = () => {
    if (isLanguageMenuToggleDisabled) return;

    setIsLanguageMenuToggleDisabled(true);
    setIsLanguageMenuActive(!isLanguageMenuActive);

    setTimeout(() => {
      setIsLanguageMenuToggleDisabled(false);
    }, 200);
  };

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
    setIsLanguageMenuActive(false);
  };


  const listenScrollEvent = () => {
    if (window.scrollY > 20) {
      setIsTransparentHeader(false);
    } else {
      setIsTransparentHeader(true);
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);


  useEffect(() => {
    setIsHeaderMenuActive(false);
  }, [isMobile]);


  useEffect(() => {
    if (currentUser) {
      navigate('/platform');
    }
  }, [currentUser, navigate]);


  useEffect(() => {
    const imageList = [platform_own_team, platform_compare, platform_report];
    imageList.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);


  const isWhiteLogo = isTransparentHeader && !isHeaderMenuActive;


  return (
    <div className='landing-page-container'>

      {isPrivacyPolicyModalOpen && (
        <div className='landing-page-privacy-policy-modal-container fade-in' onClick={() => setIsPrivacyPolicyModalOpen(false)}>
          <div
            className='landing-page-privacy-policy-modal'
            style={{ padding: isMobile ? '25px 35px' : 35 }}
            onClick={(e) => e.stopPropagation()}
          >

            <div className='landing-page-privacy-policy-modal-close-icon'>
              <CloseIcon style={{ fontSize: 26 }} onClick={() => setIsPrivacyPolicyModalOpen(false)} />
            </div>

            <div className='landing-page-privacy-policy-modal-title'>
              {translate('privacyPolicy', selectedLanguage)}
            </div>
            <div className='landing-page-privacy-policy-modal-text' style={{ marginTop: isMobile ? 20 : 24 }}>
              {translate('privacyPolicyText', selectedLanguage)}
            </div>
            <div className='landing-page-privacy-policy-modal-text' style={{ marginTop: isMobile ? 20 : 24 }}>
              {translate('privacyPolicyPlatform', selectedLanguage)}
            </div>
          </div>
        </div>
      )}

      {(isHeaderMenuActive || isLanguageMenuActive) && (
        <div
          className='landing-page-empty-mobile-header-background'
          onClick={() => {
            setIsHeaderMenuActive(false);
            setIsLanguageMenuActive(false);
          }}
        />
      )}

      <div
        className={
          'landing-page-header-container'
          + (isTransparentHeader ? ' landing-page-header-container-transparent' : '')
          + (isHeaderMenuActive ? ' landing-page-header-container-menu-active' : '')
        }
      >

        <div className='landing-page-header-content-container'>
          <div className='landing-page-header-section'>

            <Link
              to='landing-page-image'
              smooth={true}
              duration={750}
              className='landing-page-header-logo-section'
              onClick={() => setIsHeaderMenuActive(false)}
            >
              <div className='landing-page-header-logo'>
                <FokusLogo isDarkLogo={!isWhiteLogo} />
              </div>
              <div className='landing-page-header-logo-text' style={{ color: isWhiteLogo ? svgColors.fokusLogoWhite : svgColors.fokusLogoDark }}>
                FOKUS
              </div>
            </Link>

            {!isMobile && (
              <div className='landing-page-header-tabs'>

                <Link
                  to='section1'
                  smooth={true}
                  duration={750}
                  offset={-80}
                  className={'landing-page-header-tab' + (isTransparentHeader ? ' landing-page-header-tab-transparent' : '')}
                >
                  {translate('ourSolution', selectedLanguage)}
                </Link>

                <Link
                  to='section2'
                  smooth={true}
                  duration={750}
                  offset={-80}
                  className={'landing-page-header-tab' + (isTransparentHeader ? ' landing-page-header-tab-transparent' : '')}
                >
                  {translate('caseStudy', selectedLanguage)}
                </Link>

                <Link
                  to='section3'
                  smooth={true}
                  duration={750}
                  offset={-80}
                  className={'landing-page-header-tab' + (isTransparentHeader ? ' landing-page-header-tab-transparent' : '')}
                >
                  {translate('about', selectedLanguage)}
                </Link>

              </div>
            )}

            <div className='landing-page-header-corner'>
              <div
                onMouseEnter={() => !isMobile ? setIsLanguageMenuActive(true) : null}
                onMouseLeave={() => !isMobile ? setIsLanguageMenuActive(false) : null}
                className='landing-page-header-language-icon-container'
              >
                <div className='landing-page-header-language-icon'>
                  <LanguageIcon style={{ fontSize: 20, color: isWhiteLogo ? '#ffffffcc' : '#141d25cc' }} onClick={toggleLanguageMenu} />
                </div>

                {isLanguageMenuActive && (
                  <div className='landing-page-header-language-menu-path'>
                    &nbsp;
                  </div>
                )}

                {isLanguageMenuActive && (
                  <div className='landing-page-header-language-menu fast-fade-in' style={{ padding: 16 }}>
                    {supportedLanguages.map((language) => (
                      <CountryFlagButton
                        key={language}
                        language={language}
                        isSelected={selectedLanguage === language}
                        onClick={() => handleLanguageSelect(language)}
                        width={24}
                        isWhiteBackground={true}
                      />
                    ))}
                  </div>
                )}
              </div>

              {isMobile && (
                <div
                  className={'landing-page-header-menu-icon' + (isTransparentHeader && !isHeaderMenuActive ? ' landing-page-header-menu-icon-transparent' : '')}
                  onClick={() => setIsHeaderMenuActive(!isHeaderMenuActive)}
                >
                  {!isHeaderMenuActive && <MenuIcon style={{ fontSize: 26 }} />}
                  {isHeaderMenuActive && <CloseIcon style={{ fontSize: 26 }} />}
                </div>
              )}

              {!isMobile && (
                <div className='landing-page-header-login-tab'>
                  <div
                    className={'landing-page-header-tab' + (isTransparentHeader ? ' landing-page-header-tab-transparent' : '')}
                    onClick={() => navigate('login')}
                  >
                    {translate('login', selectedLanguage)}
                  </div>
                </div>
              )}

              {!isMobile && (
                <Link to='section4' smooth={true} duration={750} offset={-80}>
                  <div className={'landing-page-header-trial-button' + (isTransparentHeader ? ' landing-page-header-trial-button-transparent' : '')}>
                    {translate('getStarted', selectedLanguage)}

                    <div className='landing-page-header-trial-button-icon'>
                      <KeyboardArrowRightIcon style={{ fontSize: 16 }} />
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>

        {isHeaderMenuActive && (
          <div className='landing-page-mobile-header-tabs'>
            <Link
              to='section1'
              smooth={true}
              duration={750}
              offset={-80}
              className='landing-page-header-tab landing-page-mobile-header-tab'
              onClick={() => setIsHeaderMenuActive(false)}
            >
              {translate('ourSolution', selectedLanguage)}
            </Link>

            <Link
              to='section2'
              smooth={true}
              duration={750}
              offset={-80}
              className='landing-page-header-tab landing-page-mobile-header-tab'
              onClick={() => setIsHeaderMenuActive(false)}
            >
              {translate('caseStudy', selectedLanguage)}
            </Link>

            <Link
              to='section3'
              smooth={true}
              duration={750}
              offset={-80}
              className='landing-page-header-tab landing-page-mobile-header-tab'
              onClick={() => setIsHeaderMenuActive(false)}
            >
              {translate('about', selectedLanguage)}
            </Link>

            <Link
              to='section4'
              smooth={true}
              duration={750}
              offset={-80}
              className='landing-page-header-tab landing-page-mobile-header-tab'
              onClick={() => setIsHeaderMenuActive(false)}
            >
              {translate('contact', selectedLanguage)}
            </Link>
          </div>
        )}

      </div>

      <Element name='landing-page-image'>
        <div className={'landing-page-image' + (isMobile ? ' landing-page-image-mobile' : '')}>
          {!isMobile && (
            <div className='landing-page-image-titles'>
              <div className='landing-page-image-title'>
                {translate('oneClub', selectedLanguage)}
                &nbsp;
                {translate('onePlatform', selectedLanguage)}
              </div>
              <div className='landing-page-image-sub-title'>
                {translate('aHolistic', selectedLanguage)}
              </div>
            </div>
          )}

          {isMobile && (
            <div className='landing-page-image-titles'>
              <div className='landing-page-image-title' style={{ fontSize: 40, marginTop: '1vh' }}>
                {translate('oneClub', selectedLanguage)}
                <br />
                {translate('onePlatform', selectedLanguage)}
              </div>
            </div>
          )}
        </div>

        <div className='landing-page-main-section-container' style={{ marginTop: isMobile ? '-19vh' : '-18vh' }}>
          <div className={'landing-page-main-section' + (isMobile ? ' landing-page-main-section-mobile' : '')}>
            {!isMobile && (
              <div className='landing-page-main-section-title'>
                {translate('bringClub', selectedLanguage)}
              </div>
            )}
            {isMobile && (
              <div className='landing-page-main-section-title' style={{ fontSize: 20, width: '90%', marginTop: 15 }}>
                {translate('aHolistic', selectedLanguage)}
              </div>
            )}
            <div className='landing-page-main-section-image-container'>
              <img src={platform_search} style={{ boxShadow: '0px 0px 10px 1px #10151f20' }} />
            </div>

            <div className='landing-page-main-section-partners-container' style={{ marginTop: isMobile ? 60 : 80 }}>
              <div className='landing-page-main-section-partners-section'>
                <div className='landing-page-section-small-title'>
                  {translate('trustedBy', selectedLanguage)}
                </div>
                <div className='landing-page-main-section-partners' style={{ marginTop: isMobile ? 2 : 16 }}>
                  <div className='landing-page-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={godset_logo} style={{ height: '69%', maxHeight: 100 }} />
                  </div>
                  <div className='landing-page-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={viking_logo} style={{ height: '59%', maxHeight: 87 }} />
                  </div>
                  <div className='landing-page-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={glimt_logo} style={{ height: '72%', maxHeight: 107 }} />
                  </div>
                  <div className='landing-page-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={rbk_logo} style={{ height: '55%', maxHeight: 80 }} />
                  </div>
                  <div className='landing-page-main-section-partner-container' style={{ height: isMobile ? '50%' : '100%' }}>
                    <img src={til_logo} style={{ height: '69%', maxHeight: 100 }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name='section1'>
        <div className='landing-page-section-container landing-page-section-solution-container'>
          <div className='landing-page-section'>
            <div className='landing-page-section-small-title'>
              {translate('ourSolution', selectedLanguage, undefined, true)}
            </div>
            <div className='landing-page-section-title' style={{ fontSize: isMobile ? 22 : undefined }}>
              {translate('whatTheBiggest', selectedLanguage)}
              <br />
              {translate('nowForYou', selectedLanguage)}
            </div>
            <div className='landing-page-section-sub-title' style={{ fontSize: isMobile ? 16 : undefined }}>
              {translate('fokusIsMore1', selectedLanguage)}
              {!isMobile && <br />}
              {isMobile && ' '}
              {translate('fokusIsMore2', selectedLanguage)}
            </div>

            <div
              className='landing-page-section-solutions-tabs'
              style={{ userSelect: 'none', fontSize: isMobile ? 14 : 16, height: isMobile ? 70 : 100, paddingTop: isMobile ? 10 : 0 }}
            >
              <div
                className={'landing-page-section-solutions-tab' + (activeSolutionsTab === 'strategy' ? ' landing-page-section-solutions-tab-active' : '')}
                style={{ width: isMobile ? 110 : 130 }}
                onClick={() => setActiveSolutionsTab('strategy')}
              >
                {translate('strategy', selectedLanguage)}
              </div>
              <div
                className={'landing-page-section-solutions-tab' + (activeSolutionsTab === 'scouting' ? ' landing-page-section-solutions-tab-active' : '')}
                style={{ width: isMobile ? 110 : 130 }}
                onClick={() => setActiveSolutionsTab('scouting')}
              >
                {translate('scouting', selectedLanguage)}
              </div>
              <div
                className={'landing-page-section-solutions-tab' + (activeSolutionsTab === 'decisions' ? ' landing-page-section-solutions-tab-active' : '')}
                style={{ width: isMobile ? 110 : 130 }}
                onClick={() => setActiveSolutionsTab('decisions')}
              >
                {translate('decisions', selectedLanguage)}
              </div>
            </div>

            <div className='landing-page-section-box-view' style={{ flexDirection: isMobile ? 'column' : 'row' }}>
              {activeSolutionsTab === 'strategy' && (
                <div
                  className='landing-page-section-box-view-section fade-in'
                  style={{ paddingLeft: 20, paddingRight: 20, width: isMobile ? '100%' : undefined, textAlign: isMobile ? 'center' : undefined }}
                >
                  <div
                    className='landing-page-section-box-view-title'
                    style={{ fontSize: isMobile ? 20 : undefined }}
                  >
                    {translate('strategyTitle', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-text'
                    style={{ fontSize: isMobile ? 15 : undefined }}
                  >
                    {translate('strategySubTitle', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <DonutLargeIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {translate('strategyBullet1', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <InsightsIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {translate('strategyBullet2', selectedLanguage)}
                  </div>
                </div>
              )}
              {activeSolutionsTab === 'scouting' && (
                <div
                  className='landing-page-section-box-view-section fade-in'
                  style={{ paddingLeft: 20, paddingRight: 20, width: isMobile ? '100%' : undefined, textAlign: isMobile ? 'center' : undefined }}
                >
                  <div
                    className='landing-page-section-box-view-title'
                    style={{ fontSize: isMobile ? 20 : undefined }}
                  >
                    {translate('scoutingTitle', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-text'
                    style={{ fontSize: isMobile ? 15 : undefined }}
                  >
                    {translate('scoutingSubTitle', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <PersonSearchIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {translate('scoutingBullet1', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <QueryStatsIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {translate('scoutingBullet2', selectedLanguage)}
                  </div>
                </div>
              )}
              {activeSolutionsTab === 'decisions' && (
                <div
                  className='landing-page-section-box-view-section fade-in'
                  style={{ paddingLeft: 20, paddingRight: 20, width: isMobile ? '100%' : undefined, textAlign: isMobile ? 'center' : undefined }}
                >
                  <div
                    className='landing-page-section-box-view-title'
                    style={{ fontSize: isMobile ? 20 : undefined }}
                  >
                    {translate('decisionsTitle', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-text'
                    style={{ fontSize: isMobile ? 15 : undefined }}
                  >
                    {translate('decisionsSubTitle', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <MergeIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {translate('decisionsBullet1', selectedLanguage)}
                  </div>
                  <div
                    className='landing-page-section-box-view-bullet-row'
                    style={{ marginTop: isMobile ? 6 : 10, fontSize: isMobile ? 14 : undefined }}
                  >
                    <EqualizerIcon style={{ fontSize: 28, marginRight: 16, color: '#0066ff' }} />
                    {translate('decisionsBullet2', selectedLanguage)}
                  </div>
                </div>
              )}
              <div className='landing-page-section-box-view-section' style={{ width: isMobile ? '90%' : undefined }}>
                <img
                  src={
                    activeSolutionsTab === 'strategy'
                      ? platform_own_team
                      : activeSolutionsTab === 'scouting'
                        ? platform_compare
                        : platform_report
                  }
                  style={{ borderRadius: 6, boxShadow: '0px 0px 10px 1px #10151f12', marginTop: isMobile ? 14 : 0 }}
                />
              </div>
            </div>
          </div>
        </div>
      </Element>

      <Element name='section2'>
        <div className='landing-page-section-container landing-page-section-case-study-container'>
          <div className='landing-page-section'>
            <div className='landing-page-section-small-title'>
              {translate('caseStudy', selectedLanguage, undefined, true)}
            </div>
            <div className='landing-page-section-title' style={{ fontSize: isMobile ? 22 : undefined }}>
              {translate('glimtUsesFokus', selectedLanguage)}
            </div>

            <div
              className='landing-page-section-box-view'
              style={{ marginTop: 25, paddingTop: 30, paddingBottom: 30, flexDirection: isMobile ? 'column' : 'row' }}
            >
              <div className={isMobile ? 'landing-page-section-box-view-quote-section-mobile' : 'landing-page-section-box-view-quote-section'}>
                <div
                  className='landing-page-section-box-view-sub-title'
                  style={{ fontSize: isMobile ? 16 : undefined }}
                >
                  {translate('sakariassenQuote1', selectedLanguage)}
                  <b>{translate('sakariassenQuote2', selectedLanguage)}</b>
                  {translate('sakariassenQuote3', selectedLanguage)}
                </div>
              </div>
              <img
                src={sakariassen_image}
                style={{
                  width: 100,
                  marginTop: isMobile ? 25 : 0,
                  borderRadius: 50,
                  border: 'solid 1px #00000010',
                  boxShadow: '0px 0px 6px 1px #00000010'
                }}
              />
              <div style={{ textAlign: isMobile ? 'center' : 'left', marginLeft: isMobile ? undefined : 12, marginTop: isMobile ? 8 : 0 }}>
                <div style={{ marginTop: 2, fontSize: 14, fontWeight: 500 }}>
                  Håvard Sakariassen
                </div>

                <div style={{ marginTop: 2, fontSize: 12 }}>
                  {translate('sportingDirector', selectedLanguage)}, FK Bodø/Glimt
                </div>
              </div>
            </div>

            <img
              src={albert_goal_vs_ajax}
              style={{ width: isMobile ? '100%' : '90%', borderRadius: 50, marginTop: 50, boxShadow: '0px 0px 10px 1px #10151f20' }}
            />

            <div
              style={{
                marginTop: isMobile ? 15 : 25,
                fontSize: isMobile ? 13 : 15,
                textAlign: 'left',
                padding: '0px 75px',
                width: isMobile ? '92%' : undefined
              }}
            >
              {translate('albertDescription', selectedLanguage)}
            </div>
          </div>
        </div>
      </Element>

      <Element name='section3'>
        <div className='landing-page-section-container landing-page-section-about-container'>
          <div className='landing-page-section'>
            <div className='landing-page-section-small-title'>
              {translate('aboutUs', selectedLanguage, undefined, true)}
            </div>
            <div className='landing-page-section-title' style={{ fontSize: isMobile ? 22 : undefined }}>
              {translate('weHelpClubs', selectedLanguage)}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: isTablet ? 'center' : 'space-between', marginTop: 50 }}>
              {!isTablet && (
                <img
                  src={kjetil_image_cropped}
                  style={{ width: '42%', borderRadius: 50, boxShadow: '0px 0px 10px 1px #10151f12' }}
                />
              )}

              <div
                className='landing-page-section-small-box-view-column'
                style={{ width: isTablet ? '98%' : '53%', gap: isTablet ? 26 : 16 }}
              >
                <div
                  className='landing-page-section-small-box-view'
                  style={{ padding: !isTablet && width < 1250 ? '10px 15px' : isMobile ? 20 : 25 }}
                >
                  <div
                    className='landing-page-section-box-view-title'
                    style={{ fontSize: isMobile ? 18 : undefined }}
                  >
                    {translate('howWeStarted', selectedLanguage)}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {translate('howWeStartedParagraph1', selectedLanguage)}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {translate('howWeStartedParagraph2', selectedLanguage)}
                  </div>
                </div>

                <div
                  className='landing-page-section-small-box-view'
                  style={{ padding: !isTablet && width < 1250 ? '10px 15px' : isMobile ? 20 : 25 }}
                >
                  <div
                    className='landing-page-section-box-view-title'
                    style={{ fontSize: isMobile ? 18 : undefined }}
                  >
                    {translate('whatWeHaveLearned', selectedLanguage)}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {translate('whatWeHaveLearnedParagraph1', selectedLanguage)}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {translate('whatWeHaveLearnedParagraph2', selectedLanguage)}
                  </div>
                </div>

                <div
                  className='landing-page-section-small-box-view'
                  style={{ padding: !isTablet && width < 1250 ? '10px 15px' : isMobile ? 20 : 25 }}
                >
                  <div
                    className='landing-page-section-box-view-title'
                    style={{ fontSize: isMobile ? 18 : undefined }}
                  >
                    {translate('whereWeAreGoing', selectedLanguage)}
                  </div>
                  <div style={{ marginTop: !isTablet && width < 1150 ? 10 : 16, fontSize: isMobile ? 15 : undefined }}>
                    {translate('whereWeAreGoingParagraph1', selectedLanguage)}
                  </div>
                </div>
              </div>
            </div>

            {isTablet && (
              <img
                src={kjetil_image_cropped}
                style={{ width: '92%', maxWidth: 500, marginTop: 35, borderRadius: 50, boxShadow: '0px 0px 10px 1px #10151f12' }}
              />
            )}
          </div>
        </div>
      </Element>

      <Element name='section4'>
        <div className='landing-page-section-container landing-page-section-contact-container'>
          <div className='landing-page-section'>
            <div className='landing-page-section-title-quote' style={{ fontSize: isMobile ? 24 : undefined }}>
              {translate('finalQuote', selectedLanguage)}
            </div>
            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 90 }}>
              {translate('interested', selectedLanguage)}
            </div>
            <div style={{ fontSize: isMobile ? 16 : 20, marginTop: 40 }}>
              {translate('weAreHappy', selectedLanguage)}

              {isMobile && ' '}
              {isMobile && translate('reachOut', selectedLanguage)}
            </div>

            {!isMobile && (
              <div style={{ fontSize: isMobile ? 16 : 20, marginTop: 25 }}>
                {translate('reachOut', selectedLanguage)}
              </div>
            )}

            <div className='landing-page-section-contact-row' style={{ marginTop: 70, marginBottom: 40, fontSize: isMobile ? 16 : undefined }}>
              <EmailIcon style={{ fontSize: isMobile ? 24 : 28, color: '#000000cc', marginRight: 10, marginLeft: -10, marginTop: -1 }} />
              lars.gundersen@fokus.ing
            </div>
          </div>
        </div>
      </Element>

      <div className='landing-page-footer-container'>
        {!isMobile && (
          <div className='landing-page-footer'>
            <div className='landing-page-footer-text-container flex-column'>
              <div className='flex-row' style={{ marginBottom: 6 }}>
                <CopyrightIcon style={{ fontSize: 15, marginRight: 3 }} />
                2024 Fokus. All rights reserved.
              </div>
              {translate('photoCredit', selectedLanguage)}
            </div>
            <div style={{ width: 100, height: 100 }}>
              <FokusLogo />
            </div>
            <div className='landing-page-footer-text-container' style={{ justifyContent: 'right' }}>
              <div className='landing-page-footer-link' onClick={() => setIsPrivacyPolicyModalOpen(true)}>
                Privacy policy
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className='landing-page-footer' style={{ flexDirection: 'column' }}>
            <div style={{ width: 100, height: 100 }}>
              <FokusLogo />
            </div>
            <div className='landing-page-footer-text-container' style={{ justifyContent: 'center', marginTop: 15 }}>
              <div className='landing-page-footer-link' onClick={() => setIsPrivacyPolicyModalOpen(true)}>
                Privacy policy
              </div>
            </div>
            <div className='landing-page-footer-text-container' style={{ justifyContent: 'center', marginTop: 20, width: '90%' }}>
              <CopyrightIcon style={{ fontSize: 15, marginRight: 3 }} />
              2024 Fokus. All rights reserved.
            </div>
            <div className='landing-page-footer-text-container' style={{ justifyContent: 'center', marginTop: 20, width: '90%' }}>
              {translate('photoCredit', selectedLanguage)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
