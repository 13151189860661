import { formationToPositionOptions, positionToClosestPositions } from '../../static/propertyValues';
import { PlannerAction, PlannerConfig, PlannerConfigAction, PlayerId, StringToAnyMap } from '../../types';


// returns the position of the player in the team at the given date
export const getPlayerStatusAtGivenDate = (
  plannerConfig: PlannerConfig,
  playerId: PlayerId,
  originalTeamPlayerMap: StringToAnyMap,
  date: Date,
  selectedAction: PlannerAction | undefined,
) => {

  const dateString = date.toISOString().split('T')[0];
  const playerActions = plannerConfig[playerId] ?? [];

  // the position will determine if the player is in the team - players may be in the without a contract, but they must have a position
  let currentContractExpiration = originalTeamPlayerMap[playerId]?.contract_expiration;
  let currentPosition = originalTeamPlayerMap[playerId]?.position;
  let currentRole = originalTeamPlayerMap[playerId]?.role;
  let currentLocality = originalTeamPlayerMap[playerId]?.locality;

  // contractExtended actions are handled differently in that they can (and are defaulted to) happen on the same day as the contract expiration
  // for all other actions, the player is considered to be out of the team on the day the contract expires
  let contractExpiredOnGivenDate = false;

  // we simulate all relevant player actions until we reach the given date
  for (const action of playerActions) {

    // if we are editing an action, we need to remove the selected action from the simulation (validating the edit as if we were adding a new action)
    if (selectedAction && (action.type + action.date) === (selectedAction.type + selectedAction.date)) {
      continue;
    }

    // if the action date is after the given date, we must stop the simulation (we are only interested in the player status at the given date)
    if (action.date > dateString) {
      break;
    }

    // if action date is after the current contract expiration, we update the simulation as if the previous action was a contractExpired action
    if (currentContractExpiration && action.date > currentContractExpiration) {
      currentContractExpiration = undefined;
      currentPosition = undefined;
      currentRole = undefined;
      currentLocality = undefined;
    }

    if (action.type === 'playerAdded') {
      currentContractExpiration = action.contractExpiration;
      currentPosition = action.position;
      currentRole = action.role;
      currentLocality = action.locality;
    }

    else if (action.type === 'playerRemoved') {
      currentContractExpiration = undefined;
      currentPosition = undefined;
      currentRole = undefined;
      currentLocality = undefined;
    }

    else if (action.type === 'contractExtended') {
      currentContractExpiration = action.contractExpiration;
    }

    else if (action.type === 'positionChanged') {
      currentPosition = action.position;
    }

    else if (action.type === 'roleChanged') {
      currentRole = action.role;
    }

    else if (action.type === 'localityChanged') {
      currentLocality = action.locality;
    }
  }

  // the contract may still expire after all actions have been simulated, but before the given date
  if (currentContractExpiration && currentContractExpiration <= date) {
    currentContractExpiration = undefined;
    currentPosition = undefined;
    currentRole = undefined;
    currentLocality = undefined;
    contractExpiredOnGivenDate = currentContractExpiration === date;
  }

  return { currentContractExpiration, currentPosition, currentRole, currentLocality, contractExpiredOnGivenDate };
};


// get a position that is valid for the given formation, if provided
const getValidPosition = (position?: string, formation?: string) => {
  if (!formation || !position) return position;

  const positionOptions = formationToPositionOptions[formation];
  if (positionOptions.includes(position)) return position;

  for (const closestPosition of positionToClosestPositions[position]) {
    if (positionOptions.includes(closestPosition)) {
      return closestPosition; // This will correctly return from the function
    }
  }
};


// get new player actions after adding/editing/deleting an action - adding/editing/deleting an action may cause subsequent actions to be invalid
// if only newAction is provided, we are adding a new action
// if only selectedAction is provided, we are deleting the selected action
// if both are provided, we are editing the selected action (selectedAction is the original action, newAction is the resulting action after editing)
export const getNewValidPlayerActions = (
  plannerConfig: PlannerConfig,
  playerId: PlayerId,
  originalTeamPlayerMap: StringToAnyMap,
  newAction?: PlannerConfigAction,
  selectedAction?: PlannerAction,
  formation?: string,
): { newValidPlayerActions: PlannerConfigAction[]; subsequentActionsWillBeDeleted: boolean; } => {

  let newPlayerActions: PlannerConfigAction[] = [...(plannerConfig[playerId] ?? [])];

  // (1) remove the selected action from the player actions and add the new action
  if (selectedAction) {
    newPlayerActions = newPlayerActions.filter((action) => (action.type + action.date) !== (selectedAction.type + selectedAction.date));
  }
  if (newAction) {
    newPlayerActions.push(newAction);
    newPlayerActions.sort((a, b) => a.date.localeCompare(b.date));
  }


  // (2) simulate the new player actions to check if they are valid
  let invalidActionFound = false;
  let currentContractExpiration = originalTeamPlayerMap[playerId]?.contract_expiration;
  let currentPosition = originalTeamPlayerMap[playerId]?.position;
  let currentRole = originalTeamPlayerMap[playerId]?.role;
  let currentLocality = originalTeamPlayerMap[playerId]?.locality;

  const newValidPlayerActions: PlannerConfigAction[] = [];
  for (const action of newPlayerActions) {

    const validAction = { ...action };
    if (action.position) validAction.position = getValidPosition(action.position, formation);

    // if action date is after the current contract expiration, playerIsInTeam must be set to false (as if the previous action was a contractExpired action)
    if (currentContractExpiration && validAction.date > currentContractExpiration) {
      currentContractExpiration = undefined;
      currentPosition = undefined;
      currentRole = undefined;
      currentLocality = undefined;
    }

    // this check is necessary, but not sufficient for all types
    let isValidAction = validAction.type === 'playerAdded' ? currentPosition === undefined : currentPosition !== undefined;
    if (isValidAction && validAction.type === 'positionChanged') isValidAction = validAction.position !== currentPosition;
    if (isValidAction && validAction.type === 'roleChanged') isValidAction = validAction.role !== currentRole;
    if (isValidAction && validAction.type === 'localityChanged') isValidAction = validAction.locality !== currentLocality;

    // apply the action and add it to the new valid player actions if it is valid
    if (isValidAction) {

      if (validAction.type === 'playerAdded') {
        currentContractExpiration = validAction.contractExpiration;
        currentPosition = validAction.position;
        currentRole = validAction.role;
        currentLocality = validAction.locality;
      }

      else if (validAction.type === 'playerRemoved') {
        currentContractExpiration = undefined;
        currentPosition = undefined;
        currentRole = undefined;
        currentLocality = undefined;
      }

      if (validAction.type === 'contractExtended') {
        currentContractExpiration = validAction.contractExpiration;
      }

      else if (validAction.type === 'positionChanged') {
        currentPosition = validAction.position;
      }

      else if (validAction.type === 'roleChanged') {
        currentRole = validAction.role;
      }

      else if (validAction.type === 'localityChanged') {
        currentLocality = validAction.locality;
      }

      newValidPlayerActions.push(validAction);
    }
    else {
      invalidActionFound = true;
      continue; // we dont want to abort the simulation, as there may be subsequent actions that are still valid
    }
  }

  return { newValidPlayerActions: newValidPlayerActions, subsequentActionsWillBeDeleted: invalidActionFound };
};


// the planner config depends on the ownTeam state (palyers, positions, contracts, roles, localities. formation)
// when the oenTeam state changes, the current planner config may become invalid
// this can either be handled by updating the planner config every time the ownTeam state changes, but this is less maintainable
// therefore, when the planner is initialized, we run through the full simulation and prune all invalid actions
export const getValidPlannerConfig = (
  plannerConfig: PlannerConfig,
  originalTeamPlayerMap: StringToAnyMap,
  formation: string,
) => {

  const validPlannerConfig: PlannerConfig = {};

  for (const playerId in plannerConfig) {
    const { newValidPlayerActions } = getNewValidPlayerActions(plannerConfig, playerId, originalTeamPlayerMap, undefined, undefined, formation);

    if (newValidPlayerActions.length > 0) {
      validPlannerConfig[playerId] = newValidPlayerActions;
    }
  }

  return validPlannerConfig;
};
