/* eslint-disable max-len */

import { StringToStringMap, StringToStringToStringMap } from '../../platform/types';


export const supportedLanguages = ['no', 'se', 'dk', 'en'];

export const languageToLocale: StringToStringMap = {
  'no': 'nb-NO',
  'se': 'sv-SE',
  'dk': 'da-DK',
  'en': 'en-GB',
};


export const translate = (
  key: string,
  language: string | undefined,
  startWithLowerCase?: boolean,
  allUpperCase?: boolean,
  returnUndefinedIfMissing?: boolean,
): string => {

  let translation = translations[key]?.[language ?? 'en'] ?? (returnUndefinedIfMissing ? undefined : key);

  if (startWithLowerCase) {
    translation = translation.charAt(0).toLowerCase() + translation.slice(1);
  }
  else if (allUpperCase) {
    translation = translation.toUpperCase();
  }

  return translation;
};


// words (words in the middle of sentences that need careful handling)
// general (sorted alphabetically)

// stats/metrics
// players
// positions
// matches
// contracts
// teams/squads
// search
// filters
// alerts
// planner
// competitions
// accounts/access
// reports
// clauses
// messages
// info
// provide player info

// position keys
// position group descriptions
// static team names
// role keys
// form keys
// playing time
// role config aggregation options

// login
// home


// New paragraph for TextModal: \n
const translations: StringToStringToStringMap = {


  // words
  'basedOn<positions>': {
    'en': 'Based on',
    'no': 'Basert på',
    'se': 'Baserat på',
    'dk': 'Baseret på',
  },
  'for<season>': {
    'en': 'for',
    'no': 'for',
    'se': 'för',
    'dk': 'for',
  },
  'for<team>': {
    'en': 'for',
    'no': 'for',
    'se': 'för',
    'dk': 'for',
  },
  '<percentage>of': {
    'en': 'of',
    'no': 'av',
    'se': 'av',
    'dk': 'af',
  },
  'in<season>': {
    'en': 'in',
    'no': 'i',
    'se': 'i',
    'dk': 'i',
  },
  'in<competition>': {
    'en': 'in',
    'no': 'i',
    'se': 'i',
    'dk': 'i',
  },
  'to<team>': {
    'en': 'to',
    'no': 'til',
    'se': 'till',
    'dk': 'til',
  },
  '<fromSomething>to<something>': {
    'en': 'to',
    'no': 'til',
    'se': 'till',
    'dk': 'til',
  },
  'from<team>': {
    'en': 'from',
    'no': 'fra',
    'se': 'från',
    'dk': 'fra',
  },
  'on<team>': {
    'en': 'on',
    'no': 'på',
    'se': 'på',
    'dk': 'på',
  },
  'atLeast<threshold>': {
    'en': 'at least',
    'no': 'minst',
    'se': 'minst',
    'dk': 'mindst',
  },
  '<someone>commented': {
    'en': 'commented',
    'no': 'kommenterte',
    'se': 'kommenterade',
    'dk': 'kommenterede',
  },
  '<someone>moved<player>': {
    'en': 'moved',
    'no': 'flyttet',
    'se': 'flyttade',
    'dk': 'flyttede',
  },
  '<someone>removed<player>': {
    'en': 'removed',
    'no': 'fjernet',
    'se': 'tog bort',
    'dk': 'fjernede',
  },
  '<someone>added<player>': {
    'en': 'added',
    'no': 'la til',
    'se': 'lade till',
    'dk': 'tilføjede',
  },
  '<someone>movedThePlayerFrom<team>': {
    'en': 'moved the player from',
    'no': 'flyttet spilleren fra',
    'se': 'flyttade spelaren från',
    'dk': 'flyttede spilleren fra',
  },
  '<someone>removedThePlayerFrom<team>': {
    'en': 'removed the player from',
    'no': 'fjernet spilleren fra',
    'se': 'tog bort spelaren från',
    'dk': 'fjernede spilleren fra',
  },
  '<someone>addedThePlayerTo<team>': {
    'en': 'added the player to',
    'no': 'la til spilleren på',
    'se': 'lade till spelaren på',
    'dk': 'tilføjede spilleren til',
  },
  '<someone>uploadedAnAttachment': {
    'en': 'uploaded an attachment',
    'no': 'lastet opp et vedlegg',
    'se': 'laddade upp en bilaga',
    'dk': 'uploadede en vedhæftning',
  },
  '<someone>leftA<reportType>': {
    'en': 'left a',
    'no': 'la inn en',
    'se': 'lämnade en',
    'dk': 'forlod en',
  },
  '<someone>createdTheTeam': {
    'en': 'created the team',
    'no': 'opprettet laget',
    'se': 'skapade laget',
    'dk': 'oprettede holdet',
  },
  '<someone>createdTheSquad': {
    'en': 'created the squad',
    'no': 'opprettet stallen',
    'se': 'skapade truppen',
    'dk': 'oprettede truppen',
  },
  '<someone>created<teamOrSquad>': {
    'en': 'created',
    'no': 'opprettet',
    'se': 'skapade',
    'dk': 'oprettede',
  },
  '<someone>archivedTheTeam': {
    'en': 'archived the team',
    'no': 'arkiverte laget',
    'se': 'arkiverade laget',
    'dk': 'arkiverede holdet',
  },
  '<someone>archivedTheSquad': {
    'en': 'archived the squad',
    'no': 'arkiverte stallen',
    'se': 'arkiverade truppen',
    'dk': 'arkiverede truppen',
  },
  '<someone>archived<teamOrSquad>': {
    'en': 'archived',
    'no': 'arkiverte',
    'se': 'arkiverade',
    'dk': 'arkiverede',
  },
  '<someone>resetTheTeam': {
    'en': 'reset the team',
    'no': 'nullstilte laget',
    'se': 'återställde laget',
    'dk': 'nulstillede holdet',
  },
  '<someone>resetTheSquad': {
    'en': 'reset the squad',
    'no': 'nullstilte stallen',
    'se': 'återställde truppen',
    'dk': 'nulstillede truppen',
  },
  '<someone>reset<teamOrSquad>': {
    'en': 'reset',
    'no': 'nullstilte',
    'se': 'återställde',
    'dk': 'nulstillede',
  },
  '<someone>editedTheNameTo': {
    'en': 'edited the name to',
    'no': 'endret navnet til',
    'se': 'ändrade namnet till',
    'dk': 'ændrede navnet til',
  },
  '<someone>editedTheFormationTo': {
    'en': 'edited the formation to',
    'no': 'endret formasjonen til',
    'se': 'ändrade formationen till',
    'dk': 'ændrede formationen til',
  },
  '<someone>editedTheFormationFor<team>': {
    'en': 'edited the formation for',
    'no': 'endret formasjonen for',
    'se': 'ändrade formationen för',
    'dk': 'ændrede formationen for',
  },
  '<someone>removed<thePlayer>': {
    'en': 'removed',
    'no': 'fjernet',
    'se': 'tog bort',
    'dk': 'fjernede',
  },
  '<someone>added<thePlayer>': {
    'en': 'added',
    'no': 'la til',
    'se': 'lade till',
    'dk': 'tilføjede',
  },
  '<leftAReport>on<player>': {
    'en': 'on',
    'no': 'på',
    'se': 'på',
    'dk': 'på',
  },
  'fromInclusive': {
    'en': 'from',
    'no': 'fra og med',
    'se': 'från och med',
    'dk': 'fra og med',
  },
  'created': {
    'en': 'created',
    'no': 'opprettet',
    'se': 'skapade',
    'dk': 'oprettede',
  },
  'edited': {
    'en': 'edited',
    'no': 'redigerte',
    'se': 'redigerade',
    'dk': 'redigerede',
  },
  'theScoutingReport': {
    'en': 'the scouting report',
    'no': 'speiderapporten',
    'se': 'scoutrapporten',
    'dk': 'scout rapporten',
  },
  'theMatchReport': {
    'en': 'the match report',
    'no': 'kamprapporten',
    'se': 'matchrapporten',
    'dk': 'kamp rapporten',
  },
  'theComment': {
    'en': 'the comment',
    'no': 'kommentaren',
    'se': 'kommentaren',
    'dk': 'kommentaren',
  },
  'theAttachment': {
    'en': 'the attachment',
    'no': 'vedlegget',
    'se': 'bilagan',
    'dk': 'vedhæftningen',
  },


  // general (sorted alphabetically)
  'academy': {
    'en': 'Academy',
    'no': 'Akademi',
    'se': 'Akademi',
    'dk': 'Akademi',
  },
  'academyPlayers': {
    'en': 'Academy players',
    'no': 'Akademispillere',
    'se': 'Akademispelare',
    'dk': 'Akademispillere',
  },
  'actions': {
    'en': 'Actions',
    'no': 'Handlinger',
    'se': 'Åtgärder',
    'dk': 'Handlinger',
  },
  'activityLast14Days': {
    'en': 'Activity last 14 days',
    'no': 'Aktivitet siste 14 dager',
    'se': 'Aktivitet senaste 14 dagar',
    'dk': 'Aktivitet sidste 14 dage',
  },
  'add': {
    'en': 'Add',
    'no': 'Legg til',
    'se': 'Lägg till',
    'dk': 'Tilføj',
  },
  'ageDistribution': {
    'en': 'Age distribution',
    'no': 'Aldersfordeling',
    'se': 'Åldersfördelning',
    'dk': 'Aldersfordeling',
  },
  'all': {
    'en': 'All',
    'no': 'Alle',
    'se': 'Alla',
    'dk': 'Alle',
  },
  'allPositions': {
    'en': 'All positions',
    'no': 'Alle posisjoner',
    'se': 'Alla positioner',
    'dk': 'Alle positioner',
  },
  'allTeams': {
    'en': 'All teams',
    'no': 'Alle lag',
    'se': 'Alla lag',
    'dk': 'Alle hold',
  },
  'alphabetical': {
    'en': 'Alphabetical',
    'no': 'Alfabetisk',
    'se': 'Alfabetisk',
    'dk': 'Alfabetisk',
  },
  'amount': {
    'en': 'Amount',
    'no': 'Beløp',
    'se': 'Belopp',
    'dk': 'Beløb',
  },
  'amounts': {
    'en': 'Amounts',
    'no': 'Beløp',
    'se': 'Belopp',
    'dk': 'Beløb',
  },
  'amountOrPercentage': {
    'en': 'Amount or percentage',
    'no': 'Antall eller prosent',
    'se': 'Belopp eller procent',
    'dk': 'Beløb eller procent',
  },
  'archive': {
    'en': 'Archive',
    'no': 'Arkiv',
    'se': 'Arkiv',
    'dk': 'Arkiv',
  },
  'attachment': {
    'en': 'Attachment',
    'no': 'Vedlegg',
    'se': 'Bilaga',
    'dk': 'Bilag',
  },
  'attachments': {
    'en': 'Attachments',
    'no': 'Vedlegg',
    'se': 'Bilagor',
    'dk': 'Bilag',
  },
  'average': {
    'en': 'Average',
    'no': 'Snitt',
    'se': 'Genomsnitt',
    'dk': 'Gennemsnit',
  },
  'bonuses': {
    'en': 'Bonuses',
    'no': 'Bonuser',
    'se': 'Bonusar',
    'dk': 'Bonusser',
  },
  'bonusesExpected': {
    'en': 'Expected bonuses',
    'no': 'Forventede bonuser',
    'se': 'Förväntade bonusar',
    'dk': 'Forventede bonusser',
  },
  'cancel': {
    'en': 'Cancel',
    'no': 'Avbryt',
    'se': 'Avbryt',
    'dk': 'Annuller',
  },
  'club': {
    'en': 'Club',
    'no': 'Klubb',
    'se': 'Klubb',
    'dk': 'Klub',
  },
  'clubs': {
    'en': 'Clubs',
    'no': 'Klubber',
    'se': 'Klubbar',
    'dk': 'Klubber',
  },
  'colorBasis': {
    'en': 'Color basis',
    'no': 'Fargegrunnlag',
    'se': 'Färgbas',
    'dk': 'Farvegrundlag',
  },
  'comment': {
    'en': 'Comment',
    'no': 'Kommentar',
    'se': 'Kommentar',
    'dk': 'Kommentar',
  },
  'compare': {
    'en': 'Compare',
    'no': 'Sammenlign',
    'se': 'Jämför',
    'dk': 'Sammenlign',
  },
  'confirm': {
    'en': 'Confirm',
    'no': 'Bekreft',
    'se': 'Bekräfta',
    'dk': 'Bekræft',
  },
  'confirmEdits': {
    'en': 'Confirm edits',
    'no': 'Bekreft endringer',
    'se': 'Bekräfta ändringar',
    'dk': 'Bekræft ændringer',
  },
  'confirmNewName': {
    'en': 'Confirm new name',
    'no': 'Bekreft nytt navn',
    'se': 'Bekräfta nytt namn',
    'dk': 'Bekræft nyt navn',
  },
  'confirmNewRule': {
    'en': 'Confirm new rule',
    'no': 'Bekreft ny regel',
    'se': 'Bekräfta ny regel',
    'dk': 'Bekræft ny regel',
  },
  'country': {
    'en': 'Country',
    'no': 'Land',
    'se': 'Land',
    'dk': 'Land',
  },
  'date': {
    'en': 'Date',
    'no': 'Dato',
    'se': 'Datum',
    'dk': 'Dato',
  },
  'delete': {
    'en': 'Delete',
    'no': 'Slett',
    'se': 'Radera',
    'dk': 'Slet',
  },
  'deleteProvidedValue': {
    'en': 'Delete provided value',
    'no': 'Slett oppgitt verdi',
    'se': 'Radera angivet värde',
    'dk': 'Slet angivet værdi',
  },
  'discardChanges?': {
    'en': 'Discard changes?',
    'no': 'Forkaste endringene?',
    'se': 'Släng ändringarna?',
    'dk': 'Forkast ændringerne?',
  },
  'edit': {
    'en': 'Edit',
    'no': 'Endre',
    'se': 'Redigera',
    'dk': 'Rediger',
  },
  'editName': {
    'en': 'Edit name',
    'no': 'Endre navn',
    'se': 'Ändra namn',
    'dk': 'Rediger navn',
  },
  'editRule': {
    'en': 'Edit rule',
    'no': 'Endre regel',
    'se': 'Redigera regel',
    'dk': 'Rediger regel',
  },
  'help': {
    'en': 'Help',
    'no': 'Hjelp',
    'se': 'Hjälp',
    'dk': 'Hjælp',
  },
  'home': {
    'en': 'Home',
    'no': 'Hjem',
    'se': 'Hem',
    'dk': 'Hjem',
  },
  'info': {
    'en': 'Info',
    'no': 'Info',
    'se': 'Info',
    'dk': 'Info',
  },
  'language': {
    'en': 'Language',
    'no': 'Språk',
    'se': 'Språk',
    'dk': 'Sprog',
  },
  'log': {
    'en': 'Log',
    'no': 'Logg',
    'se': 'Logg',
    'dk': 'Log',
  },
  'marketValue': {
    'en': 'Market value',
    'no': 'Markedsverdi',
    'se': 'Marknadsvärde',
    'dk': 'Markedsværdi',
  },
  'matrix': {
    'en': 'Matrix',
    'no': 'Matrise',
    'se': 'Matris',
    'dk': 'Matrix',
  },
  'month': {
    'en': 'Month',
    'no': 'Måned',
    'se': 'Månad',
    'dk': 'Måned',
  },
  'monthsShort': {
    'en': 'months',
    'no': 'mnd',
    'se': 'mån',
    'dk': 'mdr',
  },
  'name': {
    'en': 'Name',
    'no': 'Navn',
    'se': 'Namn',
    'dk': 'Navn',
  },
  'new': {
    'en': 'New',
    'no': 'Ny',
    'se': 'Ny',
    'dk': 'Ny',
  },
  'or': {
    'en': 'Or',
    'no': 'Eller',
    'se': 'Eller',
    'dk': 'Eller',
  },
  'other': {
    'en': 'Other',
    'no': 'Annet',
    'se': 'Annat',
    'dk': 'Andet',
  },
  'overview': {
    'en': 'Overview',
    'no': 'Oversikt',
    'se': 'Översikt',
    'dk': 'Overblik',
  },
  'percentage': {
    'en': 'Percentage',
    'no': 'Prosent',
    'se': 'Procent',
    'dk': 'Procent',
  },
  'postpone': {
    'en': 'Postpone',
    'no': 'Utsett',
    'se': 'Skjut upp',
    'dk': 'Udskyd',
  },
  'provideDate': {
    'en': 'Provide date',
    'no': 'Oppgi dato',
    'se': 'Ange datum',
    'dk': 'Angiv dato',
  },
  'provideName': {
    'en': 'Provide name',
    'no': 'Skriv inn navn',
    'se': 'Ange namn',
    'dk': 'Angiv navn',
  },
  'provideNewValue': {
    'en': 'Provide new value',
    'no': 'Oppgi ny verdi',
    'se': 'Ange nytt värde',
    'dk': 'Angiv ny værdi',
  },
  'refresh': {
    'en': 'Refresh',
    'no': 'Oppdater',
    'se': 'Uppdatera',
    'dk': 'Opdater',
  },
  'removeAttachment': {
    'en': 'Remove attachment',
    'no': 'Fjern vedlegg',
    'se': 'Ta bort bilaga',
    'dk': 'Fjern vedhæftning',
  },
  'restore': {
    'en': 'Restore',
    'no': 'Gjenopprett',
    'se': 'Återställ',
    'dk': 'Gendan',
  },
  'roles': {
    'en': 'Roles',
    'no': 'Roller',
    'se': 'Roller',
    'dk': 'Roller',
  },
  'salary': {
    'en': 'Salary',
    'no': 'Lønn',
    'se': 'Lön',
    'dk': 'Løn',
  },
  'salaryCost': {
    'en': 'Salary',
    'no': 'Lønnskostnad',
    'se': 'Lönekostnad',
    'dk': 'Lønomkostning',
  },
  'salaryPlusBonus': {
    'en': 'Salary + bonuses',
    'no': 'Lønn + bonuser',
    'se': 'Lön + bonusar',
    'dk': 'Løn + bonusser',
  },
  'scouting': {
    'en': 'Scouting',
    'no': 'Scouting',
    'se': 'Scouting',
    'dk': 'Scouting',
  },
  'select': {
    'en': 'Select',
    'no': 'Velg',
    'se': 'Välj',
    'dk': 'Vælg',
  },
  'selectAll': {
    'en': 'Select all',
    'no': 'Velg alle',
    'se': 'Välj alla',
    'dk': 'Vælg alle',
  },
  'selectDate': {
    'en': 'Select date',
    'no': 'Velg dato',
    'se': 'Välj datum',
    'dk': 'Vælg dato',
  },
  'settings': {
    'en': 'Settings',
    'no': 'Innstillinger',
    'se': 'Inställningar',
    'dk': 'Indstillinger',
  },
  'shortNames': {
    'en': 'Short names',
    'no': 'Korte navn',
    'se': 'Korta namn',
    'dk': 'Korte navne',
  },
  'showAgeCategories': {
    'en': 'Show age categories',
    'no': 'Vis alderskategorier',
    'se': 'Visa ålderskategorier',
    'dk': 'Vis alderskategorier',
  },
  'showColors': {
    'en': 'Show colors',
    'no': 'Vis farger',
    'se': 'Visa färger',
    'dk': 'Vis farver',
  },
  'showDescription': {
    'en': 'Show description',
    'no': 'Vis beskrivelse',
    'se': 'Visa beskrivning',
    'dk': 'Vis beskrivelse',
  },
  'showExpirationYear': {
    'en': 'Show expiration year',
    'no': 'Vis utløpsår',
    'se': 'Visa utgångsår',
    'dk': 'Vis udløbsår',
  },
  'showLeft': {
    'en': 'Show to the left',
    'no': 'Vis til venstre',
    'se': 'Visa till vänster',
    'dk': 'Vis til venstre',
  },
  'showVersion': {
    'en': 'Show version',
    'no': 'Vis versjon',
    'se': 'Visa version',
    'dk': 'Vis version',
  },
  'signingCost': {
    'en': 'Signing cost',
    'no': 'Signering',
    'se': 'Signering',
    'dk': 'Signering',
  },
  'signingCostLong': {
    'en': 'Signing cost',
    'no': 'Signeringskostnad',
    'se': 'Signeringskostnad',
    'dk': 'Signeringsomkostninger',
  },
  'sum': {
    'en': 'Sum',
    'no': 'Sum',
    'se': 'Summa',
    'dk': 'Sum',
  },
  'summary': {
    'en': 'Summary',
    'no': 'Oppsummering',
    'se': 'Sammanfattning',
    'dk': 'Oversigt',
  },
  'table': {
    'en': 'Table',
    'no': 'Tabell',
    'se': 'Tabell',
    'dk': 'Tabel',
  },
  'undo': {
    'en': 'Undo',
    'no': 'Angre',
    'se': 'Ångra',
    'dk': 'Fortryd',
  },
  'unknownUser': {
    'en': 'A previous user',
    'no': 'En tidligere bruker',
    'se': 'En tidigare användare',
    'dk': 'En tidligere bruger',
  },
  'unknownUserShort': {
    'en': 'Previous user',
    'no': 'Tidligere bruker',
    'se': 'Tidigare användare',
    'dk': 'Tidligere bruger',
  },
  'uploadPdf': {
    'en': 'Upload PDF',
    'no': 'Last opp PDF',
    'se': 'Ladda upp PDF',
    'dk': 'Upload PDF',
  },
  'valueInOurDatabase': {
    'en': 'Value in our database',
    'no': 'Verdi i vår database',
    'se': 'Värde i vår databas',
    'dk': 'Værdi i vores database',
  },
  'year': {
    'en': 'Year',
    'no': 'År',
    'se': 'År',
    'dk': 'År',
  },
  'years': {
    'en': 'Years',
    'no': 'År',
    'se': 'År',
    'dk': 'År',
  },
  'you': {
    'en': 'You',
    'no': 'Du',
    'se': 'Du',
    'dk': 'Du',
  },


  // stats/metrics
  'dataBasis': {
    'en': 'Data basis',
    'no': 'Datagrunnlag',
    'se': 'Datagrundlag',
    'dk': 'Datagrundlag',
  },
  'advancedData': {
    'en': 'Advanced data',
    'no': 'Avansert data',
    'se': 'Avancerad data',
    'dk': 'Avanceret data',
  },
  'goals': {
    'en': 'Goals',
    'no': 'Mål',
    'se': 'Mål',
    'dk': 'Mål',
  },
  'goalsScored': {
    'en': 'Goals scored',
    'no': 'Mål scoret',
    'se': 'Mål gjorda',
    'dk': 'Mål scoret',
  },
  'goalsConceded': {
    'en': 'Goals conceded',
    'no': 'Mål sluppet inn',
    'se': 'Mål insläppta',
    'dk': 'Mål lukket ind',
  },
  'goalDifference': {
    'en': 'Goal difference',
    'no': 'Målforskjell',
    'se': 'Målskillnad',
    'dk': 'Målforskel',
  },
  'assists': {
    'en': 'Assists',
    'no': 'Assists',
    'se': 'Assists',
    'dk': 'Assists',
  },
  'averageAge': {
    'en': 'Average age',
    'no': 'Gjennomsnittsalder',
    'se': 'Genomsnittsålder',
    'dk': 'Gennemsnitsalder',
  },
  'averageContractLength': {
    'en': 'Average contract length',
    'no': 'Gjennomsnittlig kontraktslengde',
    'se': 'Genomsnittlig kontraktslängd',
    'dk': 'Gennemsnitlig kontraktlængde',
  },
  'averageAgePerMinutePlayed': {
    'en': 'Average age per minute played',
    'no': 'Gjennomsnittsalder per spilte minutt',
    'se': 'Genomsnittsålder per spelad minut',
    'dk': 'Gennemsnitsalder per spillet minut',
  },
  'averageAgePerMinutePlayedTitle': {
    'en': 'Average age per minute played',
    'no': 'Gjennomsnittsalder per spilte minutt',
    'se': 'Genomsnittsålder per spelad minut',
    'dk': 'Gennemsnitsalder per spillet minut',
  },
  'u21MinutesPerMinute': {
    'en': 'Percentage of minutes U21',
    'no': 'Prosentandel minutter U21',
    'se': 'Procentandel minuter U21',
    'dk': 'Procentdel minutter U21',
  },
  'u21MinutesPerMinuteTitle': {
    'en': 'Percentage of minutes by U21',
    'no': 'Prosentandel minutter spilt av U21',
    'se': 'Procentandel minuter spelade av U21',
    'dk': 'Procentdel minutter spillet af U21',
  },
  'confidence': {
    'en': 'Precision',
    'no': 'Presisjon',
    'se': 'Precision',
    'dk': 'Præcision',
  },
  'form': {
    'en': 'Form',
    'no': 'Form',
    'se': 'Form',
    'dk': 'Form',
  },
  'formAndPlayingTime': {
    'en': 'Form and playing time',
    'no': 'Form og spilletid',
    'se': 'Form och speltid',
    'dk': 'Form og spilletid',
  },
  'playingTime': {
    'en': 'Playing time',
    'no': 'Spilletid',
    'se': 'Speltid',
    'dk': 'Spilletid',
  },
  'skillRating': {
    'en': 'Skill rating',
    'no': 'Skillrating',
    'se': 'Skillrating',
    'dk': 'Skillrating',
  },
  'skillIndex': {
    'en': 'Skill index',
    'no': 'Skillindeks',
    'se': 'Skillindex',
    'dk': 'Skillindeks',
  },
  'roleIndex': {
    'en': 'Role index',
    'no': 'Rolleindeks',
    'se': 'Rollindex',
    'dk': 'Rolleindeks',
  },
  'roleIndexes': {
    'en': 'Role indexes',
    'no': 'Rolleindekser',
    'se': 'Rollindex',
    'dk': 'Rolleindekser',
  },
  'compareRoles': {
    'en': 'Compare roles',
    'no': 'Sammenlign roller',
    'se': 'Jämför roller',
    'dk': 'Sammenlign roller',
  },
  'createRole': {
    'en': 'Create role',
    'no': 'Opprett rolle',
    'se': 'Skapa roll',
    'dk': 'Opret rolle',
  },
  'createNewRole': {
    'en': 'Create new role',
    'no': 'Opprett ny rolle',
    'se': 'Skapa ny roll',
    'dk': 'Opret ny rolle',
  },
  'createRoleInfo': {
    'en': 'The role will be created immediately, but it will take some time to calculate ratings and indexes for the new role. The values will likely be ready by tomorrow.',
    'no': 'Rollen vil opprettes umiddelbart, men det vil ta litt tid å beregne ratinger og indekser for den nye rollen. Verdiene vil sannsynligvis være klare til i morgen.',
    'se': 'Rollen skapas omedelbart, men det tar lite tid att beräkna ratings och index för den nya rollen. Värdena kommer sannolikt att vara klara till imorgon.',
    'dk': 'Rollen oprettes med det samme, men det vil tage noget tid at beregne ratings og indekser for den nye rolle. Værdierne vil sandsynligvis være klar i morgen.',
  },
  'editRole': {
    'en': 'Edit role',
    'no': 'Rediger rolle',
    'se': 'Redigera roll',
    'dk': 'Rediger rolle',
  },
  'confirmRoleEditsInfo': {
    'en': 'It will take some time to recalculate ratings and indexes for the updated role. The values will likely be ready by tomorrow.',
    'no': 'Det vil ta litt tid å beregne nye ratinger og indekser for den oppdaterte rollen. Verdiene vil sannsynligvis være klare til i morgen.',
    'se': 'Det tar lite tid att beräkna nya ratings och index för den uppdaterade rollen. Värdena kommer sannolikt att vara klara till imorgon.',
    'dk': 'Det vil tage noget tid at beregne nye ratings og indekser for den opdaterede rolle. Værdierne vil sandsynligvis være klar i morgen.',
  },
  'deleteRole': {
    'en': 'Delete role',
    'no': 'Slett rolle',
    'se': 'Radera roll',
    'dk': 'Slet rolle',
  },
  'leaveRole?': {
    'en': 'Leave role?',
    'no': 'Forlat rollen?',
    'se': 'Lämna rollen?',
    'dk': 'Forlad rollen?',
  },
  'provideNameofRole': {
    'en': 'Provide role name',
    'no': 'Oppgi navn på rollen',
    'se': 'Ange namn på rollen',
    'dk': 'Angiv navn på rollen',
  },
  'max14CharactersInRoleName': {
    'en': 'Roles names can not contain more than 14 characters',
    'no': 'Rollenavn kan ikke inneholde mer enn 14 tegn',
    'se': 'Rollnamn kan inte innehålla mer än 14 tecken',
    'dk': 'Rollenavne kan ikke indeholde mere end 14 tegn',
  },
  'max10MetricsInRole': {
    'en': 'A role can not contain more than 10 metrics',
    'no': 'En rolle kan ikke inneholde mer enn 10 metrikker',
    'se': 'En roll kan inte innehålla mer än 10 metriker',
    'dk': 'En rolle kan ikke indeholde mere end 10 metrikker',
  },
  'youMustProvideNameOfRole': {
    'en': 'You must provide a name for the role',
    'no': 'Du må oppgi navn på rollen',
    'se': 'Du må ange namn på rollen',
    'dk': 'Du skal angive navn på rollen',
  },
  'youMustProvideWeightsForRole': {
    'en': 'You must provide weights for the role',
    'no': 'Du må oppgi vekter for rollen',
    'se': 'Du må ange vikter för rollen',
    'dk': 'Du skal angive vægte for rollen',
  },
  'xP': {
    'en': 'Expected points',
    'no': 'Forventede poeng',
    'se': 'Förväntade poäng',
    'dk': 'Forventede point',
  },
  'xG': {
    'en': 'xG created',
    'no': 'xG skapt',
    'se': 'xG skapat',
    'dk': 'xG skabt',
  },
  'xGC': {
    'en': 'xG conceded',
    'no': 'xG sluppet til',
    'se': 'xG släppt in',
    'dk': 'xG lukket ind',
  },
  'xGData': {
    'en': 'xG data',
    'no': 'xG data',
    'se': 'xG data',
    'dk': 'xG data',
  },
  'xGDataTitle': {
    'en': 'xG data per 90 for',
    'no': 'xG data per 90 for',
    'se': 'xG data per 90 for',
    'dk': 'xG data per 90 for',
  },
  'xGDifference': {
    'en': 'xG difference',
    'no': 'xG differanse',
    'se': 'xG differens',
    'dk': 'xG forskel',
  },
  'xGPer90': {
    'en': 'xG created per 90',
    'no': 'xG skapt per 90',
    'se': 'xG skapat per 90',
    'dk': 'xG skabt per 90',
  },
  'xGCPer90': {
    'en': 'xG conceded per 90',
    'no': 'xG sluppet til per 90',
    'se': 'xG släppt in per 90',
    'dk': 'xG lukket ind per 90',
  },
  'xGDifferencePer90': {
    'en': 'xG difference per 90',
    'no': 'xG differanse per 90',
    'se': 'xG differens per 90',
    'dk': 'xG forskel per 90',
  },


  // players
  'player': {
    'en': 'Player',
    'no': 'Spiller',
    'se': 'Spelare',
    'dk': 'Spiller',
  },
  'players': {
    'en': 'Players',
    'no': 'Spillere',
    'se': 'Spelare',
    'dk': 'Spillere',
  },
  'selectPlayer': {
    'en': 'Select player',
    'no': 'Velg spiller',
    'se': 'Välj spelare',
    'dk': 'Vælg spiller',
  },
  'selectPlayerForComparison': {
    'en': 'Select player to compare with',
    'no': 'Velg spiller å sammenligne med',
    'se': 'Välj spelare att jämföra med',
    'dk': 'Vælg spiller at sammenligne med',
  },
  'selectedPlayer': {
    'en': 'Selected player',
    'no': 'Valgt spiller',
    'se': 'Vald spelare',
    'dk': 'Valgt spiller',
  },
  'noPlayerSelected': {
    'en': 'No player selected',
    'no': 'Ingen spiller valgt',
    'se': 'Ingen spelare vald',
    'dk': 'Ingen spiller valgt',
  },
  'addPlayer': {
    'en': 'Add player',
    'no': 'Legg til spiller',
    'se': 'Lägg till spelare',
    'dk': 'Tilføj spiller',
  },
  'addPlayerTo': {
    'en': 'Add player to',
    'no': 'Legg til spiller på',
    'se': 'Lägg till spelare på',
    'dk': 'Tilføj spiller til',
  },
  'connectPlayer': {
    'en': 'Connect player',
    'no': 'Koble spiller',
    'se': 'Koppla spelare',
    'dk': 'Tilslut spiller',
  },
  'deletedPlayer': {
    'en': 'Deleted player',
    'no': 'Slettet spiller',
    'se': 'Raderad spelare',
    'dk': 'Slettet spiller',
  },
  'movePlayer': {
    'en': 'Move player',
    'no': 'Flytt spiller',
    'se': 'Flytta spelare',
    'dk': 'Flyt spiller',
  },
  'removed': {
    'en': 'Removed',
    'no': 'Fjernet',
    'se': 'Borttagen',
    'dk': 'Fjernet',
  },
  'removePlayer': {
    'en': 'Remove player',
    'no': 'Fjern spiller',
    'se': 'Ta bort spelare',
    'dk': 'Fjern spiller',
  },
  'removePlayerFromSquad': {
    'en': 'Remove from squad',
    'no': 'Fjern spiller fra stall',
    'se': 'Ta bort från trupp',
    'dk': 'Fjern spiller fra truppen',
  },
  'playerDoesNotExistYet': {
    'en': 'The player does not exist yet and will be created when you add the player to a team',
    'no': 'Spilleren eksisterer ikke enda og blir opprettet når du legger spilleren til på et lag',
    'se': 'Spelaren existerar inte ännu och kommer att skapas när du lägger till spelaren på ett lag',
    'dk': 'Spilleren eksisterer endnu ikke og vil blive oprettet, når du tilføjer spilleren til et hold',
  },
  'playerIsDeleted': {
    'en': 'The player was added manually and has since been deleted from the platform',
    'no': 'Spilleren ble lagt til manuelt og har siden blitt slettet fra plattformen',
    'se': 'Spelaren lades till manuellt och har sedan raderats från plattformen',
    'dk': 'Spilleren blev tilføjet manuelt og er siden blevet slettet fra platformen',
  },
  'deletePlayer': {
    'en': 'Delete player',
    'no': 'Slett spiller',
    'se': 'Radera spelare',
    'dk': 'Slet spiller',
  },
  'deletePlayerInfo': {
    'en': 'The player will  be permanently deleted from the platform',
    'no': 'Spilleren vil bli slettet permanent fra plattformen',
    'se': 'Spelaren kommer att raderas permanent från plattformen',
    'dk': 'Spilleren vil blive slettet permanent fra platformen',
  },
  'existingPlayersWithoutData': {
    'en': 'Players without data',
    'no': 'Spillere uten data',
    'se': 'Spelare utan data',
    'dk': 'Spillere uden data',
  },
  'addPlayerWithoutData': {
    'en': 'Add player without data',
    'no': 'Legg til spiller uten data',
    'se': 'Lägg till spelare utan data',
    'dk': 'Tilføj spiller uden data',
  },
  'addPlayerWithoutData?': {
    'en': 'Add a player without data?',
    'no': 'Legge til en spiller uten data?',
    'se': 'Lägga till en spelare utan data?',
    'dk': 'Tilføje en spiller uden data?',
  },
  'age': {
    'en': 'Age',
    'no': 'Alder',
    'se': 'Ålder',
    'dk': 'Alder',
  },
  'birthDate': {
    'en': 'Birth date',
    'no': 'Fødselsdato',
    'se': 'Födelsedatum',
    'dk': 'Fødselsdato',
  },
  'birthYear': {
    'en': 'Birth year',
    'no': 'Fødselsår',
    'se': 'Födelseår',
    'dk': 'Fødselsår',
  },
  'height': {
    'en': 'Height',
    'no': 'Høyde',
    'se': 'Längd',
    'dk': 'Højde',
  },
  'career': {
    'en': 'Career',
    'no': 'Karriere',
    'se': 'Karriär',
    'dk': 'Karriere'
  },
  'noNationalCareer': {
    'en': 'No national team career (since 2015)',
    'no': 'Ingen landslagskamper (siden 2015)',
    'se': 'Inga landskamper (sedan 2015)',
    'dk': 'Ingen landskampe (siden 2015)',
  },
  'playerHistory': {
    'en': 'Player history',
    'no': 'Spillerlogg',
    'se': 'Spelarhistorik',
    'dk': 'Spillerhistorik',
  },
  'noHistory': {
    'en': 'No history',
    'no': 'Ingen historikk',
    'se': 'Ingen historik',
    'dk': 'Ingen historik',
  },
  'nationality': {
    'en': 'Nationality',
    'no': 'Nasjonalitet',
    'se': 'Nationalitet',
    'dk': 'Nationalitet',
  },
  'nationalities': {
    'en': 'Nationalities',
    'no': 'Nasjonaliteter',
    'se': 'Nationaliteter',
    'dk': 'Nationaliteter',
  },
  'local': {
    'en': 'Local',
    'no': 'Lokal',
    'se': 'Lokal',
    'dk': 'Lokal',
  },
  'locality': {
    'en': 'Locality',
    'no': 'Lokalitet',
    'se': 'Lokalitet',
    'dk': 'Lokalitet',
  },
  'localities': {
    'en': 'Localities',
    'no': 'Lokaliteter',
    'se': 'Lokaliteter',
    'dk': 'Lokaliteter',
  },
  'localLocality': {
    'en': 'Local',
    'no': 'Klubbutviklet',
    'se': 'Klubbutvecklad',
    'dk': 'Klubudviklet',
  },
  'nationalLocality': {
    'en': 'National',
    'no': 'Forbundsutviklet',
    'se': 'Förbundsutvecklad',
    'dk': 'Forbundsudviklet',
  },
  'internationalLocality': {
    'en': 'International',
    'no': 'Fri spiller',
    'se': 'Fri spelare',
    'dk': 'Fri spiller',
  },
  'percentageMinutesPlayed': {
    'en': 'Percentage of minutes played',
    'no': 'Prosentandel minutter spilt',
    'se': 'Procentandel minuter spelade',
    'dk': 'Procentdel minutter spillet',
  },
  'numberOfMinutesPlayed': {
    'en': 'Number of minutes played',
    'no': 'Antall minutter spilt',
    'se': 'Antal minuter spelade',
    'dk': 'Antal minutter spillet',
  },
  'showNumberOfMinutes': {
    'en': 'Show absolute minutes',
    'no': 'Vis antall minutter',
    'se': 'Visa antal minuter',
    'dk': 'Vis antal minutter',
  },
  'showPercentageMinutesPlayed': {
    'en': 'Show percentage of minutes played',
    'no': 'Vis prosentandel minutter spilt',
    'se': 'Visa procentandel minuter spelade',
    'dk': 'Vis procentdel minutter spillet',
  },
  'showPlayerInfo': {
    'en': 'Show player info',
    'no': 'Vis spillerinformasjon',
    'se': 'Visa spelarinfo',
    'dk': 'Vis spillerinfo',
  },
  'providePlayerName': {
    'en': 'Provide the player name',
    'no': 'Oppgi navn på spilleren',
    'se': 'Ange spelarens namn',
    'dk': 'Angiv spillerens navn',
  },
  'playerWasAddedWithoutData': {
    'en': 'The player was added manually and is not connected to a player in the database.',
    'no': 'Spilleren ble lagt til manuelt og er ikke koblet opp mot en spiller i databasen.',
    'se': 'Spelaren lades till manuellt och är inte kopplad till en spelare i databasen.',
    'dk': 'Spilleren blev tilføjet manuelt og er ikke tilknyttet en spiller i databasen.',
  },
  'playerWasAddedWithoutDataDescription': {
    'en': 'You may connect the player to an existing player in the database if you find the player below.',
    'no': 'Du kan koble spilleren opp mot en eksisterende spiller i databasen dersom du finner spilleren nedenfor.',
    'se': 'Du kan koppla spelaren till en befintlig spelare i databasen om du hittar spelaren nedan.',
    'dk': 'Du kan tilknytte spilleren til en eksisterende spiller i databasen, hvis du finder spilleren nedenfor.',
  },
  'playerLinePlot': {
    'en': 'Skill rating development',
    'no': 'Utvikling i skillrating',
    'se': 'Utveckling i skillrating',
    'dk': 'Udvikling i skillrating',
  },
  'loanFrom': {
    'en': 'Loan from',
    'no': 'Lån fra',
    'se': 'Lån från',
    'dk': 'Lån fra',
  },


  // positions
  'position': {
    'en': 'Position',
    'no': 'Posisjon',
    'se': 'Position',
    'dk': 'Position',
  },
  'positions': {
    'en': 'Positions',
    'no': 'Posisjoner',
    'se': 'Positioner',
    'dk': 'Positioner',
  },
  'positionInTeam': {
    'en': 'Position in team',
    'no': 'Posisjon i laget',
    'se': 'Position i laget',
    'dk': 'Position i holdet',
  },
  'selectPosition': {
    'en': 'Select position',
    'no': 'Velg posisjon',
    'se': 'Välj position',
    'dk': 'Vælg position',
  },
  'selectNewPosition': {
    'en': 'Select new position',
    'no': 'Velg ny posisjon',
    'se': 'Välj ny position',
    'dk': 'Vælg ny position',
  },
  'changePosition': {
    'en': 'Change position',
    'no': 'Endre posisjon',
    'se': 'Ändra position',
    'dk': 'Skift position',
  },
  'positionGroups': {
    'en': 'Position groups',
    'no': 'Posisjonsgrupper',
    'se': 'Positiongrupper',
    'dk': 'Positiongrupper',
  },
  'positionsAndRoles': {
    'en': 'Positions and roles',
    'no': 'Posisjoner og roller',
    'se': 'Positioner och roller',
    'dk': 'Positioner og roller',
  },
  'noRolesForPositionGroup': {
    'en': 'No roles defined for position group',
    'no': 'Ingen roller definert for posisjonsgruppen',
    'se': 'Inga roller definierade för positiongruppen',
    'dk': 'Ingen roller defineret for positiongruppen',
  },


  // matches
  'matchDate': {
    'en': 'Match date',
    'no': 'Kampdato',
    'se': 'Matchdatum',
    'dk': 'Kampdato',
  },
  'matches': {
    'en': 'Matches',
    'no': 'Kamper',
    'se': 'Matcher',
    'dk': 'Kampe',
  },
  'matchesStarted': {
    'en': 'Matches started',
    'no': 'Kamper startet',
    'se': 'Matcher startade',
    'dk': 'Kampe startet',
  },
  'starts': {
    'en': 'Starts',
    'no': 'Starter',
    'se': 'Starter',
    'dk': 'Starter',
  },
  'matchesDrawn': {
    'en': 'Draw',
    'no': 'Uavgjort',
    'se': 'Oavgjort',
    'dk': 'Uafgjort',
  },
  'matchesDrawnShort': {
    'en': 'D',
    'no': 'U',
    'se': 'O',
    'dk': 'U',
  },
  'matchesLost': {
    'en': 'Loss',
    'no': 'Tap',
    'se': 'Förlust',
    'dk': 'Tab',
  },
  'matchesLostShort': {
    'en': 'L',
    'no': 'T',
    'se': 'F',
    'dk': 'T',
  },
  'matchesPlayed': {
    'en': 'Matches played',
    'no': 'Kamper spilt',
    'se': 'Matcher spelade',
    'dk': 'Kampe spillet',
  },
  'matchesPlayedShort': {
    'en': 'MP',
    'no': 'S',
    'se': 'S',
    'dk': 'S',
  },
  'matchesWon': {
    'en': 'Won',
    'no': 'Vunnet',
    'se': 'Vunnet',
    'dk': 'Vundet',
  },
  'matchesWonShort': {
    'en': 'W',
    'no': 'V',
    'se': 'V',
    'dk': 'V',
  },
  'points': {
    'en': 'Points',
    'no': 'Poeng',
    'se': 'Poäng',
    'dk': 'Point',
  },
  'pointsShort': {
    'en': 'P',
    'no': 'P',
    'se': 'P',
    'dk': 'P',
  },
  'minutes': {
    'en': 'Minutes',
    'no': 'Minutter',
    'se': 'Minuter',
    'dk': 'Minutter',
  },
  'minutesPercentage': {
    'en': 'Minutes (%)',
    'no': 'Minutter (%)',
    'se': 'Minuter (%)',
    'dk': 'Minutter (%)',
  },
  'minutesPlayed': {
    'en': 'Minutes played',
    'no': 'Minutter spilt',
    'se': 'Minuter spelade',
    'dk': 'Minutter spillet',
  },
  'minutesPlayedPercentage': {
    'en': 'Minutes played (%)',
    'no': 'Minutter spilt (%)',
    'se': 'Minuter spelade (%)',
    'dk': 'Minutter spillet (%)',
  },


  // contracts
  'contract': {
    'en': 'Contract',
    'no': 'Kontrakt',
    'se': 'Kontrakt',
    'dk': 'Kontrakt',
  },
  'contracts': {
    'en': 'Contracts',
    'no': 'Kontrakter',
    'se': 'Kontrakt',
    'dk': 'Kontrakter',
  },
  'contractExpiration': {
    'en': 'Contract expiration',
    'no': 'Kontrakt (utløpsår)',
    'se': 'Kontrakt (utgångsår)',
    'dk': 'Kontrakt (udløbsår)',
  },
  'newExpirationDate': {
    'en': 'New expiration date',
    'no': 'Ny utløpsdato',
    'se': 'Ny utgångsdatum',
    'dk': 'Ny udløbsdato',
  },
  'contractLength': {
    'en': 'Contract length',
    'no': 'Kontraktslengde',
    'se': 'Kontraktslängd',
    'dk': 'Kontraktslængde',
  },
  'contractLengths': {
    'en': 'Contract lengths',
    'no': 'Kontraktslengder',
    'se': 'Kontraktslängder',
    'dk': 'Kontraktslængder',
  },
  'contractStatus': {
    'en': 'Contract status',
    'no': 'Kontraktsstatus',
    'se': 'Kontraktstatus',
    'dk': 'Kontraktstatus',
  },
  'editContract': {
    'en': 'Edit contract',
    'no': 'Endre kontrakt',
    'se': 'Redigera kontrakt',
    'dk': 'Rediger kontrakt',
  },
  'over1year': {
    'en': 'Over 1 year',
    'no': 'Over 1 år',
    'se': 'Över 1 år',
    'dk': 'Over 1 år',
  },
  'over2years': {
    'en': 'Over 2 years',
    'no': 'Over 2 år',
    'se': 'Över 2 år',
    'dk': 'Over 2 år',
  },
  'over3years': {
    'en': 'Over 3 years',
    'no': 'Over 3 år',
    'se': 'Över 3 år',
    'dk': 'Over 3 år',
  },
  'over4years': {
    'en': 'Over 4 years',
    'no': 'Over 4 år',
    'se': 'Över 4 år',
    'dk': 'Over 4 år',
  },
  'includeUnknownContractStatus': {
    'en': 'Include unknown status',
    'no': 'Inkluder ukjent status',
    'se': 'Inkludera okänd status',
    'dk': 'Inkluder ukendt status',
  },
  'freeAgent': {
    'en': 'Free agent',
    'no': 'Bosmanspiller',
    'se': 'Bosmanspelare',
    'dk': 'Bosmanspiller',
  },
  'under6months': {
    'en': 'Under 6 months',
    'no': 'Under 6 måneder',
    'se': 'Under 6 månader',
    'dk': 'Under 6 måneder',
  },
  'under12months': {
    'en': 'Under 12 months',
    'no': 'Under 12 måneder',
    'se': 'Under 12 månader',
    'dk': 'Under 12 måneder',
  },
  'under18months': {
    'en': 'Under 18 months',
    'no': 'Under 18 måneder',
    'se': 'Under 18 månader',
    'dk': 'Under 18 måneder',
  },
  'under1year': {
    'en': 'Under 1 year',
    'no': 'Under 1 år',
    'se': 'Under 1 år',
    'dk': 'Under 1 år',
  },
  'under2years': {
    'en': 'Under 2 years',
    'no': 'Under 2 år',
    'se': 'Under 2 år',
    'dk': 'Under 2 år',
  },
  'under3years': {
    'en': 'Under 3 years',
    'no': 'Under 3 år',
    'se': 'Under 3 år',
    'dk': 'Under 3 år',
  },
  'under4years': {
    'en': 'Under 4 years',
    'no': 'Under 4 år',
    'se': 'Under 4 år',
    'dk': 'Under 4 år',
  },


  // teams/squads
  'formation': {
    'en': 'Formation',
    'no': 'Formasjon',
    'se': 'Formation',
    'dk': 'Formation',
  },
  'selectFormation': {
    'en': 'Select formation',
    'no': 'Velg formasjon',
    'se': 'Välj formation',
    'dk': 'Vælg formation',
  },
  'editFormation': {
    'en': 'Change formation',
    'no': 'Endre formasjon',
    'se': 'Ändra formation',
    'dk': 'Ændre formation',
  },
  'addNewTeam': {
    'en': 'Add new team',
    'no': 'Legg til nytt lag',
    'se': 'Lägg till nytt lag',
    'dk': 'Tilføj nyt hold',
  },
  'addNewSquad': {
    'en': 'Add new squad',
    'no': 'Legg til ny stall',
    'se': 'Lägg till ny trupp',
    'dk': 'Tilføj nyt trup',
  },
  'addToTeam': {
    'en': 'Add to team',
    'no': 'Legg til på lag',
    'se': 'Lägg till i lag',
    'dk': 'Tilføj til hold',
  },
  'addToSquad': {
    'en': 'Add to squad',
    'no': 'Legg til stall',
    'se': 'Lägg till i trupp',
    'dk': 'Tilføj til trup',
  },
  'allArchivedTeams': {
    'en': 'All archived teams',
    'no': 'Alle arkiverte lag',
    'se': 'Alla arkiverade lag',
    'dk': 'Alle arkiverede hold',
  },
  'noArchivedTeams': {
    'en': 'No archived teams',
    'no': 'Ingen arkiverte lag',
    'se': 'Inga arkiverade lag',
    'dk': 'Ingen arkiverede hold',
  },
  'showDeletedArchivedTeams': {
    'en': 'Show deleted archived teams',
    'no': 'Vis slettede arkiverte lag',
    'se': 'Visa raderade arkiverade lag',
    'dk': 'Vis slættede arkiverede hold',
  },
  'allNationalTeams': {
    'en': 'All national teams',
    'no': 'Alle landslag',
    'se': 'Alla landslag',
    'dk': 'Alle landshold',
  },
  'archivedTeams': {
    'en': 'Archived teams',
    'no': 'Arkiverte lag',
    'se': 'Arkiverade lag',
    'dk': 'Arkiverede hold',
  },
  'archiveTeam': {
    'en': 'Archive team',
    'no': 'Arkiver lag',
    'se': 'Arkivera lag',
    'dk': 'Arkiver hold',
  },
  'archiveSquad': {
    'en': 'Archive squad',
    'no': 'Arkiver stall',
    'se': 'Arkivera trupp',
    'dk': 'Arkiver trup',
  },
  'archiveTeamInfo': {
    'en': 'The current team will be archived',
    'no': 'Nåværende lag vil bli arkivert',
    'se': 'Nuvarande lag kommer att arkiveras',
    'dk': 'Nuværende hold vil blive arkiveret',
  },
  'archiveSquadInfo': {
    'en': 'The current squad will be archived',
    'no': 'Nåværende stall vil bli arkivert',
    'se': 'Nuvarande trupp kommer att arkiveras',
    'dk': 'Nuværende trup vil blive arkiveret',
  },
  'resetSquad': {
    'en': 'Reset squad',
    'no': 'Nullstill stall',
    'se': 'Återställ trupp',
    'dk': 'Nulstil trup',
  },
  'resetSquadInfo': {
    'en': 'All players will be removed from the squad',
    'no': 'Alle spillerne vil bli fjernet fra stallen',
    'se': 'Alla spelare kommer att tas bort från truppen',
    'dk': 'Alle spillerne vil blive fjernet fra truppen',
  },
  'resetTeam': {
    'en': 'Reset team',
    'no': 'Nullstill lag',
    'se': 'Återställ lag',
    'dk': 'Nulstil hold',
  },
  'resetTeamInfo': {
    'en': 'All players will be removed from the team',
    'no': 'Alle spillerne vil bli fjernet fra laget',
    'se': 'Alla spelare kommer att tas bort från laget',
    'dk': 'Alle spillerne vil blive fjernet fra holdet',
  },
  'createTeam': {
    'en': 'Create team',
    'no': 'Opprett lag',
    'se': 'Skapa lag',
    'dk': 'Opret hold',
  },
  'createSquad': {
    'en': 'Create squad',
    'no': 'Opprett stall',
    'se': 'Skapa trupp',
    'dk': 'Opret trup',
  },
  'deleteTeam': {
    'en': 'Delete team',
    'no': 'Slett lag',
    'se': 'Radera lag',
    'dk': 'Slet hold',
  },
  'deleteSquad': {
    'en': 'Delete squad',
    'no': 'Slett stall',
    'se': 'Radera trupp',
    'dk': 'Slet trup',
  },
  'team': {
    'en': 'Team',
    'no': 'Lag',
    'se': 'Lag',
    'dk': 'Hold',
  },
  'opponentTeam': {
    'en': 'Opponent team',
    'no': 'Motstanderlag',
    'se': 'Motståndarlag',
    'dk': 'Modstanderhold',
  },
  'selectTeam': {
    'en': 'Select team',
    'no': 'Velg lag',
    'se': 'Välj lag',
    'dk': 'Vælg hold',
  },
  'selectNewTeam': {
    'en': 'Select new team',
    'no': 'Velg nytt lag',
    'se': 'Välj ny klubb',
    'dk': 'Vælg nyt hold',
  },
  'logisticTeams': {
    'en': 'Teams',
    'no': 'Logistikklag',
    'se': 'Logistiklag',
    'dk': 'Logistikhold',
  },
  'scoutTeams': {
    'en': 'Scout teams',
    'no': 'Speiderlag',
    'se': 'Scoutlag',
    'dk': 'Spejderhold',
  },
  'squadBuilder': {
    'en': 'Squad builder',
    'no': 'Stallbygger',
    'se': 'Truppbyggare',
    'dk': 'Trupbygger',
  },
  'teamActions': {
    'en': 'Team actions',
    'no': 'Laghandlinger',
    'se': 'Lagåtgärder',
    'dk': 'Holdhandlinger',
  },
  'squadActions': {
    'en': 'Squad actions',
    'no': 'Stallhandlinger',
    'se': 'Trupphandlingar',
    'dk': 'Truphandlinger',
  },
  'teamMenu': {
    'en': 'Team menu',
    'no': 'Lagmeny',
    'se': 'Lagmeny',
    'dk': 'Holdmenu',
  },
  'teamOfPlayer': {
    'en': 'Team of player',
    'no': 'Spillerens lag',
    'se': 'Spelarens lag',
    'dk': 'Spillerens hold',
  },
  'teamRole': {
    'en': 'Team role',
    'no': 'Lagrolle',
    'se': 'Lagroll',
    'dk': 'Holdrolle',
  },
  'teamRoles': {
    'en': 'Team roles',
    'no': 'Lagroller',
    'se': 'Lagroller',
    'dk': 'Holdroller',
  },
  'addTeamRole': {
    'en': 'Provide team role',
    'no': 'Oppgi lagrolle',
    'se': 'Ange lagroll',
    'dk': 'Angiv holdrolle',
  },
  'editTeamRole': {
    'en': 'Edit team role',
    'no': 'Endre lagrolle',
    'se': 'Ändra lagroll',
    'dk': 'Rediger holdrolle',
  },
  'teamStatus': {
    'en': 'Team status',
    'no': 'Lagstatus',
    'se': 'Lagstatus',
    'dk': 'Holdstatus',
  },
  'squadStatus': {
    'en': 'Squad status',
    'no': 'Stallstatus',
    'se': 'Truppstatus',
    'dk': 'Trupstatus',
  },
  'editTeamStatus': {
    'en': 'Edit team status',
    'no': 'Endre lagsstatus',
    'se': 'Ändra lagstatus',
    'dk': 'Rediger holdstatus',
  },
  'editSquadStatus': {
    'en': 'Edit squad status',
    'no': 'Endre stallstatus',
    'se': 'Ändra truppstatus',
    'dk': 'Rediger trupstatus',
  },
  'showStartingEleven': {
    'en': 'Show starting eleven',
    'no': 'Vis startellever',
    'se': 'Visa startelvor',
    'dk': 'Vis startopstilling',
  },
  'hideUnavailablePlayers': {
    'en': 'Hide unavailable players',
    'no': 'Skjul utilgjengelige',
    'se': 'Dölj otillgängliga spelare',
    'dk': 'Skjul utilgængelige',
  },
  'hideUnavailablePlayersDescription': {
    'en': 'Hide injured and loaned out players',
    'no': 'Skjul skadede og utlånte spillere',
    'se': 'Dölj skadade och utlånade spelare',
    'dk': 'Skjul skadede og udlejede spillere',
  },
  'scrollGoalkeepers': {
    'en': 'Scroll goalkeepers',
    'no': 'Scroll keepere',
    'se': 'Scroll målvakter',
    'dk': 'Scroll målmænd',
  },
  'scrollDefenders': {
    'en': 'Scroll defenders',
    'no': 'Scroll forsvarere',
    'se': 'Scroll försvarare',
    'dk': 'Scroll forsvarere',
  },
  'scrollMidfielders': {
    'en': 'Scroll midfielders',
    'no': 'Scroll midtbanen',
    'se': 'Scroll mittfältare',
    'dk': 'Scroll midtbanespillere',
  },
  'scrollAttackers': {
    'en': 'Scroll forwards',
    'no': 'Scroll angripere',
    'se': 'Scroll anfallare',
    'dk': 'Scroll angribere',
  },
  'numberOfForeigners': {
    'en': 'Number of foreigners',
    'no': 'Antall utlendinger',
    'se': 'Antal utlänningar',
    'dk': 'Antal udlændinge',
  },
  'numberOfNationalPlayers': {
    'en': 'Number of national players',
    'no': 'Antall forbundsutviklede spillere',
    'se': 'Antal nationella spelare',
    'dk': 'Antal nationale spillere',
  },
  'numberOfInternationalPlayers': {
    'en': 'Number of international players',
    'no': 'Antall frie spillere',
    'se': 'Antal internationella spelare',
    'dk': 'Antal internationale spillere',
  },
  'numberOfLocalPlayers': {
    'en': 'Number of local players',
    'no': 'Antall klubbutviklede spillere',
    'se': 'Antal lokala spelare',
    'dk': 'Antal lokale spillere',
  },
  'numberOfLocalAndNationalPlayers': {
    'en': 'Number of local and national players',
    'no': 'Antall lokalt utviklede spillere',
    'se': 'Antal lokala och nationella spelare',
    'dk': 'Antal lokale og nationale spillere',
  },
  'numberOfPlayerLoanedIn': {
    'en': 'Number of players loaned in',
    'no': 'Antall spillere på innlån',
    'se': 'Antal spelare på lån in',
    'dk': 'Antal spillere på leje ind',
  },
  'numberOfPlayerLoanedOut': {
    'en': 'Number of players loaned out',
    'no': 'Antall spillere på utlån',
    'se': 'Antal spelare på lån ut',
    'dk': 'Antal spillere på leje ud',
  },
  'showAcademyPlayers': {
    'en': 'Show academy players',
    'no': 'Vis akademispillere',
    'se': 'Visa akademispelare',
    'dk': 'Vis akademispillere',
  },
  'clubTeam': {
    'en': 'Club team',
    'no': 'Klubblag',
    'se': 'Klubblag',
    'dk': 'Klubhold',
  },
  'clubTeamTitle': {
    'en': 'Club team (since 2015)',
    'no': 'Klubblag (siden 2015)',
    'se': 'Klubblag (sedan 2015)',
    'dk': 'Klubhold (siden 2015)',
  },
  'nationalTeam': {
    'en': 'National team',
    'no': 'Landslag',
    'se': 'Landslag',
    'dk': 'Landshold',
  },
  'nationalTeamTitle': {
    'en': 'National team (since 2015)',
    'no': 'Landslag (siden 2015)',
    'se': 'Landslag (sedan 2015)',
    'dk': 'Landshold (siden 2015)',
  },
  'theNationalTeam': {
    'en': 'National team',
    'no': 'Landslaget',
    'se': 'Landslaget',
    'dk': 'Landsholdet',
  },
  'youthNationalTeams': {
    'en': 'Youth national teams',
    'no': 'Aldersbestemte landslag',
    'se': 'Ungdomslandslag',
    'dk': 'Ungdomslandshold',
  },
  'editTeamName': {
    'en': 'Edit team name',
    'no': 'Endre navn på laget',
    'se': 'Ändra lagets namn',
    'dk': 'Rediger holdnavn',
  },
  'editSquadName': {
    'en': 'Edit squad name',
    'no': 'Endre navn på stallen',
    'se': 'Ändra truppnamn',
    'dk': 'Rediger trupnavn',
  },
  'provideTeamName': {
    'en': 'Provide the team name',
    'no': 'Oppgi navn på laget',
    'se': 'Ange lagets namn',
    'dk': 'Angiv holdnavn',
  },
  'provideSquadName': {
    'en': 'Provide the squad name',
    'no': 'Oppgi navn på stallen',
    'se': 'Ange truppnamn',
    'dk': 'Angiv trupnavn',
  },
  'provideTeam': {
    'en': 'Provide team',
    'no': 'Oppgi lag',
    'se': 'Ange lag',
    'dk': 'Angiv hold',
  },
  'loadPlayersFromTeam': {
    'en': 'Load players from a team',
    'no': 'Last inn spillere fra et lag',
    'se': 'Ladda spelare från ett lag',
    'dk': 'Indlæs spillere fra et hold',
  },
  'roleIndexInTeam': {
    'en': 'Role index in teams',
    'no': 'Rolleindeks inne på lag',
    'se': 'Rollindex i lag',
    'dk': 'Rolleindeks i hold',
  },
  'playerSquad': {
    'en': 'Squad',
    'no': 'Spillerstall',
    'se': 'Spelartrupp',
    'dk': 'Spillertrup',
  },


  // search
  'search': {
    'en': 'Search',
    'no': 'Søk',
    'se': 'Sök',
    'dk': 'Søg',
  },
  'playerSearch': {
    'en': 'Player search',
    'no': 'Spillersøk',
    'se': 'Spelarsök',
    'dk': 'Spillersøg',
  },
  'seasonSearch': {
    'en': 'Season search',
    'no': 'Sesongsøk',
    'se': 'Säsongssök',
    'dk': 'Sæsonsøg',
  },
  'createSearch': {
    'en': 'Create search',
    'no': 'Opprett søk',
    'se': 'Skapa sökning',
    'dk': 'Opret søgning',
  },
  'editSearch': {
    'en': 'Edit search',
    'no': 'Endre søket',
    'se': 'Ändra sökningen',
    'dk': 'Rediger søgningen',
  },
  'deleteSearch': {
    'en': 'Delete search',
    'no': 'Slett søk',
    'se': 'Radera sökning',
    'dk': 'Slet søgning',
  },
  'loadSearch': {
    'en': 'Load search',
    'no': 'Last inn søk',
    'se': 'Ladda sökning',
    'dk': 'Indlæs søgning',
  },
  'saveSearch': {
    'en': 'Save search',
    'no': 'Lagre søk',
    'se': 'Spara sökning',
    'dk': 'Gem søgning',
  },
  'noSavedSearches': {
    'en': 'No saved searches',
    'no': 'Ingen lagrede søk',
    'se': 'Inga sparade sökningar',
    'dk': 'Ingen gemte søgninger',
  },
  'allSearches': {
    'en': 'All searches',
    'no': 'Alle søk',
    'se': 'Alla sökningar',
    'dk': 'Alle søgninger',
  },
  'thisSearch': {
    'en': 'This search',
    'no': 'Dette søket',
    'se': 'Denna sökning',
    'dk': 'Denne søgning',
  },
  'searchAgain?': {
    'en': 'Do you want to search again?',
    'no': 'Vil du søke på nytt?',
    'se': 'Vill du söka igen?',
    'dk': 'Vil du søge igen?',
  },
  'searchForClub': {
    'en': 'Search for club',
    'no': 'Søk etter klubb',
    'se': 'Sök efter klubb',
    'dk': 'Søg efter klub',
  },
  'searchForCountry': {
    'en': 'Search for country',
    'no': 'Søk etter land',
    'se': 'Sök efter land',
    'dk': 'Søg efter land',
  },
  'searchForIteration': {
    'en': 'Search for season',
    'no': 'Søk etter sesong',
    'se': 'Sök efter säsong',
    'dk': 'Søg efter sæson',
  },
  'searchForLeague': {
    'en': 'Search for league',
    'no': 'Søk etter liga',
    'se': 'Sök efter liga',
    'dk': 'Søg efter liga',
  },
  'searchForLeagueOrCup': {
    'en': 'Search for league or cup',
    'no': 'Søk etter liga eller cup',
    'se': 'Sök efter liga eller cup',
    'dk': 'Søg efter liga eller cup',
  },
  'searchForName': {
    'en': 'Search for name',
    'no': 'Søk etter navn',
    'se': 'Sök efter namn',
    'dk': 'Søg efter navn',
  },
  'searchForPlayers': {
    'en': 'Search for players',
    'no': 'Søk etter spillere',
    'se': 'Sök efter spelare',
    'dk': 'Søg efter spillere',
  },
  'searchForPlayer?': {
    'en': 'Search for player?',
    'no': 'Søk etter spiller?',
    'se': 'Sök efter spelare?',
    'dk': 'Søg efter spiller?',
  },
  'searchForSeasons': {
    'en': 'Search for seasons',
    'no': 'Søk etter sesonger',
    'se': 'Sök efter säsonger',
    'dk': 'Søg efter sæsoner',
  },
  'searchNameAlreadyExists': {
    'en': 'A search with the same name already exists',
    'no': 'Det eksisterer allerede et søk med samme navn',
    'se': 'En sökning med samma namn finns redan',
    'dk': 'Der findes allerede et søg med samme navn',
  },
  'provideNameOfSearch': {
    'en': 'Provide name of search',
    'no': 'Skriv inn navn på søket',
    'se': 'Ange namn på sökningen',
    'dk': 'Angiv navn på søgningen',
  },
  'orderByActualValues': {
    'en': 'Sort by actual values',
    'no': 'Sorter etter faktiske verdier',
    'se': 'Sortera efter faktiska värden',
    'dk': 'Sorter efter faktiske værdier',
  },
  'orderByActualValuesTitle': {
    'en': 'Order by actual values (number per 90 or percentage)',
    'no': 'Sorter etter faktiske verdier (antall per 90 eller prosent)',
    'se': 'Sortera efter faktiska värden (antal per 90 eller procent)',
    'dk': 'Sorter efter faktiske værdier (antal per 90 eller procent)',
  },
  'orderByNormalizedValuesTitle': {
    'en': 'Order by normalized values (0 to 10)',
    'no': 'Sorter etter normaliserte verdier (0 til 10)',
    'se': 'Sortera efter normaliserade värden (0 till 10)',
    'dk': 'Sorter efter normaliserede værdier (0 til 10)',
  },
  'showNormalizedValues': {
    'en': 'Show normalized values (0 to 10)',
    'no': 'Vis normaliserte verdier (0 til 10)',
    'se': 'Visa normaliserade värden (0 till 10)',
    'dk': 'Vis normaliserede værdier (0 til 10)',
  },
  'showActualValues': {
    'en': 'Show actual values (number per 90 or percentage)',
    'no': 'Vis faktiske verdier (antall per 90 eller prosent)',
    'se': 'Visa faktiska värden (antal per 90 eller procent)',
    'dk': 'Vis faktiske værdier (antal per 90 eller procent)',
  },
  'youMustProvideSearchName': {
    'en': 'You must provide a name for the search',
    'no': 'Du må oppgi et navn på søket',
    'se': 'Du måste ange ett namn för sökningen',
    'dk': 'Du skal angive et navn for søgningen',
  },
  'noResult': {
    'en': 'No results',
    'no': 'Ingen treff',
    'se': 'Inga resultat',
    'dk': 'Ingen resultater',
  },


  // filters
  'moreFilters': {
    'en': 'More filters',
    'no': 'Flere filtre',
    'se': 'Fler filter',
    'dk': 'Flere filtre',
  },
  'resetFilters': {
    'en': 'Reset filters',
    'no': 'Nullstill filtre',
    'se': 'Återställ filter',
    'dk': 'Nulstil filtre',
  },
  'showFilters': {
    'en': 'Show filters',
    'no': 'Vis filtre',
    'se': 'Visa filter',
    'dk': 'Vis filtre',
  },
  'orderBy': {
    'en': 'Order by',
    'no': 'Sorter etter',
    'se': 'Sortera efter',
    'dk': 'Sorter efter',
  },
  'minMinutesPlayed': {
    'en': 'Min minutes played',
    'no': 'Min. minutter spilt',
    'se': 'Min. spelade minuter',
    'dk': 'Min. minutter spillet',
  },
  'minAge': {
    'en': 'Min age',
    'no': 'Min. alder',
    'se': 'Min. ålder',
    'dk': 'Min. alder',
  },
  'maxAge': {
    'en': 'Max age',
    'no': 'Maks alder',
    'se': 'Max ålder',
    'dk': 'Max alder',
  },
  'ratingRequirements': {
    'en': 'Minimum rating requirements',
    'no': 'Minimum ferdighetskrav',
    'se': 'Minimikrav på betyg',
    'dk': 'Minimum færdighedskrav',
  },
  'indexRequirements': {
    'en': 'Minimum index requirements',
    'no': 'Minimum indekskrav',
    'se': 'Minimikrav på index',
    'dk': 'Minimum indekskrav',
  },
  'minConfidence': {
    'en': 'Min precision',
    'no': 'Min. presisjon',
    'se': 'Min. precision',
    'dk': 'Min. præcision',
  },
  'selectClub': {
    'en': 'Select club',
    'no': 'Velg klubb',
    'se': 'Välj klubb',
    'dk': 'Vælg klub',
  },
  'selectCompetitions': {
    'en': 'Select competitions',
    'no': 'Velg konkurranser',
    'se': 'Välj tävlingar',
    'dk': 'Vælg konkurrencer',
  },
  'selectSeasons': {
    'en': 'Select seasons',
    'no': 'Velg sesonger',
    'se': 'Välj säsonger',
    'dk': 'Vælg sæsoner',
  },
  'selectedClub': {
    'en': 'Selected club',
    'no': 'Valgt klubb',
    'se': 'Vald klubb',
    'dk': 'Valgt klub',
  },
  'selectedClubs': {
    'en': 'Selected clubs',
    'no': 'Valgte klubber',
    'se': 'Valda klubbar',
    'dk': 'Valgte klubber',
  },
  'selectedCountries': {
    'en': 'Selected countries',
    'no': 'Valgte land',
    'se': 'Valda länder',
    'dk': 'Valgte lande',
  },
  'selectedCountry': {
    'en': 'Selected country',
    'no': 'Valgt land',
    'se': 'Valt land',
    'dk': 'Valgt land',
  },
  'selectedIterations': {
    'en': 'Selected seasons',
    'no': 'Valgte sesonger',
    'se': 'Valda säsonger',
    'dk': 'Valgte sæsoner',
  },
  'selectedLeagues': {
    'en': 'Selected ligaer',
    'no': 'Valgte ligaer',
    'se': 'Valda ligor',
    'dk': 'Valgte ligaer',
  },
  'youMustSelectAtLeastOneFilter': {
    'en': 'You must select at least one filter',
    'no': 'Du må velge minst ett filter',
    'se': 'Du måste välja minst ett filter',
    'dk': 'Du skal vælge mindst et filter',
  },


  // alerts
  'alerts': {
    'en': 'Alerts',
    'no': 'Varsler',
    'se': 'Aviseringar',
    'dk': 'Alarmer',
  },
  'addNewAlert': {
    'en': 'Add new search',
    'no': 'Opprett nytt søk',
    'se': 'Lägg till ny sökning',
    'dk': 'Tilføj ny søgning',
  },
  'playersMatchTheSearch': {
    'en': 'players match the search',
    'no': 'spillere matcher søket',
    'se': 'spelare matchar sökningen',
    'dk': 'spillere matcher søgningen',
  },
  'playersRemovedFromSearch': {
    'en': 'Players discarded from the search',
    'no': 'Spillere som er fjernet fra søket',
    'se': 'Spelare borttagna från sökningen',
    'dk': 'Spillere fjernet fra søgningen',
  },
  'showPlayersRemovedFromSearch': {
    'en': 'Show players removed from the search',
    'no': 'Vis spillere som er fjernet fra søket',
    'se': 'Visa spelare borttagna från sökningen',
    'dk': 'Vis spillere fjernet fra søgningen',
  },
  'resetExcludedPlayers': {
    'en': 'Reset players removed from this search',
    'no': 'Nullstill spillere fjernet fra dette søket',
    'se': 'Återställ spelare borttagna från denna sökning',
    'dk': 'Nulstil spillere fjernet fra denne søgning',
  },
  'resetPlayers': {
    'en': 'Reset players',
    'no': 'Nullstill spillere',
    'se': 'Återställ spelare',
    'dk': 'Nulstil spillere',
  },
  'resetExcludedPlayersInfo': {
    'en': 'All players who have been removed from this search will no longer be removed, and they will be added back to the search if they match the new search criteria',
    'no': 'Alle spillere som har blitt fjernet fra dette søket vil ikke lenger være fjernet, og de blir lagt tilbake i søket dersom de oppfyller de nye søkekriteriene',
    'se': 'Alla spelare som har tagits bort från denna sökning kommer inte längre att vara borttagna, och de kommer att läggas tillbaka i sökningen om de matchar de nya sökkriterierna',
    'dk': 'Alle spillere, der er blevet fjernet fra denne søgning, vil ikke længere blive fjernet, og de vil blive tilføjet tilbage til søgningen, hvis de matcher de nye søgekriterier',
  },
  'noPlayersRemovedFromSearch': {
    'en': 'No players have been removed from the search',
    'no': 'Ingen spillere har blitt fjerent fra søket',
    'se': 'Inga spelare har tagits bort från sökningen',
    'dk': 'Ingen spillere er blevet fjernet fra søgningen',
  },
  'noNewPlayersMatchingTheSearch': {
    'en': 'There are no new players matching the search',
    'no': 'Det er ingen nye spillere som matcher søket',
    'se': 'Det finns inga nya spelare som matchar sökningen',
    'dk': 'Der er ingen nye spillere, der matcher søgningen',
  },
  'removeFromSearch': {
    'en': 'Remove from search',
    'no': 'Fjern fra søk',
    'se': 'Ta bort från sökning',
    'dk': 'Fjern fra søgning',
  },
  'removeAll': {
    'en': 'Remove all',
    'no': 'Fjern alle',
    'se': 'Ta bort alla',
    'dk': 'Fjern alle',
  },
  'removeAllInfo': {
    'en': 'All players in the search will be removed from this search for 3 months',
    'no': 'Alle spillere som ligger i søket vil bli fjernet fra dette søket i 3 måneder',
    'se': 'Alla spelare i sökningen kommer att tas bort från denna sökning i 3 månader',
    'dk': 'Alle spillere i søgningen vil blive fjernet fra denne søgning i 3 måneder',
  },
  'undoAll': {
    'en': 'Undo all',
    'no': 'Angre alle',
    'se': 'Ångra alla',
    'dk': 'Fortryd alle',
  },
  'undoAllInfo': {
    'en': 'All players who have been removed from this search will no longer be removed, and they will be added back to the search if they still match the search criteria',
    'no': 'Alle spillere som har blitt fjernet fra dette søket vil ikke lenger være fjernet, og de blir lagt tilbake i søket dersom de fortsatt oppfyller søkekriteriene',
    'se': 'Alla spelare som har tagits bort från denna sökning kommer inte längre att vara borttagna, och de kommer att läggas tillbaka i sökningen om de fortfarande matchar sökkriterierna',
    'dk': 'Alle spillere, der er blevet fjernet fra denne søgning, vil ikke længere blive fjernet, og de vil blive tilføjet tilbage til søgningen, hvis de stadig matcher søgekriterierne',
  },
  'tooManyPlayersForAlert': {
    'en': 'The search is too wide - you must select more filters so that fewer than 1000 players match the search',
    'no': 'Søket er for bredt - du må velge flere filtre slik at færre enn 1000 spillere matcher søket',
    'se': 'Sökningen är för bred - du måste välja fler filter så att färre än 1000 spelare matchar sökningen',
    'dk': 'Søgningen er for bred - du skal vælge flere filtre, så færre end 1000 spillere matcher søgningen',
  },


  // planner
  'playerAdded': {
    'en': 'Player added',
    'no': 'Spiller lagt til',
    'se': 'Spelare tillagd',
    'dk': 'Spiller tilføjet',
  },
  'playerRemoved': {
    'en': 'Player removed',
    'no': 'Spiller fjernet',
    'se': 'Spelare borttagen',
    'dk': 'Spiller fjernet',
  },
  'contractExtended': {
    'en': 'Contract extended',
    'no': 'Kontrakt forlenget',
    'se': 'Kontrakt förlängd',
    'dk': 'Kontrakt forlænget',
  },
  'contractExpired': {
    'en': 'Contract expired',
    'no': 'Kontrakt utløpt',
    'se': 'Kontrakt utgången',
    'dk': 'Kontrakt udløbet',
  },
  'positionChanged': {
    'en': 'Position changed',
    'no': 'Posisjon endret',
    'se': 'Position ändrad',
    'dk': 'Position ændret',
  },
  'roleChanged': {
    'en': 'Role changed',
    'no': 'Rolle endret',
    'se': 'Roll ändrad',
    'dk': 'Rolle ændret',
  },
  'localityChanged': {
    'en': 'Locality changed',
    'no': 'Lokalitet endret',
    'se': 'Lokalitet ändrad',
    'dk': 'Lokalitet ændret',
  },
  'playerAdded<future>': {
    'en': 'Player is added',
    'no': 'Spiller legges til',
    'se': 'Spelare läggs till',
    'dk': 'Spiller tilføjes',
  },
  'playerRemoved<future>': {
    'en': 'Player is removed',
    'no': 'Spiller fjernes',
    'se': 'Spelare tas bort',
    'dk': 'Spiller fjernes',
  },
  'contractExtended<future>': {
    'en': 'Contract extends',
    'no': 'Kontrakt forlenges',
    'se': 'Kontrakt förlängs',
    'dk': 'Kontrakt forlænges',
  },
  'contractExpired<future>': {
    'en': 'Contract expires',
    'no': 'Kontrakt utløper',
    'se': 'Kontrakt går ut',
    'dk': 'Kontrakt udløber',
  },
  'positionChanged<future>': {
    'en': 'Position changes',
    'no': 'Posisjon endres',
    'se': 'Position ändras',
    'dk': 'Position ændres',
  },
  'roleChanged<future>': {
    'en': 'Role changes',
    'no': 'Rolle endres',
    'se': 'Roll ändras',
    'dk': 'Rolle ændres',
  },
  'localityChanged<future>': {
    'en': 'Locality changes',
    'no': 'Lokalitet endres',
    'se': 'Lokalitet ändras',
    'dk': 'Lokalitet ændres',
  },
  'playerAdded<imperative>': {
    'en': 'Add player',
    'no': 'Legg til spiller',
    'se': 'Lägg till spelare',
    'dk': 'Tilføj spiller',
  },
  'playerRemoved<imperative>': {
    'en': 'Remove player',
    'no': 'Fjern spiller',
    'se': 'Ta bort spelare',
    'dk': 'Fjern spiller',
  },
  'contractExtended<imperative>': {
    'en': 'Extend contract',
    'no': 'Forleng kontrakt',
    'se': 'Förläng kontrakt',
    'dk': 'Forlæng kontrakt',
  },
  'positionChanged<imperative>': {
    'en': 'Change position',
    'no': 'Endre posisjon',
    'se': 'Ändra position',
    'dk': 'Ændre position',
  },
  'roleChanged<imperative>': {
    'en': 'Change role',
    'no': 'Endre rolle',
    'se': 'Ändra roll',
    'dk': 'Ændre rolle',
  },
  'localityChanged<imperative>': {
    'en': 'Change locality',
    'no': 'Endre lokalitet',
    'se': 'Ändra lokalitet',
    'dk': 'Ændre lokalitet',
  },
  'planner': {
    'en': 'Planner',
    'no': 'Planlegger',
    'se': 'Planerare',
    'dk': 'Planlægger',
  },
  'status': {
    'en': 'Status',
    'no': 'Status',
    'se': 'Status',
    'dk': 'Status',
  },
  'goToPlayerProfile': {
    'en': 'Go to player profile',
    'no': 'Gå til spillerprofil',
    'se': 'Gå till spelarprofil',
    'dk': 'Gå til spillerprofil',
  },
  'noActions': {
    'en': 'No actions',
    'no': 'Ingen handlinger',
    'se': 'Inga åtgärder',
    'dk': 'Ingen handlinger',
  },
  'previousPosition': {
    'en': 'Previous position',
    'no': 'Forrige posisjon',
    'se': 'Föregående position',
    'dk': 'Forrige position',
  },
  'newPosition': {
    'en': 'New position',
    'no': 'Ny posisjon',
    'se': 'Ny position',
    'dk': 'Ny position',
  },
  'previousRole': {
    'en': 'Previous role',
    'no': 'Forrige rolle',
    'se': 'Föregående roll',
    'dk': 'Forrige rolle',
  },
  'newRole': {
    'en': 'New role',
    'no': 'Ny rolle',
    'se': 'Ny roll',
    'dk': 'Ny rolle',
  },
  'previousLocality': {
    'en': 'Previous locality',
    'no': 'Forrige lokalitet',
    'se': 'Föregående lokalitet',
    'dk': 'Forrige lokalitet',
  },
  'newLocality': {
    'en': 'New locality',
    'no': 'Ny lokalitet',
    'se': 'Ny lokalitet',
    'dk': 'Ny lokalitet',
  },
  'resetPlanner': {
    'en': 'Reset planner',
    'no': 'Nullstill planlegger',
    'se': 'Återställ planeraren',
    'dk': 'Nulstil planlægger',
  },
  'resetPlannerInfo': {
    'en': 'All actions in the planner will be removed',
    'no': 'Alle handlinger i planleggeren vil bli fjernet',
    'se': 'Alla åtgärder i planeraren kommer att tas bort',
    'dk': 'Alle handlinger i planlæggeren vil blive fjernet',
  },
  'numberOfPlayers': {
    'en': 'Number of players',
    'no': 'Antall spillere',
    'se': 'Antal spelare',
    'dk': 'Antal spillere',
  },
  'extendContract': {
    'en': 'Extend contract',
    'no': 'Forleng kontrakt',
    'se': 'Förläng kontrakt',
    'dk': 'Forlæng kontrakt',
  },
  'deleteAction': {
    'en': 'Delete action',
    'no': 'Slett handling',
    'se': 'Radera åtgärd',
    'dk': 'Slet handling',
  },
  'editAction': {
    'en': 'Edit action',
    'no': 'Endre handling',
    'se': 'Redigera åtgärd',
    'dk': 'Rediger handling',
  },
  'numberOfActionsOutsideTheTimeline': {
    'en': 'Number of actions outside the timeline',
    'no': 'Antall handlinger utenfor tidslinjen',
    'se': 'Antal åtgärder utanför tidslinjen',
    'dk': 'Antal handlinger uden for tidslinjen',
  },
  'thePlayerIsAlreadyInTeamAtSelectedDate': {
    'en': 'The player is already in the team at the selected date',
    'no': 'Spilleren er allerede på laget på valgt dato',
    'se': 'Spelaren är redan i laget på valt datum',
    'dk': 'Spilleren er allerede på holdet på valgt dato',
  },
  'thePlayerIsNotInTeamAtSelectedDate': {
    'en': 'The player is not in the team at the selected date',
    'no': 'Spilleren er ikke i laget på valgt dato',
    'se': 'Spelaren är inte i laget på valt datum',
    'dk': 'Spilleren er ikke på holdet på valgt dato',
  },
  'deleteActionPlannerInfo': {
    'en': 'If you delete this action, all subsequent actions that are no longer valid will also be deleted',
    'no': 'Hvis du sletter denne handlingen, vil alle påfølgende handlinger som ikke lenger er gyldige også bli slettet',
    'se': 'Om du raderar denna åtgärd kommer alla efterföljande åtgärder som inte längre är giltiga också att raderas',
    'dk': 'Hvis du sletter denne handling, vil alle efterfølgende handlinger, der ikke længere er gyldige, også blive slettet',
  },
  'editActionPlannerInfo': {
    'en': 'If you confirm this edit, all subsequent actions that are no longer valid will be deleted',
    'no': 'Hvis du bekrefter denne endringen, vil alle påfølgende handlinger som ikke lenger er gyldige bli slettet',
    'se': 'Om du bekräftar denna redigering kommer alla efterföljande åtgärder som inte längre är giltiga att raderas',
    'dk': 'Hvis du bekræfter denne redigering, vil alle efterfølgende handlinger, der ikke længere er gyldige, blive slettet',
  },
  'addActionPlannerInfo': {
    'en': 'If you perform this action, all subsequent actions that are no longer valid will be deleted',
    'no': 'Hvis du utfører denne handlingen, vil alle påfølgende handlinger som ikke lenger er gyldige bli slettet',
    'se': 'Om du utför denna åtgärd kommer alla efterföljande åtgärder som inte längre är giltiga att raderas',
    'dk': 'Hvis du udfører denne handling, vil alle efterfølgende handlinger, der ikke længere er gyldige, blive slettet',
  },
  'thePlayerAlreadyHasTheSelectedPositionAtSelectedDate': {
    'en': 'The player already has the position you selected at the selected date',
    'no': 'Spilleren har allerede posisjonen du valgte på valgt dato',
    'se': 'Spelaren har redan positionen du valde på valt datum',
    'dk': 'Spilleren har allerede positionen, du valgte på valgt dato',
  },
  'thePlayerAlreadyHasTheSelectedRoleAtSelectedDate': {
    'en': 'The player already has the role you selected at the selected date',
    'no': 'Spilleren har allerede rollen du valgte på valgt dato',
    'se': 'Spelaren har redan rollen du valde på valt datum',
    'dk': 'Spilleren har allerede rollen, du valgte på valgt dato',
  },
  'thePlayerAlreadyHasTheSelectedLocalityAtSelectedDate': {
    'en': 'The player already has the locality you selected at the selected date',
    'no': 'Spilleren har allerede lokaliteten du valgte på valgt dato',
    'se': 'Spelaren har redan lokaliteten du valde på valt datum',
    'dk': 'Spilleren har allerede lokaliteten, du valgte på valgt dato',
  },
  'aPlayerCanNotHaveMultipleActionsOfTheSameTypeOnTheSameDate': {
    'en': 'A player can not have multiple actions of the same type on the same date',
    'no': 'En spiller kan ikke ha flere handlinger av samme type på samme dato',
    'se': 'En spelare kan inte ha flera åtgärder av samma typ på samma datum',
    'dk': 'En spiller kan ikke have flere handlinger af samme type på samme dato',
  },
  'selectActionDate': {
    'en': 'Select action date',
    'no': 'Velg handlingsdato',
    'se': 'Välj åtgärdsdatum',
    'dk': 'Vælg handlingsdato',
  },
  'selectExpirationDate': {
    'en': 'Select expiration date',
    'no': 'Velg utløpsdato',
    'se': 'Välj utgångsdatum',
    'dk': 'Vælg udløbsdato',
  },
  'selectNewExpirationDate': {
    'en': 'Select new expiration date',
    'no': 'Velg ny utløpsdato',
    'se': 'Välj ny utgångsdatum',
    'dk': 'Vælg ny udløbsdato',
  },
  'selectTeamRole': {
    'en': 'Select team role',
    'no': 'Velg lagrolle',
    'se': 'Välj lagroll',
    'dk': 'Vælg holdrolle',
  },
  'selectLocality': {
    'en': 'Select locality',
    'no': 'Velg lokalitet',
    'se': 'Välj lokalitet',
    'dk': 'Vælg lokalitet',
  },
  'fullscreen': {
    'en': 'Fullscreen',
    'no': 'Fullskjerm',
    'se': 'Helskärm',
    'dk': 'Fuldskærm',
  },
  'numberOfPlayersWhoseContractsExpireThisYear': {
    'en': 'Number of players whose contracts expire this year',
    'no': 'Antall spillere som har kontrakt som utløper dette året',
    'se': 'Antal spelare vars kontrakt går ut detta år',
    'dk': 'Antal spillere, hvis kontrakt udløber i år',
  },
  'fromSelectedActionDate': {
    'en': 'From selected action date',
    'no': 'Fra valgt handlingsdato',
    'se': 'Från valt åtgärdsdatum',
    'dk': 'Fra valgt handlingsdato',
  },
  'fromPreviousExpirationDate': {
    'en': 'From previous expiration date',
    'no': 'Fra forrige utløpsdato',
    'se': 'Från föregående utgångsdatum',
    'dk': 'Fra forrige udløbsdato',
  },
  'fromCurrentExpirationDate': {
    'en': 'From current expiration date',
    'no': 'Fra nåværende utløpsdato',
    'se': 'Från nuvarande utgångsdatum',
    'dk': 'Fra nuværende udløbsdato',
  },


  // competitions
  'tier': {
    'en': 'Tier',
    'no': 'Nivå',
    'se': 'Nivå',
    'dk': 'Niveau',
  },
  'tablePosition': {
    'en': 'Table position',
    'no': 'Tabellplassering',
    'se': 'Tabellposition',
    'dk': 'Tabelplacering',
  },
  'league': {
    'en': 'League',
    'no': 'Liga',
    'se': 'Liga',
    'dk': 'Liga',
  },
  'leagues': {
    'en': 'Leagues',
    'no': 'Ligaer',
    'se': 'Ligor',
    'dk': 'Ligaer',
  },
  'allLeagues': {
    'en': 'All leagues',
    'no': 'Alle ligaer',
    'se': 'Alla ligor',
    'dk': 'Alle ligaer',
  },
  'cups': {
    'en': 'Cups',
    'no': 'Cuper',
    'se': 'Cuper',
    'dk': 'Cups',
  },
  'season': {
    'en': 'Season',
    'no': 'Sesong',
    'se': 'Säsong',
    'dk': 'Sæson',
  },
  'seasons': {
    'en': 'Seasons',
    'no': 'Sesonger',
    'se': 'Säsonger',
    'dk': 'Sæsoner',
  },
  'allSeasons': {
    'en': 'All seasons',
    'no': 'Alle sesonger',
    'se': 'Alla säsonger',
    'dk': 'Alle sæsoner',
  },
  'someSeasons': {
    'en': 'Selected seasons',
    'no': 'Utvalgte sesonger',
    'se': 'Valda säsonger',
    'dk': 'Valgte sæsoner',
  },
  'selectSeason': {
    'en': 'Select season',
    'no': 'Velg sesong',
    'se': 'Välj säsong',
    'dk': 'Vælg sæson',
  },
  'noSeasonSelected': {
    'en': 'No season selected',
    'no': 'Ingen sesong valgt',
    'se': 'Ingen säsong vald',
    'dk': 'Ingen sæson valgt',
  },
  'allCompetitions': {
    'en': 'All competitions',
    'no': 'Alle konkurranser',
    'se': 'Alla tävlingar',
    'dk': 'Alle konkurrencer',
  },
  'someCompetitions': {
    'en': 'Selected competitions',
    'no': 'Utvalgte konkurranser',
    'se': 'Valda tävlingar',
    'dk': 'Valgte konkurrencer',
  },
  'leaguesAndCupsWithEventData': {
    'en': 'Leagues and cups with event data',
    'no': 'Ligaer og cuper med event data',
    'se': 'Ligor och cuper med event data',
    'dk': 'Ligaer og cups med event data',
  },
  'yourLeaguesAndCups': {
    'en': 'Your leagues and cups',
    'no': 'Dine ligaer og cuper',
    'se': 'Dina ligor och cuper',
    'dk': 'Dine ligaer og cups',
  },
  'allLeaguesAndCups': {
    'en': 'All leagues and cups',
    'no': 'Alle ligaer og cuper',
    'se': 'Alla ligor och cuper',
    'dk': 'Alle ligaer og cups',
  },
  'showOnlyDomesticLeagues': {
    'en': 'Show only domestic leagues',
    'no': 'Vis kun hjemlige ligaer',
    'se': 'Visa bara inhemska ligor',
    'dk': 'Vis kun hjemlige ligaer',
  },
  'showOnlySeasonsWith180Minutes': {
    'en': 'Show only seasons with at least 180 minutes',
    'no': 'Vis kun sesonger med minst 180 minutter',
    'se': 'Visa bara säsonger med minst 180 minuter',
    'dk': 'Vis kun sæsoner med mindst 180 minutter',
  },
  '(allEuropeanQualifyingMatches)': {
    'en': '(qualifiers for all European cups)',
    'no': '(kvalifiseringskamper til alle e-cuper)',
    'se': '(kvalificeringsmatcher för alla e-cuper)',
    'dk': '(kvalifikationskampe til alle e-cups)',
  },
  '(fromAndIncludingRound3)': {
    'en': '(from round 3 and onwards)',
    'no': '(fra og med runde 3)',
    'se': '(från och med omgång 3)',
    'dk': '(fra og med runde 3)',
  },
  '(groupStageAndKnockoutStage)': {
    'en': '(group stage & knockout stage)',
    'no': '(gruppespill & sluttspill)',
    'se': '(gruppspel & slutspel)',
    'dk': '(gruppespil & knockout)',
  },


  // accounts/access
  'access': {
    'en': 'Access',
    'no': 'Tilgang',
    'se': 'Tillgång',
    'dk': 'Adgang',
  },
  'editAccess': {
    'en': 'Edit access',
    'no': 'Endre tilgang',
    'se': 'Ändra tillgång',
    'dk': 'Rediger adgang',
  },
  'editAccessFor': {
    'en': 'Edit access for',
    'no': 'Endre tilgangen til',
    'se': 'Ändra tillgången för',
    'dk': 'Rediger adgang for',
  },
  'confirmNewAccess': {
    'en': 'Confirm new access',
    'no': 'Bekreft ny tilgang',
    'se': 'Bekräfta ny tillgång',
    'dk': 'Bekræft ny adgang',
  },
  'noAccessInfo': {
    'en': 'You do not have access to the platform',
    'no': 'Du har ikke lenger tilgang til plattformen',
    'se': 'Du har inte längre tillgång till plattformen',
    'dk': 'Du har ikke længere adgang til platformen',
  },
  'account': {
    'en': 'Account',
    'no': 'Konto',
    'se': 'Konto',
    'dk': 'Konto',
  },
  'accounts': {
    'en': 'Accounts',
    'no': 'Kontoer',
    'se': 'Konton',
    'dk': 'Konti',
  },
  'addNewAccount': {
    'en': 'Add new account',
    'no': 'Legg til ny konto',
    'se': 'Lägg till ny konto',
    'dk': 'Tilføj ny konto',
  },
  'createAccount': {
    'en': 'Create account',
    'no': 'Opprett konto',
    'se': 'Skapa konto',
    'dk': 'Opret konto',
  },
  'accountCreated': {
    'en': 'Account was successfully created',
    'no': 'Kontoen ble opprettet',
    'se': 'Kontot skapades',
    'dk': 'Kontoen blev oprettet',
  },
  'accountCreatedInfo': {
    'en': 'We have sent an email to the address below with instructions on how the user can set their own password and activate the account.',
    'no': 'Vi har sendt en e-post til e-postadressen under med instrukser om hvordan brukeren kan sette sitt eget passord og aktivere kontoen.',
    'se': 'Vi har skickat ett e-postmeddelande till adressen nedan med instruktioner om hur användaren kan ställa in sitt eget lösenord och aktivera kontot.',
    'dk': 'Vi har sendt en e-mail til adressen nedenfor med instruktioner om, hvordan brugeren kan sætte sit eget password og aktivere kontoen.',
  },
  'youWillNotBeAbleToDeleteAccount': {
    'en': 'You will not be able to delete the account yourself',
    'no': 'Du vil ikke kunne slette kontoen selv',
    'se': 'Du kommer inte att kunna radera kontot själv',
    'dk': 'Du vil ikke kunne slette kontoen selv',
  },
  'adminAccess': {
    'en': 'Admin',
    'no': 'Admin',
    'se': 'Admin',
    'dk': 'Admin',
  },
  'fullAccess': {
    'en': 'Full access',
    'no': 'Full tilgang',
    'se': 'Full tillgång',
    'dk': 'Fuld adgang',
  },
  'economyAccess': {
    'en': 'Economy access',
    'no': 'Økonomitilgang',
    'se': 'Ekonomitillgång',
    'dk': 'Økonomi adgang',
  },
  'scoutAccess': {
    'en': 'Scout access',
    'no': 'Speidertilgang',
    'se': 'Scouttillgång',
    'dk': 'Spejder adgang',
  },
  'noneAccess': {
    'en': 'No access',
    'no': 'Ingen tilgang',
    'se': 'Ingen tillgång',
    'dk': 'Ingen adgang',
  },
  'adminAccessInfo': {
    'en': 'Admin gives access to the entire platform.',
    'no': 'Admin gir tilgang til hele plattformen.',
    'se': 'Admin ger tillgång till hela plattformen.',
    'dk': 'Admin giver adgang til hele platformen.',
  },
  'fullAccessInfo': {
    'en': 'Full access gives access to the entire platform, except for adding new users or changing the access of existing users',
    'no': 'Full tilgang gir tilgang til hele plattformen, med unntak av å legge til nye brukere eller endre tilgangen til eksisterende brukere.',
    'se': 'Full tillgång ger tillgång till hela plattformen, med undantag för att lägga till nya användare eller ändra tillgången för befintliga användare.',
    'dk': 'Fuld adgang giver adgang til hele platformen, undtagen for at tilføje nye brugere eller ændre adgangen for eksisterende brugere.',
  },
  'economyAccessInfo': {
    'en': 'Users with economy access will have access to the Home, Own team, Clauses and Settings tabs, but they will not be able to see the activity log on the Home screen, add or remove players from Own team, add or edit roles, or manage other users.',
    'no': 'Brukere med økonomitilgang vil ha tilgang til fanene Hjem, Eget lag, Klausuler og Innstillinger, men de vil ikke kunne se aktivitetsloggen på Hjem-skjermen, legge til eller fjerne spillere på Eget lag, legge til eller endre roller, eller administrere andre brukere.',
    'se': 'Användare med ekonomitillgång kommer att ha tillgång till flikarna Hem, Eget lag, Klausuler och Inställningar, men de kommer inte att kunna se aktivitetsloggen på Hem-skärmen, lägga till eller ta bort spelare från Eget lag, lägga till eller redigera roller, eller hantera andra användare.',
    'dk': 'Brugere med økonomi adgang vil have adgang til fanerne Hjem, Eget hold, Klausuler og Indstillinger, men de vil ikke kunne se aktivitetsloggen på Hjem-skærmen, tilføje eller fjerne spillere fra Eget hold, tilføje eller redigere roller, eller administrere andre brugere.',
  },
  'scoutAccessInfo': {
    'en': 'Users with scout access will not have access to Planner and Clauses. They can access Own team, but they cannot see the protected columns in the table and the matrix. They will also not have the ability to change the club\'s formation, add or remove players from Own team, add or edit roles, or manage other users.',
    'no': 'Brukere med speidertilgang vil ikke ha tilgang til Planlegger og Klausuler. De kan gå inn på Eget lag, men de kan ikke se de beskyttede kolonnene i tabellen og matrisen. De vil heller ikke ha muligheten til å endre klubbens formasjon, legge til eller fjerne spillere på Eget lag, legge til eller endre roller, eller administrere andre brukere.',
    'se': 'Användare med scouttillgång kommer inte att se Planerare och Klausuler. De kommer att ha tillgång till Eget lag, men de kan inte se de skyddade kolumnerna i tabellen och matrisen. De kommer heller inte att ha möjlighet att ändra klubbens formation, lägga till eller ta bort spelare från Eget lag, lägga till eller redigera roller, eller hantera andra användare.',
    'dk': 'Brugere med spejder adgang vil ikke se Planlægger og Klausuler. De vil have mulighed for at se Eget hold, men vil ikke være i stand til at se de beskyttede kolonner i tabellen og matricen. De vil heller ikke have mulighed for at ændre klubbens formation, tilføje eller fjerne spillere fra Eget hold, tilføje eller redigere roller, eller administrere andre brugere.',
  },
  'noneAccessInfo': {
    'en': 'Users without access will not have access to any part of the platform.',
    'no': 'Brukere uten tilgang vil ikke ha tilgang til noe av plattformen.',
    'se': 'Användare utan tillgång kommer inte att ha tillgång till någon del av plattformen.',
    'dk': 'Brugere uden adgang vil ikke have adgang til nogen del af platformen.',
  },


  // reports
  'report': {
    'en': 'Report',
    'no': 'Rapport',
    'se': 'Rapport',
    'dk': 'Rapport',
  },
  'reports': {
    'en': 'Reports',
    'no': 'Rapporter',
    'se': 'Rapporter',
    'dk': 'Rapporter',
  },
  'allReports': {
    'en': 'All reports',
    'no': 'Alle rapporter',
    'se': 'Alla rapporter',
    'dk': 'Alle rapporter',
  },
  'aPreviousVersionFrom': {
    'en': 'A previous version from ',
    'no': 'En tidligere versjon fra ',
    'se': 'En tidigare version från ',
    'dk': 'En tidligere version fra ',
  },
  'averageMatchReports': {
    'en': 'The gray ratings are the average of your match reports',
    'no': 'De grå vurderingene er snittet av dine kamprapporter',
    'se': 'De grå betygen är genomsnittet av dina matchrapporter',
    'dk': 'De grå vurderinger er gennemsnittet af dine kamp rapporter',
  },
  'averageRating': {
    'en': 'Average of all scout reports',
    'no': 'Gjennomsnittet av alle speiderrapporter',
    'se': 'Genomsnittet av alla scoutrapporter',
    'dk': 'Gennemsnittet af alle scout rapporter',
  },
  'newAttachment': {
    'en': 'New attachment',
    'no': 'Nytt vedlegg',
    'se': 'Ny bilaga',
    'dk': 'Ny vedhæftning',
  },
  'newComment': {
    'en': 'New comment',
    'no': 'Ny kommentar',
    'se': 'Ny kommentar',
    'dk': 'Ny kommentar',
  },
  'deleteComment': {
    'en': 'Delete comment',
    'no': 'Slett kommentar',
    'se': 'Radera kommentar',
    'dk': 'Slet kommentar',
  },
  'newMatchReport': {
    'en': 'New match report',
    'no': 'Ny kamprapport',
    'se': 'Ny matchrapport',
    'dk': 'Ny kamp rapport',
  },
  'newScoutingReport': {
    'en': 'New scouting report',
    'no': 'Ny speiderapport',
    'se': 'Ny scoutrapport',
    'dk': 'Ny scout rapport',
  },
  'totalNumberOfReports': {
    'en': 'Total number of reports',
    'no': 'Totalt antall rapporter',
    'se': 'Totalt antal rapporter',
    'dk': 'Totalt antal rapporter',
  },
  'numberOfReportsLastMonth': {
    'en': 'Number of reports last month',
    'no': 'Antall rapporter siste måned',
    'se': 'Antal rapporter senaste månaden',
    'dk': 'Antal rapporter sidste måned',
  },
  'numberOfReportsPerMonth': {
    'en': 'Number of reports per month',
    'no': 'Antall rapporter per måned',
    'se': 'Antal rapporter per månad',
    'dk': 'Antal rapporter per måned',
  },
  'numberOfReportsThisYear': {
    'en': 'Number of reports this year',
    'no': 'Antall rapporter i år',
    'se': 'Antal rapporter i år',
    'dk': 'Antal rapporter i år',
  },
  'editHistory': {
    'en': 'Previous edits',
    'no': 'Redigeringshistorikk',
    'se': 'Tidigare redigeringar',
    'dk': 'Tidligere redigeringer',
  },
  'showEditHistory': {
    'en': 'Show previous edits',
    'no': 'Vis redigeringshistorikk',
    'se': 'Visa tidigare redigeringar',
    'dk': 'Vis tidligere redigeringer',
  },
  'existingReports': {
    'en': 'Existing reports',
    'no': 'Eksisterende rapporter',
    'se': 'Befintliga rapporter',
    'dk': 'Eksisterende rapporter',
  },
  'noExistingReports': {
    'en': 'No existing reports',
    'no': 'Ingen eksisterende rapporter',
    'se': 'Inga befintliga rapporter',
    'dk': 'Ingen eksisterende rapporter',
  },
  'showDeletedReports': {
    'en': 'Show deleted reports',
    'no': 'Vis slettede rapporter',
    'se': 'Visa borttagna rapporter',
    'dk': 'Vis slettede rapporter',
  },
  'showOnlyScoutReports': {
    'en': 'Show only scout reports',
    'no': 'Vis kun speiderapporter',
    'se': 'Visa bara scoutrapporter',
    'dk': 'Vis kun scout rapporter',
  },
  'ScoutingReport': {
    'en': 'Scouting report',
    'no': 'Speiderapport',
    'se': 'Scoutrapport',
    'dk': 'Scout rapport',
  },
  'MatchReport': {
    'en': 'Match report',
    'no': 'Kamprapport',
    'se': 'Matchrapport',
    'dk': 'Kamp rapport',
  },
  'Comment': {
    'en': 'Comment',
    'no': 'Kommentar',
    'se': 'Kommentar',
    'dk': 'Kommentar',
  },
  'Attachment': {
    'en': 'Attachment',
    'no': 'Vedlegg',
    'se': 'Bilaga',
    'dk': 'Vedhæftning',
  },
  'editScoutingReport': {
    'en': 'Edit report',
    'no': 'Rediger rapport',
    'se': 'Redigera rapport',
    'dk': 'Rediger rapport',
  },
  'editMatchReport': {
    'en': 'Edit report',
    'no': 'Rediger rapport',
    'se': 'Redigera rapport',
    'dk': 'Rediger rapport',
  },
  'editComment': {
    'en': 'Edit comment',
    'no': 'Rediger kommentar',
    'se': 'Redigera kommentar',
    'dk': 'Rediger kommentar',
  },
  'editAttachment': {
    'en': 'Edit attachment',
    'no': 'Rediger vedlegg',
    'se': 'Redigera bilaga',
    'dk': 'Rediger vedhæftning',
  },
  'exitReport?': {
    'en': 'Exit report?',
    'no': 'Forlate rapporten?',
    'se': 'Avsluta rapporten?',
    'dk': 'Forlad rapporten?',
  },
  'exitReportInfo': {
    'en': 'The report will not be saved',
    'no': 'Rapporten vil ikke bli lagret',
    'se': 'Rapporten kommer inte att sparas',
    'dk': 'Rapporten vil ikke blive gemt',
  },
  'exitComment?': {
    'en': 'Exit comment?',
    'no': 'Forlate kommentaren?',
    'se': 'Avsluta kommentaren?',
    'dk': 'Forlad kommentaren?',
  },
  'exitCommentInfo': {
    'en': 'The comment will not be saved',
    'no': 'Kommentaren vil ikke bli lagret',
    'se': 'Kommentaren kommer inte att sparas',
    'dk': 'Kommentaren vil ikke blive gemt',
  },
  'exitAttachment?': {
    'en': 'Exit attachment?',
    'no': 'Forlate vedlegget?',
    'se': 'Avsluta bilagan?',
    'dk': 'Forlad vedhæftningen?',
  },
  'exitAttachmentInfo': {
    'en': 'The attachment will not be saved',
    'no': 'Vedlegget vil ikke bli lagret',
    'se': 'Bilagan kommer inte att sparas',
    'dk': 'Vedhæftningen vil ikke blive gemt',
  },
  'submitScoutingReport': {
    'en': 'Submit scouting report',
    'no': 'Send inn speiderapport',
    'se': 'Skicka scoutrapport',
    'dk': 'Indsend scout rapport',
  },
  'submitMatchReport': {
    'en': 'Submit match report',
    'no': 'Send inn kamprapport',
    'se': 'Skicka matchrapport',
    'dk': 'Indsend kamp rapport',
  },
  'submitComment': {
    'en': 'Submit comment',
    'no': 'Send inn kommentar',
    'se': 'Skicka kommentar',
    'dk': 'Indsend kommentar',
  },
  'submitAttachment': {
    'en': 'Submit attachment',
    'no': 'Send inn vedlegg',
    'se': 'Skicka bilaga',
    'dk': 'Indsend vedhæftning',
  },
  'provideComment': {
    'en': 'Write a comment',
    'no': 'Skriv inn en kommentar',
    'se': 'Skriv en kommentar',
    'dk': 'Skriv en kommentar',
  },
  'provideComments': {
    'en': 'You may provide comments here',
    'no': 'Her kan du skrive inn kommentarer',
    'se': 'Här kan du skriva kommentarer',
    'dk': 'Her kan du skrive kommentarer',
  },
  'provideCommentOrInformation': {
    'en': 'You may provide a comment or other information about the player here',
    'no': 'Her kan du skrive inn en kommentar eller annen informasjon om spilleren',
    'se': 'Här kan du skriva en kommentar eller annan information om spelaren',
    'dk': 'Her kan du skrive en kommentar eller anden information om spilleren',
  },
  'noAttachments': {
    'en': 'No attachments',
    'no': 'Ingen vedlegg',
    'se': 'Inga bilagor',
    'dk': 'Ingen vedhæftninger',
  },
  'noCommentsProvided': {
    'en': 'No comments were provided',
    'no': 'Ingen kommentarer ble oppgitt',
    'se': 'Inga kommentarer angavs',
    'dk': 'Ingen kommentarer blev angivet',
  },


  // clauses
  'addAttachment': {
    'en': 'Add attachment',
    'no': 'Legg til vedlegg',
    'se': 'Lägg till bilaga',
    'dk': 'Tilføj vedhæftning',
  },
  'addClause': {
    'en': 'Add clause',
    'no': 'Legg til klausul',
    'se': 'Lägg till klausul',
    'dk': 'Tilføj klausul',
  },
  'submitClause': {
    'en': 'Submit clause',
    'no': 'Send inn klausul',
    'se': 'Skicka klausul',
    'dk': 'Indsend klausul',
  },
  'addClause?': {
    'en': 'Add clause?',
    'no': 'Legge til klausul?',
    'se': 'Lägga till klausul?',
    'dk': 'Tilføje klausul?',
  },
  'addNewClause': {
    'en': 'Add new sale clause',
    'no': 'Opprett ny klausul',
    'se': 'Skapa ny klausul',
    'dk': 'Opret ny klausul',
  },
  'addClauseForBoughtPlayerInfo': {
    'en': 'Do you want to add a clause for the bought player?',
    'no': 'Vil du legge til en klausul for den kjøpte spilleren?',
    'se': 'Vill du lägga till en klausul för den köpta spelaren?',
    'dk': 'Vil du tilføje en klausul for den købte spiller?',
  },
  'addClauseForSoldPlayerInfo': {
    'en': 'Do you want to add a clause for the sold player?',
    'no': 'Vil du legge til en klausul for den solgte spilleren?',
    'se': 'Vill du lägga till en klausul för den sålda spelaren?',
    'dk': 'Vil du tilføje en klausul for den solgte spiller?',
  },
  'addClausesForNextClub': {
    'en': 'Do you want to add further clauses for the next club?',
    'no': 'Vil du legge til videre klausuler for neste klubb?',
    'se': 'Vill du lägga till ytterligare klausuler för nästa klubb?',
    'dk': 'Vil du tilføje yderligere klausuler for næste klub?',
  },
  'addCondition': {
    'en': 'Add condition',
    'no': 'Legg til betingelse',
    'se': 'Lägg till villkor',
    'dk': 'Tilføj betingelse',
  },
  'addFurtherClauses': {
    'en': 'Add further clauses',
    'no': 'Legg til videre klausuler',
    'se': 'Lägg till ytterligare klausuler',
    'dk': 'Tilføj yderligere klausuler',
  },
  'addOrEditPayments': {
    'en': 'Add or edit payments',
    'no': 'Legg til eller endre betalinger',
    'se': 'Lägg till eller redigera betalningar',
    'dk': 'Tilføj eller rediger betalinger',
  },
  'addPayment': {
    'en': 'Add payment',
    'no': 'Legg til betaling',
    'se': 'Lägg till betalning',
    'dk': 'Tilføj betaling',
  },
  'addPayments': {
    'en': 'Add payments',
    'no': 'Legg til betalinger',
    'se': 'Lägg till betalningar',
    'dk': 'Tilføj betalinger',
  },
  'addPercentage': {
    'en': 'Add percentage',
    'no': 'Legg til prosent',
    'se': 'Lägg till procent',
    'dk': 'Tilføj procent',
  },
  'addSubClause': {
    'en': 'Add sub-clause',
    'no': 'Opprett underklausul',
    'se': 'Skapa underklausul',
    'dk': 'Opret underklausul',
  },
  'addUnconditionedPayment': {
    'en': 'Add unconditioned payment',
    'no': 'Legg til ubetinget betaling',
    'se': 'Lägg till obetingad betalning',
    'dk': 'Tilføj ubetinget betaling',
  },
  'addUnconditionedPayments': {
    'en': 'Add unconditioned payments',
    'no': 'Legg til ubetingede betalinger',
    'se': 'Lägg till obetingade betalningar',
    'dk': 'Tilføj ubetingede betalinger',
  },
  'currency': {
    'en': 'Currency',
    'no': 'Valuta',
    'se': 'Valuta',
    'dk': 'Valuta',
  },
  'clause': {
    'en': 'Clause',
    'no': 'Klausul',
    'se': 'Klausul',
    'dk': 'Klausul',
  },
  'clauses': {
    'en': 'Clauses',
    'no': 'Klausuler',
    'se': 'Klausuler',
    'dk': 'Klausuler',
  },
  'existingClauses': {
    'en': 'Existing clauses',
    'no': 'Eksisterende klausuler',
    'se': 'Befintliga klausuler',
    'dk': 'Eksisterende klausuler',
  },
  'noExistingClauses': {
    'en': 'No existing clauses',
    'no': 'Ingen eksisterende klausuler',
    'se': 'Inga befintliga klausuler',
    'dk': 'Ingen eksisterende klausuler',
  },
  'showResolvedClauses': {
    'en': 'Show resolved clauses',
    'no': 'Vis fullførte klausuler',
    'se': 'Visa lösta klausuler',
    'dk': 'Vis fuldførte klausuler',
  },
  'showDeletedClauses': {
    'en': 'Show deleted clauses',
    'no': 'Vis slettede klausuler',
    'se': 'Visa borttagna klausuler',
    'dk': 'Vis slettede klausuler',
  },
  'editClause': {
    'en': 'Edit clause',
    'no': 'Rediger klausul',
    'se': 'Ändra klausul',
    'dk': 'Rediger klausul',
  },
  'editSubClause': {
    'en': 'Edit sub-clause',
    'no': 'Endre underklausul',
    'se': 'Ändra underklausul',
    'dk': 'Rediger underklausul',
  },
  'deleteClause': {
    'en': 'Delete clause',
    'no': 'Slett klausul',
    'se': 'Radera klausul',
    'dk': 'Slet klausul',
  },
  'deleteCondition': {
    'en': 'Delete condition',
    'no': 'Slett betingelse',
    'se': 'Radera villkor',
    'dk': 'Slet betingelse',
  },
  'deletePayment': {
    'en': 'Delete payment',
    'no': 'Slett betaling',
    'se': 'Radera betalning',
    'dk': 'Slet betaling',
  },
  'deleteMaxAmount': {
    'en': 'Delete max amount',
    'no': 'Slett maksbeløp',
    'se': 'Radera maxbelopp',
    'dk': 'Slet maksbeløb',
  },
  'deleteSubClause': {
    'en': 'Delete sub-clause',
    'no': 'Slett underklausul',
    'se': 'Radera underklausul',
    'dk': 'Slet underklausul',
  },
  'commonConditions': {
    'en': 'Common conditions',
    'no': 'Felles betingelser',
    'se': 'Vanliga villkor',
    'dk': 'Fælles betingelser',
  },
  'condition': {
    'en': 'Condition',
    'no': 'Betingelse',
    'se': 'Villkor',
    'dk': 'Betingelse',
  },
  'conditions': {
    'en': 'Conditions',
    'no': 'Betingelser',
    'se': 'Villkor',
    'dk': 'Betingelser',
  },
  'seasonCondition': {
    'en': 'Season condition',
    'no': 'Sesongbetingelse',
    'se': 'Säsongsbetingelse',
    'dk': 'Sæsonbetingelse',
  },
  'standardSeasonCondition': {
    'en': 'Standard season condition',
    'no': 'Standard sesongbetingelse',
    'se': 'Standard säsongsbetingelse',
    'dk': 'Standard sæsonbetingelse',
  },
  'advancedSeasonCondition': {
    'en': 'Advanced season condition',
    'no': 'Avansert sesongbetingelse',
    'se': 'Avancerad säsongsbetingelse',
    'dk': 'Avanceret sæsonbetingelse',
  },
  'totalCondition': {
    'en': 'Total condition',
    'no': 'Total betingelse',
    'se': 'Totalt villkor',
    'dk': 'Total betingelse',
  },
  'unconditioned': {
    'en': 'Unconditioned',
    'no': 'Ubetinget',
    'se': 'Obetingad',
    'dk': 'Ubetinget',
  },
  'subConditions': {
    'en': 'Sub conditions',
    'no': 'Delbetingelser',
    'se': 'Delvillkor',
    'dk': 'Underbetingelser',
  },
  'editCondition': {
    'en': 'Edit condition',
    'no': 'Rediger betingelse',
    'se': 'Ändra villkor',
    'dk': 'Rediger betingelse',
  },
  'editConditionStatus': {
    'en': 'Edit condition status',
    'no': 'Endre betingelsesstatus',
    'se': 'Ändra villkorsstatus',
    'dk': 'Rediger betingelsesstatus',
  },
  'selectCondition': {
    'en': 'Select condition',
    'no': 'Velg betingelse',
    'se': 'Välj villkor',
    'dk': 'Vælg betingelse',
  },
  'selectNumberOfSeasonConditionAmounts': {
    'en': 'Select the number of unique amounts in the season condition',
    'no': 'Velg antall ulike beløp som inngår i sesongbetingelsen',
    'se': 'Välj antalet unika belopp i säsongsbetingelsen',
    'dk': 'Vælg antallet af unikke beløb i sæsonbetingelsen',
  },
  'clauseWillNotBeSaved': {
    'en': 'The clause will not be saved',
    'no': 'Klausulen vil ikke bli lagret',
    'se': 'Klausulen kommer inte att sparas',
    'dk': 'Klausulen vil ikke blive gemt',
  },
  'conditionWillNotBeSaved': {
    'en': 'The condition will not be saved',
    'no': 'Betingelsen vil ikke bli lagret',
    'se': 'Villkoret kommer inte att sparas',
    'dk': 'Betingelsen vil ikke blive gemt',
  },
  'youMustProvideCustomCondition': {
    'en': 'You must provide a custom condition',
    'no': 'Du må oppgi en betingelse',
    'se': 'Du måste ange en anpassad betingelse',
    'dk': 'Du skal angive en brugerdefineret betingelse',
  },
  'youMustProvideEitherStaticOrDynamicSellOnPercentage': {
    'en': 'You must provide either a static or dynamic sell-on percentage',
    'no': 'Du må oppgi enten fast eller variabel prosent av videresalg',
    'se': 'Du måste ange antingen en statisk eller dynamisk videresäljningsprocent',
    'dk': 'Du skal angive enten en fast eller dynamisk videresalgsprocent',
  },
  'youMustProvideSubConditions': {
    'en': 'You must provide at least one condition',
    'no': 'Du må oppgi minst én betingelse',
    'se': 'Du måste ange minst en betingelse',
    'dk': 'Du skal angive mindst en betingelse',
  },
  'youMustProvideSubConditionsForEachAmount': {
    'en': 'Each amount must have at least one associated condition',
    'no': 'Hvert beløp må ha minst én tilhørende betingelse',
    'se': 'Varje belopp måste ha minst en associerad betingelse',
    'dk': 'Hvert beløb skal have mindst en tilknyttet betingelse',
  },
  'youMustProvideTablePosition': {
    'en': 'You must provide a minimum table position',
    'no': 'Du må oppgi en minimum tabellplassering',
    'se': 'Du måste ange en minsta tabellposition',
    'dk': 'Du skal angive en minimum tabelposition',
  },
  'threshold': {
    'en': 'Threshold',
    'no': 'Antall',
    'se': 'Tröskelvärde',
    'dk': 'Antal',
  },
  'thresholdAlreadyExists': {
    'en': 'An entry with this threshold already exists',
    'no': 'Det finnes allerede en betingelse med samme antall',
    'se': 'Det finns redan en post med detta tröskelvärde',
    'dk': 'Der findes allerede en post med samme antal',
  },
  'youMustProvideThreshold': {
    'en': 'You must provide a threshold',
    'no': 'Du må oppgi et antall',
    'se': 'Du måste ange ett tröskelvärde',
    'dk': 'Du skal angive et antal',
  },
  'youMustProvideThresholdAndAmount': {
    'en': 'You must provide both threshold and amount',
    'no': 'Du må oppgi både antall og beløp',
    'se': 'Du måste ange både tröskelvärde och belopp',
    'dk': 'Du skal angive både antal og beløb',
  },
  'youMustProvideThresholdOrPercentage': {
    'en': 'You must provide an amount or percentage',
    'no': 'Du må oppgi et antall eller en prosent',
    'se': 'Du måste ange ett belopp eller en procent',
    'dk': 'Du skal angive et antal eller en procent',
  },
  'youMustSelectCondition': {
    'en': 'You must select a condition',
    'no': 'Du må velge en betingelse',
    'se': 'Du måste välja en betingelse',
    'dk': 'Du skal vælge en betingelse',
  },
  'soldPlayers': {
    'en': 'Sold players',
    'no': 'Solgte spillere',
    'se': 'Sålda spelare',
    'dk': 'Solgte spillere',
  },
  'newClauseForSoldPlayer': {
    'en': 'New clause for sold player',
    'no': 'Ny klausul for solgt spiller',
    'se': 'Ny klausul för såld spelare',
    'dk': 'Ny klausul for solgt spiller',
  },
  'soldFrom': {
    'en': 'Sold from',
    'no': 'Solgt fra',
    'se': 'Såld från',
    'dk': 'Solgt fra',
  },
  'soldTo': {
    'en': 'Sold to',
    'no': 'Solgt til',
    'se': 'Såld till',
    'dk': 'Solgt til',
  },
  'boughtPlayers': {
    'en': 'Bought players',
    'no': 'Kjøpte spillere',
    'se': 'Köpta spelare',
    'dk': 'Købte spillere',
  },
  'newClauseForBoughtPlayer': {
    'en': 'New clause for bought player',
    'no': 'Ny klausul for kjøpt spiller',
    'se': 'Ny klausul för köpt spelare',
    'dk': 'Ny klausul for købt spiller',
  },
  'boughtFrom': {
    'en': 'Bought from',
    'no': 'Kjøpt fra',
    'se': 'Köpt från',
    'dk': 'Købt fra',
  },
  'transferDate': {
    'en': 'Transfer date',
    'no': 'Overgangsdato',
    'se': 'Övergångsdatum',
    'dk': 'Overgangsdato',
  },
  'transferFee': {
    'en': 'Transfer fee',
    'no': 'Overgangssum',
    'se': 'Övergångssumma',
    'dk': 'Overgangssum',
  },
  'totalTransferFee': {
    'en': 'Total transfer fee',
    'no': 'Total overgangssum',
    'se': 'Total övergångssumma',
    'dk': 'Total overgangssum',
  },
  'newTransferFee': {
    'en': 'New transfer fee',
    'no': 'Ny overgangssum',
    'se': 'Ny övergångssumma',
    'dk': 'Ny overgangssum',
  },
  'yourTransferFee': {
    'en': 'Your transfer fee',
    'no': 'Deres overgangssum',
    'se': 'Din övergångssumma',
    'dk': 'Din overgangssum',
  },
  '(noTransferRegistered)': {
    'en': '(no transfer registered)',
    'no': '(ingen overgang registrert)',
    'se': '(ingen övergång registrerad)',
    'dk': '(ingen overgang registreret)',
  },
  'transferRegisteredInfo': {
    'en': 'We have registered a transfer for the player and the conditions will no longer be updated',
    'no': 'Vi har registrert en overgang for spilleren og betingelsene vil ikke lenger oppdateres',
    'se': 'Vi har registrerat en övergång för spelaren och villkoren kommer inte längre att uppdateras',
    'dk': 'Vi har registreret en overgang for spilleren og betingelserne vil ikke længere blive opdateret',
  },
  'yourPercentageOfAmounts': {
    'en': 'Your percentage of the amounts',
    'no': 'Deres prosentandel av beløpene',
    'se': 'Din procentandel av beloppen',
    'dk': 'Din procentdel af beløbene',
  },
  'provideConditionStatus': {
    'en': 'Provide condition status',
    'no': 'Oppgi betingelsesstatus',
    'se': 'Ange villkorsstatus',
    'dk': 'Angiv betingelsesstatus',
  },
  'provideCustomCondition': {
    'en': 'Provide custom condition',
    'no': 'Oppgi annen betingelse',
    'se': 'Ange anpassad betingelse',
    'dk': 'Angiv brugerdefineret betingelse',
  },
  'resolveClause': {
    'en': 'Resolve clause',
    'no': 'Fullfør klausul',
    'se': 'Lös villkor',
    'dk': 'Fuldfør klausul',
  },
  'restoreClause': {
    'en': 'Restore clause',
    'no': 'Gjenopprett klausul',
    'se': 'Återställ villkor',
    'dk': 'Gendan klausul',
  },
  'resolveCondition': {
    'en': 'Resolve condition',
    'no': 'Løs ut betingelse',
    'se': 'Lös villkor',
    'dk': 'Løs betingelse',
  },
  'resolveOrDiscardCondition': {
    'en': 'Resolve or discard condition',
    'no': 'Løs ut eller forkast betingelse',
    'se': 'Lös ut eller kassera villkor',
    'dk': 'Løs eller forkast betingelse',
  },
  'resolveOrReactivateCondition': {
    'en': 'Resolve or reactivate condition',
    'no': 'Løs ut eller reaktiver betingelse',
    'se': 'Lös ut eller återaktivera villkor',
    'dk': 'Løs eller reaktiver betingelse',
  },
  'reactivateClause': {
    'en': 'Reactivate clause',
    'no': 'Reaktiver klausul',
    'se': 'Återaktivera villkor',
    'dk': 'Genaktiver klausul',
  },
  'reactivateCondition': {
    'en': 'Reactivate condition',
    'no': 'Reaktiver betingelse',
    'se': 'Återaktivera villkor',
    'dk': 'Genaktiver betingelse',
  },
  'reactivateConditionInfo': {
    'en': 'The condition will be reactivated and the associated payment will be deleted',
    'no': 'Betingelsen vil bli reaktivert og den tilhørende betalingen vil bli slettet',
    'se': 'Villkoret kommer att återaktiveras och den associerade betalningen kommer att raderas',
    'dk': 'Betingelsen vil blive genaktiveret og den tilknyttede betaling vil blive slettet',
  },
  'reactivateOrDiscardCondition': {
    'en': 'Reactivate or discard condition',
    'no': 'Reaktiver eller forkast betingelse',
    'se': 'Återaktivera eller kassera villkor',
    'dk': 'Genaktiver eller forkast betingelse',
  },
  'removeClub': {
    'en': 'Remove club',
    'no': 'Fjern klubb',
    'se': 'Ta bort klubb',
    'dk': 'Fjern klub',
  },
  'incorrectValue?': {
    'en': 'Incorrect value?',
    'no': 'Feil verdi?',
    'se': 'Fel värde?',
    'dk': 'Forkert værdi?',
  },
  'incorrectTransfer?': {
    'en': 'Incorrect transfer?',
    'no': 'Feil overgang?',
    'se': 'Fel övergång?',
    'dk': 'Forkert overgang?',
  },
  'markedAsIncorrectTransfer': {
    'en': 'Marked as incorrect transfer',
    'no': 'Markert som feil overgang',
    'se': 'Markerad som fel övergång',
    'dk': 'Markeret som forkert overgang',
  },
  'registeredTransfersMarkedAsIncorrect': {
    'en': 'Registered transfers marked as incorrect',
    'no': 'Registrerte overganger som er markert som feil',
    'se': 'Registrerade övergångar som är markerade som fel',
    'dk': 'Registrerede overgange, der er markeret som forkerte',
  },
  'regretIncorrectTransfer?': {
    'en': 'Correct transfer?',
    'no': 'Korrekt overgang?',
    'se': 'Korrekt övergång?',
    'dk': 'Korrekt overgang?',
  },
  'suggestedStatusInfo': {
    'en': 'Based on the data, this condition is',
    'no': 'Ifølge dataen er denne betingelsen',
    'se': 'Baserat på datan är detta villkoret',
    'dk': 'Baseret på dataen er denne betingelsen',
  },
  'achieved': {
    'en': 'Achieved',
    'no': 'Oppnådd',
    'se': 'Uppnått',
    'dk': 'Opnået',
  },
  'notAchieved': {
    'en': 'Not achieved',
    'no': 'Ikke oppnådd',
    'se': 'Inte uppnått',
    'dk': 'Ikke opnået',
  },
  'youWillReceive': {
    'en': 'You will receive',
    'no': 'Dere skal ha',
    'se': 'Ni kommer att få',
    'dk': 'I vil alle modtage',
  },
  'ofTheSumAbove': {
    'en': 'Of the sum above',
    'no': 'Av summen over',
    'se': 'Av summan över',
    'dk': 'Af summen over',
  },
  'ofTheSumsAbove': {
    'en': 'Of the sums above',
    'no': 'Av summene over',
    'se': 'Av summorna över',
    'dk': 'Af summene over',
  },
  'ofTheFinalAmount': {
    'en': 'Of the final amount',
    'no': 'Av det endelige beløpet',
    'se': 'Av det slutliga beloppet',
    'dk': 'Af det endelige beløb',
  },
  'sellOn': {
    'en': 'Sell-on',
    'no': 'Videresalg',
    'se': 'Videresäljning',
    'dk': 'Videresalg',
  },
  'sellOnPercentage': {
    'en': 'Sell-on percentage',
    'no': 'Prosent av videresalg',
    'se': 'Videresäljningsprocent',
    'dk': 'Videresalgsprocent',
  },
  'net': {
    'en': 'Net',
    'no': 'Netto',
    'se': 'Netto',
    'dk': 'Netto',
  },
  'gross': {
    'en': 'Gross',
    'no': 'Brutto',
    'se': 'Brutto',
    'dk': 'Brutto',
  },
  'netSellOn': {
    'en': 'Net sell-on',
    'no': 'Netto videresalg',
    'se': 'Netto videresäljning',
    'dk': 'Netto videresalg',
  },
  'dynamicSellOnPercentage': {
    'en': 'Dynamic sell-on percentage',
    'no': 'Variabel prosent av videresalg',
    'se': 'Dynamisk videresäljningsprocent',
    'dk': 'Dynamisk videresalgsprocent',
  },
  'staticSellOnPercentage': {
    'en': 'Static sell-on percentage',
    'no': 'Fast prosent av videresalg',
    'se': 'Statisk videresäljningsprocent',
    'dk': 'Fast videresalgsprocent',
  },
  'percentageWithDateAlreadyExists': {
    'en': 'A dynamic percentage with the same date already exists',
    'no': 'Det eksisterer allerede en variabel prosent med samme dato',
    'se': 'En dynamisk procent med samma datum finns redan',
    'dk': 'En dynamisk procent med samme dato findes allerede',
  },
  'editPercentage': {
    'en': 'Edit percentage',
    'no': 'Endre prosent',
    'se': 'Ändra procent',
    'dk': 'Rediger procent',
  },
  'sellOnCondition': {
    'en': 'Sell-on clause',
    'no': 'Videresalgsklausul',
    'se': 'Videresäljningsklausul',
    'dk': 'Videresalgsklausul',
  },
  'sellOnConditionAlreadyExists': {
    'en': 'A sell-on clause already exists',
    'no': 'En videresalgsklausul er allerede lagt til',
    'se': 'En videresäljningsklausul finns redan',
    'dk': 'En videresalgsklausul er allerede tilføjet',
  },
  'maxAmount': {
    'en': 'Max amount',
    'no': 'Maksbeløp',
    'se': 'Maxbelopp',
    'dk': 'Maksbeløb',
  },
  'provideMaxAmount': {
    'en': 'Provide max amount',
    'no': 'Oppgi maksbeløp',
    'se': 'Ange maxbelopp',
    'dk': 'Angiv maksbeløb',
  },
  'provideMaxAmountForConditions': {
    'en': 'Provide max amount for conditions',
    'no': 'Oppgi maksbeløp for betingelser',
    'se': 'Ange maxbelopp för villkor',
    'dk': 'Angiv maksbeløb for betingelser',
  },
  'confirmMaxAmount': {
    'en': 'Confirm max amount',
    'no': 'Bekreft maksbeløp',
    'se': 'Bekräfta maxbelopp',
    'dk': 'Bekræft maksbeløb',
  },
  'editMaxAmountForConditions': {
    'en': 'Edit max amount for conditions',
    'no': 'Endre maksbeløp for betingelser',
    'se': 'Ändra maxbelopp för villkor',
    'dk': 'Rediger maksbeløb for betingelser',
  },
  'payments': {
    'en': 'Payments',
    'no': 'Betalinger',
    'se': 'Betalningar',
    'dk': 'Betalinger',
  },
  'newPayment': {
    'en': 'New payment',
    'no': 'Ny betaling',
    'se': 'Ny betalning',
    'dk': 'Ny betaling',
  },
  'editPayment': {
    'en': 'Edit payment',
    'no': 'Endre betaling',
    'se': 'Ändra betalning',
    'dk': 'Rediger betaling',
  },
  'editPayments': {
    'en': 'Edit payments',
    'no': 'Endre betalinger',
    'se': 'Ändra betalningar',
    'dk': 'Rediger betalinger',
  },
  'paymentDate': {
    'en': 'Payment date',
    'no': 'Betalingsdato',
    'se': 'Betalningsdatum',
    'dk': 'Betalingsdato',
  },
  'paymentsWillNotBeSaved': {
    'en': 'The payments will not be saved',
    'no': 'Betalingene vil ikke bli lagret',
    'se': 'Betalningarna kommer inte att sparas',
    'dk': 'Betalingerne vil ikke blive gemt',
  },
  'exitClause?': {
    'en': 'Exit clause?',
    'no': 'Forlate klausulen?',
    'se': 'Avsluta klausulen?',
    'dk': 'Forlad klausulen?',
  },
  'discardCondition': {
    'en': 'Discard condition',
    'no': 'Forkast betingelse',
    'se': 'Kassera villkor',
    'dk': 'Forkast betingelse',
  },
  'discardCondition?': {
    'en': 'Discard condition?',
    'no': 'Forkaste betingelsen?',
    'se': 'Kassera villkoret?',
    'dk': 'Forkaste betingelsen?',
  },
  'discardPayments?': {
    'en': 'Discard payments?',
    'no': 'Forkaste betalingene?',
    'se': 'Kassera betalningarna?',
    'dk': 'Forkaste betalingerne?',
  },
  'overrideValue': {
    'en': 'Override value',
    'no': 'Overskriv verdi',
    'se': 'Åsidosätt värde',
    'dk': 'Overskriv værdi',
  },
  'previousOverridenValue': {
    'en': 'Previous overriden value',
    'no': 'Forrige overskrevne verdi',
    'se': 'Föregående åsidosatt värde',
    'dk': 'Forrige overskrevne værdi',
  },
  'resetOverriddenValue': {
    'en': 'Reset overridden value',
    'no': 'Nullstill overskrevet verdi',
    'se': 'Återställ åsidosatt värde',
    'dk': 'Nulstil overskrevet værdi',
  },
  'placeInTheLeague': {
    'en': 'Place in the league',
    'no': 'Plass i ligaen',
    'se': 'Plats i ligan',
    'dk': 'Plads i ligaen',
  },
  'minimumTablePosition': {
    'en': 'Minimum table position',
    'no': 'Minimum tabellplassering',
    'se': 'Minsta tabellposition',
    'dk': 'Minimum tabelposition',
  },
  'minuteRequirement?': {
    'en': 'Minute requirement?',
    'no': 'Minuttkrav per kamp?',
    'se': 'Minutkrav per match?',
    'dk': 'Minutkrav per kamp?',
  },
  'missingData': {
    'en': 'Missing data',
    'no': 'Mangler data',
    'se': 'Saknad data',
    'dk': 'Manglende data',
  },


  // info
  'noRegretInfo': {
    'en': 'You cannot regret this action',
    'no': 'Du kan ikke angre på denne handlingen',
    'se': 'Du kan inte ångra denna åtgärd',
    'dk': 'Du kan ikke fortryde denne handling',
  },
  'doNotShowThisInfoAgain': {
    'en': 'Do not show this information again',
    'no': 'Ikke vis denne informasjonen igjen',
    'se': 'Visa inte denna information igen',
    'dk': 'Vis ikke denne information igen',
  },
  'changesWillNotBeSaved': {
    'en': 'The changes will not be saved',
    'no': 'Endringene vil ikke bli lagret',
    'se': 'Ändringarna kommer inte att sparas',
    'dk': 'Ændringerne vil ikke blive gemt',
  },
  'fileAlreadyUploaded': {
    'en': 'A file with the same name has already been uploaded',
    'no': 'Det er allerede lastet opp en fil med samme navn',
    'se': 'En fil med samma namn har redan laddats upp',
    'dk': 'Der er allerede uploadet en fil med samme navn',
  },
  'underDevelopment': {
    'en': 'In development',
    'no': 'Under utvikling',
    'se': 'Under utveckling',
    'dk': 'Under udvikling',
  },
  'mobilePlatformIsUnderDevelopment': {
    'en': 'The mobile version is under development',
    'no': 'Mobilversjonen er under utvikling',
    'se': 'Mobilversionen är under utveckling',
    'dk': 'Mobilversionen er under udvikling',
  },
  'beingComputed': {
    'en': 'Being computed...',
    'no': 'Under utregning...',
    'se': 'Beräknas...',
    'dk': 'Beregnes...',
  },
  'noAmountProvided': {
    'en': 'You must provide an amount',
    'no': 'Du må oppgi et beløp',
    'se': 'Du måste ange ett belopp',
    'dk': 'Du skal angive et beløb',
  },
  'noOptionsAvailable': {
    'en': 'No options available',
    'no': 'Ingen alternativer tilgjengelige',
    'se': 'Inga alternativ tillgängliga',
    'dk': 'Ingen muligheder tilgængelige',
  },
  'invalidDate': {
    'en': 'Invalid date provided',
    'no': 'Ugyldig dato oppgitt',
    'se': 'Ogiltigt datum angivet',
    'dk': 'Ugyldig dato angivet',
  },
  'invalidAmount': {
    'en': 'The amount must be a positive number',
    'no': 'Beløpet må være et positivt tall',
    'se': 'Beloppet måste vara ett positivt nummer',
    'dk': 'Beløbet skal være et positivt tal',
  },
  'invalidAmounts': {
    'en': 'All amounts must be positive numbers',
    'no': 'Alle beløp må være positive tall',
    'se': 'Alla belopp måste vara positiva nummer',
    'dk': 'Alle beløb skal være positive tal',
  },
  'inputMustBeNumericAndPositive': {
    'en': 'Please provide a positive number',
    'no': 'Vennligst oppgi et positivt tall',
    'se': 'Vänligen ange ett positivt nummer',
    'dk': 'Angiv venligst et positivt tal',
  },
  'noChangesMade': {
    'en': 'You have not made any changes',
    'no': 'Du har ikke gjort noen endringer',
    'se': 'Du har inte gjort några ändringar',
    'dk': 'Du har ikke foretaget nogen ændringer',
  },
  'errorOccurred': {
    'en': 'An error occurred',
    'no': 'En feil oppstod',
    'se': 'Ett fel inträffade',
    'dk': 'Der opstod en fejl',
  },
  'somethingWentWrong': {
    'en': 'Something went wrong, please try again or contact us',
    'no': 'Noe gikk galt, vennligst prøv igjen eller kontakt oss',
    'se': 'Något gick fel, försök igen eller kontakta oss',
    'dk': 'Noget gik galt, prøv venligst igen eller kontakt os',
  },
  'goToRoleSettings': {
    'en': 'Go to role settings',
    'no': 'Gå til rolle-innstillinger',
    'se': 'Gå till rollinställningar',
    'dk': 'Gå til rolleindstillinger',
  },
  'updateAvailable': {
    'en': 'A new update is available',
    'no': 'En ny oppdatering er tilgjengelig',
    'se': 'En ny uppdatering är tillgänglig',
    'dk': 'En ny opdatering er tilgængelig',
  },
  'pleaseRefreshPage': {
    'en': 'Please refresh the page',
    'no': 'Vennligst last inn siden på nytt for å oppdatere',
    'se': 'Vänligen uppdatera sidan',
    'dk': 'Opdater venligst siden',
  },
  'platformIsUnderMaintenance': {
    'en': 'The platform is currently undergoing maintenance',
    'no': 'Plattformen er for øyeblikket under vedlikehold',
    'se': 'Plattformen genomgår för närvarande underhåll',
    'dk': 'Platformen er i øjeblikket under vedligeholdelse',
  },
  'editAlertInfo': {
    'en': 'Players who no longer meet the new criteria will be removed from the search',
    'no': 'Spillere som ikke lenger oppfyller de nye kriteriene bli fjernet fra søket',
    'se': 'Spelare som inte längre uppfyller de nya kriterierna kommer att tas bort från sökningen',
    'dk': 'Spillere, der ikke længere opfylder de nye kriterier, vil blive fjernet fra søgningen',
  },
  'noSeasonSelectedInfo': {
    'en': 'There are no available seasons with event data that match the filters you have selected.\nIf you choose to view a specific position group, all data shown in the player view will be based only on what the player has done in this position.\nIf you choose to filter the table on seasons with more than 180 minutes played or seasons in the domestic leagues, only these seasons will be shown and it will not be possible to select other seasons.',
    'no': 'Det er ingen tilgjengelige sesonger med event data som matcher filtrene du har valgt.\nDersom du velger å se på en spesifikk posisjonsgruppe, vil all dataen som vises inne på spilleren kun være basert på hva spilleren har gjort i denne posisjonen.\nDersom du velger å filtrere tabellen på sesonger med over 180 minutter spilt eller sesonger i hjemlige ligaer, vil kun disse sesongene vises og det vil ikke være mulig å velge andre sesonger.',
    'se': 'Det finns inga tillgängliga säsonger med event data som matchar de filter du har valt.\nOm du väljer att se på en specifik positiongrupp, kommer all data som visas i spelarvyn att vara baserad på vad spelaren har gjort i denna position.\nOm du väljer att filtrera tabellen på säsonger med mer än 180 minuter spelade eller säsonger i inhemska ligor, kommer endast dessa säsonger att visas och det kommer inte att vara möjligt att välja andra säsonger.',
    'dk': 'Der er ingen tilgængelige sæsoner med event data, der matcher de filtre, du har valgt.\nHvis du vælger at se på en specifik positiongruppe, vil alle data, der vises i spiller visningen, kun være baseret på, hvad spilleren har gjort i denne position.\nHvis du vælger at filtrere tabellen på sæsoner med mere end 180 minutter spillet eller sæsoner i hjemlige ligaer, vil kun disse sæsoner blive vist, og det vil ikke være muligt at vælge andre sæsoner.',
  },
  'playerLinePlotInfo': {
    'en': 'The graph shows the development of the player\'s skill rating over time.\nOnly available seasons in domestic leagues with more than 180 minutes played are included in the graph.\nIf you click on "Skill rating" in the table below you will get a description of what skill rating is.',
    'no': 'Grafen viser utviklingen til spillerens skillrating over tid.\nDet er kun tilgjengelige sesonger i hjemlige ligaer med over 180 minutter spilt som er inkludert i grafen.\nDersom du trykker på "Skillrating" i tabellen nedenfor vil du få opp en beskrivelse av skillrating er.',
    'se': 'Grafen visar utvecklingen av spelarens skillrating över tid.\nEndast tillgängliga säsonger i inhemska ligor med mer än 180 minuter spelade är inkluderade i grafen.\nOm du klickar på "Skillrating" i tabellen nedan får du en beskrivning av vad skillrating är.',
    'dk': 'Grafen viser udviklingen af spillerens skillrating over tid.\nKun tilgængelige sæsoner i hjemlige ligaer med mere end 180 minutter spillet er inkluderet i grafen.\nHvis du klikker på "Skillrating" i tabellen nedenfor, får du en beskrivelse af, hvad skillrating er.',
  },
  'playerRadarPlotInfo': {
    'en': 'The radar plot shows selected stats from the selected season. The selection is based on which stats are considered most relevant for the positions the player has played the most this season, or for the position group you have selected.\nPlayers will always be compared against all players in our database within the same position group. This results in a ranking among thousands of players, and the value shown for each stat then represents the percentile of where the player is placed in this ranking. A value of 9, for example, means that the player is better than 90% of all other players for a given stat, and thus in the top 10%.\nEach stat is calculated per 90 minutes played within each position group. This means that a player who has played both as a midfielder and a striker will have two sets of stats corresponding to each position group, and one overall set of stats that is a weighted average of the two position groups.\nYou can always click on any stat to get a description of what the stat means.',
    'no': 'Radarplottet viser utvalgte statistikker fra den valgte sesongen. Utvalget er basert på hvilke statistikker som er vurdert mest relevant for posisjonene spilleren har spilt mest denne sesongen, eller for posisjonsgruppen du har valgt.\nSpillere vil alltid sammenlignes opp mot alle spillere i vår database innenfor samme posisjonsgruppe. Dette gir en rangering blant tusenvis av spillere, og verdien for hver statistikk representerer da hvor spilleren havner i denne persentilrangeringen. For eksempel vil en verdi på 9 bety at spilleren er bedre enn 90% av alle andre spillere for en gitt statistikk, og dermed topp 10%.\nHver statistikk er regnet ut per 90 spilte minutter innenfor hver posisjonsgruppe. Dette betyr at en spiller som for eksempel har spilt både back og midtbane vil ha to sett med statistikker tilhørende hver posisjonsgruppe, og et overordnet sett med statistikker som er et vektet snitt av de to posisjonsgruppene.\nDu kan alltid klikke på en statistikk for å få opp en beskrivelse av hva den betyr.',
    'se': 'Radarplotten visar utvalda statistik från den valda säsongen. Urvalet baseras på vilka statistik som anses mest relevanta för de positioner som spelaren har spelat mest denna säsong, eller för den positiongrupp du har valt.\nSpelare kommer alltid att jämföras mot alla spelare i vår databas inom samma positiongrupp. Detta resulterar i en ranking bland tusentals spelare, och värdet som visas för varje statistik representerar då procentilen av var spelaren är placerad i denna ranking. Ett värde på 9 betyder till exempel att spelaren är bättre än 90% av alla andra spelare för en given statistik, och därmed i topp 10%.\nVarje statistik beräknas per 90 minuter spelade inom varje positiongrupp. Detta betyder att en spelare som har spelat både som mittfältare och anfallare kommer att ha två uppsättningar statistik som motsvarar varje positiongrupp, och en övergripande uppsättning statistik som är ett vägt genomsnitt av de två positiongrupperna.\nDu kan alltid klicka på en statistik för att få en beskrivning av vad statistiken betyder.',
    'dk': 'Radarplottet viser udvalgte statistikker fra den valgte sæson. Udvalget er baseret på, hvilke statistikker der anses mest relevante for de positioner, spilleren har spillet mest denne sæson, eller for den positiongruppe, du har valgt.\nSpillere vil altid blive sammenlignet mod alle spillere i vores database inden for samme positiongruppe. Dette resulterer i en rangering blandt tusindvis af spillere, og værdien for hver statistik repræsenterer da procentilen af, hvor spilleren er placeret i denne rangering. En værdi på 9 betyder f.eks., at spilleren er bedre end 90% af alle andre spillere for en given statistik, og dermed i top 10%.\nHver statistik beregnes per 90 minutter spillet inden for hver positiongruppe. Dette betyder, at en spiller, der har spillet både som midtbanespiller og angriber, vil have to sæt statistikker, der svarer til hver positiongruppe, og en samlet statistik, der er et vægtet gennemsnit af de to positiongrupper.\nDu kan altid klikke på en statistik for at få en beskrivelse af, hvad statistikken betyder.',
  },
  'editFormationInfo': {
    'en': 'All teams will be updated with the new formation, and players may be moved to new positions',
    'no': 'Alle lag vil bli oppdatert med ny formasjon, og spillere kan bli flyttet til nye posisjoner',
    'se': 'Alla lag kommer att uppdateras med den nya formationen, och spelare kan flyttas till nya positioner',
    'dk': 'Alle hold vil blive opdateret med den nye formation, og spillere kan blive flyttet til nye positioner',
  },
  'roleConfigsInfoText1': {
    'en': 'The roles in this team determine the value shown for each position when you select',
    'no': 'Rollene på dette laget bestemmer verdien som vises for hver posisjon når du velger',
    'se': 'Rollerna i detta lag bestämmer värdet som visas för varje position när du väljer',
    'dk': 'Rollerne på dette hold bestemmer værdien, der vises for hver position, når du vælger',
  },
  'roleConfigsInfoText2': {
    'en': 'from the team menu.',
    'no': 'fra lagmenyen.',
    'se': 'från lagmenyn.',
    'dk': 'fra holdmenuen.',
  },
  'roleConfigsInfoModalText': {
    'en': 'The roles in this team determine the value shown for each position when you select "Role index" from the team menu.\nIf you drag a role onto a position here, for example left center back, it will be this role index that is shown inside a team for players placed as left center back.\nIt is also possible to drag multiple roles (up to three) onto the same position. Then you will be able to choose a rule for which role index to show, and you will be able to read more about what the different rules entail if you click on the rule that will appear above the roles.\nIf a position has no roles assigned, the skill index of players placed on this position will be shown instead.',
    'no': 'Dette laget bestemmer hvilken verdi som vises for hver posisjon inne på et lag når du velger "Rolleindeks" fra lagmenyen.\nDersom du drar en rolle inn på en posisjon her, for eksempel venstre midtstopper, vil det være denne rolleindeksen som vises inne på et lag for spillere som er plassert som venstre midtstopper.\nDet er også mulig å dra flere roller (opptil tre) inn på samme posisjon. Da vil du kunne velge en regel for hvilken rolleindeks som skal vises, og du vil kunne lese mer om hva de ulike reglene innebærer dersom du trykker på regelen som vil dukke opp over rollene.\nHvis en posisjon ikke har noen roller tildelt, vil skillindeksen til spillere som ligger på denne posisjonen vises i stedet.',
    'se': 'Rollerna i detta lag bestämmer vilket värde som visas för varje position inne på ett lag när du väljer "Rollindex" från lagmenyn.\nOm du drar en roll till en position här, till exempel vänster mittback, kommer det att vara detta rollindex som visas inne på ett lag för spelare som är placerade som vänster mittback.\nDet är också möjligt att dra flera roller (upp till tre) till samma position. Då kommer du att kunna välja en regel för vilket rollindex som ska visas, och du kommer att kunna läsa mer om vad de olika reglerna innebär om du klickar på regeln som kommer att visas ovanför rollerna.\nOm en position inte har några roller tilldelade, kommer spelarnas skicklighetsindex som är placerade på denna position att visas istället.',
    'dk': 'Rollerne på dette hold bestemmer, hvilken værdi, der vises for hver position inde på et hold, når du vælger "Rolleindeks" fra holdmenuen.\nHvis du trækker en rolle til en position her, f.eks. venstre midterforsvarer, vil det være denne rolleindeks, der vises inde på et hold for spillere, der er placeret som venstre midterforsvarer.\nDet er også muligt at trække flere roller (op til tre) til samme position. Så vil du kunne vælge en regel for, hvilken rolleindeks, der skal vises, og du vil kunne læse mere om, hvad de forskellige regler indebærer, hvis du klikker på reglen, der vil dukke op over rollerne.\nHvis en position ikke har nogen roller tildelt, vil spillernes færdighedsindeks, der er placeret på denne position, blive vist i stedet.',
  },
  'formAndPlayingTimeInfo': {
    'en': 'Form is a measure of the player\'s overall performance level recently, while playing time represents the recent playing time in the domestic league.\nBoth form and playing time belong to the player\'s overall set of data, and not to each position group. For players who have only played one position group, this group will reflect the player\'s overall data. For players with multiple position groups, the combination of these will constitute the player\'s overall set of data.\nYou can click on form and playing time below to see a more detailed explanation of the possible values and how they are calculated.',
    'no': 'Form er et mål på spillerens overordnede prestasjonsnivå i nyere tid, mens spilletid representerer spilletiden i hjemlig liga i nyere tid.\nBåde form og spilletid hører til spillerens overordnede sett med data, og ikke til hver posisjonsgruppe. For spillere som kun har spilt en posisjonsgruppe, vil denne gruppen gjenspeile spillerens overordnede data. For spillere med flere posisjonsgrupper, vil kombinasjonen av disse utgjøre spillerens overordnede sett med data.\nDu kan klikke på form og spilletid under for å se en mer detaljert forklaring over de mulige verdiene og hvordan de regnes ut.',
    'se': 'Form är ett mått på spelarens övergripande prestandanivå nyligen, medan speltid representerar den senaste speltiden i hemmaligan.\nBåde form och speltid hör till spelarens övergripande uppsättning data, och inte till varje positiongrupp. För spelare som bara har spelat en positiongrupp kommer denna grupp att återspegla spelarens övergripande data. För spelare med flera positiongrupper kommer kombinationen av dessa att utgöra spelarens övergripande uppsättning data.\nDu kan klicka på form och speltid nedan för att se en mer detaljerad förklaring av de möjliga värdena och hur de beräknas.',
    'dk': 'Form er et mål for spillerens overordnede præstationsniveau for nylig, mens spilletid repræsenterer spilletiden i hjemlig liga for nylig.\nBåde form og spilletid hører til spillerens overordnede sæt data, og ikke til hver positiongruppe. For spillere, der kun har spillet en positiongruppe, vil denne gruppe afspejle spillerens overordnede data. For spillere med flere positiongrupper vil kombinationen af disse udgøre spillerens overordnede sæt data.\nDu kan klikke på form og spilletid nedenfor for at se en mere detaljeret forklaring af de mulige værdier og hvordan de beregnes.',
  },
  'formInfo': {
    'en': 'Form is a measure of the player\'s overall performance level recently, in relation to what is expected. Form is based on all matches the player has played in the last 4 months, and the player must have played at least 400 minutes to get a form.\nFirst, a form index is calculated, which is a new skill index for this period, where recent matches count more. This means that the latest matches of a player will have the greatest impact on the form. Then this form index is compared to the player\'s skill index.\n<FormTable/>',
    'no': 'Form er et mål på spillerens overordnede prestasjonsnivå i nyere tid, i forhold til hva som er forventet. Form baserer seg på alle kampene spilleren har spilt de siste 4 månedene, og spilleren må ha spilt minst 400 minutter for å få en form.\nFørst beregnes en formindeks, som er en ny skillindeks for denne perioden, der nylige kamper teller mer. Dette betyr at de siste kampene til en spiller vil ha størst påvirkining på formen. Derretter sammenlignes denne formindeksen opp mot spillerens skillindeks.\n<FormTable/>',
    'se': 'Form är ett mått på spelarens övergripande prestandanivå nyligen, i förhållande till vad som förväntas. Form baseras på alla matcher spelaren har spelat de senaste 4 månaderna, och spelaren måste ha spelat minst 400 minuter för att få en form.\nFörst beräknas en formindex, som är en ny skillindex för denna period, där nyliga matcher räknas mer. Detta betyder att de senaste matcherna för en spelare kommer att ha störst påverkan på formen. Sedan jämförs denna formindex med spelarens skillindex.\n<FormTable/>',
    'dk': 'Form er et mål for spillerens overordnede præstationsniveau for nylig, i forhold til hvad der forventes. Form baserer sig på alle kampe, spilleren har spillet de seneste 4 måneder, og spilleren skal have spillet mindst 400 minutter for at få en form.\nFørst beregnes en formindeks, som er en ny skillindeks for denne periode, hvor nylige kampe tæller mere. Dette betyder, at de seneste kampe for en spiller vil have størst indflydelse på formen. Derefter sammenlignes denne formindeks med spillerens skillindeks.\n<FormTable/>',
  },
  'playingTimeInfo': {
    'en': 'Playing time represents the recent playing time in the domestic league, and is only available for players who play in a league with event data. The calculation is based on how many matches the player has been in the match squad, and how many matches and minutes the player has played. It does not take into account if players are injured or suspended.\n<PlayingTimeTable/>',
    'no': 'Spilletid representerer spilletiden i hjemlig liga i nyere tid, og finnes bare for spillere som spiller i en liga med event data. Utregningen baserer seg på hvor mange kamper spilleren har vært i kamptroppen, og hvor mange kamper og minutter spilleren har spilt. Den tar derimot ikke hensyn til om spillere er skadet eller suspendert.\n<PlayingTimeTable/>',
    'se': 'Speltid representerar den senaste speltiden i hemmaligan, och finns endast för spelare som spelar i en liga med event data. Beräkningen baseras på hur många matcher spelaren har varit i matchtruppen, och hur många matcher och minuter spelaren har spelat. Den tar inte hänsyn till om spelare är skadade eller avstängda.\n<PlayingTimeTable/>',
    'dk': 'Spilletid repræsenterer spilletiden i hjemlig liga for nylig, og er kun tilgængelig for spillere, der spiller i en liga med event data. Beregningen er baseret på, hvor mange kampe spilleren har været i kamptruppen, og hvor mange kampe og minutter spilleren har spillet. Den tager ikke hensyn til, om spillere er skadet eller suspenderet.\n<PlayingTimeTable/>',
  },
  'seasonConditionInfo': {
    'en': 'A season condition is a condition where the specified criteria must be met in a given season, independent of other seasons.',
    'no': 'En sesongbetingelse er en betingelse hvor de oppgitte kriteriene må oppfylles i løpet av en sesong, uavhengig av andre sesonger.',
    'se': 'En säsongsbetingelse är en betingelse där de angivna kriterierna måste uppfyllas under en given säsong, oberoende av andra säsonger.',
    'dk': 'En sæsonbetingelse er en betingelse, hvor de angivne kriterier skal opfyldes i en given sæson, uafhængigt af andre sæsoner.',
  },
  'totalConditionInfo': {
    'en': 'A total condition is a condition that counts the total number of times a given criterion occurs, and it applies for the entire period of the player at the new club.',
    'no': 'En total betingelse er en betingelse som teller opp antall ganger et oppgitt kriterium inntreffer, og gjelder for hele oppholdet til spilleren i den nye klubben.',
    'se': 'En total betingelse är en betingelse som räknar det totala antalet gånger ett givet kriterium inträffar, och den gäller under hela spelarens tid i den nya klubben.',
    'dk': 'En total betingelse er en betingelse, der tæller det samlede antal gange, et givet kriterium forekommer, og den gælder for hele spillerens ophold i den nye klub.',
  },
  'standardSeasonConditionInfo': {
    'en': 'A standard season condition includes one amount, and is fulfilled for a given season if all the specified criteria are met during the season.\n&nbsp;\nExample:\nYou will receive 100.000,- if the player plays at least 20 matches during any season.',
    'no': 'En standard sesongbetingelse omfatter ett beløp, og oppfylles for en gitt sesong dersom alle oppgitte kriterier oppfylles i løpet av sesongen.\n&nbsp;\nEksempel:\nDere skal motta 100.000,- dersom spilleren spiller minst 20 kamper i løpet av en hvilken som helst sesong.',
    'se': 'En standard säsongsbetingelse inkluderar ett belopp och uppfylls för en given säsong om alla angivna kriterier uppfylls under säsongen.\n&nbsp;\nExempel:\nNi kommer att få 100.000,- om spelaren spelar minst 20 matcher under en vilken som helst säsong.',
    'dk': 'En standard sæsonbetingelse inkluderer et beløb og opfyldes for en given sæson, hvis alle de specificerede kriterier opfyldes i løbet af sæsonen.\n&nbsp;\nEksempel:\nI vil modtage 100.000,- hvis spilleren spiller mindst 20 kampe i løbet af en hvilken som helst sæson.',
  },
  'advancedSeasonConditionInfo': {
    'en': 'An advanced season condition consists of a set of sub-conditions, where each sub-condition is associated with a unique amount, and may also contain a set of common conditions.\nFor an advanced season condition to be fulfilled, one of the sub-conditions must be fulfilled in addition to all the common conditions.\n&nbsp;\nExample:\nYou will receive 300.000,- if the player plays 50% of the matches in any season and the club qualifies for the Champions League.\nYou will receive 200.000,- if the player plays 50% of the matches in any season and the club qualifies for the Europa League.\nYou will receive 100.000,- if the player plays 50% of the matches in any season and the club qualifies for the Conference League.\n&nbsp;\nHere, the player playing 50% of the matches is a common condition, because all the amounts (300.000, 200.000 and 100.000) are conditioned on this, while qualification for the different European cups will be sub-conditions, each associated with a unique amount.',
    'no': 'En avansert sesongbetingelse består av et sett med delbetingelser, hvor hver delbetingelse er tilknyttet et unikt beløp, og kan i tillegg inneholde et sett med felles betingelser.\nFor at en avansert sesongbetingelse skal oppfylles, må én av delbetingelsene oppfylles i tillegg til alle de felles betingelsene.\n&nbsp;\nEksempel:\nDere skal motta 300.000,- dersom spilleren, i en hvilken som helst sesong, spiller 50% av kampene og klubben kvalifiserer seg til Champions League.\nDere skal motta 200.000,- dersom spilleren, i en hvilken som helst sesong, spiller 50% av kampene og klubben kvalifiserer seg til Europa League.\nDere skal motta 100.000,- dersom spilleren, i en hvilken som helst sesong, spiller 50% av kampene og klubben kvalifiserer seg til Conference League.\n&nbsp;\nHer vil det at spilleren spiller 50% av kampene være en felles betingelse, fordi alle beløpene (300.000, 200.000 og 100.000) betinger dette, mens kvalifisering til de ulike Europa-cupene vil være delbetingelser tilknyttet hvert sitt beløp.',
    'se': 'En avancerad säsongsbetingelse består av en uppsättning delbetingelser, där varje delbetingelse är associerad med ett unikt belopp, och kan också innehålla en uppsättning gemensamma betingelser.\nFör att en avancerad säsongsbetingelse ska uppfyllas, måste en av delbetingelserna uppfyllas förutom alla gemensamma betingelser.\n&nbsp;\nExempel:\nNi kommer att få 300.000,- om spelaren spelar 50% av matcherna i vilken säsong som helst och klubben kvalificerar sig för Champions League.\nNi kommer att få 200.000,- om spelaren spelar 50% av matcherna i vilken säsong som helst och klubben kvalificerar sig för Europa League.\nNi kommer att få 100.000,- om spelaren spelar 50% av matcherna i vilken säsong som helst och klubben kvalificerar sig för Conference League.\n&nbsp;\nHär är att spelaren spelar 50% av matcherna en gemensam betingelse, eftersom alla belopp (300.000, 200.000 och 100.000) är beroende av detta, medan kvalificering för de olika europeiska cuperna kommer att vara delbetingelser, var och en associerad med ett unikt belopp.',
    'dk': 'En avanceret sæsonbetingelse består af et sæt underbetingelser, hvor hver underbetingelse er associeret med et unikt beløb, og kan også indeholde et sæt fælles betingelser.\nFor at en avanceret sæsonbetingelse skal opfyldes, skal en af underbetingelserne opfyldes udover alle de fælles betingelser.\n&nbsp;\nEksempel:\nI vil modtage 300.000,- hvis spilleren spiller 50% af kampene i en hvilken som helst sæson og klubben kvalificerer sig til Champions League.\nI vil modtage 200.000,- hvis spilleren spiller 50% af kampene i en hvilken som helst sæson og klubben kvalificerer sig til Europa League.\nI vil modtage 100.000,- hvis spilleren spiller 50% af kampene i en hvilken som helst sæson og klubben kvalificerer sig til Conference League.\n&nbsp;\nHer vil det at spilleren spiller 50% af kampene være en fælles betingelse, fordi alle beløbene (300.000, 200.000 og 100.000) er betinget af dette, mens kvalifikation til de forskellige europæiske turneringer vil være underbetingelser, hver associeret med et unikt beløb.',
  },
  'positionGroupsInfo': {
    'en': 'All data for a player is first calculated for each position group the player has played, and will be compared against all players in the database within the same position group. If a player has data for multiple position groups, the player will also have a set of overall data for all groups, which will be a weighted average of the data for the different position groups. Below you can see the different position groups and which positions are included in each group.',
    'no': 'All data for en spiller regnes først ut for hver posisjonsgruppe som spilleren har spilt, og sammenlignes opp mot dataen til alle spillere i databasen innenfor samme posisjonsgruppe. Dersom en spiller har data for flere posisjonsgrupper, vil spilleren også ha et sett med data for alle gruppene, som vil være et vektet snitt av dataen for de ulike posisjonsgruppene. Under ser du de ulike posisjonsgruppene og hvilke posisjoner som inngår i hver gruppe.',
    'se': 'All data för en spelare beräknas först för varje positiongrupp som spelaren har spelat, och jämförs mot alla spelare i databasen inom samma positiongrupp. Om en spelare har data för flera positiongrupper, kommer spelaren också att ha en uppsättning övergripande data för alla grupper, som kommer att vara ett vägt genomsnitt av data för de olika positiongrupperna. Nedan kan du se de olika positiongrupperna och vilka positioner som ingår i varje grupp.',
    'dk': 'Alle data for en spiller beregnes først for hver positiongruppe, som spilleren har spillet, og vil blive sammenlignet med alle spillere i databasen inden for samme positiongruppe. Hvis en spiller har data for flere positiongrupper, vil spilleren også have et sæt overordnede data for alle grupper, som vil være et vægtet gennemsnit af data for de forskellige positiongrupper. Nedenfor kan du se de forskellige positiongrupper og hvilke positioner, der er inkluderet i hver gruppe.',
  },
  'roleIndexesInfo': {
    'en': 'Role indexes are custom indexes that your club has defined. They will always belong to a specific position group, and will only be based on what the player has done in this position group.\nA role index is a weighted sum of the corresponding role ratings from recent seasons, where more recent seasons and seasons with a lot of playing time are weighted more. Indexes will also take into account the level of the different competitions, such that ratings from tougher competitions will be rewarded, while ratings from weaker competitions will be penalized.\nA rating is a weighted sum of selected metrics, and if you click on the different roles below you will be able to see which metrics are included and their relative weights.',
    'no': 'Rolleindekser er tilpassede indekser som klubben deres selv har definert. De vil alltid høre til en spesifikk posisjonsgruppe, og vil kun være basert på hva spilleren har gjort i denne posisjonsgruppen.\nAlle indekser er et vektet snitt av tilsvarende ratinger de siste sesongene, der nylige sesonger og sesonger med mye spilletid vektes mer. Indekser vil også ta hensyn til nivået på de ulike konkurransene, slik at ratinger fra tøffere konkurranser vil bli belønnet, mens ratinger fra svakere konkurranser vil bli straffet.\nEn rating er en vektet sum av utvalgte metrikker, og dersom du klikker på de ulike rollene under vil du kunne se hvilke metrikker som inngår og hvordan de vektes.',
    'se': 'Rollindex är anpassade index som eran klubb har definierat. De kommer alltid att tillhöra en specifik positiongrupp, och kommer endast att baseras på vad spelaren har gjort i denna positiongrupp.\nEtt rollindex är ett viktat snitt av motsvarande rollratinger från nyligen säsonger, där mer nyligen säsonger och säsonger med mycket speltid viktas mer. Index kommer också att ta hänsyn till nivån på de olika tävlingarna, så att ratinger från tuffare tävlingar kommer att belönas, medan ratinger från svagare tävlingar kommer att straffas.\nEn rating är ett viktat snitt av utvalda metriker, och om du klickar på de olika rollerna nedan kommer du att kunna se vilka metriker som ingår och deras relativa vikter.',
    'dk': 'Rolleindekser er tilpassede indekser, som jeres klub har defineret. De vil altid høre til en specifik positiongruppe, og vil kun være baseret på, hvad spilleren har gjort i denne positiongruppe.\nEn rolleindeks er et vægtet gennemsnit af de tilsvarende rolleratings fra de seneste sæsoner, hvor mere nylige sæsoner og sæsoner med meget spilletid vægtes mere. Indekser vil også tage hensyn til niveauet i de forskellige konkurrencer, således at ratings fra hårdere konkurrencer vil blive belønnet, mens ratings fra svagere konkurrencer vil blive straffet.\nEn rating er et vægtet gennemsnit af udvalgte metrikker, og hvis du klikker på de forskellige roller nedenfor, vil du kunne se, hvilke metrikker der er inkluderet, og deres relative vægte.',
  },
  'roleIndexInTeamInfo': {
    'en': 'When you select "Role index" from the team menu, the value shown for each player will be based on the position the player has in the team and the role index that is configured for this position.\nYou can configure which index to show for each position by dragging the different roles onto the team in your role settings.\nIf a position does not have any roles configured, the skill index will be shown for this position.\nYou can find the role settings by going to "Settings" and then "Roles", or by clicking the button below.',
    'no': 'Når du velger "Rolleindeks" fra lagmenyen, vil verdien som vises for hver spiller være basert på hvilken posisjon spilleren har i laget og hvilken rolleindeks som er konfigurert for denne posisjonen.\nDere kan selv konfigurere hvilken indeks som skal vises for hver posisjon ved å dra de ulike rollene inn på laget i deres rolle-innstillinger.\nDersom en posisjon ikke har noen roller konfigurert, vil skillindeksen vises for denne posisjonen.\nRolle-innstillingene finner du ved å gå til "Innstillinger" og deretter "Roller", eller ved å trykke på knappen under.',
    'se': 'När du väljer "Rollindex" från lagmenyn, kommer värdet som visas för varje spelare att vara baserat på vilken position spelaren har i laget och vilken rollindex som är konfigurerad för denna position.\nNi kan konfigurera vilken index som ska visas för varje position genom att dra de olika rollerna till laget i era rollinställningar.\nOm en position inte har några roller konfigurerade, kommer skicklighetsindexet att visas för denna position.\nNi hittar rollinställningarna genom att gå till "Inställningar" och sedan "Roller", eller genom att klicka på knappen nedan.',
    'dk': 'Når du vælger "Rolleindeks" fra holdmenuen, vil værdien, der vises for hver spiller, være baseret på den position, spilleren har på holdet, og den rolleindeks, der er konfigureret for denne position.\nDu kan konfigurere, hvilken indeks, der skal vises for hver position, ved at trække de forskellige roller til holdet i dine rolleindstillinger.\nHvis en position ikke har nogen roller konfigureret, vil færdighedsindekset blive vist for denne position.\nDu kan finde rolleindstillingerne ved at gå til "Indstillinger" og derefter "Roller", eller ved at klikke på knappen nedenfor.',
  },
  'roleInfoModalText1': {
    'en': ' is a role customized for your club.',
    'no': ' er en rolle tilpasset deres klubb.',
    'se': ' är en roll anpassad för er klubb.',
    'dk': ' er en rolle tilpasset jeres klub.',
  },
  'roleInfoModalText2': {
    'en': 'A role is a weighted sum of selected metrics, and will always belong to a specific position group. The value goes from 0 to 10, and represents a ranking among all players in the database within the same position group.',
    'no': 'En rolle er en vektet sum av utvalgte metrikker, og vil alltid høre til en spesifikk posisjonsgruppe. Verdien går fra 0 til 10, og representerer en rangering blant alle spillere i databasen innenfor samme posisjonsgruppe.',
    'se': 'En roll är ett viktat snitt av utvalda metriker, och kommer alltid att tillhöra en specifik positiongrupp. Värdet går från 0 till 10, och representerar en ranking bland alla spelare i databasen inom samma positiongrupp.',
    'dk': 'En rolle er et vægtet gennemsnit af udvalgte metrikker, og vil altid høre til en specifik positiongruppe. Værdien går fra 0 til 10, og repræsenterer en rangering blandt alle spillere i databasen inden for samme positiongruppe.',
  },
  'roleInfoModalText3': {
    'en': 'Below you can see which metrics are included in this role and how they are weighted relative to each other. You can click on each metric to get a description of what it means.',
    'no': 'Under ser du hvilke metrikker som inngår i denne rollen og hvordan de er vektet relativt til hverandre. Du kan klikke på hver metrikk for å få opp en beskrivelse av hva den betyr.',
    'se': 'Nedan kan du se vilka metriker som ingår i denna roll och hur de vägs relativt mot varandra. Du kan klicka på varje metrik för att få en beskrivning av vad den betyder.',
    'dk': 'Nedenfor kan du se, hvilke metrikker, der er inkluderet i denne rolle, og hvordan de vægtes i forhold til hinanden. Du kan klikke på hver metrik for at få en beskrivelse af, hvad den betyder.',
  },
  'roleInfoModalText4': {
    'en': 'This role is currently being calculated, and will likely be ready by tomorrow.',
    'no': 'Denne rollen er foreløpig under utregning, og er sannsynligvis klar til i morgen.',
    'se': 'Denna roll beräknas för närvarande, och kommer troligen att vara klar imorgon.',
    'dk': 'Denne rolle er i øjeblikket under beregning, og vil sandsynligvis være klar i morgen.',
  },
  'deleteSubClauseInfo': {
    'en': 'If you delete this sub-clause, all subsequent sub-clauses will also be deleted, and the action cannot be undone',
    'no': 'Dersom du sletter denne underklausulen, vil også alle påfølgende underklausuler bli slettet, og handlingen kan ikke angres',
    'se': 'Om du tar bort denna underklausul kommer också alla efterföljande underklausuler att tas bort, och åtgärden kan inte ångras',
    'dk': 'Hvis du sletter dette underpunkt, vil alle efterfølgende underpunkter også blive slettet, og handlingen kan ikke fortrydes',
  },
  'removeClubInfo': {
    'en': 'If you remove the club, the selected competitions will automatically be updated for existing conditions, and you risk that the new competitions are not correct.',
    'no': 'Dersom du fjerner klubben vil de valgte konkurransene automatisk oppdateres for eksisterende betingelser, og du riskerer at de nye konkurransene ikke er korrekte.',
    'se': 'Om du tar bort klubben kommer de valda tävlingarna automatiskt att uppdateras för befintliga villkor, och du riskerar att de nya tävlingarna inte är korrekta.',
    'dk': 'Hvis du fjerner klubben, vil de valgte konkurrencer automatisk blive opdateret for eksisterende betingelser, og du risikerer, at de nye konkurrencer ikke er korrekte.',
  },
  'regretIncorrectTransferInfo': {
    'en': 'The transfer has previously been marked as incorrect. Do you want to mark the transfer as correct?',
    'no': 'Overgangen har tidligere blitt markert som feil. Ønsker du å markere overgangen som korrekt?',
    'se': 'Övergången har tidigare markerats som felaktig. Vill du markera övergången som korrekt?',
    'dk': 'Overgangen er tidligere blevet markeret som forkert. Ønsker du at markere overgangen som korrekt?',
  },
  'incorrectTransferInfo': {
    'en': 'If the transfer is confirmed to be incorrect, for instance due to a loan, the clause will remain active',
    'no': 'Dersom overgangen bekreftes feil, for eksempel grunnet et lån, vil klausulen forbli aktiv',
    'se': 'Om övergången bekräftas vara felaktig, till exempel på grund av ett lån, kommer klausulen att förbli aktiv',
    'dk': 'Hvis overgangen bekræftes at være forkert, f.eks. på grund af et lån, vil klausulen forblive aktiv',
  },
  'overrideValueInfo': {
    'en': 'If the value is incorrect, you may override the value. In this case, future values will be based on the new value and only count onwards from the provided date.',
    'no': 'Dersom verdien er feil, kan du overskrive verdien. Da vil fremtidige verdier ta utgangspunkt i den nye verdien og kun telle videre fra den oppgitte datoen.',
    'se': 'Om värdet är felaktigt kan du skriva över värdet. I detta fall kommer framtida värden att baseras på det nya värdet och endast räknas från den angivna datumen.',
    'dk': 'Hvis værdien er forkert, kan du overskrive værdien. I dette tilfælde vil fremtidige værdier være baseret på den nye værdi og kun tælle videre fra den angivne dato.',
  },
  'provideMaxAmountForConditionsInfo': {
    'en': 'You may provide a maximum amount for all conditions (except sell-on clause)',
    'no': 'Her kan du oppgi et maksbeløp for alle betingelser (unntatt videresalg)',
    'se': 'Här kan du ange ett maxbelopp för alla villkor (förutom vidareförsäljning)',
    'dk': 'Her kan du angive et maksbeløb for alle betingelser (undtagen videresalg)',
  },
  'noDateSelectedForCondition': {
    'en': 'You must select a transfer date before you can add conditions',
    'no': 'Du må velge overgangsdato før du kan legge til betingelser',
    'se': 'Du måste välja övergångsdatum innan du kan lägga till villkor',
    'dk': 'Du skal vælge en overgangsdato, før du kan tilføje betingelser',
  },
  'noClubOrDateSelectedForCondition': {
    'en': 'You must select a club and a transfer date before you can add conditions',
    'no': 'Du må velge klubb og overgangsdato før du kan legge til betingelser',
    'se': 'Du måste välja klubb och övergångsdatum innan du kan lägga till villkor',
    'dk': 'Du skal vælge en klub og en overgangsdato, før du kan tilføje betingelser',
  },
  'noClubSelectedForCondition': {
    'en': 'You must select a club before you can add conditions',
    'no': 'Du må velge klubb før du kan legge til betingelser',
    'se': 'Du måste välja klubb innan du kan lägga till villkor',
    'dk': 'Du skal vælge en klub, før du kan tilføje betingelser',
  },
  'maxAmountReachedMessage': {
    'en': 'The total max amount for all conditions is reached',
    'no': 'Maksbeløpet for alle betingelsene er nådd',
    'se': 'Maxbeloppet för alla villkor är nått',
    'dk': 'Maksbeløbet for alle betingelser er nået',
  },
  'invalidMaxAmount': {
    'en': 'The max amount must be a positive number',
    'no': 'Maksbeløpet må være et positivt tall',
    'se': 'Maxbeloppet måste vara ett positivt tal',
    'dk': 'Maksbeløbet skal være et positivt tal',
  },
  'invalidSellOnPercentage': {
    'en': 'Please provide a number between 0 and 100',
    'no': 'Vennligst oppgi et tall mellom 0 og 100',
    'se': 'Ange ett tal mellan 0 och 100',
    'dk': 'Angiv et tal mellem 0 og 100',
  },
  'invalidThresholdOrPercentage': {
    'en': 'The threshold or percentage is invalid',
    'no': 'Antallet eller prosenten er ugyldig',
    'se': 'Tröskeln eller procenten är ogiltig',
    'dk': 'Tærsklen eller procentdelen er ugyldig',
  },
  'invalidTablePosition': {
    'en': 'The table position must be a positive number',
    'no': 'Tabellplasseringen må være et positivt tall',
    'se': 'Tabellpositionen måste vara ett positivt tal',
    'dk': 'Tabelpositionen skal være et positivt tal',
  },
  'invalidThreshold': {
    'en': 'The threshold must be a positive number',
    'no': 'Antallet må være et positivt tall',
    'se': 'Tröskeln måste vara ett positivt tal',
    'dk': 'Tærsklen skal være et positivt tal',
  },
  'invalidThresholdOrAmount': {
    'en': 'Both threshold and amount must be positive numbers',
    'no': 'Både antall og beløp må være positive tall',
    'se': 'Både tröskeln och beloppet måste vara positiva tal',
    'dk': 'Både tærsklen og beløbet skal være positive tal',
  },


  // provide player info
  'provideNewPlayerInfo_birth_date': {
    'en': 'Provide birth date for',
    'no': 'Oppgi fødselsdato for',
    'se': 'Ange födelsedatum för',
    'dk': 'Angiv fødselsdato for',
  },
  'provideNewPlayerInfo_contract_expiration': {
    'en': 'Provide contract expiration for',
    'no': 'Oppgi utløpsdato på kontrakten til',
    'se': 'Ange kontraktens utgångsdatum för',
    'dk': 'Angiv kontraktens udløbsdato for',
  },
  'provideNewPlayerInfo_country_code': {
    'en': 'Provide nationality for',
    'no': 'Oppgi nasjonalitet for',
    'se': 'Ange nationalitet för',
    'dk': 'Angiv nationalitet for',
  },
  'provideNewPlayerInfo_market_value': {
    'en': 'Provide market value for',
    'no': 'Oppgi markedsverdi for',
    'se': 'Ange marknadsvärde för',
    'dk': 'Angiv markedsværdi for',
  },
  'provideNewPlayerInfo_clubValue': {
    'en': 'Provide internal market value',
    'no': 'Oppgi klubbens verdi for',
    'se': 'Ange intern marknadsvärde för',
    'dk': 'Angiv intern markedsværdi for',
  },
  'provideNewPlayerInfo_signingCost': {
    'en': 'Provide signing cost for',
    'no': 'Oppgi signeringskostnaden for',
    'se': 'Ange signaturkostnaden för',
    'dk': 'Angiv underskriftsomkostningerne for',
  },
  'provideNewPlayerInfo_salary': {
    'en': 'Provide salary cost for',
    'no': 'Oppgi lønnskostnaden for',
    'se': 'Ange lönekostnaden för',
    'dk': 'Angiv lønomkostningerne for',
  },
  'provideNewPlayerInfo_bonuses': {
    'en': 'Provide expected total bonus for',
    'no': 'Oppgi forventet total bonus for',
    'se': 'Ange förväntad total bonus för',
    'dk': 'Angiv forventet total bonus for',
  },
  'provideNewPlayerInfo_locality': {
    'en': 'Provide locality for',
    'no': 'Oppgi lokaliteten til',
    'se': 'Ange lokaliteten för',
    'dk': 'Angiv lokaliteten for',
  },
  'provideNewPlayerInfo_ABC': {
    'en': 'Provide classification for',
    'no': 'Oppgi klassifisering for',
    'se': 'Ange klassificering för',
    'dk': 'Angiv klassificering for',
  },
  'provideNewPlayerInfo_role': {
    'en': 'Provide new role for',
    'no': 'Oppgi lagrollen til',
    'se': 'Ange lagrollen för',
    'dk': 'Angiv holdrollen for',
  },


  // age categories
  'numberOfU21': {
    'en': 'Number of U21 players',
    'no': 'Antall U21-spillere',
    'se': 'Antal U21-spelare',
    'dk': 'Antal U21-spillere',
  },
  'numberOfU25': {
    'en': 'Number of U25 players',
    'no': 'Antall U25-spillere',
    'se': 'Antal U25-spelare',
    'dk': 'Antal U25-spillere',
  },
  'numberOfU29': {
    'en': 'Number of U29 players',
    'no': 'Antall U29-spillere',
    'se': 'Antal U29-spelare',
    'dk': 'Antal U29-spillere',
  },
  'numberOf29Plus': {
    'en': 'Number of 29+ players',
    'no': 'Antall 29+ spillere',
    'se': 'Antal 29+ spelare',
    'dk': 'Antal 29+ spillere',
  },
  'U21Info': {
    'en': 'A player is U21 for a given calendar year if the player is under 21 years old on the start of the year',
    'no': 'En spiller er U21 for et gitt kalenderår dersom spilleren er under 21 år ved starten av året',
    'se': 'En spelare är U21 för ett givet kalenderår om spelaren är under 21 år vid årets början',
    'dk': 'En spiller er U21 for et givet kalenderår, hvis spilleren er under 21 år ved årets start',
  },
  'U25Info': {
    'en': 'A player is U25 for a given calendar year if the player is under 25 years old on the start of the year',
    'no': 'En spiller er U25 for et gitt kalenderår dersom spilleren er under 25 år ved starten av året',
    'se': 'En spelare är U25 för ett givet kalenderår om spelaren är under 25 år vid årets början',
    'dk': 'En spiller er U25 for et givet kalenderår, hvis spilleren er under 25 år ved årets start',
  },
  'U29Info': {
    'en': 'A player is U29 for a given calendar year if the player is under 29 years old on the start of the year',
    'no': 'En spiller er U29 for et gitt kalenderår dersom spilleren er under 29 år ved starten av året',
    'se': 'En spelare är U29 för ett givet kalenderår om spelaren är under 29 år vid årets början',
    'dk': 'En spiller er U29 for et givet kalenderår, hvis spilleren er under 29 år ved årets start',
  },
  '29+Info': {
    'en': 'A player is 29+ for a given calendar year if the player is 29 years old or older on the start of the year',
    'no': 'En spiller er 29+ for et gitt kalenderår dersom spilleren er 29 år eller eldre ved starten av året',
    'se': 'En spelare är 29+ för ett givet kalenderår om spelaren är 29 år eller äldre vid årets början',
    'dk': 'En spiller er 29+ for et givet kalenderår, hvis spilleren er 29 år eller ældre ved årets start',
  },


  // position keys
  'G': {
    'en': 'Goalkeeper',
    'no': 'Keeper',
    'se': 'Målvakt',
    'dk': 'Målmand',
  },
  'D': {
    'en': 'Defender',
    'no': 'Forsvarer',
    'se': 'Försvarare',
    'dk': 'Forsvarer',
  },
  'M': {
    'en': 'Midfielder',
    'no': 'Midtbane',
    'se': 'Mittfältare',
    'dk': 'Midtbane',
  },
  'F': {
    'en': 'Attacker',
    'no': 'Angriper',
    'se': 'Angripare',
    'dk': 'Angriber',
  },

  // difficult to translate short positions, so only Norwegian will be translated
  'GK': {
    'en': 'GK',
    'no': 'K',
  },
  'LWB': {
    'en': 'LWB',
    'no': 'VVB',
  },
  'LB': {
    'en': 'LB',
    'no': 'VB',
  },
  'LCB': {
    'en': 'LCB',
    'no': 'VMS',
  },
  'CB': {
    'en': 'CB',
    'no': 'MS',
  },
  'RCB': {
    'en': 'RCB',
    'no': 'HMS',
  },
  'RB': {
    'en': 'RB',
    'no': 'HB',
  },
  'RWB': {
    'en': 'RWB',
    'no': 'HVB',
  },
  'CDM': {
    'en': 'CDM',
    'no': 'SDM',
  },
  'LCM': {
    'en': 'LCM',
    'no': 'VSM',
  },
  'CM': {
    'en': 'CM',
    'no': 'SM',
  },
  'RCM': {
    'en': 'RCM',
    'no': 'HSM',
  },
  'CAM': {
    'en': 'CAM',
    'no': 'SOM',
  },
  'LM': {
    'en': 'LM',
    'no': 'VM',
  },
  'LW': {
    'en': 'LW',
    'no': 'VV',
  },
  'RW': {
    'en': 'RW',
    'no': 'HV',
  },
  'RM': {
    'en': 'RM',
    'no': 'HM',
  },
  'LS': {
    'en': 'LS',
    'no': 'VS',
  },
  'S': {
    'en': 'S',
    'no': 'S',
  },
  'RS': {
    'en': 'RS',
    'no': 'HS',
  },


  // position group descriptions
  'S_info': {
    'en': 'Striker and central forward',
    'no': 'Spiss',
    'se': 'Anfallare',
    'dk': 'Angriber',
  },
  'W_info': {
    'en': 'Wide midfielder and attacking midfielder',
    'no': 'Kantspiller og offensiv midtbanespiller',
    'se': 'Yttermittfältare och offensiv mittfältare',
    'dk': 'Kantmidtbanespiller og offensiv midtbanespiller',
  },
  'CM_info': {
    'en': 'Central midfielder and defensive midfielder',
    'no': 'Sentral midtbanespiller og defensiv midtbanespiller',
    'se': 'Central mittfältare och defensiv mittfältare',
    'dk': 'Central midtbanespiller og defensiv midtbanespiller',
  },
  'FB_info': {
    'en': 'Fullback and wingback',
    'no': 'Sideback og vingback',
    'se': 'Back och ytterback',
    'dk': 'Back og wingback',
  },
  'CB_info': {
    'en': 'Center back',
    'no': 'Midtstopper',
    'se': 'Mittback',
    'dk': 'Midterforsvarer',
  },
  'GK_info': {
    'en': 'Goalkeeper',
    'no': 'Keeper',
    'se': 'Målvakt',
    'dk': 'Målmand',
  },


  // static team names
  'masterTeam': {
    'en': 'Master team',
    'no': 'Masterlag',
    'se': 'Masterlag',
    'dk': 'Masterhold',
  },
  'shadowTeam': {
    'en': 'Shadow team',
    'no': 'Skyggelag',
    'se': 'Skugglag',
    'dk': 'Skyggehold',
  },
  'ownTeam': {
    'en': 'Own team',
    'no': 'Eget lag',
    'se': 'Eget lag',
    'dk': 'Eget hold',
  },
  'academyTeam': {
    'en': 'Academy team',
    'no': 'Akademilag',
    'se': 'Akademilag',
    'dk': 'Akademhold',
  },


  // role keys
  'keyPlayer': {
    'en': 'Key player',
    'no': 'Nøkkelspiller',
    'se': 'Nyckelspelare',
    'dk': 'Nøglespiller',
  },
  'valuePlayer': {
    'en': 'Value player',
    'no': 'Verdispiller',
    'se': 'Värdespelare',
    'dk': 'Værdispiller',
  },
  'rotationPlayer': {
    'en': 'Rotation player',
    'no': 'Rotasjonsspiller',
    'se': 'Rotationspelare',
    'dk': 'Rotationspiller',
  },
  'loanedIn': {
    'en': 'Loaned in',
    'no': 'På innlån',
    'se': 'Lånad in',
    'dk': 'På leje',
  },
  'developmentPlayer': {
    'en': 'Development player',
    'no': 'Utviklingsspiller',
    'se': 'Utvecklingsspelare',
    'dk': 'Udviklingsspiller',
  },
  'reserve': {
    'en': 'Reserve',
    'no': 'Reserve',
    'se': 'Reserv',
    'dk': 'Reserve',
  },
  'longTermInjured': {
    'en': 'Long term injured',
    'no': 'Langtidsskadet',
    'se': 'Långtidsskadad',
    'dk': 'Langtidsskadet',
  },
  'loanedOut': {
    'en': 'Loaned out',
    'no': 'På utlån',
    'se': 'Lånad ut',
    'dk': 'På udlån',
  },
  'trialist': {
    'en': 'Trialist',
    'no': 'Hospitant',
    'se': 'Provspelare',
    'dk': 'Prøvespiller',
  },
  'talent': {
    'en': 'Talent',
    'no': 'Talent',
    'se': 'Talang',
    'dk': 'Talent',
  },
  'potential': {
    'en': 'Potential',
    'no': 'Potensial',
    'se': 'Potential',
    'dk': 'Potentiale',
  },


  // form keys
  'IN_FORM': {
    'en': 'In form',
    'no': 'I form',
    'se': 'I form',
    'dk': 'I form',
  },
  'NORMAL_FORM': {
    'en': 'Normal form',
    'no': 'Normal form',
    'se': 'Normal form',
    'dk': 'Normal form',
  },
  'OUT_OF_FORM': {
    'en': 'Out of form',
    'no': 'Ute av form',
    'se': 'Ute av form',
    'dk': 'Ude af form',
  },
  'NO_FORM': {
    'en': 'No form',
    'no': 'Ingen form',
    'se': 'Ingen form',
    'dk': 'Ingen form',
  },
  'IN_FORM_info': {
    'en': 'The player has a sufficiently higher form index than skill index.',
    'no': 'Spilleren har en tilstrekkelig høyere formindeks enn skillindeks.',
    'se': 'Spelaren har en tillräckligt högre formindex än skillindex.',
    'dk': 'Spilleren har en tilstrækkelig højere formindeks end skillindeks.',
  },
  'NORMAL_FORM_info': {
    'en': 'The player has a form index that corresponds with the skill index.',
    'no': 'Spilleren har en formindeks som samsvarer med skillindeksen.',
    'se': 'Spelaren har en formindex som motsvarar skillindexet.',
    'dk': 'Spilleren har en formindeks, der svarer til skillindekset.',
  },
  'OUT_OF_FORM_info': {
    'en': 'The player has a sufficiently lower form index than skill index.',
    'no': 'Spilleren har en tilstrekkelig lavere formindeks enn skillindeks.',
    'se': 'Spelaren har en tillräckligt lägre formindex än skillindex.',
    'dk': 'Spilleren har en tilstrækkelig lavere formindeks end skillindeks.',
  },
  'NO_FORM_info': {
    'en': 'The player has not played enough minutes for the form to be calculated.',
    'no': 'Spilleren har ikke spilt nok minutter til at formen beregnes.',
    'se': 'Spelaren har inte spelat tillräckligt med minuter för att formen ska beräknas.',
    'dk': 'Spilleren har ikke spillet nok minutter til, at formen kan beregnes.',
  },


  // playing time keys
  'KEY': {
    'en': 'Key player',
    'no': 'Nøkkelspiller',
    'se': 'Nyckelspelare',
    'dk': 'Nøglespiller',
  },
  'ROTATION': {
    'en': 'Rotation player',
    'no': 'Rotasjonsspiller',
    'se': 'Rotationspelare',
    'dk': 'Rotationspiller',
  },
  'RESERVE': {
    'en': 'Reserve',
    'no': 'Reserve',
    'se': 'Reserv',
    'dk': 'Reserve',
  },
  'UNKNOWN': {
    'en': 'Unknown',
    'no': 'Ukjent',
    'se': 'Okänd',
    'dk': 'Ukendt',
  },
  'KEY_info': {
    'en': 'The player has started at least 4 of the last 5 matches, or 7 of the last 10 matches where the player has been in the match squad.',
    'no': 'Spilleren har startet minst 4 av de siste 5 kampene, eller 7 av de siste 10 kampene der spilleren har vært med i kamptropppen.',
    'se': 'Spelaren har startat minst 4 av de senaste 5 matcherna, eller 7 av de senaste 10 matcherna där spelaren har varit i matchtruppen.',
    'dk': 'Spilleren har startet mindst 4 af de sidste 5 kampe, eller 7 af de sidste 10 kampe, hvor spilleren har været i kamptruppen.',
  },
  'ROTATION_info': {
    'en': 'The player has been involved in at least 3 of the last 5 matches, and played at least 20% of the minutes in the last 5 matches, where the player has been in the match squad.',
    'no': 'Spilleren har vært på banen i minst 3 av de siste 5 kampene, og spilt minst 20% av minuttene i de siste 5 kampene, der spilleren har vært med i kamptroppen.',
    'se': 'Spelaren har varit med i minst 3 av de senaste 5 matcherna, och spelat minst 20% av minuterna i de senaste 5 matcherna, där spelaren har varit i matchtruppen.',
    'dk': 'Spilleren har været involveret i mindst 3 af de sidste 5 kampe, og spillet mindst 20% af minutterne i de sidste 5 kampe, hvor spilleren har været i kamptruppen.',
  },
  'RESERVE_info': {
    'en': 'The player has been in the match squad in at least 1 of the last 10 matches, without meeting the other requirements.',
    'no': 'Spilleren har vært med i kamptroppen i minst 1 av de siste 10 kampene, uten å innfri de øvrige kravene.',
    'se': 'Spelaren har varit i matchtruppen i minst 1 av de senaste 10 matcherna, utan att uppfylla de andra kraven.',
    'dk': 'Spilleren har været i kamptruppen i mindst 1 af de sidste 10 kampe, uden at opfylde de andre krav.',
  },
  'UNKNOWN_info': {
    'en': 'The player has not been in the match squad in the last 10 matches. Players who change clubs will have this status until they are in their first match squad in a league with event data.',
    'no': 'Spilleren har ikke vært med i kamptroppen i sin nåværende klubb i de siste 10 kampene. Spillere som bytter klubb vil ha denne statusen inntil de er med i sin første kamptropp i en liga med event data',
    'se': 'Spelaren har inte varit i matchtruppen i sin nuvarande klubb i de senaste 10 matcherna. Spelare som byter klubb kommer att ha denna status tills de är i sin första matchtrupp i en liga med event data.',
    'dk': 'Spilleren har ikke været i kamptruppen i sin nuværende klub i de sidste 10 kampe. Spillere, der skifter klub, vil have denne status, indtil de er i deres første kamptrup i en liga med event data.',
  },


  // role configs aggregation options
  'priorityAggregation': {
    'en': 'Priority',
    'no': 'Prioritet',
    'se': 'Prioritet',
    'dk': 'Prioritet',
  },
  'maxAggregation': {
    'en': 'Max',
    'no': 'Maks',
    'se': 'Max',
    'dk': 'Maks',
  },
  'averageAggregation': {
    'en': 'Average',
    'no': 'Snitt',
    'se': 'Snitt',
    'dk': 'Snit',
  },
  'confidenceAggregation': {
    'en': 'Precision',
    'no': 'Presisjon',
    'se': 'Precision',
    'dk': 'Præcision',
  },
  'priorityAggregationInfo': {
    'en': 'Priority means that the first role index available among the options will be displayed. If the player has a role index for the first role, this will be displayed. If not, the role index for the next role will be displayed, and so on.',
    'no': 'Prioritet betyr at den første rolleindeksen som er tilgjengelig blant alternativene vil vises. Dersom spilleren har en rolleindeks for den første rollen, vil denne vises. Hvis ikke, vises rolleindeksen for den neste rollen, og så videre.',
    'se': 'Prioritet innebär att den första rollindexen som är tillgänglig bland alternativen kommer att visas. Om spelaren har en rollindex för den första rollen kommer detta att visas. Om inte kommer rollindexet för nästa roll att visas, och så vidare.',
    'dk': 'Prioritet betyder, at den første rolleindeks, der er tilgængelig blandt mulighederne, vil blive vist. Hvis spilleren har en rolleindeks for den første rolle, vil denne blive vist. Hvis ikke vil rolleindekset for den næste rolle blive vist, og så videre.',
  },
  'maxAggregationInfo': {
    'en': 'Max means that the highest role index among the options will be displayed.',
    'no': 'Maks betyr at den høyeste rolleindeksen blant alternativene vil vises.',
    'se': 'Max innebär att den högsta rollindexen bland alternativen kommer att visas.',
    'dk': 'Maks betyder, at den højeste rolleindeks blandt mulighederne vil blive vist.',
  },
  'averageAggregationInfo': {
    'en': 'Average means that the average of the role indexes for all options will be displayed.',
    'no': 'Snitt betyr at gjennomsnittet til rolleindeksene for alle alternativene vil vises.',
    'se': 'Snitt innebär att genomsnittet av rollindexen för alla alternativ kommer att visas.',
    'dk': 'Snit betyder, at gennemsnittet af rolleindekserne for alle muligheder vil blive vist.',
  },
  'confidenceAggregationInfo': {
    'en': 'Confidence means that the role index with the highest confidence will be displayed. If multiple role indexes have the same confidence, the first will be displayed.',
    'no': 'Presisjon betyr at rolleindeksen med høyest presisjon vil vises. Dersom flere rolleindekser har samme presisjon, vil den første vises.',
    'se': 'Precision innebär att rollindexet med högst precision kommer att visas. Om flera rollindex har samma precision kommer den första att visas.',
    'dk': 'Præcision betyder, at rolleindekset med den højeste præcision vil blive vist. Hvis flere rolleindekser har samme præcision, vil den første blive vist.',
  },


  // login
  'email': {
    'en': 'Email',
    'no': 'E-post',
    'se': 'E-post',
    'dk': 'E-mail',
  },
  'provideEmail': {
    'en': 'Provide email',
    'no': 'Skriv inn e-post',
    'se': 'Ange e-post',
    'dk': 'Angiv e-mail',
  },
  'emailSent': {
    'en': 'We have sent you an email',
    'no': 'Vi har sendt deg en e-post',
    'se': 'Vi har skickat dig ett e-postmeddelande',
    'dk': 'Vi har sendt dig en e-mail',
  },
  'invalidEmail': {
    'en': 'Invalid email',
    'no': 'Ugyldig e-post',
    'se': 'Ogiltig e-post',
    'dk': 'Ugyldig e-mail',
  },
  'invalidEmailProvided': {
    'en': 'The email provided is invalid',
    'no': 'E-posten som er oppgitt er ugyldig',
    'se': 'Den angivna e-postadressen är ogiltig',
    'dk': 'Den angivne e-mail er ugyldig',
  },
  'fokusEmailRestricted': {
    'en': "Emails starting with 'fokus' are not allowed",
    'no': "E-poster som starter med 'fokus' er ikke tillatt",
    'se': "E-postadresser som börjar med 'fokus' är inte tillåtna",
    'dk': "E-mails, der starter med 'fokus', er ikke tilladt",
  },
  'login': {
    'en': 'Log in',
    'no': 'Logg inn',
    'se': 'Logga in',
    'dk': 'Log ind',
  },
  'logout': {
    'en': 'Sign out',
    'no': 'Logg ut',
    'se': 'Logga ut',
    'dk': 'Log ud',
  },
  'password': {
    'en': 'Password',
    'no': 'Passord',
    'se': 'Lösenord',
    'dk': 'Adgangskode',
  },
  'forgotPassword?': {
    'en': 'Forgot password?',
    'no': 'Glemt passord?',
    'se': 'Glömt lösenord?',
    'dk': 'Glemt adgangskode?',
  },
  'resetPassword': {
    'en': 'Reset password',
    'no': 'Tilbakestill passord',
    'se': 'Återställ lösenord',
    'dk': 'Nulstil adgangskode',
  },
  'invalidLoginInput': {
    'en': 'Invalid email or password',
    'no': 'Ugyldig e-post eller passord',
    'se': 'Ogiltig e-postadress eller lösenord',
    'dk': 'Ugyldig e-mail eller adgangskode',
  },


  // home
  'ourSolution': {
    'en': 'Our solution',
    'no': 'Vår løsning',
    'se': 'Vår lösning',
    'dk': 'Vores løsning',
  },
  'caseStudy': {
    'en': 'Case study',
    'no': 'Case-studie',
    'se': 'Fallstudie',
    'dk': 'Case-studie',
  },
  'about': {
    'en': 'About us',
    'no': 'Om oss',
    'se': 'Om oss',
    'dk': 'Om os',
  },
  'getStarted': {
    'en': 'Get started',
    'no': 'Kom i gang',
    'se': 'Kom igång',
    'dk': 'Kom i gang',
  },
  'oneClub': {
    'en': 'One club.',
    'no': 'En klubb.',
    'se': 'En klubb.',
    'dk': 'En klub.',
  },
  'onePlatform': {
    'en': 'One platform.',
    'no': 'En plattform.',
    'se': 'En plattform.',
    'dk': 'En platform.',
  },
  'bringClub': {
    'en': 'Bring club management, sporting director and scouts together.',
    'no': 'Samle klubbledelse, sportssjef og speidere i ett og samme system.',
    'se': 'Samla klubbledning, sportchef och scouter i ett och samma system.',
    'dk': 'Samle klubledelse, sportschef og spejdere i ét og samme system.',
  },
  'aHolistic': {
    'en': 'A holistic club management system powered by advanced data and tailored insights.',
    'no': 'Et helhetlig system for sportslig klubbdrift og spillerlogistikk - drevet av data.',
    'se': 'Ett komplett system för sportlig klubbdrift och spelarlogistik - drivet av data.',
    'dk': 'Et komplet system til sportslig klubdrift og spillerlogistik - drevet af data.',
  },
  'trustedBy': {
    'en': 'TRUSTED BY TOP NORWEGIAN CLUBS',
    'no': 'FORETRUKKET AV NORGES LEDENDE KLUBBER',
    'se': 'FÖREDRAGEN AV NORGES LEDANDE KLUBBAR',
    'dk': 'FORETRUKKET AF NORGES LEDENDE KLUBBER',
  },
  'whatTheBiggest': {
    'en': 'What the biggest clubs have.',
    'no': 'Det de største klubbene har.',
    'se': 'Det de största klubbarna har.',
    'dk': 'Det de største klubber har.',
  },
  'nowForYou': {
    'en': 'Now for you.',
    'no': 'Nå for dere.',
    'se': 'Nu för er.',
    'dk': 'Nu for jer.',
  },
  'fokusIsMore1': {
    'en': 'Data-driven decision-making is no longer reserved for the wealthiest clubs.',
    'no': 'Datadrevne beslutninger er ikke lenger forbeholdt de rikeste klubbene.',
    'se': 'Beslut baserade på data är inte längre reserverade för de rikaste klubbarna.',
    'dk': 'Data-drevne beslutninger er ikke længere forbeholdt de rigeste klubber.',
  },
  'fokusIsMore2': {
    'en': 'We help clubs of all sizes work smarter with player recruitment.',
    'no': 'Vi hjelper klubber i alle størrelser med spillerlogistikk.',
    'se': 'Vi hjälper klubbar i alla storlekar med spelarrekrytering.',
    'dk': 'Vi hjælper klubber i alle størrelser med spillerrekruttering.',
  },
  'strategy': {
    'en': 'Strategy',
    'no': 'Strategi',
    'se': 'Strategi',
    'dk': 'Strategi',
  },
  'strategyTitle': {
    'en': 'Implement a robust strategy',
    'no': 'Implementer en robusst strategi',
    'se': 'Implementera en robust strategi',
    'dk': 'Implementer en robust strategi',
  },
  'strategySubTitle': {
    'en': 'Clear leadership and continuity is key to success in the ever-changing football landscape.',
    'no': 'Tydelig lederskap og kontinuitet er nøkkelen til suksess i et stadig skiftende fotballandskap.',
    'se': 'Tydligt ledarskap och kontinuitet är nyckeln till framgång i den ständigt föränderliga fotbollslandskapet.',
    'dk': 'Tydelig lederskab og kontinuitet er nøglen til succes i det stadig skiftende fodboldlandskab.',
  },
  'strategyBullet1': {
    'en': 'Get instant insights into squad composition and development.',
    'no': 'Få umiddelbare innsikter i troppssammensetning og utvikling.',
    'se': 'Få omedelbar insikt i truppsammansättning och utveckling.',
    'dk': 'Få øjeblikkelig indsigt i trupkomposition og udvikling.',
  },
  'strategyBullet2': {
    'en': 'Establish club ownership of scouting activities and transfer plans, ensuring robustness in the face of staff changes.',
    'no': 'Etablér klubbeierskap til speideraktiviteter og overgangsplaner for å sikre robusthet ved endringer i personell.',
    'se': 'Etablera klubbägarskap till scoutaktiviteter och övergångsplaner för att säkerställa robusthet vid personalförändringar.',
    'dk': 'Etabler klub ejerskab af scouting aktiviteter og transferplaner for at sikre robusthed ved personaleændringer.',
  },
  'scoutingTitle': {
    'en': 'Discover new talent',
    'no': 'Finn nye spillere',
    'se': 'Upptäck nya talanger',
    'dk': 'Opdag nye talenter',
  },
  'scoutingSubTitle': {
    'en': 'Gain an edge in the transfer market by identifying the ideal players for your club before anyone else.',
    'no': 'Få en fordel i overgangsmarkedet ved å identifisere ideelle spillere for klubben din før noen andre.',
    'se': 'Få en fördel på transfermarknaden genom att identifiera de idealiska spelarna för din klubb innan någon annan.',
    'dk': 'Få en fordel på transfermarkedet ved at identificere de ideelle spillere til din klub før nogen anden.',
  },
  'scoutingBullet1': {
    'en': 'Leverage advanced data models to quickly find the best statistical fits.',
    'no': 'Bruk avanserte datamodeller for å finne de beste statistiske matchene raskt.',
    'se': 'Använd avancerade datamodeller för att snabbt hitta de bästa statistiska matchningarna.',
    'dk': 'Brug avancerede datamodeller til hurtigt at finde de bedste statistiske matcher.',
  },
  'scoutingBullet2': {
    'en': 'Track and compare player development and performance.',
    'no': 'Følg med på utvikling og prestasjoner over tid.',
    'se': 'Följ och jämför spelarutveckling och prestation.',
    'dk': 'Følg og sammenlign spillerudvikling og præstation.',
  },
  'decisions': {
    'en': 'Decisions',
    'no': 'Beslutninger',
    'se': 'Beslut',
    'dk': 'Beslutninger',
  },
  'decisionsTitle': {
    'en': 'Make well-informed decisions',
    'no': 'Ta informerte beslutninger',
    'se': 'Fatta välgrundade beslut',
    'dk': 'Tag velinformerede beslutninger',
  },
  'decisionsSubTitle': {
    'en': 'Combine data with your scouting expertise for a powerful, integrated approach to signings and squad management.',
    'no': 'Bruk data sammen med klubbens speiderkompetanse til å ta bedre avgjørelser.',
    'se': 'Kombinera data med din scoutexpertis för ett kraftfullt, integrerat tillvägagångssätt för signeringar och truphantering.',
    'dk': 'Kombiner data med din scouting ekspertise for en kraftfuld, integreret tilgang til signeringer og truphåndtering.',
  },
  'decisionsBullet1': {
    'en': 'Streamline your scouting workflow.',
    'no': 'Organiser speiderarbeidet for å frigjøre mer tid til å se spillere live.',
    'se': 'Effektivisera ditt scoutingsflöde.',
    'dk': 'Strømline din scouting arbejdsgang.',
  },
  'decisionsBullet2': {
    'en': 'Reduce the risk of unsuccessful signings with data insights.',
    'no': 'Reduser risikoen for mislykkede signeringer med datainnsikt.',
    'se': 'Minska risken för misslyckade signeringar med datainsikter.',
    'dk': 'Reducer risikoen for mislykkede signeringer med dataindsigter.',
  },
  'glimtUsesFokus': {
    'en': 'FK Bodø/Glimt uses Fokus to organize their recruitment operations.',
    'no': 'FK Bodø/Glimt bruker Fokus til å organisere spillerlogistikken.',
    'se': 'FK Bodø/Glimt använder Fokus för att organisera sin rekryteringsverksamhet.',
    'dk': 'FK Bodø/Glimt bruger Fokus til at organisere deres rekrutteringsoperationer.',
  },
  'sakariassenQuote1': {
    'en': '"Fokus is our ',
    'no': '"Fokus er vårt ',
    'se': '"Fokus är vår ',
    'dk': '"Fokus er vores ',
  },
  'sakariassenQuote2': {
    'en': 'most important tool for scouting and squad management',
    'no': 'viktigste verktøy for spillerlogistikk',
    'se': 'viktigaste verktyg för scouting och truphantering',
    'dk': 'vigtigste værktøj til scouting og truphåndtering',
  },
  'sakariassenQuote3': {
    'en': '. We are now better informed when making a signing."',
    'no': '. Vi er nå bedre informert når vi gjør en signering."',
    'se': '. Vi är nu bättre informerade när vi gör en signering."',
    'dk': '. Vi er nu bedre informeret, når vi foretager en signering."',
  },
  'sportingDirector': {
    'en': 'Sporting Director',
    'no': 'Sportssjef',
    'se': 'Sportchef',
    'dk': 'Sportschef',
  },
  'albertDescription': {
    'en': 'Albert Grønbæk celebrates scoring against Ajax in February 2024. Months later he became Bodø/Glimt´s most successful sale in history when he joined Rennes for a reported fee of 15 million Euros.',
    'no': 'Albert Grønbæk feirer scoring mot Ajax i februar 2024. Måneder senere ble han Bodø/Glimts mest vellykkede salg i historien da han ble solgt til Rennes for en rapportert sum på 15 millioner euro. ',
    'se': 'Albert Grønbæk firar att ha gjort mål mot Ajax i februari 2024. Månader senare blev han Bodø/Glimts mest framgångsrika försäljning i historien när han gick till Rennes för en rapporterad summa på 15 miljoner euro.',
    'dk': 'Albert Grønbæk fejrer scoring mod Ajax i februar 2024. Måneder senere blev han Bodø/Glimts mest succesfulde salg i historien, da han skiftede til Rennes for en rapporteret sum på 15 millioner euro.',
  },
  'weHelpClubs': {
    'en': 'We help clubs work smarter.',
    'no': 'Vi hjelper klubber med å jobbe smartere.',
    'se': 'Vi hjälper klubbar att arbeta smartare.',
    'dk': 'Vi hjælper klubber med at arbejde smartere.',
  },
  'howWeStarted': {
    'en': 'How we started',
    'no': 'Hvordan vi startet',
    'se': 'Hur vi började',
    'dk': 'Hvordan vi startede',
  },
  'howWeStartedParagraph1': {
    'en': 'For years, clubs like Liverpool and Midtjylland have been renowned for outsmarting their competition in the transfer market through data-driven strategies.',
    'no': 'I årevis har klubber som Liverpool og Midtjylland vært kjent for å overliste konkurrentene sine på overgangsmarkedet gjennom datadrevne strategier.',
    'se': 'I åratal har klubbar som Liverpool och Midtjylland varit kända för att överlista sina konkurrenter på transfermarknaden genom datadrivna strategier.',
    'dk': 'I årevis har klubber som Liverpool og Midtjylland været kendt for at overliste konkurrenterne på transfermarkedet gennem datadrevne strategier.',
  },
  'howWeStartedParagraph2': {
    'en': 'Inspired by these success stories, our journey began with three students pursuing a master’s degree in Artificial Intelligence. In their thesis they developed a state of the art machine learning model to identify top replacement players.',
    'no': 'Inspirert av disse suksesshistoriene, startet vår reise med tre masterstudenter innen kunstig intelligens. Som en del av masteroppgaven utviklet de en avansert maskinlæringsmodell for å identifisere de beste erstatterne i markedet.',
    'se': 'Inspirerade av dessa framgångshistorier började vår resa med tre studenter som studerade en master i artificiell intelligens. I sitt examensarbete utvecklade de en toppmodern maskininlärningsmodell för att identifiera de bästa ersättarna.',
    'dk': 'Inspiret af disse succes historier, startede vores rejse med tre studerende, der læste en kandidatgrad i kunstig intelligens. I deres speciale udviklede de en topmoderne maskinlæringsmodel til at identificere de bedste erstatningsspillere.',
  },
  'whatWeHaveLearned': {
    'en': 'What we have learned',
    'no': 'Hva vi har lært',
    'se': 'Vad vi har lärt oss',
    'dk': 'Hvad vi har lært',
  },
  'whatWeHaveLearnedParagraph1': {
    'en': 'To fully harness the power of advanced data models, clubs first need an organized approach to player logistics.',
    'no': 'For å utnytte kraften i avanserte datamodeller fullt ut, trenger klubber først en organisert tilnærming til spillerlogistikk.',
    'se': 'För att fullt ut utnyttja kraften i avancerade datamodeller behöver klubbar först en organiserad strategi för spelarlogistik.',
    'dk': 'For at udnytte kraften i avancerede datamodeller fuldt ud, har klubber først brug for en organiseret tilgang til spillerlogistik.',
  },
  'whatWeHaveLearnedParagraph2': {
    'en': 'That’s why we’ve created a tool that’s more than just a player database - it’s a new way of working.',
    'no': 'Derfor har vi utviklet et verktøy som er mer enn bare en spillerdatabase – det er en ny måte å jobbe på',
    'se': 'Därför har vi skapat ett verktyg som är mer än bara en spelardatabas - det är ett nytt sätt att arbeta.',
    'dk': 'Derfor har vi udviklet et værktøj, der er mere end bare en spillerdatabase - det er en ny måde at arbejde på.',
  },
  'whereWeAreGoing': {
    'en': 'Where we are going',
    'no': 'Hvor vi skal',
    'se': 'Vart vi är på väg',
    'dk': 'Hvor vi skal hen',
  },
  'whereWeAreGoingParagraph1': {
    'en': 'Data in football has come to stay. Together with our clients, we continuously improve our service, empowering clubs of all sizes to maximize their potential by working smarter.',
    'no': 'Data i fotball har kommet for å bli. Sammen med våre kunder forbedrer vi kontinuerlig tjenesten vår, slik at klubber i alle størrelser kan maksimere potensialet sitt ved å jobbe smartere.',
    'se': 'Data i fotboll har kommit för att stanna. Tillsammans med våra kunder förbättrar vi kontinuerligt vår tjänst, vilket ger klubbar i alla storlekar möjlighet att maximera sin potential genom att arbeta smartare.',
    'dk': 'Data i fodbold er kommet for at blive. Sammen med vores kunder forbedrer vi løbende vores service, så klubber i alle størrelser kan maksimere deres potentiale ved at arbejde smartere.',
  },
  'finalQuote': {
    'en': 'As data becomes essential, we’ll give you the tools to stay ahead.',
    'no': 'Data i fotball har kommet for å bli - vi gir deg verktøyene du trenger for å lykkes.',
    'se': 'Data i fotboll har kommit för att stanna - vi ger dig verktygen du behöver för att lyckas.',
    'dk': 'Data i fodbold er kommet for at blive - vi giver dig værktøjerne til at lykkes.',
  },
  'interested': {
    'en': 'Interested?',
    'no': 'Interessert?',
    'se': 'Intresserad?',
    'dk': 'Interesseret?',
  },
  'weAreHappy': {
    'en': 'We´re happy to demonstrate how we can support your club.',
    'no': 'Vi viser deg gjerne hvordan vi kan hjelpe klubben deres.',
    'se': 'Vi visar dig gärna hur vi kan stödja din klubb.',
    'dk': 'Vi viser dig gerne, hvordan vi kan støtte din klub.',
  },
  'reachOut': {
    'en': 'Reach out and we´ll get you started on a trial.',
    'no': 'Ta kontakt med oss for å komme i gang med en prøveperiode.',
    'se': 'Kontakta oss så hjälper vi dig att komma igång med en provperiod.',
    'dk': 'Kontakt os, og vi hjælper dig i gang med en prøveperiode.',
  },
  'privacyPolicy': {
    'en': 'Privacy Policy',
    'no': 'Personvernerklæring',
    'se': 'Integritetspolicy',
    'dk': 'Privatlivspolitik',
  },
  'privacyPolicyText': {
    'en': 'This site does not use cookies and does not process, collect, or store any personal information or usage data. If this changes in the future, we will update this privacy policy in advance.',
    'no': 'Denne nettsiden bruker ikke informasjonskapsler og samler ikke inn noe personlig informasjon eller annen data. Hvis dette endres i fremtiden, vil vi oppdatere denne personvernerklæringen på forhånd.',
    'se': 'Denna webbplats använder inte cookies och samlar inte in någon personlig information eller annan data. Om detta ändras i framtiden kommer vi att uppdatera denna integritetspolicy i förväg.',
    'dk': 'Denne hjemmeside bruger ikke cookies og indsamler ikke personlige oplysninger eller anden data. Hvis dette ændres i fremtiden, vil vi opdatere denne privatlivspolitik i god tid.',
  },
  'privacyPolicyPlatform': {
    'en': 'If you are a user of our platform, or are considering becoming one, we have a comprehensive Privacy Policy specific to the platform. We are committed to transparency and data protection, and we are happy to provide this policy upon request.',
    'no': 'Hvis du er bruker av plattformen vår, eller vurderer å bli det, har vi en omfattende personvernerklæring spesifikk for plattformen. Vi er opptatt av åpenhet og databeskyttelse, og vi sender deg gjerne denne erklæringen om ønskelig.',
    'se': 'Om du är användare av vår plattform, eller överväger att bli det, har vi en omfattande integritetspolicy specifik för plattformen. Vi är engagerade i öppenhet och dataskydd, och vi skickar gärna denna policy vid förfrågan.',
    'dk': 'Hvis du er bruger af vores platform, eller overvejer at blive det, har vi en omfattende privatlivspolitik specifik for platformen. Vi er forpligtet til gennemsigtighed og databeskyttelse, og vi sender dig gerne denne politik ved forespørgsel.',
  },
  'contact': {
    'en': 'Contact',
    'no': 'Kontakt',
    'se': 'Kontakt',
    'dk': 'Kontakt',
  },
  'photoCredit': {
    'en': 'Photo: Kent Even Grundstad',
    'no': 'Foto: Kent Even Grundstad',
    'se': 'Foto: Kent Even Grundstad',
    'dk': 'Foto: Kent Even Grundstad',
  },
  'customData': {
    'en': 'Custom data',
    'no': 'Egen data',
    'se': 'Egna data',
    'dk': 'Egne data',
  },
  'customDataMessageHeadline': {
    'en': 'Your data here',
    'no': 'Deres data her',
    'se': 'Dina data här',
    'dk': 'Dine data her',
  },
  'customDataMessageText': {
    'en': 'Here we can show data owned and administrated by your own club, by integrating to your existing data infrastructure/API.',
    'no': 'Her kan vi vise data som eies og administreres av din egen klubb, ved å integrere mot deres eksisterende datainfrastruktur/API.',
    'se': 'Här kan vi visa data som ägs och administreras av din egen klubb, genom att integrera mot er befintliga datainfrastruktur/API.',
    'dk': 'Her kan vi vise data, der ejes og administreres af din egen klub, ved at integrere til din eksisterende datainfrastruktur/API.',
  },
};
