import './history.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DescriptionIcon from '@mui/icons-material/Description';
import MessageIcon from '@mui/icons-material/Message';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BlockIcon from '@mui/icons-material/Block';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap } from '../../types';
import { getDisplayPlayerName } from '../../utils/playerUtils';
import { staticTeamKeys } from '../../static/propertyValues';
import { getShortUserName, userHasScoutAccess } from '../../utils/userUtils';
import { dateStringToLocaleFormat } from '../../utils/dateUtils';


interface PlatformHistoryProps {
  platformHistory: StringToAnyMap[];
  handlePlayerClick: (activity: StringToAnyMap, isReport: boolean) => void;
  handleSquadOrTeamClick: (teamOrSquadId: string, isSuqad: boolean | undefined) => void;
}

export const PlatformHistory: React.FC<PlatformHistoryProps> = ({
  platformHistory,
  handlePlayerClick,
  handleSquadOrTeamClick,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);

  const teams = useRecoilValue(teamsState) ?? {};
  const squads = useRecoilValue(squadsState) ?? {};

  const shouldShowDate = (index: number) => {
    if (index === 0) return true;

    const previousDate = platformHistory[index - 1]['date'].split('T')[0];
    const currentDate = platformHistory[index]['date'].split('T')[0];

    const previousUser = platformHistory[index - 1]['userEmail'];
    const currentUser = platformHistory[index]['userEmail'];

    return previousDate !== currentDate || previousUser !== currentUser;
  };


  const getTeamName = (teamId: string) => {
    if (!userConfig) return '';

    if (staticTeamKeys.includes(teamId)) {
      return translate(teamId, userConfig.language);
    }

    if (teams[teamId]) {
      return teams[teamId]['name'];
    }

    if (squads[teamId]) {
      return squads[teamId]['name'];
    }

    return null;
  };


  const getHistoryInfoText = (action: StringToAnyMap, actionType: string, isCurrentUser: boolean) => {
    if (!userConfig) return <div />;

    const userName = isCurrentUser
      ? translate('you', userConfig.language)
      : allUsersWithSameClub[action['userEmail']]
        ? getShortUserName(allUsersWithSameClub[action['userEmail']].name)
        : translate('unknownUser', userConfig.language);

    if (actionType === 'report') {

      const actionText = action['reportType'] === 'Attachment'
        ? translate('<someone>uploadedAnAttachment', userConfig.language)
        : translate('<someone>leftA<reportType>', userConfig.language) + ' ' + translate(action['reportType'], userConfig.language, true);

      const text = userName
        + ' '
        + actionText
        + ' '
        + translate('<leftAReport>on<player>', userConfig.language)
        + ' '
        + getDisplayPlayerName(action['playerName'], 16);

      return (
        <div className='platform-history-text-clickable' onClick={() => handlePlayerClick(action, true)}>
          {text}
        </div>
      );
    }

    const playerNameComponent = (
      <div className='platform-history-text-clickable platform-history-text-nowrap' onClick={() => handlePlayerClick(action, false)}>
        {getDisplayPlayerName(action['playerName'], 16)}
      </div>
    );

    const containerClassName = 'platform-history-text' + (!isCurrentUser ? ' platform-history-text-other-user' : '');

    if (actionType === 'playerActivity') {

      const fromName: string | undefined = getTeamName(action['fromTeamId']) ?? action['fromTeamName'];
      const toName: string | undefined = getTeamName(action['toTeamId']) ?? action['toTeamName'];

      const fromNameComponent = fromName
        ? (
          <div
            className='platform-history-text-clickable platform-history-text-nowrap'
            onClick={() => handleSquadOrTeamClick(action.fromTeamId, undefined)}
          >
            {fromName}
          </div>
        ) : null;

      const toNameComponent = toName
        ? (
          <div
            className='platform-history-text-clickable platform-history-text-nowrap'
            onClick={() => handleSquadOrTeamClick(action.toTeamId, undefined)}
          >
            {toName}
          </div>
        ) : null;

      if (fromName && toName) {
        return (
          <div className={containerClassName}>
            {userName}
            &nbsp;
            {translate('<someone>moved<player>', userConfig.language)}
            {playerNameComponent}
            {translate('from<team>', userConfig.language)}
            {fromNameComponent}
            {translate('to<team>', userConfig.language)}
            {toNameComponent}
          </div>
        );
      }
      else if (fromName) {
        return (
          <div className={containerClassName}>
            {userName}
            &nbsp;
            {translate('<someone>removed<player>', userConfig.language)}
            {playerNameComponent}
            {translate('from<team>', userConfig.language)}
            {fromNameComponent}
          </div>
        );
      }
      else if (toName) {
        return (
          <div className={containerClassName}>
            {userName}
            &nbsp;
            {translate('<someone>added<player>', userConfig.language)}
            {playerNameComponent}
            {translate('on<team>', userConfig.language)}
            {toNameComponent}
          </div>
        );
      }

      return null;
    }

    // remaining action types will all be team/squad actions
    const teamOrSquadName = staticTeamKeys.includes(action['teamOrSquadName'])
      ? translate(action['teamOrSquadName'], userConfig.language)
      : action['teamOrSquadName'];

    const teamOrSquadComponent = (
      <div
        className='platform-history-text-clickable platform-history-text-nowrap'
        onClick={() => handleSquadOrTeamClick(action.teamOrSquadId, action.isSuqad)}
      >
        {teamOrSquadName}
        {actionType === 'comment' && ':'}
      </div>
    );

    if (actionType === 'comment') {
      return (
        <div className={containerClassName}>
          {userName}
          &nbsp;
          {translate('<someone>leftA<reportType>', userConfig.language)}
          &nbsp;
          {translate('comment', userConfig.language)}
          &nbsp;
          {translate('on<team>', userConfig.language)}
          {teamOrSquadComponent}
          {action['action']['comment']}
        </div>
      );
    }

    if (actionType === 'archive') {
      return (
        <div className={containerClassName}>
          {userName}
          &nbsp;
          {translate('<someone>archived<teamOrSquad>', userConfig.language)}
          {teamOrSquadComponent}
        </div>
      );
    }

    if (actionType === 'reset') {
      return (
        <div className={containerClassName}>
          {userName}
          &nbsp;
          {translate('<someone>reset<teamOrSquad>', userConfig.language)}
          {teamOrSquadComponent}
        </div>
      );
    }

    if (actionType === 'create') {
      return (
        <div className={containerClassName}>
          {userName}
          &nbsp;
          {translate('<someone>created<teamOrSquad>', userConfig.language)}
          {teamOrSquadComponent}
        </div>
      );
    }

    if (actionType === 'editFormation') {
      return (
        <div className={containerClassName}>
          {userName}
          &nbsp;
          {translate('<someone>editedTheFormationFor<team>', userConfig.language)}
          {teamOrSquadComponent}
          {translate('<fromSomething>to<something>', userConfig.language)}
          &nbsp;
          {action['action']['newFormation']}
        </div>
      );
    }

    return null;
  };


  const scrollToBottom = () => {
    const commentField = document.querySelector('.platform-history-action-section');
    if (commentField) {
      commentField.scrollTop = commentField.scrollHeight;
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100);

    return () => clearTimeout(timer);
  }, [platformHistory]);


  if (!userHasScoutAccess(userConfig)) {
    return (
      <div className='full-size flex-center'>
        <BlockIcon style={{ fontSize: 100, color: '#ffffff22', paddingBottom: 30 }} />
      </div>
    );
  }

  else {
    return (
      <div className='history-container'>

        <div className={'history-action-section history-action-section-show platform-history-action-section'}>
          {platformHistory && platformHistory.map((action, index) => {

            const actionType = action['reportType'] ? 'report' : (action['action'] ? action['action']['actionType'] : 'playerActivity');

            const isCurrentUser = userConfig !== null && action['userEmail'] === userConfig.email;

            return (
              <div
                key={index}
                className='history-action platform-history-action'
              >

                {shouldShowDate(index) && (
                  <div className='history-date-row platform-history-date-row'>

                    {isCurrentUser && <div className='history-date-large-divider'>&nbsp;</div>}

                    <div className={'history-date-large' + (isCurrentUser ? ' history-date-current-user' : '')}>
                      {dateStringToLocaleFormat(action['date'].split('T')[0])}
                    </div>

                    {!isCurrentUser && <div className='history-date-large-divider'>&nbsp;</div>}

                  </div>
                )}

                <div
                  className={
                    'platform-history-info-text'
                    + (isCurrentUser ? ' platform-history-info-text-current-user' : '')
                    + (actionType === 'report'
                      ? (isCurrentUser ? ' platform-history-report-info-current-user' : ' platform-history-report-info-other-user')
                      : '')
                  }
                >
                  <div className='platform-history-text-container'>
                    {getHistoryInfoText(action, actionType, isCurrentUser)}

                    {actionType === 'report' && (
                      <div
                        className={
                          'platform-history-report-icon' +
                          (isCurrentUser ? ' platform-history-report-icon-current-user' : ' platform-history-report-icon-other-user')
                        }
                      >
                        {action['reportType'] === 'ScoutingReport' && (
                          <PlagiarismIcon
                            style={{ fontSize: 22, paddingLeft: isCurrentUser ? 8 : undefined, paddingRight: isCurrentUser ? undefined : 8 }}
                          />
                        )}
                        {action['reportType'] === 'MatchReport' && (
                          <DescriptionIcon
                            style={{ fontSize: 22, paddingLeft: isCurrentUser ? 8 : undefined, paddingRight: isCurrentUser ? undefined : 8 }}
                          />
                        )}
                        {action['reportType'] === 'Comment' && (
                          <MessageIcon
                            style={{ fontSize: 21, paddingLeft: isCurrentUser ? 8 : undefined, paddingRight: isCurrentUser ? undefined : 8 }}
                          />
                        )}
                        {action['reportType'] === 'Attachment' && (
                          <PictureAsPdfIcon
                            style={{ fontSize: 21, paddingLeft: isCurrentUser ? 8 : undefined, paddingRight: isCurrentUser ? undefined : 8 }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

              </div>
            );
          })}
        </div>

      </div>
    );
  }
};
