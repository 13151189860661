import '../tables.css';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import { PlayerClubIteration } from '../../../types';
import { getSeasonTableColumnGroups } from './seasonColumns';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { translate } from '../../../../common/language/translations';
import { Toggle } from '../../controls/input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { SeasonTableSeasonCell } from '../tableCells';


interface SeasonTableProps {
  data: PlayerClubIteration[];
  selectedPositionGroupKey: string;
  selectedClubIterationId: string | undefined;
  setSelectedClubIterationId: (index: string) => void;
}


export const SeasonTable: React.FC<SeasonTableProps> = ({
  data,
  selectedPositionGroupKey,
  selectedClubIterationId,
  setSelectedClubIterationId,
}) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const clubScopes = useRecoilValue(clubScopesState);

  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const seasonStatsToggles = useMemo(() => userSettings?.seasonStatsToggles ?? {}, [userSettings?.seasonStatsToggles]);
  const roleConfigs = useMemo(() => clubSettings?.roleConfigs ?? {}, [clubSettings?.roleConfigs]);
  const positionGroupToRoleIds = useMemo(() => clubSettings?.positionGroupToRoleIds ?? {}, [clubSettings?.positionGroupToRoleIds]);
  const dataColumnGroups = useMemo(
    () => {
      return getSeasonTableColumnGroups(
        selectedPositionGroupKey,
        positionGroupToRoleIds,
        roleConfigs,
        clubScopes?.hasSkillcorner ?? false,
        seasonStatsToggles,
        userConfig?.language ?? 'en',
        userConfig?.isColorBlind ?? false,
      );
    },
    [
      selectedPositionGroupKey,
      positionGroupToRoleIds,
      roleConfigs,
      clubScopes?.hasSkillcorner,
      seasonStatsToggles,
      userConfig?.isColorBlind,
      userConfig?.language,
    ]
  );


  const handleRowClick = (row: PlayerClubIteration, rowExtension: string) => {
    if (rowExtension !== 'empty' && row.club_iteration_id) {
      setSelectedClubIterationId(row.club_iteration_id);
    }
  };


  const getExtendedRowClassName = (clubIteration: PlayerClubIteration, index: number) => {
    if (!clubIteration.event_data_available) {
      return 'empty';
    }

    if (clubIteration.club_iteration_id === selectedClubIterationId) {
      return 'selected';
    }

    const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
      ? clubIteration.position_groups[0]
      : selectedPositionGroupKey;

    const playerClubIterationPositionGroupData = clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration];
    if (!playerClubIterationPositionGroupData || !playerClubIterationPositionGroupData.minutes_played) {
      return 'empty';
    }

    return index % 2 === 0 ? 'even' : 'odd';
  };


  const seasonTableStickyColumnWidth = 360;


  return (
    <div className='table-container season-table-container'>

      {/* Header */}
      <div className='table-header season-table-header season-table-header-background'>

        {/* Player column header */}
        <div
          className='table-sticky-left season-table-header-background season-table-cell-with-border season-table-sticky-header-cell'
          style={{ width: seasonTableStickyColumnWidth }}
        >
          {translate('season', userConfig?.language)}

          {userConfig && (
            <div
              className='flex-row align-center season-table-sticky-header-cell-toggle-text'
              title={translate('showOnlyDomesticLeagues', userConfig?.language)}
              style={{ marginLeft: 20 }}
            >
              {translate('leagues', userConfig?.language)}
              <div className='table-header-toggle'>
                <Toggle
                  isToggled={seasonStatsToggles.showOnlyDomesticLeagues}
                  setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                    { 'showOnlyDomesticLeagues': value },
                    userConfig.email,
                    userConfig.club,
                    currentUser
                  )}
                  isSmall={true}
                />
              </div>
            </div>
          )}

          {userConfig && (
            <div
              className='flex-row align-center season-table-sticky-header-cell-toggle-text'
              title={translate('showOnlySeasonsWith180Minutes', userConfig.language)}
              style={{ marginRight: 20 }}
            >
              {translate('minutes', userConfig.language)}
              <div className='table-header-toggle'>
                <Toggle
                  isToggled={seasonStatsToggles.showOnlySeasonsWith180Minutes ?? false}
                  setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                    { 'showOnlySeasonsWith180Minutes': value },
                    userConfig.email,
                    userConfig.club,
                    currentUser
                  )}
                  isSmall={true}
                />
              </div>
            </div>
          )}
        </div>

        {Object.entries(dataColumnGroups).map(([metricGroupKey, dataColumns]) => {

          const onClick = () => openTextModal(
            metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en'],
            metricToDisplayInfo[metricGroupKey].description[userConfig?.language ?? 'en'],
            { infoType: 'metric', titleKey: metricGroupKey }
          );

          return (
            <div
              key={metricGroupKey}
              className='table-header-group season-table-cell-with-border'
              style={{ paddingTop: 4 }}
            >
              <div className='flex-row align-center'>
                <div className='table-header-cell-clickable' onClick={onClick}>
                  {metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en']}
                </div>

                {userConfig && metricGroupKey !== 'ratings' && metricGroupKey !== 'obv' && (
                  <div
                    className='table-header-toggle'
                    title={translate(seasonStatsToggles[metricGroupKey] ? 'showNormalizedValues' : 'showActualValues', userConfig?.language)}
                  >
                    <Toggle
                      isToggled={seasonStatsToggles[metricGroupKey]}
                      setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                        { [metricGroupKey]: value },
                        userConfig.email,
                        userConfig.club,
                        currentUser
                      )}
                      isSmall={true}
                    />
                  </div>
                )}
              </div>

              <div className='table-header-group-row' style={{ fontSize: 12, height: 26 }}>
                {dataColumns.map(column => {

                  const roleId = column.roleId;
                  const onClick = roleId
                    ? () => openRoleInfoModal(roleConfigs[roleId])
                    : () => openTextModal(
                      metricToDisplayInfo[column.key].name[userConfig?.language ?? 'en'],
                      metricToDisplayInfo[column.key].description[userConfig?.language ?? 'en'],
                      { infoType: 'metric', titleKey: column.key }
                    );

                  return (
                    <div
                      key={column.key}
                      className='table-cell'
                      style={{
                        width: column.width,
                      }}
                    >
                      <div className='table-header-cell-clickable' onClick={onClick}>
                        {column.metricName}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      {/* Body */}
      <div className='season-table-body'>
        {data.map((row, rowIndex) => {

          const rowExtension = getExtendedRowClassName(row, rowIndex);

          return (
            <div
              key={rowIndex}
              className={'table-row season-table-row season-table-row-' + rowExtension}
              onClick={() => handleRowClick(row, rowExtension)}
            >
              {/* Player column */}
              <div
                className={
                  'table-sticky-left season-table-cell-with-border season-table-sticky-cell season-table-sticky-cell-' + rowExtension
                }
                style={{ width: seasonTableStickyColumnWidth }}
              >
                <SeasonTableSeasonCell
                  row={row}
                  positionGroup={selectedPositionGroupKey}
                  language={userConfig?.language}
                />
              </div>

              {/* Data columns */}
              {Object.entries(dataColumnGroups).map(([metricGroupKey, dataColumns]) => {
                return (
                  <div className='flex-row season-table-cell-with-border' key={metricGroupKey}>
                    {dataColumns.map(column => {
                      return (
                        <div
                          key={column.key}
                          className='table-cell'
                          style={{ width: column.width }}
                        >
                          {column.cell(row)}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
