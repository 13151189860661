import '../../../static/clubConfigs.css';
import './navigation.css';

import React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationPropsState } from '../../../recoil/atoms/navigationPropsState';
import { useSetAndTrackNavigationPropsState } from '../../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { updateUserConfig } from '../../../services/firestore/users';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import StarIcon from '@mui/icons-material/Star';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import GroupIcon from '@mui/icons-material/Group';
import ConstructionIcon from '@mui/icons-material/Construction';
import PublicIcon from '@mui/icons-material/Public';
import FolderIcon from '@mui/icons-material/Folder';
import PaidIcon from '@mui/icons-material/Paid';
import TimelineIcon from '@mui/icons-material/Timeline';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  getClubLogo,
  getClubLogoBackgroundColor,
  getClubLogoBorderRadius,
  getClubLogoShadow,
  getClubLogoSize,
  getClubSecondaryColor
} from '../../../static/clubConfigs';

import { translate } from '../../../../common/language/translations';
import { NavigationBackgroundSvg } from '../../../../assets/svg/NavigationBackgroundSvg';
import { StringToAnyMap } from '../../../types';
import { userHasEconomyAccess, userHasFullAccess, userHasScoutAccess } from '../../../utils/userUtils';
import { getNavigationWidth } from '../../../utils/navigationUtils';


const tabOptions: string[] = [
  'home',
  'masterTeam',
  'shadowTeam',
  'scoutTeams',
  'scouting',
  'archive',
  'ownTeam',
  'planner',
  'squadBuilder',
  'economy',
  'settings',
];

const tabOptionToDisplayInfo: StringToAnyMap = {
  'home': {
    icon: <HomeIcon style={{ fontSize: 22 }} />,
    textKey: 'home',
    hasAccess: () => true,
  },
  'masterTeam': {
    icon: <StarIcon style={{ fontSize: 22 }} />,
    textKey: 'masterTeam',
    hasAccess: userHasScoutAccess,
  },
  'shadowTeam': {
    icon: <SportsSoccerIcon style={{ fontSize: 22 }} />,
    textKey: 'shadowTeam',
    hasAccess: userHasScoutAccess,
  },
  'scoutTeams': {
    icon: <PublicIcon style={{ fontSize: 22 }} />,
    textKey: 'scoutTeams',
    hasAccess: userHasScoutAccess,
  },
  'scouting': {
    icon: <PersonSearchIcon style={{ fontSize: 22 }} />,
    textKey: 'scouting',
    hasAccess: userHasScoutAccess,
  },
  'archive': {
    icon: <FolderIcon style={{ fontSize: 22 }} />,
    textKey: 'archive',
    hasAccess: userHasScoutAccess,
  },
  'ownTeam': {
    icon: <GroupIcon style={{ fontSize: 22 }} />,
    textKey: 'ownTeam',
    hasAccess: () => true,
  },
  'planner': {
    icon: <TimelineIcon style={{ fontSize: 22 }} />,
    textKey: 'planner',
    hasAccess: userHasFullAccess,
  },
  'squadBuilder': {
    icon: <ConstructionIcon style={{ fontSize: 22 }} />,
    textKey: 'squadBuilder',
    hasAccess: userHasScoutAccess,
  },
  'economy': {
    icon: <PaidIcon style={{ fontSize: 22 }} />,
    textKey: 'clauses',
    hasAccess: userHasEconomyAccess,
  },
  'settings': {
    icon: <SettingsIcon style={{ fontSize: 22 }} />,
    textKey: 'settings',
    hasAccess: () => true,
  },
};


export const Navigation: React.FC = () => {

  const { currentUser } = useAuthContext();

  const navigationProps = useRecoilValue(navigationPropsState);
  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();

  const [isInitialized, setIsInitialized] = useState(false);

  const userConfig = useRecoilValue(userConfigState);

  const isNavigationExpanded = userConfig ? userConfig.isNavigationExpanded : true;
  const navigationWidth = getNavigationWidth(userConfig);

  const { height } = useWindowSize();

  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const [isNavigationExpanding, setIsNavigationExpanding] = useState(false);
  const [tabLineTop, setTabLineTop] = useState<number>(100);

  const [recentlyClickedTab, setRecentlyClickedTab] = useState('');


  const updateTabLineTop = (newActiveTab: string) => {
    const element = document.getElementById(newActiveTab);
    if (element) {
      const heightOffset = height <= 580 ? (1 + height / 160) : 0; // something changes when height is 580 or below (?)
      const elementPosition = element.getBoundingClientRect().top + 5.25 + heightOffset - (height / 50) - (isNavigationExpanded ? 0.5 : 0);
      setTabLineTop(elementPosition);
    }
  };


  const handleNavigation = (newActiveTab: string) => {
    setSelectedPlayer(undefined);
    if (newActiveTab !== navigationProps.activeTab) {
      setAndTrackNavigationProps({ activeTab: newActiveTab });

      setRecentlyClickedTab(newActiveTab);
      setTimeout(() => {
        setRecentlyClickedTab('');
      }, 1000);
    }
    updateTabLineTop(newActiveTab);
  };


  const handleNavigationExpansion = () => {
    if (userConfig) {
      updateUserConfig(userConfig.email, 'isNavigationExpanded', !isNavigationExpanded, 'NavigationExpansionToggled', currentUser);
    }
  };


  useEffect(() => {
    if (isInitialized) {
      setIsNavigationExpanding(true);
      let count = 0;

      // (maxCount * interval) must exceed var(--platform-navigation-expansion-transition)
      const maxCount = 26;
      const interval = 10;

      const intervalId = setInterval(() => {
        updateTabLineTop(navigationProps.activeTab);
        count++;
        if (count >= maxCount) {
          setIsNavigationExpanding(false);
          clearInterval(intervalId);
        }
      }, interval);

      const clear = () => {
        setIsNavigationExpanding(false);
        clearInterval(intervalId);
      };

      return () => clear();
    }
  }, [isNavigationExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isInitialized) {
      let count = 0;
      const maxCount = 10;
      const interval = 10;

      const intervalId = setInterval(() => {
        updateTabLineTop(navigationProps.activeTab);
        count++;
        if (count >= maxCount) {
          clearInterval(intervalId);
        }
      }, interval);

      return () => clearInterval(intervalId);
    }
  }, [height]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (navigationProps) {
      updateTabLineTop(navigationProps.activeTab);
      setIsInitialized(true);
    }
  }, [navigationProps]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubSecondaryColor = getClubSecondaryColor(userConfig?.club ?? '');
  const logoShadow = getClubLogoShadow(userConfig?.club ?? '');
  const logoSize = getClubLogoSize(userConfig?.club ?? '');
  const logoBackgroundColor = getClubLogoBackgroundColor(userConfig?.club ?? '');
  const logoBorderRadius = getClubLogoBorderRadius(userConfig?.club ?? '');


  return (
    <div className='navigation-container' style={{ width: navigationWidth }}>

      <NavigationBackgroundSvg />

      <div className='navigation-tabs'>

        <div
          className={'navigation-line fade-in' + (isNavigationExpanding ? ' navigation-line-expanding' : '')}
          style={{ top: tabLineTop, left: navigationWidth - 10, backgroundColor: clubSecondaryColor }}
        />

        <div className={'navigation-logo-container' + (isNavigationExpanded ? ' navigation-logo-container-expanded' : '')}>
          {logoShadow && <div className={'navigation-logo-shadow-overlay' + ' logo-shadow-' + logoShadow} />}

          <img
            src={getClubLogo(userConfig?.club ?? '')}
            alt='logo'
            className='navigation-logo'
            draggable={false}
            style={{
              maxWidth: logoSize ?? '100%',
              maxHeight: logoSize ?? '100%',
              backgroundColor: logoBackgroundColor,
              borderRadius: logoBorderRadius ?? (logoBackgroundColor ? 8 : undefined),
              overflow: logoBorderRadius !== undefined ? 'hidden' : undefined,
              padding: logoBackgroundColor ? '5%' : undefined,
            }}
          />
        </div>

        <div className='navigation-space' />

        {tabOptions.map(tab => {

          const hasAccess = tabOptionToDisplayInfo[tab].hasAccess(userConfig);
          if (!hasAccess) return null;

          const isActiveTab = navigationProps.activeTab === tab;
          const showDividerAbove = tab === 'home' || tab === 'settings' || (tab === 'economy' && !userHasScoutAccess(userConfig));
          const showDividerBelow = tab === 'home' || tab === 'settings' || tab === 'scoutTeams' || tab === 'archive';

          return (
            <React.Fragment key={tab}>

              {showDividerAbove && <div className='navigation-divider' />}

              <div
                key={tab}
                id={tab}
                style={{ color: isActiveTab ? clubSecondaryColor : '#efefef', width: navigationWidth }}
                className={
                  'navigation-tab-link'
                  + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
                  + (isActiveTab ? ' navigation-tab-link-active' : '')
                }
                onClick={() => handleNavigation(tab)}
              >
                <div className='navigation-tab-content'>
                  <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                    {tabOptionToDisplayInfo[tab].icon}
                  </div>
                  {isNavigationExpanded && !isNavigationExpanding && (
                    <div className='navigation-tab-text fade-in'>
                      {translate(tabOptionToDisplayInfo[tab].textKey, userConfig?.language)}
                    </div>
                  )}
                  {!isNavigationExpanded && recentlyClickedTab !== tab && (
                    <div className='navigation-tab-hover-text'>
                      {translate(tabOptionToDisplayInfo[tab].textKey, userConfig?.language)}
                    </div>
                  )}
                </div>
              </div>

              {showDividerBelow && <div className='navigation-divider' />}

            </React.Fragment>
          );
        })}

        <div className='navigation-space' />

        <div
          className={'navigation-expand-icon' + (!isNavigationExpanded ? ' navigation-expand-icon-right' : '')}
          onClick={handleNavigationExpansion}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: 22 }} />
        </div>

      </div>
    </div>
  );
};
