import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap } from '../../types';
import { countryCodeToCountryInfo } from '../../static/countries';
import { CountryDropDown } from '../controls/dropDowns/CountryDropDown';
import { InputField } from '../controls/input/InputField';
import { getLocalityIcon, getRoleIcon } from '../../utils/iconUtils';
import { setPlayerProperties } from '../../services/firestore/players';
import { getDisplayEconomicValue } from '../../utils/currencyUtils';
import { IconButton } from '../controls/buttons/IconButton';
import { TextButton } from '../controls/buttons/TextButton';
import { OptionButton } from '../controls/buttons/OptionButton';
import { academyRoles, playerLocalities, teamRoles } from '../../static/propertyValues';
import { DateInputField } from '../controls/input/DateInputField';
import { dateStringToLocaleFormat, getAdjustedDate } from '../../utils/dateUtils';


interface EditPlayerInfoModalProps {
  editPlayerInfoProperty: string | undefined;
  closeModal: (feedback?: string | undefined) => void;
  playerToEdit: StringToAnyMap | undefined;
  isAcademyPlayer: boolean;
}

export const EditPlayerInfoModal: React.FC<EditPlayerInfoModalProps> = ({
  editPlayerInfoProperty,
  closeModal,
  playerToEdit,
  isAcademyPlayer,
}) => {

  const { currentUser } = useAuthContext();
  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal } = useOpenGlobalModal();

  const [invalidInputMessage, setInvalidInputMessage] = useState<string | undefined>(undefined);

  const propertyIsDate = (editPlayerInfoProperty === 'birth_date' || editPlayerInfoProperty === 'contract_expiration');
  const propertyIsCategorical = (editPlayerInfoProperty === 'role' || editPlayerInfoProperty === 'locality');
  const propertyTypeIsText =
    !propertyIsDate &&
    !propertyIsCategorical &&
    editPlayerInfoProperty !== 'country_code';

  const propertyIsOverridden =
    editPlayerInfoProperty &&
    playerToEdit &&
    playerToEdit[editPlayerInfoProperty] !== undefined &&
    playerToEdit[editPlayerInfoProperty] !== null &&
    playerToEdit[editPlayerInfoProperty] !== playerToEdit[editPlayerInfoProperty + '_in_database'];


  const isButtonDisabled = () => {
    if (propertyIsDate) {
      return !(playerToEdit && editPlayerInfoProperty && selectedDate && selectedDate.toISOString().split('T')[0] !== playerToEdit[editPlayerInfoProperty]);
    }
    if (editPlayerInfoProperty === 'country_code') {
      return !(playerToEdit && selectedCountryCodes.length > 0 && selectedCountryCodes[0] !== playerToEdit.country_code);
    }
    return !(searchString.length > 0 && playerToEdit && editPlayerInfoProperty && String(playerToEdit[editPlayerInfoProperty]) !== searchString);
  };


  const handleNewLocalityClick = (localityClicked: string, isOptionAlreadySelected: boolean) => {
    if (!playerToEdit || !userConfig) return;
    setPlayerProperties(playerToEdit.id, { locality: isOptionAlreadySelected ? null : localityClicked }, userConfig.club, currentUser, true);
    closeModal();
  };


  // const handleNewAbcClick = (ABC: string, isOptionAlreadySelected: boolean) => {
  //   if (!playerToEdit || !userConfig) return;
  //   setPlayerProperty(playerToEdit.id, 'ABC', isOptionAlreadySelected ? null : ABC, userConfig.club);
  //   closeModal();
  // };


  const handleNewRoleClick = (roleClicked: string, isOptionAlreadySelected: boolean) => {
    if (!playerToEdit || !userConfig) return;
    setPlayerProperties(playerToEdit.id, { role: isOptionAlreadySelected ? null : roleClicked }, userConfig.club, currentUser, true);
    closeModal('role');
  };


  const confirmEditPlayerInfo = () => {
    if (!userConfig || !editPlayerInfoProperty || !playerToEdit) return;

    if (propertyIsDate && selectedDate) {
      const newValue = selectedDate.toISOString().split('T')[0];
      setPlayerProperties(playerToEdit.id, { [editPlayerInfoProperty]: newValue }, userConfig.club, currentUser, true);
      closeModal('contract_expiration');
      return;
    }

    if (editPlayerInfoProperty === 'country_code') {
      const newValue = selectedCountryCodes[0];
      setPlayerProperties(playerToEdit.id, { [editPlayerInfoProperty]: newValue }, userConfig.club, currentUser, true);
      closeModal();
      return;
    }

    if (isNaN(Number(searchString)) || Number(searchString) < 0) {
      setInvalidInputMessage('inputMustBeNumericAndPositive');
      return false;
    }
    if (searchString.length > 0 && playerToEdit && editPlayerInfoProperty && String(playerToEdit[editPlayerInfoProperty]) !== searchString) {
      setPlayerProperties(playerToEdit.id, { [editPlayerInfoProperty]: Number(searchString) }, userConfig.club, currentUser, true);
      closeModal();
    }

    return;
  };


  const deletePlayerInfo = () => {
    if (!userConfig || !editPlayerInfoProperty || !playerToEdit) return;
    setPlayerProperties(playerToEdit.id, { [editPlayerInfoProperty]: null }, userConfig.club, currentUser, true);
    closeModal();
  };


  // properties that may both exist in the database and in the players collection
  const potentiallyOverriddenProperties = ['birth_date', 'country_code', 'contract_expiration', 'market_value'];

  const getCurrentDisplayValue = () => {
    if (propertyIsDate) {
      const propertyValue = playerToEdit ? playerToEdit[editPlayerInfoProperty + '_in_database'] : undefined;
      return dateStringToLocaleFormat(propertyValue);
    }

    if (editPlayerInfoProperty === 'country_code') {
      const propertyValue = playerToEdit ? playerToEdit[editPlayerInfoProperty + '_in_database'] : undefined;
      if (!userConfig || !propertyValue || !countryCodeToCountryInfo[propertyValue]) return '-';

      return countryCodeToCountryInfo[propertyValue].name[userConfig.language] ?? '-';
    }

    if (editPlayerInfoProperty === 'market_value') {
      const propertyValue = playerToEdit ? playerToEdit[editPlayerInfoProperty + '_in_database'] : undefined;
      return propertyValue ? getDisplayEconomicValue(propertyValue, true) : '-';
    }
  };


  // Date section
  const [selectedDate, setSelectedDate] = useState(
    (propertyIsDate && playerToEdit && editPlayerInfoProperty && playerToEdit[editPlayerInfoProperty])
      ? new Date(playerToEdit[editPlayerInfoProperty])
      : editPlayerInfoProperty === 'contract_expiration'
        ? new Date()
        : null
  );

  const handleSetSelectedDate = (date: Date | null) => {
    if (!date) {
      setSelectedDate(null);
      return;
    }
    const adjustedDate = getAdjustedDate(date);
    setSelectedDate(adjustedDate);
  };


  // Nationalities section
  const [isNationalitiesExpanded, setIsNationalitiesExpanded] = useState(false);
  const [selectedCountryCodes, setSelectedCountryCodes] = useState<string[]>(playerToEdit?.country_code ? [playerToEdit.country_code] : []);


  // Text sections
  const [searchString, setSearchString] = useState(playerToEdit && editPlayerInfoProperty ? (playerToEdit[editPlayerInfoProperty] ?? '') : '');

  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidInputMessage(undefined);
    setSearchString(event.target.value.replaceAll('.', ''));
  };

  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      confirmEditPlayerInfo();
    }
  };

  const resetSearchString = () => {
    setInvalidInputMessage(undefined);
    setSearchString('');
  };


  const modalHeight = editPlayerInfoProperty === 'locality'
    ? 260
    : editPlayerInfoProperty === 'role'
      ? (isAcademyPlayer ? 260 : 490)
      : propertyTypeIsText
        ? 400
        : 600;

  return (
    <div
      className='modal-root-container edit-player-info-modal-root'
      style={{ height: modalHeight }}
    >

      {isNationalitiesExpanded &&
        <div className='modal-empty-background' onClick={() => setIsNationalitiesExpanded(false)} />
      }

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={() => closeModal()}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      {propertyIsOverridden && (
        <IconButton
          icon={<ReplayIcon style={{ fontSize: 24 }} />}
          onClick={() => openConfirmModal(deletePlayerInfo, 'deleteProvidedValue', 'noRegretInfo')}
          size={30}
          style={{ position: 'absolute', top: 12, left: 12 }}
          startWhite={true}
          title={translate('deleteProvidedValue', userConfig?.language)}
        />
      )}

      <div className='modal-root-title' style={{ height: 65 }}>
        {editPlayerInfoProperty ? translate('provideNewPlayerInfo_' + editPlayerInfoProperty, userConfig?.language, undefined, undefined, true) : ''}
        <div style={{ marginTop: 8 }}>
          {playerToEdit?.fullname}
        </div>
      </div>

      <div className='modal-divider modal-root-title-divider' style={{ top: 65 }} />

      <div className='modal-content-container' style={{ top: 70 }}>

        {invalidInputMessage && (
          <div className='edit-player-info-modal-invalid-input-message'>
            {translate(invalidInputMessage, userConfig?.language)}
          </div>
        )}

        <div className='modal-content-section' style={{ minHeight: modalHeight / 3, padding: '25px 0px' }}>

          {propertyIsDate && (
            <DateInputField
              value={selectedDate}
              placeholder={translate('selectDate', userConfig?.language)}
              onChange={handleSetSelectedDate}
              minDate={editPlayerInfoProperty === 'birth_date' ? 'today-40y' : 'today-10y'}
              maxDate={editPlayerInfoProperty === 'birth_date' ? 'today-10y' : 'today+10y'}
            />
          )}

          {editPlayerInfoProperty === 'country_code' && (
            <div style={{ width: 300 }}>
              <CountryDropDown
                id={'edit-player-info-modal-country-drop-down'}
                isDropDownExpanded={isNationalitiesExpanded}
                setIsDropDownExpanded={setIsNationalitiesExpanded}
                selectedCountryCodes={selectedCountryCodes}
                setSelectedCountryCodes={setSelectedCountryCodes}
                defaultDropDownText={'select'}
                defaultDropDownTextColor={'#00000080'}
                marginBetweenOptions={3}
                maxHeight={320}
                singleSelect={true}
              />
            </div>
          )}

          {editPlayerInfoProperty === 'locality' && (
            <div className='modal-option-column'>
              {playerLocalities.map(locality => {

                const isSelected = playerToEdit !== undefined && playerToEdit.locality === locality;
                const icon = getLocalityIcon(locality, 22);

                return (
                  <div key={locality}>
                    <OptionButton
                      onClick={() => handleNewLocalityClick(locality, isSelected)}
                      option={locality + 'Locality'}
                      language={userConfig?.language}
                      optionIsSelected={isSelected}
                      icons={{ leftIcon: icon }}
                      style={{ width: 220 }}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {editPlayerInfoProperty === 'role' && (
            <div className='modal-option-column'>
              {(isAcademyPlayer ? academyRoles : teamRoles).map(role => {

                const isSelected = playerToEdit !== undefined && playerToEdit.role === role;
                const icon = getRoleIcon(role, 22);

                return (
                  <div key={role}>
                    <OptionButton
                      onClick={() => handleNewRoleClick(role, isSelected)}
                      option={role}
                      language={userConfig?.language}
                      optionIsSelected={isSelected}
                      icons={{ leftIcon: icon }}
                      style={{ width: 220 }}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {propertyTypeIsText && (
            <div style={{ marginTop: 6 }}>
              <InputField
                value={getDisplayEconomicValue(searchString)}
                placeholder={translate('provideNewValue', userConfig?.language)}
                onChange={onChangeSearchField}
                onKeyDown={onKeyDownSearchField}
                resetValue={resetSearchString}
                width={200}
              />
            </div>
          )}
        </div>

        {!propertyIsCategorical && (
          <div
            className='modal-content-section'
            style={{ maxHeight: 160, minHeight: 120, padding: '10px 0px 20px 0px' }}
          >
            {editPlayerInfoProperty && potentiallyOverriddenProperties.includes(editPlayerInfoProperty) && !playerToEdit?.isPlayerWithoutData && (
              <div className='edit-player-info-modal-database-value-row'>
                {translate('valueInOurDatabase', userConfig?.language)}
                <div style={{ marginLeft: 8 }}>
                  {getCurrentDisplayValue()}
                </div>
              </div>
            )}

            <TextButton
              onClick={confirmEditPlayerInfo}
              text={translate('confirm', userConfig?.language)}
              icon={<CheckIcon style={{ fontSize: 22 }} />}
              disabled={isButtonDisabled()}
            />
          </div>
        )}
      </div>
    </div>
  );
};
