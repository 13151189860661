import axios from 'axios';

import { FirebaseUser } from '../../../../firebase';
import { ClubMap, ClubsQueryOptions } from '../../../types';

import { APPLICATION_API_URL } from '../constants';
import { getBearerToken } from '../token';
import { trackEvent } from '../analytics/trackEvent';


// Get clubs for all given clubIds
export const getClubs = async (
  clubIds: number[],
  currentUser: FirebaseUser,
): Promise<ClubMap | undefined> => {
  try {
    clubIds = clubIds.filter((clubId) => !isNaN(Number(clubId))).map((clubId) => Number(clubId));

    if (clubIds.length === 0) return undefined;

    const token = await getBearerToken(currentUser);
    const clubIdsString = clubIds.join(',');

    const result = await axios.get(`${APPLICATION_API_URL}/clubs/${clubIdsString}`, {
      headers: { Authorization: token },
    });

    if (!result) return undefined;

    return result.data;
  }
  catch (error) {
    trackEvent('Error', { api: 'server', function: 'getClubs', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Search for clubs (only name is used currently as query parameter)
export const searchClubs = async (
  options: ClubsQueryOptions,
  currentUser: FirebaseUser,
): Promise<ClubMap[] | undefined> => {
  try {
    const token = await getBearerToken(currentUser);

    const competitionsString = options.competitions ? options.competitions.join(',') : undefined;
    const countryCodesString = options.countryCodes ? options.countryCodes.join(',') : undefined;

    const result = await axios.get(`${APPLICATION_API_URL}/search/clubs/`, {
      headers: { Authorization: token },
      params: {
        name: options.name,
        competitions: competitionsString,
        country_codes: countryCodesString,
        page: options.page ?? 1,
      },
    });

    if (!result) return undefined;

    return result.data;
  }
  catch (error) {
    trackEvent('Error', { api: 'server', function: 'searchClubs', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};
