
import { JSX } from 'react';
import { CareerIterationEntry } from '../../playerView/PlayerViewCareer';
import { PositionCell } from '../tableCells';


interface CareerTableColumnCellProps {
  row: CareerIterationEntry;
  language?: string;
}


interface CareerTableColumn {
  key: string;
  width: number;
  cell: (props: CareerTableColumnCellProps) => JSX.Element;
}


export const careerTableColumnsWidth = 450;
export const careerTablePadding = 15;
export const careerTableColumns: CareerTableColumn[] = [
  {
    key: 'matches',
    width: 90,
    cell: ({ row }: CareerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.appearances}
      </div>
    ),
  },
  {
    key: 'starts',
    width: 90,
    cell: ({ row }: CareerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.games_started}
      </div>
    ),
  },
  {
    key: 'position',
    width: 90,
    cell: ({ row, language }: CareerTableColumnCellProps) => (
      <PositionCell playerData={row} language={language} isDarkBackground={true} />
    ),
  },
  {
    key: 'goals',
    width: 90,
    cell: ({ row }: CareerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.n_goals}
      </div>
    ),
  },
  {
    key: 'assists',
    width: 90 + careerTablePadding,
    cell: ({ row }: CareerTableColumnCellProps) => (
      <div className='table-cell' style={{ paddingRight: careerTablePadding }}>
        {row.n_assists}
      </div>
    ),
  },
];
