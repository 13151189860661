import { db, FirebaseUser } from '../../../firebase';

import {
  doc,
  setDoc,
  getDoc,
} from 'firebase/firestore';

import { PlannerConfig, PlayerId } from '../../types';
import { trackEvent } from '../server/analytics/trackEvent';


// Get plannerConfig
export const getPlannerConfig = async (
  club: string,
  currentUser: FirebaseUser
): Promise<PlannerConfig> => {

  const plannerConfigDocRef = doc(db, 'configs', club, 'planner', 'config');

  try {
    const docSnap = await getDoc(plannerConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data?.plannerConfig) {
        return data.plannerConfig as PlannerConfig;
      }
    }
  }

  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'getPlannerConfig', errorDetails: { error } }, currentUser, 'system');
  }

  return {} as PlannerConfig;
};


// Update plannerConfig
export const updatePlannerConfig = async (
  newPlannerConfig: PlannerConfig,
  club: string,
  currentUser: FirebaseUser
): Promise<boolean> => {

  const plannerConfigDocRef = doc(db, 'configs', club, 'planner', 'config');

  try {
    await setDoc(
      plannerConfigDocRef,
      { plannerConfig: newPlannerConfig },
    );

    return true;
  }

  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updatePlannerConfig', errorDetails: { error } }, currentUser, 'system');
  }

  return false;
};


// Delete all actions for the given player from the planner config
export const deletePlayerFromPlanner = async (
  playerId: PlayerId,
  club: string,
  currentUser: FirebaseUser
) => {

  const plannerConfigDocRef = doc(db, 'configs', club, 'planner', 'config');

  try {
    const docSnap = await getDoc(plannerConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data?.plannerConfig) {
        const plannerConfig = data.plannerConfig as PlannerConfig;
        delete plannerConfig[playerId];

        await setDoc(
          plannerConfigDocRef,
          { plannerConfig: plannerConfig },
        );
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deletePlayerFromPlanner', errorDetails: { error } }, currentUser, 'system');
  }
};
