import {
  AccessOption,
  AgeCategory,
  AvailabilityStatus,
  ContractCategory,
  FormStatus,
  NumberToStringMap,
  PlayerLocality,
  StringToNumberMap,
  StringToStringArrayMap,
  TeamRole,
} from '../types';


export const staticTeamKeys: string[] = [
  'masterTeam',
  'shadowTeam',
  'ownTeam',
  'academyTeam',
];


export const teamRoleToSortOrder: Record<TeamRole, number> = {
  'keyPlayer': 30,
  'valuePlayer': 24,
  'rotationPlayer': 20,
  'loanedIn': 18,
  'developmentPlayer': 16,
  'reserve': 14,
  'longTermInjured': 12,
  'loanedOut': 10,
  'trialist': 6,
  'talent': 4,
  'potential': 2,
};


export const teamRoles: TeamRole[] = [
  'keyPlayer',
  'valuePlayer',
  'rotationPlayer',
  'loanedIn',
  'developmentPlayer',
  'reserve',
  'longTermInjured',
  'loanedOut',
];


export const academyRoles: TeamRole[] = [
  'trialist',
  'talent',
  'potential',
];


export const playerLocalityToSortOrder: Record<PlayerLocality, number> = {
  'local': 5,
  'national': 4,
  'international': 3,
};


export const playerLocalities: PlayerLocality[] = [
  'local',
  'national',
  'international',
];


export const ageCategories: AgeCategory[] = [
  'U21',
  'U25',
  'U29',
  '29+',
];


export const contractCategories: ContractCategory[] = [
  'U1',
  'U2',
  'U3',
  '3+',
];


export const accessOptionToSortOrder: Record<AccessOption, number> = {
  'admin': 6,
  'full': 5,
  'economy': 4,
  'scout': 3,
  'none': 2,
};


export const editAccessOptions: AccessOption[] = [
  'full',
  'economy',
  'scout',
  'none',
];


export const editFokusAccessOptions: AccessOption[] = [
  'admin',
  'full',
  'economy',
  'scout',
];


export const newAccessOptions: AccessOption[] = [
  'full',
  'economy',
  'scout',
];


export const positionOptionsPlatform: string[] = [
  'LS',
  'S',
  'RS',
  'LM',
  'LW',
  'RW',
  'RM',
  'CAM',
  'LCM',
  'RCM',
  'CDM',
  'LWB',
  'LB',
  'LCB',
  'CB',
  'RCB',
  'RB',
  'RWB',
  'GK',
];


// every key here must map to at least one position that exists in any formation
export const positionToClosestPositions: StringToStringArrayMap = {
  'LS': ['S', 'LW', 'LM', 'CAM', 'LCM', 'CM'],
  'S': ['LS', 'LW', 'LM', 'CAM', 'CM'], // splitting left
  'RS': ['S', 'RW', 'RM', 'CAM', 'RCM', 'CM'],
  'LM': ['LW', 'LWB', 'LB', 'LS', 'LCM', 'CAM', 'CM'],
  'LW': ['LM', 'LS', 'LWB', 'LB', 'LCM', 'CAM', 'CM'],
  'RW': ['RM', 'RS', 'RWB', 'RB', 'RCM', 'CAM', 'CM'],
  'RM': ['RW', 'RWB', 'RB', 'RS', 'RCM', 'CAM', 'CM'],
  'CAM': ['CM', 'S', 'RCM', 'RM', 'RW', 'RS'], // splitting right
  'LCM': ['CM', 'CDM', 'CAM', 'LM', 'LW', 'LB', 'LS'],
  'RCM': ['CM', 'CDM', 'CAM', 'RM', 'RW', 'RB', 'RS'],
  'CDM': ['CM', 'LCM', 'CAM', 'LCB', 'CB'], // splitting left
  'LWB': ['LB', 'LM', 'LW', 'LCB', 'CB'],
  'LB': ['LWB', 'LM', 'LW', 'LCB', 'CB'],
  'LCB': ['CB', 'LB', 'LWB', 'LCM', 'CDM', 'CM'],
  'CB': ['RCB', 'CDM', 'RB', 'RWB', 'CM', 'RCM'], // splitting right
  'RCB': ['CB', 'RB', 'RWB', 'RCM', 'CDM', 'CM'],
  'RB': ['RWB', 'RM', 'RW', 'RCB', 'CB'],
  'RWB': ['RB', 'RM', 'RW', 'RCB', 'CB'],
};


export const positionGroupToPositions: StringToStringArrayMap = {
  'S': ['S'],
  'W': ['LW', 'RW', 'CAM'],
  'CM': ['CM', 'CDM'],
  'FB': ['LB', 'RB'],
  'CB': ['CB'],
  'GK': ['GK'],
};


export const formationOptions: string[] = [
  '433',
  '442',
  '4312',
  '4231',
  '4141',
  '4411',
  '343',
  '3421',
  '541',
  '523',
  '532'
];


export const formationToPositionOptions: StringToStringArrayMap = {

  '433': [
    'S',
    'LW',
    'RW',
    'LCM',
    'RCM',
    'CDM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '442': [
    'LS',
    'RS',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4312': [
    'LS',
    'RS',
    'CAM',
    'LCM',
    'RCM',
    'CDM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4231': [
    'S',
    'LW',
    'RW',
    'CAM',
    'LCM',
    'RCM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4141': [
    'S',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'CDM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '4411': [
    'S',
    'CAM',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LB',
    'LCB',
    'RCB',
    'RB',
    'GK'
  ],

  '343': [
    'S',
    'LW',
    'RW',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LCB',
    'CB',
    'RCB',
    'GK'
  ],

  '3421': [
    'S',
    'LS',
    'RS',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LCB',
    'CB',
    'RCB',
    'GK'
  ],

  '541': [
    'S',
    'LM',
    'LCM',
    'RCM',
    'RM',
    'LWB',
    'LCB',
    'CB',
    'RCB',
    'RWB',
    'GK'
  ],

  '523': [
    'S',
    'LW',
    'RW',
    'LCM',
    'RCM',
    'LWB',
    'LCB',
    'CB',
    'RCB',
    'RWB',
    'GK'
  ],

  '532': [
    'LS',
    'RS',
    'LCM',
    'RCM',
    'CDM',
    'LWB',
    'LCB',
    'CB',
    'RCB',
    'RWB',
    'GK'
  ]
};


export const contractKeys: string[] = [
  'under6months',
  'under12months',
  'under18months',
  'under2years',
  'under3years',
  'under4years',
];


export const contractKeysToMonths: StringToNumberMap = {
  'freeAgent': 0,
  'under6months': 6,
  'under12months': 12,
  'under18months': 18,
  'under2years': 24,
  'under3years': 36,
  'under4years': 48,
};


export const monthsToContractKeys: NumberToStringMap = {
  0: 'freeAgent',
  6: 'under6months',
  12: 'under12months',
  18: 'under18months',
  24: 'under2years',
  36: 'under3years',
  48: 'under4years',
};


export const ageKeys: string[] = [
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
];


export const minConfidenceKeys: string[] = [
  '5',
  '10',
  '20',
  '30',
  '50',
  '70',
  '90',
];


export const indexKeys: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10'
];


export const playingTimeKeys: AvailabilityStatus[] = [
  'KEY',
  'ROTATION',
  'RESERVE',
  'UNKNOWN',
];


export const formKeys: FormStatus[] = [
  'IN_FORM',
  'NORMAL_FORM',
  'OUT_OF_FORM',
  'NO_FORM',
];


export const minuteAbsoluteKeys: string[] = [
  '200',
  '600',
  '1000',
  '1500',
  '2000'
];


export const minutePercentageKeys: string[] = [
  '20%',
  '40%',
  '60%',
  '80%',
  '100%'
];
