import {
  ReactNode,
  createContext,
  useState,
  useEffect
} from 'react';

import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import type { UserCredential } from 'firebase/auth';

import {
  auth,
  FirebaseUser
} from '../../firebase';


export interface AuthContextType {
  currentUser: FirebaseUser;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
}


export const AuthContext = createContext<AuthContextType | undefined>(undefined);


export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [currentUser, setCurrentUser] = useState<FirebaseUser>(null);
  const [loading, setLoading] = useState<boolean>(true);


  function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }


  function signUp(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }


  function logout() {
    return auth.signOut();
  }


  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);


  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    signUp,
  };


  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
