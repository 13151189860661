import '../../platform.css';
import './planner.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import EditIcon from '@mui/icons-material/Edit';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import { IconButton } from '../../components/controls/buttons/IconButton';
import { getAge, getDisplayPlayerName, getMonthsLeftOfContract } from '../../utils/playerUtils';
import { dateStringToLocaleFormat, dateToLocaleTextFormat } from '../../utils/dateUtils';
import { PlayerImage } from '../../components/display/images/PlayerImage';
import { translate } from '../../../common/language/translations';
import { CountryFlag } from '../../components/display/images/CountryFlag';
import { getLocalityIcon, getPlannerActionIcon, getRoleIcon } from '../../utils/iconUtils';
import { PlannerAction, PlannerActionType, PlayerId, StringToAnyMap } from '../../types';
import { TextButton } from '../../components/controls/buttons/TextButton';


interface PlannerPlayerViewProps {
  selectedPlayerId: PlayerId;
  getPlayerPropertyValue: (property: string, playerId: PlayerId) => any; // eslint-disable-line
  onPlayerLinkClick: () => void;
  currentTeamPlayerMap: StringToAnyMap;
  actions: PlannerAction[];
  selectedAction: PlannerAction | undefined;
  onActionClick: (action: PlannerAction) => void;
  openPlannerActionModal: (type?: PlannerActionType, action?: PlannerAction, newPosition?: string, contractExpirationDateToEdit?: string) => void;
  selectedDate: string;
}

export const PlannerPlayerView: React.FC<PlannerPlayerViewProps> = ({
  selectedPlayerId,
  getPlayerPropertyValue,
  onPlayerLinkClick,
  currentTeamPlayerMap,
  actions,
  selectedAction,
  onActionClick,
  openPlannerActionModal,
  selectedDate,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const dateDividerRef = useRef<HTMLDivElement | null>(null);
  const selectedActionRef = useRef<HTMLDivElement | null>(null);


  const playerActions = actions.filter(action => action.playerId === selectedPlayerId);
  const actionsWithDateDivider = playerActions.reduce((acc, action, index) => {

    const isBeforeSelectedDate = action.date <= selectedDate;

    if (!acc.dividerInserted && !isBeforeSelectedDate) {
      acc.result.push({
        type: 'dateDivider',
        date: selectedDate,
      });
      acc.dividerInserted = true;
    }

    acc.result.push(action);

    // if all actions are in the past, insert a divider at the end
    if (index === playerActions.length - 1 && !acc.dividerInserted) {
      acc.result.push({
        type: 'dateDivider',
        date: selectedDate,
      });
    }

    return acc;

  }, { result: [] as (PlannerAction | { type: 'dateDivider'; date: string; })[], dividerInserted: false });


  const playerIsInTeam = selectedPlayerId in currentTeamPlayerMap;

  const image_url = getPlayerPropertyValue('image_url', selectedPlayerId);
  const fullname = getPlayerPropertyValue('fullname', selectedPlayerId);
  const country_code = getPlayerPropertyValue('country_code', selectedPlayerId);
  const birth_date = getPlayerPropertyValue('birth_date', selectedPlayerId);
  const contractExpiration = currentTeamPlayerMap[selectedPlayerId]?.contract_expiration;
  const position = currentTeamPlayerMap[selectedPlayerId]?.position;
  const role = currentTeamPlayerMap[selectedPlayerId]?.role;
  const locality = currentTeamPlayerMap[selectedPlayerId]?.locality;


  const onEditActionClick = (event: React.MouseEvent | undefined, action: PlannerAction) => {
    event?.stopPropagation();
    openPlannerActionModal(undefined, action, undefined, action.type === 'contractExpired' ? action.date : undefined);
  };


  const actionHeight = 24;
  const actionGap = 10;
  const totalActionHeight = actionHeight + actionGap;


  useEffect(() => {
    const parent = dateDividerRef.current?.parentElement;

    if (parent && dateDividerRef.current) {
      const { offsetTop } = dateDividerRef.current;
      let newTop = offsetTop - parent.clientHeight / 2;
      newTop = Math.round(newTop / totalActionHeight) * totalActionHeight;

      parent.scrollTo({
        top: newTop,
        behavior: 'smooth',
      });
    }
  }, [actions, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const parent = selectedActionRef.current?.parentElement;

    if (parent && selectedActionRef.current) {
      const { offsetTop } = selectedActionRef.current;
      let newTop = offsetTop - parent.clientHeight / 2;
      newTop = Math.round(newTop / totalActionHeight) * totalActionHeight;

      parent.scrollTo({
        top: newTop,
        behavior: 'smooth',
      });
    }
  }, [selectedAction]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='planner-view-left-section-content-container'>

      <div className='planner-view-player-view-header'>
        <PlayerImage
          src={image_url}
          isSingleImage={true}
          imageStyle={{ width: 54, height: 54 }}
        />

        <div className='planner-view-player-view-header-row'>
          <div style={{ fontSize: 17 }}>
            {getDisplayPlayerName(fullname, 20)}
          </div>

          <CountryFlag
            countryCode={country_code}
            width={16}
            imageStyle={{ marginBottom: -2, marginLeft: 8 }}
          />
        </div>

        <div
          className='planner-view-player-view-header-link'
          onClick={onPlayerLinkClick}
        >
          {translate('goToPlayerProfile', userConfig?.language)}
          <ArrowRightAltIcon style={{ fontSize: 16 }} />
        </div>
      </div>

      <div className='planner-view-info-section planner-view-player-view-info-section'>

        <div className='planner-view-section-title planner-view-player-view-section-title'>
          {translate('status', userConfig?.language) + ' ' + dateToLocaleTextFormat(new Date(selectedDate), userConfig?.language ?? 'en')}
        </div>

        <div className='planner-view-info-section-entry'>
          <div className='planner-view-info-section-entry-label'>
            {translate('age', userConfig?.language)}
          </div>

          <div className='planner-view-info-section-entry-value'>
            {getAge(birth_date, selectedDate) ?? '-'}
            {birth_date && (
              <div className='planner-view-info-section-entry-info'>
                {'(' + dateStringToLocaleFormat(birth_date) + ')'}
              </div>
            )}
          </div>
        </div>

        <div className={'planner-view-info-section-entry' + (playerIsInTeam ? ' planner-view-info-section-entry-with-edit' : '')}>
          <div className='planner-view-info-section-entry-label'>
            {translate('contract', userConfig?.language)}
          </div>

          {playerIsInTeam && (
            <div className='planner-view-info-section-entry-value'>
              {getMonthsLeftOfContract(contractExpiration, selectedDate) ?? '-'}

              {contractExpiration
                ? ' ' + translate('monthsShort', userConfig?.language)
                : ''}

              {contractExpiration && (
                <div className='planner-view-info-section-entry-info'>
                  {'(' + dateStringToLocaleFormat(contractExpiration) + ')'}
                </div>
              )}
            </div>
          )}

          {!playerIsInTeam && (
            <div className='planner-view-info-section-entry-value'>
              -
            </div>
          )}

          {playerIsInTeam && (
            <IconButton
              onClick={() => openPlannerActionModal('contractExtended')}
              icon={<EditIcon style={{ fontSize: 14 }} />}
              size={22}
              style={{ position: 'absolute', marginTop: -1, right: -6, zIndex: 2 }}
            />
          )}
        </div>

        <div className={'planner-view-info-section-entry' + (playerIsInTeam ? ' planner-view-info-section-entry-with-edit' : '')}>
          <div className='planner-view-info-section-entry-label'>
            {translate('positionInTeam', userConfig?.language)}
          </div>

          <div className='planner-view-info-section-entry-value'>
            {translate(position, userConfig?.language) ?? '-'}
          </div>

          {playerIsInTeam && (
            <IconButton
              onClick={() => openPlannerActionModal('positionChanged')}
              icon={<EditIcon style={{ fontSize: 14 }} />}
              size={22}
              style={{ position: 'absolute', marginTop: -1, right: -6, zIndex: 2 }}
            />
          )}
        </div>

        <div className={'planner-view-info-section-entry' + (playerIsInTeam ? ' planner-view-info-section-entry-with-edit' : '')}>
          <div className='planner-view-info-section-entry-label'>
            {translate('teamRole', userConfig?.language)}
          </div>

          <div className='planner-view-info-section-entry-value'>
            {role !== undefined
              ? getRoleIcon(role, 18, 'light')
              : ''}
            {translate(role, userConfig?.language) ?? '-'}
          </div>

          {playerIsInTeam && (
            <IconButton
              onClick={() => openPlannerActionModal('roleChanged')}
              icon={<EditIcon style={{ fontSize: 14 }} />}
              size={22}
              style={{ position: 'absolute', marginTop: -1, right: -6, zIndex: 2 }}
            />
          )}
        </div>

        <div className={'planner-view-info-section-entry' + (playerIsInTeam ? ' planner-view-info-section-entry-with-edit' : '')}>
          <div className='planner-view-info-section-entry-label'>
            {translate('locality', userConfig?.language)}
          </div>

          <div className='planner-view-info-section-entry-value'>
            {locality !== undefined
              ? getLocalityIcon(locality, 18, 'light')
              : ''}
            {locality !== undefined
              ? translate(locality + 'Locality', userConfig?.language)
              : '-'}
          </div>

          {playerIsInTeam && (
            <IconButton
              onClick={() => openPlannerActionModal('localityChanged')}
              icon={<EditIcon style={{ fontSize: 14 }} />}
              size={22}
              style={{ position: 'absolute', marginTop: -1, right: -6, zIndex: 2 }}
            />
          )}
        </div>
      </div>

      <div className='planner-view-player-view-actions-section' style={{ height: 10 + ((playerActions.length + 1) * 10) + '%' }}>
        <div className='planner-view-section-title planner-view-player-view-section-title'>
          {translate('actions', userConfig?.language)}
        </div>

        {actionsWithDateDivider.result.length === 0 && (
          <div className='planner-view-info-section-entry-label' style={{ marginTop: 12, width: '100%', textAlign: 'center' }}>
            {translate('noActions', userConfig?.language)}
          </div>
        )}

        <div className='planner-view-actions-column planner-view-player-view-actions-column' style={{ gap: actionGap }}>
          {actionsWithDateDivider.result.map(action => {

            if (action.type === 'dateDivider') {
              return (
                <div
                  ref={dateDividerRef}
                  key='dateDivider'
                  className='planner-view-actions-column-date-divider-container planner-view-player-view-actions-column-date-divider-container'
                >
                  <div className='planner-view-actions-column-date-divider'>&nbsp;</div>
                  <div className='planner-view-actions-column-date-divider-text'>{dateStringToLocaleFormat(action.date)}</div>
                  <div className='planner-view-actions-column-date-divider'>&nbsp;</div>
                </div>
              );
            }

            const isSelected = selectedAction && selectedAction.playerId + selectedAction.type + selectedAction.date === action.playerId + action.type + action.date;

            const isInPast = action.date <= selectedDate;

            const iconNeedsShiftingRight = action.type === 'playerAdded' || action.type === 'playerRemoved' || action.type === 'positionChanged';

            return (
              <div
                ref={isSelected ? selectedActionRef : null}
                key={action.playerId + action.date + action.type}
                className={'planner-view-action-row planner-view-player-view-action-row' + (isSelected ? ' planner-view-player-view-action-row-selected' : '')}
                style={{ minHeight: actionHeight, maxHeight: actionHeight }}
                onClick={() => onActionClick(action)}
              >
                <div className='flex-row align-center' style={{ minWidth: iconNeedsShiftingRight ? 20 : 21, marginLeft: iconNeedsShiftingRight ? 5 : 4 }}>
                  {getPlannerActionIcon(action.type, 16, 'light', action.role, action.locality)}
                </div>

                <div className='flex-row align-center' style={{ minWidth: 165 }}>
                  {translate(action.type + (!isInPast ? '<future>' : ''), userConfig?.language)}

                  {action.type === 'positionChanged' && action.position && (
                    <div className='flex-row align-center' style={{ marginLeft: 3, fontSize: 10, color: '#ffffffcc' }}>
                      <ArrowRightAltIcon style={{ fontSize: 12 }} />
                      <div style={{ marginLeft: 2 }}>{translate(action.position, userConfig?.language)}</div>
                    </div>
                  )}
                </div>

                <div className='flex-row align-center' style={{ minWidth: 67, fontSize: 11, color: '#ffffffdd' }}>
                  {dateStringToLocaleFormat(action.date)}
                </div>

                <div className='flex-row align-center' style={{ width: 14 }}>
                  {isInPast && <CheckIcon style={{ fontSize: 14, color: '#90ff82' }} />}
                  {!isInPast && <HourglassEmptyIcon style={{ fontSize: 14, color: '#ffffffaa' }} />}
                </div>


                <IconButton
                  onClick={(event) => onEditActionClick(event, action)}
                  icon={<EditIcon style={{ fontSize: 14 }} />}
                  size={22}
                  style={{ position: 'absolute', marginTop: -1, right: -30, zIndex: 2 }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className='planner-view-player-view-button-section'>
        <TextButton
          onClick={() => (playerIsInTeam ? openPlannerActionModal('playerRemoved') : openPlannerActionModal('playerAdded'))}
          text={translate(playerIsInTeam ? 'removePlayer' : 'addPlayer', userConfig?.language)}
          icon={playerIsInTeam ? <PersonRemoveAlt1Icon style={{ fontSize: 18 }} /> : <PersonAddAlt1Icon style={{ fontSize: 18 }} />}
          buttonType='transparent'
          style={{ width: 200, height: 25, fontSize: 13 }}
        />
      </div>
    </div>
  );
};
