import '../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../../../common/language/translations';
import { PlayerHistory } from '../../history/PlayerHistory';
import { StringToAnyMap } from '../../../types';
import { IconButton } from '../../controls/buttons/IconButton';


interface PlayerHistoryModalProps {
  history: StringToAnyMap[];
  closeModal: () => void;
}

export const PlayerHistoryModal: React.FC<PlayerHistoryModalProps> = ({ history, closeModal }) => {

  const userConfig = useRecoilValue(userConfigState);

  return (
    <div className='modal-root-container player-history-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('playerHistory', userConfig?.language)}
      </div>

      {history.length === 0 && <div className='modal-divider modal-root-title-divider' />}

      {history.length > 0 && (
        <div className='player-history-modal-history-container'>
          <PlayerHistory
            history={history}
          />
        </div>
      )}

      {history.length === 0 && (
        <div className='player-history-modal-no-history'>
          {translate('noHistory', userConfig?.language)}
        </div>
      )}

    </div>
  );
};
