import '../../mobilePlatform.css';
import './mobilePlayerView.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { playerCareersState } from '../../../recoil/atoms/playerCareersState';

// import Dialog from '@mui/material/Dialog';
// import ConstructionIcon from '@mui/icons-material/Construction';
// import AddIcon from '@mui/icons-material/Add';
// import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
// import EditCalendarIcon from '@mui/icons-material/EditCalendar';
// import SsidChartIcon from '@mui/icons-material/SsidChart';
// import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { IconButton } from '../../../components/controls/buttons/IconButton';
import { PlayerImage } from '../../../components/display/images/PlayerImage';
import { PlayerLinePlot } from '../../../components/plots/PlayerLinePlot';
import { PlayerRadarPlot } from '../../../components/plots/PlayerRadarPlot';
import { getClubColor } from '../../../static/clubConfigs';
import { PlayerClubIteration, SelectedPlayer } from '../../../types';
import { MobilePlayerViewTab, MobilePlayerViewTabs, tabOptions } from './MobilePlayerViewTabs';


export const MobilePlayerView: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);

  const competitions = useRecoilValue(competitionsState);
  const playerCareers = useRecoilValue(playerCareersState);

  const [playerClubIterationArray, setPlayerClubIterationArray] = useState<PlayerClubIteration[] | undefined>(undefined);
  const [selectedClubIterationId, setSelectedClubIterationId] = useState<string | undefined>(undefined);
  const [selectedClubIterationIdIsInitialized, setSelectedClubIterationIdIsInitialized] = useState(false);

  const [activePlayerViewTab, setActivePlayerViewTab] = useState<MobilePlayerViewTab>('overview');
  const [tabLineOffset, setTabLineOffset] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);
  const [currentOrPreviousSelectedPlayer, setCurrentOrPreviousSelectedPlayer] = useState<SelectedPlayer | undefined>();


  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, offsetWidth } = scrollContainerRef.current;
      const totalTabs = tabOptions.length;

      const fractionalIndex = scrollLeft / offsetWidth;
      const currentIndex = Math.round(fractionalIndex);
      const newActiveTab = tabOptions[currentIndex];

      // Update the active tab when fully scrolled into a view
      if (newActiveTab !== activePlayerViewTab) {
        setActivePlayerViewTab(newActiveTab);
      }

      // Update tab-line position
      const tabLineProgress = fractionalIndex * 100 / totalTabs;
      setTabLineOffset(tabLineProgress);
    }
  };


  const handleTabClick = (tab: MobilePlayerViewTab) => {
    setActivePlayerViewTab(tab);
    const tabIndex = tabOptions.indexOf(tab);
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: tabIndex * scrollContainerRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };


  useEffect(() => {
    if (playerCareers && currentOrPreviousSelectedPlayer && currentOrPreviousSelectedPlayer.id in playerCareers && userSettings) {

      const playerCareer = playerCareers[Number(currentOrPreviousSelectedPlayer.id)];
      const clubIterationArray: PlayerClubIteration[] = [];

      Object.keys(playerCareer).forEach(clubIterationId => {
        if (playerCareer[clubIterationId].event_data_exist) {
          clubIterationArray.push({ ...playerCareer[clubIterationId], 'club_iteration_id': clubIterationId });
        }
      });

      clubIterationArray.sort(
        (a, b) => b.basic_stats?.plot_date && a.basic_stats?.plot_date
          ? b.basic_stats.plot_date.localeCompare(a.basic_stats.plot_date)
          : 0
      );

      const clubIterationArrayWithToggleConstraintsArray: PlayerClubIteration[] = [];
      let anyLeagueWithMinutes = false;

      // we only want to set a new selectedClubIterationId if none is selected or if the selected one is no longer available or blocked
      const rejectedClubIterationIds: string[] = [];
      let newSelectedClubIterationId: string | undefined = undefined;

      clubIterationArray.forEach(clubIteration => {
        if (clubIteration.event_data_exist) {

          const eventDataAvailable = clubIteration.event_data_available;

          const competitionIsLeague = clubIteration.competition_id in competitions && competitions[clubIteration.competition_id].type === 'League';

          // if selectedPositionGroupKey is 'overall', the key for iterations without 'overall' will be the primary position group for that iteration
          const selectedPositionGroupKeyForIteration = !clubIteration.position_group_stats?.overall
            ? clubIteration.position_groups[0]
            : 'overall';

          const minutesPlayed = clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration]?.minutes_played ?? 0;

          const seasonIsConstrainedByMinutes = userSettings.seasonStatsToggles
            && userSettings.seasonStatsToggles['showOnlySeasonsWith180Minutes']
            && minutesPlayed < 180;

          const seasonIsConstrainedByLeague = userSettings.seasonStatsToggles
            && userSettings.seasonStatsToggles['showOnlyDomesticLeagues']
            && !competitionIsLeague;

          const seasonIsBlocked = minutesPlayed === 0;

          if (!seasonIsConstrainedByMinutes && !seasonIsConstrainedByLeague) {
            if (
              eventDataAvailable &&
              !anyLeagueWithMinutes &&
              competitionIsLeague &&
              minutesPlayed > 0
            ) {
              newSelectedClubIterationId = clubIteration.club_iteration_id;
              anyLeagueWithMinutes = true;
            }
            else if (
              eventDataAvailable &&
              !anyLeagueWithMinutes &&
              !competitionIsLeague &&
              minutesPlayed > 0
            ) {
              newSelectedClubIterationId = clubIteration.club_iteration_id;
            }

            clubIterationArrayWithToggleConstraintsArray.push(clubIteration);
          }

          if (clubIteration.club_iteration_id && (seasonIsConstrainedByMinutes || seasonIsConstrainedByLeague || seasonIsBlocked)) {
            rejectedClubIterationIds.push(clubIteration.club_iteration_id);
          }
        }
      });

      setPlayerClubIterationArray(clubIterationArrayWithToggleConstraintsArray);

      if (
        !selectedClubIterationIdIsInitialized
        && currentOrPreviousSelectedPlayer.initialClubIteration
        && clubIterationArrayWithToggleConstraintsArray
          .some(clubIteration => clubIteration.club_iteration_id === currentOrPreviousSelectedPlayer.initialClubIteration)
      ) {
        setSelectedClubIterationId(String(currentOrPreviousSelectedPlayer.initialClubIteration));
      }

      else if (selectedClubIterationId === undefined || rejectedClubIterationIds.includes(selectedClubIterationId)) {
        setSelectedClubIterationId(newSelectedClubIterationId);
      }

      setSelectedClubIterationIdIsInitialized(true);
    }
  }, [competitions, playerCareers, currentOrPreviousSelectedPlayer, userSettings]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (selectedPlayer) {
      setCurrentOrPreviousSelectedPlayer(selectedPlayer);
    }
    const timer = setTimeout(() => {
      handleTabClick('overview');
    }, 150);

    return () => clearTimeout(timer);
  }, [selectedPlayer]);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='full-size-container'>

      <div className='mobile-player-view-header-container'>
        <div className='mobile-player-view-header-row'>
          <IconButton
            icon={<ArrowBackIosNewIcon style={{ fontSize: 22 }} />}
            onClick={() => setSelectedPlayer(undefined)}
            size={30}
            isMobile={true}
          />

          <PlayerImage
            src={currentOrPreviousSelectedPlayer?.playerOverview?.image_url}
            isSingleImage={true}
            imageStyle={{ width: 50, height: 50 }}
          />

          {currentOrPreviousSelectedPlayer?.playerOverview?.fullname}
        </div>

        <div className='mobile-player-view-header-navigation-row'>
          <MobilePlayerViewTabs
            activeTab={activePlayerViewTab}
            setActiveTab={handleTabClick}
            tabLineOffset={tabLineOffset}
          />
        </div>
      </div>

      <div className='mobile-platform-view-section mobile-player-view-section'>
        <div
          className='mobile-player-view-content-outer-container'
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          <div className='mobile-player-view-content-container'>
            <div className='mobile-player-view-content-section'>
              <div className='mobile-player-view-widget-container' style={{ height: 150 }}>
                Placeholder
              </div>

              <div className='mobile-player-view-widget-container'>
                <PlayerLinePlot
                  playerClubIterations={playerClubIterationArray ?? []}
                  selectedClubIterationId={selectedClubIterationId}
                  selectedPositionGroupKey={'overall'}
                  clubColor={clubColor}
                  competitions={competitions}
                />
              </div>

              <div className='mobile-player-view-widget-container'>
                <PlayerRadarPlot
                  playerCareer={
                    playerCareers && currentOrPreviousSelectedPlayer && currentOrPreviousSelectedPlayer.id in playerCareers
                      ? playerCareers[Number(currentOrPreviousSelectedPlayer.id)]
                      : {}
                  }
                  selectedClubIterationId={selectedClubIterationId}
                  selectedPositionGroupKey={'overall'}
                  clubColor={clubColor}
                  language={userConfig?.language ?? 'en'}
                />
              </div>

              <div className='mobile-player-view-widget-container' style={{ height: 300 }}>
                Placeholder
              </div>
            </div>
          </div>

          <div className='mobile-player-view-content-container'>
            <div className='mobile-player-view-content-section' style={{ height: '100%' }}>
              <div className='mobile-player-view-career-table-container'>
                Karrietabell
              </div>
            </div>
          </div>

          <div className='mobile-player-view-content-container'>
            <div className='mobile-player-view-content-section' style={{ height: '100%' }}>
              <div className='mobile-player-view-career-table-container'>
                Rapporter
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
