import React, { useEffect, useState } from 'react';
import '../../platform.css';
import './settings.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { useAuthContext } from '../../../common/contexts/useAuthContext';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { trackEvent } from '../../services/server/analytics/trackEvent';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import BuildIcon from '@mui/icons-material/Build';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../common/language/translations';
import { positionGroupToPositions } from '../../static/propertyValues';
import { StringToAnyMap, RoleIndexConfigMap, RoleConfig, AggregationRule } from '../../types';
import { TeamView } from '../../components/teamView/TeamView';
import { deleteRoleConfig, updatePositionToRoleIndexConfig, updateRoleConfig } from '../../services/firestore/clubSettings';
import { RoleConfigWeightBar } from '../../components/display/visualization/RoleConfigWeightBar';
import { deepCloneObject, generateNanoId, stringToNumberMapsAreEqual } from '../../utils/utils';
import { RoleConfigWeights } from './RoleConfigWeights';
import { InputField } from '../../components/controls/input/InputField';
import { userHasFullAccess } from '../../utils/userUtils';
import { IconButton } from '../../components/controls/buttons/IconButton';


export const RoleConfigs: React.FC = () => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const hasFullAccess = userHasFullAccess(userConfig);

  const { openTextModal, openConfirmModal } = useOpenGlobalModal();
  const openConfirmLeaveRoleModal = (confirm: () => void) => openConfirmModal(confirm, 'leaveRole?', 'changesWillNotBeSaved');

  const clubSettings = useRecoilValue(clubSettingsState);
  const positionGroupToRoleIds = clubSettings?.positionGroupToRoleIds ?? {};
  const roleConfigs = clubSettings?.roleConfigs ?? {};

  const [roleConfigsTeamData, setRoleConfigsTeamData] = useState<StringToAnyMap | undefined>(undefined);

  const [selectedRoleConfigIdsToView, setSelectedRoleConfigIdsToView] = useState<string[]>([]);
  const [roleConfigToAddOrEdit, setRoleConfigToAddOrEdit] = useState<RoleConfig | undefined>(undefined);
  const [inputMessageKey, setInputMessageKey] = useState<string | undefined>(undefined);

  const [roleConfigHasNameChanges, setRoleConfigHasNameChanges] = useState(false);
  const [roleConfigHasWeightsChanges, setRoleConfigHasWeightsChanges] = useState(false);
  const roleConfigHasUnsavedChanges = roleConfigHasNameChanges || roleConfigHasWeightsChanges;

  const [draggedRoleConfig, setDraggedRoleConfig] = useState<StringToAnyMap | undefined>(undefined);
  const [droppedRoleConfig, setDroppedRoleConfig] = useState<StringToAnyMap | undefined>(undefined);
  const [removeRoleConfigIsDraggedOver, setRemoveRoleConfigIsDraggedOver] = useState(false);


  const onCloseIconClick = () => {
    if (roleConfigHasUnsavedChanges) {
      openConfirmLeaveRoleModal(confirmOnCloseIconClick);
    }
    else {
      confirmOnCloseIconClick();
    }
  };

  const confirmOnCloseIconClick = () => {
    setSelectedRoleConfigIdsToView([]);
    setRoleConfigHasNameChanges(false);
    setRoleConfigHasWeightsChanges(false);
    setRoleConfigToAddOrEdit(undefined);
    setInputMessageKey(undefined);
  };


  const onAddIconClick = (positionGroup: string) => {
    if (roleConfigHasUnsavedChanges) {
      openConfirmLeaveRoleModal(() => confirmOnAddIconClick(positionGroup));
    }
    else {
      confirmOnAddIconClick(positionGroup);
    }
  };

  const confirmOnAddIconClick = (positionGroup: string) => {
    setSelectedRoleConfigIdsToView([]);
    setRoleConfigHasNameChanges(false);
    setRoleConfigHasWeightsChanges(false);
    setInputMessageKey(undefined);

    if (roleConfigToAddOrEdit?.positionGroup === positionGroup) {
      setRoleConfigToAddOrEdit(undefined);
      return;
    }

    let largestIndex = -1;
    if (positionGroup in positionGroupToRoleIds) {
      positionGroupToRoleIds[positionGroup].forEach(roleId => {
        const index = roleConfigs[roleId].positionGroupIndex;
        if (index > largestIndex) {
          largestIndex = index;
        }
      });
    }

    const roleConfigBeingAdded: RoleConfig = {
      id: undefined,
      name: '',
      positionGroup: positionGroup,
      positionGroupIndex: largestIndex + 1,
      weights: {},
      dataLastUpdated: undefined,
      weightsLastUpdated: '',
    };
    setRoleConfigToAddOrEdit(roleConfigBeingAdded);
  };


  const onEditIconClick = (roleId: string) => {
    if (roleConfigHasUnsavedChanges) {
      openConfirmLeaveRoleModal(() => confirmOnEditIconClick(roleId));
    }
    else {
      confirmOnEditIconClick(roleId);
    }
  };

  const confirmOnEditIconClick = (roleId: string) => {
    setSelectedRoleConfigIdsToView([]);
    setRoleConfigHasNameChanges(false);
    setRoleConfigHasWeightsChanges(false);
    setInputMessageKey(undefined);

    const roleConfigBeingEdited = {
      ...roleConfigs[roleId],
      id: roleId,
    };
    setRoleConfigToAddOrEdit(roleConfigBeingEdited);
  };


  const handleOnRoleConfigClickFromTeam = (roleConfigInTeam: StringToAnyMap) => {
    if (roleConfigInTeam.id in roleConfigs) {
      confirmOnRoleConfigClick(roleConfigInTeam.id);
    }
  };


  const onRoleConfigClick = (roleId: string, isSelected?: boolean) => {
    if (roleId === roleConfigToAddOrEdit?.id) return;

    if (roleConfigHasUnsavedChanges) {
      openConfirmLeaveRoleModal(() => confirmOnRoleConfigClick(roleId, isSelected));
    }
    else {
      confirmOnRoleConfigClick(roleId, isSelected);
    }
  };


  const confirmOnRoleConfigClick = (roleId: string, isSelected?: boolean) => {
    setRoleConfigToAddOrEdit(undefined);
    setRoleConfigHasNameChanges(false);
    setRoleConfigHasWeightsChanges(false);
    setInputMessageKey(undefined);

    if (isSelected) {
      setSelectedRoleConfigIdsToView(selectedRoleConfigIdsToView.filter(id => id !== roleId));
    }
    else {
      trackEvent('RoleConfigViewed', {}, currentUser, 'user');
      setSelectedRoleConfigIdsToView([...selectedRoleConfigIdsToView, roleId]);
    }
  };


  const handleOnChangeInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (roleConfigToAddOrEdit) {

      if (event.target.value.length > 14) {
        setInputMessageKey('max14CharactersInRoleName');
        return;
      }

      setInputMessageKey(undefined);

      setRoleConfigToAddOrEdit({ ...roleConfigToAddOrEdit, name: event.target.value });
      if (roleConfigToAddOrEdit.id) {
        setRoleConfigHasNameChanges(
          event.target.value !== roleConfigs[roleConfigToAddOrEdit.id].name
        );
      }
      else {
        setRoleConfigHasNameChanges(event.target.value.length > 0);
      }
    }
  };


  const handleResetInputField = () => {
    if (roleConfigToAddOrEdit) {
      setRoleConfigToAddOrEdit({ ...roleConfigToAddOrEdit, name: '' });
      setRoleConfigHasNameChanges(roleConfigToAddOrEdit.id !== undefined);
      setInputMessageKey(undefined);
    }
  };


  const isValidRoleConfigToAddOrEdit = () => {
    if (!roleConfigToAddOrEdit) return false;

    if (roleConfigToAddOrEdit.name.length === 0) {
      setInputMessageKey('youMustProvideNameOfRole');
      return false;
    }

    if (Object.keys(roleConfigToAddOrEdit.weights).length === 0) {
      setInputMessageKey('youMustProvideWeightsForRole');
      return false;
    }

    setInputMessageKey(undefined);
    return true;
  };


  const onConfirmIconClick = () => {
    if (!roleConfigToAddOrEdit || !isValidRoleConfigToAddOrEdit()) return;

    if (roleConfigToAddOrEdit.id) {
      if (roleConfigHasWeightsChanges) {
        openConfirmModal(handleUpdateRoleConfig, 'confirmEdits', 'confirmRoleEditsInfo');
      }
      else {
        handleUpdateRoleConfig();
      }
    }

    else {
      openConfirmModal(handleAddRoleConfig, 'createRole', 'createRoleInfo');
    }
  };


  const handleUpdateRoleConfig = async () => {
    if (!roleConfigToAddOrEdit?.id || !userConfig || !clubSettings) return;

    if (roleConfigHasUnsavedChanges) {
      const newRoleConfig: RoleConfig = {
        name: roleConfigToAddOrEdit.name,
        weights: roleConfigToAddOrEdit.weights,
        positionGroup: roleConfigToAddOrEdit.positionGroup,
        positionGroupIndex: roleConfigToAddOrEdit.positionGroupIndex,
        weightsLastUpdated: roleConfigHasWeightsChanges ? new Date().toISOString() : roleConfigToAddOrEdit.weightsLastUpdated,
        dataLastUpdated: roleConfigToAddOrEdit.dataLastUpdated,
      };

      await updateRoleConfig(
        roleConfigToAddOrEdit.id,
        newRoleConfig,
        userConfig.club,
        roleConfigHasWeightsChanges ? 'RoleConfigEdited' : 'RoleConfigNameEdited',
        currentUser
      );
    }

    setRoleConfigHasNameChanges(false);
    setRoleConfigHasWeightsChanges(false);
    setRoleConfigToAddOrEdit(undefined);
    setInputMessageKey(undefined);
    setSelectedRoleConfigIdsToView([roleConfigToAddOrEdit.id]);
  };


  const handleAddRoleConfig = async () => {
    if (!roleConfigToAddOrEdit || !userConfig || !clubSettings) return;

    const newRoleId = userConfig.club + '_' + roleConfigToAddOrEdit.positionGroup + '_' + generateNanoId();
    const newRoleIdWithoutHyphens = newRoleId.replace('-', '');
    const newRoleConfig: RoleConfig = {
      name: roleConfigToAddOrEdit.name,
      weights: roleConfigToAddOrEdit.weights,
      positionGroup: roleConfigToAddOrEdit.positionGroup,
      positionGroupIndex: roleConfigToAddOrEdit.positionGroupIndex,
      weightsLastUpdated: new Date().toISOString(),
      dataLastUpdated: null,
    };

    await updateRoleConfig(newRoleIdWithoutHyphens, newRoleConfig, userConfig.club, 'RoleConfigCreated', currentUser);

    setRoleConfigHasNameChanges(false);
    setRoleConfigHasWeightsChanges(false);
    setRoleConfigToAddOrEdit(undefined);
    setInputMessageKey(undefined);
    setSelectedRoleConfigIdsToView([newRoleIdWithoutHyphens]);
  };


  const onDeleteIconClick = () => {
    if (!roleConfigToAddOrEdit || !roleConfigToAddOrEdit.id) return;
    openConfirmModal(handleDeleteRoleConfig, 'deleteRole', 'noRegretInfo');
  };


  const handleDeleteRoleConfig = async () => {
    if (!roleConfigToAddOrEdit?.id || !userConfig || !clubSettings) return;

    // positionToRoleIndexConfig must also be updated
    const newPositionToRoleIndexConfig: RoleIndexConfigMap = {};
    Object.entries(clubSettings.positionToRoleIndexConfig).forEach(([position, roleIndexConfig]) => {
      const newRoleIds = roleIndexConfig.roleIds.filter(roleId => roleId !== roleConfigToAddOrEdit.id);
      if (newRoleIds.length >= 1) {
        newPositionToRoleIndexConfig[position] = {
          roleIds: newRoleIds,
          aggregationRule: newRoleIds.length === 1 ? 'priority' : roleIndexConfig.aggregationRule,
        };
      }
    });

    await deleteRoleConfig(roleConfigToAddOrEdit.id, newPositionToRoleIndexConfig, userConfig.club, currentUser);
    confirmOnCloseIconClick();
  };


  const handleSetRoleConfigToAddOrEdit = (roleConfig: RoleConfig) => {
    if (!roleConfigToAddOrEdit) return;
    setRoleConfigToAddOrEdit(roleConfig);

    // if this an edit, we check if the weights are actually different from the original
    if (roleConfigToAddOrEdit.id) {
      setRoleConfigHasWeightsChanges(
        !stringToNumberMapsAreEqual(roleConfig.weights, roleConfigs[roleConfigToAddOrEdit.id].weights)
      );
    }
    else {
      setRoleConfigHasWeightsChanges(true);
    }
  };


  const handleSetDraggedRoleConfig = (roleId: string | undefined) => {
    if (roleId === undefined) {
      setDraggedRoleConfig(undefined);
      return;
    }

    if (clubSettings && roleId in clubSettings.roleConfigs) {
      setDraggedRoleConfig({
        ...clubSettings.roleConfigs[roleId],
        positionKey: 'roleConfigs',
        id: roleId,
      });
    }
  };


  const handleRoleConfigDrop = async (newPosition: string, newPositionIndex?: number) => {
    if (!draggedRoleConfig || !userConfig || !clubSettings) return;

    disableHover();

    // either (1) a new role is added from the left, (2) a role has changed position, or (3) a role has changed position index within the same position
    const newPositionToRoleIndexConfig: RoleIndexConfigMap = deepCloneObject(clubSettings.positionToRoleIndexConfig);

    // in all cases, the role index config for the new position must be updated the same way
    if (newPositionToRoleIndexConfig[newPosition] === undefined) {
      newPositionToRoleIndexConfig[newPosition] = {
        roleIds: [draggedRoleConfig.id],
        aggregationRule: 'priority',
      };
    }
    else {
      // the filtering here will remove the old role if case (3)
      const updatedRoleIds = newPositionToRoleIndexConfig[newPosition].roleIds.filter(roleId => roleId !== draggedRoleConfig.id);
      updatedRoleIds.splice(newPositionIndex ?? updatedRoleIds.length, 0, draggedRoleConfig.id);
      newPositionToRoleIndexConfig[newPosition].roleIds = updatedRoleIds;
    }

    // remove the old role if case (2)
    if (draggedRoleConfig.positionKey !== 'roleConfigs' && draggedRoleConfig.positionKey !== newPosition) {
      newPositionToRoleIndexConfig[draggedRoleConfig.positionKey].roleIds = newPositionToRoleIndexConfig[draggedRoleConfig.positionKey].roleIds.filter(
        roleId => roleId !== draggedRoleConfig.id
      );

      if (newPositionToRoleIndexConfig[draggedRoleConfig.positionKey].roleIds.length === 0) {
        delete newPositionToRoleIndexConfig[draggedRoleConfig.positionKey];
      }
    }

    setDroppedRoleConfig({ ...draggedRoleConfig, droppedPositionKey: newPosition });
    await updatePositionToRoleIndexConfig(newPositionToRoleIndexConfig, userConfig.club, 'RoleConfigMoved', currentUser);

    setDraggedRoleConfig(undefined);
  };


  const handleRemoveRoleConfigDrop = async () => {
    setRemoveRoleConfigIsDraggedOver(false);

    if (!draggedRoleConfig || !userConfig || !clubSettings) return;

    const newPositionToRoleIndexConfig: RoleIndexConfigMap = deepCloneObject(clubSettings.positionToRoleIndexConfig);
    const positionOfDraggedRole = draggedRoleConfig['positionKey'];

    newPositionToRoleIndexConfig[positionOfDraggedRole].roleIds = newPositionToRoleIndexConfig[positionOfDraggedRole].roleIds.filter(
      roleId => roleId !== draggedRoleConfig.id
    );

    if (newPositionToRoleIndexConfig[positionOfDraggedRole].roleIds.length === 0) {
      delete newPositionToRoleIndexConfig[positionOfDraggedRole];
    }

    setDroppedRoleConfig({ ...draggedRoleConfig, droppedPositionKey: 'delete' });
    await updatePositionToRoleIndexConfig(newPositionToRoleIndexConfig, userConfig.club, 'RoleConfigMoved', currentUser);
  };


  const confirmNewAggregationRule = async (newAggregation: AggregationRule, positionKey: string) => {
    if (!userConfig || !clubSettings) return;

    const newPositionToRoleIndexConfig: RoleIndexConfigMap = deepCloneObject(clubSettings.positionToRoleIndexConfig);

    if (newPositionToRoleIndexConfig[positionKey] !== undefined) {
      newPositionToRoleIndexConfig[positionKey].aggregationRule = newAggregation;
    }

    await updatePositionToRoleIndexConfig(newPositionToRoleIndexConfig, userConfig.club, 'RoleConfigAggregationRuleEdited', currentUser);
  };


  const handleRoleConfigRemoveDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };


  const handleRoleConfigRemoveDragEnter = () => {
    setRemoveRoleConfigIsDraggedOver(true);
  };


  const handleRoleConfigRemoveDragLeave = () => {
    setRemoveRoleConfigIsDraggedOver(false);
  };


  const roleConfigIsReady = (roleId: string) => {
    const roleConfig = roleConfigs[roleId];
    return roleConfig
      && roleConfig.weightsLastUpdated
      && roleConfig.dataLastUpdated
      && roleConfig.dataLastUpdated >= roleConfig.weightsLastUpdated;
  };


  useEffect(() => {
    if (!clubSettings) return;

    const teamData: StringToAnyMap = {
      formation: clubSettings.formation,
    };

    Object.entries(clubSettings.positionToRoleIndexConfig).forEach(([position, roleIndexConfig]) => {
      teamData[position] = [];
      roleIndexConfig.roleIds.forEach(roleId => {
        if (roleId in clubSettings.roleConfigs) {
          teamData[position].push({
            id: roleId,
            name: clubSettings.roleConfigs[roleId].name,
            positionGroup: clubSettings.roleConfigs[roleId].positionGroup,
            aggregationRule: roleIndexConfig.aggregationRule,
          });
        }
      });
    });

    setRoleConfigsTeamData(teamData);
  }, [clubSettings]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setDroppedRoleConfig(undefined);
    }, 400);
    return () => clearTimeout(timer);
  }, [droppedRoleConfig]);


  const [tableHoverDisabled, setTableHoverDisabled] = useState(false);
  const disableHover = () => {
    setTableHoverDisabled(true);
    document.addEventListener('mousemove', enableHoverOnMouseMove);
  };
  const enableHoverOnMouseMove = () => {
    setTableHoverDisabled(false);
    document.removeEventListener('mousemove', enableHoverOnMouseMove);
  };


  const isTeamView = selectedRoleConfigIdsToView.length === 0 && !roleConfigToAddOrEdit;


  return (
    <div className='full-size-container'>

      <div className='role-configs-left-container'>

        <div className='role-configs-left-container-vertical-divider role-configs-left-container-vertical-left-divider' />
        <div className='role-configs-left-container-vertical-divider role-configs-left-container-vertical-right-divider' />

        {Object.keys(positionGroupToPositions).map((positionGroup, index) => {
          return (
            <React.Fragment key={index}>
              <div className='role-configs-position-group-container'>

                <div className='role-configs-position-group-positions'>
                  {positionGroupToPositions[positionGroup].map((position, index) => {
                    return (
                      <div key={index} className='role-configs-position-group-position'>
                        {translate(position, userConfig?.language)}
                      </div>
                    );
                  })}
                </div>

                <div className='role-configs-position-group-roles-container'>
                  {(positionGroupToRoleIds[positionGroup] ?? []).map(roleId => {

                    if (!(roleId in roleConfigs)) return null;

                    const isSelected = selectedRoleConfigIdsToView.includes(roleId);
                    const isEdited = roleConfigToAddOrEdit?.id === roleId;

                    return (
                      <div
                        key={roleId}
                        className={
                          'role-configs-position-group-role'
                          + (isSelected ? ' role-configs-position-group-role-selected' : '')
                          + (isEdited ? ' role-configs-position-group-role-edited' : '')
                        }
                        onClick={() => onRoleConfigClick(roleId, isSelected)}
                        draggable={isTeamView}
                        onDragStart={() => handleSetDraggedRoleConfig(roleId)}
                        onDragEnd={() => handleSetDraggedRoleConfig(undefined)}
                      >
                        {roleConfigs[roleId].name}
                      </div>
                    );
                  })}
                </div>

                {(positionGroupToRoleIds[positionGroup] ?? []).length < 4 && !roleConfigToAddOrEdit?.id && hasFullAccess && (
                  <IconButton
                    onClick={() => onAddIconClick(positionGroup)}
                    icon={<AddIcon style={{ fontSize: 18 }} />}
                    size={24}
                    style={{ position: 'absolute', right: 12 }}
                    isActive={roleConfigToAddOrEdit?.positionGroup === positionGroup}
                    title={translate('createNewRole', userConfig?.language)}
                  />
                )}

              </div>

              {index < Object.keys(positionGroupToPositions).length - 1 && <div className='role-configs-divider' />}
            </React.Fragment>
          );
        })}
      </div>

      <div className='role-configs-right-container'>

        {isTeamView && (
          <div className='role-configs-team-view-container fade-in'>

            <div className='role-configs-swipe-icon'>
              <SwipeRightIcon style={{ fontSize: 22 }} />
            </div>

            <div className='role-configs-team-view-container-remove-container'>
              <div
                className={
                  'role-configs-team-view-container-remove-icon' +
                  (draggedRoleConfig && draggedRoleConfig['positionKey'] !== 'roleConfigs' ? ' role-configs-team-view-container-remove-icon-active' : '')
                }
                style={{ boxShadow: removeRoleConfigIsDraggedOver ? '0px 0px 0px 3px #ff2424' : undefined }}
              >
                <DeleteForeverIcon style={{ fontSize: 28 }} />
              </div>

              {draggedRoleConfig && draggedRoleConfig['positionKey'] !== 'roleConfigs' && (
                <div
                  className='role-configs-team-view-container-remove-overlay'
                  onDragOver={handleRoleConfigRemoveDragOver}
                  onDragEnter={handleRoleConfigRemoveDragEnter}
                  onDragLeave={handleRoleConfigRemoveDragLeave}
                  onDrop={handleRemoveRoleConfigDrop}
                />
              )}
            </div>

            <div className='role-configs-team-view-container-info-text'>
              <div style={{ textAlign: 'center', lineHeight: 1.4 }}>
                {translate('roleConfigsInfoText1', userConfig?.language)}
                <i>
                  {' ' + translate('roleIndex', userConfig?.language) + ' '}
                </i>
                {translate('roleConfigsInfoText2', userConfig?.language)}
              </div>

              <IconButton
                onClick={() => openTextModal(
                  translate('roleIndexInTeam', userConfig?.language),
                  translate('roleConfigsInfoModalText', userConfig?.language),
                  { infoType: 'infoIcon', titleKey: 'roleIndexInTeam' }
                )}
                icon={<InfoOutlinedIcon style={{ fontSize: 14 }} />}
                size={20}
                style={{ marginTop: 1, marginLeft: 6 }}
              />
            </div>

            <TeamView
              teamOrSquadId={'roleConfigs'}
              teamOrSquadData={roleConfigsTeamData}
              draggedPlayer={draggedRoleConfig}
              setDraggedPlayer={setDraggedRoleConfig}
              handlePlayerDrop={handleRoleConfigDrop}
              droppedPlayer={droppedRoleConfig}
              setDroppedPlayer={setDroppedRoleConfig}
              handlePlayerClick={handleOnRoleConfigClickFromTeam}
              confirmNewAggregationRule={confirmNewAggregationRule}
              tableHoverDisabled={tableHoverDisabled}
            />
          </div>
        )}

        {!isTeamView && (
          <IconButton
            onClick={onCloseIconClick}
            icon={<CloseIcon style={{ fontSize: 24 }} />}
            size={26}
            style={{ position: 'absolute', top: 50, right: 50, zIndex: 5 }}
            startWhite={true}
            whiteBackgroundOnHover={true}
          />
        )}

        {(roleConfigToAddOrEdit || selectedRoleConfigIdsToView.length === 1) && hasFullAccess && (
          <IconButton
            onClick={
              () => roleConfigToAddOrEdit
                ? onConfirmIconClick()
                : onEditIconClick(selectedRoleConfigIdsToView[0])
            }
            icon={
              roleConfigToAddOrEdit
                ? <CheckIcon style={{ fontSize: 24, marginTop: -1 }} />
                : <EditNoteIcon style={{ fontSize: 24, marginLeft: 2.5, marginTop: -1 }} />
            }
            size={26}
            style={{ position: 'absolute', top: 50, right: 105, zIndex: 5 }}
            startWhite={true}
            whiteBackgroundOnHover={true}
            title={translate(
              roleConfigToAddOrEdit
                ? roleConfigToAddOrEdit.id ? 'confirmEdits' : 'createRole'
                : 'editRole',
              userConfig?.language)}
          />
        )}

        {roleConfigToAddOrEdit?.id && (
          <IconButton
            onClick={onDeleteIconClick}
            icon={<DeleteForeverIcon style={{ fontSize: 18, marginTop: -1 }} />}
            size={26}
            style={{ position: 'absolute', top: 53, left: 16, zIndex: 5 }}
            startWhite={true}
            whiteBackgroundOnHover={true}
            title={translate('deleteRole', userConfig?.language)}
          />
        )}

        {roleConfigToAddOrEdit && !roleConfigToAddOrEdit.id && (
          <div className='role-configs-position-group-of-new-role fade-in'>
            {positionGroupToPositions[roleConfigToAddOrEdit.positionGroup].map((position, index) => {
              return (
                <div key={index} className='role-configs-new-role-config-position'>
                  {translate(position, userConfig?.language)}
                </div>
              );
            })}
          </div>
        )}

        {roleConfigToAddOrEdit && (
          <div className='role-configs-new-role-config-title fade-in'>
            {translate(roleConfigToAddOrEdit.id ? 'editRole' : 'createNewRole', userConfig?.language)}
          </div>
        )}

        {(roleConfigToAddOrEdit || selectedRoleConfigIdsToView.length === 1) && (
          <div className='role-configs-selected-role-config-container fade-in'>

            {inputMessageKey && (
              <div className='role-configs-input-message'>
                {translate(inputMessageKey, userConfig?.language)}
              </div>
            )}

            {!roleConfigToAddOrEdit && !roleConfigIsReady(selectedRoleConfigIdsToView[0]) && (
              <div className='role-configs-selected-role-config-being-computed'>
                <BuildIcon style={{ fontSize: 16 }} />
                {translate('beingComputed', userConfig?.language)}
              </div>
            )}

            {!roleConfigToAddOrEdit && (
              <div className='role-configs-selected-role-config-title'>
                {roleConfigs[selectedRoleConfigIdsToView[0]].name}
              </div>
            )}

            {roleConfigToAddOrEdit && (
              <div
                className='role-configs-selected-role-config-title fade-in'
                style={{ width: 180 }}
              >
                <InputField
                  value={roleConfigToAddOrEdit.name}
                  placeholder={translate('provideNameofRole', userConfig?.language)}
                  onChange={event => handleOnChangeInputField(event)}
                  resetValue={handleResetInputField}
                />
              </div>
            )}

            <div className='role-configs-selected-role-weight-bar-container'>
              <RoleConfigWeightBar
                roleConfig={roleConfigToAddOrEdit ?? roleConfigs[selectedRoleConfigIdsToView[0]]}
              />
            </div>

            <div className='role-configs-selected-role-weights-container'>
              <RoleConfigWeights
                roleConfig={roleConfigToAddOrEdit ?? roleConfigs[selectedRoleConfigIdsToView[0]]}
                isEditMode={roleConfigToAddOrEdit !== undefined}
                setRoleConfig={handleSetRoleConfigToAddOrEdit}
                setInputMessageKey={setInputMessageKey}
              />
            </div>
          </div>
        )}

        {selectedRoleConfigIdsToView.length > 1 && (
          <div className='role-configs-selected-role-config-container fade-in'>

            <div className='role-configs-selected-role-config-title'>
              {translate('compareRoles', userConfig?.language)}
            </div>

            <div className='role-configs-compare-container'>
              {selectedRoleConfigIdsToView.map(roleId => {

                const roleConfig = roleConfigs[roleId];

                return (
                  <React.Fragment key={roleId}>
                    <div className='role-configs-comparison-divider' />

                    <div className='role-configs-selected-role-config-for-comparison'>

                      <div className='role-configs-selected-role-config-for-comparison-title'>
                        {roleConfig.name}

                        {hasFullAccess && (
                          <IconButton
                            onClick={() => onEditIconClick(roleId)}
                            icon={<EditNoteIcon style={{ fontSize: 16, marginLeft: 2 }} />}
                            size={22}
                            style={{ position: 'absolute', top: 0, right: -2 }}
                            title={translate('editRole', userConfig?.language)}
                          />
                        )}

                        <IconButton
                          onClick={() => onRoleConfigClick(roleId, true)}
                          icon={<CloseIcon style={{ fontSize: 16 }} />}
                          size={22}
                          style={{ position: 'absolute', top: 0, right: hasFullAccess ? -30 : -2 }}
                        />
                      </div>

                      <div className='role-configs-selected-role-weight-bar-container'>
                        <RoleConfigWeightBar
                          roleConfig={roleConfig}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>

          </div>
        )}

      </div>

    </div>
  );
};
