import './buttons.css';

import { JSX } from 'react';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import BrushIcon from '@mui/icons-material/Brush';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { translate } from '../../../../common/language/translations';


interface OptionButtonProps {
  // option
  onClick: () => void;
  option: string;
  language: string | undefined;
  optionIsSelected?: boolean;
  showContent?: boolean;

  // icons
  icons?: {
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    sortIcon?: boolean;
    colorIcon?: boolean;
    switchIcon?: boolean;
    categoryIcon?: boolean;
    calendarIcon?: boolean;
  };
  selectedIcons?: {
    sortIcon?: boolean;
    colorIcon?: boolean;
    switchIcon?: boolean;
    gridIcon?: boolean;
    categoryIcon?: boolean;
    calendarIcon?: boolean;
  };
  onIconClick?: (option: string, iconProperty: string, isIconAlreadySelected: boolean, event: React.MouseEvent<HTMLElement>) => void;
  iconIsHighlighted?: boolean;

  // styling
  style?: React.CSSProperties;
}

export const OptionButton: React.FC<OptionButtonProps> = ({
  onClick,
  option,
  optionIsSelected,
  language,
  showContent = true,

  icons,
  selectedIcons,
  onIconClick,
  iconIsHighlighted,

  style,
}) => {

  return (
    <div
      className={'option-button-container' + (optionIsSelected ? ' option-button-container-selected' : '')}
      onClick={() => onClick()}
      style={{
        paddingLeft: !icons ? 12 : undefined,
        ...style,
      }}
    >

      {showContent && icons?.leftIcon && (
        <div className={'option-button-icon option-button-left-icon'}>
          {icons.leftIcon}
        </div>
      )}

      {showContent && translate(option, language)}

      {showContent && icons?.switchIcon && onIconClick && (
        <div
          className={'option-button-interactive-icon option-button-switch-icon' + (selectedIcons?.switchIcon ? ' option-button-interactive-icon-selected' : '')}
          onClick={(event) => onIconClick(option, 'showLeft', selectedIcons?.switchIcon ?? false, event)}
          title={translate('showLeft', language)}
        >
          <SwapHorizIcon style={{ fontSize: 17 }} />
        </div>
      )}

      {showContent && icons?.categoryIcon && onIconClick && (
        <div
          className={'option-button-interactive-icon option-button-switch-icon' + (selectedIcons?.categoryIcon ? ' option-button-interactive-icon-selected' : '')}
          onClick={(event) => onIconClick(option, 'showAgeCategories', selectedIcons?.categoryIcon ?? false, event)}
          title={translate('showAgeCategories', language)}
        >
          <DonutLargeIcon style={{ fontSize: 15 }} />
        </div>
      )}

      {showContent && icons?.calendarIcon && onIconClick && (
        <div
          className={'option-button-interactive-icon option-button-switch-icon' + (selectedIcons?.calendarIcon ? ' option-button-interactive-icon-selected' : '')}
          onClick={(event) => onIconClick(option, 'showContractExpirationYear', selectedIcons?.calendarIcon ?? false, event)}
          title={translate('showExpirationYear', language)}
        >
          <EventBusyIcon style={{ fontSize: 15, marginBottom: -1 }} />
        </div>
      )}

      {showContent && icons?.colorIcon && onIconClick && (
        <div
          className={'option-button-interactive-icon option-button-color-icon' + (selectedIcons?.colorIcon ? ' option-button-interactive-icon-selected' : '')}
          onClick={(event) => onIconClick(option, 'colored', selectedIcons?.colorIcon ?? false, event)}
          title={translate('showColors', language)}
        >
          <BrushIcon style={{ fontSize: 16 }} />
        </div>
      )}

      {showContent && icons?.sortIcon && onIconClick && (
        <div
          className={'option-button-interactive-icon option-button-sort-icon' + (selectedIcons?.sortIcon ? ' option-button-interactive-icon-selected' : '')}
          onClick={(event) => onIconClick(option, 'orderBy', selectedIcons?.sortIcon ?? false, event)}
          title={translate('orderBy', language)}
        >
          <SwapVertIcon style={{ fontSize: 17 }} />
        </div>
      )}

      {showContent && icons?.rightIcon && (
        <div className={'option-button-icon option-button-right-icon' + (iconIsHighlighted ? ' option-button-icon-highlighted' : '')}>
          {icons.rightIcon}
        </div>
      )}

    </div>
  );
};
