import { db, FirebaseUser } from '../../../firebase';
import {
  collection,
  addDoc,
  doc,
  onSnapshot,
  setDoc,
  deleteDoc,
  getDocs,
  getDoc
} from 'firebase/firestore';

import {
  StringToAnyMap,
  PlayerCollection,
  PlayerDocument,
  PlayerEntry,
  PlayerId,
  PlayerReport,
  PlannerConfigAction
} from '../../types';

import { getStrictPlayerId } from '../../utils/playerUtils';
import { removePlayerFromAllTeamsAndSquads, removePlayerFromTeamOrSquad } from './teamsOrSquads';
import { getReportsForPlayer, updateReport } from './reports';
import { getPlayerActivites, updatePlayerOfActivity } from './activities';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { trackEvent } from '../server/analytics/trackEvent';
import { deletePlayerFromPlanner } from './planner';


// Get players
export const getPlayers = (setPlayers: (players: PlayerCollection) => void, club: string) => {

  const playersCollectionRef = collection(db, 'configs', club, 'players');

  const unsubscribe = onSnapshot(playersCollectionRef, snapshot => {

    const players: PlayerCollection = {};
    snapshot.forEach((doc) => {
      const playerId: PlayerId = getStrictPlayerId(doc.id);
      players[playerId] = { ...doc.data(), id: playerId } as PlayerDocument;
    });

    setPlayers(players);
  });

  return unsubscribe;
};


// Add a new player that was added manually without data to the players collection and return the document id as the new player id
export const addPlayerWithoutData = async (
  fullname: string,
  club: string,
  currentUser: FirebaseUser,
): Promise<string | undefined> => {

  const playersCollectionRef = collection(db, 'configs', club, 'players');

  const dateAdded = (new Date()).toISOString().split('T')[0];

  try {
    const docRef = await addDoc(playersCollectionRef, { fullname: fullname, dateAdded: dateAdded });
    trackEvent('PlayerCreated', {}, currentUser, 'user');
    return docRef.id;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addPlayerWithoutData', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Set a property of a player document
export const setPlayerProperties = async (
  playerId: PlayerId,
  properties: Record<string, unknown>,
  club: string,
  currentUser: FirebaseUser,
  shouldTrackEvent?: boolean,
) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  try {
    await setDoc(playerDocRef, properties, { merge: true });
    if (shouldTrackEvent) {
      trackEvent('PlayerInfoProvided', {}, currentUser, 'user');
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'setPlayerProperties', errorDetails: { error } }, currentUser, 'system');
  }
};


// Delete a player document and remove from all teams and squad (archived teams and reports and history will remain)
export const deletePlayerFromPlatform = async (
  playerId: PlayerId,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  try {
    await deleteDoc(playerDocRef);
    await removePlayerFromAllTeamsAndSquads(playerId, userEmail, club, currentUser);
    await deletePlayerFromPlanner(playerId, club, currentUser);

    trackEvent('PlayerDeleted', {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deletePlayerFromPlatform', errorDetails: { error } }, currentUser, 'system');
  }
};


// Delete a player document
export const deletePlayerDocument = async (playerId: PlayerId, club: string) => {
  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));
  deleteDoc(playerDocRef);
};


// Delete data from the player collection related to ownTeam
export const deleteOwnTeamAndAcademyPlayerData = async (
  playerId: PlayerId,
  club: string,
  currentUser: FirebaseUser,
) => {

  const playerDocRef = doc(db, 'configs', club, 'players', String(playerId));

  try {
    await setDoc(playerDocRef, {
      contract_expiration: null,
      market_value: null,
      isStartingEleven: null,
      role: null,
      clubValue: null,
      signingCost: null,
      salary: null,
      bonuses: null,
      isLocal: null,
    }, { merge: true });
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteOwnTeamAndAcademyPlayerData', errorDetails: { error } }, currentUser, 'system');
  }
};


// Connect a player without data to a player with data
export const connectPlayerWithoutData = async (
  currentPlayerId: PlayerId,
  currentTeam: string,
  newPlayerId: PlayerId,
  newPlayerFullname: string,
  newPlayerTeam: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  // every instance of currentPlayerId (and fullname) in reports, activities, archivedTeams and squads will be replaced with newPlayerId (and newPlayerFullname)
  try {

    // reports
    const reportsOfPlayer: PlayerReport[] = (await getReportsForPlayer(currentPlayerId, club, currentUser)).reports;
    reportsOfPlayer.forEach(report => {
      if (report.id) {
        const newValues = { playerId: newPlayerId, playerName: newPlayerFullname };
        updateReport(report.id, newValues, club, currentUser);
      }
    });

    // activities
    const activitiesOfPlayer: StringToAnyMap[] = await getPlayerActivites(currentPlayerId, club);
    activitiesOfPlayer.forEach(activity => {
      updatePlayerOfActivity(activity.id, newPlayerId, newPlayerFullname, club, currentUser);
    });

    // archivedTeams
    const archivedTeamsCollectionRef = collection(db, 'configs', club, 'archivedTeams');
    const archivedTeamsSnapshot = await getDocs(archivedTeamsCollectionRef);
    archivedTeamsSnapshot.docs.forEach(doc => {
      const archivedTeam = doc.data();
      positionOptionsPlatform.forEach(position => {
        if (archivedTeam[position]) {
          const newPositionList: PlayerEntry[] = [];
          archivedTeam[position].forEach((player: PlayerEntry) => {
            if (player.id === currentPlayerId) {
              newPositionList.push({ id: newPlayerId, fullname: newPlayerFullname });
            }
            else {
              newPositionList.push(player);
            }
          });
          archivedTeam[position] = newPositionList;
        }
      });
      setDoc(doc.ref, archivedTeam);
    });

    // planner
    const plannerConfigDocRef = doc(db, 'configs', club, 'planner', 'config');
    const docSnap = await getDoc(plannerConfigDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data?.plannerConfig) {
        const plannerConfig = data.plannerConfig;

        const currentPlayerActions: PlannerConfigAction[] = plannerConfig[currentPlayerId] ?? [];
        const newPlayerActions: PlannerConfigAction[] = [];

        currentPlayerActions.forEach(action => {
          if (action.fullname) {
            newPlayerActions.push({ ...action, fullname: newPlayerFullname });
          }
          else {
            newPlayerActions.push(action);
          }
        });

        delete plannerConfig[currentPlayerId];
        plannerConfig[newPlayerId] = newPlayerActions;

        setDoc(
          plannerConfigDocRef,
          { plannerConfig: plannerConfig },
        );
      }
    }

    // squads
    const squadsCollectionRef = collection(db, 'configs', club, 'squads');
    const squadsSnapshot = await getDocs(squadsCollectionRef);
    squadsSnapshot.docs.forEach(doc => {
      const squad = doc.data();
      positionOptionsPlatform.forEach(position => {
        if (squad[position]) {
          const newPositionList: PlayerEntry[] = [];
          squad[position].forEach((player: PlayerEntry) => {
            if (player.id === currentPlayerId) {
              newPositionList.push({ id: newPlayerId, fullname: newPlayerFullname });
            }
            else {
              newPositionList.push(player);
            }
          });
          squad[position] = newPositionList;
        }
      });

      const newSquadHistory = squad.history.map((item: StringToAnyMap) => {
        if (item.playerId === currentPlayerId) {
          item.playerId = newPlayerId;
          item.playerName = newPlayerFullname;
        }
        return item;
      });
      squad.history = newSquadHistory;

      setDoc(doc.ref, squad);
    });


    // update player collection appropriately - the player document should only remain in case currentTeam is 'ownTeam'
    let currentPlayerDocument: StringToAnyMap | undefined = undefined;
    if (currentTeam === 'ownTeam') {
      const playerDocRef = doc(db, 'configs', club, 'players', String(currentPlayerId));
      currentPlayerDocument = (await getDoc(playerDocRef)).data();
      if (currentPlayerDocument) {
        currentPlayerDocument['dateAdded'] = null;
        currentPlayerDocument['fullname'] = newPlayerFullname;
      }
    }

    deletePlayerDocument(currentPlayerId, club);
    deletePlayerDocument(newPlayerId, club);

    if (currentPlayerDocument) {
      const playerDocRef = doc(db, 'configs', club, 'players', String(newPlayerId));
      await setDoc(playerDocRef, currentPlayerDocument);
    }

    // update currentTeam and newPlayerTeam correctly
    if (newPlayerTeam) {
      await removePlayerFromTeamOrSquad(newPlayerId, newPlayerTeam, false, userEmail, club, currentUser);
    }
    if (currentTeam) {
      const teamDocRef = doc(db, 'configs', club, 'teams', currentTeam);
      const currentTeamData = (await getDoc(teamDocRef)).data();

      positionOptionsPlatform.forEach(position => {
        if (currentTeamData && currentTeamData[position]) {
          const newPositionList: PlayerEntry[] = [];
          currentTeamData[position].forEach((player: PlayerEntry) => {
            if (player.id === currentPlayerId) {
              newPositionList.push({ id: newPlayerId, fullname: newPlayerFullname });
            }
            else {
              newPositionList.push(player);
            }
          });
          currentTeamData[position] = newPositionList;
        }
      });

      setDoc(teamDocRef, currentTeamData);
    }

    trackEvent('PlayerConnected', {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'connectPlayerWithoutData', errorDetails: { error } }, currentUser, 'system');
  }
};
