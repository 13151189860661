import { languageToLocale } from '../../common/language/translations';


// Gets last two digits of the year of a date
export const getYearShort = (date: string) => {
  if (date) {
    return date.split('-')[0].substring(2);
  }
  return '';
};


// Gets the date in locale format (dd/mm/yyyy, yyyy//mm/dd, etc.)
export const dateStringToLocaleFormat = (date: string | undefined) => {
  if (!date) {
    return '-';
  }

  const dateList = date.split('-');

  return dateList[2] + '.' + dateList[1] + '.' + dateList[0];
};


export const dateToLocaleTextFormat = (date: Date, language: string) => {
  const locale = languageToLocale[language];
  return new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(date);
};


export const getAdjustedDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12);
};


export const getDateXYearsAhead = (date: Date, years: number): Date => {
  const targetYear = date.getFullYear() + years;

  // leap year handling
  if (date.getMonth() === 1 && date.getDate() === 29) {

    const isTargetLeapYear =
      (targetYear % 4 === 0 && targetYear % 100 !== 0) || targetYear % 400 === 0;

    return isTargetLeapYear
      ? getAdjustedDate(new Date(targetYear, 1, 29))
      : getAdjustedDate(new Date(targetYear, 1, 28));
  }

  return getAdjustedDate(new Date(targetYear, date.getMonth(), date.getDate()));
};


export const datesAreXYearsApart = (earliestDate: Date, latestDate: Date, x: number): boolean => {
  const dateXYearsAfterEarliest = getDateXYearsAhead(earliestDate, x);

  return (
    latestDate.getFullYear() === dateXYearsAfterEarliest.getFullYear() &&
    latestDate.getMonth() === dateXYearsAfterEarliest.getMonth() &&
    latestDate.getDate() === dateXYearsAfterEarliest.getDate()
  );
};


export const getNextDate = (date: Date): Date => {
  const nextDate = new Date(date);
  nextDate.setDate(nextDate.getDate() + 1);
  return nextDate;
};
