import '../scouting.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import { Dialog } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../../components/controls/input/InputField';
import { DropDown } from '../../../components/controls/dropDowns/DropDown';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { LeagueDropDown } from '../../../components/controls/dropDowns/LeagueDropDown';
import { CountryDropDown } from '../../../components/controls/dropDowns/CountryDropDown';
import { Club, StringToAnyMap } from '../../../types';
import { SaveOrLoadSearchModal } from '../../../components/modals/SaveOrLoadSearchModal';
import { saveUserSearch } from '../../../services/firestore/scouting';
import { ClubDropDown } from '../../../components/controls/dropDowns/ClubDropDown';
import { PositionAndRoleDropDown } from '../../../components/controls/dropDowns/PositionAndRoleDropDown';
import { DropDownWithIcons } from '../../../components/controls/dropDowns/DropDownWithIcons';
import { PlayerScoutingFilter, PlayerScoutingState } from './playerScoutingState';
import { PlayerScoutingAction } from './playerScoutingReducer';
import {
  ageKeys,
  contractKeys,
  formKeys,
  minConfidenceKeys,
  playingTimeKeys
} from '../../../static/propertyValues';
import { IconButton } from '../../../components/controls/buttons/IconButton';
import { modalPaperProps } from '../../../components/modals/globalModals/GlobalModals';


interface PlayerScoutingFiltersProps {
  state: PlayerScoutingState;
  dispatch: React.Dispatch<PlayerScoutingAction>;
  nameSearchString: string;
  setNameSearchString: (nameSearchString: string) => void;
  onKeyDownNameSearchField: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onNameSearchHistoryClick: (name: string) => void;
}

export const PlayerScoutingFilters: React.FC<PlayerScoutingFiltersProps> = ({
  state,
  dispatch,
  nameSearchString,
  setNameSearchString,
  onKeyDownNameSearchField,
  onNameSearchHistoryClick,
}) => {

  const { currentUser } = useAuthContext();
  const userConfig = useRecoilValue(userConfigState);

  const width = useWindowSize().width;


  const saveSearch = async (searchName: string) => {
    if (!userConfig) return;

    const search: StringToAnyMap = {
      'searchName': searchName,
      'searchType': 'playerScouting',
    };

    if (nameSearchString.length > 0) search['nameSearchString'] = nameSearchString;

    if (state.selectedMinConfidence.length > 0) search['minConfidence'] = state.selectedMinConfidence;
    if (state.selectedMaxAge.length > 0) search['maxAge'] = state.selectedMaxAge;
    if (state.selectedPositions.length > 0) search['positions'] = state.selectedPositions;
    if (state.selectedRoles.length > 0) search['roles'] = state.selectedRoles;
    if (state.selectedLeagues.length > 0) search['leagues'] = state.selectedLeagues;
    if (state.selectedOrderBy.length > 0) search['orderBy'] = state.selectedOrderBy;

    if (state.selectedClubs.length > 0) search['clubs'] = state.selectedClubs.map((club: Club) => {
      return {
        'id': club.id,
        'name': club.name,
        'logo_url': club.logo_url,
      };
    });
    if (state.selectedForm.length > 0) search['form'] = state.selectedForm;
    if (state.selectedPlayingTime.length > 0) search['playingTime'] = state.selectedPlayingTime;
    if (state.selectedNationalities.length > 0) search['nationalities'] = state.selectedNationalities;
    if (state.selectedContractStatus.length > 0) search['contractStatus'] = state.selectedContractStatus;
    if (state.isContractStatusToggled) search['isContractStatusToggled'] = true;

    saveUserSearch(search, userConfig.email, userConfig.club, currentUser);
  };


  const loadSearch = (savedSearch: StringToAnyMap) => {
    setNameSearchString(savedSearch['nameSearchString'] ?? '');
    dispatch({ type: 'LOAD_SEARCH', payload: savedSearch });
  };


  const handleSetIsDropDownExpanded = (filter: PlayerScoutingFilter, value: boolean) => {
    if (value) {
      dispatch({ type: 'SET_FILTER_EXPANSION', payload: filter });
    }
    else {
      dispatch({ type: 'RESET_FILTER_EXPANSION' });
    }
  };


  useEffect(() => {
    if (!state.isAdvancedFiltersExpanded) {
      dispatch({ type: 'SET_SHOW_ADVANCED_FILTERS', payload: false });
    }
    else {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_SHOW_ADVANCED_FILTERS', payload: true });
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [state.isAdvancedFiltersExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <Dialog
        open={state.modalTypeOpen !== undefined}
        onClose={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: undefined })}
        PaperProps={modalPaperProps}
      >
        <SaveOrLoadSearchModal
          modalType={state.modalTypeOpen}
          searchType={'playerScouting'}
          closeModal={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: undefined })}
          saveSearch={saveSearch}
          loadSearch={loadSearch}
        />
      </Dialog>

      <div className='player-filter-section-input-container'>
        <InputField
          value={nameSearchString}
          placeholder={translate(width > 1200 ? 'searchForName' : 'name', userConfig?.language)}
          onChange={(event) => setNameSearchString(event.target.value)}
          onKeyDown={onKeyDownNameSearchField}
          resetValue={() => setNameSearchString('')}
          showNameSearchHistory={true}
          onNameSearchHistoryClick={onNameSearchHistoryClick}
          maxHeight={'75vh'}
          isWhiteBackgroundWhenExpanded={true}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-confidence'>
        {translate(width > 1000 ? 'minConfidence' : 'confidence', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-confidence'>
        <DropDown
          id={'player-filter-section-confidence-drop-down'}
          dropDownOptions={minConfidenceKeys}
          isDropDownExpanded={state.currentFilterExpanded === 'minConfidence'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('minConfidence', value)}
          selectedOptions={state.selectedMinConfidence}
          setSelectedOptions={(selectedMinConfidence: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMinConfidence } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'75vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-age'>
        <DropDown
          id={'player-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          isDropDownExpanded={state.currentFilterExpanded === 'maxAge'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('maxAge', value)}
          selectedOptions={state.selectedMaxAge}
          setSelectedOptions={(selectedMaxAge: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMaxAge } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-positions-and-roles'>
        {translate('positionsAndRoles', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-positions-and-roles'>
        <PositionAndRoleDropDown
          id={'player-filter-section-positions-and-roles-drop-down'}
          selectedPositions={state.selectedPositions}
          setSelectedPositions={(selectedPositions: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedPositions } })}
          selectedRoles={state.selectedRoles}
          setSelectedRoles={(selectedRoles: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedRoles } })}
          isDropDownExpanded={state.currentFilterExpanded === 'positionsAndRoles'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('positionsAndRoles', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-leagues'>
        {translate('leagues', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-leagues'>
        <LeagueDropDown
          id={'player-filter-section-leagues-drop-down'}
          isDropDownExpanded={state.currentFilterExpanded === 'leagues'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('leagues', value)}
          selectedLeagues={state.selectedLeagues}
          setSelectedLeagues={(selectedLeagues: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedLeagues } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-order-by'>
        {translate('orderBy', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-order-by'>
        <DropDown
          id={'player-filter-section-order-by-drop-down'}
          dropDownOptions={[...state.selectedRoles, 'skillIndex', 'age']}
          isDropDownExpanded={state.currentFilterExpanded === 'orderBy'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('orderBy', value)}
          selectedOptions={state.selectedOrderBy}
          setSelectedOptions={(selectedOrderBy: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedOrderBy } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <IconButton
        onClick={() => { setNameSearchString(''); dispatch({ type: 'RESET_STATE' }); }}
        icon={<ReplayIcon style={{ fontSize: 20 }} />}
        size={24}
        style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(94% - 9px)' }}
        startWhite={true}
        title={translate('resetFilters', userConfig?.language)}
      />

      <IconButton
        onClick={() => dispatch({ type: 'SET_ADVANCED_FILTERS_EXPANSION' })}
        icon={!state.isAdvancedFiltersExpanded ? <FilterAltIcon style={{ fontSize: 20, marginTop: 1 }} /> : <CloseIcon style={{ fontSize: 20 }} />}
        size={24}
        style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(97% - 7px)' }}
        startWhite={true}
        title={!state.isAdvancedFiltersExpanded ? translate('moreFilters', userConfig?.language) : undefined}
      />

      {state.isAdvancedFiltersExpanded && state.showAdvancedFilters && (
        <div className='filter-section-advanced-filter-section'>
          <div className='filter-section-drop-down-title player-filter-section-clubs'>
            {translate('clubs', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-clubs'>
            <ClubDropDown
              id={'player-filter-section-clubs-drop-down'}
              isDropDownExpanded={state.currentFilterExpanded === 'clubs'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('clubs', value)}
              selectedClubs={state.selectedClubs}
              setSelectedClubs={(selectedClubs: Club[]) => dispatch({ type: 'SET_FILTER', payload: { selectedClubs } })}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-form'>
            {translate('form', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-form'>
            <DropDownWithIcons
              id={'player-filter-section-form-drop-down'}
              dropDownOptions={formKeys}
              isDropDownExpanded={state.currentFilterExpanded === 'form'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('form', value)}
              selectedOptions={state.selectedForm}
              setSelectedOptions={(selectedForm: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedForm } })}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              multiSelect={true}
              useFormIcons={true}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-playing-time'>
            {translate('playingTime', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-playing-time'>
            <DropDownWithIcons
              id={'player-filter-section-playing-time-drop-down'}
              dropDownOptions={playingTimeKeys}
              isDropDownExpanded={state.currentFilterExpanded === 'playingTime'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('playingTime', value)}
              selectedOptions={state.selectedPlayingTime}
              setSelectedOptions={(selectedPlayingTime: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedPlayingTime } })}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              multiSelect={true}
              usePlayingTimeIcons={true}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-nationalities'>
            {translate('nationalities', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-nationalities'>
            <CountryDropDown
              id={'player-filter-section-nationalities-drop-down'}
              isDropDownExpanded={state.currentFilterExpanded === 'nationalities'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('nationalities', value)}
              selectedCountryCodes={state.selectedNationalities}
              setSelectedCountryCodes={(selectedNationalities: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedNationalities } })}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
            />
          </div>

          <div className='filter-section-drop-down-title player-filter-section-contract'>
            {translate('contractStatus', userConfig?.language)}
          </div>
          <div className='filter-section-drop-down player-filter-section-contract'>
            <DropDown
              id={'player-filter-section-contract-drop-down'}
              dropDownOptions={contractKeys}
              isDropDownExpanded={state.currentFilterExpanded === 'contractStatus'}
              setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('contractStatus', value)}
              selectedOptions={state.selectedContractStatus}
              setSelectedOptions={(selectedContractStatus: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedContractStatus } })}
              defaultDropDownText={'select'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'75vh'}
              toggleText={'includeUnknownContractStatus'}
              isToggled={state.isContractStatusToggled}
              setIsToggled={(value: boolean) => dispatch({ type: 'SET_FILTER', payload: { isContractStatusToggled: value } })}
            />
          </div>

          <IconButton
            onClick={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: 'saveSearch' })}
            icon={<SaveAsIcon style={{ fontSize: 20, marginTop: -1 }} />}
            size={24}
            style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(94% - 9px)' }}
            startWhite={true}
            title={translate('saveSearch', userConfig?.language)}
          />

          <IconButton
            onClick={() => dispatch({ type: 'SET_MODAL_TYPE_OPEN', payload: 'loadSearch' })}
            icon={<ArrowCircleDownIcon style={{ fontSize: 20 }} />}
            size={24}
            style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(97% - 7px)' }}
            startWhite={true}
            title={translate('loadSearch', userConfig?.language)}
          />
        </div>
      )}

    </div>
  );
};
