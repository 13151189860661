import { StringToStringMap } from '../../types';


export const tierColors: StringToStringMap = {
  '1': '#79bbc3',
  '2': '#c6a66c',
};

// Some clubs with golden main color cannot use the standard tier colors (e.g. B93)
export const alternativeTierColors: StringToStringMap = {
  '1': '#1d6be0',
  '2': '#79bbc3',
};
