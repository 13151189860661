import '../scouting.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import ReplayIcon from '@mui/icons-material/Replay';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../../components/controls/input/InputField';
import { DropDown } from '../../../components/controls/dropDowns/DropDown';
import { ageKeys } from '../../../static/propertyValues';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { LeagueDropDown } from '../../../components/controls/dropDowns/LeagueDropDown';
import { CountryDropDown } from '../../../components/controls/dropDowns/CountryDropDown';
import { ClubDropDown } from '../../../components/controls/dropDowns/ClubDropDown';
import { PlayerFindingFilter, PlayerFindingState } from './playerFindingState';
import { PlayerFindingAction } from './playerFindingReducer';
import { Club } from '../../../types';
import { IconButton } from '../../../components/controls/buttons/IconButton';


interface PlayerFindingFiltersProps {
  state: PlayerFindingState;
  dispatch: React.Dispatch<PlayerFindingAction>;
  nameSearchString: string;
  setNameSearchString: (value: string) => void;
  onKeyDownNameSearchField: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onNameSearchHistoryClick: (name: string) => void;
  isAnyFilterExpanded: boolean;
  setIsAnyFilterExpanded: (value: boolean) => void;
}

export const PlayerFindingFilters: React.FC<PlayerFindingFiltersProps> = ({
  state,
  dispatch,
  nameSearchString,
  setNameSearchString,
  onKeyDownNameSearchField,
  onNameSearchHistoryClick,
  isAnyFilterExpanded,
  setIsAnyFilterExpanded,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const width = useWindowSize().width;


  const handleSetIsDropDownExpanded = (filter: PlayerFindingFilter, value: boolean) => {
    if (value) {
      setIsAnyFilterExpanded(true);
      dispatch({ type: 'SET_FILTER_EXPANSION', payload: filter });
    }
    else {
      setIsAnyFilterExpanded(false);
      dispatch({ type: 'RESET_FILTER_EXPANSION' });
    }
  };


  useEffect(() => {
    if (!isAnyFilterExpanded) {
      dispatch({ type: 'RESET_FILTER_EXPANSION' });
    }
  }, [isAnyFilterExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='filter-section'>

      <div className='player-finding-filter-section-input-container-name'>
        <InputField
          value={nameSearchString}
          placeholder={translate(width > 1150 ? 'searchForName' : 'name', userConfig?.language)}
          onChange={(event) => setNameSearchString(event.target.value)}
          onKeyDown={onKeyDownNameSearchField}
          resetValue={() => setNameSearchString('')}
          showNameSearchHistory={true}
          onNameSearchHistoryClick={onNameSearchHistoryClick}
          maxHeight={'50vh'}
          isWhiteBackgroundWhenExpanded={true}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-min-age'>
        {translate('minAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-min-age'>
        <DropDown
          id={'player-finding-filter-section-min-age-drop-down'}
          dropDownOptions={ageKeys}
          selectedOptions={state.selectedMinAge}
          setSelectedOptions={(selectedMinAge: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMinAge } })}
          isDropDownExpanded={state.currentFilterExpanded === 'minAge'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('minAge', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-max-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-max-age'>
        <DropDown
          id={'player-finding-filter-section-max-age-drop-down'}
          dropDownOptions={ageKeys}
          selectedOptions={state.selectedMaxAge}
          setSelectedOptions={(selectedMaxAge: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMaxAge } })}
          isDropDownExpanded={state.currentFilterExpanded === 'maxAge'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('maxAge', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-nationalities'>
        {translate('nationalities', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-nationalities'>
        <CountryDropDown
          id={'player-finding-filter-section-nationalities-drop-down'}
          selectedCountryCodes={state.selectedNationalities}
          setSelectedCountryCodes={(selectedNationalities: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedNationalities } })}
          isDropDownExpanded={state.currentFilterExpanded === 'nationalities'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('nationalities', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-competitions'>
        {translate('leagues', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-competitions'>
        <LeagueDropDown
          id={'player-finding-filter-section-competitions-drop-down'}
          selectedLeagues={state.selectedLeagues}
          setSelectedLeagues={(selectedLeagues: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedLeagues } })}
          isDropDownExpanded={state.currentFilterExpanded === 'leagues'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('leagues', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-finding-filter-section-clubs'>
        {translate('clubs', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-finding-filter-section-clubs'>
        <ClubDropDown
          id={'player-finding-filter-section-clubs-drop-down'}
          selectedClubs={state.selectedClubs}
          setSelectedClubs={(selectedClubs: Club[]) => dispatch({ type: 'SET_FILTER', payload: { selectedClubs } })}
          isDropDownExpanded={state.currentFilterExpanded === 'clubs'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('clubs', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'57vh'}
        />
      </div>

      <IconButton
        onClick={() => { setNameSearchString(''); dispatch({ type: 'RESET_STATE' }); }}
        icon={<ReplayIcon style={{ fontSize: 20 }} />}
        size={24}
        style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(97% - 7px)' }}
        startWhite={true}
        title={translate('resetFilters', userConfig?.language)}
      />

    </div>
  );
};
