import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { savedSearchesSelector } from '../../recoil/selectors/scoutingConfigSelectors';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { translate } from '../../../common/language/translations';
import { InputField } from '../controls/input/InputField';
import { StringToAnyMap } from '../../types';
import { deleteSearch } from '../../services/firestore/scouting';
import { trackEvent } from '../../services/server/analytics/trackEvent';
import { IconButton } from '../controls/buttons/IconButton';
import { TextButton } from '../controls/buttons/TextButton';


interface SaveOrLoadSearchModalProps {
  modalType: 'saveSearch' | 'loadSearch' | undefined;
  searchType: 'playerScouting' | 'seasonScouting';
  closeModal: () => void;
  saveSearch: (searchName: string) => void,
  loadSearch: (savedSearch: StringToAnyMap) => void,
}


export const SaveOrLoadSearchModal: React.FC<SaveOrLoadSearchModalProps> = ({ modalType, searchType, closeModal, saveSearch, loadSearch }) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const savedSearchesState = useRecoilValue(savedSearchesSelector);

  const { openConfirmModal } = useOpenGlobalModal();

  const [searchString, setSearchString] = useState('');

  const [buttonDisabled, setButtonDisabled] = useState(false);


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSaveSearch();
    }
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 50) {
      setSearchString(event.target.value);
    }
  };


  const handleSaveSearch = async () => {
    if (!userConfig || buttonDisabled || searchString.length === 0) return;

    setButtonDisabled(true);

    saveSearch(searchString);
    trackEvent('SearchSaved', { searchType: searchType }, currentUser, 'user');

    setButtonDisabled(false);

    closeModal();
  };


  const handleLoadSearch = async (search: StringToAnyMap) => {
    if (!userConfig || buttonDisabled) return;

    setButtonDisabled(true);

    loadSearch(search);
    trackEvent('SearchLoaded', { searchType: searchType }, currentUser, 'user');

    setButtonDisabled(false);

    closeModal();
  };


  const handleDeleteSearch = (index: number, event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    openConfirmModal(
      () => confirmDelete(index),
      'deleteSearch',
      'noRegretInfo',
    );
  };


  const confirmDelete = (index: number) => {
    if (!userConfig) return;

    deleteSearch(index, userConfig.email, userConfig.club, currentUser);
    trackEvent('SearchDeleted', { searchType: searchType }, currentUser, 'user');
  };


  return (
    <div
      className='modal-root-container save-or-load-search-modal-root'
      style={{ height: modalType === 'saveSearch' ? 310 : modalType === 'loadSearch' ? 500 : 0 }}
    >

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {modalType && translate(modalType, userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      {modalType === 'saveSearch' && (
        <div className='modal-content-container'>
          <div className='modal-section-title' style={{ marginTop: 30 }}>
            {translate('provideNameOfSearch', userConfig?.language)}
          </div>

          <div style={{ width: 300 }}>
            <InputField
              value={searchString}
              placeholder={translate('provideName', userConfig?.language)}
              onChange={onChangeSearchField}
              onKeyDown={onKeyDownSearchField}
              resetValue={() => setSearchString('')}
            />
          </div>

          <TextButton
            onClick={handleSaveSearch}
            text={translate('saveSearch', userConfig?.language)}
            icon={<SaveIcon style={{ fontSize: 22 }} />}
            disabled={searchString.length === 0}
          />
        </div>
      )}

      {modalType === 'loadSearch' && (
        <div className='modal-content-container' style={{ width: '90%', margin: 20, padding: '0px 10px' }}>
          {savedSearchesState && savedSearchesState.length > 0 && savedSearchesState.map((search: StringToAnyMap, index: number) => {

            if (search['searchType'] !== searchType) return null;

            return (
              <div className='save-or-load-search-saved-search' key={index} onClick={() => handleLoadSearch(search)}>
                <div className='save-or-load-search-saved-search-name'>
                  {search['searchName']}
                </div>
                <IconButton
                  icon={<DeleteOutlineIcon style={{ fontSize: 20 }} />}
                  onClick={(event) => handleDeleteSearch(index, event)}
                  size={24}
                  style={{ position: 'absolute', right: 10 }}
                  backgroundColorBehindIcon='gray'
                />
              </div>
            );
          })}

          {!(savedSearchesState && savedSearchesState.filter((savedSearch: StringToAnyMap) => savedSearch.searchType === searchType).length > 0) && (
            <div className='save-or-load-search-no-saved-searches'>
              {translate('noSavedSearches', userConfig?.language)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
