import '../../../platform.css';
import '../scouting.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { playerOverviewsState } from '../../../recoil/atoms/playerOverviewsState';
import { trackEvent } from '../../../services/server/analytics/trackEvent';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import SearchIcon from '@mui/icons-material/Search';

import {
  PlayerOverviews,
  RatingRequirement,
  PositionGroupClubIterationsQueryOptions,
} from '../../../types';

import { translate } from '../../../../common/language/translations';
import { searchPositionGroupClubIterations, SearchPositionGroupClubIterationsResponse } from '../../../services/server/application/positionGroupClubIterations';
import { SeasonScoutingFilters } from './SeasonScoutingFilters';
import { stringArraysContainSameElements } from '../../../utils/utils';
import { getPlayerOverviews } from '../../../services/server/application/playerOverviews';
import { commonSeasonTableMetrics, getNormalizedMetricName } from '../../../static/playerMetrics';
import { contractKeysToMonths } from '../../../static/propertyValues';
import { playerSeasonTableChapterSize, playerSeasonTablePageSize } from '../Scouting';
import { getFiltersUsed } from '../../../utils/scoutingUtils';
import { TextButton } from '../../../components/controls/buttons/TextButton';
import { SeasonScoutingState } from './seasonScoutingState';
import { SeasonScoutingAction } from './seasonScoutingReducer';
import { PlayerSeasonTable } from '../../../components/tables/playerSeasonTable/PlayerSeasonTable';


interface SeasonScoutingProps {
  state: SeasonScoutingState;
  dispatch: React.Dispatch<SeasonScoutingAction>;
  additionalPlayerOverviews: PlayerOverviews;
  setAdditionalPlayerOverviews: (value: PlayerOverviews) => void;
}

export const SeasonScouting: React.FC<SeasonScoutingProps> = ({
  state,
  dispatch,
  additionalPlayerOverviews,
  setAdditionalPlayerOverviews,
}) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const playerOverviews = useRecoilValue(playerOverviewsState);

  const handleTrackEvent = (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    searchQueryOptions: PositionGroupClubIterationsQueryOptions,
    totalHits: number,
  ) => {

    const initiator = nextChapter
      ? 'pagination'
      : !isNewSearch
        ? 'scroll' // not currently possible for season search
        : isUserInitiated
          ? 'user'
          : 'changes';

    trackEvent(
      'SeasonSearch',
      {
        initiator: initiator,
        isNewSearch: isNewSearch,
        filtersUsed: getFiltersUsed(searchQueryOptions),
        totalHits: totalHits,

        minMinutesPlayed: searchQueryOptions.minMinutesPlayed,
        minMinutesPlayedPercentage: searchQueryOptions.minMinutesPlayedPercentage,
        minMinutes: state.selectedMinutes.length > 0 ? state.selectedMinutes[0] : undefined,

        maxAge: searchQueryOptions.maxAge,
        primaryPositions: searchQueryOptions.primaryPositions,
        positionGroup: searchQueryOptions.positionGroup,
        iterations: searchQueryOptions.iterations,
        competitions: state.selectedIterations.length > 0
          ? state.selectedIterations.map(iteration => iteration.competitionId)
          : undefined,
        seasons: state.selectedIterations.length > 0
          ? state.selectedIterations.map(iteration => iteration.season)
          : undefined,

        clubs: searchQueryOptions.clubs,
        ratingRequirements: searchQueryOptions.ratingRequirements,
        countryCodes: searchQueryOptions.countryCodes,
        maxContractLength: searchQueryOptions.maxContractLength,
        includeUnknownContracts: searchQueryOptions.includeUnknownContracts ?? false,

        sortByActualValues: searchQueryOptions.sortByActualValues ?? false,
        sortBy: searchQueryOptions.sortBy && clubSettings?.roleConfigs[searchQueryOptions.sortBy]
          ? 'role_rating'
          : searchQueryOptions.sortBy,
      },
      currentUser,
      'user',
    );
  };


  const handleSearchButtonPressed = async (
    isUserInitiated: boolean,
    isNewSearch: boolean,
    nextChapter?: number,
    newOverriddenOrderBy?: string | undefined,
    newOverriddenRatings?: RatingRequirement[] | undefined,
  ) => {

    dispatch({ type: 'SET_IS_LOADING' });

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * playerSeasonTableChapterSize) + 1)
        : ((state.currentChapter * playerSeasonTableChapterSize) + (state.currentModuloPage + 1)));

    const sortBy = newOverriddenOrderBy ??
      (state.selectedOrderBy.length > 0
        ? getNormalizedMetricName(state.selectedOrderBy[0])
        : undefined);

    const positionGroupOfSelectedRoles = state.selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[state.selectedRoles[0]]
      ? clubSettings.roleConfigs[state.selectedRoles[0]].positionGroup
      : null;

    const queryOptions: PositionGroupClubIterationsQueryOptions = {
      minMinutesPlayed: state.selectedMinutes.length > 0
        ? String(state.selectedMinutes[0]).includes('%') ? undefined : parseInt(state.selectedMinutes[0])
        : undefined,
      minMinutesPlayedPercentage: state.selectedMinutes.length > 0
        ? String(state.selectedMinutes[0]).includes('%') ? (parseInt(state.selectedMinutes[0].replace('%', '')) / 100) : undefined
        : undefined,
      maxAge: state.selectedMaxAge.length > 0 ? parseInt(state.selectedMaxAge[0]) : undefined,
      primaryPositions: state.selectedPositions.length > 0 ? state.selectedPositions : undefined,
      positionGroup: positionGroupOfSelectedRoles,
      iterations: state.selectedIterations.length > 0 ? state.selectedIterations.map(iteration => iteration.iterationId) : undefined,

      clubs: state.selectedClubs.length > 0 ? state.selectedClubs.map(club => club.id) : undefined,
      ratingRequirements: newOverriddenRatings ??
        (state.selectedRatings.length > 0
          ? state.selectedRatings
          : undefined),
      countryCodes: state.selectedNationalities.length > 0 ? state.selectedNationalities : undefined,
      maxContractLength: state.selectedContractStatus.length > 0 ? contractKeysToMonths[state.selectedContractStatus[0]] : undefined,
      includeUnknownContracts: state.isContractStatusToggled,

      page: nextPageToQuery,
      pageSize: playerSeasonTablePageSize,
      sortByActualValues: userSettings?.seasonStatsToggles?.orderBy ?? false,
      sortBy: sortBy,
    };

    const result: SearchPositionGroupClubIterationsResponse | undefined = await searchPositionGroupClubIterations(queryOptions, currentUser);

    if (!result) {
      // todo: handle error?
      dispatch({ type: 'RESET_STATE' });
      return;
    }

    handleTrackEvent(isUserInitiated, isNewSearch, nextChapter, queryOptions, result.total_hits);

    dispatch({
      type: 'SET_SEARCH_RESULT',
      payload: {
        result: result,
        isNewSearch: isNewSearch,
        nextChapter: nextChapter,
        newOverriddenOrderBy: newOverriddenOrderBy,
        selectedOrderByToggle: userSettings?.seasonStatsToggles?.orderBy ?? false,
      }
    });
  };


  // we automatically fetch player overviews for every player in view to have the data ready when players are clicked
  useEffect(() => {
    if (state.playerIdsOnCurrentPage.length > 0) {
      const playerIdsToQuery = state.playerIdsOnCurrentPage
        .filter(playerId => !(
          isNaN(Number(playerId)) ||
          playerOverviews[playerId] ||
          additionalPlayerOverviews[playerId]
        ))
        .map(playerId => Number(playerId));

      if (playerIdsToQuery.length > 0) {
        getPlayerOverviews(playerIdsToQuery, currentUser).then((result) => {
          if (result) {
            setAdditionalPlayerOverviews({ ...additionalPlayerOverviews, ...result });
          }
        });
      }
    }
  }, [currentUser, state.playerIdsOnCurrentPage]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    dispatch({ type: 'SET_EMPTY_TABLE_DATA' });
    const nextChapter = isIncrement ? state.currentChapter + 1 : state.currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  // when a filter is de-expanded, we check if the selected filters have changed, and if so, trigger a new search
  useEffect(() => {
    // only trigger a new search if the filter is de-expanded and search is active
    const searchIsActive = state.tableData.length > 0 || state.isEmptySearchResult;
    if (!state.currentFilterExpanded && state.previousFilterExpanded && searchIsActive) {
      let shouldTriggerSearch = false;
      let rolesHaveChanged = false;
      let positionsHaveChanged = false;

      switch (state.previousFilterExpanded) {
        case 'positionsAndRoles':
          rolesHaveChanged = !stringArraysContainSameElements(state.currentRoles, state.selectedRoles);
          positionsHaveChanged = !stringArraysContainSameElements(state.currentPositions, state.selectedPositions);
          shouldTriggerSearch = rolesHaveChanged || positionsHaveChanged;
          break;

        case 'minutes':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMinutes, state.selectedMinutes);
          break;

        case 'maxAge':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMaxAge, state.selectedMaxAge);
          break;

        case 'iterations':
          shouldTriggerSearch = !stringArraysContainSameElements(
            state.currentIterations.map(iteration => String(iteration.iterationId)), state.selectedIterations.map(iteration => String(iteration.iterationId))
          );
          break;

        case 'orderBy':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentOrderBy, state.selectedOrderBy)
            || state.currentOrderByToggle !== (userSettings?.seasonStatsToggles?.orderBy ?? false);
          break;

        case 'clubs':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentClubs.map(c => String(c.id)), state.selectedClubs.map(c => String(c.id)));
          break;

        case 'ratings':
          shouldTriggerSearch = !stringArraysContainSameElements(
            state.currentRatings.map(rating => rating.metric + '_' + rating.value), state.selectedRatings.map(rating => rating.metric + '_' + rating.value)
          );
          break;

        case 'nationalities':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentNationalities, state.selectedNationalities);
          break;

        case 'contractStatus':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentContractStatus, state.selectedContractStatus)
            || state.currentIsContractStatusToggled !== state.isContractStatusToggled;
          break;
      }

      let newOverriddenOrderBy: string | undefined = undefined;
      let newOverriddenRatings: RatingRequirement[] | undefined = undefined;

      if (positionsHaveChanged) {
        if (state.selectedPositions.includes('GK') !== state.currentPositions.includes('GK')) {
          if (state.selectedOrderBy.length > 0 && !commonSeasonTableMetrics.includes(state.selectedOrderBy[0])) {
            newOverriddenOrderBy = 'skill_rating';
          }
          newOverriddenRatings = state.selectedRatings.filter(rating => (rating.metric === 'skill_rating' || commonSeasonTableMetrics.includes(rating.metric)));
        }
      }

      // some changes to the selected roles will automatically set the orderBy
      if (rolesHaveChanged) {

        // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
        // we only want this if there are currently no selected roles - if there are, and the orderBy is not a role, the orderBy was manually set
        if (
          state.currentRoles.length === 0
          && state.selectedRoles.length > 0
          && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skill_rating')
        ) {
          const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
          newOverriddenOrderBy = lastSelectedRole;
        }

        // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
        if (
          state.selectedOrderBy.length > 0
          && clubSettings?.roleConfigs[state.selectedOrderBy[0]]
          && !state.selectedRoles.includes(state.selectedOrderBy[0])
        ) {
          if (state.selectedRoles.length > 0) {
            newOverriddenOrderBy = state.selectedRoles[0];
          }
          else {
            newOverriddenOrderBy = 'skill_rating';
          }
        }

        // if any rating requirements are no longer applicable to the new roles, we remove them
        if (
          newOverriddenRatings === undefined
          && state.selectedRatings.some(rating => (clubSettings?.roleConfigs[rating.metric] && !state.selectedRoles.includes(rating.metric)))
        ) {
          newOverriddenRatings = state.selectedRatings.filter(r => !(clubSettings?.roleConfigs[r.metric] && !state.selectedRoles.includes(r.metric)));
        }
      }

      if (newOverriddenOrderBy) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [newOverriddenOrderBy] });
      }

      if (newOverriddenRatings) {
        dispatch({ type: 'SET_SELECTED_RATINGS', payload: newOverriddenRatings });
      }

      if (shouldTriggerSearch) {
        handleSearchButtonPressed(false, true, undefined, newOverriddenOrderBy, newOverriddenRatings);
      }
    }
  }, [state.currentFilterExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  // if search is not active, some changes to the selected roles will automatically set the orderBy
  useEffect(() => {
    if (state.tableData.length > 0 || state.isEmptySearchResult) return;

    // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
    if (state.selectedRoles.length > 0 && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skill_rating')) {
      const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
      dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [lastSelectedRole] });
    }

    // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
    if (state.selectedOrderBy.length > 0 && clubSettings?.roleConfigs[state.selectedOrderBy[0]] && !state.selectedRoles.includes(state.selectedOrderBy[0])) {
      if (state.selectedRoles.length > 0) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [state.selectedRoles[0]] });
      }
      else {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: ['skill_rating'] });
      }
    }

    // if any rating requirements are no longer applicable to the new roles, we remove them
    if (state.selectedRatings.some(rating => (clubSettings?.roleConfigs[rating.metric] && !state.selectedRoles.includes(rating.metric)))) {
      dispatch({
        type: 'SET_SELECTED_RATINGS',
        payload: state.selectedRatings.filter(rating => !(clubSettings?.roleConfigs[rating.metric] && !state.selectedRoles.includes(rating.metric)))
      });
    }
  }, [state.selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps


  // if search is not active, some changes to the selected positions will automatically set the orderBy and/or ratings
  useEffect(() => {
    if (state.tableData.length > 0 || state.isEmptySearchResult) return;

    if (state.selectedPositions.includes('GK') !== state.currentPositions.includes('GK')) {
      if (state.selectedOrderBy.length > 0 && !commonSeasonTableMetrics.includes(state.selectedOrderBy[0])) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: ['skill_rating'] });
      }
      dispatch({
        type: 'SET_SELECTED_RATINGS',
        payload: state.selectedRatings.filter(rating => (rating.metric === 'skill_rating' || commonSeasonTableMetrics.includes(rating.metric)))
      });
    }
  }, [state.selectedPositions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (state.searchWasLoaded) {
      handleSearchButtonPressed(true, true);
    }
  }, [state.searchWasLoaded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='full-size-container'>

      {state.currentFilterExpanded &&
        <div className='filter-section-empty-background' onClick={() => dispatch({ type: 'RESET_FILTER_EXPANSION' })} />
      }

      <SeasonScoutingFilters
        state={state}
        dispatch={dispatch}
      />

      <div
        className='scouting-view-result-container'
        style={{ top: state.scoutingViewResultContainerTop }}
      >
        {state.tableData.length > 0 && (
          <PlayerSeasonTable
            data={state.tableData}
            additionalPlayerOverviews={additionalPlayerOverviews}
            isLoading={state.isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={state.currentModuloPage}
            currentChapter={state.currentChapter}
            totalHits={state.totalHits}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            selectedOrderBy={state.selectedOrderBy.length > 0 ? state.selectedOrderBy[0] : undefined}
            selectedRoles={state.selectedRoles}
            isGoalkeeperSelected={state.selectedPositions.includes('GK')}
          />
        )}

        {state.tableData.length === 0 && state.isEmptySearchResult && !state.isLoading && (
          <div className='scouting-info-title fade-in'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {state.tableData.length === 0 && !state.isEmptySearchResult && !state.isLoading && (
          <TextButton
            onClick={() => handleSearchButtonPressed(true, true)}
            text={translate('searchForSeasons', userConfig?.language)}
            icon={<SearchIcon style={{ fontSize: 22 }} />}
            buttonType={'dark'}
            isRound={true}
            style={{ height: 40, fontSize: 15, gap: 30 }}
          />
        )}

      </div>
    </div>
  );
};
