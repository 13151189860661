import '../mobileTables.css';

import { useEffect, useRef } from 'react';

// import { useRecoilValue } from 'recoil';
// import { userConfigState } from '../../../../recoil/atoms/userConfigState';
// import { clubSettingsState } from '../../../../recoil/atoms/clubSettingsState';
// import { useSetAndTrackSelectedPlayerState } from '../../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
// import { useOpenGlobalModal } from '../../../../recoil/hooks/useOpenGlobalModal';
// import { useWindowSize } from '../../../../../common/hooks/WindowSize';

import { PlayerOverview } from '../../../../types';
import { debounce } from '../../../../utils/utils';
import { playerTableChapterSize, playerTablePageSize } from '../../../../views/scouting/Scouting';
// import { getClubSecondaryColor } from '../../../../static/clubConfigs';
// import { getMobilePlayerColumns, getMobilePlayerTableDynamicColumnsMinWidth } from './mobilePlayerColumns';


interface MobilePlayerTableProps {
  data: PlayerOverview[];

  isLoading?: boolean;
  handleSearchButtonPressed?: (isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  handleChangeCurrentChapter: (isIncrement: boolean) => void;

  selectedOrderBy?: string | undefined;
  selectedRoles?: string[];
}

export const MobilePlayerTable: React.FC<MobilePlayerTableProps> = ({
  data,

  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  // handleChangeCurrentChapter,

  // selectedOrderBy,
  // selectedRoles,
}) => {

  // const userConfig = useRecoilValue(userConfigState);
  // const clubSettings = useRecoilValue(clubSettingsState);
  // const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  // const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  // const { width } = useWindowSize();
  // const mobilePlayerTableStickyColumnWidth = 200;
  // const remainingWidth = width - mobilePlayerTableStickyColumnWidth;
  // const dynamicColumnsMinWidth = getMobilePlayerTableDynamicColumnsMinWidth(selectedRoles, clubSettings?.roleConfigs);
  // const widthUnit = remainingWidth / dynamicColumnsMinWidth;


  // const handleRowClick = (playerOverview: PlayerOverview) => {
  //   const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
  //     ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
  //     : undefined;

  //   setAndTrackSelectedPlayerState({
  //     id: playerOverview.id,
  //     fullname: playerOverview.fullname,
  //     playerOverview: playerOverview,
  //     initialPositionGroup: initialPositionGroup,
  //   });
  // };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !handleSearchButtonPressed) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    const isMoreDataToFetch = ((currentChapter * playerTablePageSize * playerTableChapterSize) + data.length) < totalHits;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && isMoreDataToFetch) {
      await handleSearchButtonPressed(false);
    }
  };


  useEffect(() => {
    if (playerTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      ref={tableContainerRef}
      className='mobile-player-table-container'
    >

      {/* {headerGroups.map(headerGroup => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={key}
            {...restHeaderGroupProps}
            className='mobile-player-table-header-group'
          >
            {headerGroup.headers.map((column: StringToAnyMap) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
              return (
                <div
                  key={columnKey}
                  {...restColumnProps}
                  className='mobile-player-table-header-cell'
                >
                  {column.render('Header')}
                </div>
              );
            })}
          </div>
        );
      })}

      <div {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? 'mobile-player-table-body-row-even' : 'mobile-player-table-body-row-odd'}
              onClick={() => handleRowClick(row.original)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'mobile-player-table-body-cell'
                      + (i % 2 === 0 ? ' mobile-player-table-body-cell-even' : ' mobile-player-table-body-cell-odd')
                      + (cell.column.isFinalSubMetric ? ' mobile-player-table-cell-with-border' : '')
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div> */}

    </div>
  );
};
