import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationPropsState } from '../atoms/navigationPropsState';
import { currentReactVersionState } from '../atoms/currentReactVersionState';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import { trackEvent } from '../../services/server/analytics/trackEvent';
import { NavigationProps } from '../../types';


export const useSetAndTrackNavigationPropsState = () => {

  const { currentUser } = useAuthContext();
  const currentReactVersion = useRecoilValue(currentReactVersionState);

  const setNavigationProps = useSetRecoilState(navigationPropsState);

  const useSetAndTrackNavigationPropsState = (newNavigationProps: NavigationProps) => {
    trackEvent('TabClicked', { tab: newNavigationProps.activeTab }, currentUser, 'user', currentReactVersion);
    setNavigationProps(newNavigationProps);
  };

  return useSetAndTrackNavigationPropsState;
};
