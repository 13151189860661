import './teamView.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';


import ReplayIcon from '@mui/icons-material/Replay';
import SaveAltIcon from '@mui/icons-material/SaveAlt';


import { translate } from '../../../common/language/translations';
import { StringToStringMap, StringToAnyMap } from '../../types';
import { archiveTeamOrSquad, resetTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';
import { updateTeamMenu } from '../../services/firestore/userSettings';
import { Toggle } from '../controls/input/Toggle';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { Dialog } from '@mui/material';
import { RoleIndexInTeamsInfoModal } from '../modals/infoModals/RoleIndexInTeamsInfoModal';
import { OptionButton } from '../controls/buttons/OptionButton';
import { modalPaperProps } from '../modals/globalModals/GlobalModals';


interface TeamMenuProps {
  teamOrSquadId: string;
  teamOrSquadData?: StringToAnyMap | undefined;
  isSquad?: boolean;

  showFirstEleven?: boolean;
  setShowFirstEleven?: (isToggled: boolean) => void;
  benchPlayers?: StringToAnyMap[];

  draggedPlayer?: StringToAnyMap | undefined;
  setDraggedPlayer?: (player: StringToAnyMap | undefined) => void;
  handlePlayerDrop?: (newPosition: string, positionIndex?: number) => void;
  tableHoverDisabled?: boolean;

  defaultOrderBy?: string;
  defaultToShortNames?: boolean;
  forceShowContent?: boolean;
  resetPlannerConfig?: () => Promise<void>;
}


export const TeamMenu: React.FC<TeamMenuProps> = ({
  teamOrSquadId,
  teamOrSquadData,
  isSquad,

  showFirstEleven,
  setShowFirstEleven,
  benchPlayers,

  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,
  tableHoverDisabled,

  defaultOrderBy,
  defaultToShortNames,
  forceShowContent,
  resetPlannerConfig,
}) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openConfirmModal } = useOpenGlobalModal();
  const [isRoleIndexInfoModalOpen, setIsRoleIndexInfoModalOpen] = useState(false);
  const [doNotShowRoleIndexInfoModalAgain, setDoNotShowRoleIndexInfoModalAgain] = useState(false);

  const [teamButtonDisabled, setTeamButtonDisabled] = useState(false);

  const [showContent, setShowContent] = useState(forceShowContent ?? false);

  const [isIconHighlighted, setIsIconHighlighted] = useState<string | undefined>(undefined);

  const [tableData, setTableData] = useState<StringToAnyMap[] | undefined>([]);


  const menu = (teamOrSquadId === 'ownTeam'
    ? userSettings?.ownTeamMenu
    : teamOrSquadId === 'ownTeamPlanner'
      ? userSettings?.ownTeamPlannerMenu
      : teamOrSquadId === 'academyTeam'
        ? userSettings?.academyTeamMenu
        : userSettings?.teamMenu) ?? {};

  const orderBy = menu.orderBy ?? defaultOrderBy;


  const menuOptions = teamOrSquadId === 'ownTeam'
    ? ['shortNames', 'teamRole', 'nationality', 'locality', 'age', 'contract', 'form', 'playingTime', 'skillIndex', 'roleIndex']
    : teamOrSquadId === 'ownTeamPlanner'
      ? ['shortNames', 'teamRole', 'nationality', 'locality', 'age', 'contract']
      : teamOrSquadId === 'academyTeam'
        ? ['shortNames', 'teamRole', 'nationality', 'age', 'contract']
        : ['shortNames', 'nationality', 'club', 'age', 'contract', 'form', 'playingTime', 'skillIndex', 'roleIndex'];


  // we want to decouple the actual property from the option shown
  const menuOptionToPropertyMap: StringToStringMap = {
    'shortNames': 'shortNames',
    'teamRole': 'role',
    'nationality': 'country_code',
    'locality': 'locality',
    'club': 'club_logo_url',
    'age': 'age',
    'contract': 'contract_expiration',
    'form': 'form_status',
    'playingTime': 'availability_status',
    'skillIndex': 'skill_index',
    'roleIndex': 'role_index',
  };


  const propertiesWithColorIcon = ['age', 'contract_expiration', 'locality', 'skill_index', 'role_index', 'role'];
  const propertiesWithSortIcon = ['age', 'contract_expiration', 'locality', 'skill_index', 'role_index', 'role'];


  const handleCloseRoleIndexInfoModal = () => {
    if (!userConfig) return;

    if (doNotShowRoleIndexInfoModalAgain) {
      const newMenuFields = { doNotShowRoleIndexInfoModalAgain: true };
      updateTeamMenu(newMenuFields, 'ownTeam', userConfig.email, userConfig.club, currentUser);
    }

    setIsRoleIndexInfoModalOpen(false);
  };


  // if the user has not opted out, this will open an info modal to explain how this works
  const openRoleIndexInfoModalIfNeeded = () => {
    const userHasOptedOut = userSettings?.ownTeamMenu?.doNotShowRoleIndexInfoModalAgain;

    if (!userHasOptedOut) {
      setTimeout(() => {
        setIsRoleIndexInfoModalOpen(true);
      }, 300);
    }
  };


  const handleOptionClick = (option: string, isOptionAlreadySelected: boolean) => {
    if (!userConfig || !userSettings) return;

    const property = menuOptionToPropertyMap[option];
    const newMenu: StringToAnyMap = { [property]: !isOptionAlreadySelected };

    if (isOptionAlreadySelected && menu['colored'] === property && property !== 'role') {
      newMenu['colored'] = null;
    }

    if (property === 'role_index') {
      newMenu['skill_index'] = false;
      if (menu['colored'] === 'skill_index') {
        newMenu['colored'] = null;
      }

      if (!isOptionAlreadySelected) {
        openRoleIndexInfoModalIfNeeded();
      }
    }
    else if (property === 'skill_index') {
      newMenu['role_index'] = false;
      if (menu['colored'] === 'role_index') {
        newMenu['colored'] = null;
      }
    }

    updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club, currentUser);
  };


  const toggleUnavailablePlayers = (isToggled: boolean) => {
    if (!userConfig || !userSettings) return;

    const newMenu = { hideUnavailablePlayers: isToggled };
    updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club, currentUser);
  };


  const handleIconClick = (
    option: string,
    iconProperty: string,
    iconIsAlreadySelected: boolean,
    event: React.MouseEvent<HTMLElement>
  ) => {

    if (!userConfig || !userSettings) return;
    event.stopPropagation();

    const property = menuOptionToPropertyMap[option];
    const newMenu: StringToAnyMap = { [iconProperty]: iconIsAlreadySelected ? null : property };

    if (iconProperty === 'colored' && !iconIsAlreadySelected) {
      if (property !== 'role') {
        newMenu[property] = true;
      }
      if (property === 'role_index') {
        newMenu['skill_index'] = false;
      }
      else if (property === 'skill_index') {
        newMenu['role_index'] = false;
      }
    }

    if ((iconProperty === 'showLeft' || iconProperty === 'showAgeCategories' || iconProperty === 'showContractExpirationYear') && !iconIsAlreadySelected) {
      newMenu[property] = true;
    }

    if (property === 'role_index' && !iconIsAlreadySelected) {
      openRoleIndexInfoModalIfNeeded();
      newMenu['role_index'] = true;
    }

    updateTeamMenu(newMenu, teamOrSquadId, userConfig.email, userConfig.club, currentUser);
  };


  const handleBenchPlayerClick = (player: StringToAnyMap) => {
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: playerOverviews[player.id],
    });
  };


  const resetTeam = async () => {
    if (!userConfig || !teamOrSquadId || !teamOrSquadData || teamButtonDisabled) return;
    setTeamButtonDisabled(true);

    await resetTeamOrSquad(teamOrSquadId, teamOrSquadData, isSquad ?? false, userConfig.email, userConfig.club, currentUser);

    setIsIconHighlighted('reset');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  const archiveTeam = async () => {
    if (!userConfig || !teamOrSquadId || !teamOrSquadData || !teamOrSquadData['formation'] || teamButtonDisabled) return;
    setTeamButtonDisabled(true);

    await archiveTeamOrSquad(teamOrSquadId, teamOrSquadData, isSquad ?? false, teamOrSquadData['formation'], userConfig.email, userConfig.club, currentUser);

    setIsIconHighlighted('archive');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  const resetOwnTeamPlanner = async () => {
    if (!userConfig || !teamOrSquadId || teamButtonDisabled || !resetPlannerConfig) return;
    setTeamButtonDisabled(true);

    await resetPlannerConfig();

    setIsIconHighlighted('reset');
    setTimeout(() => {
      setIsIconHighlighted(undefined);
      setTeamButtonDisabled(false);
    }, 750);
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    if (benchPlayers) {
      setTableData(
        userSettings?.ownTeamMenu.hideUnavailablePlayers
          ? benchPlayers.filter(player => (player.role !== 'longTermInjured' && player.role !== 'loanedOut'))
          : benchPlayers
      );
    }
  }, [benchPlayers, userSettings?.ownTeamMenu.hideUnavailablePlayers]);


  return (
    <div
      className='team-menu-container'
      style={{
        top: teamOrSquadId === 'ownTeam' ? '2vh' : teamOrSquadId === 'academyTeam' ? 0 : '-2vh',
        height: (teamOrSquadId === 'academyTeam' || teamOrSquadId === 'ownTeam') ? '100%' : undefined,
      }}
    >

      <Dialog
        open={isRoleIndexInfoModalOpen}
        onClose={() => handleCloseRoleIndexInfoModal()}
        PaperProps={modalPaperProps}
      >
        <RoleIndexInTeamsInfoModal
          closeModal={handleCloseRoleIndexInfoModal}
          doNotShowRoleIndexInfoModalAgain={doNotShowRoleIndexInfoModalAgain}
          setDoNotShowRoleIndexInfoModalAgain={setDoNotShowRoleIndexInfoModalAgain}
        />
      </Dialog>

      {teamOrSquadId === 'ownTeam' && showFirstEleven !== undefined && setShowFirstEleven && (
        <div className='team-menu-toggle-container' style={{ marginTop: '-4vh' }}>
          <div className='team-menu-toggle-section' style={{ width: userConfig && userConfig.language === 'no' ? 145 : 170 }}>
            <div className='team-menu-toggle-text'>
              {showContent && translate('showStartingEleven', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && <Toggle isToggled={showFirstEleven} setIsToggled={setShowFirstEleven} boxShadow={'0px 0px 2px 1px #181a2366'} />}
            </div>
          </div>
        </div>
      )}

      {(teamOrSquadId === 'ownTeam' || teamOrSquadId === 'ownTeamPlanner') && (
        <div className='team-menu-toggle-container' style={{ marginTop: teamOrSquadId === 'ownTeamPlanner' ? '7vh' : 10 }}>
          <div
            className='team-menu-toggle-section'
            title={translate('hideUnavailablePlayersDescription', userConfig?.language)}
            style={{ width: userConfig && userConfig.language === 'no' ? 145 : 170 }}
          >
            <div className='team-menu-toggle-text'>
              {showContent && translate('hideUnavailablePlayers', userConfig?.language)}
            </div>
            <div className='team-menu-toggle'>
              {showContent && (
                <Toggle
                  isToggled={menu['hideUnavailablePlayers']}
                  setIsToggled={toggleUnavailablePlayers}
                  boxShadow={'0px 0px 2px 1px #181a2366'}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {!showFirstEleven && (
        <div
          className='team-menu-player-info-section'
          style={{
            paddingTop: (teamOrSquadId === 'academyTeam' || teamOrSquadId === 'ownTeam') ? '2vh' : undefined,
          }}
        >
          <div className='team-menu-title fade-in'>
            {showContent && translate('showPlayerInfo', userConfig?.language)}
          </div>

          {menuOptions.map((displayOption: string, index: number) => {
            const property = menuOptionToPropertyMap[displayOption];

            const marginTop = (index === 0 || property === 'role_index') ? '1.4vh' : '2vh';

            const isSelected = menu[property] || (defaultToShortNames && property === 'shortNames' && menu[property] === undefined);

            return (
              <div key={index} className='fade-in' style={{ width: '100%', maxWidth: 200, marginTop: marginTop }}>
                <OptionButton
                  onClick={() => handleOptionClick(displayOption, isSelected)}
                  option={displayOption}
                  optionIsSelected={isSelected}
                  language={userConfig?.language}
                  showContent={showContent}

                  icons={{
                    sortIcon: propertiesWithSortIcon.includes(property),
                    colorIcon: propertiesWithColorIcon.includes(property),
                    switchIcon: property === 'role',
                    categoryIcon: property === 'age' && teamOrSquadId !== 'academyTeam',
                    calendarIcon: property === 'contract_expiration' && teamOrSquadId !== 'academyTeam',
                  }}
                  selectedIcons={{
                    sortIcon: orderBy === property,
                    colorIcon: menu.colored === property,
                    switchIcon: isSelected && menu.showLeft === property,
                    categoryIcon: isSelected && menu.showAgeCategories,
                    calendarIcon: isSelected && menu.showContractExpirationYear,
                  }}
                  onIconClick={handleIconClick}
                  style={{ height: 26, fontSize: 13, borderRadius: 4 }}
                />
              </div>
            );
          })}
        </div>
      )}

      {!showFirstEleven && (
        <div className='team-menu-team-actions-section'>
          <div className='team-menu-title fade-in'>
            {showContent && translate(isSquad ? 'squadActions' : 'teamActions', userConfig?.language)}
          </div>

          {teamOrSquadId !== 'ownTeamPlanner' && (
            <div className='fade-in' style={{ width: '100%', maxWidth: 200, marginTop: '1.4vh' }}>
              <OptionButton
                onClick={() => openConfirmModal(archiveTeam, isSquad ? 'archiveSquad' : 'archiveTeam', isSquad ? 'archiveSquadInfo' : 'archiveTeamInfo')}
                option={isSquad ? 'archiveSquad' : 'archiveTeam'}
                language={userConfig?.language}
                showContent={showContent}
                icons={{ rightIcon: <SaveAltIcon style={{ fontSize: 17 }} /> }}
                iconIsHighlighted={isIconHighlighted === 'archive'}
                style={{ height: 28, fontSize: 13, borderRadius: 4 }}
              />
            </div>
          )}

          {teamOrSquadId !== 'ownTeamPlanner' && teamOrSquadId !== 'ownTeam' && teamOrSquadId !== 'academyTeam' && (
            <div className='fade-in' style={{ width: '100%', maxWidth: 200, marginTop: '2vh' }}>
              <OptionButton
                onClick={() => openConfirmModal(resetTeam, isSquad ? 'resetSquad' : 'resetTeam', isSquad ? 'resetSquadInfo' : 'resetTeamInfo')}
                option={isSquad ? 'resetSquad' : 'resetTeam'}
                language={userConfig?.language}
                showContent={showContent}
                icons={{ rightIcon: <ReplayIcon style={{ fontSize: 17 }} /> }}
                iconIsHighlighted={isIconHighlighted === 'reset'}
                style={{ height: 28, fontSize: 13, borderRadius: 4 }}
              />
            </div>
          )}

          {teamOrSquadId === 'ownTeamPlanner' && (
            <div className='fade-in' style={{ width: '100%', maxWidth: 200, marginTop: '1.4vh', marginBottom: '2vh' }}>
              <OptionButton
                onClick={() => openConfirmModal(resetOwnTeamPlanner, 'resetPlanner', 'resetPlannerInfo')}
                option={'resetPlanner'}
                language={userConfig?.language}
                showContent={showContent}
                icons={{ rightIcon: <ReplayIcon style={{ fontSize: 17 }} /> }}
                iconIsHighlighted={isIconHighlighted === 'reset'}
                style={{ height: 28, fontSize: 13, borderRadius: 4 }}
              />
            </div>
          )}
        </div>
      )}

      {showFirstEleven && tableData && (
        <div className='own-team-menu-bench-players-table-container fade-in'>
          <div className='own-team-menu-bench-players-table'>
            <PlayerSimpleTable
              tableData={tableData}
              onPlayerClick={handleBenchPlayerClick}
              tableType={'bench'}
              maxHeight={'74vh'}
              positionKey={'bench'}
              draggedPlayer={draggedPlayer}
              setDraggedPlayer={setDraggedPlayer}
              handlePlayerDrop={handlePlayerDrop}
              tableHoverDisabled={tableHoverDisabled}
            />
          </div>
        </div>
      )}

    </div>
  );
};
