import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedPlayerState } from '../atoms/selectedPlayerState';
import { currentReactVersionState } from '../atoms/currentReactVersionState';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import { trackEvent } from '../../services/server/analytics/trackEvent';
import { SelectedPlayer } from '../../types';


export const useSetAndTrackSelectedPlayerState = () => {

  const { currentUser } = useAuthContext();
  const currentReactVersion = useRecoilValue(currentReactVersionState);

  const setSelectedPlayerState = useSetRecoilState(selectedPlayerState);

  const setAndTrackSelectedPlayerState = (selectedPlayer: SelectedPlayer | undefined) => {

    if (selectedPlayer) {
      trackEvent('PlayerClicked', { playerId: selectedPlayer.id.toString(), playerName: selectedPlayer.fullname }, currentUser, 'user', currentReactVersion);
    }

    setSelectedPlayerState(selectedPlayer);
  };

  return setAndTrackSelectedPlayerState;
};
