import '../../platform.css';
import './planner.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { dateStringToLocaleFormat, dateToLocaleTextFormat } from '../../utils/dateUtils';
import { translate } from '../../../common/language/translations';
import { getLocalityIcon, getPlannerActionIcon, getRoleIcon } from '../../utils/iconUtils';
import { PlannerAction, StringToAnyMap } from '../../types';
import { ageCategories, contractCategories, playerLocalities, teamRoles } from '../../static/propertyValues';
import { getDisplayContractCategory, getDisplayPlayerName } from '../../utils/playerUtils';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { getAgeCategoryIndexEquivalent, getContractCategoryIndexEquivalent, getIndexColor } from '../../utils/colorUtils';


interface PlannerStatusViewProps {
  selectedDate: Date;
  currentTeamSummary: StringToAnyMap;
  actions: PlannerAction[];
  onActionClick: (action: PlannerAction) => void;
}

export const PlannerStatusView: React.FC<PlannerStatusViewProps> = ({
  selectedDate,
  currentTeamSummary,
  actions,
  onActionClick,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const { openTextModal } = useOpenGlobalModal();

  const dateDividerRef = useRef<HTMLDivElement | null>(null);


  const actionsWithDateDivider = actions.reduce((acc, action, index) => {

    const isBeforeSelectedDate = new Date(action.date) < selectedDate;

    if (!acc.dividerInserted && !isBeforeSelectedDate) {
      acc.result.push({
        type: 'dateDivider',
        date: selectedDate.toISOString().split('T')[0],
      });
      acc.dividerInserted = true;
    }

    acc.result.push(action);

    // if all actions are in the past, insert a divider at the end
    if (index === actions.length - 1 && !acc.dividerInserted) {
      acc.result.push({
        type: 'dateDivider',
        date: selectedDate.toISOString().split('T')[0],
      });
    }

    return acc;

  }, { result: [] as (PlannerAction | { type: 'dateDivider'; date: string; })[], dividerInserted: false });


  const actionHeight = 31;
  const actionGap = 10;
  const totalActionHeight = actionHeight + actionGap;

  useEffect(() => {
    const parent = dateDividerRef.current?.parentElement;
    if (parent && dateDividerRef.current) {

      const { offsetTop } = dateDividerRef.current;
      let newTop = offsetTop - parent.clientHeight / 2;
      newTop = Math.round(newTop / totalActionHeight) * totalActionHeight;

      parent.scrollTo({
        top: newTop,
        behavior: 'smooth',
      });
    }
  }, [actions, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='planner-view-left-section-content-container'>

      <div className='planner-view-section-title'>
        {translate('status', userConfig?.language) + ' ' + dateToLocaleTextFormat(selectedDate, userConfig?.language ?? 'en')}
      </div>

      <div className='planner-view-info-section planner-view-status-view-info-section'>
        <div className='planner-view-info-section-entry' style={{ minHeight: 21 }}>
          <div className='planner-view-info-section-entry-label'>
            {translate('numberOfPlayers', userConfig?.language)}
          </div>

          <div className='planner-view-info-section-entry-value'>
            {currentTeamSummary.numberOfPlayers}
          </div>
        </div>

        <div className='planner-view-info-section-entry' style={{ minHeight: 21 }}>
          <div className='planner-view-info-section-entry-label'>
            {translate('averageAge', userConfig?.language)}
          </div>

          <div className='planner-view-info-section-entry-value'>
            {currentTeamSummary.averageAge ?? '-'}
          </div>
        </div>

        <div className='planner-view-status-view-category-container'>

          <div className='planner-view-status-view-category-section'>

            {ageCategories.map(ageCategory => {

              const widthPercentage = currentTeamSummary[ageCategory] / currentTeamSummary.maxPlayersInAnyCategory * 55;
              const indexEquivalent = getAgeCategoryIndexEquivalent(ageCategory);
              const backgroundColor = getIndexColor(indexEquivalent, 69);

              return (
                <div
                  key={ageCategory}
                  className='planner-view-status-view-category-row'
                  style={{ justifyContent: 'flex-start', textAlign: 'left' }}
                >
                  <div
                    className='planner-view-info-section-entry-label planner-view-status-view-bar-entry-label-clickable'
                    style={{ minWidth: 24, maxWidth: 24, marginRight: 7 }}
                    onClick={() => openTextModal(ageCategory, translate(ageCategory + 'Info', userConfig?.language))}
                  >
                    {ageCategory}
                  </div>

                  <div
                    className='planner-view-status-view-bar'
                    style={{ width: widthPercentage + '%', backgroundColor: backgroundColor }}
                    title={translate('numberOf' + ageCategory, userConfig?.language)}
                  >
                    &nbsp;
                  </div>

                  <div className='planner-view-status-view-bar-value'>
                    {currentTeamSummary[ageCategory]}
                  </div>
                </div>
              );
            })}
          </div>

          <div className='planner-view-status-view-category-section'>

            {contractCategories.map(contractCategory => {

              const widthPercentage = currentTeamSummary[contractCategory] / currentTeamSummary.maxPlayersInAnyCategory * 55;
              const indexEquivalent = getContractCategoryIndexEquivalent(contractCategory);
              const backgroundColor = getIndexColor(indexEquivalent, 69);

              return (
                <div
                  key={contractCategory}
                  className='planner-view-status-view-category-row'
                  style={{ justifyContent: 'flex-end', textAlign: 'right' }}
                >

                  <div className='planner-view-status-view-bar-value'>
                    {currentTeamSummary[contractCategory]}
                  </div>

                  <div
                    className='planner-view-status-view-bar'
                    style={{ width: widthPercentage + '%', backgroundColor: backgroundColor }}
                    title={translate('numberOfPlayersWhoseContractsExpireThisYear', userConfig?.language)}
                  >
                    &nbsp;
                  </div>

                  <div
                    className='planner-view-info-section-entry-label flex-row align-center'
                    style={{ minWidth: 32, marginLeft: 6, justifyContent: 'end', fontSize: contractCategory === '3+' ? 10 : 12 }}
                  >
                    {getDisplayContractCategory(contractCategory, selectedDate.toISOString().split('T')[0])}

                    {contractCategory === '3+' && (
                      <div style={{ marginTop: -2, marginRight: -2 }}>
                        {'+'}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

        </div>

        <div className='planner-view-status-view-distribution-row'>
          {teamRoles.map((role) => {
            return (
              <div key={role} className='planner-view-status-view-distribution-entry'>
                {getRoleIcon(role, 15, 'light')}
                {currentTeamSummary[role] ?? 0}
              </div>
            );
          })}

          <div className='planner-view-status-view-distribution-row-divider'>&nbsp;</div>

          {playerLocalities.map((locality) => {
            return (
              <div key={locality} className='planner-view-status-view-distribution-entry'>
                {getLocalityIcon(locality, 15, 'light')}
                {currentTeamSummary[locality] ?? 0}
              </div>
            );
          })}
        </div>
      </div>

      <div className='planner-view-status-view-action-section'>
        <div className='planner-view-section-title planner-view-player-view-section-title'>
          {translate('actions', userConfig?.language)}
        </div>

        <div className='planner-view-actions-column planner-view-status-view-actions-column' style={{ gap: actionGap }}>
          {actionsWithDateDivider.result.map(action => {

            if (action.type === 'dateDivider') {
              return (
                <div
                  ref={dateDividerRef}
                  key='dateDivider'
                  className='planner-view-actions-column-date-divider-container'
                >
                  <div className='planner-view-actions-column-date-divider'>&nbsp;</div>
                  <div className='planner-view-actions-column-date-divider-text'>{dateStringToLocaleFormat(action.date)}</div>
                  <div className='planner-view-actions-column-date-divider'>&nbsp;</div>
                </div>
              );
            }

            const isInPast = new Date(action.date) <= selectedDate;

            const iconNeedsShiftingRight = action.type === 'playerAdded' || action.type === 'playerRemoved' || action.type === 'positionChanged';

            return (
              <div
                key={action.playerId + action.date + action.type}
                className='planner-view-action-row planner-view-status-view-action-row'
                style={{ minHeight: actionHeight, maxHeight: actionHeight }}
                onClick={() => onActionClick(action)}
              >
                <div className='flex-row align-center' style={{ minWidth: iconNeedsShiftingRight ? 23 : 24, marginLeft: iconNeedsShiftingRight ? 6 : 5 }}>
                  {getPlannerActionIcon(action.type, 18, 'light', action.role, action.locality)}
                </div>

                <div className='flex-column' style={{ gap: 2, minWidth: 163 }}>
                  <div className='flex-row align-center'>
                    {translate(action.type + (!isInPast ? '<future>' : ''), userConfig?.language)}

                    {action.type === 'positionChanged' && action.position && (
                      <div className='flex-row align-center' style={{ marginLeft: 3, fontSize: 10, color: '#ffffffcc' }}>
                        <ArrowRightAltIcon style={{ fontSize: 12 }} />
                        <div style={{ marginLeft: 3, marginTop: -1 }}>{translate(action.position, userConfig?.language)}</div>
                      </div>
                    )}
                  </div>
                  <div className='flex-row align-center' style={{ fontSize: 10, color: '#ffffffcc' }}>
                    {getDisplayPlayerName(action.fullname, 24)}
                  </div>
                </div>

                <div className='flex-row align-center' style={{ minWidth: 67, color: '#ffffffcc' }}>
                  {dateStringToLocaleFormat(action.date)}
                </div>

                <div className='flex-row align-center' style={{ width: 14 }}>
                  {isInPast && <CheckIcon style={{ fontSize: 14, color: '#90ff82' }} />}
                  {!isInPast && <HourglassEmptyIcon style={{ fontSize: 14, color: '#ffffffaa' }} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>

    </div >
  );
};
