import { UserConfig } from '../types';


export const getNavigationWidth = (userConfig: UserConfig | null) => {
  if (userConfig && userConfig.isNavigationExpanded) {
    if (userConfig.language === 'no') return 133;
    if (userConfig.language === 'en') return 143;
    if (userConfig.language === 'se') return 143;
    if (userConfig.language === 'dk') return 138;
  }
  return 64;
};
