import '../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { translate } from '../../../../common/language/translations';
import { Toggle } from '../../controls/input/Toggle';
import { useSetAndTrackNavigationPropsState } from '../../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';


interface RoleIndexInTeamsInfoModalProps {
  closeModal: () => void;
  doNotShowRoleIndexInfoModalAgain: boolean;
  setDoNotShowRoleIndexInfoModalAgain: (value: boolean) => void;
}

export const RoleIndexInTeamsInfoModal: React.FC<RoleIndexInTeamsInfoModalProps> = ({
  closeModal,
  doNotShowRoleIndexInfoModalAgain,
  setDoNotShowRoleIndexInfoModalAgain,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();


  const onButtonClick = () => {
    setAndTrackNavigationProps({
      activeTab: 'settings',
      activeSubTab: 2,
    });
    closeModal();
  };


  const textArray = userConfig
    ? translate('roleIndexInTeamInfo', userConfig?.language).split('\n')
    : [];


  return (
    <div
      className='modal-root-container text-modal-root'
      style={{ width: 550 }}
    >

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('roleIndexInTeam', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div
        className='modal-content-container text-modal-content-container'
        style={{
          fontSize: 15,
          textAlign: 'left',
        }}
      >
        {textArray.map((textLine: string, index: number) => {
          const isEmptySpace = textLine === '&nbsp;';

          if (isEmptySpace) {
            return (
              <div
                key={index}
                style={{ height: 20 }}
              />
            );
          }

          return (
            <div
              key={index}
              style={{
                marginTop: index > 0 ? 12 : undefined,
              }}>
              {textLine}
            </div>
          );
        })}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 50,
            height: 30,
            fontSize: 14,
            color: '#ffffffee'
          }}
        >
          {translate('doNotShowThisInfoAgain', userConfig?.language)}

          <div style={{ height: 11, width: 30, marginLeft: 12 }}>
            <Toggle isToggled={doNotShowRoleIndexInfoModalAgain} setIsToggled={setDoNotShowRoleIndexInfoModalAgain} />
          </div>
        </div>

        <TextButton
          onClick={() => onButtonClick()}
          text={translate('goToRoleSettings', userConfig?.language)}
          icon={<ArrowRightAltIcon style={{ fontSize: 22 }} />}
          style={{ width: 240, marginTop: 40 }}
        />
      </div>
    </div>
  );
};
