
import BuildIcon from '@mui/icons-material/Build';

import { JSX } from 'react';
import { StringToAnyMap } from '../../../types';
import { getFormIcon, getLocalityIcon, getPlayingTimeIcon, getRoleIcon } from '../../../utils/iconUtils';
import { getAge, getAgeCategory, getContractCategory, getDisplayPlayerName, getMonthsLeftOfContract } from '../../../utils/playerUtils';
import { ClubLogo } from '../../display/images/ClubLogo';
import { CountryFlag } from '../../display/images/CountryFlag';
import { PlayerImage } from '../../display/images/PlayerImage';
import { shouldAddPlayerSimpleTableTeamViewPadding } from '../utils';
import { PlayerSimpleTableType } from './PlayerSimpleTable';
import { PlayerSimpleTableInfoCell, PlayerSimpleTableTeamIconCell } from '../tableCells';

import {
  getAgeCategoryIndexEquivalent,
  getAgeIndexEquivalent,
  getContractCategoryIndexEquivalent,
  getIndexColor,
  getLocalityIndexEquivalent,
  getMonthsLeftOfContractIndexEquivalent
} from '../../../utils/colorUtils';


interface PlayerSimpleTableColumn {
  key: string;
  width: number;
  cell: (row: StringToAnyMap) => JSX.Element | null;
}


export const getPlayerSimpleTableColumns = (
  tableType: PlayerSimpleTableType, // undefined -> team
  teamMenu: StringToAnyMap,
  clubLightColor: string,
  disablePlayersWithoutEventData: boolean | undefined,
  isGoalkeeper: boolean | undefined,
  isSmallTeamView: boolean | undefined,
  defaultToShortNames: boolean | undefined,
): PlayerSimpleTableColumn[] => {

  if (tableType === 'addPlayerView') {
    return addPlayerColumns(disablePlayersWithoutEventData, isGoalkeeper);
  }

  if (tableType === 'addPlayerViewTeamIcon') {
    return addPlayerIconColumns('teamIcon');
  }

  if (tableType === 'addPlayerViewRoleIcon') {
    return addPlayerIconColumns('roleIcon', clubLightColor);
  }

  if (tableType === 'archivedTeam') {
    return archivedTeamViewColumns;
  }

  if (tableType === 'playerButton') {
    return playerButtonColumns;
  }

  if (tableType === 'bench') {
    return benchColumns(clubLightColor);
  }

  return teamViewColumns(tableType, teamMenu, clubLightColor, isSmallTeamView ?? false, defaultToShortNames ?? false);
};


const teamViewColumnsPadding = 2;
const teamViewColumns = (tableType: string, teamMenu: StringToAnyMap, clubLightColor: string, isSmallTeamView: boolean, defaultToShortNames: boolean) => {

  const columns: PlayerSimpleTableColumn[] = [];

  const tableHeight = getPlayerSimpleTableRowHeight(tableType, isSmallTeamView);
  const useShortNames = teamMenu.shortNames === undefined ? defaultToShortNames : teamMenu.shortNames;
  const smallTableWidthOffset = isSmallTeamView ? 2 : 0;
  const smallTableIconSizeOffset = isSmallTeamView ? 1 : 0;

  if (teamMenu.role && teamMenu.showLeft === 'role') {
    columns.push({
      key: 'role',
      width: 24 - smallTableWidthOffset,
      cell: (row: StringToAnyMap) => (
        <div className='table-cell'>
          {getRoleIcon(row.role, 18 - smallTableIconSizeOffset, 'dark', clubLightColor)}
        </div>
      ),
    });
  }

  columns.push({
    key: 'image',
    width: tableHeight,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <PlayerImage
          src={row.image_url}
          size={tableHeight - 2}
          containerStyle={{ transition: 'height 250ms, width 250ms' }}
          imageStyle={{ transition: 'height 250ms, width 250ms' }}
        />
      </div>
    ),
  });

  columns.push({
    key: 'fullname',
    width: useShortNames ? 114 : 138,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <div className={'player-simple-table-cell-fullname' + (tableType === 'ownTeam' ? ' player-simple-table-cell-fullname-' + row.role : '')}>
          {getDisplayPlayerName(row.fullname, useShortNames ? 10 : 18)}
        </div>
      </div>
    ),
  });

  if (teamMenu.role && teamMenu.showLeft !== 'role') {
    columns.push({
      key: 'role',
      width: 24 - smallTableWidthOffset,
      cell: (row: StringToAnyMap) => (
        <div className='table-cell'>
          {getRoleIcon(row.role, 18 - smallTableIconSizeOffset, 'dark', clubLightColor)}
        </div>
      ),
    });
  }

  if (teamMenu.country_code) {
    columns.push({
      key: 'country_code',
      width: 21 - smallTableWidthOffset,
      cell: (row: StringToAnyMap) => (
        <div className='table-cell'>
          <CountryFlag
            countryCode={row.country_code}
            width={15 - smallTableIconSizeOffset}
            isWhiteBackground={true}
          />
        </div>
      ),
    });
  }

  if (teamMenu.locality) {
    columns.push({
      key: 'locality',
      width: 24 - smallTableWidthOffset,
      cell: (row: StringToAnyMap) => {

        const indexEquivalent = getLocalityIndexEquivalent(row.locality);
        const backgroundColor = teamMenu.colored === 'locality' ? getIndexColor(indexEquivalent, 69) : undefined;

        return (
          <div
            className='table-cell'
            style={{ backgroundColor: backgroundColor }}
          >
            {getLocalityIcon(row.locality, 18 - smallTableIconSizeOffset, 'dark')}
          </div>
        );
      },
    });
  }

  if (teamMenu.club_logo_url) {
    columns.push({
      key: 'club_logo_url',
      width: 21 - smallTableWidthOffset,
      cell: (row: StringToAnyMap) => (
        <div className='table-cell'>
          <ClubLogo
            clubLogoUrl={row.club_logo_url}
            size={16 - smallTableIconSizeOffset}
          />
        </div>
      ),
    });
  }

  if (teamMenu.age) {

    const useAgeCategories = teamMenu.showAgeCategories;

    columns.push({
      key: 'age',
      width: useAgeCategories ? 28 : 21,
      cell: (row: StringToAnyMap) => {

        let displayValue;
        let indexEquivalent;

        if (useAgeCategories) {
          const ageCategory = getAgeCategory(row.birth_date, row.when);
          displayValue = ageCategory;
          indexEquivalent = getAgeCategoryIndexEquivalent(ageCategory);
        }

        else {
          const age = getAge(row.birth_date, row.when);
          indexEquivalent = getAgeIndexEquivalent(age, tableType === 'academyTeam');
          displayValue = age ? Math.round(Number(age)) : undefined;
        }

        const backgroundColor = teamMenu.colored === 'age' ? getIndexColor(indexEquivalent, 69) : undefined;

        return (
          <div
            className='table-cell player-simple-table-info'
            style={{ backgroundColor: backgroundColor }}
          >
            {displayValue}
          </div>
        );
      },
    });
  }

  if (teamMenu.contract_expiration) {

    const useExpirationYear = teamMenu.showContractExpirationYear;

    columns.push({
      key: 'contract_expiration',
      width: useExpirationYear ? 33 : 21,
      cell: (row: StringToAnyMap) => {

        let displayValue;
        let indexEquivalent;

        if (useExpirationYear) {
          const contractCategory = getContractCategory(row.contract_expiration, row.when);
          indexEquivalent = getContractCategoryIndexEquivalent(contractCategory);
          displayValue = row.contract_expiration ? row.contract_expiration.split('-')[0] : undefined;
        }

        else {
          const monthsLeft = getMonthsLeftOfContract(row.contract_expiration, row.when);
          displayValue = monthsLeft;
          indexEquivalent = getMonthsLeftOfContractIndexEquivalent(monthsLeft);
        }

        const backgroundColor = teamMenu.colored === 'contract_expiration' ? getIndexColor(indexEquivalent, 69) : undefined;

        return (
          <div
            className='table-cell player-simple-table-info'
            style={{ backgroundColor: backgroundColor }}
          >
            {displayValue}
          </div>
        );
      },
    });
  }

  if (teamMenu.form_status) {
    columns.push({
      key: 'form_status',
      width: 22,
      cell: (row: StringToAnyMap) => (
        <div className='table-cell'>
          {getFormIcon(row.form_status, 17, 'dark', 35)}
        </div>
      ),
    });
  }

  if (teamMenu.availability_status) {
    columns.push({
      key: 'availability_status',
      width: 22,
      cell: (row: StringToAnyMap) => (
        <div className='table-cell'>
          {getPlayingTimeIcon(row.availability_status, 17, 'dark')}
        </div>
      ),
    });
  }

  if (teamMenu.skill_index) {
    columns.push({
      key: 'skill_index',
      width: 26,
      cell: (row: StringToAnyMap) => (
        <div
          className='table-cell player-simple-table-info'
          style={{ backgroundColor: teamMenu.colored === 'skill_index' ? getIndexColor(row.skill_index, 69) : undefined }}
        >
          {row.skill_index !== undefined && row.skill_index !== null ? Math.round(Number(row.skill_index) * 10) / 10 : undefined}
        </div>
      ),
    });
  }

  if (teamMenu.role_index) {
    columns.push({
      key: 'role_index',
      width: 26,
      cell: (row: StringToAnyMap) => {

        let roleIndex = row.role_index;
        if (roleIndex === 'beingComputed') {
          roleIndex = <BuildIcon style={{ fontSize: 15, marginTop: 1, color: '#000000aa' }} />;
        }
        else if (roleIndex !== undefined && roleIndex !== null) {
          roleIndex = Math.round(Number(roleIndex) * 10) / 10;
        }

        return (
          <div
            className='table-cell player-simple-table-info'
            style={{ backgroundColor: teamMenu.colored === 'role_index' ? getIndexColor(row.role_index, 69) : undefined }}
          >
            {roleIndex}
          </div>
        );
      },
    });
  }

  if (shouldAddPlayerSimpleTableTeamViewPadding(teamMenu)) {
    columns.push({
      key: 'padding',
      width: teamViewColumnsPadding,
      cell: () => null,
    });
  }

  return columns;
};


const addPlayerColumns = (disablePlayersWithoutEventData?: boolean, isGoalkeeper?: boolean): PlayerSimpleTableColumn[] => [
  {
    key: 'id',
    width: 199,
    cell: (row: StringToAnyMap) => (
      <PlayerSimpleTableInfoCell
        row={row}
        disablePlayersWithoutEventData={disablePlayersWithoutEventData}
        isGoalkeeper={isGoalkeeper}
      />
    )
  },
  {
    key: 'currentTeam',
    width: 28,
    cell: (row: StringToAnyMap) => (
      <PlayerSimpleTableTeamIconCell
        row={row}
        disablePlayersWithoutEventData={disablePlayersWithoutEventData}
        isGoalkeeper={isGoalkeeper}
      />
    ),
  },
];


const addPlayerIconColumns = (iconToShow: 'teamIcon' | 'roleIcon', clubLightColor?: string): PlayerSimpleTableColumn[] => [
  {
    key: 'image',
    width: 32,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <PlayerImage
          src={row.image_url}
          size={30}
        />
      </div>
    ),
  },
  {
    key: 'fullname',
    width: 156,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <div className='player-simple-table-cell-fullname' style={{ fontWeight: 400 }}>
          {getDisplayPlayerName(row.fullname, 18)}
        </div>
      </div>
    ),
  },
  {
    key: 'icon',
    width: 32,
    cell: (row: StringToAnyMap) => {
      if (iconToShow === 'teamIcon') return <PlayerSimpleTableTeamIconCell row={row} />;
      if (iconToShow === 'roleIcon') return <div className='table-cell'>{getRoleIcon(row.role, 18, 'dark', clubLightColor)}</div>;
      return null;
    }
  },
];


const archivedTeamViewColumns: PlayerSimpleTableColumn[] = [
  {
    key: 'image',
    width: 32,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <PlayerImage
          src={row.image_url}
          size={30}
        />
      </div>
    ),
  },
  {
    key: 'fullname',
    width: 138,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <div className='player-simple-table-cell-fullname'>
          {getDisplayPlayerName(row.fullname, 16)}
        </div>
      </div>
    ),
  },
];


const playerButtonColumns: PlayerSimpleTableColumn[] = [
  {
    key: 'image',
    width: 34,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <PlayerImage
          src={row.image_url}
          size={30}
        />
      </div>
    ),
  },
  {
    key: 'fullname',
    width: 180,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <div className='player-simple-table-cell-fullname'>
          {getDisplayPlayerName(row.fullname, 20)}
        </div>
      </div>
    ),
  },
];


const benchColumns = (clubLightColor: string): PlayerSimpleTableColumn[] => [
  {
    key: 'image',
    width: 32,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <PlayerImage
          src={row.image_url}
          size={30}
        />
      </div>
    ),
  },
  {
    key: 'fullname',
    width: 126,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>
        <div className='player-simple-table-cell-fullname'>
          {getDisplayPlayerName(row.fullname, 10)}
        </div>
      </div>
    ),
  },
  {
    key: 'icon',
    width: 32,
    cell: (row: StringToAnyMap) => (
      <div className='table-cell'>{getRoleIcon(row.role, 18, 'dark', clubLightColor)}</div>
    )
  },
];


export const getEmptyPlayerSimpleTableWidth = (
  tableType: PlayerSimpleTableType,
  teamMenu: StringToAnyMap,
  isSmallTeamView: boolean | undefined,
  defaultToShortNames: boolean | undefined,
) => {

  if (tableType === 'bench') return 190;
  else if (tableType === 'archivedTeam') return 170;

  const tableHeight = getPlayerSimpleTableRowHeight(tableType, isSmallTeamView);
  const isSmallTable = tableType === 'ownTeamPlanner';
  const useShortNames = teamMenu.shortNames === undefined ? defaultToShortNames : teamMenu.shortNames;
  const smallTableWidthOffset = isSmallTable ? 2 : 0;


  let width = tableHeight; // image width
  width += useShortNames ? 114 : 138; // fullname width

  if (teamMenu.role) width += (24 - smallTableWidthOffset);
  if (teamMenu.country_code) width += (21 - smallTableWidthOffset);
  if (teamMenu.locality) width += (24 - smallTableWidthOffset);
  if (teamMenu.club_logo_url) width += (21 - smallTableWidthOffset);

  if (teamMenu.age) width += 21;
  if (teamMenu.age && teamMenu.showAgeCategories === 'age') width += 7;

  if (teamMenu.contract_expiration) width += 21;
  if (teamMenu.contract_expiration && teamMenu.showContractExpirationYear) width += 12;

  if (teamMenu.form_status) width += 22;
  if (teamMenu.availability_status) width += 22;
  if (teamMenu.skill_index) width += 26;
  if (teamMenu.role_index) width += 26;

  if (shouldAddPlayerSimpleTableTeamViewPadding(teamMenu)) width += teamViewColumnsPadding;

  return width;
};


export const getPlayerSimpleTableRowHeight = (tableType: string | undefined, isSmallTeamView: boolean | undefined) => {
  if (isSmallTeamView) return 28;
  if (tableType === 'addPlayerView') return 38;
  return 32;
};
