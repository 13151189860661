import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap } from '../../types';
import { DropDown } from '../controls/dropDowns/DropDown';
import { InputField } from '../controls/input/InputField';
import { addTeamOrSquad, deleteTeamOrSquad, editTeamOrSquad } from '../../services/firestore/teamsOrSquads';
import { formationOptions } from '../../static/propertyValues';
import { TextButton } from '../controls/buttons/TextButton';
import { IconButton } from '../controls/buttons/IconButton';


interface TeamOrSquadModalProps {
  teamsOrSquads: StringToAnyMap | null;
  isSquad: boolean;
  setIsTeamOrSquadModalOpen: (value: boolean) => void;
  activeTabIndex: number | undefined,
  setActiveTabIndex: (tab: number) => void;
  tabIndexToEdit: number | undefined,
  setTabIndexToEdit: (tab: number | undefined) => void;
}


export const TeamOrSquadModal: React.FC<TeamOrSquadModalProps> = ({
  teamsOrSquads,
  isSquad,
  setIsTeamOrSquadModalOpen,
  activeTabIndex,
  setActiveTabIndex,
  tabIndexToEdit,
  setTabIndexToEdit
}) => {

  const { currentUser } = useAuthContext();
  const userConfig = useRecoilValue(userConfigState);

  const teams = useRecoilValue(teamsState);

  const { openConfirmModal } = useOpenGlobalModal();

  const clubSettings = useRecoilValue(clubSettingsState);
  const clubFormation = clubSettings?.formation;

  const [searchString, setSearchString] = useState(tabIndexToEdit !== undefined && teamsOrSquads ? Object.values(teamsOrSquads)[tabIndexToEdit]['name'] : '');

  const [selectedFormation, setSelectedFormation] = useState<string[]>(
    tabIndexToEdit !== undefined && teamsOrSquads ? [Object.values(teamsOrSquads)[tabIndexToEdit]['formation']] : [clubFormation]
  );
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);

  const [isFormationDropDownExpanded, setIsFormationDropDownExpanded] = useState(false);
  const [isTeamDropDownExpanded, setIsTeamDropDownExpanded] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isButtonDisabled()) {
      handleAddOrEditClick();
    }
  };

  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 26) {
      setSearchString(event.target.value);
    }
  };

  const handleAddOrEditClick = async () => {
    if (!userConfig || submitButtonDisabled || !teamsOrSquads || !clubFormation) return;

    setSubmitButtonDisabled(true);

    if (tabIndexToEdit !== undefined) {
      await editTeamOrSquad(
        Object.keys(teamsOrSquads)[tabIndexToEdit],
        searchString,
        selectedFormation[0],
        Object.values(teamsOrSquads)[tabIndexToEdit],
        isSquad,
        userConfig.email,
        userConfig.club,
        currentUser,
      );

      setActiveTabIndex(tabIndexToEdit);
      setIsTeamOrSquadModalOpen(false);
      setTimeout(() => {
        setTabIndexToEdit(undefined);
      }, 150);
    }
    else {
      let newTeamOrSquadId: string | undefined = undefined;
      const selectedTeamId = selectedTeam.length > 0 ? selectedTeam[0] : undefined;

      newTeamOrSquadId = await addTeamOrSquad(
        searchString,
        selectedFormation[0],
        Object.keys(teamsOrSquads).length,
        clubFormation,
        isSquad,
        userConfig.email,
        userConfig.club,
        currentUser,
        selectedTeamId,
      );

      if (newTeamOrSquadId !== undefined) {
        setIsTeamOrSquadModalOpen(false);
      }
    }

    setTimeout(() => {
      setSubmitButtonDisabled(false);
    }, 500);
  };

  const confirmDelete = async () => {
    if (!userConfig || tabIndexToEdit === undefined || activeTabIndex === undefined || !teamsOrSquads || deleteButtonDisabled) return;

    setDeleteButtonDisabled(true);

    setIsTeamOrSquadModalOpen(false);

    const orderIndex = Object.values(teamsOrSquads)[tabIndexToEdit]['orderIndex'];
    const name = Object.keys(teamsOrSquads)[tabIndexToEdit];

    setTabIndexToEdit(undefined);

    await deleteTeamOrSquad(name, isSquad, orderIndex, userConfig.club, currentUser);

    setTimeout(() => {
      setDeleteButtonDisabled(false);
    }, 500);
  };


  const removeDropDownExpansions = () => {
    setIsFormationDropDownExpanded(false);
    setIsTeamDropDownExpanded(false);
  };


  const isButtonDisabled = () => {
    if (submitButtonDisabled) return true;

    if (searchString.length === 0) {
      return true;
    }

    if (tabIndexToEdit === undefined) {
      return searchString.length === 0 || selectedFormation.length === 0;
    }

    const isNewName = teamsOrSquads && searchString.length > 0 && searchString !== Object.values(teamsOrSquads)[tabIndexToEdit]['name'];
    const isNewFormation = teamsOrSquads && selectedFormation.length > 0 && selectedFormation[0] !== Object.values(teamsOrSquads)[tabIndexToEdit]['formation'];

    return !isNewName && !isNewFormation;
  };


  const getButtonText = () => {
    if (!userConfig) return '';

    if (tabIndexToEdit === undefined) {
      return translate(isSquad ? 'createSquad' : 'createTeam', userConfig.language);
    }

    return translate('confirmEdits', userConfig.language);
  };


  return (
    <div className='modal-root-container team-or-squad-modal-root' style={{ height: isSquad && tabIndexToEdit === undefined ? 700 : 650 }}>

      {(isFormationDropDownExpanded || isTeamDropDownExpanded) &&
        <div className='modal-empty-background' onClick={() => removeDropDownExpansions()} />
      }

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={() => setIsTeamOrSquadModalOpen(false)}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      {!deleteButtonDisabled && (
        <div className='modal-root-title'>
          {tabIndexToEdit !== undefined && teamsOrSquads
            ? Object.values(teamsOrSquads)[tabIndexToEdit]['name']
            : translate(isSquad ? 'addNewSquad' : 'addNewTeam', userConfig?.language)
          }
        </div>
      )}

      <div className='modal-divider modal-root-title-divider' />

      {!deleteButtonDisabled && (
        <div className='modal-content-container'>

          <div
            className='modal-content-section'
            style={{ minHeight: 250 }}
          >
            <div className='modal-section-title'>
              {tabIndexToEdit !== undefined && translate(isSquad ? 'editSquadName' : 'editTeamName', userConfig?.language)}
              {tabIndexToEdit === undefined && translate(isSquad ? 'provideSquadName' : 'provideTeamName', userConfig?.language)}
            </div>

            <InputField
              value={searchString}
              placeholder={translate('provideName', userConfig?.language)}
              onChange={onChangeSearchField}
              onKeyDown={onKeyDownSearchField}
              resetValue={() => setSearchString('')}
              width={240}
            />

            <div className='modal-divider' style={{ marginTop: 15 }} />

            <div className='modal-section-title'>
              {translate(tabIndexToEdit !== undefined ? 'editFormation' : 'selectFormation', userConfig?.language)}
            </div>

            <div className='team-or-squad-modal-drop-down'>
              <DropDown
                id={'team-or-squad-modal-formation-select'}
                dropDownOptions={formationOptions}
                selectedOptions={selectedFormation}
                setSelectedOptions={setSelectedFormation}
                isDropDownExpanded={isFormationDropDownExpanded}
                setIsDropDownExpanded={setIsFormationDropDownExpanded}
                defaultDropDownText={'selectFormation'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
                maxHeight={'50vh'}
                height={26}
              />
            </div>

            {isSquad && tabIndexToEdit === undefined && (
              <div className='modal-content-sub-section' style={{ marginTop: 15 }}>
                <div className='modal-divider' />

                <div className='modal-section-title'>
                  {translate('loadPlayersFromTeam', userConfig?.language)}
                </div>

                <div className='team-or-squad-modal-drop-down'>
                  <DropDown
                    id={'team-or-squad-modal-team-select'}
                    dropDownOptions={teams ? Object.keys(teams) : []}
                    selectedOptions={selectedTeam}
                    setSelectedOptions={setSelectedTeam}
                    isDropDownExpanded={isTeamDropDownExpanded}
                    setIsDropDownExpanded={setIsTeamDropDownExpanded}
                    defaultDropDownText={'selectTeam'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    maxHeight={'33vh'}
                    height={26}
                  />
                </div>
              </div>
            )}
          </div>

          <div
            className='modal-content-section'
            style={{ maxHeight: 160, minHeight: tabIndexToEdit !== undefined ? 120 : 80 }}
          >
            <TextButton
              onClick={async () => await handleAddOrEditClick()}
              text={getButtonText()}
              icon={tabIndexToEdit === undefined ? <AddIcon style={{ fontSize: 24 }} /> : <UpdateIcon style={{ fontSize: 24 }} />}
              disabled={isButtonDisabled()}
            />

            {tabIndexToEdit !== undefined && <div className='modal-divider' />}
            {tabIndexToEdit !== undefined && (
              <TextButton
                onClick={() => openConfirmModal(confirmDelete, isSquad ? 'deleteSquad' : 'deleteTeam', 'noRegretInfo')}
                text={translate(isSquad ? 'deleteSquad' : 'deleteTeam', userConfig?.language)}
                icon={<DeleteOutlineIcon style={{ fontSize: 24 }} />}
              />
            )}
          </div>

        </div>
      )}

    </div>
  );
};
