import { RoleConfigMap, StringToAnyMap } from '../../types';


export const getMetricColumnWidth = (metricName: string) => {
  return Math.max(metricName.length * 7 + 10.5, 50) + 20;
};


export const getRoleColumnWidth = (roleName: string) => {
  return Math.min(Math.max(60, roleName.length * 8), 100);
};


export const getRoleColumnsWidth = (
  selectedRoles: string[] | undefined,
  roleConfigs: RoleConfigMap | undefined,
) => {

  return selectedRoles && roleConfigs
    ? selectedRoles.reduce((acc, role) => acc + getRoleColumnWidth(roleConfigs[role].name), 0)
    : 0;
};


export const getCurrencyExtension = (clubCountryCode: string) => {
  switch (clubCountryCode) {
    case 'NO':
      return 'NOK';
    case 'SE':
      return 'SEK';
    case 'DK':
      return 'DKK';
    case 'JP':
      return '¥';
    default:
      return '€';
  }
};


// we want to add a small padding to the right of icon columns (except role), if there is no other column to the right
const anyNumericColumnSelected = (menu: StringToAnyMap) => menu.role_index || menu.skill_index || menu.age || menu.contract_expiration || menu.locality;
const anyIconColumnSelected = (menu: StringToAnyMap) => menu.club_logo_url || menu.country_code || menu.form_status || menu.availability_status;
export const shouldAddPlayerSimpleTableTeamViewPadding = (menu: StringToAnyMap) => anyIconColumnSelected(menu) && !anyNumericColumnSelected(menu);
