import '../../platform.css';
import './searchPlayers.css';

import { useAuthContext } from '../../../common/contexts/useAuthContext';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { nameSearchHistorySelector } from '../../recoil/selectors/scoutingConfigSelectors';

import { StringToAnyMap, PlayerOverview, PlayerOverviewsQueryOptions } from '../../types';
import { translate } from '../../../common/language/translations';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';
import { InputField } from '../controls/input/InputField';
import { debounce } from '../../utils/utils';
import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../services/server/application/playerOverviews';
import { playerSimpleTablePageSize } from '../../views/scouting/Scouting';


interface SelectPlayerViewProps {
  onPlayerClick: (player: StringToAnyMap) => void;
  titleKey: string;
  maxTableHeight: string;
  isGoalkeeper?: boolean;
  disablePlayersWithoutEventData?: boolean;

  setIsPlayerSearchActive?: (value: boolean) => void;

  marginTop?: string;
  marginBottom?: string;
}

export const SelectPlayerView: React.FC<SelectPlayerViewProps> = ({
  onPlayerClick,
  titleKey,
  maxTableHeight,
  isGoalkeeper,
  disablePlayersWithoutEventData,
  setIsPlayerSearchActive,
  marginTop,
  marginBottom,
}) => {

  const { currentUser } = useAuthContext();
  const userConfig = useRecoilValue(userConfigState);
  const nameSearchHistory = useRecoilValue(nameSearchHistorySelector);

  const [searchString, setSearchString] = useState<string>('');
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [activeSearchString, setActiveSearchString] = useState('');
  const [shouldShowSearchResult, setShouldShowSearchResult] = useState(false);
  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);

  const [tableData, setTableData] = useState<PlayerOverview[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForPlayers(true, true);
    }
  };


  const setEmptyTableData = () => {
    setTableData([]);
    setCurrentPage(0);
    setTotalHits(0);
  };


  const handleSearchForPlayers = async (isUserInitiated: boolean, isNewSearch: boolean) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    if (searchString !== '') {

      const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;
      const queryOptions: PlayerOverviewsQueryOptions = {
        name: searchString,
        page: nextPageToQuery,
        pageSize: playerSimpleTablePageSize,
      };

      const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(
        queryOptions,
        currentUser,
        nameSearchHistory,
        userConfig?.email,
        userConfig?.club
      );
      setActiveSearchString(searchString);

      if (!result) {
        // todo: handle error?
        setEmptyTableData();
      }
      else if (result.total_hits === 0 || (isNewSearch && result.players.length === 0)) {
        setIsSearchWithoutResult(true);
        setEmptyTableData();
      }
      else {
        setIsSearchWithoutResult(false);
        setTableData(isNewSearch ? result.players : [...tableData, ...result.players]);
        setCurrentPage(result.current_page);
        setTotalHits(result.total_hits);
      }
    }

    setIsLoading(false);
  };


  const resetSearch = () => {
    setDebouncedSearchString('');
    setShouldShowSearchResult(false);
    setEmptyTableData();
    setIsSearchWithoutResult(false);

    if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(false);
    }
  };


  useEffect(() => {
    if (searchString.length === 0) {
      resetSearch();
    }
    else if (setIsPlayerSearchActive) {
      setIsPlayerSearchActive(true);
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (searchString !== debouncedSearchString) {
        setDebouncedSearchString(searchString);
      }
    }, 600);

    debounceFilter();

    clearDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForPlayers(false, true);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString !== '' && searchString === activeSearchString) {
      setShouldShowSearchResult(true);
    }
  }, [searchString, activeSearchString]);


  return (
    <div className='select-player-view-container'>

      <div
        className='select-player-view-title'
        style={{ marginTop: marginTop ?? '2vh', marginBottom: marginBottom ?? '2vh' }}
      >
        {translate(titleKey, userConfig?.language)}
      </div>

      <div className='select-player-view-input-field-container'>
        <InputField
          value={searchString}
          placeholder={translate('searchForName', userConfig?.language)}
          onChange={onChangeSearchField}
          onKeyDown={onKeyDownSearchField}
          resetValue={() => setSearchString('')}
          showNameSearchHistory={true}
          onNameSearchHistoryClick={(name: string) => { setSearchString(name); setDebouncedSearchString(name); }}
          maxHeight={maxTableHeight}
          isWhiteBackgroundWhenExpanded={true}
        />
      </div>

      <div className='select-player-view-result-section'>

        {shouldShowSearchResult && isSearchWithoutResult && (
          <div className=''>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {shouldShowSearchResult && !isSearchWithoutResult && (
          <div className='select-player-view-table'>
            <PlayerSimpleTable
              tableData={tableData}
              onPlayerClick={onPlayerClick}
              tableType={'addPlayerView'}
              maxHeight={maxTableHeight}

              isSearchResultTable={true}
              isLoading={isLoading}
              handleSearchForPlayers={handleSearchForPlayers}
              currentPage={currentPage}
              totalHits={totalHits}

              disablePlayersWithoutEventData={disablePlayersWithoutEventData}
              isGoalkeeper={isGoalkeeper}
            />
          </div>
        )}

      </div>

    </div>
  );
};
