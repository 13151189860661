import '../tables.css';

import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';


import {
  alertTableActionColumnWidth,
  alertTableInfoColumns,
  alertTableInfoColumnsWidth,
  alertTablePadding,
  alertTableStaticDataColumnsWidth,
  getAlertTableActionColumn,
  getAlertTableDataColumns,
} from './alertColumns';

import { PlayerOverview } from '../../../types';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { alertTablePageSize } from '../../../views/scouting/Scouting';
import { getClubSecondaryColor } from '../../../static/clubConfigs';
import { translate } from '../../../../common/language/translations';
import { PlayerInfoCell, PlayerPaginationCell } from '../tableCells';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { getDisplayPositions } from '../../../utils/playerUtils';
import { positionGroupToPositions } from '../../../static/propertyValues';
import { PlayerOverviewWithAlertProperties } from '../../../views/scouting/alerts/Alerts';
import { getRoleColumnsWidth } from '../utils';
import { getNavigationWidth } from '../../../utils/navigationUtils';


interface AlertTableProps {
  data: PlayerOverviewWithAlertProperties[];

  isExcludedPlayersView: boolean;
  excludePlayer: (playerId: number, allAlerts: boolean, monthsExcluded: number) => void,
  undoExcludePlayer: (playerOverview: PlayerOverview, allAlerts: boolean) => void,
  excludeAllPlayers: () => void,
  undoAllExcludedPlayers: () => void,

  currentPage: number;
  totalHits: number;
  handleChangeCurrentPage: (increment: boolean) => void;

  selectedOrderBy: string | undefined;
  selectedRoles: string[];
}


export const AlertTable: React.FC<AlertTableProps> = ({
  data,

  isExcludedPlayersView,
  excludePlayer,
  undoExcludePlayer,
  excludeAllPlayers,
  undoAllExcludedPlayers,

  currentPage,
  totalHits,
  handleChangeCurrentPage,

  selectedOrderBy,
  selectedRoles,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();
  const { openTextModal, openRoleInfoModal, openConfirmModal } = useOpenGlobalModal();
  const { width } = useWindowSize();

  const roleConfigs = useMemo(() => clubSettings?.roleConfigs ?? {}, [clubSettings?.roleConfigs]);
  const dataColumns = useMemo(() => {
    return getAlertTableDataColumns(selectedRoles, roleConfigs);
  }, [selectedRoles, roleConfigs]);


  const actionColumn = useMemo(() => {
    return getAlertTableActionColumn(isExcludedPlayersView);
  }, [isExcludedPlayersView]);


  const handleRowClick = (playerOverview: PlayerOverviewWithAlertProperties) => {
    const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && roleConfigs[selectedRoles[0]]
      ? roleConfigs[selectedRoles[0]].positionGroup
      : undefined;

    setAndTrackSelectedPlayerState({
      id: playerOverview.id,
      fullname: playerOverview.fullname,
      playerOverview: playerOverview,
      initialPositionGroup: initialPositionGroup,
    });
  };


  const tableWidth = width - 24 - getNavigationWidth(userConfig);
  const alertTableStickyColumnWidth = 300;
  const alertTableNonStickyWidth = tableWidth - alertTableStickyColumnWidth;
  const alertTableNonStickyMinWidth = alertTableInfoColumnsWidth
    + alertTableStaticDataColumnsWidth
    + getRoleColumnsWidth(selectedRoles, roleConfigs)
    + alertTableActionColumnWidth
    + alertTablePadding;
  const widthUnit = Math.max(alertTableNonStickyWidth / alertTableNonStickyMinWidth, 1);


  const positionGroup = selectedRoles && selectedRoles.length > 0 && roleConfigs[selectedRoles[0]]
    ? roleConfigs[selectedRoles[0]].positionGroup
    : 'overall';


  return (
    <div
      className='table-container player-table-container'
      style={{ overflowX: widthUnit <= 1 ? 'auto' : 'hidden', fontSize: 12 }}
    >

      {/* Header */}
      <div className='table-header player-table-header player-table-header-background'>

        {/* Player column header */}
        <div
          className='table-sticky-left player-table-sticky-header-cell player-table-header-cell-with-border'
          style={{ width: alertTableStickyColumnWidth }}
        >
          <PlayerPaginationCell
            totalHits={totalHits}
            currentChapter={currentPage - 1}
            hitsPerChapter={alertTablePageSize}
            handleChangeCurrentChapter={handleChangeCurrentPage}
            language={userConfig?.language}
          />
        </div>


        {/* Info column headers */}
        <div className='table-header-group player-table-header-cell-with-border'>
          {translate('info', userConfig?.language)}

          <div className='table-header-group-row'>
            {alertTableInfoColumns.map(column => {

              const isClickable = column.textModalInfoKey !== undefined;
              const onClick = isClickable
                ? () => openTextModal(
                  translate(column.key, userConfig?.language),
                  translate(column.key + 'Info', userConfig?.language),
                  { infoType: 'metric', titleKey: column.key }
                )
                : undefined;

              const isOrderedBy = selectedOrderBy && selectedOrderBy === column.orderByKey;

              return (
                <div
                  key={column.key}
                  className='table-cell'
                  title={isClickable ? translate('showDescription', userConfig?.language) : undefined}

                  style={{
                    width: column.width * widthUnit,
                    color: isOrderedBy ? getClubSecondaryColor(userConfig?.club ?? '') : undefined,
                    fontWeight: isOrderedBy ? 600 : undefined,
                  }}
                >
                  <div
                    className={isClickable ? ' table-header-cell-clickable' : ''}
                    onClick={onClick}
                  >
                    {translate(column.key, userConfig?.language)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>


        {/* Info column headers */}
        <div className='table-header-group player-table-header-cell-with-border'>
          {translate('basedOn<positions>', userConfig?.language) + ' '}
          {positionGroup !== 'overall'
            ? getDisplayPositions(positionGroupToPositions[positionGroup], userConfig?.language)
            : translate('allPositions', userConfig?.language, true)}

          <div className='table-header-group-row'>
            {dataColumns.map(column => {

              const roleId = column.roleId;
              const onClick = roleId !== undefined
                ? () => openRoleInfoModal(roleConfigs[roleId])
                : () => openTextModal(
                  metricToDisplayInfo[column.key].name[userConfig?.language ?? 'en'],
                  metricToDisplayInfo[column.key].description[userConfig?.language ?? 'en'],
                  { infoType: 'metric', titleKey: column.key }
                );

              const isOrderedBy = selectedOrderBy && selectedOrderBy === column.orderByKey;

              return (
                <div
                  key={column.key}
                  className='table-cell'
                  style={{
                    width: column.width * widthUnit,
                    color: isOrderedBy ? getClubSecondaryColor(userConfig?.club ?? '') : undefined,
                    fontWeight: isOrderedBy ? 600 : undefined,
                  }}
                >
                  <div
                    className='table-header-cell-clickable'
                    onClick={onClick}
                  >
                    {column.roleId
                      ? roleConfigs[column.roleId].name
                      : metricToDisplayInfo[column.key].name[userConfig?.language ?? 'en']}
                  </div>
                </div>
              );
            })}
          </div>
        </div>


        {/* Action column header */}
        <div className='table-header-group'>
          <div style={{ paddingRight: alertTablePadding }}>
            {translate(isExcludedPlayersView ? 'undo' : 'removeFromSearch', userConfig?.language)}
          </div>

          <div className='table-header-group-row'>
            <div
              key={actionColumn.key}
              className='table-cell'
              style={{ width: actionColumn.width * widthUnit }}
            >
              <div
                className='table-header-cell-clickable'
                style={{ paddingRight: alertTablePadding }}
                onClick={() => openConfirmModal(
                  isExcludedPlayersView ? undoAllExcludedPlayers : excludeAllPlayers,
                  translate(isExcludedPlayersView ? 'undoAll' : 'removeAll', userConfig?.language),
                  translate(isExcludedPlayersView ? 'undoAllInfo' : 'removeAllInfo', userConfig?.language),
                )}
              >
                {translate(actionColumn.key, userConfig?.language)}?
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Body */}
      <div className='player-table-body'>
        {data.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={'table-row player-table-row' + (rowIndex % 2 === 0 ? ' player-table-row-even' : ' player-table-row-odd')}
              onClick={() => handleRowClick(row)}
            >

              {/* Player column */}
              <div
                className={
                  'table-sticky-left player-table-sticky-cell player-table-body-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' player-table-sticky-cell-even' : ' player-table-sticky-cell-odd')
                }
                style={{ width: alertTableStickyColumnWidth }}
              >
                <PlayerInfoCell
                  playerOverview={row}
                  language={userConfig?.language}
                  maxPlayerNameLength={20}
                />
              </div>

              {/* Info columns */}
              <div className='flex-row player-table-body-cell-with-border'>
                {alertTableInfoColumns.map(column => {
                  return (
                    <div
                      key={column.key}
                      className='table-cell'
                      style={{ width: column.width * widthUnit }}
                    >
                      {column.cell({ row, language: userConfig?.language })}
                    </div>
                  );
                })}
              </div>

              {/* Data columns */}
              <div className='flex-row player-table-body-cell-with-border'>
                {dataColumns.map(column => {
                  return (
                    <div
                      key={column.key}
                      className='table-cell'
                      style={{ width: column.width * widthUnit }}
                    >
                      {column.cell({ row, language: userConfig?.language, positionGroup })}
                    </div>
                  );
                })}
              </div>

              {/* Action column */}
              <div
                key={actionColumn.key}
                className='table-cell'
                style={{ width: actionColumn.width * widthUnit }}
              >
                {actionColumn.cell({ row, language: userConfig?.language, excludePlayer, undoExcludePlayer })}
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
};
