import '../../mobilePlatform.css';
import './mobileScouting.css';

import { useEffect, useReducer } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import SearchIcon from '@mui/icons-material/Search';

import mobileScoutingReducer from './mobileScoutingReducer';
import { ViewContainerBackgroundSvg } from '../../../../assets/svg/ViewContainerBackgroundSvg';
import { PlayerOverviewsQueryOptions } from '../../../types';
import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../../services/server/application/playerOverviews';
import { anyMobileFilterExceptNameAndDefaultOrderBySelected, initialMobileScoutingState } from './mobileScoutingState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { nameSearchHistorySelector } from '../../../recoil/selectors/scoutingConfigSelectors';
import { stringArraysContainSameElements } from '../../../utils/utils';
import { getFiltersUsed } from '../../../utils/scoutingUtils';
import { trackEvent } from '../../../services/server/analytics/trackEvent';
import { MobileScoutingFilters } from './MobileScoutingFilters';
import { translate } from '../../../../common/language/translations';
import { TextButton } from '../../../components/controls/buttons/TextButton';
import { MobileHeader } from '../../mobileComponents/MobileHeader';
import { MobilePlayerTable } from '../../mobileComponents/mobileTables/mobilePlayerTable/MobilePlayerTable';


export const mobilePlayerTablePageSize = 25;
export const mobilePlayerTableChapterSize = 4;

export const filterSectionHeight = 50;
export const filterSectionExpandedHeight = 150;


export const MobileScouting: React.FC = () => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const nameSearchHistory = useRecoilValue(nameSearchHistorySelector);

  const [state, dispatch] = useReducer(
    mobileScoutingReducer,
    initialMobileScoutingState,
  );


  const onKeyDownNameSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && state.nameSearchString !== state.currentNameSearchString) {
      handleSearchButtonPressed(true);
    }
  };


  const handleTrackEvent = (
    isNewSearch: boolean,
    searchQueryOptions: PlayerOverviewsQueryOptions,
    totalHits: number,
  ) => {

    const typeOfSearch = searchQueryOptions.name
      ? anyMobileFilterExceptNameAndDefaultOrderBySelected(state)
        ? 'nameAndFilterSearch'
        : 'nameSearch'
      : 'filterSearch';

    trackEvent(
      'MobilePlayerSearch',
      {
        typeOfSearch: typeOfSearch,
        isNewSearch: isNewSearch,
        filtersUsed: getFiltersUsed(searchQueryOptions),
        totalHits: totalHits,

        name: searchQueryOptions.name,
        minConfidence: searchQueryOptions.minConfidence ? (searchQueryOptions.minConfidence * 100) : undefined,
        maxAge: searchQueryOptions.maxAge,
        primaryPositions: searchQueryOptions.primaryPositions,
        positionGroup: searchQueryOptions.positionGroup,
        leagues: searchQueryOptions.competitions,

        sortBy: searchQueryOptions.sortBy && clubSettings?.roleConfigs[searchQueryOptions.sortBy]
          ? 'role_index'
          : searchQueryOptions.sortBy,
      },
      currentUser,
      'user',
    );
  };


  const handleSearchButtonPressed = async (
    isNewSearch: boolean,
    nextChapter?: number,
    newOverriddenOrderBy?: string | undefined,
    nameSearchString?: string,
  ) => {

    dispatch({ type: 'SET_IS_LOADING' });

    const nameSearchStringToUse = nameSearchString ?? state.nameSearchString;

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * mobilePlayerTableChapterSize) + 1)
        : ((state.currentChapter * mobilePlayerTableChapterSize) + (state.currentModuloPage + 1)));

    const sortBy = newOverriddenOrderBy ??
      (nameSearchStringToUse.length > 0
        ? undefined
        : state.selectedOrderBy.length > 0
          ? state.selectedOrderBy[0]
          : undefined);

    const positionGroupOfSelectedRoles = state.selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[state.selectedRoles[0]]
      ? clubSettings.roleConfigs[state.selectedRoles[0]].positionGroup
      : null;

    const queryOptions: PlayerOverviewsQueryOptions = {
      name: nameSearchStringToUse,
      minConfidence: state.selectedMinConfidence.length > 0 ? (parseInt(state.selectedMinConfidence[0]) / 100) : undefined,
      maxAge: state.selectedMaxAge.length > 0 ? parseInt(state.selectedMaxAge[0]) : undefined,
      primaryPositions: state.selectedPositions.length > 0 ? state.selectedPositions : undefined,
      positionGroup: positionGroupOfSelectedRoles,
      competitions: state.selectedLeagues.length > 0 ? state.selectedLeagues.map(league => parseInt(league)) : undefined,
      sortBy: sortBy,

      page: nextPageToQuery,
      pageSize: mobilePlayerTablePageSize,
    };

    const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(
      queryOptions,
      currentUser,
      nameSearchHistory,
      userConfig?.email,
      userConfig?.club,
    );

    if (!result) {
      // todo: handle error?
      dispatch({ type: 'RESET_STATE' });
      return;
    }

    handleTrackEvent(isNewSearch, queryOptions, result.total_hits);

    dispatch({
      type: 'SET_SEARCH_RESULT',
      payload: {
        result: result,
        isNewSearch: isNewSearch,
        nextChapter: nextChapter,
        newOverriddenOrderBy: newOverriddenOrderBy,
      }
    });
  };


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    dispatch({ type: 'SET_EMPTY_TABLE_DATA' });
    const nextChapter = isIncrement ? state.currentChapter + 1 : state.currentChapter - 1;
    handleSearchButtonPressed(false, nextChapter);
  };


  // when a filter is de-expanded, we check if the selected filters have changed, and if so, trigger a new search
  useEffect(() => {
    // only trigger a new search if the filter is de-expanded and search is active
    const searchIsActive = state.tableData.length > 0 || state.isEmptySearchResult;
    if (!state.currentFilterExpanded && state.previousFilterExpanded && searchIsActive) {

      let shouldTriggerSearch = false;
      let rolesHaveChanged = false;

      switch (state.previousFilterExpanded) {
        case 'positionsAndRoles':
          rolesHaveChanged = !stringArraysContainSameElements(state.currentRoles, state.selectedRoles);
          shouldTriggerSearch = rolesHaveChanged || !stringArraysContainSameElements(state.currentPositions, state.selectedPositions);
          break;

        case 'minConfidence':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMinConfidence, state.selectedMinConfidence);
          break;

        case 'maxAge':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMaxAge, state.selectedMaxAge);
          break;

        case 'leagues':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentLeagues, state.selectedLeagues);
          break;

        case 'orderBy':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentOrderBy, state.selectedOrderBy);
          break;

        // case 'clubs':
        //   shouldTriggerSearch = !stringArraysContainSameElements(state.currentClubs.map(c => String(c.id)), state.selectedClubs.map(c => String(c.id)));
        //   break;

        // case 'form':
        //   shouldTriggerSearch = !stringArraysContainSameElements(state.currentForm, state.selectedForm);
        //   break;

        // case 'playingTime':
        //   shouldTriggerSearch = !stringArraysContainSameElements(state.currentPlayingTime, state.selectedPlayingTime);
        //   break;

        // case 'nationalities':
        //   shouldTriggerSearch = !stringArraysContainSameElements(state.currentNationalities, state.selectedNationalities);
        //   break;

        // case 'contractStatus':
        //   shouldTriggerSearch = !stringArraysContainSameElements(state.currentContractStatus, state.selectedContractStatus)
        //     || state.currentIsContractStatusToggled !== state.isContractStatusToggled;
        //   break;
      }

      let newOverriddenOrderBy: string | undefined = undefined;

      // some changes to the selected roles will automatically set the orderBy
      if (rolesHaveChanged) {

        // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
        // we only want this if there are currently no selected roles - if there are, and the orderBy is not a role, the orderBy was manually set
        if (
          state.currentRoles.length === 0
          && state.selectedRoles.length > 0
          && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skillIndex')
        ) {
          const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
          newOverriddenOrderBy = lastSelectedRole;
        }

        // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
        else if (
          state.selectedOrderBy.length > 0
          && clubSettings?.roleConfigs[state.selectedOrderBy[0]]
          && !state.selectedRoles.includes(state.selectedOrderBy[0])
        ) {
          if (state.selectedRoles.length > 0) {
            newOverriddenOrderBy = state.selectedRoles[0];
          }
          else {
            newOverriddenOrderBy = 'skillIndex';
          }
        }
      }

      if (newOverriddenOrderBy) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [newOverriddenOrderBy] });
      }

      if (shouldTriggerSearch) {
        handleSearchButtonPressed(true, undefined, newOverriddenOrderBy);
      }
    }
  }, [state.currentFilterExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  // if search is not active, some changes to the selected roles will automatically set the orderBy
  useEffect(() => {
    if (state.tableData.length > 0 || state.isEmptySearchResult) return;

    // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
    if (
      state.selectedRoles.length > 0
      && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skillIndex')
    ) {
      const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
      dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [lastSelectedRole] });
    }

    // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
    if (
      state.selectedOrderBy.length > 0
      && clubSettings?.roleConfigs[state.selectedOrderBy[0]]
      && !state.selectedRoles.includes(state.selectedOrderBy[0])
    ) {
      if (state.selectedRoles.length > 0) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [state.selectedRoles[0]] });
      }
      else {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: ['skillIndex'] });
      }
    }
  }, [state.selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (state.nameSearchString.length === 0) {
      dispatch({ type: 'RESET_SEARCH_STATE' });
    }
  }, [state.nameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='full-size-container'>

      <div className='mobile-home-view-background'>
        <ViewContainerBackgroundSvg />
      </div>

      <MobileHeader
        tab={'scouting'}
        isMobileScoutingFilterSectionExpanded={state.isFilterSectionExpanded}
        setIsMobileScoutingFilterSectionExpanded={() => dispatch({ type: 'SET_IS_FILTER_SECTION_EXPANDED', payload: !state.isFilterSectionExpanded })}
        resetMobileScoutingState={() => dispatch({ type: 'RESET_STATE' })}
      />

      <div className='mobile-platform-view-section'>
        <div className='mobile-platform-view-content-container'>

          <MobileScoutingFilters
            state={state}
            dispatch={dispatch}
            isMobileScoutingFilterSectionExpanded={state.isFilterSectionExpanded}
            onKeyDownNameSearchField={onKeyDownNameSearchField}
            onNameSearchHistoryClick={(nameSearchString) => handleSearchButtonPressed(true, undefined, undefined, nameSearchString)}
          />

          <div
            className='mobile-scouting-view-result-container'
            style={{ top: state.isFilterSectionExpanded ? filterSectionExpandedHeight : filterSectionHeight }}
          >
            {state.tableData.length > 0 && (
              <MobilePlayerTable
                data={state.tableData}
                isLoading={state.isLoading}
                handleSearchButtonPressed={handleSearchButtonPressed}
                currentModuloPage={state.currentModuloPage}
                currentChapter={state.currentChapter}
                totalHits={state.totalHits}
                handleChangeCurrentChapter={handleChangeCurrentChapter}
                selectedOrderBy={state.selectedOrderBy.length > 0 ? state.selectedOrderBy[0] : undefined}
                selectedRoles={state.selectedRoles}
              />
            )}

            {state.tableData.length === 0 && state.isEmptySearchResult && !state.isLoading && (
              <div className='scouting-info-title fade-in'>
                {translate('noResult', userConfig?.language)}
              </div>
            )}

            {state.tableData.length === 0 && !state.isEmptySearchResult && !state.isLoading && (
              <TextButton
                onClick={() => handleSearchButtonPressed(true)}
                text={translate('searchForPlayers', userConfig?.language)}
                icon={<SearchIcon style={{ fontSize: 22 }} />}
                isMobile={true}
              />
            )}
          </div>

        </div>
      </div>
    </div>
  );
};
