import './mobile/mobilePlatform.css';
import './platform.css';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userConfigState } from './recoil/atoms/userConfigState';
import { teamsState } from './recoil/atoms/teamsState';
import { squadsState } from './recoil/atoms/squadsState';
import { playersState } from './recoil/atoms/playersState';
import { selectedPlayerState } from './recoil/atoms/selectedPlayerState';
import { clubScopesState } from './recoil/atoms/clubScopesState';
import { clubSettingsState } from './recoil/atoms/clubSettingsState';
import { userSettingsState } from './recoil/atoms/userSettingsState';
import { navigationPropsState } from './recoil/atoms/navigationPropsState';
import { competitionsState } from './recoil/atoms/competitionsState';
import { scoutingConfigState } from './recoil/atoms/scoutingConfigState';
import { playerDataLastRefreshState } from './recoil/atoms/playerDataLastRefreshState';
import { currentReactVersionState } from './recoil/atoms/currentReactVersionState';
import { useRefreshPlayerCareers } from './recoil/hooks/useRefreshPlayerCareers';
import { useRefreshPlayerOverviews } from './recoil/hooks/useRefreshPlayerOverviews';
import { useSyncPlayerData } from './recoil/hooks/useSyncPlayerData';
import { useOpenGlobalModal } from './recoil/hooks/useOpenGlobalModal';
import { trackEvent } from './services/server/analytics/trackEvent';

import { getAllUsersWithSameClub, getUserConfig } from './services/firestore/users';
import { useWindowSize } from '../common/hooks/WindowSize';
import { translate } from '../common/language/translations';

import { StringToStringMap, UserConfig } from './types';
import { userHasMaintenanceAccess, userHasMobileAccess, userHasNoAccess } from './utils/userUtils';
import { Navigation } from './components/controls/navigation/Navigation';
import { SingleTeam } from './views/singleTeam/SingleTeam';
import { TeamOrSquadBuilder } from './views/teamOrSquadBuilder/TeamOrSquadBuilder';
import { OwnTeam } from './views/ownTeam/OwnTeam';
import { getTeamsOrSquads } from './services/firestore/teamsOrSquads';
import { Scouting } from './views/scouting/Scouting';
import { Settings } from './views/settings/Settings';
import { allUserConfigsState } from './recoil/atoms/allUserConfigsState';
import { Archive } from './views/archive/Archive';
import { Home } from './views/home/Home';
import { getClubScopes } from './services/firestore/clubScopes';
import { getPlayers } from './services/firestore/players';
import { Economy } from './views/economy/Economy';
import { Account } from './views/settings/Account';
import { getGlobalSettings } from './services/firestore/global';
import { getCompetitions } from './services/firestore/competitions';
import { getClubSettings } from './services/firestore/clubSettings';
import { getUserSettings } from './services/firestore/userSettings';
import { getScoutingConfig } from './services/firestore/scouting';
import { PlayerView } from './components/playerView/PlayerView';
import { GlobalModals } from './components/modals/globalModals/GlobalModals';
import { Planner } from './views/planner/Planner';

import { MobileNavigation, MobileNavigationTab } from './mobile/mobileComponents/MobileNavigation';
import { MobileScouting } from './mobile/mobileViews/mobileScouting/MobileScouting';
import { MobileHome } from './mobile/mobileViews/MobileHome';
import { MobilePlayers } from './mobile/mobileViews/MobilePlayers';
import { MobileReports } from './mobile/mobileViews/MobileReports';
import { MobileAccount } from './mobile/mobileViews/MobileAccount';
import { MobilePlayerView } from './mobile/mobileComponents/mobilePlayerView/MobilePlayerView';
import { getNavigationWidth } from './utils/navigationUtils';
import { useAuthContext } from '../common/contexts/useAuthContext';


export const Platform = () => {

  const { currentUser, logout } = useAuthContext();

  const { width } = useWindowSize();

  const navigate = useNavigate();

  const [navigationProps, setNavigationProps] = useRecoilState(navigationPropsState);

  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);


  // Users that do not have a userConfig should be signed out once we know the userConfig have been loaded
  const handleSignOut = async () => {
    await logout();
  };


  // Initialize firestore listener for userConfig
  const [userConfig, setUserConfig] = useRecoilState(userConfigState);
  const [userConfigLoadingState, setUserConfigLoadingState] = useState({
    userConfigExists: null as boolean | null,
    userConfigIsLoaded: false,
  });

  const handleSetUserConfig = (userConfig: UserConfig | null) => {
    setUserConfig(userConfig);
    setUserConfigLoadingState({
      userConfigExists: userConfig !== null,
      userConfigIsLoaded: true,
    });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    trackEvent('PlatformInitialized', {}, currentUser, 'system', currentReactVersion);
    const unsubscribe = getUserConfig(currentUser, handleSetUserConfig);
    return () => unsubscribe();
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps


  // Listener logic for user access - if access changes, we must reset the navigation to avoid unauthorized access
  // // devspeed: this could be commented out to speed up development
  useEffect(() => {
    setNavigationProps({ activeTab: 'home' });
  }, [userConfig?.access]); // eslint-disable-line react-hooks/exhaustive-deps


  // Initialize firestore listeners once userConfig is initialized
  const setTeams = useSetRecoilState(teamsState);
  const setSquads = useSetRecoilState(squadsState);
  const setPlayers = useSetRecoilState(playersState);
  const setClubScopes = useSetRecoilState(clubScopesState);
  const setClubSettings = useSetRecoilState(clubSettingsState);
  const setUserSettings = useSetRecoilState(userSettingsState);
  const setAllUsers = useSetRecoilState(allUserConfigsState);
  const setCompetitions = useSetRecoilState(competitionsState);
  const setScoutingConfig = useSetRecoilState(scoutingConfigState);
  useEffect(() => {
    if (!userConfig?.club || !userConfig?.email) return;

    // Firestore listener for teams
    const unsubscribeFromTeams = getTeamsOrSquads(setTeams, false, userConfig.club);

    // Firestore listener for squads
    const unsubscribeFromSquads = getTeamsOrSquads(setSquads, true, userConfig.club);

    // Firestore listener for players
    const unsubscribeFromPlayers = getPlayers(setPlayers, userConfig.club);

    // Firestore listener for clubScopes
    const unsubscribeFromClubScopes = getClubScopes(setClubScopes, userConfig.club);

    // Firestore listener for clubSettings
    const unsubscribeFromClubSettings = getClubSettings(setClubSettings, userConfig.club);

    // Firestore listener for userSettings
    const unsubscribeFromUserSettings = getUserSettings(setUserSettings, userConfig.email, userConfig.club);

    // Firestore listener for all users with the same club
    const unsubscribeFromAllUsers = getAllUsersWithSameClub(setAllUsers, userConfig.club);

    // Firestore listener for competitions
    const unsubscribeFromCompetitions = getCompetitions(setCompetitions);

    // Firestore listener for scoutingConfig
    const unsubscribeFromScoutingConfig = getScoutingConfig(setScoutingConfig, userConfig.email, userConfig.club);

    // Clean up subscriptions when component unmounts
    return () => {
      unsubscribeFromTeams();
      unsubscribeFromSquads();
      unsubscribeFromPlayers();
      unsubscribeFromClubScopes();
      unsubscribeFromClubSettings();
      unsubscribeFromUserSettings();
      unsubscribeFromAllUsers();
      unsubscribeFromCompetitions();
      unsubscribeFromScoutingConfig();
    };
  }, [userConfig?.club, userConfig?.email]); // eslint-disable-line react-hooks/exhaustive-deps


  // The syncPlayerData hook will keep the playerOverviews and playerCareers atoms up to date with the state of the teams and squads
  useSyncPlayerData();

  // We also want to enforce some TTL on the player data fetched from the server
  const refreshPlayerOverviews = useRefreshPlayerOverviews();
  const refreshPlayerCareers = useRefreshPlayerCareers();
  const [playerDataLastRefresh, setPlayerDataLastRefresh] = useRecoilState(playerDataLastRefreshState);
  const tabsThatShouldInitiatePlayerDataRefresh = ['home', 'scouting', 'archive', 'economy', 'settings'];
  useEffect(() => {
    if (tabsThatShouldInitiatePlayerDataRefresh.includes(navigationProps.activeTab)) {
      const currentDate = new Date().toISOString().split('T')[0];

      // current TTL is set to 1 day, where first occurrence of a new tab on a new day will trigger a refresh
      if (playerDataLastRefresh !== null && playerDataLastRefresh !== currentDate) {
        trackEvent('PlayerDataRefreshed', {}, currentUser, 'system', currentReactVersion);
        refreshPlayerOverviews(currentUser);
        refreshPlayerCareers(currentUser);
        setPlayerDataLastRefresh(currentDate);
      }
    }
  }, [navigationProps.activeTab, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps


  // Firestore listener for react version updates
  const [currentReactVersion, setCurrentReactVersion] = useRecoilState(currentReactVersionState);
  const [latestReactVersion, setLatestReactVersion] = useState<string | undefined>(undefined);
  const [versionMessage, setVersionMessage] = useState<StringToStringMap | undefined>(undefined);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState<StringToStringMap | undefined>(undefined);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const { openConfirmModal } = useOpenGlobalModal();

  useEffect(() => {
    const unsubscribe = getGlobalSettings(setLatestReactVersion, setVersionMessage, setIsMaintenance, setMaintenanceMessage);
    return () => unsubscribe();
  }, []);


  const confirmApplyUpdate = () => {
    trackEvent('UpdateAccepted', {}, currentUser, 'user', currentReactVersion);
    setIsUpdateAvailable(false);
    window.location.reload();
  };

  const handleOpenUpdateAvailableModal = () => {
    openConfirmModal(
      confirmApplyUpdate,
      'updateAvailable',
      undefined,
      () => { trackEvent('UpdatePostponed', {}, currentUser, 'user', currentReactVersion); },
      'refresh',
      'postpone',
      versionMessage && versionMessage[userConfig?.language ?? 'en']
        ? versionMessage[userConfig?.language ?? 'en']
        : translate('pleaseRefreshPage', userConfig?.language),
    );
  };


  useEffect(() => {
    if (!latestReactVersion) return;

    if (!currentReactVersion) {
      setCurrentReactVersion(latestReactVersion);
    }

    else if (currentReactVersion !== latestReactVersion) {
      setIsUpdateAvailable(true);
    }
  }, [latestReactVersion]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isUpdateAvailable) {
      handleOpenUpdateAvailableModal();
    }

    // Periodically prompt the user to refresh if an update is waiting
    const intervalId = setInterval(() => {
      if (isUpdateAvailable) {
        handleOpenUpdateAvailableModal();
      }
    }, 900000); // 15 minutes

    return () => clearInterval(intervalId);
  }, [isUpdateAvailable]); // eslint-disable-line react-hooks/exhaustive-deps


  const isMobile = width < 900;
  const [activeMobileTab, setActiveMobileTab] = useState<MobileNavigationTab>('home');
  const handleSetActiveMobileTab = (tab: MobileNavigationTab) => {
    setActiveMobileTab(tab);
    setSelectedPlayer(undefined);
  };

  const maintenanceMessageString = maintenanceMessage?.[userConfig?.language ?? 'en'];
  const maintenanceMessageToDisplay = maintenanceMessageString ? maintenanceMessageString : translate('platformIsUnderMaintenance', userConfig?.language);


  if (!currentUser) {
    navigate('/login');
    return (<div></div>);
  }


  else if (userConfig && userHasNoAccess(userConfig)) {
    return (
      <div className='platform-container platform-container-dark-background'>
        {isMobile
          ? <MobileAccount noAccessView={true} />
          : <Account noAccessView={true} />
        }
      </div>
    );
  }

  else if (userConfig && isMaintenance && !userHasMaintenanceAccess(userConfig)) {
    return (
      <div className='platform-container platform-container-dark-background'>
        {isMobile
          ? <MobileAccount maintenanceMessage={maintenanceMessageToDisplay} />
          : <Account maintenanceMessage={maintenanceMessageToDisplay} />
        }
      </div>
    );
  }


  else if (userConfig && isMobile) {

    if (userHasMobileAccess(userConfig)) {
      return (
        <div key={'mobile'} className='platform-container'>
          <GlobalModals isMobileView={true} />

          <MobileNavigation activeTab={activeMobileTab} setActiveTab={handleSetActiveMobileTab} />

          <div
            className='mobile-platform-view-container'
            style={{
              left: selectedPlayer ? '-50%' : 0,
              zIndex: 1,
            }}
          >
            {activeMobileTab === 'home' && <MobileHome />}
            {activeMobileTab === 'players' && <MobilePlayers />}
            {activeMobileTab === 'scouting' && <MobileScouting />}
            {activeMobileTab === 'reports' && <MobileReports />}
            {activeMobileTab === 'account' && <MobileAccount />}
          </div>

          <div
            className='mobile-platform-view-container'
            style={{
              left: selectedPlayer ? 0 : '100%',
              zIndex: 2,
            }}
          >
            <MobilePlayerView />
          </div>
        </div>
      );
    }

    else {
      return (
        <div key={'mobile'} className='platform-container platform-container-dark-background'>
          <MobileAccount noMobileAccessView={true} />
        </div>
      );
    }
  }


  else if (userConfig) {
    return (
      <div key={'desktop'} className='platform-container'>

        <GlobalModals isMobileView={false} />

        <Navigation />

        <div className='platform-view-container' style={{ left: getNavigationWidth(userConfig) }}>

          {/* -------------------------- */}

          <div className={'platform-view-section player-view-container' + (!selectedPlayer ? ' player-view-container-inactive' : '')}>
            {selectedPlayer && (
              <PlayerView />
            )}
          </div>

          {/* -------------------------- */}

          {navigationProps.activeTab === 'home' && <Home />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'masterTeam' && <SingleTeam teamId={'masterTeam'} />}

          {navigationProps.activeTab === 'shadowTeam' && <SingleTeam teamId={'shadowTeam'} />}

          {navigationProps.activeTab === 'scoutTeams' && <TeamOrSquadBuilder isSquad={false} />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'scouting' && <Scouting />}

          {navigationProps.activeTab === 'archive' && <Archive />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'ownTeam' && <OwnTeam />}

          {navigationProps.activeTab === 'squadBuilder' && <TeamOrSquadBuilder isSquad={true} />}

          {navigationProps.activeTab === 'planner' && <Planner />}

          {navigationProps.activeTab === 'economy' && <Economy />}

          {/* -------------------------- */}

          {navigationProps.activeTab === 'settings' && <Settings />}

          {/* -------------------------- */}

        </div>

      </div>
    );
  }


  else if (userConfigLoadingState.userConfigIsLoaded && userConfigLoadingState.userConfigExists === false) {
    handleSignOut();
    return (<div></div>);
  }


  else {
    return (<div></div>);
  }
};
