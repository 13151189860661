

import {
  goalkeeperSeasonTableMetricGroups,
  metricToDisplayInfo,
  outfieldSeasonTableMetricGroups,
  skillcornerStats
} from '../../../static/playerMetrics';

import { JSX } from 'react';
import { PositionGroupClubIteration, RoleConfigMap, StringToAnyMap } from '../../../types';
import { dateStringToLocaleFormat } from '../../../utils/dateUtils';
import { IterationMetricCell } from '../tableCells';
import { getMetricColumnWidth } from '../utils';


interface PlayerSeasonTableColumnCellProps {
  row: PositionGroupClubIteration;
  language?: string;
  positionGroup?: string;
}


interface PlayerSeasonTableColumn {
  key: string;
  width: number;
  roleId?: string;
  metricName?: string;
  cell: (props: PlayerSeasonTableColumnCellProps) => JSX.Element;
}


export const playerSeasonTableInfoColumns: PlayerSeasonTableColumn[] = [
  {
    key: 'age',
    width: 90,
    cell: ({ row }: PlayerSeasonTableColumnCellProps) => (
      <div className='table-cell'>
        {row.age ?? '-'}
      </div>
    ),
  },
  {
    key: 'contract',
    width: 120,
    cell: ({ row }: PlayerSeasonTableColumnCellProps) => (
      <div className='table-cell'>
        {dateStringToLocaleFormat(row.contract_expiration)}
      </div>
    ),
  },
];


export const getPlayerSeasonTableDataColumnGroups = (
  selectedRoles: string[] | undefined,
  roleConfigs: RoleConfigMap,
  isGoalkeeperSelected: boolean,
  hasSkillcorner: boolean,
  seasonStatsToggles: StringToAnyMap,
  language: string,
  isColorBlind: boolean,
): { [key: string]: PlayerSeasonTableColumn[] } => {

  const dataColumnGroups: { [key: string]: PlayerSeasonTableColumn[] } = {};

  const ratingMetricGroup = { 'ratings': ['skill_rating', ...(selectedRoles ?? [])] };
  const normalMetricGroups = isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const skillCornerMetricGroup = hasSkillcorner && !isGoalkeeperSelected ? { 'skillcorner': skillcornerStats } : {};
  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups, ...skillCornerMetricGroup };

  Object.entries(metricGroups).forEach(([metricGroupKey, metrics]: [string, string[]]) => {

    dataColumnGroups[metricGroupKey] = metrics.map(metric => {

      const roleConfig = roleConfigs[metric];

      const metricName = roleConfig !== undefined
        ? roleConfigs[metric].name
        : metricToDisplayInfo[metric]?.name[language];

      return {
        key: metric,
        width: getMetricColumnWidth(metricName),
        roleId: roleConfig ? metric : undefined,
        metricName: metricName,
        cell: ({ row }: PlayerSeasonTableColumnCellProps) => (
          <IterationMetricCell
            positionGroupClubIteration={row}
            property={metric}
            metricGroupKey={metricGroupKey}
            metricGroupIsToggled={seasonStatsToggles[metricGroupKey]}
            roleConfig={roleConfig}
            isColorBlind={isColorBlind}
          />
        ),
      };
    });

  });

  return dataColumnGroups;
};
