import '../../platform.css';
import './economy.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { playerCareersState } from '../../recoil/atoms/playerCareersState';
import { useGetAndAddPlayerOverview } from '../../recoil/hooks/useGetAndAddPlayerOverview';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import AddIcon from '@mui/icons-material/Add';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
// import ContactPageIcon from '@mui/icons-material/ContactPage';
// import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';

import {
  ClubMap,
  PlayerCareers,
  PlayerClause,
  PlayerId,
  PlayerOverview,
} from '../../types';

import { translate } from '../../../common/language/translations';
import { ViewContainerBackgroundSvg } from '../../../assets/svg/ViewContainerBackgroundSvg';
import { getAllClauses } from '../../services/firestore/clauses';
import { Clause } from '../../components/documents/clause/Clause';
import { getClubs } from '../../services/server/application/clubs';
import { getPlayerCareers } from '../../services/server/application/playerCareers';
import { trackEvent } from '../../services/server/analytics/trackEvent';
import { TextButton } from '../../components/controls/buttons/TextButton';
import { addClauseData } from '../../utils/clauseUtils';
import { ExistingClauses } from '../../components/documents/existingDocuments/ExistingClauses';
import { FixedTabs } from '../../components/controls/navigation/FixedTabs';


export const Economy = () => {

  const { currentUser } = useAuthContext();

  const navigationProps = useRecoilValue(navigationPropsState);
  const { openConfirmModal } = useOpenGlobalModal();

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const getAndAddPlayerOverview = useGetAndAddPlayerOverview();

  const playerCareers = useRecoilValue(playerCareersState);
  const [playerCareersOfAllClauses, setPlayerCareersOfAllClauses] = useState<PlayerCareers | undefined>(undefined);

  const [allClubs, setAllClubs] = useState<ClubMap>({});

  const [soldPlayers, setSoldPlayers] = useState<PlayerClause[]>([]);
  const [boughtPlayers, setBoughtPlayers] = useState<PlayerClause[]>([]);
  const [isClausesInitialized, setIsClausesInitialized] = useState(false);

  const [existingClauseSelected, setExistingClauseSelected] = useState<PlayerClause | undefined>(undefined);
  const [newClauseSelected, setNewClauseSelected] = useState(navigationProps.selectedPlayerId !== undefined);

  const [clauseHasUnsavedChanges, setClauseHasUnsavedChanges] = useState(false);

  const [selectedPlayerOverview, setSelectedPlayerOverview] = useState<PlayerOverview | undefined>(undefined);

  const [isClubsExpanded, setIsClubsExpanded] = useState(false);
  const [isCurrencyExpanded, setIsCurrencyExpanded] = useState(false);

  const removeExpansions = () => {
    setIsClubsExpanded(false);
    setIsCurrencyExpanded(false);
  };

  const initialActiveTab = navigationProps?.activeSubTab ?? 0;
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const tabOptions = [
    // 'contracts',
    'soldPlayers',
    'boughtPlayers',
    // 'budget' / 'payments',
  ];

  const tabIcons = [
    // <ContactPageIcon key={'contact-icon'} style={{ fontSize: 22 }} />,
    <GroupRemoveIcon key={'sold-player-icon'} style={{ fontSize: 22 }} />,
    <GroupAddIcon key={'bought-player-icon'} style={{ fontSize: 22 }} />,
    // <SignalCellularAltIcon key={'signal-icon'} style={{ fontSize: 22, marginTop: -1 }} />,
  ];


  const confirmExitClause = (action: () => void) => {
    action();
    setClauseHasUnsavedChanges(false);
  };


  const confirmCloseClause = () => {
    setExistingClauseSelected(undefined);
    setNewClauseSelected(false);
    setSelectedPlayerOverview(undefined);
    setClauseHasUnsavedChanges(false);
    removeExpansions();
  };


  const handleCloseIconClick = () => {
    if (clauseHasUnsavedChanges) {
      openConfirmModal(
        () => confirmExitClause(() => confirmCloseClause()),
        'exitClause?',
        existingClauseSelected ? 'changesWillNotBeSaved' : 'clauseWillNotBeSaved'
      );
    }
    else {
      confirmCloseClause();
    }
  };


  const confirmSetActiveTab = (tabIndex: number, updateTabLineCallback?: (tabIndex: number) => void) => {
    setActiveTab(tabIndex);
    if (updateTabLineCallback) {
      updateTabLineCallback(tabIndex);
    }
    confirmCloseClause();
  };


  const handleSetActiveTab = (tabIndex: number, updateTabLineCallback?: (tabIndex: number) => void) => {
    if (tabIndex !== activeTab) {
      if (clauseHasUnsavedChanges) {
        openConfirmModal(
          () => confirmExitClause(() => confirmSetActiveTab(tabIndex, updateTabLineCallback)),
          'exitClause?',
          existingClauseSelected ? 'changesWillNotBeSaved' : 'clauseWillNotBeSaved'
        );
      }
      else {
        confirmSetActiveTab(tabIndex, updateTabLineCallback);
      }
    }
  };


  const setNewExistingClauseSelected = async (clause: PlayerClause | undefined) => {
    setExistingClauseSelected(clause);
    setNewClauseSelected(false);

    if (clause) {
      await handleSetSelectedPlayerOverview(clause.playerId);
    }
  };


  const handleExistingClauseClicked = (clause: PlayerClause | undefined) => {
    if (clauseHasUnsavedChanges) {
      openConfirmModal(
        () => confirmExitClause(async () => await setNewExistingClauseSelected(clause)),
        'exitClause?',
        existingClauseSelected ? 'changesWillNotBeSaved' : 'clauseWillNotBeSaved'
      );
    }
    else {
      setNewExistingClauseSelected(clause);
    }
  };


  const handleSetSelectedPlayerOverview = async (playerId: PlayerId) => {

    // the economy view only considers players with data, but this might change in the future
    const numberId = Number(playerId);
    if (isNaN(numberId)) {
      trackEvent('UnexpectedBehavior', { file: 'Economy', behavior: 'Clause for player without data: ' + playerId }, currentUser, 'system');
      return;
    }

    if (playerOverviews[numberId]) {
      setSelectedPlayerOverview(playerOverviews[numberId]);
    }

    else {
      setSelectedPlayerOverview(undefined);
      const playerOverview = await getAndAddPlayerOverview(numberId, currentUser);
      setSelectedPlayerOverview(playerOverview);
    }
  };


  const addOrUpdateClause = async (clause: PlayerClause, isNewClause: boolean, isSoldPlayer: boolean) => {
    const clauses = isSoldPlayer ? soldPlayers : boughtPlayers;
    const setClauses = isSoldPlayer ? setSoldPlayers : setBoughtPlayers;

    const numberId = Number(clause.playerId);
    let playerCareerOfClause = playerCareersOfAllClauses ? playerCareersOfAllClauses[numberId] : undefined;

    // fetch (if needed) playerCareers and add to playerCareersOfAllClauses
    if (!playerCareerOfClause && !isNaN(numberId)) {

      if (playerCareers[numberId]) {
        playerCareerOfClause = playerCareers[numberId];
      }

      else {
        const fetchedPlayerCareers: PlayerCareers | undefined = await getPlayerCareers([numberId], currentUser);
        if (fetchedPlayerCareers) {
          playerCareerOfClause = fetchedPlayerCareers[numberId];
        }
      }

      if (playerCareerOfClause) {
        setPlayerCareersOfAllClauses({ ...playerCareersOfAllClauses, [numberId]: playerCareerOfClause });
      }
    }

    // add or re-calculate player data for clause
    if (playerCareerOfClause) {
      const likelyNextClubId: number | undefined = addClauseData(clause, playerCareerOfClause, allClubs);
      if (likelyNextClubId !== undefined && !(likelyNextClubId in allClubs)) {
        const fetchedClubs = await getClubs([likelyNextClubId], currentUser);
        if (fetchedClubs) {
          setAllClubs({ ...allClubs, ...fetchedClubs });
        }
      }
    }

    if (isNewClause) {
      setClauses([clause, ...clauses]);
    }
    else {
      const updatedClauses = clauses.map((existingClause: PlayerClause) => {
        if (existingClause.id === clause.id) {
          return clause;
        }
        return existingClause;
      });
      setClauses(updatedClauses);
    }

    setExistingClauseSelected(clause);
  };


  useEffect(() => {

    // (1) Load all clauses from firestore
    // (2) Load playerCareers for all players with a clause
    // (3) Load all clubs included in the clauses
    // (4) Calculate clausePlayerData for each clause and append to the clause (and add potentially existing nextClub to allClubs)
    // When clauses are updated or added, (2) and (4) should be done only for the new or updated clause by the addOrUpdateClause function,
    // while (4) is maintained continuously whenever new clubs are selected
    const fetchAndProcessClauses = async () => {
      if (userConfig?.club && playerCareers && clubSettings?.clubId) {

        // (1)
        const clauses: PlayerClause[] = await getAllClauses(userConfig.club, currentUser);

        // (2)
        let playerCareersOfPlayersWithClauses: PlayerCareers = {};
        const playerIdsToFetch = new Set<number>();

        clauses.forEach(clause => {
          const numberId = Number(clause.playerId);
          if (!isNaN(numberId)) {
            if (playerCareers[numberId]) {
              playerCareersOfPlayersWithClauses[numberId] = playerCareers[numberId];
            } else {
              playerIdsToFetch.add(numberId);
            }
          }
        });

        const playerIdsToFetchArray = Array.from(playerIdsToFetch);
        if (playerIdsToFetchArray.length > 0) {
          const fetchedPlayerCareers: PlayerCareers | undefined = await getPlayerCareers(playerIdsToFetchArray, currentUser);
          if (fetchedPlayerCareers) {
            playerCareersOfPlayersWithClauses = { ...playerCareersOfPlayersWithClauses, ...fetchedPlayerCareers };
          }
        }

        // (3)
        const allClubIds = new Set<number>();
        allClubIds.add(clubSettings.clubId);

        clauses.forEach(clause => {
          allClubIds.add(clause.sellingClubId);
          allClubIds.add(clause.buyingClubId);
          if (clause.nextClubId !== undefined) allClubIds.add(clause.nextClubId);
          if (clause.incorrectNextClubIds) clause.incorrectNextClubIds.forEach(clubId => allClubIds.add(clubId));
          if (clause.subClauses) {
            clause.subClauses.forEach(subClause => {
              allClubIds.add(subClause.sellingClubId);
              allClubIds.add(subClause.buyingClubId);
              if (subClause.nextClubId !== undefined) allClubIds.add(subClause.nextClubId);
              if (subClause.incorrectNextClubIds) subClause.incorrectNextClubIds.forEach(clubId => allClubIds.add(clubId));
            });
          }
        });

        const allClubIdsArray = Array.from(allClubIds);
        let clubs: ClubMap = {};
        if (allClubIdsArray.length > 0) {
          const fetchedClubs = await getClubs(allClubIdsArray, currentUser);
          if (fetchedClubs) {
            clubs = fetchedClubs;
          }
        }

        // (4)
        const soldPlayerClauses: PlayerClause[] = [];
        const boughtPlayerClauses: PlayerClause[] = [];
        const newClubIdsToFetch = new Set<number>();

        clauses.forEach(clause => {
          const likelyNextClubId: number | undefined = addClauseData(clause, playerCareersOfPlayersWithClauses[Number(clause.playerId)], clubs);
          if (likelyNextClubId !== undefined) {
            newClubIdsToFetch.add(likelyNextClubId);
          }
          if (clause.isSoldPlayer) soldPlayerClauses.push(clause);
          else boughtPlayerClauses.push(clause);
        });

        const newClubIdsToFetchArray = Array.from(newClubIdsToFetch);
        if (newClubIdsToFetchArray.length > 0) {
          const fetchedClubs = await getClubs(newClubIdsToFetchArray, currentUser);
          if (fetchedClubs) {
            clubs = { ...clubs, ...fetchedClubs };
          }
        }

        // sort clauses first by showNotification, then by transfer date
        const getClauseSortDistance = (a: PlayerClause, b: PlayerClause) => {
          if (a.showNotification && !b.showNotification) return -1;
          if (!a.showNotification && b.showNotification) return 1;

          return b.transferDate.localeCompare(a.transferDate);
        };

        soldPlayerClauses.sort(getClauseSortDistance);
        boughtPlayerClauses.sort(getClauseSortDistance);

        setSoldPlayers(soldPlayerClauses);
        setBoughtPlayers(boughtPlayerClauses);
        setAllClubs(clubs);

        setPlayerCareersOfAllClauses(playerCareersOfPlayersWithClauses);
        setIsClausesInitialized(true);
      }
    };

    fetchAndProcessClauses();
  }, [currentUser, userConfig?.club, playerCareers, clubSettings?.clubId]);


  useEffect(() => {
    if (navigationProps.selectedPlayerId !== undefined) {
      handleSetSelectedPlayerOverview(navigationProps.selectedPlayerId);
      setNewClauseSelected(true);
    }
  }, [navigationProps]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='platform-view-section'>

      <div className='full-size'>
        <ViewContainerBackgroundSvg />
      </div>

      <FixedTabs
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        parentTab={'economy'}
        initialActiveTab={initialActiveTab}
        tabIcons={tabIcons}
        useUpdateTabLineCallback={true}
      />

      <div className='platform-view-content-container-with-tabs'>

        {(isClubsExpanded || isCurrencyExpanded) && <div className='filter-section-empty-background' onClick={() => removeExpansions()} />}

        <div className='economy-existing-clauses-container fade-in'>
          <ExistingClauses
            isSoldPlayer={activeTab === 0}
            clauses={activeTab === 0 ? soldPlayers : boughtPlayers}
            allClubs={allClubs}
            existingClauseSelected={existingClauseSelected}
            handleExistingClauseClicked={handleExistingClauseClicked}
            isClausesInitialized={isClausesInitialized}
          />
        </div>

        <div className='economy-clause-container'>
          {(newClauseSelected || existingClauseSelected) && clubSettings?.clubId && (
            <Clause
              isSoldPlayer={activeTab === 0}
              existingClauseSelected={existingClauseSelected}
              setExistingClauseSelected={setExistingClauseSelected}

              handleCloseIconClick={handleCloseIconClick}
              addOrUpdateClause={addOrUpdateClause}

              selectedPlayerOverview={selectedPlayerOverview}
              setSelectedPlayerOverview={setSelectedPlayerOverview}
              allClubs={allClubs}
              setAllClubs={setAllClubs}
              userClubId={clubSettings.clubId}

              isClubsExpanded={isClubsExpanded}
              setIsClubsExpanded={setIsClubsExpanded}
              isCurrencyExpanded={isCurrencyExpanded}
              setIsCurrencyExpanded={setIsCurrencyExpanded}

              clauseHasUnsavedChanges={clauseHasUnsavedChanges}
              setClauseHasUnsavedChanges={setClauseHasUnsavedChanges}
            />
          )}

          {!existingClauseSelected && !newClauseSelected && (
            <TextButton
              onClick={() => setNewClauseSelected(true)}
              text={translate('addNewClause', userConfig?.language)}
              icon={<AddIcon style={{ fontSize: 22 }} />}
              buttonType={'transparent'}
              isRound={true}
              style={{ height: 36 }}
            />
          )}
        </div>
      </div>

    </div>
  );
};
