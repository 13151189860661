import '../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseState, SubClauseState } from '../../documents/clause/clauseState';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { TextButton } from '../../controls/buttons/TextButton';
import { IconButton } from '../../controls/buttons/IconButton';
import { DateInputField } from '../../controls/input/DateInputField';
import { InputField } from '../../controls/input/InputField';
import { getAdjustedDate } from '../../../utils/dateUtils';


interface ResolveSellOnConditionModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}

export const ResolveSellOnConditionModal: React.FC<ResolveSellOnConditionModalProps> = ({
  closeModal,
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [paymentDate, setPaymentDate] = useState<Date | null>(null);

  const [messageKey, setMessageKey] = useState<string | undefined>();

  const stateAccessor: ClauseState | SubClauseState = state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  // the amount is newTransferFee - yourTransferFee (if isNet) * sellOnPercentage / 100
  const [newTransferFee, setNewTransferFee] = useState('');
  const [yourTransferFee, setYourTransferFee] = useState(state.additionalClauseState.activeSubClauseTab !== undefined ? '' : state.totalTransferFee.toString());
  const [amount, setAmount] = useState('');


  const onChangeInputField = (event: React.ChangeEvent<HTMLInputElement>, feeType: 'newTransferFee' | 'yourTransferFee' | 'amount') => {
    const newFee = event.target.value.replaceAll('.', '');
    const newFeeNumber = Number(newFee);

    if (isNaN(newFeeNumber) || newFeeNumber < 0) {
      setMessageKey('invalidAmount');
      return;
    }
    setMessageKey(undefined);

    if (feeType === 'newTransferFee') {
      if (stateAccessor.sellOnCondition?.isNet) {
        const yourTransferFeeNumber = yourTransferFee ? Number(yourTransferFee) : 0;
        const sellOnPercentage = stateAccessor.sellOnCondition?.sellOnPercentage ?? 0;
        const amount = Math.round((newFeeNumber - yourTransferFeeNumber) * (sellOnPercentage / 100));
        const nonNegativeAmount = amount < 0 ? 0 : amount;
        setAmount(nonNegativeAmount.toString());
      }
      else {
        const amount = Math.round(newFeeNumber * (stateAccessor.sellOnCondition?.sellOnPercentage ?? 0) / 100);
        const nonNegativeAmount = amount < 0 ? 0 : amount;
        setAmount(nonNegativeAmount.toString());
      }
      setNewTransferFee(newFee);
    }

    else if (feeType === 'yourTransferFee') {
      if (newTransferFee && stateAccessor.sellOnCondition?.isNet) {
        const newTransferFeeNumber = Number(newTransferFee);
        const sellOnPercentage = stateAccessor.sellOnCondition?.sellOnPercentage ?? 0;
        const amount = Math.round((newTransferFeeNumber - newFeeNumber) * (sellOnPercentage / 100));
        const nonNegativeAmount = amount < 0 ? 0 : amount;
        setAmount(nonNegativeAmount.toString());
      }
      setYourTransferFee(newFee);
    }

    else if (feeType === 'amount') {
      if (!state.sellOnCondition?.isNet) {
        const newTransferFee = Math.round(newFeeNumber / (stateAccessor.sellOnCondition?.sellOnPercentage ?? 0) * 100);
        const nonNegativeNewTransferFee = newTransferFee < 0 ? 0 : newTransferFee;
        setNewTransferFee(nonNegativeNewTransferFee.toString());
      }
      else {
        const yourTransferFeeNumber = yourTransferFee ? Number(yourTransferFee) : 0;
        const newTransferFee = Math.round(newFeeNumber / (stateAccessor.sellOnCondition?.sellOnPercentage ?? 0) * 100) + yourTransferFeeNumber;
        const nonNegativeNewTransferFee = newTransferFee < 0 ? 0 : newTransferFee;
        setNewTransferFee(nonNegativeNewTransferFee.toString());
      }
      setAmount(newFee);
    }
  };


  const handleSetPaymentDate = (date: Date | null) => {
    if (!date) {
      setPaymentDate(null);
      return;
    }
    const adjustedDate = getAdjustedDate(date);
    setPaymentDate(adjustedDate);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleResolveCondition();
    }
  };


  const handleResolveCondition = () => {
    const amountNumber = Number(amount.replaceAll('.', ''));

    if (isNaN(amountNumber) || amountNumber < 0) {
      setMessageKey('invalidAmount');
      return;
    }

    dispatch({ type: 'RESOLVE_SELL_ON_CONDITION', payload: { amount: amountNumber, date: paymentDate } });
    closeModal();
  };


  const getYourPaymentDescription = () => {
    if (!userConfig || !state.subClauses || state.additionalClauseState.activeSubClauseTab === undefined) return;

    const totalPercentage = (stateAccessor as SubClauseState).totalSellOnPercentage;

    let description = translate('youWillReceive', userConfig.language);
    description += ' ' + totalPercentage + '% ';
    description += translate('ofTheFinalAmount', userConfig?.language, true);

    return description;
  };


  return (
    <div
      className='modal-root-container'
      style={{ width: stateAccessor.sellOnCondition?.isNet ? 600 : 500, height: state.additionalClauseState.activeSubClauseTab !== undefined ? 550 : 510 }}
    >

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('resolveCondition', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      {stateAccessor.sellOnCondition && userConfig && (
        <div className='modal-content-container'>

          <div className='modal-content-section' style={{ minHeight: 140 }}>
            <div className='resolve-condition-modal-info-row'>
              {translate('sellOnCondition', userConfig.language)}
              : {stateAccessor.sellOnCondition.sellOnPercentage}%
              {' (' + translate(stateAccessor.sellOnCondition.isNet ? 'net' : 'gross', userConfig?.language, true) + ') '}
            </div>

            {state.additionalClauseState.activeSubClauseTab !== undefined && (
              <div className='resolve-condition-modal-info-row' style={{ marginTop: 10, fontSize: 14, color: '#ffffffcc' }}>
                {getYourPaymentDescription()}
              </div>
            )}

            <div className='flex-row' style={{ marginTop: 15 }}>
              <DateInputField
                value={paymentDate}
                placeholder={translate('selectDate', userConfig?.language) + '?'}
                onChange={handleSetPaymentDate}
                onKeyDown={onKeyDownInputField}
                minDate='today-10y'
                maxDate='today+10y'
              />
            </div>
          </div>


          <div className='modal-content-section' style={{ minHeight: 80 }}>
            {stateAccessor.sellOnCondition.isNet && (
              <div className='flex-row' style={{ width: 540 }}>
                <div style={{ fontSize: 20, marginTop: 29 }}>
                  {'('}
                </div>

                <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                  <div style={{ marginBottom: 12, fontSize: 13 }}>
                    {translate('newTransferFee', userConfig.language)}
                  </div>
                  <InputField
                    value={getDisplayEconomicValue(newTransferFee)}
                    placeholder={translate('amount', userConfig.language)}
                    onChange={(event) => onChangeInputField(event, 'newTransferFee')}
                    onKeyDown={onKeyDownInputField}
                    isSquare={true}
                    height={23}
                    width={130}
                  />
                </div>

                <div style={{ fontSize: 20, marginTop: 29 }}>
                  -
                </div>

                <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                  <div style={{ marginBottom: 12, fontSize: 13 }}>
                    {translate('yourTransferFee', userConfig.language)}
                  </div>
                  <InputField
                    value={getDisplayEconomicValue(yourTransferFee)}
                    placeholder={translate('amount', userConfig.language) + '?'}
                    onChange={(event) => onChangeInputField(event, 'yourTransferFee')}
                    onKeyDown={onKeyDownInputField}
                    isSquare={true}
                    height={23}
                    width={130}
                  />
                </div>

                <div style={{ fontSize: 20, marginTop: 29 }}>
                  {')'}
                </div>

                <div style={{ fontSize: 18, marginTop: 35, marginLeft: 10, marginRight: 10 }}>
                  *
                </div>

                <div style={{ fontSize: 14, marginTop: 34, marginRight: 12 }}>
                  {(stateAccessor.sellOnCondition?.sellOnPercentage ?? 0) / 100}
                </div>

                <div style={{ fontSize: 18, marginTop: 31, marginRight: 4 }}>
                  {'='}
                </div>

                <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                  <div style={{ marginBottom: 12, fontSize: 13 }}>
                    {translate('amount', userConfig.language)}
                  </div>
                  <InputField
                    value={getDisplayEconomicValue(amount)}
                    placeholder={translate('amount', userConfig.language)}
                    onChange={(event) => onChangeInputField(event, 'amount')}
                    onKeyDown={onKeyDownInputField}
                    isSquare={true}
                    height={23}
                    width={130}
                  />
                </div>
              </div>
            )}

            {!stateAccessor.sellOnCondition.isNet && (
              <div className='flex-row' style={{ width: 380 }}>
                <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                  <div style={{ marginBottom: 12, fontSize: 13 }}>
                    {translate('transferFee', userConfig.language)}
                  </div>
                  <InputField
                    value={getDisplayEconomicValue(newTransferFee)}
                    placeholder={translate('amount', userConfig.language)}
                    onChange={(event) => onChangeInputField(event, 'newTransferFee')}
                    onKeyDown={onKeyDownInputField}
                    isSquare={true}
                    height={23}
                    width={130}
                  />
                </div>

                <div style={{ fontSize: 18, marginTop: 35, marginLeft: 8, marginRight: 12 }}>
                  *
                </div>

                <div style={{ fontSize: 14, marginTop: 34, marginRight: 12 }}>
                  {(stateAccessor.sellOnCondition?.sellOnPercentage ?? 0) / 100}
                </div>

                <div style={{ fontSize: 18, marginTop: 31, marginRight: 4 }}>
                  {'='}
                </div>

                <div className='margin-auto flex-column' style={{ alignItems: 'center' }}>
                  <div style={{ marginBottom: 12, fontSize: 13 }}>
                    {translate('amount', userConfig.language)}
                  </div>
                  <InputField
                    value={getDisplayEconomicValue(amount)}
                    placeholder={translate('amount', userConfig.language)}
                    onChange={(event) => onChangeInputField(event, 'amount')}
                    onKeyDown={onKeyDownInputField}
                    isSquare={true}
                    height={23}
                    width={130}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='modal-content-section' style={{ paddingTop: 30, height: 110, minHeight: 110 }}>
            {messageKey && (
              <div className='condition-modal-message' style={{ top: 10 }}>
                {translate(messageKey, userConfig.language)}
              </div>
            )}

            <TextButton
              onClick={handleResolveCondition}
              text={translate('resolveCondition', userConfig?.language)}
              icon={<CheckIcon style={{ fontSize: 22 }} />}
            />
          </div>
        </div>
      )}
    </div>
  );
};
