import './playerView.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerCareersState } from '../../recoil/atoms/playerCareersState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';

import { translate } from '../../../common/language/translations';
import { Club, StringToNumberMap } from '../../types';
import { getSeasonStartDate } from '../../utils/clauseUtils';
import { CareerTable } from '../tables/careerTable/CareerTable';


export interface CareerIterationEntry {
  // iteration data
  club: Club;
  primary_positions: string[];
  secondary_positions?: string[];
  event_data_exist?: boolean;

  // basic stats
  minutes_played: number;
  appearances: number;
  games_started: number;
  n_goals: number;
  n_assists: number;

  // for club iterations
  season?: string;
  competition_id?: number;
  plot_date?: string;

  // for national iterations
  country_code?: string;
}

interface IntermediateNationalTeamEntry extends CareerIterationEntry {
  totalValues: StringToNumberMap;
}


export const PlayerViewCareer: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const competitions = useRecoilValue(competitionsState);
  const userSettings = useRecoilValue(userSettingsState);

  const playerCareers = useRecoilValue(playerCareersState);
  const [playerClubIterationEntryArray, setPlayerClubIterationEntryArray] = useState<CareerIterationEntry[]>([]);
  const [playerNationalIterationEntryArray, setPlayerNationalIterationEntryArray] = useState<CareerIterationEntry[]>([]);
  const [careerArraysInitialized, setCareerArraysInitialized] = useState(false);


  useEffect(() => {
    if (playerCareers && selectedPlayer && selectedPlayer.id in playerCareers && userSettings) {

      const playerCareer = playerCareers[Number(selectedPlayer.id)];
      const clubArray: CareerIterationEntry[] = [];
      let nationalArray: CareerIterationEntry[] = [];

      Object.keys(playerCareer).forEach((clubIterationId) => {

        const clubIteration = playerCareer[clubIterationId];

        const iterationEntry: CareerIterationEntry = {
          club: clubIteration.club,
          primary_positions: clubIteration.primary_positions,
          secondary_positions: clubIteration.secondary_positions,
          event_data_exist: clubIteration.event_data_exist,

          minutes_played: clubIteration.basic_stats.minutes_played,
          appearances: clubIteration.basic_stats.appearances,
          games_started: clubIteration.basic_stats.games_started,
          n_goals: clubIteration.basic_stats.n_goals,
          n_assists: clubIteration.basic_stats.n_assists,
        };

        // national teams will be sorted based on the team (U17, U19, U21, A)
        if (clubIteration.club?.is_national_team) {
          iterationEntry['country_code'] = clubIteration.club?.country_code;
          nationalArray.push(iterationEntry);
        }

        else {
          const isCup = competitions && clubIteration.competition_id in competitions && competitions[clubIteration.competition_id].type === 'Cup';
          if (isCup && userSettings.seasonStatsToggles?.hideCareerCups) {
            return null;
          }

          iterationEntry['season'] = clubIteration.season;
          iterationEntry['competition_id'] = clubIteration.competition_id;

          const countryCode = (competitions && clubIteration.competition_id in competitions)
            ? competitions[clubIteration.competition_id]?.countryCode
            : undefined;

          iterationEntry['country_code'] = countryCode;

          // if plot_date exists, we will use that, if not we will create one based on the season string
          if (clubIteration.basic_stats?.plot_date) {
            iterationEntry['plot_date'] = clubIteration.basic_stats.plot_date;
          }
          else {
            const seasonString = clubIteration.season;
            const plotDate = getSeasonStartDate(seasonString).toISOString();
            iterationEntry['plot_date'] = plotDate;
          }

          clubArray.push(iterationEntry);
        }
      });

      // aggregate national team data into one row per team (U17, U19, U21, A)
      nationalArray = Object.values(nationalArray.reduce((acc: { [key: string]: IntermediateNationalTeamEntry }, item: CareerIterationEntry) => {
        const clubId = item.club.id.toString();

        if (!acc[clubId]) {
          // initialize the accumulator for the club with primary_positions
          acc[clubId] = {
            ...item,
            totalValues: item.primary_positions.reduce((positionAcc, position) => {
              positionAcc[position] = item.appearances;
              return positionAcc;
            }, {} as { [position: string]: number })
          };
        }
        else {
          // accumulate the other stats
          acc[clubId]['minutes_played'] += item.minutes_played;
          acc[clubId]['appearances'] += item.appearances;
          acc[clubId]['games_started'] += item.games_started;
          acc[clubId]['n_goals'] += item.n_goals;
          acc[clubId]['n_assists'] += item.n_assists;

          // update the position appearance counts for all primary_positions in the current item
          item.primary_positions.forEach((position) => {
            if (!acc[clubId].totalValues[position]) {
              acc[clubId].totalValues[position] = item.appearances;
            }
            else {
              acc[clubId].totalValues[position] += item.appearances;
            }
          });

          // decide which positions to set as primary_positions
          const dominanceThreshold = 1.75;
          acc[clubId].primary_positions = (() => {
            const sortedPositions = Object.keys(acc[clubId].totalValues).sort((a, b) => acc[clubId].totalValues[b] - acc[clubId].totalValues[a]);
            if (sortedPositions.length < 2) {
              return sortedPositions;
            }

            const topPositionCount = acc[clubId].totalValues[sortedPositions[0]];
            const secondPositionCount = acc[clubId].totalValues[sortedPositions[1]];
            if (topPositionCount >= secondPositionCount * dominanceThreshold) {
              return [sortedPositions[0]];
            }
            else {
              return sortedPositions.slice(0, 2);
            }
          })();
        }

        return acc;
      }, {}));

      clubArray.sort((a, b) => b.plot_date && a.plot_date ? b.plot_date.localeCompare(a.plot_date) : 0);
      nationalArray.sort((a, b) => b.club?.name && a.club?.name ? b.club.name.localeCompare(a.club.name) : 0);

      setPlayerClubIterationEntryArray(clubArray);
      setPlayerNationalIterationEntryArray(nationalArray);
      setCareerArraysInitialized(true);
    }
  }, [competitions, playerCareers, selectedPlayer, userSettings]);


  const getFullNationalTableHeight = () => {
    const height = playerNationalIterationEntryArray.length > 0
      ? 32 + (playerNationalIterationEntryArray.length * 37)
      : 38;

    return height;
  };


  return (
    <div className='full-size-container'>
      <div className='player-view-widget-container player-view-career-national-entry' style={{ height: Math.min(300, getFullNationalTableHeight()) }}>

        {careerArraysInitialized && playerNationalIterationEntryArray.length === 0 && (
          <div className='player-view-career-entry-top-section'>
            <div className='player-view-career-entry-title'>
              {translate('noNationalCareer', userConfig?.language)}
            </div>
          </div>
        )}

        {careerArraysInitialized && playerNationalIterationEntryArray.length > 0 && (
          <CareerTable
            data={playerNationalIterationEntryArray}
            isNationalTeam={true}
          />
        )}
      </div>

      <div className='player-view-widget-container player-view-career-club-entry' style={{ top: Math.min(300, getFullNationalTableHeight()) + 24 }}>
        {careerArraysInitialized && (
          <CareerTable
            data={playerClubIterationEntryArray}
            isNationalTeam={false}
          />
        )}
      </div>
    </div>
  );
};
