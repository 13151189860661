import '../documents.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import { Dialog } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EditIcon from '@mui/icons-material/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

// import EuroIcon from '@mui/icons-material/Euro';
// import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import {
  ClauseCondition,
  TotalSubCondition,
  PlayerClause,
  ConditionCompetitionString,
} from '../../../types';

import { translate } from '../../../../common/language/translations';
import { ConditionModal } from '../../modals/clauseModals/ConditionModal';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ResolveTotalConditionModal } from '../../modals/clauseModals/ResolveTotalConditionModal';
import { SeasonAccordion } from '../../accordions/SeasonAccordion';
import { ResolveSellOnConditionModal } from '../../modals/clauseModals/ResolveSellOnConditionModal';
import { OverrideTotalConditionModal } from '../../modals/clauseModals/OverrideTotalConditionModal';
import { ClauseState, SubClauseState } from './clauseState';
import { ClauseAction } from './clauseReducer';
import { getSeasonStartDate } from '../../../utils/clauseUtils';
import { IconButton } from '../../controls/buttons/IconButton';
import { ClubLogo } from '../../display/images/ClubLogo';
import { modalPaperProps } from '../../modals/globalModals/GlobalModals';


interface ConditionsProps {
  existingClauseSelected: PlayerClause | undefined;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}


export const Conditions: React.FC<ConditionsProps> = ({
  existingClauseSelected,
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const competitions = useRecoilValue(competitionsState);

  const { openConfirmModal, openTextModal } = useOpenGlobalModal();

  const [conditionModalHasUnsavedChanges, setConditionModalHasUnsavedChanges] = useState(false);

  const [isResolveSellOnConditionModalOpen, setIsResolveSellOnConditionModalOpen] = useState(false);

  const [isResolveTotalConditionModalOpen, setIsResolveTotalConditionModalOpen] = useState(false);
  const [isOverrideTotalConditionModalOpen, setIsOverrideTotalConditionModalOpen] = useState(false);
  const [totalConditionIndexOfModal, setTotalConditionIndexOfModal] = useState<number | undefined>(undefined);
  const [totalSubConditionIndexOfModal, setTotalSubConditionIndexOfModal] = useState<number | undefined>(undefined);

  const [conditionToEdit, setConditionToEdit] = useState<ClauseCondition | undefined>(undefined);

  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());


  const stateAccessor: ClauseState | SubClauseState = state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;


  const handleEditConditionClick = (condition: ClauseCondition) => {
    setConditionToEdit(condition);
    dispatch({ type: 'SET_IS_CONDITION_MODAL_OPEN', payload: true });
  };


  const closeConditionModal = () => {
    setConditionModalHasUnsavedChanges(false);
    dispatch({ type: 'SET_IS_CONDITION_MODAL_OPEN', payload: false });

    setTimeout(() => {
      setConditionToEdit(undefined);
    }, 150);
  };


  const addOrEditCondition = (newCondition: ClauseCondition) => {
    dispatch({ type: 'ADD_OR_EDIT_CONDITION', payload: { newCondition, conditionToEdit } });
    closeConditionModal();
  };


  const deleteCondition = () => {
    if (conditionToEdit) {
      dispatch({ type: 'DELETE_CONDITION', payload: conditionToEdit.conditionId });
    }
    closeConditionModal();
  };


  const handleOpenCloseConditionModal = () => {
    openConfirmModal(
      closeConditionModal,
      conditionToEdit ? 'discardChanges?' : 'discardCondition?',
      conditionToEdit ? 'changesWillNotBeSaved' : 'conditionWillNotBeSaved'
    );
  };


  const handleCloseConditionModalClick = () => {
    if (conditionModalHasUnsavedChanges) {
      handleOpenCloseConditionModal();
    }
    else {
      closeConditionModal();
    }
  };


  // Total condition functions

  const handleTotalConditionValueClick = (totalConditionIndex: number) => {
    setTotalConditionIndexOfModal(totalConditionIndex);
    setIsOverrideTotalConditionModalOpen(true);
  };


  const closeOverrideTotalConditionModal = () => {
    setTotalConditionIndexOfModal(undefined);
    setIsOverrideTotalConditionModalOpen(false);
  };


  const reactivateTotalCondition = async (totalConditionIndex: number, totalSubConditionIndex: number) => {
    dispatch({ type: 'REACTIVATE_TOTAL_CONDITION', payload: { totalConditionIndex, totalSubConditionIndex } });
    closeResolveOrReactivateTotalConditionModal();
  };


  const closeResolveOrReactivateTotalConditionModal = () => {
    setTotalConditionIndexOfModal(undefined);
    setTotalSubConditionIndexOfModal(undefined);
    setIsResolveTotalConditionModalOpen(false);
  };


  const onTotalConditionResolveOrReactivateIconClick = (conditionIndex: number, subConditionIndex: number, isResolve: boolean) => {
    setTotalConditionIndexOfModal(conditionIndex);
    setTotalSubConditionIndexOfModal(subConditionIndex);
    if (isResolve) {
      setIsResolveTotalConditionModalOpen(true);
    }
    else {
      openConfirmModal(
        () => reactivateTotalCondition(conditionIndex, subConditionIndex),
        'reactivateCondition',
        'reactivateConditionInfo',
        closeResolveOrReactivateTotalConditionModal
      );
    }
  };


  const confirmIncorrectTransfer = () => {
    if (existingClauseSelected && stateAccessor.nextClubId !== undefined) {
      dispatch({ type: 'SET_INCORRECT_NEXT_CLUB', payload: stateAccessor.nextClubId });
    }
  };


  const confirmRegretIncorrectTransfer = (incorrectClubId: number) => {
    if (existingClauseSelected) {
      dispatch({ type: 'REGRET_INCORRECT_NEXT_CLUB', payload: incorrectClubId });
    }
  };


  const getCompetitionsTitle = (competitionIds: number[], competitionString: ConditionCompetitionString) => {
    if (!competitions || competitionString !== 'someCompetitions' || competitionIds.length <= 1) return undefined;

    let title = '';
    competitionIds.forEach(competitionId => {
      const competition = competitions[competitionId];
      title += competition ? competition.name + ', ' : '';
    });

    return title.slice(0, -2);
  };

  const getCompetitionsDisplay = (competitionIds: number[], competitionString: ConditionCompetitionString) => {
    if (!userConfig || !competitions) return '';

    if (competitionString !== 'someCompetitions') {
      return translate(competitionString, userConfig.language, true);
    }

    if (competitionIds.length === 1) {
      const competitionName = competitions[competitionIds[0]]?.name ??
        (competitionIds[0] === 100000 ? 'European Qualifiers' : '');

      return competitionName;
    }

    return translate('someCompetitions', userConfig.language, true);
  };


  const isActiveClause = !(existingClauseSelected?.isDeleted || existingClauseSelected?.isResolved);

  const isLastOrOnlySubClause = !state.subClauses || (
    state.additionalClauseState.activeSubClauseTab !== undefined && state.additionalClauseState.activeSubClauseTab === state.subClauses.length - 1
  );

  const isActiveSubClause = isActiveClause && isLastOrOnlySubClause;

  const isMaxConditionalFeeReached = stateAccessor.maxConditionalFees !== null && (stateAccessor.conditionalFees ?? 0) >= stateAccessor.maxConditionalFees;

  const sellingClub = stateAccessor.sellingClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.sellingClubId] : undefined;
  const buyingClub = stateAccessor.buyingClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.buyingClubId] : undefined;
  const nextClub = stateAccessor.nextClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.nextClubId] : undefined;


  return (
    <div className='clause-conditions-section'>

      <Dialog
        open={state.additionalClauseState.isConditionModalOpen}
        onClose={() => handleCloseConditionModalClick()}
        PaperProps={modalPaperProps}
      >
        <ConditionModal
          closeModal={() => handleCloseConditionModalClick()}

          conditionToEdit={conditionToEdit}
          state={state}

          addOrEditCondition={addOrEditCondition}
          deleteCondition={deleteCondition}

          hasUnsavedChanges={conditionModalHasUnsavedChanges}
          setHasUnsavedChanges={setConditionModalHasUnsavedChanges}
        />
      </Dialog>

      <Dialog
        open={isOverrideTotalConditionModalOpen}
        onClose={() => closeOverrideTotalConditionModal()}
        PaperProps={modalPaperProps}
      >
        <OverrideTotalConditionModal
          closeModal={() => closeOverrideTotalConditionModal()}
          state={state}
          dispatch={dispatch}
          totalConditionIndex={totalConditionIndexOfModal}
        />
      </Dialog>

      <Dialog
        open={isResolveTotalConditionModalOpen}
        onClose={() => closeResolveOrReactivateTotalConditionModal()}
        PaperProps={modalPaperProps}
      >
        <ResolveTotalConditionModal
          closeModal={() => closeResolveOrReactivateTotalConditionModal()}
          state={state}
          dispatch={dispatch}
          totalConditionIndex={totalConditionIndexOfModal}
          totalSubConditionIndex={totalSubConditionIndexOfModal}
        />
      </Dialog>

      <Dialog
        open={isResolveSellOnConditionModalOpen}
        onClose={() => setIsResolveSellOnConditionModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <ResolveSellOnConditionModal
          closeModal={() => setIsResolveSellOnConditionModalOpen(false)}
          state={state}
          dispatch={dispatch}
        />
      </Dialog>

      {stateAccessor.totalConditions.map((condition, conditionIndex) => {

        const maxThreshold = condition.subConditions
          ? condition.subConditions[condition.subConditions.length - 1].threshold
          : 0;

        const currentValue = condition.currentValue;
        const progressValue = Math.min(currentValue ?? 0, maxThreshold);

        const allThresholdsAreResolved = condition.subConditions.every(subCondition => subCondition.isResolved);

        const progressBarPositioningOffset = condition.subConditions[0].threshold / maxThreshold < 0.05
          ? 35
          : condition.subConditions[0].threshold / maxThreshold < 0.1
            ? 15
            : 0;

        return (
          <div key={conditionIndex} className='clause-existing-condition'>
            <div
              className='clause-existing-condition-row'
              style={{ height: 32 }}
            >
              <div className='clause-existing-condition-top-row-title'>
                {condition.condition ? translate(condition.condition, userConfig?.language) : ''}

                {condition.minMinutesPlayed && userConfig && (
                  <div style={{ fontSize: 12, marginLeft: 3, marginTop: 1 }}>
                    {' (' + translate('atLeast<threshold>', userConfig?.language) + ' ' + condition.minMinutesPlayed + ' min)'}
                  </div>
                )}

                {currentValue !== undefined && ': '}
                {currentValue !== undefined && (
                  <div
                    title={translate('incorrectValue?', userConfig?.language)}
                    className='clause-existing-condition-top-row-current-value'
                  >
                    {currentValue}
                  </div>
                )}
              </div>

              <div className='clause-existing-condition-top-row-info' title={getCompetitionsTitle(condition.competitionIds, condition.competitionString)}>
                {'(' + getCompetitionsDisplay(condition.competitionIds, condition.competitionString) + ')'}
              </div>

              <div
                className='clause-incorrect-transfer-section'
                style={{ marginLeft: 30, fontSize: 10, marginTop: -1 }}
                onClick={() => handleTotalConditionValueClick(conditionIndex)}
              >
                <div
                  className='clause-existing-condition-status-icon'
                  style={{ transition: '0ms', paddingRight: 20, marginLeft: -20 }}>
                  <ErrorOutlineIcon style={{ fontSize: 14, marginTop: -3 }} />
                </div>
                {translate('incorrectValue?', userConfig?.language)}
              </div>

              {(condition.comment ?? '') !== '' && (
                <div className='clause-existing-condition-top-row-comment'>
                  <IconButton
                    onClick={() => openTextModal(translate('comment', userConfig?.language), condition.comment)}
                    icon={<CommentIcon style={{ fontSize: 15, marginBottom: -2 }} />}
                    size={24}
                  />
                  <div style={{ marginLeft: 2 }}>
                    {condition.comment}
                  </div>
                </div>
              )}
            </div>

            <div
              className='clause-existing-condition-row'
              style={{ height: 55 }}
            >
              <div
                className='clause-existing-condition-total-progress-bar'
                style={{
                  marginLeft: 15 + progressBarPositioningOffset,
                  width: 'calc(100% - ' + (65 + progressBarPositioningOffset) + 'px)'
                }}
              >

                {condition.subConditions && condition.subConditions.map((subCondition: TotalSubCondition, thresholdIndex: number) => {

                  const isReached = subCondition.threshold <= progressValue;

                  return (
                    <div
                      key={conditionIndex + '-' + thresholdIndex}
                      className='clause-existing-condition-threshold-container'
                      style={{ left: (subCondition.threshold / maxThreshold) * 100 + '%' }}
                    >
                      <div className='clause-existing-condition-threshold'>
                        {subCondition.threshold}
                      </div>

                      <div className='clause-existing-condition-threshold-amount-container'>
                        <div className='clause-existing-condition-threshold-amount'>
                          {subCondition.isResolved && (
                            <div
                              className='clause-existing-condition-status-icon clause-existing-condition-resolved-icon'
                              title={translate('reactivateCondition', userConfig?.language)}
                              onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, false)}
                            >
                              <TaskAltIcon style={{ fontSize: 14 }} />
                            </div>
                          )}

                          {!subCondition.isResolved && isReached && isActiveSubClause && !isMaxConditionalFeeReached && (
                            <div
                              className='clause-existing-condition-status-icon clause-existing-condition-notification-icon'
                              title={translate('resolveCondition', userConfig?.language)}
                              onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, true)}
                            >
                              <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                            </div>
                          )}

                          {!subCondition.isResolved && !isReached && isActiveSubClause && !isMaxConditionalFeeReached && (
                            <div
                              className='clause-existing-condition-status-icon clause-existing-condition-pending-icon'
                              title={translate('resolveCondition', userConfig?.language)}
                              onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, true)}
                            >
                              <HourglassTopIcon style={{ fontSize: 14 }} />
                            </div>
                          )}

                          {!subCondition.isResolved
                            && existingClauseSelected
                            && (!isActiveSubClause || isMaxConditionalFeeReached)
                            && (
                              <div
                                className='clause-existing-condition-status-icon clause-existing-condition-discarded-icon'
                                title={translate('resolveCondition', userConfig?.language)}
                                onClick={() => onTotalConditionResolveOrReactivateIconClick(conditionIndex, thresholdIndex, true)}
                              >
                                <CancelOutlinedIcon style={{ fontSize: 14 }} />
                              </div>
                            )
                          }

                          {getDisplayEconomicValue(subCondition.resolvedAmount ?? subCondition.amount, true)}
                        </div>
                      </div>

                    </div>
                  );
                })}

                <div className='clause-existing-condition-total-progress' style={{ width: (progressValue / maxThreshold) * 100 + '%' }} />
              </div>
            </div>

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <IconButton
                onClick={() => handleEditConditionClick(condition)}
                icon={<EditIcon style={{ fontSize: 16, marginTop: 1 }} />}
                size={24}
                style={{ position: 'absolute', right: 2, top: 2 }}
              />
            )}

            {allThresholdsAreResolved && !(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='fade-in' style={{ position: 'absolute', right: 7, top: 7 }}>
                <TaskAltIcon style={{ fontSize: 16, color: '#9df78b' }} />
              </div>
            )}
          </div>
        );
      })}

      {stateAccessor.seasonConditions.map((condition, conditionIndex) => {

        const isMaxSeasonConditionAmountReached = condition.maxAmount !== undefined && (condition.totalResolvedAmount ?? 0) >= condition.maxAmount;

        return (
          <div key={conditionIndex} className='clause-existing-condition'>
            <div
              className='clause-existing-condition-row'
              style={{ height: 32 }}>
              <div className='clause-existing-condition-top-row-title'>
                {translate('seasonCondition', userConfig?.language)}
              </div>

              <div className='flex-row' style={{ marginLeft: 15, marginRight: 3, fontSize: 11, marginBottom: -1, color: 'lightgray' }}>
                {getDisplayEconomicValue((condition.totalResolvedAmount ?? 0), true)}
                {' / '}

                {condition.maxAmount !== undefined && (
                  <div style={{ marginLeft: 3 }}>
                    {getDisplayEconomicValue(condition.maxAmount, true)}
                  </div>
                )}

                {condition.maxAmount === undefined && <AllInclusiveIcon style={{ fontSize: 14, marginLeft: 3, marginBottom: -2, color: 'lightgray' }} />}
              </div>

              <div className='clause-existing-condition-top-row-info' title={getCompetitionsTitle(condition.competitionIds, condition.competitionString)}>

                {userConfig && condition.competitionString && condition.seasonString
                  ? (
                    '('
                    + translate(condition.seasonString, userConfig?.language, true)
                    + ' '
                    + translate(
                      condition.competitionString === 'theNationalTeam' || condition.competitionString === 'allNationalTeams' ? 'for<team>' : 'in<competition>',
                      userConfig?.language
                    )
                    + ' '
                    + getCompetitionsDisplay(condition.competitionIds, condition.competitionString)
                    + ')'
                  )
                  : ''}
              </div>

              {(condition.comment ?? '') !== '' && (
                <div className='clause-existing-condition-top-row-comment'>
                  <IconButton
                    onClick={() => openTextModal(translate('comment', userConfig?.language), condition.comment)}
                    icon={<CommentIcon style={{ fontSize: 15, marginBottom: -2 }} />}
                    size={24}
                  />
                  <div style={{ marginLeft: 2 }}>
                    {condition.comment}
                  </div>
                </div>
              )}
            </div>

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <IconButton
                onClick={() => handleEditConditionClick(condition)}
                icon={<EditIcon style={{ fontSize: 16, marginTop: 1 }} />}
                size={24}
                style={{ position: 'absolute', right: 2, top: 2 }}
              />
            )}

            {isMaxSeasonConditionAmountReached && !(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <div className='fade-in' style={{ position: 'absolute', right: 7, top: 7 }}>
                <TaskAltIcon style={{ fontSize: 16, color: '#9df78b' }} />
              </div>
            )}

            {condition.seasonStrings.map((seasonOption, seasonIndex) => {

              // we always want to show all seasons up until the current season, unless any max amount is reached
              // if the max amount is reached, we only want to show resolved seasons
              // we always want to show at least on season, even if it is in the future, as long as the clause is active
              // we always want to show at least one pending season, even if it's in the future, as long the clause is active

              const seasonStartDate = getSeasonStartDate(seasonOption);
              const seasonStartedOverAYearAgo = seasonStartDate < oneYearAgo;
              const seasonIsInTheFuture = seasonStartDate > today;

              const overallStatus = condition.seasonToCurrentStatuses[seasonOption]?.overallStatus;
              if ((isMaxSeasonConditionAmountReached || isMaxConditionalFeeReached) && overallStatus !== 'resolved') return null;

              if (seasonStartDate > today && !overallStatus) {
                // if the season is in the future, we only want to show it if the clause is active
                if (!isActiveSubClause) return null;

                // if the season is in the future and the clause is active, we want to show it if it the first season without a status
                const statusOfPreviousSeason = condition.seasonToCurrentStatuses[condition.seasonStrings[seasonIndex - 1]]?.overallStatus;
                if (seasonIndex > 0 && !statusOfPreviousSeason) return null;
              }

              // we want to expand all seasons that are not resolved and are not in the future
              const shouldStartExpanded = !overallStatus && isActiveSubClause && !isMaxConditionalFeeReached && !seasonIsInTheFuture;

              return (
                <div
                  key={(existingClauseSelected?.id ?? '') + seasonIndex}
                  style={{ marginTop: seasonIndex === 0 ? 2 : 0 }}
                  className='clause-existing-condition-season-accordion'>
                  <SeasonAccordion
                    state={state}
                    seasonString={seasonOption}
                    seasonCondition={condition}
                    shouldStartExpanded={shouldStartExpanded}
                    seasonStartedOverAYearAgo={seasonStartedOverAYearAgo}
                    isActiveSubClause={isActiveSubClause && !isMaxConditionalFeeReached}
                    dispatch={dispatch}
                  />
                </div>
              );
            })}
          </div>
        );
      })}

      {stateAccessor.sellOnCondition && (
        <div className='clause-existing-condition'>

          <div className='clause-existing-condition-row' style={{ height: 30 }}>

            <div className='clause-existing-condition-top-row-title'>
              {translate('sellOnCondition', userConfig?.language)}
              : {stateAccessor.sellOnCondition.sellOnPercentage}%
            </div>

            {stateAccessor.sellOnCondition.dynamicSellOnPercentages && (
              <div className='clause-existing-condition-top-row-info flex-row' style={{ marginLeft: 8 }}>
                {'('}
                {stateAccessor.sellOnCondition.dynamicSellOnPercentages.map((datedValue, index) => {
                  return (
                    <div key={index} className='flex-row'>
                      {datedValue.value}%
                      {stateAccessor.sellOnCondition?.dynamicSellOnPercentages && index < stateAccessor.sellOnCondition.dynamicSellOnPercentages.length - 1
                        ? <ArrowRightAltIcon style={{ fontSize: 12, marginLeft: 1, marginRight: 1, marginTop: 1, color: '#ffffffd4' }} />
                        : ''}
                    </div>
                  );
                })}
                {')'}
              </div>
            )}

            <div className='clause-existing-condition-top-row-info' style={{ marginLeft: stateAccessor.sellOnCondition.dynamicSellOnPercentages ? 6 : 8 }}>
              {'(' + translate(stateAccessor.sellOnCondition.isNet ? 'net' : 'gross', userConfig?.language, true) + ')'}
            </div>

            {stateAccessor.sellOnCondition && (stateAccessor.sellOnCondition.comment ?? '') !== '' && (
              <div className='clause-existing-condition-top-row-comment'>
                <IconButton
                  onClick={() => openTextModal(translate('comment', userConfig?.language), stateAccessor.sellOnCondition?.comment ?? '')}
                  icon={<CommentIcon style={{ fontSize: 15, marginBottom: -2 }} />}
                  size={24}
                />
                <div style={{ marginLeft: 2 }}>
                  {stateAccessor.sellOnCondition.comment}
                </div>
              </div>
            )}
          </div>

          <div className='clause-existing-condition-row clause-existing-condition-sell-on-row'>

            <div className='flex-row'>
              {sellingClub
                && !stateAccessor.sellOnCondition.isResolved
                && !nextClub
                && isActiveSubClause
                && (
                  <ClubLogo
                    club={sellingClub}
                    size={14}
                    showClubName={true}
                    containerStyle={{ gap: 3 }}
                  />
                )}

              {sellingClub
                && !stateAccessor.sellOnCondition.isResolved
                && !nextClub
                && isActiveSubClause
                && <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />
              }

              {buyingClub && (
                <ClubLogo
                  club={buyingClub}
                  size={14}
                  showClubName={true}
                  containerStyle={{ gap: 3 }}
                />
              )}

              {existingClauseSelected && <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />}

              {existingClauseSelected && nextClub && (
                <ClubLogo
                  club={nextClub}
                  size={14}
                  showClubName={true}
                  containerStyle={{ gap: 3 }}
                />
              )}

              {existingClauseSelected
                && !nextClub
                && (stateAccessor.sellOnCondition.isResolved || !isActiveSubClause)
                && (
                  <div style={{ marginLeft: 4 }}>
                    ?
                  </div>
                )
              }

              {existingClauseSelected
                && !nextClub
                && !(stateAccessor.sellOnCondition.isResolved || !isActiveSubClause)
                && (
                  <div className='flex-row'>
                    <div
                      className='clause-existing-condition-status-icon clause-existing-condition-pending-icon'
                      title={translate('resolveCondition', userConfig?.language)}
                      style={{ marginLeft: 1, marginTop: -1 }}
                      onClick={() => setIsResolveSellOnConditionModalOpen(true)}
                    >
                      <HourglassTopIcon style={{ fontSize: 16 }} />
                    </div>

                    <div style={{ marginLeft: 22 }}>
                      {translate('(noTransferRegistered)', userConfig?.language)}
                    </div>
                  </div>
                )
              }
            </div>

            {existingClauseSelected
              && nextClub
              && !(stateAccessor.sellOnCondition.isResolved || !isActiveSubClause)
              && (
                <div>
                  <div
                    className='clause-existing-condition-status-icon clause-existing-condition-notification-icon'
                    title={translate('resolveCondition', userConfig?.language)}
                    style={{ marginLeft: 16, marginTop: -8.5 }}
                    onClick={() => setIsResolveSellOnConditionModalOpen(true)}
                  >
                    <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                  </div>
                </div>
              )
            }

            {stateAccessor.sellOnCondition.isResolved && (
              <div>
                <div
                  className='clause-existing-condition-status-icon clause-existing-condition-resolved-icon'
                  title={translate('reactivateCondition', userConfig?.language)}
                  style={{ marginLeft: 10, marginTop: -8.5 }}
                  onClick={() => openConfirmModal(
                    () => dispatch({ type: 'REACTIVATE_SELL_ON_CONDITION' }),
                    'reactivateCondition',
                    'reactivateConditionInfo'
                  )}
                >
                  <TaskAltIcon style={{ fontSize: 14 }} />
                </div>
              </div>
            )}

            {existingClauseSelected
              && !stateAccessor.sellOnCondition.isResolved
              && !isActiveSubClause
              && (
                <div>
                  <div
                    className='clause-existing-condition-status-icon clause-existing-condition-discarded-icon'
                    title={translate('reactivateCondition', userConfig?.language)}
                    style={{ marginLeft: 10, marginTop: -8.5 }}
                    onClick={() => setIsResolveSellOnConditionModalOpen(true)}
                  >
                    <CancelOutlinedIcon style={{ fontSize: 14 }} />
                  </div>
                </div>
              )}

            {existingClauseSelected
              && nextClub
              && !stateAccessor.sellOnCondition.isResolved
              && (
                <div
                  className='clause-incorrect-transfer-section'
                  onClick={() => openConfirmModal(confirmIncorrectTransfer, 'incorrectTransfer?', 'incorrectTransferInfo')}
                >
                  <div
                    className='clause-existing-condition-status-icon'
                    style={{ transition: '0ms', paddingRight: 20 }}
                  >
                    <ErrorOutlineIcon style={{ fontSize: 14 }} />
                  </div>
                  {translate('incorrectTransfer?', userConfig?.language)}
                </div>
              )
            }
          </div>

          {state.isSoldPlayer
            && stateAccessor.sellOnCondition.isResolved
            && isActiveSubClause
            && (
              <div className='clause-existing-condition-row' style={{ height: 30 }} >
                <div
                  className='clause-sell-on-clause--add-sub-clause-link'
                  onClick={() => dispatch({ type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN', payload: true })}
                >
                  <div
                    className='clause-existing-condition-status-icon clause-existing-condition-notification-icon'
                    style={{ marginLeft: -24, marginTop: -1 }}
                  >
                    <NotificationsIcon style={{ fontSize: 14, marginTop: -1 }} />
                  </div>
                  {translate('addClausesForNextClub', userConfig?.language)}
                </div>
              </div>
            )
          }

          {!stateAccessor.sellOnCondition.isResolved
            && buyingClub
            && isActiveSubClause
            && stateAccessor.incorrectNextClubIds
            && stateAccessor.incorrectNextClubIds.length > 0
            && stateAccessor.incorrectNextClubIds.map((incorrectClubId, index) => {

              const incorrectClub = state.additionalClauseState.allClubs[incorrectClubId];

              return (
                <div
                  key={index}
                  className='clause-existing-condition-row clause-existing-condition-sell-on-row'
                  style={{
                    marginTop: index === 0 ? 6 : 0,
                    backgroundImage: 'linear-gradient(to right, #ffffffaa 90%, #00000000 97%)'
                  }}
                >
                  <div className='flex-row'>
                    <ClubLogo
                      club={buyingClub}
                      size={14}
                      showClubName={true}
                      containerStyle={{ gap: 3 }}
                    />

                    <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />

                    <ClubLogo
                      club={incorrectClub}
                      size={14}
                      showClubName={true}
                      containerStyle={{ gap: 3 }}
                    />

                    <div
                      className='clause-incorrect-transfer-section'
                      onClick={() => openConfirmModal(
                        () => confirmRegretIncorrectTransfer(incorrectClubId),
                        'regretIncorrectTransfer?',
                        'regretIncorrectTransferInfo')
                      }
                      style={{ marginLeft: 28, fontSize: 11, marginTop: 1 }}
                    >
                      <div
                        className='clause-existing-condition-status-icon'
                        style={{ transition: '0ms', paddingRight: 10, marginTop: -3, marginLeft: -19 }}
                      >
                        <ErrorOutlineIcon style={{ fontSize: 14 }} />
                      </div>
                      {translate('markedAsIncorrectTransfer', userConfig?.language)}
                    </div>
                  </div>
                </div>
              );
            })
          }

          {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
            <IconButton
              onClick={() => stateAccessor.sellOnCondition ? handleEditConditionClick(stateAccessor.sellOnCondition) : null}
              icon={<EditIcon style={{ fontSize: 16, marginTop: 1 }} />}
              size={24}
              style={{ position: 'absolute', right: 2, top: 2 }}
            />
          )}
        </div>
      )
      }


      {
        !stateAccessor.sellOnCondition
        && existingClauseSelected
        && isActiveSubClause
        && (nextClub || (stateAccessor.incorrectNextClubIds && stateAccessor.incorrectNextClubIds.length > 0))
        && (
          <div className='clause-existing-condition'>

            <div
              className='clause-existing-condition-row'
              style={{ height: 30 }}>
              <div className='clause-existing-condition-top-row-title'>
                {nextClub
                  ? translate('transferRegisteredInfo', userConfig?.language)
                  : translate('registeredTransfersMarkedAsIncorrect', userConfig?.language) + ':'}
              </div>
            </div>

            {nextClub && (
              <div className='clause-existing-condition-row clause-existing-condition-sell-on-row'>

                {buyingClub && (
                  <div className='flex-row'>
                    <ClubLogo
                      club={buyingClub}
                      size={14}
                      showClubName={true}
                      containerStyle={{ gap: 3 }}
                    />

                    <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />

                    <ClubLogo
                      club={nextClub}
                      size={14}
                      showClubName={true}
                      containerStyle={{ gap: 3 }}
                    />
                  </div>
                )}

                <div
                  className='clause-incorrect-transfer-section'
                  style={{ marginLeft: 35 }}
                  onClick={() => openConfirmModal(confirmIncorrectTransfer, 'incorrectTransfer?', 'incorrectTransferInfo')}
                >
                  <div
                    className='clause-existing-condition-status-icon'
                    style={{ transition: '0ms', paddingRight: 20, marginLeft: -20 }}>
                    <ErrorOutlineIcon style={{ fontSize: 14 }} />
                  </div>
                  {translate('incorrectTransfer?', userConfig?.language)}
                </div>
              </div>
            )}

            {buyingClub
              && stateAccessor.incorrectNextClubIds
              && stateAccessor.incorrectNextClubIds.length > 0
              && stateAccessor.incorrectNextClubIds.map((incorrectClubId, index) => {

                const incorrectClub = state.additionalClauseState.allClubs[incorrectClubId];

                return (
                  <div
                    key={index}
                    className='clause-existing-condition-row clause-existing-condition-sell-on-row'
                    style={{
                      marginTop: index === 0 ? 6 : 0,
                      backgroundImage: 'linear-gradient(to right, #ffffffaa 90%, #00000000 97%)'
                    }}
                  >
                    <div className='flex-row'>
                      <ClubLogo
                        club={buyingClub}
                        size={14}
                        showClubName={true}
                        containerStyle={{ gap: 3 }}
                      />

                      <ArrowRightAltIcon style={{ fontSize: 15, marginLeft: 3, marginRight: 3, marginBottom: -2, color: '#ffffffd4' }} />

                      <ClubLogo
                        club={incorrectClub}
                        size={14}
                        showClubName={true}
                        containerStyle={{ gap: 3 }}
                      />

                      <div
                        className='clause-incorrect-transfer-section'
                        onClick={() => openConfirmModal(
                          () => confirmRegretIncorrectTransfer(incorrectClubId),
                          'regretIncorrectTransfer?',
                          'regretIncorrectTransferInfo')
                        }
                        style={{ marginLeft: 28, fontSize: 11, marginTop: 1 }}
                      >
                        <div
                          className='clause-existing-condition-status-icon'
                          style={{ transition: '0ms', paddingRight: 10, marginTop: -3, marginLeft: -19 }}
                        >
                          <ErrorOutlineIcon style={{ fontSize: 14 }} />
                        </div>
                        {translate('markedAsIncorrectTransfer', userConfig?.language)}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        )
      }
    </div >
  );
};
