
import { JSX } from 'react';
import { PlayerOverview } from '../../../types';
import { ClubLogo } from '../../display/images/ClubLogo';
import { CompetitionFlag } from '../../display/images/CompetitionFlag';
import { PositionCell } from '../tableCells';


interface FindPlayerTableColumnCellProps {
  row: PlayerOverview;
  language?: string;
}


interface FindPlayerTableColumn {
  key: string;
  width: number;
  cell: (props: FindPlayerTableColumnCellProps) => JSX.Element;
}


export const findPlayerTableColumnsWidth = 600;
export const findPlayerTablePadding = 15;
export const findPlayerTableColumns: FindPlayerTableColumn[] = [
  {
    key: 'age',
    width: 60,
    cell: ({ row }: FindPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.age ?? '-'}
      </div>
    ),
  },
  {
    key: 'position',
    width: 110,
    cell: ({ row, language }: FindPlayerTableColumnCellProps) => (
      <PositionCell playerData={row} language={language} />
    ),
  },
  {
    key: 'height',
    width: 70,
    cell: ({ row }: FindPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.height ? `${row.height} cm` : '-'}
      </div>
    ),
  },
  {
    key: 'league',
    width: 180,
    cell: ({ row }: FindPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        <CompetitionFlag
          competitionId={row.club?.current_competition_id}
          width={17}
          showHyphenIfEmpty={true}
          containerStyle={{ gap: 5 }}
          isWhiteBackground={true}
          smallFontSize={12}
          smallFontSizeThreshold={18}
        />
      </div>
    ),
  },
  {
    key: 'club',
    width: 180 + findPlayerTablePadding,
    cell: ({ row }: FindPlayerTableColumnCellProps) => (
      <div className='table-cell' style={{ paddingRight: findPlayerTablePadding }}>
        <ClubLogo
          club={row.club}
          size={18}
          showClubName={true}
          showHyphenIfEmpty={true}
          containerStyle={{ gap: 5 }}
          isWhiteBackground={true}
          smallFontSize={12}
          smallFontSizeThreshold={18}
        />
      </div>
    ),
  },
];
