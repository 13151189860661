import '../../../platform.css';
import '../scouting.css';

import { useEffect, useReducer, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { alertsConfigSelector } from '../../../recoil/selectors/scoutingConfigSelectors';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { teamsState } from '../../../recoil/atoms/teamsState';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Alert,
  PlayerOverview,
  PlayerOverviewsListQueryOptions,
  PlayerOverviewsQueryOptions,
  StringToAnyMap,
  PlayerAlertsQueryOptions,
} from '../../../types';

import {
  createAlert,
  deleteAlert,
  excludeAllPlayersFromAlert,
  excludePlayerFromAlert,
  undoAllExcludedPlayersFromAlert,
  undoExcludePlayerFromAlert,
  updateAlert,
  updateAlertName,
  updateAlertViewedData
} from '../../../services/firestore/scouting';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../../components/controls/input/InputField';
import { AlertFilters } from './AlertFilters';
import { getPlayerOverviewsList, searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../../services/server/application/playerOverviews';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';
import { ViewContainerBackgroundSvg } from '../../../../assets/svg/ViewContainerBackgroundSvg';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { Toggle } from '../../../components/controls/input/Toggle';
import { alertTablePageSize } from '../Scouting';
import { contractKeysToMonths } from '../../../static/propertyValues';
import { alertHasUnsavedChanges, initialAlertsState } from './alertsState';
import alertsReducer from './alertsReducer';
import { TextButton } from '../../../components/controls/buttons/TextButton';
import { IconButton } from '../../../components/controls/buttons/IconButton';
import { getClubColor } from '../../../static/clubConfigs';
import { AlertTable } from '../../../components/tables/alertTable/AlertTable';


export interface PlayerOverviewWithAlertProperties extends PlayerOverview {
  isExcludedFromAllAlerts?: boolean;
}


export const Alerts: React.FC = () => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const alertsConfig = useRecoilValue(alertsConfigSelector);
  const clubSettings = useRecoilValue(clubSettingsState);
  const teams = useRecoilValue(teamsState);

  const excludeOrUndoExclusionButtonDisabledRef = useRef(false);

  const { openConfirmModal, openTextModal } = useOpenGlobalModal();

  const [state, dispatch] = useReducer(
    alertsReducer,
    initialAlertsState,
  );


  const onChangeInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_SHOW_ALERT_NAME_REQUIRED', payload: false });
    dispatch({ type: 'SET_ALERT_NAME', payload: event.target.value });
  };


  const setExistingAlertSelectedState = async (alert: Alert) => {
    dispatch({ type: 'SET_EXISTING_ALERT_SELECTED', payload: alert });
    dispatch({ type: 'SET_FILTERS', payload: alert });

    await getPlayersFromServer(
      alert,
      state.isExcludedPlayersView,
      Math.max(1, state.currentPage),
    );
  };


  const handleExistingAlertClicked = async (alert: Alert) => {
    if (!userConfig) return;

    setExistingAlertSelectedState(alert);
    dispatch({ type: 'SET_EXISTING_ALERT_WAS_JUST_CLICKED', payload: true });
    updateAlertViewedData(alert.name, userConfig.email, userConfig.club, currentUser);

    // updating the alert view data triggers the useEffect to call setExistingAlertSelectedState again, but existingAlertSelectedWasJustClicked prevents this
    setTimeout(() => {
      dispatch({ type: 'SET_EXISTING_ALERT_WAS_JUST_CLICKED', payload: false });
    }, 500);
  };


  const confirmArrowBackClick = () => {
    dispatch({ type: 'RESET_ALERT_STATE' });
    if (state.existingAlertSelected && userConfig) {
      updateAlertViewedData(state.existingAlertSelected.name, userConfig.email, userConfig.club, currentUser);
    }
  };


  const handleArrowBackClick = () => {
    if ((state.isEditAlertView || !state.existingAlertSelected) && alertHasUnsavedChanges(state)) {
      openConfirmModal(
        confirmArrowBackClick,
        'discardChanges?',
        'changesWillNotBeSaved'
      );
    }
    else {
      confirmArrowBackClick();
    }
  };


  const confirmCancelEditAlert = () => {
    dispatch({ type: 'SET_IS_EDIT_ALERT_VIEW', payload: false });
    dispatch({ type: 'SET_SHOW_EDIT_ALERT_VIEW', payload: false });
    dispatch({ type: 'SET_FILTERS', payload: state.existingAlertSelected });
  };


  const handleEditAlertIconClick = () => {
    if (!state.isEditAlertView) {
      dispatch({ type: 'SET_IS_EDIT_ALERT_VIEW', payload: true });
      setTimeout(() => {
        dispatch({ type: 'SET_SHOW_EDIT_ALERT_VIEW', payload: true });
      }, 300);
    }
    else if (state.showEditAlertView) {
      if (alertHasUnsavedChanges(state)) {
        openConfirmModal(
          confirmCancelEditAlert,
          'discardChanges?',
          'changesWillNotBeSaved'
        );
      }
      else {
        confirmCancelEditAlert();
      }
    }
  };


  const handleBlockIconClick = async () => {
    dispatch({ type: 'SET_IS_EMPTY_SEARCH_RESULT', payload: false });
    dispatch({ type: 'SET_IS_EXCLUDED_PLAYERS_VIEW', payload: !state.isExcludedPlayersView });
    await getPlayersFromServer(
      undefined,
      !state.isExcludedPlayersView,
      1,
    );
    dispatch({ type: 'SET_SHOW_EXCLUDED_PLAYERS_VIEW', payload: !state.isExcludedPlayersView });
  };


  const getPlayersFromServer = async (
    alert: Alert | undefined,
    getExlcudedPlayers: boolean,
    page: number
  ) => {

    const alertSelected = alert ?? state.existingAlertSelected;

    if (!currentUser || !alertsConfig || !alertSelected) return;

    let playerIds: number[] = [];

    if (getExlcudedPlayers) {
      const playerIdsToExclude = alertSelected.playerIdsToExclude;
      const playerIdsToExcludeFromAllAlerts = alertsConfig.playerIdsToExcludeFromAllAlerts;

      const allExcludedPlayerIds = { ...playerIdsToExclude, ...playerIdsToExcludeFromAllAlerts };
      playerIds = Object.keys(allExcludedPlayerIds)
        .map(Number)
        .sort((a, b) => {
          const dateA = new Date(allExcludedPlayerIds[a].dateAdded);
          const dateB = new Date(allExcludedPlayerIds[b].dateAdded);
          return dateB.getTime() - dateA.getTime();
        });
    }

    else {
      playerIds = Object.keys(alertSelected.playerIdsToAlert).map(playerId => parseInt(playerId));
    }

    const commonQueryOptions = {
      playerIds: playerIds,
      page: page,
      pageSize: alertTablePageSize,
    };

    const sortBy = alertSelected.filters.sortBy;
    const positionGroupOfSelectedRoles = alertSelected.selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[alertSelected.selectedRoles[0]]
      ? clubSettings.roleConfigs[alertSelected.selectedRoles[0]].positionGroup
      : null;

    // different endpoint for excluded players due to different sorting
    const result: SearchPlayerOverviewsResponse | undefined = getExlcudedPlayers
      ? await getPlayerOverviewsList(commonQueryOptions as PlayerOverviewsListQueryOptions, currentUser)
      : await searchPlayerOverviews({
        ...commonQueryOptions,
        sortBy: sortBy,
        positionGroup: positionGroupOfSelectedRoles,
      } as PlayerOverviewsQueryOptions, currentUser);


    let newTableData: PlayerOverviewWithAlertProperties[] = [];
    let newTotalHits = 0;
    let newCurrentPage = 0;
    let newIsEmptySearchResult = false;

    if (!result) {
      // todo: handle error?
    }
    else if (!result.total_hits || (result.players.length === 0 && result.current_page === 1)) {
      newCurrentPage = 1;
      newIsEmptySearchResult = true;
    }
    else {
      // the table needs to know if excluded players are exlcuded from all allerts or not
      // adding here, as opposed to the cell subscribing to the alertsConfig recoil atom, provides table consistency
      if (getExlcudedPlayers) {
        result.players.forEach((player: PlayerOverview) => {
          newTableData.push({
            ...player,
            isExcludedFromAllAlerts: player['id'] in alertsConfig.playerIdsToExcludeFromAllAlerts
          });
        });
      }
      else {
        newTableData = result.players;
      }

      newTotalHits = result.total_hits;
      newCurrentPage = result.current_page;
    }

    // if the page is now empty, but more pages exist, we fetch the previous page
    if (newTableData.length === 0 && newCurrentPage > 1) {
      await getPlayersFromServer(
        alert,
        getExlcudedPlayers,
        newCurrentPage - 1,
      );
      return;
    }

    dispatch({
      type: 'SET_SEARCH_RESULT',
      payload: {
        tableData: newTableData,
        totalHits: newTotalHits,
        currentPage: newCurrentPage,
        isEmptySearchResult: newIsEmptySearchResult,
      }
    });
  };


  const handleChangeCurrentPage = async (isIncrement: boolean) => {
    dispatch({ type: 'SET_EMPTY_TABLE_DATA' });
    const nextPage = state.currentPage + (isIncrement ? 1 : - 1);
    await getPlayersFromServer(
      undefined,
      state.isExcludedPlayersView,
      nextPage,
    );
  };


  const handleAddOrEditAlert = async () => {
    if (!userConfig || !alertsConfig) return;

    dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: true });

    const filters: PlayerAlertsQueryOptions = {};

    const positionGroupOfSelectedRoles = state.selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[state.selectedRoles[0]]
      ? clubSettings.roleConfigs[state.selectedRoles[0]].positionGroup
      : null;

    if (state.selectedLogisticTeams.length > 0) filters.logisticTeamIds = state.selectedLogisticTeams;
    if (state.selectedMinConfidence.length > 0) filters.minConfidence = parseInt(state.selectedMinConfidence[0]) / 100;
    if (state.selectedMaxAge.length > 0) filters.maxAge = parseInt(state.selectedMaxAge[0]);
    if (state.selectedPositions.length > 0) filters.primaryPositions = state.selectedPositions;
    if (positionGroupOfSelectedRoles) filters.positionGroup = positionGroupOfSelectedRoles;
    if (state.selectedLeagues.length > 0) filters.competitions = state.selectedLeagues.map(id => parseInt(id));
    if (state.selectedOrderBy.length > 0) filters.sortBy = state.selectedOrderBy[0];

    if (state.selectedIndexes.length > 0) filters.indexRequirements = state.selectedIndexes;
    if (state.selectedForm.length > 0) filters.form = state.selectedForm;
    if (state.selectedPlayingTime.length > 0) filters.playingTime = state.selectedPlayingTime;
    if (state.selectedNationalities.length > 0) filters.countryCodes = state.selectedNationalities;
    if (state.selectedContractStatus.length > 0) filters.maxContractLength = contractKeysToMonths[state.selectedContractStatus[0]];
    if (state.isContractStatusToggled) filters.includeUnknownContracts = true;

    if (state.alertName.length === 0) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate('youMustProvideSearchName', userConfig?.language) });
      dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
      dispatch({ type: 'SET_SHOW_ALERT_NAME_REQUIRED', payload: true });
      return;
    }

    if (Object.keys(filters).length <= 1) {
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate('youMustSelectAtLeastOneFilter', userConfig?.language) });
      dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
      return;
    }


    if (state.newAlertSelected) {

      if (alertsConfig.alerts.some(alert => alert.name === state.alertName)) {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate('searchNameAlreadyExists', userConfig?.language) });
        dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
        return;
      }

      dispatch({ type: 'SET_IS_LOADING', payload: true });

      // alertResult is either message: string, alert: Alert or null (error)
      const createAlertResult = await createAlert(
        state.alertName,
        filters,
        state.selectedRoles,
        alertsConfig.playerIdsToExcludeFromAllAlerts ?? {},
        currentUser,
        userConfig.email,
        userConfig.club,
        currentUser,
      );

      if (createAlertResult) {
        // if alertResult is string, we show the message
        if (typeof createAlertResult === 'string') {
          dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate(createAlertResult, userConfig?.language) });
        }
        else {
          await setExistingAlertSelectedState(createAlertResult);
          dispatch({ type: 'SET_NEW_ALERT_SELECTED', payload: false });
        }
      }

      dispatch({ type: 'SET_IS_LOADING', payload: false });
    }

    else if (state.existingAlertSelected) {

      if (alertsConfig.alerts.some(alert => (alert.name === state.alertName && alert.name !== state.existingAlertSelected?.name))) {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate('searchNameAlreadyExists', userConfig?.language) });
        dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
        return;
      }

      const hasUnsavedFilterChanges = alertHasUnsavedChanges(state, true);
      const hasUnsavedNameChanges = state.alertName !== state.existingAlertSelected.name;

      if (!hasUnsavedFilterChanges && !hasUnsavedNameChanges) {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate('noChangesMade', userConfig?.language) });
        dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
        return;
      }

      // special handling if only name is changed
      if (hasUnsavedNameChanges && !hasUnsavedFilterChanges) {
        updateAlertName(state.existingAlertSelected.name, state.alertName, userConfig.email, userConfig.club, currentUser);
        dispatch({ type: 'SET_IS_EDIT_ALERT_VIEW', payload: false });
        dispatch({ type: 'SET_SHOW_EDIT_ALERT_VIEW', payload: false });
        dispatch({ type: 'SET_IS_RESET_EXCLUDED_PLAYERS_TOGGLED', payload: false });
        dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
        return;
      }

      openConfirmModal(
        () => confirmEditAlert(filters),
        'confirmEdits',
        'editAlertInfo'
      );
    }

    dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
  };


  const confirmEditAlert = async (filters: StringToAnyMap) => {
    if (!userConfig || !state.existingAlertSelected) return;

    dispatch({ type: 'SET_IS_LOADING', payload: true });

    // updateAlertResult is either message: string, or success: boolean
    const updateAlertResult = await updateAlert(
      state.existingAlertSelected.name,
      state.alertName,
      filters,
      state.selectedRoles,
      state.isResetExcludedPlayersToggled,
      currentUser,
      userConfig.email,
      userConfig.club,
      currentUser,
    );

    if (updateAlertResult) {
      if (typeof updateAlertResult === 'string') {
        dispatch({ type: 'SET_ERROR_MESSAGE', payload: translate(updateAlertResult, userConfig?.language) });
      }
      else {
        dispatch({ type: 'SET_IS_EDIT_ALERT_VIEW', payload: false });
        dispatch({ type: 'SET_SHOW_EDIT_ALERT_VIEW', payload: false });
        dispatch({ type: 'SET_IS_RESET_EXCLUDED_PLAYERS_TOGGLED', payload: false });
      }
    }

    dispatch({ type: 'SET_IS_LOADING', payload: false });
  };


  const confirmDeleteAlert = async () => {
    if (!userConfig || !state.existingAlertSelected) return;

    dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: true });
    await deleteAlert(state.existingAlertSelected.name, userConfig.email, userConfig.club, currentUser);

    confirmArrowBackClick();
    dispatch({ type: 'SET_SUBMIT_BUTTON_DISABLED', payload: false });
  };


  const handleDeleteAlert = async () => {
    openConfirmModal(
      confirmDeleteAlert,
      'deleteSearch',
      'noRegretInfo'
    );
  };


  // if a player is excluded
  // if excluded for all alerts
  //   add entry to playerIdsToExcludeFromAllAlerts and remove from all playerIdsToAlert for all alerts
  // if excluded for one alert
  //   add entry to playerIdsToAlert for that alert and remove from playerIdsToAlert for that alert
  const excludePlayer = async (playerId: number, allAlerts: boolean, monthsExcluded: number) => {
    if (!userConfig || excludeOrUndoExclusionButtonDisabledRef.current) return;

    excludeOrUndoExclusionButtonDisabledRef.current = true;
    dispatch({ type: 'SET_PLAYER_WAS_JUST_EXCLUDED', payload: true });

    await excludePlayerFromAlert(
      playerId,
      allAlerts ? undefined : state.alertName,
      monthsExcluded,
      userConfig.email,
      userConfig.club,
      currentUser,
    );

    excludeOrUndoExclusionButtonDisabledRef.current = false;
    dispatch({ type: 'SET_PLAYER_WAS_JUST_EXCLUDED', payload: false });
  };

  const excludAllPlayers = async () => {
    if (!userConfig || !state.existingAlertSelected || excludeOrUndoExclusionButtonDisabledRef.current) return;

    excludeOrUndoExclusionButtonDisabledRef.current = true;
    dispatch({ type: 'SET_PLAYER_WAS_JUST_EXCLUDED', payload: true });

    await excludeAllPlayersFromAlert(
      state.existingAlertSelected,
      userConfig.email,
      userConfig.club,
      currentUser,
    );

    excludeOrUndoExclusionButtonDisabledRef.current = false;
    dispatch({ type: 'SET_PLAYER_WAS_JUST_EXCLUDED', payload: false });
  };


  // if exclusion of player is undonde
  // if excluded for all alerts
  //   remove entry from playerIdsToExcludeFromAllAlerts
  //   for each alert, check manually if player matches filters and if so, add to playerIdsToAlert for that alert
  // if excluded for one alert
  //   remove entry from playerIdsToAlert for that alert
  //   check manually if player matches filters and if so, add to playerIdsToAlert for that alert
  const undoExcludePlayer = async (playerOverview: PlayerOverview, allAlerts: boolean) => {
    if (!userConfig || excludeOrUndoExclusionButtonDisabledRef.current) return;

    excludeOrUndoExclusionButtonDisabledRef.current = true;
    dispatch({ type: 'SET_PLAYER_EXCLUSION_WAS_JUST_UNDONE', payload: true });

    await undoExcludePlayerFromAlert(
      playerOverview,
      state.existingAlertSelected?.filters ?? {},
      teams,
      allAlerts ? undefined : state.alertName,
      userConfig.email,
      userConfig.club,
      currentUser,
    );

    excludeOrUndoExclusionButtonDisabledRef.current = false;
    dispatch({ type: 'SET_PLAYER_EXCLUSION_WAS_JUST_UNDONE', payload: false });

    setTimeout(() => {
      if (state.existingAlertSelected) {
        updateAlertViewedData(state.existingAlertSelected.name, userConfig.email, userConfig.club, currentUser);
      }
    }, 500);
  };

  const undoAllExcludedPlayers = async () => {
    if (!userConfig || !state.existingAlertSelected || excludeOrUndoExclusionButtonDisabledRef.current) return;

    excludeOrUndoExclusionButtonDisabledRef.current = true;
    dispatch({ type: 'SET_PLAYER_EXCLUSION_WAS_JUST_UNDONE', payload: true });

    await undoAllExcludedPlayersFromAlert(
      state.existingAlertSelected,
      teams,
      userConfig.email,
      userConfig.club,
      currentUser,
    );

    excludeOrUndoExclusionButtonDisabledRef.current = false;
    dispatch({ type: 'SET_PLAYER_EXCLUSION_WAS_JUST_UNDONE', payload: false });

    setTimeout(() => {
      if (state.existingAlertSelected) {
        updateAlertViewedData(state.existingAlertSelected.name, userConfig.email, userConfig.club, currentUser);
      }
    }, 500);
  };


  useEffect(() => {
    if (alertsConfig && state.existingAlertSelected && !state.existingAlertSelectedWasJustClicked) {
      const alert = alertsConfig.alerts.find(alert => (alert.name === state.existingAlertSelected?.name || alert.name === state.alertName));
      if (alert) {
        setExistingAlertSelectedState(alert);
      }
    }
  }, [alertsConfig]); // eslint-disable-line react-hooks/exhaustive-deps


  // some changes to the selected roles will automatically set the orderBy and/or index requirements
  useEffect(() => {
    // if a role is selected and the orderBy is the default (skillIndex) or not selected, we automatically set the orderBy to the selected role
    if (state.selectedRoles.length > 0 && (state.selectedOrderBy.length === 0 || state.selectedOrderBy[0] === 'skillIndex')) {
      const lastSelectedRole = state.selectedRoles[state.selectedRoles.length - 1];
      dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [lastSelectedRole] });
    }

    // if orderBy is a role that was now deselected, we set a new orderBy, where selected roles have precedence over skillIndex
    if (state.selectedOrderBy.length > 0 && clubSettings?.roleConfigs[state.selectedOrderBy[0]] && !state.selectedRoles.includes(state.selectedOrderBy[0])) {
      if (state.selectedRoles.length > 0) {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: [state.selectedRoles[0]] });
      }
      else {
        dispatch({ type: 'SET_SELECTED_ORDER_BY', payload: ['skillIndex'] });
      }
    }

    if (state.selectedIndexes.some(index => (index.metric !== 'skill_index' || !state.selectedRoles.includes(index.metric)))) {
      dispatch({
        type: 'SET_SELECTED_INDEXES',
        payload: state.selectedIndexes.filter(index => (index.metric === 'skill_index' || state.selectedRoles.includes(index.metric)))
      });
    }
  }, [state.selectedRoles]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='full-size-container'>

      <div className='full-size'>
        <ViewContainerBackgroundSvg />
      </div>

      {!state.newAlertSelected && !state.existingAlertSelected && (
        <div className='full-size-container alerts-view-existing-alerts-container'>
          {alertsConfig && alertsConfig.alerts.length > 0 && (
            <div className='alert-view-existing-alerts-section' style={{ zIndex: 1 }}>
              {alertsConfig.alerts.map((alert: Alert, index) => {
                return (
                  <div
                    key={index}
                    className='alerts-view-existing-alert'
                    onClick={() => handleExistingAlertClicked(alert)}
                  >
                    <div className='alerts-view-existing-alert-icon-container' style={{ color: clubColor }}>
                      {<NewReleasesIcon style={{ fontSize: 26 }} />}
                    </div>

                    <div className='alerts-view-existing-alert-icon'></div>

                    <div className='alerts-view-existing-alert-name'>
                      {alert.name}
                    </div>

                    <div className='alerts-view-existing-alert-count'>
                      {Object.keys(alert.playerIdsToAlert).length}
                    </div>

                    <div className='alerts-view-existing-alert-text'>
                      {translate('playersMatchTheSearch', userConfig?.language)}
                    </div>

                    <div
                      className='alerts-view-existing-alert-arrow-icon'
                      style={{ color: clubColor }}
                    >
                      {<ArrowRightAltIcon />}
                    </div>

                    {alert.numberOfNewPlayersSinceLastViewed > 0 && (
                      <div className='alerts-view-existing-alert-notification-container'>
                        <div className='alerts-view-existing-alert-notification'>
                          {alert.numberOfNewPlayersSinceLastViewed}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
              }
            </div>
          )}

          <div className='alerts-view-new-alert-button-section'>
            <TextButton
              onClick={() => dispatch({ type: 'SET_NEW_ALERT_SELECTED', payload: true })}
              text={translate('addNewAlert', userConfig?.language)}
              icon={<AddIcon style={{ fontSize: 22 }} />}
              buttonType={'transparent'}
              isRound={true}
              style={{ height: 36 }}
            />
          </div>
        </div>
      )}

      {(state.newAlertSelected || state.existingAlertSelected) && !state.isLoading && (
        <div className='full-size-container'>

          {state.currentFilterExpanded &&
            <div className='filter-section-empty-background' onClick={() => dispatch({ type: 'RESET_FILTER_EXPANSION' })} />
          }

          <div className='alerts-view-filters-container' style={{ height: (state.newAlertSelected || state.isEditAlertView) ? '94vh' : 70 }}>
            <div className='alerts-view-top-row'>
              <IconButton
                onClick={handleArrowBackClick}
                icon={<ArrowBackIcon style={{ fontSize: 24 }} />}
                size={30}
                style={{ position: 'absolute', marginLeft: 30 }}
                startWhite={true}
              />

              {(state.newAlertSelected || state.showEditAlertView) && (
                <div style={{ position: 'relative', width: 300, margin: 'auto', paddingTop: 25 }}>
                  <InputField
                    value={state.alertName}
                    placeholder={translate('provideNameOfSearch', userConfig?.language)}
                    onChange={onChangeInputField}
                    resetValue={() => dispatch({ type: 'SET_ALERT_NAME', payload: '' })}
                  />

                  {state.showAlertNameRequired && (
                    <div className='alerts-view-name-required'>
                      *
                    </div>
                  )}
                </div>
              )}

              {state.existingAlertSelected && !state.isEditAlertView && !state.showExcludedPlayersView && (
                <div className='alerts-view-name fade-in'>
                  {state.existingAlertSelected.name}
                </div>
              )}

              {state.existingAlertSelected && !state.isEditAlertView && state.showExcludedPlayersView && (
                <div className='alerts-view-name fade-in'>
                  {translate('playersRemovedFromSearch', userConfig?.language)}
                </div>
              )}

              {state.existingAlertSelected && !state.isEditAlertView && (
                <div className='fade-in' style={{ position: 'absolute', right: 85 }}>
                  <IconButton
                    onClick={handleBlockIconClick}
                    icon={!state.isExcludedPlayersView ? <BlockIcon style={{ fontSize: 18 }} /> : <CloseIcon style={{ fontSize: 22 }} />}
                    size={30}
                    iconColor={state.playerWasJustExcluded ? '#ff3d3d' : state.playerExclusionWasJustUndone ? '#17c33f' : undefined}
                    startWhite={true}
                    title={!state.isExcludedPlayersView ? translate('showPlayersRemovedFromSearch', userConfig?.language) : undefined}
                  />
                </div>
              )}

              {state.existingAlertSelected && !state.isExcludedPlayersView && (
                <div className='fade-in' style={{ position: 'absolute', right: 35 }}>
                  <IconButton
                    onClick={handleEditAlertIconClick}
                    icon={!state.isEditAlertView ? <EditNoteIcon style={{ fontSize: 22, marginRight: -3 }} /> : <CloseIcon style={{ fontSize: 22 }} />}
                    size={30}
                    startWhite={true}
                    title={!state.isEditAlertView ? translate('editSearch', userConfig?.language) : undefined}
                  />
                </div>
              )}
            </div>

            {(state.newAlertSelected || state.showEditAlertView) && (
              <div className='alerts-view-filters-section'>
                <div className='alerts-view-filters'>
                  <AlertFilters
                    state={state}
                    dispatch={dispatch}
                  />

                  {state.showEditAlertView && (
                    <div className='alert-view-filters-toggle-section'>
                      <IconButton
                        icon={<InfoOutlinedIcon style={{ fontSize: 16 }} />}
                        onClick={() => openTextModal(
                          translate('resetPlayers', userConfig?.language),
                          translate('resetExcludedPlayersInfo', userConfig?.language),
                          { infoType: 'infoIcon', titleKey: 'resetPlayers' },
                        )}
                        size={24}
                        style={{ position: 'absolute', left: -32 }}
                      />

                      {translate('resetExcludedPlayers', userConfig?.language)}
                      <div className='alert-view-filters-toggle'>
                        <Toggle
                          isToggled={state.isResetExcludedPlayersToggled}
                          setIsToggled={(value: boolean) => dispatch({ type: 'SET_IS_RESET_EXCLUDED_PLAYERS_TOGGLED', payload: value })}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className='alerts-view-submit-section'>

                  {state.errorMessage && (
                    <div className='alerts-view-error-message'>
                      {state.errorMessage}
                    </div>
                  )}

                  {state.existingAlertSelected && (
                    <TextButton
                      onClick={() => !state.submitButtonDisabled ? handleDeleteAlert() : null}
                      text={translate('deleteSearch', userConfig?.language)}
                      icon={<DeleteOutlineIcon style={{ fontSize: 21 }} />}
                      buttonType={'transparent'}
                      style={{ width: 210 }}
                    />
                  )}

                  <TextButton
                    onClick={() => !state.submitButtonDisabled ? handleAddOrEditAlert() : null}
                    text={translate(state.isEditAlertView ? 'confirmEdits' : 'createSearch', userConfig?.language)}
                    icon={state.isEditAlertView ? <CheckIcon style={{ fontSize: 21 }} /> : <ArrowRightAltIcon style={{ fontSize: 21 }} />}
                    buttonType={'transparent'}
                    style={{ width: 210 }}
                  />

                </div>
              </div>
            )}
          </div>

          {state.existingAlertSelected && (
            <div className='alerts-view-table-section' style={{ top: state.isEditAlertView ? '94vh' : 70 }}>

              {state.tableData.length > 0 && (
                <AlertTable
                  data={state.tableData}
                  isExcludedPlayersView={state.showExcludedPlayersView}
                  excludePlayer={excludePlayer}
                  undoExcludePlayer={undoExcludePlayer}
                  excludeAllPlayers={excludAllPlayers}
                  undoAllExcludedPlayers={undoAllExcludedPlayers}
                  selectedOrderBy={state.showExcludedPlayersView ? undefined : state.existingAlertSelected.filters.sortBy}
                  selectedRoles={state.selectedRoles}
                  currentPage={state.currentPage}
                  totalHits={state.totalHits}
                  handleChangeCurrentPage={handleChangeCurrentPage}
                />
              )}

              {state.isEmptySearchResult && (
                <div className='alerts-view-empty-search-result'>
                  {translate(state.isExcludedPlayersView ? 'noPlayersRemovedFromSearch' : 'noNewPlayersMatchingTheSearch', userConfig?.language)}
                </div>
              )}
            </div>
          )}

        </div>
      )}

      {state.isLoading && (
        <div className='full-size-container'>
          <div className='loading-spinner loading-spinner-small' />
        </div>
      )}
    </div>
  );
};
