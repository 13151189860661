import '../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  ClauseCondition,
  TotalSubCondition,
  SellOnCondition,
  TotalCondition,
  SeasonCondition,
  SeasonSubCondition,
  SeasonOrCondition,
  DatedValue,
  ConditionOption,
  ConditionType,
  ConditionCompetitionString,
  ConditionSeasonString,
} from '../../../types';

import { translate } from '../../../../common/language/translations';
import { useEffect, useState } from 'react';
import { CompetitionDropDown } from '../../controls/dropDowns/CompetitionDropDown';
import { SeasonDropDown } from '../../controls/dropDowns/SeasonDropDown';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { deepCloneObject, generateRandomId, getOrdinalSuffix, stringArraysContainSameElements } from '../../../utils/utils';
import { Toggle } from '../../controls/input/Toggle';
import { Dialog } from '@mui/material';
import { SubConditionModal } from './SubConditionModal';
import { ConditionDropDown } from '../../controls/dropDowns/ConditionDropDown';
import { ClauseState, SubClauseState } from '../../documents/clause/clauseState';
import { getCurrentSellOnPercentage, getYearOfFirstNextSeason } from '../../../utils/clauseUtils';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { DateInputField } from '../../controls/input/DateInputField';
import { InputField } from '../../controls/input/InputField';
import { TextArea } from '../../controls/input/TextArea';
import { modalPaperProps } from '../globalModals/GlobalModals';
import { dateStringToLocaleFormat, getAdjustedDate } from '../../../utils/dateUtils';


interface ConditionModalProps {
  closeModal: () => void;

  conditionToEdit: ClauseCondition | undefined;
  state: ClauseState;

  addOrEditCondition: (newCondition: ClauseCondition) => void;
  deleteCondition: () => void;

  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}


export const ConditionModal: React.FC<ConditionModalProps> = ({
  closeModal,

  conditionToEdit,
  state,

  addOrEditCondition,
  deleteCondition,

  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {

  // the modal will depend on different clause state data depending on the activeSubClauseTab
  const stateAccessor: ClauseState | SubClauseState = state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);

  const { openConfirmModal, openTextModal } = useOpenGlobalModal();

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);

  const [conditionType, setConditionType] = useState<ConditionType | undefined>(conditionToEdit?.conditionType);

  const [commentString, setCommentString] = useState<string>(conditionToEdit?.comment ?? '');

  const [isConditionDropDownExpanded, setIsConditionDropDownExpanded] = useState(false);
  const [isCompetitionDropDownExpanded, setIsCompetitionDropDownExpanded] = useState(false);
  const [isSeasonDropDownExpanded, setIsSeasonDropDownExpanded] = useState(false);

  const totalConditionOptions: ConditionOption[] = ['matchesStarted', 'matchesPlayed', 'minutesPlayed', 'goalsScored'];
  const seasonConditionOptions: ConditionOption[] = ['matchesStarted', 'matchesPlayed', 'minutesPlayed', 'goalsScored', 'tablePosition', 'other'];
  const [selectedConditionOption, setSelectedConditionOption] = useState<ConditionOption[]>([]);
  const [minuteRequirementPerMatch, setMinuteRequirementPerMatch] = useState('');

  const [selectedCompetitions, setSelectedCompetitions] = useState<number[]>([]);
  const [selectedCompetitionString, setSelectedCompetitionString] = useState<ConditionCompetitionString>('');

  const [seasonOptions, setSeasonOptions] = useState<string[]>([]);
  const [selectedSeasons, setSelectedSeasons] = useState<string[]>([]);
  const [selectedSeasonString, setSelectedSeasonString] = useState<ConditionSeasonString>('');

  const [showNewTotalConditionThresholds, setShowNewTotalConditionThresholds] = useState(conditionToEdit !== undefined);
  const [showSeasonConditionsSection, setShowSeasonConditionsSection] = useState(conditionToEdit !== undefined);

  const [newConditionThreshold, setNewConditionThreshold] = useState('');


  // total condition
  const [totalSubConditions, setTotalSubConditions] = useState<TotalSubCondition[]>([]);
  const [totalSubConditionIndexToEdit, setTotalSubConditionIndexToEdit] = useState<number | undefined>(undefined);
  const [newTotalConditionAmount, setNewTotalConditionAmount] = useState('');


  // season condition
  const [numberOfSeasonConditionAmounts, setNumberOfSeasonConditionAmounts] = useState<number | undefined>(undefined);
  const [seasonCommonSubConditions, setSeasonCommonSubConditions] = useState<SeasonSubCondition[]>([]);
  const [seasonOrConditions, setSeasonOrConditions] = useState<SeasonOrCondition[]>([]);

  const [standardSeasonConditionAmount, setStandardSeasonConditionAmount] = useState('');
  const [advancedSeasonConditionAmounts, setAdvancedSeasonConditionAmounts] = useState<string[]>([]);
  const [seasonConditionMaxAmount, setSeasonConditionMaxAmount] = useState('');
  const [seasonSubConditionIndexToEdit, setSeasonSubConditionIndexToEdit] = useState<number | undefined>(undefined); // index within a subCondition list

  const [isSeasonSubConditionModalOpen, setIsSeasonSubConditionModalOpen] = useState(false);
  const [seasonOrConditionIndexOfModal, setSeasonOrConditionIndexOfModal] = useState<number | undefined>(undefined);


  // sell-on condition
  const [staticSellOnPercentage, setStaticSellOnPercentage] = useState('');
  const [dynamicSellOnPercentage, setDynamicSellOnPercentage] = useState('');
  const [dynamicSellOnPercentageDate, setDynamicSellOnPercentageDate] = useState(
    stateAccessor.transferDate ? new Date(stateAccessor.transferDate) : new Date()
  );
  const [dynamicSellOnPercentages, setDynamicSellOnPercentages] = useState<DatedValue[]>([]);
  const [sellOnIsNet, setSellOnIsNet] = useState(true);

  const [dynamicSellOnPercentageIndexToEdit, setDynamicSellOnPercentageIndexToEdit] = useState<number | undefined>(undefined);


  const handleSetDynamicSellOnPercentageDate = (date: Date | null) => {
    if (!date) {
      setDynamicSellOnPercentageDate(new Date());
      return;
    }
    const adjustedDate = getAdjustedDate(date);
    setDynamicSellOnPercentageDate(adjustedDate);
  };


  const removeDropDownExpansions = () => {
    setIsConditionDropDownExpanded(false);
    setIsCompetitionDropDownExpanded(false);
    setIsSeasonDropDownExpanded(false);
  };


  const deleteDynamicSellOnPercentage = (index: number) => {
    const newDynamicSellOnPercentages = [...dynamicSellOnPercentages];
    newDynamicSellOnPercentages.splice(index, 1);
    setDynamicSellOnPercentages(newDynamicSellOnPercentages);
  };


  const handleEditDynamicSellOnPercentageIconClick = (index: number) => {
    setDynamicSellOnPercentageIndexToEdit(index);
    setDynamicSellOnPercentage(dynamicSellOnPercentages[index].value.toString());
    setDynamicSellOnPercentageDate(new Date(dynamicSellOnPercentages[index].date));
  };


  const cancelEditDynamicSellOnPercentage = () => {
    setDynamicSellOnPercentageIndexToEdit(undefined);
    setMessageKey(undefined);
    setDynamicSellOnPercentage('');
    setDynamicSellOnPercentageDate(stateAccessor.transferDate ? new Date(stateAccessor.transferDate) : new Date());
  };


  const addOrEditDynamicSellOnPercentage = () => {
    if (!dynamicSellOnPercentage) {
      setMessageKey('invalidSellOnPercentage');
      return;
    }

    const percentage = Number(dynamicSellOnPercentage.trim().replaceAll('%', '').replaceAll(',', '.'));

    if (isNaN(percentage) || percentage <= 0 || percentage > 100) {
      setMessageKey('invalidSellOnPercentage');
      return;
    }

    const date = dynamicSellOnPercentageDate.toISOString().split('T')[0];

    let dateAlreadyExists = false;
    dynamicSellOnPercentages.forEach((dynamicSellOnPercentage, index) => {
      if (dynamicSellOnPercentage.date === date) {
        if (index !== dynamicSellOnPercentageIndexToEdit) {
          dateAlreadyExists = true;
          return;
        }
      }
    });
    if (dateAlreadyExists) {
      setMessageKey('percentageWithDateAlreadyExists');
      return;
    }

    setMessageKey(undefined);

    if (dynamicSellOnPercentageIndexToEdit !== undefined) {
      const newDynamicSellOnPercentages = [...dynamicSellOnPercentages];
      newDynamicSellOnPercentages[dynamicSellOnPercentageIndexToEdit] = {
        date: date,
        value: percentage,
      };
      newDynamicSellOnPercentages.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });
      setDynamicSellOnPercentages(newDynamicSellOnPercentages);
      setDynamicSellOnPercentageIndexToEdit(undefined);
      setDynamicSellOnPercentage('');
      return;
    }

    const newDynamicSellOnPercentages = [...dynamicSellOnPercentages];
    const newDatedValue = {
      date: date,
      value: percentage,
    };
    newDynamicSellOnPercentages.push(newDatedValue);
    newDynamicSellOnPercentages.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
    setDynamicSellOnPercentages(newDynamicSellOnPercentages);
    setDynamicSellOnPercentage('');
  };


  const addOrEditTotalSubCondition = () => {
    if (!newConditionThreshold) {
      setMessageKey('youMustProvideThresholdAndAmount');
      return;
    }

    const threshold = Number(newConditionThreshold);
    const amount = Number(newTotalConditionAmount.replaceAll('.', ''));

    if (isNaN(amount) || isNaN(threshold) || threshold <= 0 || amount <= 0) {
      setMessageKey('invalidThresholdOrAmount');
      return;
    }

    if (totalSubConditions.find(t => t.threshold === threshold) && totalSubConditionIndexToEdit === undefined) {
      setMessageKey('thresholdAlreadyExists');
      return;
    }

    setMessageKey(undefined);

    const newCondition: TotalSubCondition = {
      threshold: threshold,
      amount: amount,
      isResolved: false,
    };

    if (totalSubConditionIndexToEdit !== undefined) {
      // we keep the isReolved status if the threshold is the same
      if (totalSubConditions[totalSubConditionIndexToEdit].isResolved && totalSubConditions[totalSubConditionIndexToEdit].threshold === threshold) {
        newCondition.isResolved = true;
      }

      const newTotalSubConditions = [...totalSubConditions];
      newTotalSubConditions[totalSubConditionIndexToEdit] = newCondition;
      newTotalSubConditions.sort((a, b) => {
        return a.threshold - b.threshold;
      });
      setTotalSubConditions(newTotalSubConditions);
      setTotalSubConditionIndexToEdit(undefined);
      setNewTotalConditionAmount('');
      setNewConditionThreshold('');
      return;
    }

    setNewConditionThreshold('');

    const newTotalSubConditions = [...totalSubConditions, newCondition];
    newTotalSubConditions.sort((a, b) => {
      return a.threshold - b.threshold;
    });

    setTotalSubConditions(newTotalSubConditions);
  };


  const addOrEditSeasonSubCondition = (seasonOrConditionIndex: number) => {
    if (numberOfSeasonConditionAmounts === undefined) return;

    if (selectedConditionOption.length === 0) {
      setMessageKey('youMustSelectCondition');
      return;
    }

    let newThreshold: string | number = newConditionThreshold;

    if (selectedConditionOption[0] === 'matchesPlayed' || selectedConditionOption[0] === 'matchesStarted' || selectedConditionOption[0] === 'minutesPlayed') {
      if (!newConditionThreshold) {
        setMessageKey('youMustProvideThresholdOrPercentage');
        return;
      }

      const thresholdIsPercentage = newConditionThreshold.includes('%');
      const threshold = Number(newConditionThreshold.replaceAll('%', '').replaceAll(' ', ''));

      if (isNaN(threshold) || threshold < 0 || (thresholdIsPercentage && threshold > 100)) {
        setMessageKey('invalidThresholdOrPercentage');
        return;
      }

      newThreshold = thresholdIsPercentage ? (threshold + '%') : threshold;
    }

    else if (selectedConditionOption[0] === 'goalsScored' || selectedConditionOption[0] === 'tablePosition') {
      if (!newConditionThreshold) {
        setMessageKey(selectedConditionOption[0] === 'goalsScored' ? 'youMustProvideThreshold' : 'youMustProvideTablePosition');
        return;
      }

      const threshold = Number(newConditionThreshold.trim());

      if (isNaN(threshold) || threshold < 0) {
        setMessageKey(selectedConditionOption[0] === 'goalsScored' ? 'invalidThreshold' : 'invalidTablePosition');
        return;
      }

      newThreshold = threshold;
    }

    else if (selectedConditionOption[0] === 'other') {
      if (!newConditionThreshold) {
        setMessageKey('youMustProvideCustomCondition');
        return;
      }
    }

    const newSubCondition: SeasonSubCondition = {
      condition: selectedConditionOption[0],
      threshold: newThreshold,
    };

    if (minuteRequirementPerMatch && (selectedConditionOption[0] === 'matchesPlayed' || selectedConditionOption[0] === 'matchesStarted')) {
      newSubCondition.minMinutesPlayed = Number(minuteRequirementPerMatch);
    }

    if (seasonOrConditionIndex === -1) {
      if (seasonSubConditionIndexToEdit !== undefined) {
        const newSeasonCommonSubConditions = [...seasonCommonSubConditions];
        newSeasonCommonSubConditions[seasonSubConditionIndexToEdit] = newSubCondition;
        setSeasonCommonSubConditions(newSeasonCommonSubConditions);
      }
      else {
        setSeasonCommonSubConditions([...seasonCommonSubConditions, newSubCondition]);
      }
    }
    else {
      const newSeasonOrConditions = [...seasonOrConditions];
      if (newSeasonOrConditions.length === 0) {
        for (let i = 0; i < numberOfSeasonConditionAmounts; i++) {
          newSeasonOrConditions.push({ amount: 0, subConditions: [] });
        }
      }
      if (seasonSubConditionIndexToEdit !== undefined) {
        newSeasonOrConditions[seasonOrConditionIndex].subConditions[seasonSubConditionIndexToEdit] = newSubCondition;
        setSeasonOrConditions(newSeasonOrConditions);
      }
      else {
        newSeasonOrConditions[seasonOrConditionIndex].subConditions.push(newSubCondition);
        setSeasonOrConditions(newSeasonOrConditions);
      }
    }

    if (isSeasonSubConditionModalOpen) {
      closeSeasonSubConditionModal();
    }
    else {
      setSelectedConditionOption([]);
      setNewConditionThreshold('');
      setMinuteRequirementPerMatch('');
      setSeasonSubConditionIndexToEdit(undefined);
    }
    setMessageKey(undefined);
    return;
  };


  const deleteTotalConditionThreshold = (index: number) => {
    const newTotalSubConditions = [...totalSubConditions];
    newTotalSubConditions.splice(index, 1);
    setTotalSubConditions(newTotalSubConditions);
  };


  const handleEditTotalConditionThresholdIconClick = (index: number) => {
    setTotalSubConditionIndexToEdit(index);
    setNewConditionThreshold(totalSubConditions[index].threshold.toString());
    setNewTotalConditionAmount(totalSubConditions[index].amount.toString());
  };


  const cancelEditTotalConditionThreshold = () => {
    setTotalSubConditionIndexToEdit(undefined);
    setMessageKey(undefined);
    setNewConditionThreshold('');
    setNewTotalConditionAmount('');
  };


  const deleteSeasonSubCondition = (orConditionIndex: number, subConditionIndex: number) => {
    if (orConditionIndex === -1) {
      const newSeasonCommonSubConditions = [...seasonCommonSubConditions];
      newSeasonCommonSubConditions.splice(subConditionIndex, 1);
      setSeasonCommonSubConditions(newSeasonCommonSubConditions);
    }
    else {
      const newSeasonOrConditions = [...seasonOrConditions];
      newSeasonOrConditions[orConditionIndex].subConditions.splice(subConditionIndex, 1);
      setSeasonOrConditions(newSeasonOrConditions);
    }
  };


  const handleEditSeasonSubConditionIconClick = (orConditionIndex: number, subConditionIndex: number) => {
    setSeasonSubConditionIndexToEdit(subConditionIndex);
    if (orConditionIndex === -1) {
      setSelectedConditionOption([seasonCommonSubConditions[subConditionIndex].condition]);
      setNewConditionThreshold(seasonCommonSubConditions[subConditionIndex].threshold.toString());
      setMinuteRequirementPerMatch(seasonCommonSubConditions[subConditionIndex].minMinutesPlayed?.toString() ?? '');
    }
    else {
      setSelectedConditionOption([seasonOrConditions[orConditionIndex].subConditions[subConditionIndex].condition]);
      setNewConditionThreshold(seasonOrConditions[orConditionIndex].subConditions[subConditionIndex].threshold.toString());
      setMinuteRequirementPerMatch(seasonOrConditions[orConditionIndex].subConditions[subConditionIndex].minMinutesPlayed?.toString() ?? '');
    }

    if (numberOfSeasonConditionAmounts && numberOfSeasonConditionAmounts > 1) {
      setSeasonOrConditionIndexOfModal(orConditionIndex);
      setIsSeasonSubConditionModalOpen(true);
    }
  };


  const cancelEditSeasonSubCondition = () => {
    setSeasonSubConditionIndexToEdit(undefined);
    setMessageKey(undefined);
    setSelectedConditionOption([]);
    setNewConditionThreshold('');
  };


  const handleAddSeasonSubConditionIconClick = (orConditionIndex: number) => {
    setSeasonOrConditionIndexOfModal(orConditionIndex);
    setIsSeasonSubConditionModalOpen(true);
    setMessageKey(undefined);
  };


  const closeSeasonSubConditionModal = () => {
    setIsSeasonSubConditionModalOpen(false);
    setMessageKey(undefined);
    setTimeout(() => {
      setSeasonOrConditionIndexOfModal(undefined);
      setSeasonSubConditionIndexToEdit(undefined);
      setSelectedConditionOption([]);
      setNewConditionThreshold('');
      setMinuteRequirementPerMatch('');
    }, 150);
  };


  const onChangeAmountInputField = (
    event: React.ChangeEvent<HTMLInputElement>,
    amountSetter: (amount: string) => void,
    shouldNotReplaceDots?: boolean
  ) => {
    setMessageKey(undefined);
    const value = shouldNotReplaceDots
      ? event.target.value
      : event.target.value.replaceAll('.', '');
    amountSetter(value);
  };


  const onChangeAdvancedAmountInputField = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (numberOfSeasonConditionAmounts === undefined) return;

    setMessageKey(undefined);
    const newAmounts = [...advancedSeasonConditionAmounts];
    if (newAmounts.length === 0) {
      for (let i = 0; i < numberOfSeasonConditionAmounts; i++) {
        newAmounts.push('');
      }
    }
    newAmounts[index] = event.target.value;
    setAdvancedSeasonConditionAmounts(newAmounts);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent, conditionType: ConditionType, seasonOrConditionIndex?: number, isDynamicSellOn?: boolean) => {
    if (event.key === 'Enter') {
      if (conditionType === 'sellOn') {
        if (isDynamicSellOn) {
          addOrEditDynamicSellOnPercentage();
        }
        else {
          confirmAddOrEditCondition();
        }
      }
      else if (conditionType === 'total') {
        addOrEditTotalSubCondition();
      }
      else if (conditionType === 'season') {
        if (seasonOrConditionIndex !== undefined) {
          addOrEditSeasonSubCondition(seasonOrConditionIndex);
        }
        else {
          confirmAddOrEditCondition();
        }
      }
    }
  };


  const getSeasonThresholdInputPlaceholder = () => {
    if (selectedConditionOption.length === 0) return '';

    if (selectedConditionOption[0] === 'matchesPlayed' || selectedConditionOption[0] === 'matchesStarted' || selectedConditionOption[0] === 'minutesPlayed') {
      return translate('amountOrPercentage', userConfig?.language);
    }

    if (selectedConditionOption[0] === 'goalsScored') {
      return translate('threshold', userConfig?.language);
    }

    if (selectedConditionOption[0] === 'tablePosition') {
      return translate('minimumTablePosition', userConfig?.language);
    }

    if (selectedConditionOption[0] === 'other') {
      return translate('provideCustomCondition', userConfig?.language);
    }

    return '';
  };


  const getAndValidateSellOnPercentages = () => {
    if (
      (staticSellOnPercentage && dynamicSellOnPercentages.length > 0) ||
      (!staticSellOnPercentage && dynamicSellOnPercentages.length === 0)
    ) {
      return ['youMustProvideEitherStaticOrDynamicSellOnPercentage', undefined, undefined];
    }

    if (staticSellOnPercentage) {
      const staticNumberPercentage = Number(staticSellOnPercentage.trim().replaceAll('%', '').replaceAll(',', '.'));

      if (isNaN(staticNumberPercentage) || staticNumberPercentage <= 0 || staticNumberPercentage > 100) {
        return ['invalidSellOnPercentage', undefined, undefined];
      }

      return [undefined, staticNumberPercentage, undefined];
    }

    const currentSellOn = getCurrentSellOnPercentage(dynamicSellOnPercentages);

    return [undefined, currentSellOn, dynamicSellOnPercentages];
  };


  const confirmAddOrEditCondition = () => {

    if (conditionType === 'sellOn') {
      const [message, currentSellOn, dynamicSellOn] = getAndValidateSellOnPercentages() as [string | undefined, number, DatedValue[] | undefined];

      if (message) {
        setMessageKey(message);
        return;
      }

      const sellOnCondition: SellOnCondition = {
        conditionType: conditionType,
        comment: commentString.trim(),
        conditionId: conditionToEdit ? conditionToEdit.conditionId : generateRandomId(),

        isNet: sellOnIsNet,
        isResolved: conditionToEdit ? (conditionToEdit as SellOnCondition).isResolved : false,

        sellOnPercentage: currentSellOn,
      };

      if (dynamicSellOn) {
        sellOnCondition.dynamicSellOnPercentages = dynamicSellOn;
      }

      addOrEditCondition(sellOnCondition);
    }

    if (conditionType === 'total') {

      const totalCondition: TotalCondition = {
        conditionType: conditionType,
        comment: commentString.trim(),
        conditionId: conditionToEdit ? conditionToEdit.conditionId : generateRandomId(),

        competitionIds: selectedCompetitions.sort(),
        competitionString: selectedCompetitionString,

        condition: selectedConditionOption[0],
        subConditions: totalSubConditions,
      };

      if (minuteRequirementPerMatch && (selectedConditionOption[0] === 'matchesPlayed' || selectedConditionOption[0] === 'matchesStarted')) {
        totalCondition.minMinutesPlayed = Number(minuteRequirementPerMatch);
      }

      const currentValue = (conditionToEdit as TotalCondition)?.currentValue;
      if (currentValue !== undefined) {
        totalCondition.currentValue = currentValue;
      }

      addOrEditCondition(totalCondition);
    }

    if (conditionType === 'season') {

      let amount: number | undefined = undefined;
      let commonSubConditions: SeasonSubCondition[] | undefined = undefined;
      let orConditions: SeasonOrCondition[] | undefined = undefined;

      let maxAmount: number | undefined = undefined;
      if (seasonConditionMaxAmount) {
        maxAmount = Number(seasonConditionMaxAmount.replaceAll('.', ''));

        if (isNaN(maxAmount) || maxAmount <= 0) {
          setMessageKey('invalidAmounts');
          return;
        }
      }

      // if no max amount is provided and a subset of seasons is selected, we add a virtual max amount
      else if (selectedSeasonString !== 'allSeasons') {
        const maxAmountPerSeason = numberOfSeasonConditionAmounts === 1
          ? Number(standardSeasonConditionAmount.replaceAll('.', ''))
          : Math.max(...advancedSeasonConditionAmounts.map(amount => Number(amount.replaceAll('.', ''))) ?? 0);

        maxAmount = maxAmountPerSeason * selectedSeasons.length;
      }

      if (numberOfSeasonConditionAmounts === 1) {
        amount = Number(standardSeasonConditionAmount.replaceAll('.', ''));

        if (isNaN(amount) || amount <= 0) {
          setMessageKey('invalidAmounts');
          return;
        }

        if (seasonCommonSubConditions.length === 0) {
          setMessageKey('youMustProvideSubConditions');
          return;
        }
      }

      else {
        const allAmountsAreValid = advancedSeasonConditionAmounts.length === numberOfSeasonConditionAmounts && advancedSeasonConditionAmounts.every(amount => {
          const amountNumber = Number(amount.replaceAll('.', ''));
          return !isNaN(amountNumber) && amountNumber > 0;
        });

        if (!allAmountsAreValid) {
          setMessageKey('invalidAmounts');
          return;
        }

        const allOrConditionsAreValid = seasonOrConditions.length === numberOfSeasonConditionAmounts &&
          seasonOrConditions.every(orCondition => orCondition.subConditions.length > 0);

        if (!allOrConditionsAreValid) {
          setMessageKey('youMustProvideSubConditionsForEachAmount');
          return;
        }

        // add all amounts to the corresponding orCondition and sort on amount
        orConditions = seasonOrConditions.map((orCondition, index) => {
          return {
            amount: Number(advancedSeasonConditionAmounts[index].replaceAll('.', '')),
            subConditions: orCondition.subConditions,
          };
        });
        if (!conditionToEdit) {
          orConditions.sort((a, b) => {
            return b.amount - a.amount;
          });
        }

        // sort the subConditions within each orCondition
        orConditions.forEach(orCondition => {
          orCondition.subConditions.sort((a) => {
            return a.condition === 'other' ? 1 : -1;
          });
        });
      }

      if (seasonCommonSubConditions.length > 0) {
        commonSubConditions = seasonCommonSubConditions;
        commonSubConditions.sort((a) => {
          return a.condition === 'other' ? 1 : -1;
        });
      }

      const seasonCondition: SeasonCondition = {
        conditionType: conditionType,
        comment: commentString.trim(),
        conditionId: conditionToEdit ? conditionToEdit.conditionId : generateRandomId(),

        competitionIds: selectedCompetitions.sort(),
        competitionString: selectedCompetitionString,

        seasonStrings: selectedSeasons.sort(),
        seasonString: selectedSeasonString,

        seasonToCurrentValues: conditionToEdit ? deepCloneObject((conditionToEdit as SeasonCondition).seasonToCurrentValues) : {},
        seasonToCurrentStatuses: conditionToEdit ? deepCloneObject((conditionToEdit as SeasonCondition).seasonToCurrentStatuses) : {},

        numberOfAmounts: Number(numberOfSeasonConditionAmounts),
      };

      if (amount !== undefined) {
        seasonCondition.amount = amount;
      }

      if (maxAmount !== undefined) {
        seasonCondition.maxAmount = maxAmount;
      }

      if (commonSubConditions !== undefined) {
        seasonCondition.commonSubConditions = commonSubConditions;
      }

      if (orConditions !== undefined) {
        seasonCondition.orConditions = orConditions;
      }

      addOrEditCondition(seasonCondition);
    }
  };


  const isButtonDisabled = () => {

    if (!hasUnsavedChanges) return true;

    if (!conditionType) return true;

    if (conditionType === 'sellOn') {
      return (
        (staticSellOnPercentage === '' && dynamicSellOnPercentages.length === 0) ||
        (staticSellOnPercentage !== '' && dynamicSellOnPercentages.length > 0)
      );
    }

    if (conditionType === 'total') {
      return selectedConditionOption.length === 0 || selectedCompetitions.length === 0 || totalSubConditions.length === 0;
    }

    if (conditionType === 'season') {
      if (selectedSeasons.length === 0 || selectedCompetitions.length === 0) return true;

      if (numberOfSeasonConditionAmounts === 1) {
        return seasonCommonSubConditions.length === 0 || standardSeasonConditionAmount === '';
      }
      else {
        return !numberOfSeasonConditionAmounts ||
          advancedSeasonConditionAmounts.length !== numberOfSeasonConditionAmounts ||
          seasonOrConditions.length !== numberOfSeasonConditionAmounts ||
          seasonOrConditions.some(orCondition => orCondition.subConditions.length === 0);
      }
    }

    return true;
  };


  const resetClauseModal = (resetConditionType: boolean) => {
    setCommentString('');
    setSelectedConditionOption([]);
    setMinuteRequirementPerMatch('');
    setSelectedCompetitions([]);
    setSelectedCompetitionString('');
    setSelectedSeasons([]);
    setSelectedSeasonString('');
    setNewConditionThreshold('');

    setTotalSubConditions([]);
    setTotalSubConditionIndexToEdit(undefined);
    setNewTotalConditionAmount('');

    setNumberOfSeasonConditionAmounts(undefined);
    setSeasonCommonSubConditions([]);
    setSeasonOrConditions([]);
    setStandardSeasonConditionAmount('');
    setSeasonConditionMaxAmount('');
    setAdvancedSeasonConditionAmounts([]);
    setSeasonSubConditionIndexToEdit(undefined);

    setSellOnIsNet(true);
    setStaticSellOnPercentage('');
    setDynamicSellOnPercentage('');
    setDynamicSellOnPercentageDate(stateAccessor.transferDate ? new Date(stateAccessor.transferDate) : new Date());
    setDynamicSellOnPercentages([]);

    setShowNewTotalConditionThresholds(false);
    setShowSeasonConditionsSection(false);
    setMessageKey(undefined);
    setHasUnsavedChanges(false);

    if (resetConditionType) {
      setConditionType(undefined);
    }
  };


  const handleArrowBackIconClick = () => {
    const resetConditionType = !(conditionType === 'season' && numberOfSeasonConditionAmounts);

    if (hasUnsavedChanges) {
      openConfirmModal(
        () => resetClauseModal(resetConditionType),
        'discardCondition?',
        'conditionWillNotBeSaved'
      );
    }
    else {
      resetClauseModal(resetConditionType);
    }
  };


  const handleNewConditionClick = (conditionType: ConditionType) => {
    if (conditionType === 'sellOn' && stateAccessor.sellOnCondition) {
      setMessageKey('sellOnConditionAlreadyExists');
      return;
    }

    setConditionType(conditionType);
    setMessageKey(undefined);
  };


  const buyingClub = stateAccessor.buyingClubId !== undefined ? state.additionalClauseState.allClubs[stateAccessor.buyingClubId] : undefined;


  useEffect(() => {
    if (buyingClub && stateAccessor.transferDate && selectedCompetitions) {

      let isCrossYearCompetition: boolean = (
        competitions &&
        buyingClub.current_competition_id !== undefined &&
        competitions[buyingClub.current_competition_id] &&
        competitions[buyingClub.current_competition_id].crossYear
      ) ?? false;

      // if any competitions are selected, it may affect the season options
      if (selectedCompetitions.length > 0) {
        // if the buying club is not cross year (Norwegian clubs), but only cross year competitions are selected, the options must be cross year
        if (!isCrossYearCompetition) {
          const onlyEuropeanCupsSelected = selectedCompetitions.every(id => (id === 100000 || (competitions[id] && competitions[id].countryCode === 'EU')));
          isCrossYearCompetition = onlyEuropeanCupsSelected;
        }
      }

      const firstYear = getYearOfFirstNextSeason(new Date(stateAccessor.transferDate), isCrossYearCompetition);
      const seasons = [];
      for (let i = 0; i < 10; i++) {
        const season = isCrossYearCompetition
          ? (String(firstYear + i).substring(2)) + '/' + (String(firstYear + i + 1).substring(2))
          : firstYear + i;
        seasons.push(String(season));
      }

      setSeasonOptions(seasons);

      // if all seasons are selected, we 're-select' all seaons in case the options have changed
      if (selectedSeasonString === 'allSeasons') {
        setSelectedSeasons(seasons);
      }
      else {
        // If a full-year season is selected, but a crossYear competition is selected or is default, the selected seasons must be reset
        // If a crossYear season is selected, but a non-crossYear competition is selected or is default, the selected seasons must be reset
        const fullYearSeasonSelectedForCrossYearCompetition = isCrossYearCompetition && selectedSeasons.length > 0 && !selectedSeasons[0].includes('/');
        const crossYearSeasonSelectedForFullYearCompetition = !isCrossYearCompetition && selectedSeasons.length > 0 && selectedSeasons[0].includes('/');
        if (fullYearSeasonSelectedForCrossYearCompetition || crossYearSeasonSelectedForFullYearCompetition) {
          setSelectedSeasons([]);
          setSelectedSeasonString('');
        }
      }
    }
  }, [stateAccessor.buyingClubId, stateAccessor.transferDate, selectedCompetitions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (conditionType === 'total' && !showNewTotalConditionThresholds && !isConditionDropDownExpanded && !isCompetitionDropDownExpanded) {
      if (selectedConditionOption.length > 0 && selectedCompetitions.length > 0) {
        setShowNewTotalConditionThresholds(true);
      }
    }
  }, [
    isConditionDropDownExpanded,
    isCompetitionDropDownExpanded,
    competitions,
    conditionType,
    showNewTotalConditionThresholds,
    selectedConditionOption.length,
    selectedCompetitions.length
  ]);


  useEffect(() => {
    if (conditionType === 'season' && !showSeasonConditionsSection && !isSeasonDropDownExpanded && !isCompetitionDropDownExpanded) {
      if (selectedSeasons.length > 0 && selectedCompetitions.length > 0) {
        setShowSeasonConditionsSection(true);
      }
    }
  }, [
    isSeasonDropDownExpanded,
    isCompetitionDropDownExpanded,
    conditionType,
    showSeasonConditionsSection,
    selectedSeasons.length,
    selectedCompetitions.length
  ]);


  useEffect(() => {
    if (conditionType === 'total') {
      if (conditionToEdit) {
        const totalConditionToEdit = conditionToEdit as TotalCondition;
        if (
          selectedConditionOption.length === 0 ||
          selectedConditionOption[0] !== totalConditionToEdit.condition ||
          minuteRequirementPerMatch !== (totalConditionToEdit.minMinutesPlayed?.toString() ?? '') ||
          selectedCompetitions.length === 0 ||
          !stringArraysContainSameElements(selectedCompetitions.map(id => String(id)), (totalConditionToEdit.competitionIds.map(id => String(id)) ?? [])) ||
          JSON.stringify(totalSubConditions) !== JSON.stringify(totalConditionToEdit.subConditions)
        ) {
          setHasUnsavedChanges(true);
          return;
        }
      }
      else {
        if (selectedConditionOption.length > 0 || selectedCompetitions.length > 0 || totalSubConditions.length > 0) {
          setHasUnsavedChanges(true);
          return;
        }
      }
    }

    if (conditionType === 'season') {
      if (conditionToEdit) {
        const seasonConditionToEdit = conditionToEdit as SeasonCondition;
        if (
          selectedCompetitions.length === 0 ||
          selectedSeasons.length === 0 ||
          !stringArraysContainSameElements(selectedCompetitions.map(id => String(id)), (seasonConditionToEdit.competitionIds.map(id => String(id)) ?? [])) ||
          !stringArraysContainSameElements(selectedSeasons, seasonConditionToEdit.seasonStrings) ||
          JSON.stringify(seasonCommonSubConditions) !== JSON.stringify(seasonConditionToEdit.commonSubConditions ?? []) ||
          JSON.stringify(seasonOrConditions) !== JSON.stringify(seasonConditionToEdit.orConditions ?? []) ||
          JSON.stringify(advancedSeasonConditionAmounts) !== JSON.stringify(
            (seasonConditionToEdit.orConditions ?? []).map(orCondition => orCondition.amount.toString())
          ) ||
          standardSeasonConditionAmount !== (seasonConditionToEdit.amount ? seasonConditionToEdit.amount.toString() : '') ||
          seasonConditionMaxAmount !== (seasonConditionToEdit.maxAmount?.toString() ?? '')
        ) {
          setHasUnsavedChanges(true);
          return;
        }
      }
      else {
        if (
          selectedCompetitions.length > 0 ||
          selectedSeasons.length > 0 ||
          seasonCommonSubConditions.length > 0 ||
          seasonOrConditions.length > 0 ||
          advancedSeasonConditionAmounts.length > 0 ||
          standardSeasonConditionAmount !== '' ||
          seasonConditionMaxAmount !== ''
        ) {
          setHasUnsavedChanges(true);
          return;
        }
      }
    }

    if (conditionType === 'sellOn') {
      if (conditionToEdit) {
        const sellOnConditionToEdit = conditionToEdit as SellOnCondition;
        if (sellOnIsNet !== sellOnConditionToEdit.isNet) {
          setHasUnsavedChanges(true);
          return;
        }

        if (sellOnConditionToEdit.dynamicSellOnPercentages) {
          if (!stringArraysContainSameElements(
            dynamicSellOnPercentages.map(dsp => dsp.date + dsp.value),
            sellOnConditionToEdit.dynamicSellOnPercentages.map(dsp => dsp.date + dsp.value)
          )) {
            setHasUnsavedChanges(true);
            return;
          }
        }
        else {
          if (Number(staticSellOnPercentage) !== sellOnConditionToEdit.sellOnPercentage) {
            setHasUnsavedChanges(true);
            return;
          }
        }

      }
      else {
        if (staticSellOnPercentage !== '' || dynamicSellOnPercentages.length > 0) {
          setHasUnsavedChanges(true);
          return;
        }
      }
    }

    if (commentString !== (conditionToEdit?.comment ?? '')) {
      setHasUnsavedChanges(true);
      return;
    }

    setHasUnsavedChanges(false);
  }, [
    conditionType,
    conditionToEdit,
    commentString,
    selectedCompetitions,
    selectedConditionOption,
    selectedSeasons,
    minuteRequirementPerMatch,

    totalSubConditions,

    seasonCommonSubConditions,
    seasonOrConditions,
    advancedSeasonConditionAmounts,
    standardSeasonConditionAmount,
    seasonConditionMaxAmount,

    staticSellOnPercentage,
    dynamicSellOnPercentages,
    sellOnIsNet,
    setHasUnsavedChanges,
  ]);


  useEffect(() => {
    if (conditionToEdit) {
      if (conditionToEdit.conditionType === 'sellOn') {
        const sellOnCondition = conditionToEdit as SellOnCondition;
        setSellOnIsNet(sellOnCondition.isNet);

        if (sellOnCondition.dynamicSellOnPercentages) {
          setDynamicSellOnPercentages(sellOnCondition.dynamicSellOnPercentages);
        }
        else {
          setStaticSellOnPercentage(sellOnCondition.sellOnPercentage.toString());
        }
      }

      if (conditionToEdit.conditionType === 'total') {
        const totalCondition = conditionToEdit as TotalCondition;
        setSelectedConditionOption([totalCondition.condition]);
        setSelectedCompetitions(totalCondition.competitionIds);
        setSelectedCompetitionString(totalCondition.competitionString);
        setTotalSubConditions(totalCondition.subConditions);

        if (totalCondition.minMinutesPlayed) {
          setMinuteRequirementPerMatch(totalCondition.minMinutesPlayed.toString());
        }
      }

      if (conditionToEdit.conditionType === 'season') {
        const seasonCondition = conditionToEdit as SeasonCondition;
        setSelectedSeasons(seasonCondition.seasonStrings);
        setSelectedSeasonString(seasonCondition.seasonString);
        setSelectedCompetitions(seasonCondition.competitionIds);
        setSelectedCompetitionString(seasonCondition.competitionString);
        setNumberOfSeasonConditionAmounts(seasonCondition.numberOfAmounts);
        setSeasonCommonSubConditions(seasonCondition.commonSubConditions ?? []);
        setStandardSeasonConditionAmount(seasonCondition.amount ? seasonCondition.amount.toString() : '');
        setSeasonConditionMaxAmount(seasonCondition.maxAmount?.toString() ?? '');
        setAdvancedSeasonConditionAmounts((seasonCondition.orConditions ?? []).map(orCondition => orCondition.amount.toString()));

        if (seasonCondition.orConditions) {
          const orConditionsCopy = deepCloneObject(seasonCondition.orConditions);
          setSeasonOrConditions(orConditionsCopy);
        }
      }
    }
  }, [conditionToEdit]);


  const getSeasonSubConditionTextDisplay = (subCondition: SeasonSubCondition) => {
    if (!userConfig) return '';

    if (subCondition.condition === 'other') {
      return subCondition.threshold;
    }

    let text = subCondition.threshold;

    if (subCondition.condition === 'tablePosition') {
      const ordinalSuffix = getOrdinalSuffix(Number(subCondition.threshold), userConfig.language);
      return text + ordinalSuffix + ' ' + translate('placeInTheLeague', userConfig.language, true);
    }

    if (subCondition.threshold.toString().includes('%')) {
      text += ' ' + translate('<percentage>of', userConfig.language);
    }

    text += ' ' + translate(subCondition.condition, userConfig.language, true);

    if (subCondition.minMinutesPlayed) {
      text += ' (' + translate('atLeast<threshold>', userConfig.language) + ' ' + subCondition.minMinutesPlayed + ' min)';
    }

    return text;
  };


  const showConditionContent = conditionType && (conditionType !== 'season' || numberOfSeasonConditionAmounts !== undefined);


  return (
    <div className='modal-root-container condition-modal-root'>

      <Dialog
        open={isSeasonSubConditionModalOpen}
        onClose={() => closeSeasonSubConditionModal()}
        PaperProps={modalPaperProps}
      >
        <SubConditionModal
          newConditionThreshold={newConditionThreshold}
          setNewConditionThreshold={setNewConditionThreshold}

          minuteRequirementPerMatch={minuteRequirementPerMatch}
          setMinuteRequirementPerMatch={setMinuteRequirementPerMatch}

          addOrEditSeasonSubCondition={addOrEditSeasonSubCondition}
          closeModal={closeSeasonSubConditionModal}

          seasonOrConditionIndex={seasonOrConditionIndexOfModal}
          isEdit={seasonSubConditionIndexToEdit !== undefined}
          messageKey={messageKey}

          seasonConditionOptions={seasonConditionOptions}
          selectedConditionOption={selectedConditionOption}
          setSelectedConditionOption={setSelectedConditionOption}
          isConditionDropDownExpanded={isConditionDropDownExpanded}
          setIsConditionDropDownExpanded={setIsConditionDropDownExpanded}

          onKeyDownInputField={onKeyDownInputField}
          getSeasonThresholdInputPlaceholder={getSeasonThresholdInputPlaceholder}
        />
      </Dialog>

      {(isConditionDropDownExpanded || isCompetitionDropDownExpanded || isSeasonDropDownExpanded) && (
        <div className='modal-empty-background' style={{ zIndex: 10 }} onClick={() => removeDropDownExpansions()} />
      )}

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      {conditionType && !conditionToEdit && (
        <IconButton
          icon={<ArrowBackIcon style={{ fontSize: 24 }} />}
          onClick={handleArrowBackIconClick}
          size={30}
          style={{ position: 'absolute', top: 12, left: 12 }}
          startWhite={true}
        />
      )}

      <div className='modal-root-title'>
        {
          translate(conditionToEdit ? 'edit' : 'add', userConfig?.language) + ' ' +
          translate(conditionType ? (conditionType + 'Condition') : 'condition', userConfig?.language, true)
        }
      </div>

      <div className='modal-divider modal-root-title-divider' />

      {buyingClub && !stateAccessor.transferDate && (
        <div className='condition-modal-no-club-selected-title'>
          {translate('noDateSelectedForCondition', userConfig?.language)}
        </div>
      )}

      {!buyingClub && stateAccessor.transferDate && (
        <div className='condition-modal-no-club-selected-title'>
          {translate('noClubSelectedForCondition', userConfig?.language)}
        </div>
      )}

      {!buyingClub && !stateAccessor.transferDate && (
        <div className='condition-modal-no-club-selected-title'>
          {translate('noClubOrDateSelectedForCondition', userConfig?.language)}
        </div>
      )}

      {buyingClub && stateAccessor.transferDate && (
        <div className='modal-content-container'>

          {!conditionType && (
            <div className='modal-content-section' style={{ margin: 'auto', maxHeight: 500, minHeight: 260, padding: '40px 0px' }}>
              <div className='condition-modal-new-condition-button'>
                <IconButton
                  onClick={() => openTextModal(
                    translate('totalCondition', userConfig?.language),
                    translate('totalConditionInfo', userConfig?.language),
                    { infoType: 'infoIcon', titleKey: 'totalCondition' }
                  )}
                  icon={<InfoOutlinedIcon style={{ fontSize: 18 }} />}
                  size={24}
                  style={{ position: 'absolute', left: -40 }}
                />

                <TextButton
                  onClick={() => handleNewConditionClick('total')}
                  text={translate('new', userConfig?.language) + ' ' + translate('totalCondition', userConfig?.language, true)}
                  icon={<AddIcon style={{ fontSize: 22 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 230 }}
                />
              </div>

              <div className='condition-modal-new-condition-button'>
                <IconButton
                  onClick={() => openTextModal(
                    translate('totalCondition', userConfig?.language),
                    translate('totalConditionInfo', userConfig?.language),
                    { infoType: 'infoIcon', titleKey: 'totalCondition' }
                  )}
                  icon={<InfoOutlinedIcon style={{ fontSize: 18 }} />}
                  size={24}
                  style={{ position: 'absolute', left: -40 }}
                />

                <TextButton
                  onClick={() => handleNewConditionClick('season')}
                  text={translate('new', userConfig?.language) + ' ' + translate('seasonCondition', userConfig?.language, true)}
                  icon={<AddIcon style={{ fontSize: 22 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 230 }}
                />
              </div>

              <TextButton
                onClick={() => handleNewConditionClick('sellOn')}
                text={translate('new', userConfig?.language) + ' ' + translate('sellOnCondition', userConfig?.language, true)}
                icon={<AddIcon style={{ fontSize: 22 }} />}
                buttonType={'transparent'}
                isRound={true}
                style={{ width: 230 }}
              />

              {messageKey && (
                <div className='condition-modal-sell-on-condition-exists-message'>
                  {translate(messageKey, userConfig?.language)}
                </div>
              )}
            </div>
          )}

          {conditionType === 'season' && numberOfSeasonConditionAmounts === undefined && (
            <div className='modal-content-section' style={{ minHeight: 360 }}>
              <div className='condition-modal-season-amounts-title'>
                {translate('selectNumberOfSeasonConditionAmounts', userConfig?.language)}
              </div>

              <div className='condition-modal-season-amounts-standard-section'>
                <div className='condition-modal-season-amounts-section-title'>
                  <IconButton
                    onClick={() => openTextModal(
                      translate('standardSeasonCondition', userConfig?.language),
                      translate('standardSeasonConditionInfo', userConfig?.language),
                      { infoType: 'infoIcon', titleKey: 'standardSeasonCondition' }
                    )}
                    icon={<InfoOutlinedIcon style={{ fontSize: 18 }} />}
                    size={24}
                    style={{ position: 'absolute', left: -40 }}
                  />
                  {translate('standardSeasonCondition', userConfig?.language)}
                </div>

                <TextButton
                  onClick={() => setNumberOfSeasonConditionAmounts(1)}
                  text={'1 ' + translate('amount', userConfig?.language, true)}
                  icon={<ArrowRightAltIcon style={{ fontSize: 21 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 200, height: 30 }}
                />
              </div>

              <div className='condition-modal-season-amounts-advanced-section'>
                <div className='condition-modal-season-amounts-section-title'>
                  <IconButton
                    onClick={() => openTextModal(
                      translate('advancedSeasonCondition', userConfig?.language),
                      translate('advancedSeasonConditionInfo', userConfig?.language),
                      { infoType: 'infoIcon', titleKey: 'advancedSeasonCondition' }
                    )}
                    icon={<InfoOutlinedIcon style={{ fontSize: 18 }} />}
                    size={24}
                    style={{ position: 'absolute', left: -40 }}
                  />
                  {translate('advancedSeasonCondition', userConfig?.language)}
                </div>
                {[2, 3, 4, 5].map(numberOfAmounts => {
                  return (
                    <div key={numberOfAmounts} style={{ margin: 'auto' }}>
                      <TextButton
                        onClick={() => setNumberOfSeasonConditionAmounts(numberOfAmounts)}
                        text={numberOfAmounts + ' ' + translate('amount', userConfig?.language, true)}
                        icon={<ArrowRightAltIcon style={{ fontSize: 21 }} />}
                        buttonType={'transparent'}
                        isRound={true}
                        style={{ width: 200, height: 30 }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {showConditionContent && conditionType === 'sellOn' && (
            <div className='modal-content-section' style={{ paddingTop: 30, paddingBottom: 10 }}>
              {messageKey && (
                <div className='condition-modal-sell-on-percentage-message'>
                  {translate(messageKey, userConfig?.language)}
                </div>
              )}

              <div className='condition-modal-sell-on-percentage-section'>
                {userConfig ? translate('staticSellOnPercentage', userConfig.language) : ''}

                <InputField
                  value={staticSellOnPercentage}
                  placeholder={translate('percentage', userConfig?.language) + '?'}
                  onChange={(event) => onChangeAmountInputField(event, setStaticSellOnPercentage, true)}
                  onKeyDown={(event) => onKeyDownInputField(event, 'sellOn')}
                  disabled={dynamicSellOnPercentages.length > 0}
                  showDisabled={true}
                  isSquare={true}
                  height={22}
                  width={120}
                />
              </div>

              <div className='modal-divider-row condition-modal-sell-on-divider'>
                <div className='modal-divider modal-half-divider margin-auto' />
                <div className='modal-divider-text'>
                  {translate('or', userConfig?.language, true)}
                </div>
                <div className='modal-divider modal-half-divider margin-auto' />
              </div>

              <div className='condition-modal-sell-on-percentage-section'>
                {translate('dynamicSellOnPercentage', userConfig?.language)}

                <div className='modal-content-section' style={{ height: 75, minHeight: 75 }}>

                  <div className='payment-modal-new-payment-input-row' style={{ width: 375 }}>
                    <InputField
                      value={dynamicSellOnPercentage}
                      placeholder={translate('percentage', userConfig?.language) + '?'}
                      onChange={(event) => onChangeAmountInputField(event, setDynamicSellOnPercentage, true)}
                      onKeyDown={(event) => onKeyDownInputField(event, 'sellOn', undefined, true)}
                      disabled={staticSellOnPercentage !== ''}
                      showDisabled={true}
                      isSquare={true}
                      height={22}
                      width={120}
                    />

                    <div style={{ textWrap: 'nowrap' }}>
                      {translate('fromInclusive', userConfig?.language)}
                    </div>

                    <DateInputField
                      value={dynamicSellOnPercentageDate}
                      placeholder={translate('selectDate', userConfig?.language)}
                      onChange={handleSetDynamicSellOnPercentageDate}
                      disabled={staticSellOnPercentage !== ''}
                      showDisabled={true}
                      height={22}
                      width={120}
                      minDate={new Date(stateAccessor.transferDate)}
                      maxDate='minDate+10y'
                      popperPlacement='bottom'
                    />
                  </div>

                  <div className='payment-modal-divider' style={{ width: 340 }} />

                  <TextButton
                    onClick={() => addOrEditDynamicSellOnPercentage()}
                    text={translate(dynamicSellOnPercentageIndexToEdit !== undefined ? 'editPercentage' : 'addPercentage', userConfig?.language)}
                    icon={<CheckIcon style={{ fontSize: 20 }} />}
                    buttonType={'transparent'}
                    style={{ height: 25 }}
                    disabled={staticSellOnPercentage !== ''}
                  />
                </div>

                <div className='condition-modal-dynamic-sell-on-percentages'>
                  {dynamicSellOnPercentages.map((datedSellOnPercentage, index) => {
                    return (
                      <div key={index} className='payment-modal-existing-payment' style={{ height: 26, minHeight: 26, marginTop: 5, marginBottom: 5 }}>

                        <IconButton
                          onClick={() => deleteDynamicSellOnPercentage(index)}
                          icon={<DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />}
                          size={24}
                          style={{ position: 'absolute', left: -32 }}
                          title={translate('deleteCondition', userConfig?.language)}
                        />

                        <div className='payment-modal-existing-payment-value' style={{ width: 280 }}>
                          {datedSellOnPercentage.value}%
                          <div style={{ fontSize: 13, color: '#ffffffcc', padding: '0px 10px' }}>
                            {translate('fromInclusive', userConfig?.language)}
                          </div>
                          {dateStringToLocaleFormat(datedSellOnPercentage.date)}
                        </div>

                        <IconButton
                          onClick={
                            () => index === dynamicSellOnPercentageIndexToEdit
                              ? cancelEditDynamicSellOnPercentage()
                              : handleEditDynamicSellOnPercentageIconClick(index)
                          }
                          icon={<EditIcon style={{ fontSize: 17, marginTop: 1 }} />}
                          size={24}
                          isActive={index === dynamicSellOnPercentageIndexToEdit}
                          style={{ position: 'absolute', right: -32 }}
                          title={translate('editCondition', userConfig?.language)}
                        />

                      </div>
                    );
                  })}
                </div>
              </div>

              <div className='condition-modal-sell-on-toggle-row'>
                {translate('netSellOn', userConfig?.language)}

                <div className='condition-modal-sell-on-toggle' style={{ marginTop: 2 }}>
                  <Toggle isToggled={sellOnIsNet} setIsToggled={setSellOnIsNet} />
                </div>
              </div>
            </div>
          )}

          {showConditionContent && conditionType === 'total' && (
            <div className='modal-content-section'>
              {messageKey && (
                <div className='condition-modal-message' style={{ top: 55 }}>
                  {translate(messageKey, userConfig?.language)}
                </div>
              )}

              <div className='condition-modal-drop-down-row' style={{ top: 15 }}>
                <div className='condition-modal-drop-down-column'>
                  <ConditionDropDown
                    id={'clauses-modal-condition-select'}
                    dropDownOptions={totalConditionOptions}
                    selectedOptions={selectedConditionOption}
                    setSelectedOptions={setSelectedConditionOption}
                    isDropDownExpanded={isConditionDropDownExpanded}
                    setIsDropDownExpanded={setIsConditionDropDownExpanded}
                    defaultDropDownText={'selectCondition'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={4}
                    maxHeight={'60vh'}
                    height={26}
                    minuteRequirementPerMatch={minuteRequirementPerMatch}
                    setMinuteRequirementPerMatch={setMinuteRequirementPerMatch}
                  />
                </div>

                <div className='condition-modal-drop-down-column'>
                  <CompetitionDropDown
                    id={'clauses-modal-competition-select'}
                    competitionOptions={buyingClub.possible_competition_ids ?? []}
                    selectedCompetitions={selectedCompetitions}
                    setSelectedCompetitions={setSelectedCompetitions}
                    selectedCompetitionString={selectedCompetitionString}
                    setSelectedCompetitionString={setSelectedCompetitionString}
                    isDropDownExpanded={isCompetitionDropDownExpanded}
                    setIsDropDownExpanded={setIsCompetitionDropDownExpanded}
                    defaultDropDownText={'selectCompetitions'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={3}
                    maxHeight={'60vh'}
                  />
                </div>
              </div>

              {showNewTotalConditionThresholds && (
                <div className='fade-in' style={{ marginTop: 60 }}>
                  <div className='condition-modal-new-total-condition-section'>

                    <div className='modal-content-section'>
                      <div className='payment-modal-new-payment-input-row'>
                        <InputField
                          value={newConditionThreshold}
                          placeholder={translate('threshold', userConfig?.language)}
                          onChange={(event) => setNewConditionThreshold(event.target.value)}
                          onKeyDown={(event) => onKeyDownInputField(event, 'total')}
                          isSquare={true}
                          height={22}
                          width={120}
                        />
                        <InputField
                          value={getDisplayEconomicValue(newTotalConditionAmount)}
                          placeholder={translate('amount', userConfig?.language)}
                          onChange={(event) => onChangeAmountInputField(event, setNewTotalConditionAmount)}
                          onKeyDown={(event) => onKeyDownInputField(event, 'total')}
                          isSquare={true}
                          height={22}
                          width={120}
                        />
                      </div>

                      <div className='payment-modal-divider' />

                      <TextButton
                        onClick={addOrEditTotalSubCondition}
                        text={translate(totalSubConditionIndexToEdit !== undefined ? 'editCondition' : 'addCondition', userConfig?.language)}
                        icon={<CheckIcon style={{ fontSize: 20 }} />}
                        buttonType={'transparent'}
                        style={{ height: 26 }}
                      />
                    </div>
                  </div>

                  <div className='condition-modal-total-conditions'>
                    {totalSubConditions.map((subCondition, index) => {
                      return (
                        <div key={index} className='payment-modal-existing-payment'>

                          <IconButton
                            onClick={() => deleteTotalConditionThreshold(index)}
                            icon={<DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />}
                            size={24}
                            style={{ position: 'absolute', left: -32 }}
                            title={translate('deleteCondition', userConfig?.language)}
                          />

                          <div className='payment-modal-existing-payment-value' style={{ width: 160 }}>
                            {selectedConditionOption.length > 0 ? subCondition.threshold : '-'}
                            {' ' + (selectedConditionOption.length > 0 ? translate(selectedConditionOption[0], userConfig?.language, true) : '')}
                          </div>

                          <div className='payment-modal-existing-payment-value' style={{ width: 160 }}>
                            {getDisplayEconomicValue(subCondition.amount, true)}
                          </div>

                          <IconButton
                            onClick={
                              () => index === totalSubConditionIndexToEdit
                                ? cancelEditTotalConditionThreshold()
                                : handleEditTotalConditionThresholdIconClick(index)
                            }
                            icon={<EditIcon style={{ fontSize: 17, marginTop: 1 }} />}
                            size={24}
                            isActive={index === totalSubConditionIndexToEdit}
                            style={{ position: 'absolute', right: -32 }}
                            title={translate('editCondition', userConfig?.language)}
                          />

                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}

          {showConditionContent && conditionType === 'season' && (
            <div className='modal-content-section'>
              {messageKey && !isSeasonSubConditionModalOpen && (
                <div className='condition-modal-message' style={{ top: 52 }}>
                  {translate(messageKey, userConfig?.language)}
                </div>
              )}

              <div className='condition-modal-drop-down-row' style={{ top: 15 }}>
                <div className='condition-modal-drop-down-column'>
                  <SeasonDropDown
                    id={'clauses-modal-season-select'}
                    seasonOptions={seasonOptions}
                    selectedSeasons={selectedSeasons}
                    setSelectedSeasons={setSelectedSeasons}
                    selectedSeasonString={selectedSeasonString}
                    setSelectedSeasonString={setSelectedSeasonString}
                    isDropDownExpanded={isSeasonDropDownExpanded}
                    setIsDropDownExpanded={setIsSeasonDropDownExpanded}
                    defaultDropDownText={'selectSeasons'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={3}
                    maxHeight={'60vh'}
                  />
                </div>

                <div className='condition-modal-drop-down-column'>
                  <CompetitionDropDown
                    id={'clauses-modal-competition-select'}
                    competitionOptions={buyingClub.possible_competition_ids ?? []}
                    selectedCompetitions={selectedCompetitions}
                    setSelectedCompetitions={setSelectedCompetitions}
                    selectedCompetitionString={selectedCompetitionString}
                    setSelectedCompetitionString={setSelectedCompetitionString}
                    isDropDownExpanded={isCompetitionDropDownExpanded}
                    setIsDropDownExpanded={setIsCompetitionDropDownExpanded}
                    defaultDropDownText={'selectCompetitions'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={3}
                    maxHeight={'60vh'}
                  />
                </div>
              </div>

              {showSeasonConditionsSection && (
                <div className='modal-content-section' style={{ marginTop: 60 }}>

                  {numberOfSeasonConditionAmounts === 1 && (
                    <div className='modal-content-section'>
                      <div className='condition-modal-new-total-condition-section'>
                        <div className='modal-content-section'>
                          <div className='condition-modal-season-condition-input-row'>

                            <div style={{ width: 240 }}>
                              <ConditionDropDown
                                id={'clauses-modal-condition-select'}
                                dropDownOptions={seasonConditionOptions}
                                selectedOptions={selectedConditionOption}
                                setSelectedOptions={setSelectedConditionOption}
                                isDropDownExpanded={isConditionDropDownExpanded}
                                setIsDropDownExpanded={setIsConditionDropDownExpanded}
                                defaultDropDownText={'selectCondition'}
                                defaultDropDownTextColor={'#000000'}
                                marginBetweenOptions={4}
                                maxHeight={'60vh'}
                                height={26}
                                minuteRequirementPerMatch={minuteRequirementPerMatch}
                                setMinuteRequirementPerMatch={setMinuteRequirementPerMatch}
                              />
                            </div>

                            <InputField
                              value={newConditionThreshold}
                              placeholder={getSeasonThresholdInputPlaceholder()}
                              onChange={(event) => setNewConditionThreshold(event.target.value)}
                              onKeyDown={(event) => onKeyDownInputField(event, 'season', -1)}
                              disabled={selectedConditionOption.length === 0}
                              showDisabled={true}
                              isSquare={true}
                              width={240}
                              height={26}
                            />
                          </div>

                          <div className='payment-modal-divider' style={{ marginTop: 46, width: 500 }} />

                          <TextButton
                            onClick={() => addOrEditSeasonSubCondition(-1)}
                            text={translate(seasonSubConditionIndexToEdit !== undefined ? 'editCondition' : 'addCondition', userConfig?.language)}
                            icon={<CheckIcon style={{ fontSize: 20 }} />}
                            buttonType={'transparent'}
                            style={{ height: 26 }}
                          />
                        </div>
                      </div>

                      <div className='condition-modal-total-conditions'>
                        {seasonCommonSubConditions.map((subCondition, subConditionIndex) => {
                          return (
                            <div
                              key={subConditionIndex}
                              className={
                                'payment-modal-existing-payment' +
                                (subCondition.condition === 'other' ? ' condition-modal-season-condition-other' : '')
                              }
                            >

                              <IconButton
                                onClick={() => deleteSeasonSubCondition(-1, subConditionIndex)}
                                icon={<DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />}
                                size={24}
                                style={{ position: 'absolute', left: -32 }}
                                title={translate('deleteCondition', userConfig?.language)}
                              />

                              <div className='payment-modal-existing-payment-value' style={{ width: 460, boxSizing: 'border-box', padding: 7 }}>
                                {getSeasonSubConditionTextDisplay(subCondition)}
                              </div>

                              <IconButton
                                onClick={
                                  () => subConditionIndex === seasonSubConditionIndexToEdit
                                    ? cancelEditSeasonSubCondition()
                                    : handleEditSeasonSubConditionIconClick(-1, subConditionIndex)
                                }
                                icon={<EditIcon style={{ fontSize: 17, marginTop: 1 }} />}
                                size={24}
                                isActive={subConditionIndex === seasonSubConditionIndexToEdit}
                                style={{ position: 'absolute', right: -32 }}
                                title={translate('editCondition', userConfig?.language)}
                              />

                            </div>
                          );
                        })}
                      </div>

                      <div className='condition-modal-season-condition-amount-container'>
                        <InputField
                          value={getDisplayEconomicValue(standardSeasonConditionAmount)}
                          placeholder={translate('amount', userConfig?.language)}
                          onChange={(event) => onChangeAmountInputField(event, setStandardSeasonConditionAmount)}
                          onKeyDown={(event) => onKeyDownInputField(event, 'season')}
                          isSquare={true}
                          width={160}
                          height={24}
                        />
                        <InputField
                          value={getDisplayEconomicValue(seasonConditionMaxAmount)}
                          placeholder={translate('maxAmount', userConfig?.language) + '?'}
                          onChange={(event) => onChangeAmountInputField(event, setSeasonConditionMaxAmount)}
                          onKeyDown={(event) => onKeyDownInputField(event, 'season')}
                          isSquare={true}
                          width={160}
                          height={24}
                        />
                      </div>
                    </div>
                  )}

                  {numberOfSeasonConditionAmounts !== undefined && numberOfSeasonConditionAmounts > 1 && (
                    <div className='modal-content-section' style={{ paddingTop: 15 }}>

                      <div className='condition-modal-advanced-season-sub-condition-section'>
                        <div className='condition-modal-advanced-season-sub-condition-section-title-row' style={{ marginTop: 0 }}>
                          <div className='condition-modal-advanced-season-sub-condition-section-title-sub-row' style={{ width: 150, marginLeft: 100 }}>
                            {translate('commonConditions', userConfig?.language)}

                            <IconButton
                              onClick={() => handleAddSeasonSubConditionIconClick(-1)}
                              icon={<AddCircleOutlineIcon style={{ fontSize: 18 }} />}
                              size={24}
                              title={translate('addCondition', userConfig?.language)}
                              style={{ marginLeft: 10 }}
                            />
                          </div>

                          <div className='condition-modal-advanced-season-sub-condition-section-title-sub-row' style={{ width: 150, marginLeft: 100 }}>
                            <InputField
                              value={getDisplayEconomicValue(seasonConditionMaxAmount)}
                              placeholder={translate('maxAmount', userConfig?.language) + '?'}
                              onChange={(event) => onChangeAmountInputField(event, setSeasonConditionMaxAmount)}
                              onKeyDown={(event) => onKeyDownInputField(event, 'season')}
                              isSquare={true}
                              height={20}
                            />
                          </div>
                        </div>

                        {seasonCommonSubConditions.map((subCondition, subConditionIndex) => {
                          return (
                            <div
                              key={subConditionIndex}
                              style={{ height: 'auto', minHeight: 26, marginTop: 4, marginBottom: 4 }}
                              className={
                                'payment-modal-existing-payment' +
                                (subCondition.condition === 'other' ? ' condition-modal-season-condition-other' : '')
                              }
                            >

                              <IconButton
                                onClick={() => deleteSeasonSubCondition(-1, subConditionIndex)}
                                icon={<DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />}
                                size={24}
                                style={{ position: 'absolute', left: -32 }}
                                title={translate('deleteCondition', userConfig?.language)}
                              />

                              <div className='payment-modal-existing-payment-value' style={{ width: 460, boxSizing: 'border-box', padding: '5px 7px' }}>
                                {getSeasonSubConditionTextDisplay(subCondition)}
                              </div>

                              <IconButton
                                onClick={
                                  () => subConditionIndex === seasonSubConditionIndexToEdit
                                    ? cancelEditSeasonSubCondition()
                                    : handleEditSeasonSubConditionIconClick(-1, subConditionIndex)
                                }
                                icon={<EditIcon style={{ fontSize: 17, marginTop: 1 }} />}
                                size={24}
                                isActive={subConditionIndex === seasonSubConditionIndexToEdit}
                                style={{ position: 'absolute', right: -32 }}
                                title={translate('editCondition', userConfig?.language)}
                              />

                            </div>
                          );
                        })}
                      </div>

                      {Array.from({ length: numberOfSeasonConditionAmounts * 2 }, (_, index) => index).map((_, orConditionIndex) => {
                        if (orConditionIndex % 2 === 0) {
                          return (
                            <div key={orConditionIndex + '-divider'} className='modal-divider-row'>
                              <div className='modal-divider modal-half-divider margin-auto' />
                              {orConditionIndex !== 0 && (
                                <div className='modal-divider-text'>
                                  {translate('or', userConfig?.language, true)}
                                </div>
                              )}
                              <div className='modal-divider modal-half-divider margin-auto' />
                            </div>
                          );
                        }

                        orConditionIndex = Math.floor(orConditionIndex / 2);

                        return (
                          <div key={orConditionIndex} className='condition-modal-advanced-season-sub-condition-section'>
                            <div className='condition-modal-advanced-season-sub-condition-section-title-row'>
                              <div className='condition-modal-advanced-season-sub-condition-section-title-sub-row' style={{ width: 150, marginLeft: 100 }}>
                                {translate('subConditions', userConfig?.language)}

                                <IconButton
                                  onClick={() => handleAddSeasonSubConditionIconClick(orConditionIndex)}
                                  icon={<AddCircleOutlineIcon style={{ fontSize: 18 }} />}
                                  size={24}
                                  title={translate('addCondition', userConfig?.language)}
                                  style={{ marginLeft: 10 }}
                                />
                              </div>

                              <div className='condition-modal-advanced-season-sub-condition-section-title-sub-row' style={{ width: 150, marginLeft: 100 }}>
                                <InputField
                                  value={
                                    advancedSeasonConditionAmounts.length > 0
                                      ? getDisplayEconomicValue(advancedSeasonConditionAmounts[orConditionIndex])
                                      : ''
                                  }
                                  placeholder={translate('amount', userConfig?.language)}
                                  onChange={(event) => onChangeAdvancedAmountInputField(event, orConditionIndex)}
                                  onKeyDown={(event) => onKeyDownInputField(event, 'season')}
                                  isSquare={true}
                                  height={20}
                                />
                              </div>
                            </div>

                            {seasonOrConditions.length > 0 && seasonOrConditions[orConditionIndex].subConditions.map((subCondition, subConditionIndex) => {
                              return (
                                <div
                                  key={subConditionIndex}
                                  style={{ height: 'auto', minHeight: 26, marginTop: 4, marginBottom: 4 }}
                                  className={
                                    'payment-modal-existing-payment' +
                                    (subCondition.condition === 'other' ? ' condition-modal-season-condition-other' : '')
                                  }
                                >

                                  <IconButton
                                    onClick={() => deleteSeasonSubCondition(orConditionIndex, subConditionIndex)}
                                    icon={<DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />}
                                    size={24}
                                    style={{ position: 'absolute', left: -32 }}
                                    title={translate('deleteCondition', userConfig?.language)}
                                  />

                                  <div className='payment-modal-existing-payment-value' style={{ width: 460, boxSizing: 'border-box', padding: '5px 7px' }}>
                                    {getSeasonSubConditionTextDisplay(subCondition)}
                                  </div>

                                  <IconButton
                                    onClick={
                                      () => subConditionIndex === seasonSubConditionIndexToEdit
                                        ? cancelEditSeasonSubCondition()
                                        : handleEditSeasonSubConditionIconClick(orConditionIndex, subConditionIndex)
                                    }
                                    icon={<EditIcon style={{ fontSize: 17, marginTop: 1 }} />}
                                    size={24}
                                    isActive={subConditionIndex === seasonSubConditionIndexToEdit}
                                    style={{ position: 'absolute', right: -32 }}
                                    title={translate('editCondition', userConfig?.language)}
                                  />

                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {showConditionContent && (
            <div className='condition-modal-text-area-container'>
              <TextArea
                value={commentString ?? ''}
                placeholder={translate(conditionToEdit ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                onChange={(event) => setCommentString(event.target.value)}
              />
            </div>
          )}

          {showConditionContent && (
            <div className='condition-modal-submit-button-row'>
              <TextButton
                onClick={() => confirmAddOrEditCondition()}
                text={
                  translate(conditionToEdit ? 'edit' : 'add', userConfig?.language) + ' ' + translate(conditionType + 'Condition', userConfig?.language, true)
                }
                icon={conditionToEdit ? <CheckIcon style={{ fontSize: 22 }} /> : <AddIcon style={{ fontSize: 22 }} />}
                style={{ width: 256 }}
                disabled={isButtonDisabled()}
              />

              {conditionToEdit && (
                <TextButton
                  onClick={() => openConfirmModal(deleteCondition, 'deleteCondition', 'noRegretInfo')}
                  text={translate('delete', userConfig?.language) + ' ' + translate(conditionType + 'Condition', userConfig?.language, true)}
                  icon={<DeleteOutlineIcon style={{ fontSize: 22 }} />}
                  style={{ width: 256 }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
