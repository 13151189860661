import '../../platform.css';

import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { useSetAndTrackNavigationPropsState } from '../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext } from '../../../common/contexts/useAuthContext';

import { TeamView } from './TeamView';
import { StringToAnyMap, NavigationProps, PlayerEntry, PlayerOverview } from '../../types';
import { addPlayerToTeam, addPlayerToSquad } from '../../services/firestore/teamsOrSquads';
import { AddPlayerView } from '../searchPlayers/AddPlayerView';
import { getCompleteTeamHistory } from '../../services/firestore/activities';
import { TeamHistory } from '../history/TeamHistory';
import { TeamMenu } from './TeamMenu';
import { addPlayerWithoutData, setPlayerProperties } from '../../services/firestore/players';
import { userHasFullAccess } from '../../utils/userUtils';


export interface LastDraggedOwnTeamPlayerData {
  playerId: number;
  playerName: string;
  wasAdded: boolean;
}


interface LogisticTeamViewProps {
  teamOrSquadId: string | undefined;
  teamOrSquad: StringToAnyMap | undefined;

  isTabLine: boolean;
  isSquad?: boolean;

  showFirstEleven?: boolean;
  setShowFirstEleven?: (isToggled: boolean) => void;
  benchPlayers?: StringToAnyMap[];

  logisticTeamViewToggle?: boolean; // when toggled, expansions will be closed
}

export const LogisticTeamView: React.FC<LogisticTeamViewProps> = ({
  teamOrSquadId,
  teamOrSquad,

  isTabLine,
  isSquad,

  showFirstEleven,
  setShowFirstEleven,
  benchPlayers,

  logisticTeamViewToggle,
}) => {

  const { currentUser } = useAuthContext();

  const userConfig = useRecoilValue(userConfigState);
  const hasFullAccess = userHasFullAccess(userConfig);
  const teams = useRecoilValue(teamsState);
  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};

  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();
  const setPlayerOverviews = useSetRecoilState(playerOverviewsState);
  const { openConfirmModal } = useOpenGlobalModal();

  const [teamHistory, setTeamHistory] = useState<StringToAnyMap[] | undefined>(undefined);

  const [addPlayerSearchString, setAddPlayerSearchString] = useState('');
  const [isAddPlayerWithoutData, setIsAddPlayerWithoutData] = useState(false);

  const [isAddPlayerViewExpanded, setIsAddPlayerViewExpanded] = useState(false);
  const [rightSectionExpansionType, setRightSectionExpansionType] = useState<string | undefined>(undefined);

  const [draggedPlayer, setDraggedPlayer] = useState<StringToAnyMap | undefined>(undefined);
  const [droppedPlayer, setDroppedPlayer] = useState<StringToAnyMap | undefined>(undefined);
  const [playerWasJustDropped, setPlayerWasJustDropped] = useState(false);
  const [lastDraggedOwnTeamPlayerData, setLastDraggedOwnTeamPlayerData] = useState<LastDraggedOwnTeamPlayerData | undefined>(undefined);


  const handlePlayerDrop = async (
    newPosition: string,
    newPositionIndex?: number, // should be provided in all cases except for when newPosition is 'bench'
  ) => {

    if (!userConfig || !draggedPlayer) return;

    disableHover();
    setPlayerWasJustDropped(true);

    let playerId = draggedPlayer['id'];
    const playerPropertiesToSet: Record<string, unknown> = {};

    // this is the only update needed when a player is moved to the bench
    if (newPosition === 'bench') {
      playerPropertiesToSet['isStartingEleven'] = false;
    }

    else if (teamOrSquadId && teamOrSquad) {

      if (showFirstEleven) {
        playerPropertiesToSet['isStartingEleven'] = true;
      }

      const fullname = draggedPlayer['fullname'];
      const currentPosition = draggedPlayer['positionKey'];

      if (currentPosition === 'addPlayerTable' || currentPosition === 'addPlayerTableExistingPlayers') {

        // if current position is addPlayerTable, draggedPlayer will contain the player overview if the dragged player has data
        // we dont need to add the overview here, but doing so will prevent a short flicker of overview data when the player is added to the team
        if (currentPosition === 'addPlayerTable' && !isAddPlayerWithoutData) {
          delete draggedPlayer['positionKey'];
          setPlayerOverviews((currentPlayerOverviews) => ({
            ...currentPlayerOverviews,
            [playerId]: draggedPlayer as PlayerOverview,
          }));
        }

        if (teamOrSquadId === 'ownTeam' && hasFullAccess) {
          if (!isNaN(Number(playerId))) {
            setLastDraggedOwnTeamPlayerData({
              playerId: Number(playerId),
              playerName: fullname,
              wasAdded: currentPosition === 'addPlayerTable',
            });
          }

          // in case this is a player added from the academyTeam, we reset the role
          playerPropertiesToSet['role'] = null;
        }

        if (isAddPlayerWithoutData && currentPosition === 'addPlayerTable') {
          playerId = await addPlayerWithoutData(fullname, userConfig.club, currentUser);
        }

        if (playerId === undefined) return;

        setAddPlayerSearchString('');
      }

      const playerData: PlayerEntry = {
        id: playerId,
        fullname: fullname,
      };

      if (isSquad) {
        addPlayerToSquad(
          playerData,
          newPosition,
          newPositionIndex,
          teamOrSquadId,
          teamOrSquad,
          userConfig.email,
          userConfig.club,
          'dragged',
          currentUser,
        );
      }

      else {
        const currentTeamData = teams && playerId in playerTeamData
          ? {
            teamId: playerTeamData[playerId].currentTeam,
            teamData: teams[playerTeamData[playerId].currentTeam],
            currentPosition: playerTeamData[playerId].currentPosition,
          }
          : undefined;

        addPlayerToTeam(
          playerData,
          newPosition,
          newPositionIndex,
          teamOrSquadId,
          teamOrSquad,
          currentTeamData,
          userConfig.email,
          userConfig.club,
          'dragged',
          currentUser,
        );
      }
    }

    if (Object.keys(playerPropertiesToSet).length > 0) {
      setPlayerProperties(playerId, playerPropertiesToSet, userConfig.club, currentUser);
    }

    setDroppedPlayer({ ...draggedPlayer, droppedPositionKey: newPosition });
    setDraggedPlayer(undefined);
  };


  useEffect(() => {
    setAddPlayerSearchString('');
    setIsAddPlayerWithoutData(false);
  }, [isAddPlayerViewExpanded]);


  useEffect(() => {
    if (teamOrSquad && teamOrSquadId && userConfig?.club) {
      getCompleteTeamHistory(teamOrSquadId, teamOrSquad['history'] ?? [], userConfig.club).then((history) => {
        setTeamHistory(history);
      });
    }
  }, [teamOrSquad, teamOrSquadId, userConfig?.club]);


  useEffect(() => {
    setIsAddPlayerViewExpanded(false);
    setRightSectionExpansionType(undefined);
  }, [logisticTeamViewToggle]);


  const confirmAddClause = () => {
    if (lastDraggedOwnTeamPlayerData) {
      const navigationProps: NavigationProps = {
        activeTab: 'economy',
        activeSubTab: lastDraggedOwnTeamPlayerData.wasAdded ? 1 : 0,
        selectedPlayerId: lastDraggedOwnTeamPlayerData.playerId,
      };
      setAndTrackNavigationProps(navigationProps);
    }
  };


  useEffect(() => {
    if (lastDraggedOwnTeamPlayerData !== undefined) {
      openConfirmModal(
        confirmAddClause,
        'addClause?',
        lastDraggedOwnTeamPlayerData?.wasAdded ? 'addClauseForBoughtPlayerInfo' : 'addClauseForSoldPlayerInfo',
        () => setLastDraggedOwnTeamPlayerData(undefined),
        undefined,
        undefined,
        undefined,
        300,
      );
    }
  }, [lastDraggedOwnTeamPlayerData]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (droppedPlayer) {
      const timer = setTimeout(() => {
        setDroppedPlayer(undefined);
        setPlayerWasJustDropped(false);
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [droppedPlayer]);


  const [tableHoverDisabled, setTableHoverDisabled] = useState(false);
  const disableHover = () => {
    setTableHoverDisabled(true);
    document.addEventListener('mousemove', enableHoverOnMouseMove);
  };
  const enableHoverOnMouseMove = () => {
    setTableHoverDisabled(false);
    document.removeEventListener('mousemove', enableHoverOnMouseMove);
  };


  return (
    <div>
      <div
        className={
          'full-size-container logistic-team-view-section' +
          (isAddPlayerViewExpanded ? ' logistic-team-view-section-left-expanded' : '') +
          (rightSectionExpansionType ? ' logistic-team-view-section-right-expanded' : '')
        }
      >
        {teamOrSquadId && (
          <TeamView
            teamOrSquadId={teamOrSquadId}
            teamOrSquadData={teamOrSquad}
            isSquad={isSquad}

            draggedPlayer={draggedPlayer}
            setDraggedPlayer={setDraggedPlayer}
            handlePlayerDrop={handlePlayerDrop}
            droppedPlayer={droppedPlayer}
            setDroppedPlayer={setDroppedPlayer}
            setLastDraggedOwnTeamPlayerData={setLastDraggedOwnTeamPlayerData}

            isLeftSectionExpanded={isAddPlayerViewExpanded}
            setIsLeftSectionExpanded={setIsAddPlayerViewExpanded}
            rightSectionExpansionType={rightSectionExpansionType}
            setRightSectionExpansionType={setRightSectionExpansionType}

            showFirstEleven={showFirstEleven}
            benchPlayers={benchPlayers}
            tableHoverDisabled={tableHoverDisabled}
          />
        )}
      </div>

      {teamOrSquad && (
        <div>
          <div
            className={
              'logistic-team-view-side-section' +
              (isAddPlayerViewExpanded ? ' logistic-team-view-left-section-expanded' : '')
            }
          >
            {isAddPlayerViewExpanded && (
              <div className='fade-in' style={{ height: isTabLine ? '81vh' : '85vh', marginTop: '6.5vh' }}>
                <AddPlayerView
                  searchString={addPlayerSearchString}
                  setSearchString={setAddPlayerSearchString}

                  squadId={isSquad ? teamOrSquadId : undefined}
                  isOwnTeam={teamOrSquadId === 'ownTeam'}
                  isTabLine={isTabLine}

                  isAddPlayerWithoutData={isAddPlayerWithoutData}
                  setIsAddPlayerWithoutData={setIsAddPlayerWithoutData}

                  draggedPlayer={draggedPlayer}
                  setDraggedPlayer={setDraggedPlayer}
                  playerWasJustDropped={playerWasJustDropped}
                />
              </div>
            )}
          </div>

          <div
            className={
              'logistic-team-view-side-section logistic-team-view-right-section' +
              (rightSectionExpansionType ? ' logistic-team-view-right-section-expanded' : '')
            }
          >
            {rightSectionExpansionType === 'history' && teamHistory && (
              <div className='logistic-team-view-right-expansion-section fade-in' style={{ height: isTabLine ? '81vh' : '85vh', marginTop: '6.5vh' }}>
                <TeamHistory
                  teamOrSquadId={teamOrSquadId}
                  history={teamHistory}
                  isSquad={isSquad}
                />
              </div>
            )}

            {rightSectionExpansionType === 'menu' && teamOrSquadId && (
              <div className='logistic-team-view-right-expansion-section fade-in' style={{ height: isTabLine ? '81vh' : '85vh', marginTop: '6.5vh' }}>
                <TeamMenu
                  teamOrSquadId={teamOrSquadId}
                  teamOrSquadData={teamOrSquad}
                  isSquad={isSquad}

                  showFirstEleven={showFirstEleven}
                  setShowFirstEleven={setShowFirstEleven}
                  benchPlayers={benchPlayers}
                  draggedPlayer={draggedPlayer}
                  setDraggedPlayer={setDraggedPlayer}
                  handlePlayerDrop={handlePlayerDrop}
                  tableHoverDisabled={tableHoverDisabled}
                />
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};
