import './history.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { teamsState } from '../../recoil/atoms/teamsState';

import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DescriptionIcon from '@mui/icons-material/Description';
import MessageIcon from '@mui/icons-material/Message';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap } from '../../types';
import { staticTeamKeys } from '../../static/propertyValues';
import { getShortUserName } from '../../utils/userUtils';
import { dateStringToLocaleFormat } from '../../utils/dateUtils';


interface PlayerHistoryProps {
  history: StringToAnyMap[];
}


export const PlayerHistory: React.FC<PlayerHistoryProps> = ({ history }) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const teams = useRecoilValue(teamsState);

  const [showHistory, setShowHistory] = useState(false);


  const shouldShowDate = (index: number) => {
    if (index === 0) return true;

    const previousDate = history[index - 1]['date'].split('T')[0];
    const currentDate = history[index]['date'].split('T')[0];

    const previousUser = history[index - 1]['userEmail'];
    const currentUser = history[index]['userEmail'];

    return previousDate !== currentDate || previousUser !== currentUser;
  };


  const getHistoryInfoText = (playerAction: StringToAnyMap, actionType: string, isCurrentUser: boolean) => {
    if (!userConfig || !teams) return '';

    const userName = isCurrentUser
      ? translate('you', userConfig.language)
      : allUsersWithSameClub[playerAction['userEmail']]
        ? getShortUserName(allUsersWithSameClub[playerAction['userEmail']].name)
        : translate('unknownUser', userConfig.language);

    if (actionType === 'playerActivity') {

      const fromTeamName: string | undefined = playerAction['fromTeamId']
        ? (staticTeamKeys.includes(playerAction['fromTeamId'])
          ? translate(playerAction['fromTeamId'], userConfig.language)
          : teams[playerAction['fromTeamId']]
            ? teams[playerAction['fromTeamId']]['name']
            : playerAction['fromTeamName'])
        : undefined;

      const toTeamName: string | undefined = playerAction['toTeamId']
        ? (staticTeamKeys.includes(playerAction['toTeamId'])
          ? translate(playerAction['toTeamId'], userConfig.language)
          : teams[playerAction['toTeamId']] ? teams[playerAction['toTeamId']]['name'] : playerAction['toTeamName'])
        : undefined;

      if (fromTeamName && toTeamName) {
        return userName
          + ' '
          + translate('<someone>movedThePlayerFrom<team>', userConfig.language)
          + ' '
          + fromTeamName
          + ' '
          + translate('to<team>', userConfig.language)
          + ' '
          + toTeamName;
      }
      else if (fromTeamName) {
        return userName
          + ' '
          + translate('<someone>removedThePlayerFrom<team>', userConfig.language)
          + ' '
          + fromTeamName;
      }
      else if (toTeamName) {
        return userName
          + ' '
          + translate('<someone>addedThePlayerTo<team>', userConfig.language)
          + ' '
          + toTeamName;
      }

      return actionType;
    }

    if (actionType === 'report') {

      const actionText = playerAction['reportType'] === 'Attachment'
        ? translate('<someone>uploadedAnAttachment', userConfig.language)
        : translate('<someone>leftA<reportType>', userConfig.language) + ' ' + translate(playerAction['reportType'], userConfig.language, true);

      return userName
        + ' '
        + actionText;
    }

    return actionType;
  };


  const scrollToBottom = () => {
    const commentField = document.querySelector('.player-history-action-section');
    if (commentField) {
      commentField.scrollTop = commentField.scrollHeight;
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHistory(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    scrollToBottom();
  }, []);


  return (
    <div className='history-container player-history-container'>

      <div className={'history-action-section player-history-action-section' + (showHistory ? ' history-action-section-show' : '')}>
        {history && history.map((playerAction, index) => {

          const actionType = playerAction['reportType'] ? 'report' : 'playerActivity';

          const isCurrentUser = userConfig !== null && playerAction['userEmail'] === userConfig.email;

          return (
            <div
              key={index}
              className='history-action player-history-action'
              style={{ marginTop: index === 0 ? 0 : undefined }}
            >

              {shouldShowDate(index) && (
                <div className='history-date-row player-history-date-row'>

                  {isCurrentUser && <div className='history-date-large-divider' />}

                  <div className={'history-date-large' + (isCurrentUser ? ' history-date-current-user' : '')}>
                    {dateStringToLocaleFormat(playerAction['date'].split('T')[0])}
                  </div>

                  {!isCurrentUser && <div className='history-date-large-divider' />}

                </div>
              )}

              <div
                className={
                  'player-history-info-text'
                  + (isCurrentUser ? ' player-history-info-text-current-user' : '')
                  + (actionType === 'report' ? (isCurrentUser ? ' player-history-report-info-current-user' : ' player-history-report-info-other-user') : '')
                }
              >
                {getHistoryInfoText(playerAction, actionType, isCurrentUser)}

                {actionType === 'report' && (
                  <div
                    className={
                      'player-history-report-icon' +
                      (isCurrentUser ? ' player-history-report-icon-current-user' : ' player-history-report-icon-other-user')
                    }
                  >
                    {playerAction['reportType'] === 'ScoutingReport' && <PlagiarismIcon style={{ fontSize: 22, color: '#ffffffaa' }} />}
                    {playerAction['reportType'] === 'MatchReport' && <DescriptionIcon style={{ fontSize: 22, color: '#ffffffaa' }} />}
                    {playerAction['reportType'] === 'Comment' && <MessageIcon style={{ fontSize: 21, marginTop: 1, color: '#ffffffaa' }} />}
                    {playerAction['reportType'] === 'Attachment' && <PictureAsPdfIcon style={{ fontSize: 21, marginTop: 1, color: '#ffffffaa' }} />}
                  </div>
                )}
              </div>

            </div>
          );
        })}
      </div>

    </div>
  );
};
