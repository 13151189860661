import '../tables.css';

import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { playerOverviewsState } from '../../../recoil/atoms/playerOverviewsState';
import { useAuthContext } from '../../../../common/contexts/useAuthContext';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { getOwnTeamTableColumns, ownTeamTableBaseColumnsWidth, ownTeamTableEconomyColumnsWidth, ownTeamTablePadding } from './ownTeamColumns';
import { translate } from '../../../../common/language/translations';
import { StringToAnyMap, PlayerEntry, PlayerOverview } from '../../../types';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { Dialog } from '@mui/material';
import { EditPlayerInfoModal } from '../../modals/EditPlayerInfoModal';
import { userHasEconomyAccess } from '../../../utils/userUtils';
import { modalPaperProps } from '../../modals/globalModals/GlobalModals';
import { PlayerInfoCell } from '../tableCells';
import { getClubLightColor } from '../../../static/clubConfigs';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { Toggle } from '../../controls/input/Toggle';
import { getNavigationWidth } from '../../../utils/navigationUtils';


interface OwnTeamTableProps {
  data: StringToAnyMap[];
  tableSortBy: StringToAnyMap | undefined;
  setTableSortBy: (value: StringToAnyMap | undefined) => void;
}

export const OwnTeamTable: React.FC<OwnTeamTableProps> = ({
  data,
  tableSortBy,
  setTableSortBy,
}) => {

  const { currentUser } = useAuthContext();
  const width = useWindowSize().width;

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const [isEditPlayerInfoModalOpen, setIsEditPlayerInfoModalOpen] = useState(false);
  const [editPlayerInfoProperty, setEditPlayerInfoProperty] = useState<string | undefined>(undefined);
  const [playerToEdit, setPlayerToEdit] = useState<PlayerEntry | undefined>(undefined);


  const handleCellClick = (row: StringToAnyMap, property: string, event: React.MouseEvent<HTMLElement>) => {

    if (property === 'fullname') {
      setAndTrackSelectedPlayerState({
        id: row.id,
        fullname: row.fullname,
        playerOverview: !isNaN(Number(row.id)) ? playerOverviews[Number(row.id)] : undefined,
      });
      return;
    }

    event.stopPropagation();

    setPlayerToEdit(row as PlayerEntry);
    setEditPlayerInfoProperty(property);
    setIsEditPlayerInfoModalOpen(true);
  };


  const handleHeaderCellClick = (column: StringToAnyMap) => {
    if (tableSortBy && tableSortBy.id === column.property) {
      if (tableSortBy.desc) {
        setTableSortBy({ id: column.property, desc: false });
      }
      else {
        setTableSortBy(undefined);
      }
    }
    else {
      setTableSortBy({ id: column.property, desc: true });
    }
  };


  const hasEconomyAccess = userHasEconomyAccess(userConfig);
  const ownTeamColumns = useMemo(
    () => {
      return getOwnTeamTableColumns(
        hasEconomyAccess,
        clubSettings?.countryCode ?? 'NO',
        getClubLightColor(userConfig?.club ?? ''),
        userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable,
        userConfig?.language ?? 'en',
      );
    },
    [
      hasEconomyAccess,
      clubSettings?.countryCode,
      userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable,
      userConfig,
    ]
  );

  const tableWidth = width - 24 - getNavigationWidth(userConfig);
  const ownTeamTableStickyColumnWidth = hasEconomyAccess ? 215 : 240;
  const ownTeamTableNonStickyWidth = tableWidth - ownTeamTableStickyColumnWidth;
  const ownTeamTableNonStickyMinWidth = hasEconomyAccess
    ? ownTeamTableBaseColumnsWidth + ownTeamTableEconomyColumnsWidth + ownTeamTablePadding
    : ownTeamTableBaseColumnsWidth + ownTeamTablePadding;
  const widthUnit = Math.max(ownTeamTableNonStickyWidth / ownTeamTableNonStickyMinWidth, 1);


  return (
    <div
      className='table-container player-table-container'
      style={{ overflowX: widthUnit <= 1 ? 'auto' : 'hidden' }}
    >

      <Dialog
        open={isEditPlayerInfoModalOpen}
        onClose={() => setIsEditPlayerInfoModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <EditPlayerInfoModal
          editPlayerInfoProperty={editPlayerInfoProperty}
          closeModal={() => setIsEditPlayerInfoModalOpen(false)}
          playerToEdit={playerToEdit}
          isAcademyPlayer={userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable ?? false}
        />
      </Dialog>

      {/* Header */}
      <div className='table-header own-team-table-header player-table-header-background'>

        <div
          className='table-sticky-left own-team-table-header-toggle-section player-table-header-background'
          style={{ width: ownTeamTableStickyColumnWidth }}
        >
          {translate('showAcademyPlayers', userConfig?.language)}

          {userConfig && (
            <div className='table-header-toggle'>
              <Toggle
                isToggled={userSettings?.seasonStatsToggles?.showAcademyPlayersInOwnTeamTable}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { 'showAcademyPlayersInOwnTeamTable': value },
                  userConfig.email,
                  userConfig.club,
                  currentUser
                )}
                isSmall={true}
              />
            </div>
          )}
        </div>

        {ownTeamColumns.map(column => {

          const paddingRight = column.key === 'teamRole' ? ownTeamTablePadding : 0;
          return (
            <div
              key={column.key}
              className='table-cell'
              style={{ width: column.width * widthUnit, cursor: 'pointer' }}
              onClick={() => handleHeaderCellClick(column)}
            >
              <div
                className='flex-row align-center'
                style={{ paddingRight: paddingRight, position: 'relative' }}
                title={column.title}
              >
                {column.needsEconomyAccess && <AdminPanelSettingsIcon style={{ fontSize: 20, color: '#ffffffee' }} />}
                {translate(column.key, userConfig?.language)}

                {tableSortBy && tableSortBy.id === column.property && (
                  <div style={{ position: 'absolute', right: paddingRight - 22 }}>
                    {tableSortBy.desc
                      ? <KeyboardArrowDownIcon style={{ fontSize: 22, marginBottom: -2 }} />
                      : <KeyboardArrowUpIcon style={{ fontSize: 22, marginBottom: -2 }} />
                    }
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>


      {/* Body */}
      <div>
        {data.map((row, rowIndex) => {

          const isSummaryRow = row.fullname === 'tableSummary';

          return (
            <div key={rowIndex} className={'table-row player-table-row' + (isSummaryRow ? ' own-team-table-summary-row' : '')}>

              <div
                className={
                  'table-sticky-left player-table-sticky-cell player-table-body-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' own-team-table-cell-even' : ' own-team-table-cell-odd')
                  + (isSummaryRow ? ' no-hover' : '')
                }
                style={{ width: ownTeamTableStickyColumnWidth }}
                onClick={(event) => handleCellClick(row, 'fullname', event)}
              >
                {isSummaryRow && (
                  <div className='table-cell flex-column'>
                    <div className='own-team-table-summary-cell-label'>
                      {translate('summary', userConfig?.language)}
                    </div>
                    {row.numberOfPlayers + ' ' + translate('players', userConfig?.language, true)}
                  </div>
                )}

                {!isSummaryRow && (
                  <PlayerInfoCell
                    playerOverview={row as PlayerOverview}
                    language={userConfig?.language}
                    showCountryFlag={false}
                    showClubInfo={false}
                    showTeamIcon={false}
                  />
                )}
              </div>

              {ownTeamColumns.map(column => {
                return (
                  <div
                    key={column.key}
                    className={
                      'table-cell'
                      + (rowIndex % 2 === 0 ? ' own-team-table-cell-even' : ' own-team-table-cell-odd')
                      + (isSummaryRow ? ' no-hover' : '')
                    }
                    title={!isSummaryRow ? translate('edit', userConfig?.language) : ''}
                    style={{ width: column.width * widthUnit }}
                    onClick={(event) => handleCellClick(row, column.property, event)}
                  >
                    {column.cell(row)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
