import './buttons.css';
import { JSX } from 'react';


interface IconButtonProps {
  onClick: (event?: React.MouseEvent) => void;
  icon: JSX.Element | undefined;
  size: number;
  style?: React.CSSProperties;
  startWhite?: boolean; // icon will start with a whiter/brigther color
  backgroundColorBehindIcon?: 'gray' | 'white';
  whiteBackgroundOnHover?: boolean;
  iconColor?: string; // will be used to set temporary color for feedback
  isActive?: boolean; // border will be added
  title?: string;
  hoverText?: string;
  hoverStyle?: React.CSSProperties;
  isMobile?: boolean;
  noMobileFeedback?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  icon,
  size,
  style,
  startWhite,
  backgroundColorBehindIcon,
  whiteBackgroundOnHover,
  iconColor,
  isActive,
  title,
  hoverText,
  hoverStyle,
  isMobile,
  noMobileFeedback,
}) => {


  const containerClassName = (
    isMobile
      ? ('icon-button-container-mobile' + (!noMobileFeedback ? ' icon-button-container-mobile-feedback' : ''))
      : (
        'icon-button-container'
        + (startWhite ? ' icon-button-container-white' : '')
        + (whiteBackgroundOnHover ? ' icon-button-container-whiteBackgroundOnHover' : '')
        + (isActive ? ' icon-button-container-active' : '')
        + (backgroundColorBehindIcon ? ' icon-button-container-' + backgroundColorBehindIcon + '-background' : '')
      )
  );


  return (
    <div
      className={containerClassName}
      style={{
        ...style,
        width: size,
        height: size,
        color: iconColor,
      }}
      onClick={(event) => onClick(event)}
      title={title}
    >
      {icon}

      {hoverText && (
        <div
          className='icon-button-hover-text'
          style={{
            top: (size - 22) / 2,
            left: !(hoverStyle?.left || hoverStyle?.right) ? size + 6 : undefined,
            ...hoverStyle,
          }}
        >
          {hoverText}
        </div>
      )}
    </div>
  );
};
