import { FirebaseUser } from '../../firebase';
import { trackEvent } from '../services/server/analytics/trackEvent';


// open pdf in new tab
export const openPdfInNewTab = (fileUrl: string, fileName: string, documentType: 'reports' | 'clauses', currentUser: FirebaseUser) => {

  trackEvent('FileViewed', { documentType: documentType }, currentUser, 'user');

  const newTab = window.open();

  if (newTab) {
    // Create an iframe element
    const iframe = document.createElement('iframe');
    iframe.src = fileUrl;
    iframe.style.width = '100%';
    iframe.style.height = '100vh';
    iframe.style.border = 'none';

    // Create a div or container to wrap the iframe (optional, for styling purposes)
    const container = newTab.document.createElement('div');
    container.style.margin = '0';
    container.style.padding = '0';
    container.style.height = '100%';

    // Append iframe to the container
    container.appendChild(iframe);

    // Append container to the new tab's body
    newTab.document.body.style.margin = '0';
    newTab.document.body.style.padding = '0';
    newTab.document.body.appendChild(container);

    // Optional: set the title of the new tab
    newTab.document.title = fileName;
  }
};


// get shortened file name
export const getShortenedFileName = (fileName: string, maxLength?: number) => {
  maxLength = maxLength ?? 32;
  if (fileName.length > maxLength) {
    const halfMaxLength = Math.round(maxLength / 2) - 1;
    return fileName.slice(0, halfMaxLength) + '...' + fileName.slice(fileName.length - halfMaxLength, fileName.length);
  } else {
    return fileName;
  }
};
