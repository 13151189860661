import '../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { TextButton } from '../../controls/buttons/TextButton';


interface ConfirmModalProps {
  confirm: () => void;
  cancel: () => void;
  confirmTitleKey: string;
  confirmInfoKey?: string;
  confirmInfo?: string;
  confirmKey?: string;
  cancelKey?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  confirm,
  cancel,
  confirmTitleKey,
  confirmInfoKey,
  confirmInfo,
  confirmKey,
  cancelKey
}) => {

  const userConfig = useRecoilValue(userConfigState);

  return (
    <div className='modal-root-container confirm-modal-root'>

      <div className='modal-root-title'>
        {translate(confirmTitleKey, userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='confirm-modal-info'>
        {userConfig && !confirmInfo && confirmInfoKey && translate(confirmInfoKey, userConfig?.language)}
        {userConfig && confirmInfo}
      </div>

      <div className='confirm-modal-row'>
        <TextButton
          onClick={confirm}
          text={translate(confirmKey ?? 'confirm', userConfig?.language)}
          icon={<CheckIcon style={{ fontSize: 22 }} />}
          style={{ width: 125, height: 34 }}
        />

        <TextButton
          onClick={cancel}
          text={translate(cancelKey ?? 'cancel', userConfig?.language)}
          icon={<CloseIcon style={{ fontSize: 22 }} />}
          style={{ width: 125, height: 34 }}
        />
      </div>

    </div>
  );
};
