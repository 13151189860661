import { JSX } from 'react';
import { useRecoilValue } from 'recoil';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import LockIcon from '@mui/icons-material/Lock';
import RestoreIcon from '@mui/icons-material/Restore';
import PublicIcon from '@mui/icons-material/Public';
import BuildIcon from '@mui/icons-material/Build';

import {
  PlayerClubIteration,
  PlayerClubIterationPositionGroupData,
  PlayerOverview,
  PositionGroupClubIteration,
  RoleConfig,
  StringToAnyMap
} from '../../types';


import { translate } from '../../../common/language/translations';
import { IconButton } from '../controls/buttons/IconButton';
import { getLocalityIcon, getRoleIcon, getTeamIcon } from '../../utils/iconUtils';
import { PlayerImage } from '../display/images/PlayerImage';
import { getAge, getDisplayPlayerName, getDisplayPositions, getMonthsLeftOfContract } from '../../utils/playerUtils';
import { CountryFlag } from '../display/images/CountryFlag';
import { ClubLogo } from '../display/images/ClubLogo';
import { ProgressCircle } from '../display/visualization/ProgressCircle';
import { getColorBlindRatingColor, getIndexColor, getRatingColor } from '../../utils/colorUtils';
import { PlayerOverviewWithAlertProperties } from '../../views/scouting/alerts/Alerts';
import { dateStringToLocaleFormat } from '../../utils/dateUtils';
import { countryCodeToCountryInfo } from '../../static/countries';
import { getDisplayEconomicValue } from '../../utils/currencyUtils';
import { CompetitionFlag } from '../display/images/CompetitionFlag';
import { CareerIterationEntry } from '../playerView/PlayerViewCareer';


interface PlayerPaginationCellProps {
  totalHits: number;
  currentChapter: number; // or page if chapterless
  hitsPerChapter: number; // or hitsPerPage if chapterless
  handleChangeCurrentChapter: (isIncrement: boolean) => void;
  paginationSectionPaddingRight?: number;
  language?: string;
}


export const PlayerPaginationCell: React.FC<PlayerPaginationCellProps> = ({
  totalHits,
  currentChapter,
  hitsPerChapter,
  handleChangeCurrentChapter,
  paginationSectionPaddingRight,
  language,
}) => {

  return (
    <div className='player-pagination-cell'>
      {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + translate(totalHits === 1 ? 'player' : 'players', language, true)}

      {totalHits > hitsPerChapter && (
        <div className='table-cell' style={{ justifyContent: 'start' }}>

          <div className='player-pagination-cell-current-chapter-range'>
            {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
          </div>

          <div className='player-pagination-cell-pagination-section' style={{ paddingRight: paginationSectionPaddingRight }}>
            <IconButton
              icon={(
                <KeyboardArrowLeftIcon
                  style={{ fontSize: 17, color: currentChapter > 0 ? '#ffffffcc' : '#ffffff77' }}
                />
              )}
              onClick={() => currentChapter > 0 ? handleChangeCurrentChapter(false) : null}
              size={20}
              isMobile={true}
              style={{ cursor: currentChapter > 0 ? 'pointer' : 'default' }}
            />

            <div className='player-pagination-cell-current-chapter'>
              {currentChapter + 1}
            </div>

            <IconButton
              icon={(
                <KeyboardArrowRightIcon
                  style={{ fontSize: 17, color: currentChapter < Math.floor(totalHits / hitsPerChapter) ? '#ffffffcc' : '#ffffff77' }}
                />
              )}
              onClick={() => currentChapter < Math.floor(totalHits / hitsPerChapter) ? handleChangeCurrentChapter(true) : null}
              size={20}
              isMobile={true}
              style={{ cursor: currentChapter < Math.floor(totalHits / hitsPerChapter) ? 'pointer' : 'default' }}
            />
          </div>

        </div>
      )}
    </div>
  );
};


interface PlayerInfoCellProps {
  playerOverview?: PlayerOverview;
  positionGroupClubIteration?: PositionGroupClubIteration;
  maxPlayerNameLength?: number;
  infoCellColumnWidth?: number | string;
  language?: string;
  showCountryFlag?: boolean;
  showClubInfo?: boolean;
  showTeamIcon?: boolean;
}

export const PlayerInfoCell: React.FC<PlayerInfoCellProps> = ({
  playerOverview,
  positionGroupClubIteration,
  maxPlayerNameLength,
  infoCellColumnWidth,
  language,
  showCountryFlag = true,
  showClubInfo = true,
  showTeamIcon = true,
}) => {

  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};
  const { openPlayerViewModal } = useOpenGlobalModal();

  // either one should be provided
  const playerObject = playerOverview ?? positionGroupClubIteration;
  if (!playerObject) return null;

  let teamIcon: JSX.Element | undefined = undefined;
  if (showTeamIcon && playerOverview) {

    const currentTeam = playerTeamData[playerOverview.id]?.currentTeam;
    let currentTeamIcon = getTeamIcon(currentTeam, 18, 'dark');
    if (!currentTeamIcon) {
      currentTeamIcon = <AddIcon style={{ fontSize: 13 }} />;
    }

    teamIcon = (
      <IconButton
        onClick={(event) => {
          {
            if (event) event.stopPropagation();
            openPlayerViewModal(
              playerOverview.id,
              playerOverview.fullname,
              undefined,
              undefined,
              true
            );
          }
        }}
        icon={currentTeamIcon}
        size={24}
        title={translate(currentTeam ? 'editTeamStatus' : 'addToTeam', language)}
        backgroundColorBehindIcon='gray'
      />
    );
  }

  let clubIterationInfo: JSX.Element | undefined = undefined;
  if (positionGroupClubIteration) {
    const primaryPositions = positionGroupClubIteration.overall_primary_positions ?? [];
    const secondaryPositions = positionGroupClubIteration.overall_secondary_positions ?? [];

    const primaryPositionString = getDisplayPositions(primaryPositions.slice(0, 3), language);
    let secondaryPositionString: string | undefined = undefined;

    if (primaryPositions.length === 1 && secondaryPositions.length > 0) {
      secondaryPositionString = getDisplayPositions(secondaryPositions.slice(0, 2), language);
    }

    clubIterationInfo = (
      <div className='player-info-cell-position-column'>
        <div className='player-info-cell-position-row' style={{ fontSize: 13 }}>
          {positionGroupClubIteration.season}
        </div>
        <div className='player-info-cell-position-row' style={{ fontSize: 12 }}>
          {primaryPositionString ?? '-'}

          {secondaryPositionString && (
            <div style={{ fontSize: 11, color: '#000000bb' }}>
              {'(' + secondaryPositionString + ')'}
            </div>
          )}
        </div>
      </div>
    );
  }


  return (
    <div className='player-info-cell'>

      <PlayerImage
        src={playerObject.image_url}
        size={40}
        containerStyle={{ marginLeft: 7, marginRight: 10 }}
        isSearchTableComponent={true}
      />


      <div className='player-info-cell-column' style={{ width: infoCellColumnWidth ?? '70%' }}>
        <div className='player-info-cell-row' style={{ fontSize: 13 }}>
          {getDisplayPlayerName(playerObject.fullname, maxPlayerNameLength ?? 22)}

          {showCountryFlag && (
            <CountryFlag
              countryCode={playerObject.country_code}
              width={16}
              isWhiteBackground={true}
              containerStyle={{ marginLeft: 9 }}
            />
          )}
        </div>

        {showClubInfo && (
          <ClubLogo
            club={playerObject.club}
            size={14}
            showClubName={true}
            competitionIdToShow={playerObject.club?.current_competition_id}
            containerStyle={{ justifyContent: 'left', color: '#444444', fontSize: 12 }}
          />
        )}
      </div>

      {teamIcon}

      {clubIterationInfo}

    </div>
  );
};


interface PlayerSimpleTableInfoCellProps {
  row: StringToAnyMap;
  disablePlayersWithoutEventData?: boolean;
  isGoalkeeper?: boolean;
}

export const PlayerSimpleTableInfoCell: React.FC<PlayerSimpleTableInfoCellProps> = ({
  row,
  disablePlayersWithoutEventData,
  isGoalkeeper
}) => {

  const disablePlayer = (disablePlayersWithoutEventData && !row.event_data_available)
    || (isGoalkeeper !== undefined && (isGoalkeeper !== row.primary_positions.includes('GK')));

  return (
    <div className='table-cell'>

      <PlayerImage
        src={row.image_url}
        size={34}
        containerStyle={{ marginLeft: 1, marginRight: 4, padding: '2px 0px' }}
        isSearchTableComponent={true}
      />

      <div className='player-simple-table-info-cell-column'>

        <div className='player-simple-table-info-cell-row'>
          <div className='player-simple-table-info-cell-name' style={{ color: disablePlayer ? '#4e4e4e' : 'none' }}>
            {getDisplayPlayerName(row.fullname, 16)}
          </div>

          <CountryFlag
            countryCode={row.country_code}
            width={13}
            containerStyle={{ marginLeft: 3 }}
            isWhiteBackground={true}
          />
        </div>

        {row.club && (
          <div className='player-simple-table-info-cell-row'>
            <ClubLogo
              club={row.club}
              size={12}
              showClubName={true}
              containerStyle={{ color: disablePlayer ? '#4e4e4e' : '#444444', fontSize: 11, gap: 3 }}
            />
          </div>
        )}
      </div>
    </div>
  );
};


interface PositionCellProps {
  playerData: PlayerOverview | CareerIterationEntry;
  language?: string;
  maxPositions?: number; // the maximum number of positions to display for each type
  isDarkBackground?: boolean;
}

export const PositionCell: React.FC<PositionCellProps> = ({
  playerData,
  language,
  maxPositions,
  isDarkBackground,
}) => {

  let primaryPositions = [];
  let secondaryPositions = [];

  if (playerData.event_data_exist) {
    primaryPositions = playerData.primary_positions ?? [];
    secondaryPositions = playerData.secondary_positions ?? [];

    if (maxPositions) {
      const allPositions = primaryPositions.concat(secondaryPositions);
      primaryPositions = allPositions.slice(0, maxPositions);
      secondaryPositions = allPositions.slice(maxPositions, maxPositions * 2);
    }
  }

  else {
    // if FAPI positions, we use the first position as primary and the second position as secondary (whether it's primary or secondary)
    const allPositions = (playerData.primary_positions ?? []).concat(playerData.secondary_positions ?? []);
    primaryPositions = allPositions.slice(0, 1);
    secondaryPositions = allPositions.slice(1);
  }

  const primaryPositionString = getDisplayPositions(primaryPositions, language);
  const secondaryPositionString = getDisplayPositions(secondaryPositions, language);

  return (
    <div
      className='position-cell'
      style={{ fontSize: primaryPositions.length > 2 ? 12 : undefined }}
    >
      {primaryPositionString ?? '-'}

      {secondaryPositionString && (
        <div style={{ fontSize: secondaryPositions.length > 2 ? 10 : 11, color: isDarkBackground ? '#ffffffdd' : '#000000dd' }}>
          {secondaryPositionString}
        </div>
      )}
    </div>
  );
};


interface IndexCellProps {
  playerOverview: PlayerOverview;
  property: string;
  positionGroup: string;
}

export const IndexCell: React.FC<IndexCellProps> = ({
  playerOverview,
  property,
  positionGroup,
}) => {

  let value: number | string | undefined = property === 'skill_index' || property === 'confidence'
    ? playerOverview.position_group_stats?.[positionGroup]?.[property]
    : playerOverview.position_group_stats?.[positionGroup]?.custom_indexes[property + '_index'];

  // not entirely sure if both checks are necessary
  value = value !== undefined && value !== null
    ? property === 'confidence'
      ? (Math.round(value * 100))
      : (Math.round(value * 10) / 10)
    : '-';

  return (
    <div className='table-cell'>
      {value}
    </div>
  );
};


interface ConfidenceCellProps {
  playerOverview: PlayerOverview;
  positionGroup: string;
}

export const ConfidenceCell: React.FC<ConfidenceCellProps> = ({
  playerOverview,
  positionGroup,
}) => {

  const confidence = playerOverview.position_group_stats?.[positionGroup]?.confidence;

  return (
    <div className='table-cell'>
      {confidence !== undefined && confidence !== null && (
        <ProgressCircle
          size={28}
          progress={confidence * 100}
          value={Math.round(confidence * 100)}
          trailColor='#00000022'
          color={getIndexColor(confidence * 10, 47)}
          fontSize={12}
        />
      )}
    </div>
  );
};


interface AlertTableExcludeCellProps {
  row: PlayerOverviewWithAlertProperties;
  language?: string;
  excludePlayer?: (playerId: number, allAlerts: boolean, monthsDiscarded: number) => void;
}

export const AlertTableExcludeCell: React.FC<AlertTableExcludeCellProps> = ({
  row,
  language,
  excludePlayer,
}) => {

  const excludePlayerInternal = (event: React.MouseEvent, allAlerts: boolean, monthsDiscarded: number) => {
    event.stopPropagation();
    if (excludePlayer) {
      excludePlayer(row.id, allAlerts, monthsDiscarded);
    }
  };

  return (
    <div className='alert-table-exclude-cell'>

      <div className='alert-table-exclude-container' onClick={(event) => excludePlayerInternal(event, false, 3)}>
        <div className='alert-table-exclude-row'>
          <BlockIcon style={{ fontSize: 16 }} />
          {'3 '}
          {translate('monthsShort', language, true)}
        </div>
        <div className='alert-table-exclude-row'>
          {translate('thisSearch', language)}
        </div>
      </div>

      <div className='alert-table-exclude-container' onClick={(event) => excludePlayerInternal(event, true, 12)}>
        <div className='alert-table-exclude-row'>
          <BlockIcon style={{ fontSize: 16 }} />
          {'1 '}
          {translate('year', language, true)}
        </div>
        <div className='alert-table-exclude-row'>
          {translate('allSearches', language)}
        </div>
      </div>

    </div >
  );
};


interface AlertTableExcludedCellProps {
  row: PlayerOverviewWithAlertProperties;
  language?: string;
  undoExcludePlayer?: (playerOverview: PlayerOverviewWithAlertProperties, allAlerts: boolean) => void;
}

export const AlertTableExcludedCell: React.FC<AlertTableExcludedCellProps> = ({
  row,
  language,
  undoExcludePlayer,
}) => {

  const undoExcludePlayerInternal = (event: React.MouseEvent, allAlerts: boolean) => {
    event.stopPropagation();
    if (undoExcludePlayer) {
      undoExcludePlayer(row, allAlerts);
    }
  };

  const isExcludedFromAllAlerts = row.isExcludedFromAllAlerts;

  return (
    <div className='alert-table-exclude-cell'>

      <div className='alert-table-exclude-column' style={{ width: 80, fontSize: 10, textWrap: 'nowrap' }}>
        <BlockIcon style={{ fontSize: 16 }} />
        {translate(isExcludedFromAllAlerts ? 'allSearches' : 'thisSearch', language)}
      </div>

      <div
        className='alert-table-excluded-container'
        onClick={(event) => undoExcludePlayerInternal(event, isExcludedFromAllAlerts ?? false)}
      >
        <div className='alert-table-exclude-column'>
          <RestoreIcon style={{ fontSize: 16, marginRight: 2 }} />
          {translate('undo', language)}?
        </div>
      </div>

    </div >
  );
};


interface PlayerSeasonTableMinutesCellProps {
  row: PositionGroupClubIteration;
}

export const PlayerSeasonTableMinutesCell: React.FC<PlayerSeasonTableMinutesCellProps> = ({ row }) => {

  // total minutes info
  const minutesPlayed = row.minutes_played ?? 0;
  const percentagePlayed = Math.round((row.minutes_played_percentage ?? 0) * 100) + '%';
  const minutesColorIndex = Math.min(minutesPlayed / 150, 10);
  const minutesColor = minutesColorIndex ? getIndexColor(minutesColorIndex, 50) : 'transparent';

  return (
    <div className='player-season-table-minutes-cell'>
      <ProgressCircle
        size={18}
        progress={minutesColorIndex * 10}
        color={minutesColor}
        strokeWidth={12}
        trailColor='#00000022'
      />

      <div className='player-season-table-minutes-row'>
        {minutesPlayed} min

        <div style={{ fontSize: 11, color: '#000000bb' }}>
          {'(' + percentagePlayed + ')'}
        </div>
      </div>
    </div>
  );
};


interface IterationMetricCellProps {
  positionGroupClubIteration?: PositionGroupClubIteration,
  playerClubIteration?: PlayerClubIteration,
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
  roleConfig?: RoleConfig,
  positionGroup?: string,
}

export const IterationMetricCell: React.FC<IterationMetricCellProps> = ({
  positionGroupClubIteration,
  playerClubIteration,
  property,
  metricGroupKey,
  metricGroupIsToggled,
  isColorBlind,
  roleConfig,
  positionGroup,
}) => {

  if (!positionGroupClubIteration && !playerClubIteration) return null;

  // if playerClubIteration is provided and roleConfig is under construction, show build icon
  if (playerClubIteration) {
    const isRoleRatingUnderConstruction = roleConfig && (
      !roleConfig.weightsLastUpdated ||
      !roleConfig.dataLastUpdated ||
      roleConfig.dataLastUpdated < roleConfig.weightsLastUpdated
    );

    if (isRoleRatingUnderConstruction) {
      return (
        <div className='table-cell'>
          <BuildIcon style={{ fontSize: 14, marginTop: 1 }} />
        </div>
      );
    }
  }

  let color: undefined | string = undefined;

  // if positionGroupClubIteration is provided, all metrics can be accessed directly, else we need to access the correct position_group_stats
  let metricMapAccessor: PositionGroupClubIteration | PlayerClubIterationPositionGroupData | undefined = positionGroupClubIteration;

  if (playerClubIteration && positionGroup) {
    const positionGroupForIteration = positionGroup === 'overall' && !playerClubIteration.position_group_stats?.overall
      ? playerClubIteration.position_groups[0]
      : positionGroup;

    metricMapAccessor = playerClubIteration.position_group_stats?.[positionGroupForIteration];

    // if playerClubIteration is provided and no data is available, the row is either blocked or locked, and this will be displayed in the skill rating cell
    const isBlocked = !metricMapAccessor || !metricMapAccessor.minutes_played;
    const isLocked = !playerClubIteration.event_data_available;

    if (property === 'skill_rating') {
      if (isBlocked) {
        return (
          <div className='table-cell' style={{ justifyContent: 'left', gap: 2 }}>
            <BlockIcon style={{ fontSize: 18, color: '#ffffff55', marginLeft: 16 }} />
            <ArrowRightAltIcon style={{ fontSize: 16, color: '#ffffff55' }} />
          </div>
        );
      }
      if (isLocked) {
        return (
          <div className='table-cell' style={{ justifyContent: 'left', gap: 2 }}>
            <LockIcon style={{ fontSize: 18, marginTop: -1, marginLeft: 16, color: '#ffffffaa' }} />
            <ArrowRightAltIcon style={{ fontSize: 16, color: '#ffffffaa' }} />
          </div>
        );
      }
    }

    if (isBlocked || isLocked) color = '#ffffff99';
  }

  const metricMap: StringToAnyMap | undefined = property === 'skill_rating'
    ? metricMapAccessor
    : roleConfig
      ? metricMapAccessor?.custom_ratings
      : metricMapAccessor?.metrics;

  const metricKey = property === 'skill_rating'
    ? property
    : roleConfig
      ? property + '_rating'
      : property + '_normalized';

  const normalizedValue = metricMap?.[metricKey];

  let displayValue = normalizedValue !== undefined ? Math.round(normalizedValue * 10) / 10 : '-';

  if (metricMap && metricGroupIsToggled) {
    let actualValue = metricMap[property];

    const isPercentage = property.endsWith('_percentage');
    if (actualValue && isPercentage) {
      actualValue = actualValue * 100;
    }

    const roundingFactor = (
      metricGroupKey === 'attacking' && property !== 'n_shots_per_90') ||
      property === 'gk_postshot_xg_prevented_difference_per_shot' ||
      property === 'skillcorner_time_to_hsr_weighted_average' ||
      property === 'skillcorner_time_to_sprint_weighted_average'
      ? 100
      : 10;

    displayValue = actualValue !== undefined ? Math.round(actualValue * roundingFactor) / roundingFactor : '-';

    if (displayValue !== '-' && isPercentage) {
      displayValue = displayValue + '%';
    }

    if (displayValue !== '-' && ['skillcorner_distance_per_90', 'skillcorner_distance_tip_per_30', 'skillcorner_distance_otip_per_30'].includes(property)) {
      displayValue = Math.round(actualValue / 100) / 10 + ' km';
    }

    if (displayValue !== '-' && [
      'skillcorner_sprinting_distance_per_90',
      'skillcorner_sprinting_distance_tip_per_30',
      'skillcorner_sprinting_distance_otip_per_30',
      'skillcorner_hsr_distance_per_90',
      'skillcorner_hsr_distance_tip_per_30',
      'skillcorner_hsr_distance_otip_per_30',
    ].includes(property)) {
      displayValue = Math.round(actualValue) + ' m';
    }

    if (displayValue !== '-' && property === 'skillcorner_psv-99_average') {
      displayValue = displayValue + ' km/h';
    }

    if (displayValue !== '-' && property === 'gk_penalty_saves_percentage') {
      displayValue = metricMap['gk_n_penalties_saved'] + '/' + metricMap['gk_n_penalties_faced']
        + ' (' + displayValue + ')';
    }
  }

  const backgroundColor = positionGroupClubIteration
    ? (isColorBlind ? getColorBlindRatingColor(normalizedValue ?? 0, 0.3) : getIndexColor(normalizedValue, 74))
    : (isColorBlind ? getColorBlindRatingColor(normalizedValue ?? 0, 0.7) : getRatingColor(normalizedValue));


  return (
    <div className='table-cell'>
      <div
        className='table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          color: color,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500
        }}
      >
        {displayValue}
      </div>
    </div>
  );
};


interface OwnTeamTableAgeCellProps {
  row: StringToAnyMap;
  language?: string;
}

export const OwnTeamTableAgeCell: React.FC<OwnTeamTableAgeCellProps> = ({
  row,
  language,
}) => {

  if (row.fullname === 'tableSummary') {
    return (
      <div className='table-cell flex-column'>
        <div className='own-team-table-summary-cell-label'>
          {translate('average', language)}
        </div>
        {row.averageAge ? (row.averageAge + ' ' + translate('years', language, true)) : '-'}
      </div>
    );
  }

  const birthDate = row.birth_date;
  const age = getAge(birthDate);

  if (!age) return (<div className='table-cell'>-</div>);

  return (
    <div className='table-cell flex-column'>

      <div>
        {age + ' ' + translate('years', language, true)}
      </div>

      <div style={{ fontSize: 12, color: '#00000099', marginTop: 1 }}>
        {birthDate ? dateStringToLocaleFormat(birthDate) : ''}
      </div>

    </div>
  );
};


interface OwnTeamTableContractCellProps {
  row: StringToAnyMap;
  language?: string;
}

export const OwnTeamTableContractCell: React.FC<OwnTeamTableContractCellProps> = ({
  row,
  language,
}) => {

  if (row.fullname === 'tableSummary') {
    return (
      <div className='table-cell flex-column'>
        <div className='own-team-table-summary-cell-label'>
          {translate('average', language)}
        </div>
        {row.averageMonthsLeftOfContract ? (row.averageMonthsLeftOfContract + ' ' + translate('monthsShort', language)) : '-'}
      </div>
    );
  }

  const contractExpiration = row.contract_expiration;
  const monthsLeft = getMonthsLeftOfContract(row.contract_expiration);

  if (monthsLeft === undefined) return (<div className='table-cell'>-</div>);

  return (
    <div className='table-cell flex-column'>

      <div>
        {monthsLeft + ' ' + translate('monthsShort', language)}
      </div>

      <div style={{ fontSize: 12, color: '#00000099', marginTop: 1 }}>
        {contractExpiration ? dateStringToLocaleFormat(contractExpiration) : ''}
      </div>

    </div>
  );
};


interface OwnTeamTableCountryCellProps {
  row: StringToAnyMap;
  language?: string;
}

export const OwnTeamTableCountryCell: React.FC<OwnTeamTableCountryCellProps> = ({
  row,
  language,
}) => {

  if (row.fullname === 'tableSummary') {

    const nationalPercentage = row.numberOfPlayers ? Math.round((row.numberOfNatives / row.numberOfPlayers) * 100) : 0;

    return (
      <div className='table-cell flex-column' style={{ height: 46, marginTop: 4 }}>

        <div
          className='table-cell'
          style={{ fontSize: 12, color: '#000000cc', marginLeft: 4 }}
        >
          <PublicIcon style={{ fontSize: 16, color: '#353a48bb' }} />
          <div style={{ width: 26, marginLeft: 6 }}>
            {row.numberOfForeigners}
          </div>
        </div>

        <div className='table-cell' style={{ fontSize: 12, color: '#000000cc', marginLeft: 4 }}>
          <CountryFlag
            countryCode={row.nationalCountryCode}
            width={15}
            isWhiteBackground={true}
          />
          <div style={{ width: 26, marginLeft: 6 }}>
            {nationalPercentage + '%'}
          </div>
        </div>

      </div >
    );
  }

  const countryCode = row.country_code;
  const countryInfo = countryCodeToCountryInfo[countryCode];

  return (
    <div
      className='table-cell'
      title={countryInfo ? countryInfo.name[language ?? 'en'] : ''}
    >
      <CountryFlag
        countryCode={countryCode}
        width={16}
        showHyphenIfEmpty={true}
        isWhiteBackground={true}
      />
    </div>
  );
};


interface OwnTeamTableEconomicCellProps {
  row: StringToAnyMap;
  property: string;
  language?: string;
}

export const OwnTeamTableEconomicCell: React.FC<OwnTeamTableEconomicCellProps> = ({
  row,
  property,
  language,
}) => {

  if (row.fullname === 'tableSummary') {
    return (
      <div className='table-cell flex-column'>
        <div className='own-team-table-summary-cell-label'>
          {translate('sum', language)}
        </div>
        {row[property + 'Total'] ? (getDisplayEconomicValue(row[property + 'Total'], true)) : '-'}
      </div>
    );
  }

  let value = row[property];

  if (value !== undefined && value !== null) {
    value = getDisplayEconomicValue(value, true);
  }

  return (
    <div className='table-cell'>
      {value}
    </div>
  );
};


interface OwnTeamTableLocalityCellProps {
  row: StringToAnyMap;
  language?: string;
}

export const OwnTeamTableLocalityCell: React.FC<OwnTeamTableLocalityCellProps> = ({
  row,
  language
}) => {

  if (row.fullname === 'tableSummary') {

    const localIcon = getLocalityIcon('local', 16, '#353a48bb');
    const nationalIcon = getLocalityIcon('national', 16, '#353a48bb');
    const internationalIcon = getLocalityIcon('international', 16, '#353a48bb');

    return (
      <div className='table-cell flex-column' style={{ height: 46, marginTop: 4 }}>
        <div
          className='table-cell'
          style={{ fontSize: 13, color: '#000000cc' }}
        >
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {localIcon}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {nationalIcon}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {internationalIcon}
          </div>
        </div>

        <div
          className='table-cell'
          style={{ fontSize: 13, color: '#000000cc' }}
        >
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {row.numberOfLocalPlayers}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {row.numberOfNationalPlayers}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {row.numberOfInternationalPlayers}
          </div>
        </div>
      </div>
    );
  }

  const icon = getLocalityIcon(row.locality, 18, 'dark');

  return (
    <div
      className='table-cell'
      title={row.locality ? translate(row.locality + 'Locality', language, undefined, undefined, true) : undefined}
    >
      {icon}
    </div>
  );
};


interface OwnTeamTableRoleCellProps {
  row: StringToAnyMap;
  clubLightColor: string;
  isAcademyTable: boolean;
  language?: string;
}

export const OwnTeamTableRoleCell: React.FC<OwnTeamTableRoleCellProps> = ({
  row,
  clubLightColor,
  isAcademyTable,
  language,
}) => {

  if (row.fullname === 'tableSummary') {

    if (isAcademyTable) {
      const trialistIcon = getRoleIcon('trialist', 16, '#353a48bb');
      const talentIcon = getRoleIcon('talent', 16, '#353a48bb');
      const potentialIcon = getRoleIcon('potential', 16, '#353a48bb');

      return (
        <div className='table-cell flex-column' style={{ height: 46, marginTop: 4 }}>
          <div
            className='table-cell'
            style={{ fontSize: 13, color: '#000000cc' }}
          >
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {trialistIcon}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {talentIcon}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {potentialIcon}
            </div>
          </div>

          <div
            className='table-cell'
            style={{ fontSize: 13, color: '#000000cc' }}
          >
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {row.roleMap.trialist ?? 0}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {row.roleMap.talent ?? 0}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {row.roleMap.potential ?? 0}
            </div>
          </div>
        </div>
      );
    }

    const loanedInIcon = getRoleIcon('loanedIn', 16, '#353a48bb');
    const loanedOutIcon = getRoleIcon('loanedOut', 16, '#353a48bb');

    return (
      <div className='table-cell flex-column' style={{ height: 46, marginTop: 4 }}>
        <div
          className='table-cell'
          style={{ fontSize: 12, color: '#000000cc', paddingLeft: 4 }}
        >
          {loanedInIcon}
          <div style={{ marginLeft: 4 }}>
            {row.roleMap.loanedIn ?? 0}
          </div>
        </div>
        <div
          className='table-cell'
          style={{ fontSize: 12, color: '#000000cc', paddingLeft: 4 }}
        >
          {loanedOutIcon}
          <div style={{ marginLeft: 4 }}>
            {row.roleMap.loanedOut ?? 0}
          </div>
        </div>
      </div>
    );
  }

  const icon = getRoleIcon(row.role, 18, 'dark', clubLightColor);

  return (
    <div
      className='table-cell'
      title={row.role ? translate(row.role, language) : undefined}
    >
      {icon}
    </div>
  );
};


interface SeasonTableSeasonCellProps {
  row: PlayerClubIteration;
  positionGroup: string;
  language?: string;
}

export const SeasonTableSeasonCell: React.FC<SeasonTableSeasonCellProps> = ({
  row,
  positionGroup,
  language,
}) => {

  // total minutes info
  const selectedPositionGroupKeyForIteration = positionGroup === 'overall' && !row.position_group_stats?.overall
    ? row.position_groups[0]
    : positionGroup;

  const positionGroupStats = row.position_group_stats?.[selectedPositionGroupKeyForIteration];
  const totalMinutesPlayed = positionGroupStats?.minutes_played ?? 0;
  const minutesColorIndex = Math.min(totalMinutesPlayed / 150, 10);
  const minutesColor = minutesColorIndex ? getIndexColor(minutesColorIndex, 65) : 'transparent';

  // if selectedPositionGroupKey is overall, we want to display positions similar to other tables, and then minutes played
  // if selectedPositionGroupKey is not overall, we want to display minutes played for each position
  let positionColumn: JSX.Element | undefined = undefined;
  if (positionGroup === 'overall') {
    const primaryPositions = row.primary_positions ?? [];
    const secondaryPositions = row.secondary_positions ?? [];

    const primaryPositionsToUse = primaryPositions.slice(0, 3);
    const primaryPositionString = getDisplayPositions(primaryPositionsToUse, language);

    let secondaryPositionsToUse = [];
    let secondaryPositionString: string | undefined = undefined;

    if (primaryPositionsToUse.length <= 1 && secondaryPositions.length > 0) {
      secondaryPositionsToUse = secondaryPositions.slice(0, 2);
      secondaryPositionString = getDisplayPositions(secondaryPositionsToUse, language);
    }

    const primaryFontSize = primaryPositionsToUse.length > 2 ? 10 : secondaryPositionsToUse.length > 1 ? 11 : 12;
    const secondaryFontSize = 12 - secondaryPositionsToUse.length;

    positionColumn = (
      <div className='season-table-season-cell-position-column' style={{ fontSize: primaryFontSize, gap: 4 }}>
        <div className='season-table-season-cell-position-row' style={{ justifyContent: 'left', gap: 4 }}>
          {primaryPositionString}

          {secondaryPositionString && (
            <div style={{ fontSize: secondaryFontSize, color: '#ffffffbb' }}>
              {'(' + secondaryPositionString + ')'}
            </div>
          )}
        </div>

        <div style={{ fontSize: 11 }}>
          {totalMinutesPlayed} min
        </div>
      </div>
    );
  }

  else {
    let positions = positionGroupStats?.positions ?? [];
    let positionColor: string | undefined = undefined;
    let minutesColor: string | undefined = undefined;

    if (positions.length === 0) {
      positions = [{ position: '-', percentage: 0, minutes: 0 }];
      positionColor = '#ffffff99';
      minutesColor = '#ffffff66';
    }

    const fontSize = positions.length > 2 ? 10 : 11;

    positionColumn = (
      <div className='season-table-season-cell-position-column' style={{ fontSize: fontSize }}>
        {positions.map((position, index) => {
          return (
            <div key={index} className='season-table-season-cell-position-row' style={{ color: positionColor }}>
              {getDisplayPositions([position.position], language)}

              <div style={{ color: minutesColor }}>
                {position.minutes} min
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className='season-table-season-cell'>

      <div className='season-table-season-cell-column'>

        <div className='season-table-season-cell-row'>
          <CompetitionFlag
            competitionId={row.competition_id}
            width={14}
            seasonToShow={row.season}
            containerStyle={{ gap: 3, marginLeft: 1 }}
            imageStyle={{ marginRight: 2 }}
            smallFontSize={11}
            smallFontSizeThreshold={20}
          />
        </div>

        <div className='season-table-season-cell-row'>
          <ClubLogo
            club={row.club}
            size={14}
            showClubName={true}
            containerStyle={{ gap: 5, marginLeft: 1 }}
            smallFontSize={11}
            smallFontSizeThreshold={20}
          />
        </div>

      </div>

      {positionColumn}

      <div className='season-table-season-cell-minutes-column'>
        <ProgressCircle
          size={14}
          progress={minutesColorIndex * 10}
          color={minutesColor}
          strokeWidth={9}
          trailColor='#ffffff44'
        />
      </div>

    </div>
  );
};


export const CareerTableNationalCell: React.FC<{ row: CareerIterationEntry }> = ({ row }) => {

  return (
    <div className='table-cell' style={{ justifyContent: 'left', paddingLeft: 8 }}>
      <ClubLogo
        club={row.club}
        size={14}
        showClubName={true}
        containerStyle={{ gap: 6, marginLeft: 1 }}
      />
    </div>
  );
};


export const CareerTableSeasonCell: React.FC<{ row: CareerIterationEntry }> = ({ row }) => {

  return (
    <div className='season-table-season-cell'>

      <div className='season-table-season-cell-column' style={{ width: 'calc(100% - 65px)' }}>

        <CompetitionFlag
          competitionId={row.competition_id}
          width={14}
          containerStyle={{ justifyContent: 'left', marginTop: 2, marginBottom: 2, gap: 6, marginLeft: 1 }}
        />

        <ClubLogo
          club={row.club}
          size={14}
          showClubName={true}
          containerStyle={{ justifyContent: 'left', marginTop: 2, marginBottom: 2, gap: 6, marginLeft: 1 }}
        />
      </div>

      <div className='table-cell' style={{ width: 55, paddingRight: 0 }}>
        {row.season}
      </div>

    </div>
  );
};


export const TeamTableTeamCell: React.FC<{ row: StringToAnyMap, index: number }> = ({ row, index }) => {

  let clubName = row.club_name;
  const clubNameArray = clubName.split(' ');

  if (clubNameArray.length > 2 && clubName.length > 25) {
    clubName = clubNameArray[0] + ' ' + clubNameArray[clubNameArray.length - 1];
  }

  return (
    <div className='table-cell' style={{ paddingLeft: 12, paddingRight: 4, justifyContent: 'left' }}>
      <div className='team-table-team-cell-rank'>
        {index + 1}
      </div>

      <ClubLogo
        clubLogoUrl={row.club_logo_url}
        clubName={clubName}
        size={18}
        showClubName={true}
        containerStyle={{ justifyContent: 'left', gap: 6, fontSize: 12, marginLeft: 1 }}
        smallFontSize={11}
        smallFontSizeThreshold={12}
        maxInfoWidth={97}
      />
    </div>
  );
};


interface PlayerSimpleTableTeamIconCellProps {
  row: StringToAnyMap;
  disablePlayersWithoutEventData?: boolean;
  isGoalkeeper?: boolean;
}

export const PlayerSimpleTableTeamIconCell: React.FC<PlayerSimpleTableTeamIconCellProps> = ({
  row,
  disablePlayersWithoutEventData,
  isGoalkeeper
}) => {

  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};

  if (disablePlayersWithoutEventData && !row.event_data_available) {
    if (row.event_data_exist) {
      return (
        <div className='table-cell'>
          <LockIcon style={{ fontSize: 18, color: '#353a48' }} />
        </div>
      );
    }
    return (
      <div className='table-cell'>
        <BlockIcon style={{ fontSize: 18, color: '#353a48' }} />
      </div>
    );
  }

  if (isGoalkeeper !== undefined && (isGoalkeeper !== row.primary_positions.includes('GK'))) {
    return (
      <div className='table-cell'>
        <BlockIcon style={{ fontSize: 18, color: '#353a48' }} />
      </div>
    );
  }

  const currentTeam = playerTeamData[row.id]?.currentTeam;
  const currentTeamIcon = getTeamIcon(currentTeam, 18, 'dark');

  if (currentTeamIcon) {
    return (
      <div className='table-cell'>
        {currentTeamIcon}
      </div>
    );
  }

  return <div>&nbsp;</div>;
};
