import '../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

import { translate } from '../../../../common/language/translations';
import { DatedValue } from '../../../types';
import { ClauseState } from '../../documents/clause/clauseState';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { DateInputField } from '../../controls/input/DateInputField';
import { InputField } from '../../controls/input/InputField';
import { dateStringToLocaleFormat, getAdjustedDate } from '../../../utils/dateUtils';


interface OverrideTotalConditionModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
  totalConditionIndex: number | undefined;
}

export const OverrideTotalConditionModal: React.FC<OverrideTotalConditionModalProps> = ({
  closeModal,
  state,
  dispatch,
  totalConditionIndex,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [newValue, setNewValue] = useState('');
  const [providedDate, setProvidedDate] = useState<Date | null>(new Date());
  const buttonIsDisabled = !newValue || !providedDate;


  const handleSetProvidedDate = (date: Date | null) => {
    if (!date) {
      setProvidedDate(null);
      return;
    }
    const adjustedDate = getAdjustedDate(date);
    setProvidedDate(adjustedDate);
  };


  const onChangeInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value !== '') {
      const numberValue = Number(value);
      if (isNaN(numberValue) || numberValue < 0) return;
    }

    setNewValue(value);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!buttonIsDisabled) handleOverrideTotalCondition(Number(newValue), providedDate.toISOString().split('T')[0]);
    }
  };


  const handleOverrideTotalCondition = (newValue: number | null, providedDate?: string) => {
    if (totalConditionIndex !== undefined) {
      const overriddenValue: DatedValue | null = newValue !== null && providedDate ? { value: newValue, date: providedDate } : null;
      dispatch({ type: 'SET_OVERRIDDEN_TOTAL_CONDITION_VALUE', payload: { overriddenValue, totalConditionIndex } });
    }
    closeModal();
  };


  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;
  const previousOverriddenValue = totalConditionIndex !== undefined ? stateAccessor.totalConditions[totalConditionIndex].overriddenValue : undefined;


  return (
    <div className='modal-root-container override-total-condition-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('incorrectValue?', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>

        <div className='modal-content-section' style={{ textAlign: 'center', padding: 20 }}>
          {translate('overrideValueInfo', userConfig?.language)}
          <div style={{ marginTop: 20, marginBottom: 8 }}>
            {translate('provideDate', userConfig?.language)}
          </div>
          <DateInputField
            value={providedDate}
            placeholder={translate('selectDate', userConfig?.language)}
            onChange={handleSetProvidedDate}
            onKeyDown={onKeyDownInputField}
            height={23}
            width={130}
            minDate='today-10y'
            maxDate='today+10y'
          />
        </div>

        <div className='modal-content-section' style={{ padding: '25px 0px' }}>
          <div style={{ marginBottom: 8 }}>
            {translate('provideNewValue', userConfig?.language)}
          </div>
          <InputField
            value={newValue}
            onChange={onChangeInputField}
            onKeyDown={onKeyDownInputField}
            isSquare={true}
            height={23}
            width={130}
          />
        </div>

        <div className='modal-content-section' style={{ height: 100, minHeight: 100 }}>
          {previousOverriddenValue && (
            <div className='override-total-condition-modal-previous-value'>
              {translate('previousOverridenValue', userConfig?.language)}
              : {previousOverriddenValue.value} {'(' + dateStringToLocaleFormat(previousOverriddenValue.date) + ')'}

              <IconButton
                icon={<ReplayIcon style={{ fontSize: 18 }} />}
                onClick={() => handleOverrideTotalCondition(null)}
                size={24}
                title={translate('resetOverriddenValue', userConfig?.language)}
              />
            </div>
          )}

          <TextButton
            onClick={() => handleOverrideTotalCondition(Number(newValue), providedDate?.toISOString().split('T')[0])}
            text={translate('overrideValue', userConfig?.language)}
            icon={<CheckIcon style={{ fontSize: 22 }} />}
            disabled={buttonIsDisabled}
          />
        </div>
      </div>
    </div>
  );
};
