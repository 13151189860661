import '../tables.css';

import { useEffect, useMemo, useRef } from 'react';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';


import {
  getPlayerTableDataColumns,
  playerTableInfoColumns,
  playerTableInfoColumnsWidth,
  playerTablePadding,
  playerTableStaticDataColumnsWidth,
} from './playerColumns';

import { PlayerOverview } from '../../../types';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { debounce } from '../../../utils/utils';
import { playerTableChapterSize, playerTablePageSize } from '../../../views/scouting/Scouting';
import { getClubSecondaryColor } from '../../../static/clubConfigs';
import { translate } from '../../../../common/language/translations';
import { PlayerInfoCell, PlayerPaginationCell } from '../tableCells';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { getDisplayPositions } from '../../../utils/playerUtils';
import { positionGroupToPositions } from '../../../static/propertyValues';
import { getRoleColumnsWidth } from '../utils';
import { getNavigationWidth } from '../../../utils/navigationUtils';


interface PlayerTableProps {
  data: PlayerOverview[];

  isLoading?: boolean;
  handleSearchButtonPressed?: (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  handleChangeCurrentChapter: (isIncrement: boolean) => void;

  selectedOrderBy?: string | undefined;
  selectedRoles?: string[];
}

export const PlayerTable: React.FC<PlayerTableProps> = ({
  data,

  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  handleChangeCurrentChapter,

  selectedOrderBy,
  selectedRoles,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();
  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();
  const { width } = useWindowSize();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const roleConfigs = useMemo(() => clubSettings?.roleConfigs ?? {}, [clubSettings?.roleConfigs]);
  const selectedRolesInternal = useMemo(() => selectedRoles ?? [], [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
  const dataColumns = useMemo(() => {
    return getPlayerTableDataColumns(selectedRolesInternal, roleConfigs);
  }, [selectedRolesInternal, roleConfigs]);


  const handleRowClick = (playerOverview: PlayerOverview) => {
    const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && roleConfigs[selectedRoles[0]]
      ? roleConfigs[selectedRoles[0]].positionGroup
      : undefined;

    setAndTrackSelectedPlayerState({
      id: playerOverview.id,
      fullname: playerOverview.fullname,
      playerOverview: playerOverview,
      initialPositionGroup: initialPositionGroup,
    });
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !handleSearchButtonPressed) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    const isMoreDataToFetch = ((currentChapter * playerTablePageSize * playerTableChapterSize) + data.length) < totalHits;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && isMoreDataToFetch) {
      await handleSearchButtonPressed(false, false);
    }
  };


  useEffect(() => {
    if (playerTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  const tableWidth = width - 24 - getNavigationWidth(userConfig);
  const playerTableStickyColumnWidth = 320;
  const playerTableNonStickyWidth = tableWidth - playerTableStickyColumnWidth;
  const playerTableNonStickyMinWidth = playerTableInfoColumnsWidth
    + playerTableStaticDataColumnsWidth
    + getRoleColumnsWidth(selectedRolesInternal, roleConfigs)
    + playerTablePadding;
  const widthUnit = Math.max(playerTableNonStickyWidth / playerTableNonStickyMinWidth, 1);


  const hitsPerChapter = playerTablePageSize * playerTableChapterSize;
  const positionGroup = selectedRolesInternal && selectedRolesInternal.length > 0 && roleConfigs[selectedRolesInternal[0]]
    ? roleConfigs[selectedRolesInternal[0]].positionGroup
    : 'overall';


  return (
    <div
      ref={tableContainerRef}
      className='table-container player-table-container'
      style={{ overflowX: widthUnit <= 1 ? 'auto' : 'hidden' }}
    >

      {/* Header */}
      <div className='table-header player-table-header player-table-header-background'>

        {/* Player column header */}
        <div
          className='table-sticky-left player-table-header-background player-table-header-cell-with-border'
          style={{ width: playerTableStickyColumnWidth }}
        >
          <PlayerPaginationCell
            totalHits={totalHits}
            currentChapter={currentChapter}
            hitsPerChapter={hitsPerChapter}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            language={userConfig?.language}
          />
        </div>

        {/* Info column headers */}
        <div className='table-header-group player-table-header-cell-with-border'>
          {translate('info', userConfig?.language)}

          <div className='table-header-group-row'>
            {playerTableInfoColumns.map(column => {

              const isClickable = column.textModalInfoKey !== undefined;
              const onClick = isClickable
                ? () => openTextModal(
                  translate(column.key, userConfig?.language),
                  translate(column.key + 'Info', userConfig?.language),
                  { infoType: 'metric', titleKey: column.key }
                )
                : undefined;

              const isOrderedBy = selectedOrderBy && selectedOrderBy === column.orderByKey;

              return (
                <div
                  key={column.key}
                  className='table-cell'
                  title={isClickable ? translate('showDescription', userConfig?.language) : undefined}

                  style={{
                    width: column.width * widthUnit,
                    color: isOrderedBy ? getClubSecondaryColor(userConfig?.club ?? '') : undefined,
                    fontWeight: isOrderedBy ? 600 : undefined,
                  }}
                >
                  <div
                    className={isClickable ? ' table-header-cell-clickable' : ''}
                    onClick={onClick}
                  >
                    {translate(column.key, userConfig?.language)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Data column headers */}
        <div className='table-header-group'>

          <div style={{ paddingRight: playerTablePadding }}>
            {translate('basedOn<positions>', userConfig?.language) + ' '}
            {positionGroup !== 'overall'
              ? getDisplayPositions(positionGroupToPositions[positionGroup], userConfig?.language)
              : translate('allPositions', userConfig?.language, true)}
          </div>

          <div className='table-header-group-row'>
            {dataColumns.map(column => {

              const roleId = column.roleId;
              const onClick = roleId
                ? () => openRoleInfoModal(roleConfigs[roleId])
                : () => openTextModal(
                  metricToDisplayInfo[column.key].name[userConfig?.language ?? 'en'],
                  metricToDisplayInfo[column.key].description[userConfig?.language ?? 'en'],
                  { infoType: 'metric', titleKey: column.key }
                );

              const isOrderedBy = selectedOrderBy && selectedOrderBy === column.orderByKey;

              return (
                <div
                  key={column.key}
                  className='table-cell'
                  style={{
                    width: column.width * widthUnit,
                    color: isOrderedBy ? getClubSecondaryColor(userConfig?.club ?? '') : undefined,
                    fontWeight: isOrderedBy ? 600 : undefined,
                  }}
                >
                  <div
                    className='table-header-cell-clickable'
                    style={{ paddingRight: column.key === 'confidence' ? playerTablePadding : 0 }}
                    onClick={onClick}
                  >
                    {column.roleId
                      ? roleConfigs[column.roleId].name
                      : metricToDisplayInfo[column.key].name[userConfig?.language ?? 'en']}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Body */}
      <div className='player-table-body'>
        {data.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={'table-row player-table-row' + (rowIndex % 2 === 0 ? ' player-table-row-even' : ' player-table-row-odd')}
              onClick={() => handleRowClick(row)}
            >
              {/* Player column */}
              <div
                className={
                  'table-sticky-left player-table-sticky-cell player-table-body-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' player-table-sticky-cell-even' : ' player-table-sticky-cell-odd')
                }
                style={{ width: playerTableStickyColumnWidth }}
              >
                <PlayerInfoCell
                  playerOverview={row}
                  language={userConfig?.language}
                />
              </div>

              {/* Info columns */}
              <div className='flex-row player-table-body-cell-with-border'>
                {playerTableInfoColumns.map(column => {
                  return (
                    <div
                      key={column.key}
                      className='table-cell'
                      style={{ width: column.width * widthUnit }}
                    >
                      {column.cell({ row, language: userConfig?.language })}
                    </div>
                  );
                })}
              </div>

              {/* Data columns */}
              {dataColumns.map(column => {
                return (
                  <div
                    key={column.key}
                    className='table-cell'
                    style={{ width: column.width * widthUnit }}
                  >
                    {column.cell({ row, positionGroup })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
